/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';

import {
  ModalHeader, ModalContent, CustomModal, AddFileComponent, CustomButton,
} from '../../assets/styledNew';
import BottomSheet from '../BottomSheet';
import { tra } from '../../commons/utils';
import { selectExcelErrorList, selectExcelErrorModal } from '../../store/selectors';
import { setExcelErrorModal } from '../../store/actions';

class ExcelErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  contentPage = () => {
    const { excelErrorList } = this.props;
    return (
      <DialogContent className="content">
        <ModalHeader>
          <h1>{tra('Excel_Error_List')}</h1>
          <CloseIcon onClick={() => this.props.setExcelErrorModal(false)} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner">
            <div className="excelErrorList">
              {excelErrorList.map((msg, index) => (
                <p><span>{index + 1}.</span> {msg}</p>
              ))}
            </div>
          </div>
        </ModalContent>
      </DialogContent>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={() => this.props.setExcelErrorModal(false)} maxWidth="sm" fullWidth="true" scroll="paper">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll isOpen={(open) => open === false && this.props.setExcelErrorModal(false)}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

ExcelErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setExcelErrorModal: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  excelErrorList: selectExcelErrorList(),
  open: selectExcelErrorModal(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setExcelErrorModal,
    }, dispatch)
  )
);

const ExcelErrorModalWithTheme = withTheme(ExcelErrorModal);
const ExcelErrorModalWithWidth = withWidth()(ExcelErrorModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(ExcelErrorModalWithWidth);

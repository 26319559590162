import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  DialogContent, // DialogTitle,
  Grid,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  CustomButton,
  CustomModal,
  ModalHeader,
  ModalContent,
  BottomSheetWrapper,
  IntegrationMessageErrorAccordion,
} from '../../assets/styledNew';

import {
  Colors,
} from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';
import {
  getIntegrationMessageLog,
  createIntegrationMessageLog,
  deleteIntegrationMessageLog,
  setResetState,
} from '../../store/actions';
import { selectInfos, selectIntegrationMessageLogData, selectResetState } from '../../store/selectors';
import BottomSheet from '../BottomSheet';
import Table from '../Table';
import { Edit, Info, Remove } from '../../assets/icons';
import DeleteModal from './DeleteModal';

class IntegrationMessageMonitoringErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      edit: false,
      deleteId: false,
      formData: {},
      formDataArr: [
      ],
      detailData: {},
    };
  }

  componentDidUpdate(nextProps) {
    const { id } = this.props;
    if (!!id && nextProps.id !== id) {
      this.props.getIntegrationMessageLog(id);
    }
    if (nextProps.resetState !== this.props.resetState && this.props.resetState) {
      this.resetState();
    }
  }

  formDataOnChange = (name, data) => {
    this.setState((p) => ({ formData: { ...p.formData, [name]: data } }));
  }

  tableSelectRow = (row) => {
    if ('color' in row) {
      const formData = {
        ...row,
        monitoring_type_id: this.props.MonitoringMailTypes?.find((i) => i?.id === row?.monitoring_type_id) ?? '',
        mail_type_id: this.props.MonitoringTypes?.find((i) => i?.id === row?.mail_type_id) ?? '',
      };
      this.setState({ formData, expanded: true, edit: true });
    } else {
      this.setState({ detailData: row });
    }
  }

  tableDeleteItem = (row) => {
    const { formDataArr } = this.state;
    if ('color' in row) {
      // userData
      const index = formDataArr.findIndex((i) => i?.id === row?.id);
      const removeData = formDataArr.filter((v, i) => i !== index);
      this.setState({ formDataArr: removeData });
    } else {
      this.setState({ deleteId: row?.id });
    }
  }

  createSave = () => {
    const { formDataArr } = this.state;
    const serviceData = formDataArr.map((formData) => {
      const { color, id, ...otherData } = formData;
      return otherData;
    });
    if (serviceData?.length) {
      this.props.createIntegrationMessageLog({ id: this.props.id, data: serviceData, modal_id: this.props.id });
    }
  }

  formDataPush = () => {
    const {
      monitoring_type_id,
      mail_type_id,
      ...otherProps
    } = this.state.formData;

    if (Object.keys(this.state.formData)?.length >= 5) {
      this.setState((p) => ({
        formDataArr: [
          ...p.formDataArr,
          {
            ...otherProps,
            monitoring_type_id: monitoring_type_id?.id ?? false,
            mail_type_id: mail_type_id?.id ?? false,
            id: `formData${+(new Date())}`,
            color: true,
          }],
        formData: {},
      }));
    }
  }

  formDataEdit = () => {
    const { formData, formDataArr } = this.state;
    const { monitoring_type_id, mail_type_id, ...otherFormData } = formData;
    const deepCopy = JSON.parse(JSON.stringify(formDataArr));
    const index = formDataArr?.findIndex((i) => i?.id === formData?.id);
    if (index >= 0) {
      deepCopy[index] = {
        ...otherFormData,
        monitoring_type_id: monitoring_type_id?.id ?? '',
        mail_type_id: mail_type_id?.id ?? '',
      };
      this.setState({ formDataArr: deepCopy, formData: {}, edit: false });
    }
  }

  resetState = () => {
    this.setState({
      expanded: false,
      edit: false,
      deleteId: false,
      formData: {},
      formDataArr: [],
      detailData: {},
    });
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { formData, formDataArr, detailData } = this.state;
    const column_table = [
      {
        name: 'id',
        text: tra('Detay'),
        type: 'text',
        align: 'center',
        customBody: (val, name, index, row) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            onClick={() => this.tableSelectRow(row)}
          >
            {
              'color' in row
                ? <Edit color={Colors.blue} />
                : <Info color={Colors.blue} />
            }
          </div>
        ),
      },
      {
        name: 'monitoring_type_id',
        text: tra('Monitoring_Tipi'),
        type: 'text',
        align: 'center',
        customBody: (val) => (this.props.MonitoringTypes?.find((i) => i?.id === val)?.name ?? false),
      },
      {
        name: 'mail_type_id',
        text: tra('Mail_Tipi'),
        type: 'text',
        align: 'center',
        customBody: (val) => (this.props.MonitoringMailTypes?.find((i) => i?.id === val)?.name ?? false),
      },
      {
        name: 'error_reason',
        text: tra('Hata_Nedeni'),
        type: 'text',
        align: 'center',
        sortable: true,
        searchable: true,
      },
      {
        name: 'error_status',
        text: tra('Hata_Durumu'),
        type: 'text',
        align: 'center',
        sortable: true,
      },
      {
        name: 'solution_proposal',
        text: tra('Cozum_Onerisi'),
        type: 'text',
        align: 'center',
        sortable: true,
      },
      {
        name: 'id',
        text: '',
        type: 'text',
        align: 'center',
        // sortable: true,
        customBody: (id, name, index, row) => (
          <Remove color={Colors.red} onClick={() => this.tableDeleteItem(row)} />
        ),
      },
    ];
    return (
      <>
        <ContentWrapper>
          <ModalHeader>
            <h1>{tra('Error_Detail_Information')}</h1>
            <Close onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
          </ModalHeader>
          <ModalContent
            style={{
              maxHeight: '80vh',
              overflowY: 'auto',
              paddingRight: '3px',
            }}
          >
            <div className="pageInner" style={{ paddingTop: 30 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table
                    id="integrationMessageErrorDetail"
                    data={[...(this.props.messsageLog ?? []), ...formDataArr]}
                    columns={column_table}
                    // rowClick={(row) => this.tableSelectRow(row)}
                    rowColorKey="color" // rowu boyamak için karşılaştırılacak key
                    rowColorCase // rowu boyamak için karşılaştırılacak keyin eşit olması gereken value (true)
                    rowColorBg={Colors.greenBackg}
                    options={{
                      pagination: {
                        active: true,
                        perPage: 13,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IntegrationMessageErrorAccordion
                    expanded={this.state.expanded}
                    onChange={(e, val) => this.setState({ expanded: val })}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="accordionHeaderTitle">{this.state.edit ? tra('Bu_Satiri_Duzenle') : tra('Yeni_Satir_Ekle')}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Input
                            type="autoComplete"
                            multiple={false}
                            title={tra('Monitoring_Tipi')}
                            data={this.props?.MonitoringTypes ?? []}
                            onChange={(e, data) => this.formDataOnChange('monitoring_type_id', data)}
                            value={formData?.monitoring_type_id ?? ''}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            type="autoComplete"
                            multiple={false}
                            data={this.props?.MonitoringMailTypes ?? []}
                            title={tra('Mail_Tipi')}
                            onChange={(e, data) => this.formDataOnChange('mail_type_id', data)}
                            value={formData?.mail_type_id ?? ''}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            type="text"
                            title={tra('Hata_Nedeni')}
                            onChange={(e) => this.formDataOnChange('error_reason', e.target.value)}
                            value={formData?.error_reason ?? ''}
                            row={3}

                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            type="text"
                            title={tra('Hata_Durumu')}
                            onChange={(e) => this.formDataOnChange('error_status', e.target.value)}
                            value={formData?.error_status ?? ''}
                            row={3}

                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            type="text"
                            title={tra('Cozum_Onerisi')}
                            onChange={(e) => this.formDataOnChange('solution_proposal', e.target.value)}
                            value={formData?.solution_proposal ?? ''}
                            row={3}

                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                      {
                        this.state.edit ? (
                          <>
                            <CustomButton
                              onClick={this.formDataEdit}
                              disabled={!Object.values(formData).every((i) => i) || Object.values(formData)?.length < 5}
                            >
                              {tra('Duzenle')}
                            </CustomButton>
                            <CustomButton
                              onClick={this.formDataPush}
                              disabled={!Object.values(formData).every((i) => i) || Object.values(formData)?.length < 5}
                            >
                              {tra('Yeni_Satir_Ekle')}
                            </CustomButton>
                          </>
                        ) : (
                          <CustomButton
                            onClick={this.formDataPush}
                            disabled={!Object.values(formData).every((i) => i) || Object.values(formData)?.length < 5}
                          >
                            {tra('Yeni_Satir_Ekle')}
                          </CustomButton>
                        )
                      }

                    </AccordionActions>
                  </IntegrationMessageErrorAccordion>
                </Grid>
              </Grid>
            </div>
          </ModalContent>
          <Grid container justify="flex-end" item xs={12} style={{ padding: '5px', gap: '5px' }}>
            {this.state.edit && <CustomButton onClick={() => this.setState({ edit: false, formData: {} })} color={Colors.blue}> {tra('Duzenleme_Kapat')}  </CustomButton>}
            <CustomButton onClick={this.createSave} color={Colors.blue}> {tra('Kaydet')}  </CustomButton>
          </Grid>
        </ContentWrapper>
        <CustomModal
          open={!!Object.values(this.state.detailData)?.length}
          onClose={() => this.setState({ detailData: {} })}
          maxWidth="sm"
          fullWidth="true"
          scroll="body"
        >

          <ContentWrapper>
            <ModalHeader>
              <h1>{tra('Detay')}</h1>
              <Close onClick={() => this.setState({ detailData: {} })} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
            </ModalHeader>
            <ModalContent
              style={{
                maxHeight: '80vh',
                overflowY: 'auto',
                paddingRight: '3px',
                paddingTop: '10px',
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    multiple={false}
                    title={tra('Monitoring_Tipi')}
                    data={this.props?.MonitoringTypes ?? []}
                    value={this.props.MonitoringTypes?.find((i) => i?.id === detailData?.monitoring_type_id) ?? ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    multiple={false}
                    data={this.props?.MonitoringMailTypes ?? []}
                    title={tra('Mail_Tipi')}
                    onChange={(e, data) => this.formDataOnChange('mail_type_id', data)}
                    value={this.props.MonitoringMailTypes?.find((i) => i?.id === detailData?.mail_type_id) ?? ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    title={tra('Hata_Nedeni')}
                    value={detailData?.error_reason ?? ''}
                    row={3}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    title={tra('Hata_Durumu')}
                    value={detailData?.error_status ?? ''}
                    disabled
                    row={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    title={tra('Cozum_Onerisi')}
                    value={detailData?.solution_proposal ?? ''}
                    disabled
                    row={3}
                  />
                </Grid>
              </Grid>

            </ModalContent>
          </ContentWrapper>
        </CustomModal>
        <DeleteModal
          open={!!this.state.deleteId}
          close={() => this.setState({ deleteId: false })}
          message={tra('Silme_Mesaji')}
          deleteFunc={() => {
            this.props.deleteIntegrationMessageLog({ id: this.state.deleteId, modal_id: this.props.id });
            this.setState({ deleteId: false });
          }}
        />
      </>

    );
  }

  displayDesktop = () => (
    <CustomModal
      onEntering={() => { }}
      open={this.props.open}
      onClose={this.props.close}
      onExit={() => this.resetState()}
      maxWidth="md"
      fullWidth="true"
      scroll="body"
    >
      {this.contentPage()}
    </CustomModal>
  );

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

IntegrationMessageMonitoringErrorModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  theme: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired,
  getIntegrationMessageLog: PropTypes.func.isRequired,
  createIntegrationMessageLog: PropTypes.func.isRequired,
  deleteIntegrationMessageLog: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  messsageLog: PropTypes.any.isRequired,
  MonitoringTypes: PropTypes.array.isRequired,
  MonitoringMailTypes: PropTypes.array.isRequired,
  resetState: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  messsageLog: selectIntegrationMessageLogData(),
  resetState: selectResetState(),
  MonitoringTypes: selectInfos('MonitoringTypes'),
  MonitoringMailTypes: selectInfos('MonitoringMailTypes'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getIntegrationMessageLog,
      createIntegrationMessageLog,
      deleteIntegrationMessageLog,
      setResetState,
    }, dispatch)
  )
);

export default compose(
  withWidth(),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(IntegrationMessageMonitoringErrorModal);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropsType from 'prop-types';
import {
  Grid, IconButton, LinearProgress,
} from '@material-ui/core';
import {
  PersonelDetailGrid, ProfilPageGrid, TableWrapper, ProfilSurveyCard, CustomTabs, CustomTabHead,
} from '../assets/styledNew';
import { selectActiveUser, selectProfileData, selectUserModule } from '../store/selectors';
import { profileInit, getProfileLog } from '../store/actions';
import { Colors } from '../assets/statics';
import {
  Camera, CorporateFare, Mail, Phone, WorkOutlined,
} from '../assets/icons';
import {
  FormatUTCLocaleDateStr, isEmptyObject, isUserModule, tra,
} from '../commons/utils';
import Table from '../components/Table';
import Logs from '../components/Logs';
import ProfilePhotoChange from '../components/modals/ProfilePhotoChange';
import { ModuleSwitch } from '../commons/ModuleSwitch';
import moduleType from '../commons/enum/moduleType';

// const profilLog = {
//   Hardware: 1,
//   Application: 2,
//   Costing: 3,
// };

class Profil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logTabIndex: 0,
      photoModal: false,
    };
    props.profileInit();
  }

  isUserModuleFunc = (modulTypeArr) => isUserModule(modulTypeArr)(this.props.initModule);

  profilInfoCard=(info) => (
    <PersonelDetailGrid container item justifyContent="start" alignContent="stretch" spacing={3}>
      <Grid item xs={12} md={3} justifyContent="center">
        <div className="userImage">
          <img src={`${info?.profileImage}`} alt={info?.full_name} />
          <IconButton
            key="close"
            aria-label="Close"
            className="userImgChangeButton"
            onClick={() => this.setState({ photoModal: true })}
          >
            <Camera color={Colors.white} width={21} />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <p className="name">{info?.full_name}</p>
        <p className="title">{info?.title}</p>
        <p className="role">{info?.permission_name}</p>
        <p className="lastlogin">{tra('Son_Giris')}: {info?.last_login && FormatUTCLocaleDateStr(info?.last_login)}</p>
      </Grid>
      <Grid item xs={12} md={1} />
      <Grid item xs={12} md={5} className="profilContact">
        <div><CorporateFare /><p> {info?.company_name}</p></div>
        <div><WorkOutlined /><p> {info?.department_name}</p></div>
        <div><Mail /><p> {info?.email}</p></div>
        <div><Phone /><p> {info?.phone}</p></div>

      </Grid>
    </PersonelDetailGrid>
  )

  applicationTable=(data) => {
    const columns = [
      {
        name: 'application_name',
        text: tra('Uygulama_Adı'),
        type: 'text',
        tooltip: false,
        sortable: true,
        align: 'center',
      },
      {
        name: 'scope',
        text: tra('Kapsam'),
        type: 'text',
        tooltip: false,
        sortable: true,
        align: 'center',
      },
      {
        name: 'business_capability',
        text: tra('Business_Capability'),
        type: 'text',
        tooltip: false,
        sortable: true,
        align: 'center',
      },
      {
        name: 'category',
        text: tra('Kategori'),
        type: 'text',
        tooltip: false,
        sortable: true,
        align: 'center',
      },
      {
        name: 'manifacturer',
        text: tra('Manifacturer'),
        type: 'text',
        tooltip: false,
        sortable: true,
        align: 'center',
      },

    ];

    return this.TableFormatComp('applicationInventoryTableProfil', tra('Envanter'), columns, data);
  }

  hardwareTable=(data) => {
    const columns = [
      {
        name: 'hardware_type_name',
        text: tra('Donanim_Tipi'),
        type: 'text',
        tooltip: false,
        sortable: true,
        align: 'center',
      },
      {
        name: 'hardware_category_name',
        text: tra('Donanim_Kategorisi'),
        sortable: true,
        tooltip: true,
        align: 'center',
      },
      {
        name: 'hardware_producer',
        text: tra('Marka'),
        sortable: true,
        type: 'text',
        align: 'center',
      },
      {
        name: 'model',
        text: tra('Model'),
        sortable: true,
        type: 'text',
        align: 'center',
      },
      {
        name: 'first_usage_date',
        text: 'Teslim Alınan Tarih',
        sortable: true,
        type: 'text',
        align: 'center',
        customBody: (value) => (
          FormatUTCLocaleDateStr(value)
        ),
      },

    ];
    return this.TableFormatComp('hardwareInventoryTableProfil', tra('Donanim'), columns, data);
  }

  /**
   *
   * @param {String} name
   * @param {Array} columns
   * @param {Array} data
   * @returns
   */
  TableFormatComp=(id, name, columns, data) => (
    <TableWrapper style={{ minHeight: '20vh' }}>
      <div className="header">
        <h4>{name}</h4>
      </div>
      <Table
        id={id}
        data={data}
        columns={columns}
        options={{
          header: {
            visible: false,
            columnSetting: false,
          },
          pagination: {
            active: true,
          },

        }}
      />
    </TableWrapper>
  )

  surveyComp=(survey) => {
    const percent = (survey?.total_complete_survey_count / survey?.total_survey_count) * 100;
    return (
      <ProfilSurveyCard>
        <div className="survey-card_left">
          <p>{survey.name}</p>
          <p>{survey.description}</p>
        </div>
        <div className="progressArea">
          <div className="proggresInfo">
            <span>{tra('Tamamla')}</span>
            <span>{`${percent}%`}</span>
          </div>
          <LinearProgress className="progressBar" variant="determinate" value={percent} />
        </div>
      </ProfilSurveyCard>
    );
  }

  logChangeTabs=() => {
    const profilLogType = [
      {
        name: 'Hardware',
        requestId: 1,
        disabled: this.isUserModuleFunc(moduleType.HARDWARE),
      },
      {
        name: 'Application',
        requestId: 2,
        disabled: this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        name: 'Costing',
        requestId: 3,
        disabled: this.isUserModuleFunc([moduleType.SOFTWARE, moduleType.HARDWARE]),
      },
    ];

    return (
      <CustomTabs
        value={this.state.logTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, newValue) => {
          this.setState({ logTabIndex: newValue }, () => {
            this.props.getProfileLog(profilLogType[newValue].requestId);
          });
        }}
        aria-label="profilLog"
      >
        { profilLogType?.map((item, i) => <CustomTabHead tabValue={i} active={this.state.logTabIndex === i} label={item.name} id={i} />)}
      </CustomTabs>
    );
  }

  render() {
    const { profileData } = this.props;
    const {
      info, hardware, application, log, survey,
    } = profileData ?? {};
    return !isEmptyObject(profileData) && (
      <ProfilPageGrid container spacing={3}>
        <Grid container item xs={12} md={8} spacing={3} style={{ height: 'fit-content' }}>
          <Grid item xs={12}>
            <div className="widgetGrid">
              {info && this.profilInfoCard(info)}
            </div>
          </Grid>
          <Grid item xs={12}>
            <ModuleSwitch hardware>
              <div className="widgetGrid">
                {hardware && this.hardwareTable(hardware)}
              </div>
            </ModuleSwitch>
          </Grid>
          <Grid item xs={12}>
            <ModuleSwitch software>
              <div className="widgetGrid">
                {hardware && this.applicationTable(application)}
              </div>
            </ModuleSwitch>
          </Grid>

        </Grid>

        <Grid container item xs={12} md={4} spacing={3} style={{ height: 'fit-content' }}>
          <ModuleSwitch software>
            <Grid item xs={12}>
              <div className="widgetGrid surveyBG">
                <div className="header">
                  <h4>{tra('Anketler')}</h4>
                </div>
                <div>
                  {survey && survey?.map((i) => this.surveyComp(i)) }
                </div>
              </div>
            </Grid>
          </ModuleSwitch>
          <Grid item xs={12}>
            {log && this.logChangeTabs()}
            {log && <Logs data={log} />}
          </Grid>

        </Grid>
        <ProfilePhotoChange user={this.props.activeUser} open={this.state.photoModal} close={() => this.setState({ photoModal: false })} />
      </ProfilPageGrid>
    );
  }
}

/* <ProfilePhotoChange user={activeUser} open={photoModal} close={() => this.setState({ photoModal: false })} /> */

Profil.propTypes = {
  profileData: PropsType.object.isRequired,
  activeUser: PropsType.object.isRequired,
  profileInit: PropsType.func.isRequired,
  getProfileLog: PropsType.func.isRequired,
  initModule: PropsType.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  profileData: selectProfileData(),
  activeUser: selectActiveUser(),
  initModule: selectUserModule(),
  // appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      profileInit,
      getProfileLog,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Profil);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import SimpleReactValidator from 'simple-react-validator';
import CloseIcon from '@material-ui/icons/Close';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  NewCalendarEventModal,
  CustomButton,
} from '../../assets/styledNew';
import Input from '../Input';
import {
  Colors,
} from '../../assets/statics';
import {
  SaveNew,
  CheckCalendar,
  AlarmClock,
  DateIcon,
} from '../../assets/icons';
import { tra, hexToRgbA } from '../../commons/utils';
import { createEvent } from '../../store/actions';
import { selectCreatedEvent } from '../../store/selectors';
import BottomSheet from '../BottomSheet';

class NewCalendarEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      title: '',
      desc: '',
      lang: {
        takvim: tra('Takvim'),
        hatirlaticiKur: tra('Hatirlatici_Kur'),
        yeniForm: tra('Yeni_Form'),
        yeniEtkinlik: tra('Yeni_Etkinlik'),
        yeniLisanUygulamaEkle: tra('Yeni_Lisans_Uygulama_Ekle'),
        bugun: tra('Bugun'),
        kaydet: tra('Kaydet'),
        aciklama: tra('Aciklama'),
        etkinlikAdi: tra('Etkinlik_Adi'),
        tarih: tra('Tarih'),
        etkinlikKaydedildi: tra('Etkinlik_Kaydedildi'),
      },
    };
    this.validator = new SimpleReactValidator({
      locale: 'tr',
      autoForceUpdate: this,
      element: false,
    });
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  handleSave = () => {
    const { title, desc } = this.state;
    const { selectedDay } = this.props;
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return false;
    }
    this.props.createEvent({ title, date: selectedDay, desc });
    this.setState({ type: 1 });
    return false;
  }

  card = (item) => (
    <div className="card">
      <span className="icon" style={{ background: this.props.theme.blueBackg }}><CheckCalendar color={this.props.theme.blueText} /></span>
      <div>
        <div className="cardHeader">
          <p>{item.title}</p>
          {/* <div>
            <CloseIcon style={{ color: Colors.red }} />
            <CheckIcon style={{ color: Colors.green }} />
            <MoreHorizIcon />
          </div> */}
        </div>
        <div className="cardContent">
          {item.desc}
        </div>
        <div className="cardFooter">
          <AlarmClock color={this.props.theme.blueText} width={16} />
          <div>
            <p>{this.props.selectedDay.toLocaleDateString('tr', { weekday: 'long' })}</p>
            <p>{this.props.selectedDay.toLocaleDateString('tr', { month: 'long', year: 'numeric', day: 'numeric' })}</p>
          </div>
        </div>
      </div>
    </div>
  )

  newEventPage = () => {
    const {
      lang, title, desc,
    } = this.state;
    const { close, selectedDay } = this.props;
    return (
      <div className="container" onClick={(e) => e.stopPropagation()} aria-hidden>
        <div className="header">{lang.yeniEtkinlik} <CloseIcon onClick={close} style={{ cursor: 'pointer' }} /></div>
        <div className="content">
          <Input
            title={lang.tarih}
            type="date"
            name="date"
            value={selectedDay}
            // onChange={(e) => this.handleChangeDate('date')(e)}
            endAdornment={<DateIcon color={this.props.theme.blueText} />}
            disabled
          />
          <Input
            type="text"
            name="title"
            onChange={(e) => this.handleChange(e)}
            value={title}
            title={lang.etkinlikAdi}
            error={this.validator.message('title', title, 'required')}
            helperText={this.validator.message('title', title, 'required')}
          />
          <Input
            type="text"
            name="desc"
            onChange={(e) => this.handleChange(e)}
            value={desc}
            title={lang.aciklama}
            row={2}
            error={this.validator.message('desc', desc, 'required')}
            helperText={this.validator.message('desc', desc, 'required')}
          />
        </div>
        <div className="footer">
          {/* <CustomButton outlined><Paperclip width={14} />Add Attachement</CustomButton> */}
          <CustomButton onClick={() => this.handleSave()}><SaveNew width={14} />{lang.kaydet}</CustomButton>
        </div>
      </div>
    );
  }

  resultPage = () => {
    const { lang } = this.state;
    const { close, event } = this.props;
    return (
      <div className="container" onClick={(e) => e.stopPropagation()} aria-hidden>
        <div className="header">{lang.yeniEtkinlik}
          <div>
            <CustomButton shadow onClick={() => this.setState({ type: 0, title: '', desc: '' })}><AddCircleOutlineOutlined />{lang.yeniForm}</CustomButton>
            <CloseIcon onClick={close} style={{ cursor: 'pointer' }} />
          </div>
        </div>
        <div className="content">
          <span className="icon" style={{ background: this.props.theme.greenBackg }}><CheckCalendar color={this.props.theme.greenText} /></span>
          <p>{lang.etkinlikKaydedildi}.</p>
          {event && this.card(event)}
        </div>
      </div>
    );
  }

  onClose = () => {
    this.setState({ type: 0, title: '', desc: '' });
    this.validator.hideMessages();
    this.props.close();
  }

  contentPage = () => (this.state.type ? this.resultPage() : this.newEventPage());

  displayDesktop = () => <NewCalendarEventModal open={this.props.open} fullWidth="true" onClick={() => this.onClose()}>{this.contentPage()}</NewCalendarEventModal>;

  displayMobile = () => <BottomSheet snapPoint={[600, 0]} initialSnap={0} open={this.props.open} isOpen={(open) => open === false && this.props.close()}><NewCalendarEventModal mobile open>{this.contentPage()}</NewCalendarEventModal></BottomSheet>;

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

NewCalendarEvent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  createEvent: PropTypes.func.isRequired,
  selectedDay: PropTypes.any.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  event: selectCreatedEvent(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createEvent,
    }, dispatch)
  )
);

const NewCalendarEventWithTheme = withTheme(NewCalendarEvent);
const NewCalendarEventWithWidth = withWidth()(NewCalendarEventWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(NewCalendarEventWithWidth);

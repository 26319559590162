import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTheme } from 'styled-components';
import { Grid, withWidth } from '@material-ui/core';
import {
  ComponentLoader, NoData, PageContent, PageWrapper,
} from '../../assets/styledNew';
import {
  getSingleSurveyDetail, getSurveyDetail, setSingleSurveyDetail, setSurveyDetail,
} from '../../store/actions';
import { selectInfos, selectSingleSurveyDetail, selectSurveyDetail } from '../../store/selectors';
import Input from '../../components/Input';
import {
  SingleSurveyDetailHeader, SurveyOptionBox, SurveyQuestionBox, SurveySectionHeader,
} from '../../assets/styledSurveyQuestions';
import NoDataImg from '../../assets/img/noData.png';
import { tra } from '../../commons/utils';

class SingleSurveyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.getSingleSurveyDetail(props.match.params.userId, props.match.params.id, props.match.params.appId);
    props.getSurveyDetail(props.match.params.id, props.match.params.appId);
  }

  componentWillUnmount() {
    this.props.setSingleSurveyDetail(false);
    this.props.setSurveyDetail(false);
  }

  answerRenderer = (id, choice, index) => {
    const { inputTypes } = this.props;
    const inputType = inputTypes.find((item) => item.id === id).type;
    switch (inputType) {
      case 0:
        return (
          <div className="paragraph">
            <Input
              disabled
              type="text"
              value={choice.answer_text}
              row={choice.answer_text && choice.answer_text.length > 50 && choice.answer_text.length / 50}
            />
          </div>
        );
      case 1:
        return (
          <div className="radioButton">
            <p>{index + 1}. {choice.text}</p>
          </div>
        );
      case 2:
        return (
          <div className="radioButton">
            <p>{index + 1}. {choice.text}</p>
          </div>
        );
      default:
        return (
          <div className="paragraph">{ }</div>
        );
    }
  }

  handleReturn = () => {
    const { history, match } = this.props;
    history.goBack(`SurveyDetail/${match.params.userId}`);
  }

  render() {
    const { singleSurveyDetail, surveyDetail } = this.props;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container>
              <Grid item xs={12}>
                {singleSurveyDetail.user && (
                  <SingleSurveyDetailHeader>
                    <div className="left">
                      <img className="profileCircle" src={singleSurveyDetail.user.profile_img} alt="profImg" />
                      <div className="info">
                        <h4>{singleSurveyDetail.user.name}</h4>
                        <p>{singleSurveyDetail.user.permission}</p>
                      </div>
                    </div>
                  </SingleSurveyDetailHeader>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid container justify="center" item xs={12}>
                <div>
                  <h4 style={{ color: this.props.theme.text2, fontSize: 25 }}>{surveyDetail.name}</h4>
                </div>
              </Grid>
            </Grid>
            {singleSurveyDetail ? (
              Object.keys(singleSurveyDetail).length > 0 && singleSurveyDetail.SurveySections.length > 0 ? (
                singleSurveyDetail.SurveySections.map((section) => (
                  <Grid container spacing={4}>
                    <Grid style={{ margin: '15px 0px' }} item xs={12}>
                      <SurveySectionHeader>
                        <p style={{ background: this.props.theme.mainBackg }}>{section.name}</p>
                      </SurveySectionHeader>
                    </Grid>
                    {section.Questions.map((question, index) => (
                      <Grid item md={4} sm={6} xs={12}>
                        <SurveyQuestionBox>
                          <h4>{index + 1}. {question.question_text}</h4>
                          <div className="answers">
                            {question.OptionChoices.map((choice, i) => (
                              <SurveyOptionBox answered={choice.answer}>{this.answerRenderer(question.input_type_id, choice, i)}</SurveyOptionBox>
                            ))}
                          </div>
                        </SurveyQuestionBox>
                      </Grid>
                    ))}
                  </Grid>
                ))) : (
                  <NoData>
                    <img src={NoDataImg} alt="No Data" />
                    <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                  </NoData>
              )) : (
                <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
            )}
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

SingleSurveyDetail.propTypes = {
  getSingleSurveyDetail: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  singleSurveyDetail: PropTypes.object.isRequired,
  surveyDetail: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getSurveyDetail: PropTypes.func.isRequired,
  setSingleSurveyDetail: PropTypes.func.isRequired,
  inputTypes: PropTypes.array.isRequired,
  setSurveyDetail: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  singleSurveyDetail: selectSingleSurveyDetail(),
  surveyDetail: selectSurveyDetail(),
  inputTypes: selectInfos('InputTypes'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getSingleSurveyDetail,
      getSurveyDetail,
      setSingleSurveyDetail,
      setSurveyDetail,
    }, dispatch)
  )
);

const SingleSurveyDetailWithTheme = withTheme(SingleSurveyDetail);
const SingleSurveyDetailWithWidth = withWidth()(SingleSurveyDetailWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(SingleSurveyDetailWithWidth);

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import moduleType from './enum/moduleType';
import { isUserModule } from './utils';

export const ModuleSwitch = ({
  hardware, integration, software, children, isSome,
}) => {
  const modulArray = [
    !!hardware && moduleType.HARDWARE,
    !!software && moduleType.SOFTWARE,
    !!integration && moduleType.INTEGRATION,
  ].filter((i) => i);
  const initModule = useSelector((s) => s?.activeUser?.modules?.filter((i) => i?.active).map((i) => i?.id)) ?? [];
  const result = useCallback(() => isUserModule(modulArray, isSome)(initModule), [initModule, isSome, modulArray]);
  return !!result() && children;
};

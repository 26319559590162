/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  ClickAwayListener,
  Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  Delete, DeleteOutline, BookmarkBorder, Public, Check, Close,
} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  PageWrapper, InputWrapper, PageContent, CustomButton, StarRating, SwitchStatus, GridPageWrapper, ActionFooter, RatingMenu,
} from '../../assets/styledNew';
import {
  Plus, DocumentPDF, FilledStar,
} from '../../assets/icons';
import {
  tra, gartnerModel,
} from '../../commons/utils';
import {
  createApplication, setResetState, createAppWithFile, setInventoryRegisterFiles, setInventoryRegisterFileUrls, getApplicationDraft,
  updateApplicationDraft, uploadAppDraftFile, deleteFile, setSendToDetailPageID, setSendToView, setToast,
} from '../../store/actions';
import {
  selectInfos, selectUsers, selectApplications, selectResetState, selectInventoryRegisterFiles, selectInventoryRegisterFileUrls, selectApplicationDraft, selectSendToDetailPageID, selectSendToView,
} from '../../store/selectors';
import InventoryDocumentModal from '../../components/modals/InventoryDocumentModal';
import { InventoryContext } from '../../commons/context/InventoryContext';
import InventoryFastInput from '../../components/inventory/InventoryFastInput';
import Validator from '../../commons/validator';
import { Colors } from '../../assets/statics';

class InventoryRegister extends Component {
  static contextType = InventoryContext;

  constructor(props) {
    super(props);
    this.state = {
      fileArr: [],
      fileUrlArr: [],
      appDraftId: '',
      removeCheckClick: false,
    };
    if (props.match.params.type !== 'register') {
      props.getApplicationDraft(props.match.params.type);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.applicationDraft && props.applicationDraft._id && (props.applicationDraft._id !== state.appId)) {
      return {
        appDraftId: props.applicationDraft._id,
        fileUrlArr: props.applicationDraft.AppUrls.map((item) => item),
        fileArr: props.applicationDraft?.AppFiles,
      };
    }
    if (props.match.params.type !== 'register' && props.applicationDraft?.id && props.applicationDraft.id !== state.appDraftId) {
      props.getApplicationDraft(props.match.params.type);
      return {
        appDraftId: props.match.params.type,
      };
    }
    return null;
  }

  componentDidUpdate(props, state) {
    const { type } = this.props.match.params;
    const {
      applicationDraft, inventoryRegisterFiles, legalOwners, locations,
    } = this.props;
    const { InventoryState } = this.context;
    if (this.props.resetState) {
      this.resetState();
    }
    if (Number.isInteger(this.props.sendToDetailID) && this.props.sendToView) {
      /**
       * send to detail page if registration is successful by server
       */
      this.props.setSendToView(false);
      this.props.history.push(`/InventoryDetail/${this.props.sendToDetailID}`);
    }
    if (
      type !== 'register' && legalOwners.length > 0 && locations.length > 0 && applicationDraft && applicationDraft._id
      && (applicationDraft._id !== state.appDraftId
        || applicationDraft?.AppFiles.length !== inventoryRegisterFiles.length
        || applicationDraft.statu !== InventoryState.statu
      )) {
      const tempAppPlans = [];
      const selectedLegalOwner = legalOwners.find((item) => item.id === applicationDraft.leg_id);
      const selectedCountry = locations.find((item) => item.id === applicationDraft.country_id);
      const selectedLocation = selectedCountry?.Locations.find((item) => item.id === applicationDraft.location_id);
      for (let i = 0; i < applicationDraft.AppPlans.length; ++i) {
        const newObj = {
          data_plan_id: applicationDraft.AppPlans[i].data_plan_id,
          plan_id: applicationDraft.AppPlans[i].plan_id,
          year: new Date(applicationDraft.AppPlans[i].year.toString()),
          desc: applicationDraft.AppPlans[i].desc,
        };
        tempAppPlans.push(newObj);
      }
      const newData = {
        ...applicationDraft,
        Tags: applicationDraft.Tags.map((item) => item),
        AppUrls: applicationDraft.AppUrls.map((item) => item),
        AppPlans: tempAppPlans,
        leg_id: selectedLegalOwner,
        country_id: selectedCountry,
        location_id: selectedLocation,
      };
      delete newData._id;
      delete newData.__v;
      this.props.setInventoryRegisterFiles(applicationDraft.AppFiles);
      this.props.setInventoryRegisterFileUrls(applicationDraft.AppUrls.map((item) => item));
      this.context.setInventoryState(newData);
      this.context.setInventoryPageState((prvState) => ({
        ...prvState,
        sendSysOwners: applicationDraft.SysOwners.map((item) => ({ id: item.id })),
        sendTechnicOwners: applicationDraft.TechnicOwners.map((item) => ({ id: item.id })),
        sendProcesses: applicationDraft.Processes.map((item) => ({ id: item.id })),
        sendBusinessOwners: applicationDraft.BusinessOwners.map((item) => ({ id: item.id })),
      }));
    }
  }

  componentWillUnmount() {
    this.resetState();
    this.props.setInventoryRegisterFileUrls([]);
    this.props.setInventoryRegisterFiles([]);
    this.context.resetState();
    this.props.setSendToDetailPageID(false);
  }

  resetState = () => {
    this.context.resetState();
    this.props.setInventoryRegisterFiles([]);
    this.props.setInventoryRegisterFileUrls([]);
    Validator.hideMessages();
    this.forceUpdate();
    this.props.setResetState(false);
    this.setState({ fileArr: [], fileUrlArr: [] });
  }

  createApplication = (type, sendToView) => {
    const registerType = this.props.match.params.type;
    const { InventoryState, InventoryPageState } = this.context;
    const {
      inventoryRegisterFiles, inventoryRegisterFileUrls,
    } = this.props;
    if (!Validator.allValid() && type === 'register') {
      Validator.showMessages();
      this.forceUpdate();
      this.props.setToast(true, tra('Lutfen_Zorunlu_Alanlari_Doldurun'), 'warning');
      return false;
    }
    const tempSendAppPlans = [];
    for (let i = 0; i < InventoryState.AppPlans.length; ++i) {
      const newObj = {
        ...InventoryState.AppPlans[i],
        desc: InventoryState.AppPlans[i]?.desc ?? '',
      };
      newObj.year = new Date(newObj.year).getFullYear();
      tempSendAppPlans.push(newObj);
    }
    const data = {
      name: InventoryState.name,
      desc: InventoryState.desc,
      version: InventoryState.version,
      platform_id: InventoryState.platform_id,
      location_id: InventoryState.location_id ? InventoryState.location_id.id : null,
      country_id: InventoryState.country_id ? InventoryState.country_id.id : null,
      total_cost: 0,
      budget_owner: InventoryState.budget_owner,
      vendor_id: Number(+InventoryState?.vendor_id) ? InventoryState?.vendor_id : InventoryState?.vendor_id?.id,
      SysOwners: InventoryPageState.sendSysOwners,
      TechnicOwners: InventoryPageState.sendTechnicOwners,
      BusinessOwners: InventoryPageState.sendBusinessOwners,
      Tags: InventoryState.Tags,
      AppUrls: inventoryRegisterFileUrls,
      Processes: InventoryPageState.sendProcesses,
      business_value: InventoryState.business_value,
      it_quality: InventoryState.it_quality,
      time_value: InventoryState.time_value,
      scope_id: InventoryState.scope_id,
      cat_id: InventoryState.cat_id,
      func_id: InventoryState.func_id,
      managed_by: InventoryState.managed_by,
      leg_id: InventoryState.leg_id?.id,
      AppPlans: tempSendAppPlans,
      PreApps: InventoryPageState.sendPreApps,
      SucApps: InventoryPageState.sendSucApps,
      user_base: InventoryState.user_base,
    };
    if (inventoryRegisterFiles.length > 0 && registerType === 'register') {
      this.props.createAppWithFile(data, inventoryRegisterFiles, type, sendToView);
    } else {
      this.props.createApplication(data, type, registerType === 'register' ? undefined : registerType, sendToView);
    }
    return true;
  }

  handleSubmitToFromViewDetail = () => {
    this.createApplication('register', true);
  }

  updateDraft = () => {
    const registerType = this.props.match.params.type;
    const { InventoryState, InventoryPageState } = this.context;
    const { inventoryRegisterFileUrls } = this.props;
    const data = {
      name: InventoryState.name,
      desc: InventoryState.desc,
      version: InventoryState.version,
      platform_id: InventoryState.platform_id,
      location_id: InventoryState.location_id ? InventoryState.location_id.id : null,
      country_id: InventoryState.country_id ? InventoryState.country_id.id : null,
      total_cost: 0,
      budget_owner: InventoryState.budget_owner,
      vendor_id: Number(+InventoryState?.vendor_id) ? InventoryState?.vendor_id : InventoryState?.vendor_id?.id,
      SysOwners: InventoryPageState.sendSysOwners,
      TechnicOwners: InventoryPageState.sendTechnicOwners,
      BusinessOwners: InventoryPageState.sendBusinessOwners,
      Tags: InventoryState.Tags,
      AppUrls: inventoryRegisterFileUrls,
      Processes: InventoryPageState.sendProcesses,
      business_value: InventoryState.business_value,
      it_quality: InventoryState.it_quality,
      time_value: InventoryState.time_value,
      scope_id: InventoryState.scope_id,
      cat_id: InventoryState.cat_id,
      func_id: InventoryState.func_id,
      managed_by: InventoryState.managed_by,
      leg_id: InventoryState.leg_id?.id,
      AppPlans: InventoryState.AppPlans,
      PreApps: InventoryPageState.sendPreApps,
      SucApps: InventoryPageState.sendSucApps,
      user_base: InventoryState.user_base,
    };
    this.props.updateApplicationDraft(data, registerType);
  }

  handleStarHoverChange = (num, numHover) => {
    if (num === 0 || num === null) {
      return numHover === -1 ? 0 : numHover;
    }
    return num;
  }

  render() {
    const {
      InventoryState, InventoryPageState, handleStarChange, handleChange, onChangeAutoComplete, onChangeTags,
      setInventoryPageState, deleteAppPlan, addAppPlan, handleChangeAppPlanData,
      RatingArr,
    } = this.context;
    const {
      fileArr, fileUrlArr, removeCheckClick,
    } = this.state;
    const {
      platforms, departments, tags, processes, users, vendors, applications, scopes,
      categories, legalOwners, history, userBases, match, dataPlans, plans, locations,
    } = this.props;
    Validator.purgeFields();
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <GridPageWrapper container spacing={2}>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container direction="row" spacing={2}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }} className="left">
                            {RatingArr.map((rate) => (
                              <StarRating starColor={rate.color}>
                                <p>{rate.title}</p>
                                <p style={{ color: rate.color }} className="ratingNumber">
                                  {this.handleStarHoverChange(InventoryState[rate.valueKey], InventoryPageState[rate.hoverKey])}
                                </p>
                                <ClickAwayListener onClickAway={() => rate.title === this.state.ratingMenu && this.setState({ ratingMenu: null })}>
                                  <RatingMenu open={this.state.ratingMenu === rate.title}>
                                    <IconButton onClick={() => this.setState({ ratingMenu: rate.title })}>
                                      <FilledStar color={rate.color} />
                                    </IconButton>
                                    <div className="menu">
                                      <Rating
                                        onChange={(event, newValue) => handleStarChange(newValue, rate.valueKey)}
                                        onChangeActive={(event, newHover) => {
                                          setInventoryPageState({
                                            ...InventoryPageState,
                                            [rate.hoverKey]: newHover,
                                          });
                                        }}
                                        value={InventoryState[rate.valueKey]}
                                        size="small"
                                        precision={0.5}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                      />
                                    </div>
                                  </RatingMenu>
                                </ClickAwayListener>
                              </StarRating>
                            ))}
                            {
                              gartnerModel.find((m) => m.value === InventoryState.time_value) && (
                                <SwitchStatus active>
                                  <span>{tra('Sistem_Onerisi')}</span>/
                                  <span>{gartnerModel.find((m) => m.value === InventoryState.time_value).name}</span>
                                </SwitchStatus>
                              )
                            }
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }} className="right">
                            <CustomButton
                              opacity
                              onClick={() => (match.params.type === 'register' ? this.createApplication('draft', false) : this.updateDraft())}
                              startIcon={<BookmarkBorder width={12} />}
                            >
                              {tra('Taslak')}
                            </CustomButton>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={6}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Application_Plan')}</h4>
                              <p>{tra('Application_Plan_Detail')}.</p>
                            </div>
                          </div>
                          <div className="inputList">
                            <InventoryFastInput
                              type="text"
                              title={tra('Uygulama_Adi')}
                              name="name"
                              onChange={(e) => handleChange(e, 'name')}
                              error={Validator.message('name', InventoryState.name, 'required|min:3')}
                              helperText={Validator.message('name', InventoryState.name, 'required|min:3')}
                              indicate
                            />
                            <InventoryFastInput
                              type="text"
                              title={tra('Versiyon')}
                              name="version"
                              onChange={(e) => handleChange(e, 'version')}
                              error={Validator.message('version', InventoryState.version, 'required')}
                              helperText={Validator.message('version', InventoryState.version, 'required')}
                              indicate
                            />
                            <InventoryFastInput
                              type="select"
                              emptyItem
                              title={tra('User_Base')}
                              info={tra('User_Base_Info')}
                              name="user_base"
                              onChange={(e) => handleChange(e, 'user_base')}
                              error={Validator.message('user_base', InventoryState.user_base, 'required')}
                              helperText={Validator.message('user_base', InventoryState.user_base, 'required')}
                              data={userBases}
                              indicate
                            />
                            <InventoryFastInput
                              type="select"
                              emptyItem
                              title={tra('Kapsam')}
                              name="scope_id"
                              info={tra('Scope_Info')}
                              onChange={(e) => handleChange(e, 'scope_id')}
                              error={Validator.message('scope_id', InventoryState.scope_id, 'required')}
                              helperText={Validator.message('scope_id', InventoryState.scope_id, 'required')}
                              data={scopes}
                              indicate
                            />
                            <InventoryFastInput
                              info={tra('Tag_Info')}
                              type="autoCompleteFree"
                              multiple
                              title={tra('Etiket')}
                              data={tags.map((item) => item.name)}
                              freeSolo
                              name="Tags"
                              onChange={(e, newValue) => onChangeTags(e, newValue, 'Tags')}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid className="rowRight" item xs={6}>
                        <div className="box">
                          <div className="headerGrid" />
                          <div className="inputList">
                            <InventoryFastInput
                              type="select"
                              emptyItem
                              title={tra('Business_Domain')}
                              name="func_id"
                              onChange={(e) => handleChange(e, 'func_id')}
                              error={Validator.message('func_id', InventoryState.func_id, 'required')}
                              helperText={Validator.message('func_id', InventoryState.func_id, 'required')}
                              data={departments}
                              indicate
                            />
                            <InventoryFastInput
                              type="select"
                              emptyItem
                              title={tra('Categories')}
                              name="cat_id"
                              onChange={(e) => handleChange(e, 'cat_id')}
                              error={Validator.message('cat_id', InventoryState.cat_id, 'required')}
                              helperText={Validator.message('cat_id', InventoryState.cat_id, 'required')}
                              data={categories}
                              info={tra('IT_Domain_Info')}
                              indicate
                            />
                            <InventoryFastInput
                              title={tra('Business_Capability')}
                              multiple
                              type="autoComplete"
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'Processes')}
                              data={processes}
                              name="Processes"
                              error={Validator.message('Processes', InventoryState.Processes, 'required')}
                              helperText={Validator.message('Processes', InventoryState.Processes, 'required')}
                              indicate
                            />
                            <InventoryFastInput
                              type="text"
                              title={tra('Uygulama_Aciklamasi')}
                              row="4"
                              onChange={(e) => handleChange(e, 'desc')}
                              name="desc"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={6}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Technical_Information')}</h4>
                              <p>{tra('Info_Regarding_The_Technical_Aspect_Of_Your_Application_Are_Shown_Below')}.</p>
                            </div>
                          </div>
                          <div className="inputList">
                            <div className="multipleInputWrapper">
                              <InventoryFastInput
                                type="autoComplete"
                                emptyItem
                                data={vendors}
                                multiple={false}
                                title={tra('Uretici')}
                                onChange={(e, newValue, reason) => onChangeAutoComplete(e, newValue, 'vendor_id', reason)}
                                // name="vendor_id"
                                value={Number(+InventoryState.vendor_id) ? vendors.find((i) => i?.id === InventoryState.vendor_id) : InventoryState.vendor_id}
                                error={Validator.message('vendor_id', InventoryState.vendor_id, 'required')}
                                helperText={Validator.message('vendor_id', InventoryState.vendor_id, 'required')}
                                indicate
                              />
                              <InventoryFastInput
                                type="select"
                                emptyItem
                                data={platforms}
                                title={tra('Saglayici_Platformu')}
                                onChange={(e) => handleChange(e, 'platform_id')}
                                name="platform_id"
                                error={Validator.message('platform_id', InventoryState.platform_id, 'required')}
                                helperText={Validator.message('platform_id', InventoryState.platform_id, 'required')}
                                indicate
                              />
                            </div>
                            <div className="multipleInputWrapper">
                              <InventoryFastInput
                                title={tra('Saglayici_Ulkesi')}
                                name="country_id"
                                multiple={false}
                                type="autoComplete"
                                data={locations}
                                onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'country_id')}
                                error={Validator.message('country_id', InventoryState.country_id, 'required')}
                                helperText={Validator.message('country_id', InventoryState.country_id, 'required')}
                                indicate
                              />
                              <InventoryFastInput
                                disabled={InventoryState.country_id === null}
                                title={tra('Saglayici_Sehri')}
                                multiple={false}
                                type="autoComplete"
                                name="location_id"
                                data={InventoryState?.country_id?.Locations}
                                onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'location_id')}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid className="rowRight" item xs={6}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Technical_Information')}</h4>
                              <p>{tra('Info_Regarding_The_Technical_Aspect_Of_Your_Application_Are_Shown_Below')}.</p>
                            </div>
                          </div>
                          <div className="inputList">
                            <InventoryFastInput
                              type="autoComplete"
                              multiple
                              title={tra('Predecessor_Application')}
                              data={applications}
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'PreApps')}
                              name="PreApps"
                            />
                            <InventoryFastInput
                              type="autoComplete"
                              multiple
                              data={applications}
                              title={tra('Succesor_Application')}
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'SucApps')}
                              name="SucApps"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={6}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Owner_Details')}</h4>
                              <p>{tra('You_Can_Find_Info_About_The_People_In_Contact_With_Your_Application_Here')}.</p>
                            </div>
                          </div>
                          <div className="inputList">
                            <InventoryFastInput
                              type="autoComplete"
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'SysOwners')}
                              title={tra('Sistem_Sorumlulari')}
                              multiple
                              data={users}
                              name="SysOwners"
                              error={Validator.message('SysOwners', InventoryState.SysOwners, 'required')}
                              helperText={Validator.message('SysOwners', InventoryState.SysOwners, 'required')}
                              indicate
                            />
                            <InventoryFastInput
                              type="autoComplete"
                              title={tra('Is_Birimi_Sorumlulari')}
                              multiple
                              data={users}
                              name="BusinessOwners"
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'BusinessOwners')}
                              error={Validator.message('BusinessOwners', InventoryState.BusinessOwners, 'required')}
                              helperText={Validator.message('BusinessOwners', InventoryState.BusinessOwners, 'required')}
                              indicate
                            />
                            <InventoryFastInput
                              type="autoComplete"
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'TechnicOwners')}
                              title={tra('Teknik_Sorumlular')}
                              multiple
                              data={users}
                              name="TechnicOwners"
                              error={Validator.message('TechnicOwners', InventoryState.TechnicOwners, 'required')}
                              helperText={Validator.message('TechnicOwners', InventoryState.TechnicOwners, 'required')}
                              indicate
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid className="rowRight" item xs={6}>
                        <div className="box">
                          <div className="headerGrid" />
                          <div className="inputList">
                            <InventoryFastInput
                              type="autoComplete"
                              onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'leg_id')}
                              title={tra('Yasal_Sorumlular')}
                              multiple={false}
                              data={legalOwners}
                              name="leg_id"
                              error={Validator.message('leg_id', InventoryState.leg_id, 'required')}
                              helperText={Validator.message('leg_id', InventoryState.leg_id, 'required')}
                              indicate
                            />
                            <InventoryFastInput
                              type="select"
                              emptyItem
                              data={departments}
                              title={tra('Butce_Sorumlulari')}
                              onChange={(e) => handleChange(e, 'budget_owner')}
                              name="budget_owner"
                              error={Validator.message('budget_owner', InventoryState.budget_owner, 'required')}
                              helperText={Validator.message('budget_owner', InventoryState.budget_owner, 'required')}
                              indicate
                            />
                            <InventoryFastInput
                              type="select"
                              emptyItem
                              data={departments}
                              title={tra('Yonetim')}
                              onChange={(e) => handleChange(e, 'managed_by')}
                              name="managed_by"
                              error={Validator.message('managed_by', InventoryState.managed_by, 'required')}
                              helperText={Validator.message('managed_by', InventoryState.managed_by, 'required')}
                              indicate
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className={InventoryState.AppPlans.length > 0 ? 'gridRowWithoutAfter' : 'gridRow'} container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={12}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Application_Plan')}</h4>
                              <p>{tra('Info_Regarding_The_Technical_Aspect_Of_Your_Application_Are_Shown_Below')}.</p>
                            </div>
                            <div>
                              <CustomButton startIcon={<Plus width={12} />} onClick={addAppPlan}>{tra('Ekle')}</CustomButton>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {InventoryState.AppPlans.map((rowItem, index) => (
                        <Grid className={index !== InventoryState.AppPlans.length - 1 ? 'gridRowDashed' : 'gridRow'} container item xs={12} spacing={3}>
                          <Grid container justify="flex-end" item xs={12}>
                            <CustomButton
                              style={{ padding: 0, marginRight: 40 }}
                              opacity
                              onClick={() => deleteAppPlan(index)}
                            >
                              <DeleteOutline />
                            </CustomButton>
                          </Grid>
                          <Grid className="rowLeft" item xs={6}>
                            <div className="box">
                              <div className="inputList">
                                <InventoryFastInput
                                  type="date"
                                  value={rowItem.year}
                                  onlyYear
                                  title={tra('Yil_Secimi')}
                                  onChange={(e) => handleChangeAppPlanData(e, 'year', index)}
                                />
                                <div className="multipleInputWrapper">
                                  <InventoryFastInput
                                    type="select"
                                    data={plans}
                                    title={tra('Plan_App')}
                                    value={rowItem.plan_id}
                                    onChange={(e) => handleChangeAppPlanData(e, 'plan_id', index)}
                                  />
                                  <InventoryFastInput
                                    type="select"
                                    data={dataPlans}
                                    title={tra('Plan_Data')}
                                    value={rowItem.data_plan_id}
                                    onChange={(e) => handleChangeAppPlanData(e, 'data_plan_id', index)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid className="rowRight" item xs={6}>
                            <div className="box">
                              <div className="inputList">
                                <InventoryFastInput
                                  type="text"
                                  row="4"
                                  title={tra('Action_App_Notes')}
                                  value={rowItem.desc}
                                  onChange={(e) => handleChangeAppPlanData(e, 'desc', index)}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={12}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Add_Document_And_Link')}</h4>
                              <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>
                            </div>
                            <div>
                              <CustomButton
                                onClick={() => setInventoryPageState({
                                  ...InventoryPageState,
                                  openModalInventoryDetail: true,
                                })}
                                startIcon={<Plus width={12} />}
                                active
                              >
                                {tra('Ekle')}
                              </CustomButton>
                            </div>
                          </div>
                          <div className="documentList">
                            {(fileArr.length > 0 || fileUrlArr.length > 0) && (
                              <Table>
                                <TableHead>
                                  <TableCell colSpan={4}>{fileArr.length} {tra('Dokuman')} / {fileUrlArr.length} Link</TableCell>
                                </TableHead>
                                <TableBody>
                                  {fileArr.map((file, index) => (
                                    <TableRow>
                                      <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                                      <TableCell style={{ width: 'auto' }}>{match.params.type === 'register' ? file.name : <a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a>}</TableCell>
                                      <TableCell style={{ width: 150, textAlign: 'center' }}>{parseInt(file.size / 1024, 10)} Kb</TableCell>
                                      <TableCell style={{ width: 50, textAlign: 'center' }}>
                                        {removeCheckClick === index
                                          ? (
                                            <div style={{ display: 'flex' }}>
                                              <IconButton
                                                onClick={() => {
                                                  if (match.params.type !== 'register') {
                                                    this.props.deleteFile(file.id, match.params.type, 'draft');
                                                  } else {
                                                    fileArr.splice(index, 1);
                                                    this.props.setInventoryRegisterFiles(fileArr);
                                                    this.setState({ fileArr });
                                                  }
                                                }}
                                                style={{ padding: 5 }}
                                              >
                                                <Check style={{ color: Colors.green }} />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => this.setState({ removeCheckClick: false })}
                                                style={{ padding: 5 }}
                                              >
                                                <Close style={{ color: Colors.red }} />
                                              </IconButton>
                                            </div>
                                          )
                                          : (
                                            <IconButton
                                              onClick={() => this.setState({ removeCheckClick: index })}
                                              style={{ padding: 5 }}
                                            ><Delete />
                                            </IconButton>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  {fileUrlArr.map((url, index) => (
                                    <TableRow>
                                      <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                                      <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                                      <TableCell style={{ width: 50, textAlign: 'center' }}>
                                        <IconButton
                                          onClick={() => {
                                            fileUrlArr.splice(index, 1);
                                            this.props.setInventoryRegisterFileUrls(fileUrlArr);
                                            this.setState({ fileUrlArr });
                                          }}
                                          style={{ padding: 5 }}
                                        ><Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <ActionFooter>
                <CustomButton
                  opacity
                  onClick={() => history.push('/Inventories')}
                >
                  {tra('Iptal_Et')}
                </CustomButton>
                <CustomButton
                  active
                  onClick={() => this.createApplication('register', false)}
                >
                  {tra('Save_Close')}
                </CustomButton>
                <CustomButton
                  active
                  onClick={() => this.handleSubmitToFromViewDetail()}
                >
                  {tra('Save_View')}
                </CustomButton>
              </ActionFooter>
            </GridPageWrapper>
          </div>
        </PageContent>
        <InventoryDocumentModal
          open={InventoryPageState.openModalInventoryDetail}
          close={() => setInventoryPageState({
            ...InventoryPageState,
            openModalInventoryDetail: false,
          })}
          id={match.params.type}
          type={match.params.type === 'register' ? 'register' : 'draft'}
          setFileArr={(files) => this.setState({ fileArr: files })}
          setFileUrlArr={(fileUrl) => this.setState({ fileUrlArr: fileUrl })}
          uploadFile={this.props.uploadAppDraftFile}
        />
      </PageWrapper>
    );
  }
}

InventoryRegister.propTypes = {
  createApplication: PropTypes.func.isRequired,
  platforms: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  processes: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  setResetState: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  createAppWithFile: PropTypes.func.isRequired,
  setInventoryRegisterFiles: PropTypes.func.isRequired,
  setInventoryRegisterFileUrls: PropTypes.func.isRequired,
  inventoryRegisterFiles: PropTypes.array.isRequired,
  inventoryRegisterFileUrls: PropTypes.array.isRequired,
  scopes: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  legalOwners: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  userBases: PropTypes.array.isRequired,
  applicationDraft: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getApplicationDraft: PropTypes.func.isRequired,
  updateApplicationDraft: PropTypes.func.isRequired,
  uploadAppDraftFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  dataPlans: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired,
  sendToDetailID: PropTypes.number.isRequired,
  setSendToDetailPageID: PropTypes.func.isRequired,
  sendToView: PropTypes.bool.isRequired,
  setSendToView: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  departments: selectInfos('Departments'),
  locations: selectInfos('Locations'),
  platforms: selectInfos('Platforms'),
  vendors: selectInfos('Vendors'),
  tags: selectInfos('Tags'),
  processes: selectInfos('Processes'),
  scopes: selectInfos('Scopes'),
  categories: selectInfos('Categories'),
  legalOwners: selectInfos('LegalOwners'),
  userBases: selectInfos('UserBases'),
  plans: selectInfos('Plans'),
  dataPlans: selectInfos('DataPlans'),
  applications: selectApplications(),
  users: selectUsers(),
  resetState: selectResetState(),
  inventoryRegisterFiles: selectInventoryRegisterFiles(),
  inventoryRegisterFileUrls: selectInventoryRegisterFileUrls(),
  applicationDraft: selectApplicationDraft(),
  sendToDetailID: selectSendToDetailPageID(),
  sendToView: selectSendToView(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createApplication,
      setResetState,
      // uploadAppFile,
      createAppWithFile,
      setInventoryRegisterFiles,
      setInventoryRegisterFileUrls,
      getApplicationDraft,
      updateApplicationDraft,
      uploadAppDraftFile,
      deleteFile,
      setSendToDetailPageID,
      setSendToView,
      setToast,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryRegister);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTheme } from 'styled-components';
import {
  Switch, Fade, MenuItem, Menu,
} from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { withRouter } from 'react-router-dom';
import {
  MobileDrawerMenu, MobileMenuItem, CustomButton,
} from '../assets/styledNew';
import {
  Home, Cloud, Settings, Exit, File, Light, IntegrationIcon, Moon, Earth, Notification, AppSetting, HardwareIcon, Coin, BarGraph, LicenseFollowIcon, // Calendar,
} from '../assets/icons';
import { Colors } from '../assets/statics';
import {
  setLoginState,
  startRequiresServices,
  getApplications,
  getIntegrationComponents,
  getIntegration,
  getUsers,
  getSurveyList,
  getAdminCompanies,
  setAppConfig,
} from '../store/actions';
import { selectActiveUser, selectAppConfig, selectUserModule } from '../store/selectors';
import { isUserModule, pageShow, tra } from '../commons/utils';
import moduleType from '../commons/enum/moduleType';

// let root = '/';

class MobileDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      companyMenu: false,
      languageMenu: false,
      languageAnchorEl: null,
      companyAnchorEl: null,
      lang: {
        genelBakis: tra('Genel_Bakis'),
        envanter: tra('Envanter'),
        raporlar: tra('Raporlar'),
        takvim: tra('Takvim'),
        bildirimler: tra('Bildirimler'),
        anketler: tra('Anketler'),
        ayarlar: tra('Ayarlar'),
        koyuMod: tra('Koyu_Moda_Gec'),
        secilenDil: tra('Secilen_Dil'),
        cikisYap: tra('Cikis_Yap'),
        entegrasyon: tra('Entegrasyon'),
        kullaniciAyarlari: tra('Kullanici_Ayarlari'),
        profil: tra('Profil'),
        donanim: tra('Donanim'),
        masraf: tra('Masraf'),
        lisansTakibi: tra('Lisans_Takibi'),
      },
    };
  }

  logout = () => {
    localStorage.removeItem('token');
    this.props.setLoginState(true);
    this.props.drawerClose();
  }

  selectCompany = (id) => {
    localStorage.setItem('company', id);
    this.props.getApplications();
    this.props.getIntegrationComponents();
    this.props.getIntegration();
    this.props.getUsers();
    this.props.getSurveyList();
    this.props.getAdminCompanies();
    this.props.startRequiresServices();
    this.setState({ companyAnchorEl: null, companyMenu: false });
    this.props.drawerClose();
  }

  selectLanguage = (id) => {
    localStorage.setItem('language', id);
    // this.props.startRequiresServices();
    this.setState({ languageAnchorEl: null, languageMenu: false });
    window.location.reload();
    this.props.drawerClose();
  }

  handleMenuSelect = (index) => {
    this.setState({ active: index });
    this.props.drawerClose();
  }

  handleDarkmode = () => {
    const theme = localStorage.getItem('theme');
    if (theme !== null) {
      if (theme === 'dark') {
        this.props.setAppConfig('theme', 'light');
        localStorage.setItem('theme', 'light');
      } else {
        localStorage.setItem('theme', 'dark');
        this.props.setAppConfig('theme', 'dark');
      }
    } else {
      localStorage.setItem('theme', 'dark');
      this.props.setAppConfig('theme', 'dark');
    }
  }

  handleEventProps = (arg, callback) => {
    if (callback) callback();
    if (!Array.isArray(arg)) {
      const [key, value] = [Object.keys(arg).toString(), ...Object.values(arg)];
      this.props.setAppConfig(key, value);
    } else {
      this.props.setAppConfig(...arg);
    }
  }

  isUserModuleFunc = (modulTypeArr) => isUserModule(modulTypeArr)(this.props.initModule);

  render() {
    const {
      active, lang, companyMenu, companyAnchorEl, languageMenu, languageAnchorEl, // darkMode,
    } = this.state;
    const { activeUser } = this.props;
    const themeColor = this.props.theme;

    const activeComp = localStorage.getItem('company');
    const selectedComp = Object.keys(activeUser).length > 0 && activeUser.companies && activeUser.companies.find((item) => item.id.toString() === activeComp);
    const isAdmin = selectedComp !== undefined ? (selectedComp.permission === 1) : false;
    const menus = [
      {
        title: lang.genelBakis,
        icon: (route) => (<Home color={pageShow.HOME === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.HOME,
      },
      {
        title: lang.envanter,
        icon: (route) => (<Cloud color={pageShow.INVENTORY === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.INVENTORY,
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        title: lang.masraf,
        icon: (route) => (<Coin color={pageShow.COSTING === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Costing',
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE) && !this.isUserModuleFunc(moduleType.HARDWARE),
      },
      {
        title: lang.donanim,
        icon: (index) => (<HardwareIcon color={active === index ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.HARDWARE_INVENTORY,
        disabled: !this.isUserModuleFunc(moduleType.HARDWARE),
      },
      {
        title: lang.raporlar,
        icon: (route) => (<BarGraph color={pageShow.HOME === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.REPORTS,
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE) && !this.isUserModuleFunc(moduleType.HARDWARE),
      },
      {
        title: lang.entegrasyon,
        icon: (route) => (<IntegrationIcon color={pageShow.INTEGRATION === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.INTEGRATION,
        disabled: !this.isUserModuleFunc(moduleType.INTEGRATION),
      },
      // {
      //   title: lang.takvim,
      //   icon: (route) => (<Calendar color={pageShow.SCHEDULE === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
      //   link: pageShow.SCHEDULE,
      //   disabledd: true,
      // },
      {
        title: lang.bildirimler,
        icon: (route) => (<Notification color={pageShow.NOTIFICATION === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Notification',
      },
      {
        title: lang.lisansTakibi,
        icon: (route) => (<LicenseFollowIcon color={pageShow.LICENSE_FOLLOW === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/LicenseFollow',
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        title: lang.anketler,
        icon: (route) => (<File color={pageShow.SURVEY === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.SURVEY,
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
    ];
    if (isAdmin) {
      menus.push({
        title: lang.ayarlar,
        icon: (route) => (<Settings color={pageShow.ADMİN === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.ADMİN,
      });
      menus.push({
        title: lang.kullaniciAyarlari,
        icon: (route) => (<AppSetting color={pageShow.PROFIL_SETTİNG === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.PROFIL_SETTİNG,
      });
    } else {
      menus.push({
        title: lang.kullaniciAyarlari,
        icon: (route) => (<AppSetting color={pageShow.PROFIL_SETTİNG === route ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: pageShow.PROFIL_SETTİNG,
      });
    }
    return (
      <MobileDrawerMenu>
        <div className="header">
          {
            activeUser && activeUser.user_name && (
              <>
                <img src={`${activeUser.profile_img}?v=${new Date().getTime()}`} alt={activeUser.name} />
                <p className="userName">{activeUser.name}</p>
                {
                  activeUser.companies && activeUser.companies.find((c) => c.id.toString() === localStorage.getItem('company'))
                  && (
                    <>
                      <CustomButton transparent aria-controls="companyMenu" aria-haspopup="true" onClick={(event) => this.setState({ companyAnchorEl: event.currentTarget, companyMenu: true })}>
                        {activeUser.companies.find((c) => c.id.toString() === localStorage.getItem('company')).name}
                        <ExpandMoreOutlinedIcon />
                      </CustomButton>

                      <Menu
                        id="companyMenu"
                        anchorEl={companyAnchorEl}
                        keepMounted
                        open={companyMenu}
                        onClose={() => this.setState({ companyAnchorEl: null, companyMenu: false })}
                        TransitionComponent={Fade}
                      >
                        {
                          activeUser.companies.map((item) => <MenuItem onClick={() => this.selectCompany(item.id)}>{item.name}</MenuItem>)
                        }
                      </Menu>
                    </>
                  )
                }
              </>
            )
          }
        </div>
        <div className="content">
          {
            menus.map((m, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MobileMenuItem key={`mobileMenuItem${index}`} disabled={m.disabled} to={m?.disabled ? false : m.link} active={active === index} onClick={() => this.handleMenuSelect(index)}>
                {m.icon(this.props.history.location.pathname)}
                <p>{m.title}</p>
              </MobileMenuItem>
            ))
          }
        </div>
        <div className="footer">
          <div style={{ padding: '10px 0px' }}>

            <div>
              <CustomButton transparent aria-controls="languageMenu" aria-haspopup="true" onClick={(event) => this.setState({ languageAnchorEl: event.currentTarget, languageMenu: true })}>
                <Earth color={themeColor.sidebarIcon} />
                {localStorage.getItem('language').toLocaleUpperCase()}
                <ExpandMoreOutlinedIcon />
              </CustomButton>

              <Menu
                id="languageMenu"
                anchorEl={languageAnchorEl}
                keepMounted
                open={languageMenu}
                onClose={() => this.setState({ languageAnchorEl: null, languageMenu: false })}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => this.selectLanguage('tr')}>TR</MenuItem>
                <MenuItem onClick={() => this.selectLanguage('en')}>EN</MenuItem>
              </Menu>
            </div>

            <Switch
              checked={Boolean(this.props.appConfig.theme !== 'light')}
              onChange={() => this.handleDarkmode()}
              color="default"
              name="darkModeSwitch"
              checkedIcon={<Moon color={Colors.white} />}
              icon={<Light />}
            />
          </div>
          <MobileMenuItem to="/" onClick={this.logout}>
            <Exit color={themeColor.sidebarIcon} />
            <p>{lang.cikisYap}</p>
          </MobileMenuItem>
        </div>
      </MobileDrawerMenu>
    );
  }
}

MobileDrawer.propTypes = {
  // toggle: PropTypes.func.isRequired,
  setLoginState: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  startRequiresServices: PropTypes.func.isRequired,
  drawerClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getApplications: PropTypes.func.isRequired,
  getIntegrationComponents: PropTypes.func.isRequired,
  getIntegration: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getSurveyList: PropTypes.func.isRequired,
  getAdminCompanies: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  setAppConfig: PropTypes.func.isRequired,
  initModule: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activeUser: selectActiveUser(),
  appConfig: selectAppConfig(),
  initModule: selectUserModule(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setLoginState,
      startRequiresServices,
      getApplications,
      getIntegrationComponents,
      getIntegration,
      getUsers,
      getSurveyList,
      getAdminCompanies,
      setAppConfig,
    }, dispatch)
  )
);

const MobileDrawerWithTheme = withTheme(MobileDrawer);
const MobileDrawerWithRouter = withRouter(MobileDrawerWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(MobileDrawerWithRouter);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
// import { Link } from 'react-router-dom';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // MenuItem,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Input from '../../components/Input';
import Table from '../../components/Table';
import { Colors, SheetWithModalEnum, hardwareUnitSelect } from '../../assets/statics';
import {
  CustomButton, CustomDrawer, TableWrapper, PageWrapper, FilteredSidebar, PageContent, ComponentLoader,
  // PageHeader, CustomMenu, Placeholder,
} from '../../assets/styledNew';
import {
  // CloudImport, CloudExport, CirclePlus,
  LeftArrow,
} from '../../assets/icons';
import { FormatUTCLocaleDateStr, tra } from '../../commons/utils';
import {
  getApplications, getApplicationDetail, setFilterDrawer, excelDownload, excelUpload, setAppConfig, getHardwareDeviceDetail,
  getHardwareDevices, getHardwareModalTypes, getHardwareModalCategory, getInfoHardware,
} from '../../store/actions';
import {
  selectAppConfig, selectApplications, selectFilterDrawer, selectInfos, selectUsers, selectHardwareDeviceDetail, selectHardwareDevices, selectHardwareModalCategory, selectHardwareModalTypes,
} from '../../store/selectors';

import NewHardwareInventory from '../../components/modals/NewHardwareInventory';
// import { hwaLang } from '../../assets/hardwareStatic';

class HardwareInventories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: JSON.parse(localStorage.getItem('hardwareFilter')) || [],
      excelMenu: null,
      lang: {
        filtre: tra('Filtre'),
        durum: tra('Durum'),
        donanimTipi: tra('Donanim_Tipi'),
        donanimKategorisi: tra('Donanim_Kategorisi'),
        marka: tra('Marka'),
        model: tra('Model'),
        satinAlmaTarihi: tra('Satin_Alma_Tarihi'),
        fiyat: tra('Fiyat'),
        lokasyon: tra('Lokasyon'),
        statu: tra('Statu'),
        sahip: tra('Sahip'),

        geri: tra('Geri'),
        yeniEnvanter: tra('Yeni_Envanter'),
        iceAktar: tra('Ice_Aktar'),
        disaAktar: tra('Disa_Aktar'),
        ornekIndir: tra('Ornek_Indir'),
        sifirla: tra('Sifirla'),
        envanterSayisi: tra('Envanter_Sayisi'),
        uygula: tra('Uygula'),
      },
      drawer: true,
      tabValue: 0,
    };
    props.getHardwareDevices();
    props.getHardwareModalTypes();
    props.getInfoHardware();
    this.hiddenInput = React.createRef();
  }

  componentWillUnmount() {
    this.props.setFilterDrawer(false);
  }

  handleWithStateProps = (data, callback) => {
    const [key, value] = [Object.keys(data).toString(), ...Object.values(data)];
    this.props.setAppConfig(key, value);
    this.setState({ [key]: value });
    if (callback) callback();
  }

  getArrFilters = (itemName, arrayPath, count, countKey) => {
    const { devices } = this.props;
    const data = [];
    for (let i = 0; i < devices.length; i++) {
      for (let j = 0; j < devices[i][arrayPath].length; j++) {
        data.push(devices[i][arrayPath][j][itemName]);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  }

  changeFilter = (state, value, text, type, label, header) => {
    const { filter } = this.state;
    if (type === 'resetAll') {
      this.setState({ filter: [] });
      const localFilter = JSON.stringify([]);
      localStorage.setItem('hardwareFilter', localFilter);
    } else {
      const stateName = state.replace(/[0-9]/g, '');
      let oldValue = false;
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state);
        filter.splice(index, 1);
      }
      if (type === 'select') {
        oldValue = this.state.filter.find((item) => item.column === stateName);
      } else {
        oldValue = this.state.filter.find((item) => item.column === stateName && item.value === text);
      }
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value) {
        filter.push({
          column: stateName, value: text, label, header,
        });
      }
      this.setState({
        filter,
        [state]: type === 'select' ? text : value,
      });
      const localFilter = JSON.stringify(filter);
      localStorage.setItem('hardwareFilter', localFilter);
    }
  }

  excelDownlaod = (type, fileName) => {
    // uploadAppFile = (e) => {
    //   const { files } = this.state;
    //   files.push(e.target.files[0]);
    //   this.setState({ files });
    // }
    this.props.excelDownload(type, fileName);
    this.setState({ excelMenu: null });
  }

  excelUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.props.excelUpload(e.target.files);
    }
    this.setState({ excelMenu: null });
  }

  handleExcelMenu = (e) => {
    this.setState({ excelMenu: e.currentTarget });
  }

  render() {
    const {
      filterDrawer, devices, locations, situations, hardwareType, history,
    } = this.props;
    const { filter, lang } = this.state;
    const { hardwareInventoryModalEnum } = SheetWithModalEnum;
    const columns = [
      {
        name: 'is_removed',
        text: lang.durum,
        editable: false,
        align: 'center',
        sortable: true,
        customBody: (value) => (
          <div style={{
            width: 10,
            height: 10,
            borderRadius: '100%',
            background: value === 1 ? Colors.red : Colors.green,
            margin: '0 auto',
          }}
          />
        ),
      },
      {
        name: 'id',
        text: 'ID',
        sortable: true,
        filterable: false,
        type: 'text',
        searchable: true,
      },
      {
        name: 'hardware_types_name_id',
        text: lang.donanimTipi,
        // sortable: true,
        // filterable: true,
        type: 'text',
        tooltip: false,
        searchable: true,
        filterLocation: 'hardwareType',
        filterable: true,
        customBody: (value) =>
          ((value && hardwareType && hardwareType.find((item) => item.id === value)) && hardwareType.find((item) => item.id === value).name),
      },
      {
        name: 'hardware_category_name',
        text: lang.donanimKategorisi,
        sortable: true,
        tooltip: true,
        searchable: true,
        // customBody: (value) => (
        //   hardwareUnitSelect.find((item) => item.id === value) && hardwareUnitSelect.find((item) => item.id === value).name
        // ),
      },
      {
        name: 'hardware_producer',
        text: lang.marka,
        sortable: true,
        type: 'text',
        filterable: true,
        searchable: true,
      },
      {
        name: 'model',
        text: lang.model,
        sortable: true,
        type: 'text',
        filterable: false,
        searchable: true,
        filterLocation: 'departments',
        // customBody: (value) => (
        //   departments.find((item) => item.id === value) && departments.find((item) => item.id === value).name
        // ),
      },
      {
        name: 'first_usage_date',
        text: lang.satinAlmaTarihi,
        sortable: true,
        type: 'text',
        // filterLocation: 'locations',
        searchable: true,
        filterable: false,
        customBody: (value) => (
          FormatUTCLocaleDateStr(value)
        ),
      },
      {
        name: 'cost',
        text: lang.fiyat,
        sortable: true,
        type: 'text',
        filterable: false,
        searchable: true,
        // filterLocation: 'platforms',
        // customBody: (value) => (
        //   platforms.find((item) => item.id === value) && platforms.find((item) => item.id === value).name
        // ),
      },
      {
        name: 'location_id',
        text: lang.lokasyon,
        // arrayPath: 'locations',
        type: 'text',
        searchable: true,
        sortable: true,
        filterable: true,
        filterLocation: 'locations',
        customBody: (value) => (
          locations.find((item) => item.id === value) && locations.find((item) => item.id === value).name
        ),
      },
      {
        name: 'situation',
        text: lang.statu, // inputlardan kaldırılmıştı
        searchable: true,
        filterable: true,
        sortable: true,
        filterLocation: 'situations',
        // arrayPath: 'situations',
        customBody: (value) => (
          (value && situations && situations.find((item) => item.id === value)) && situations.find((item) => item.id === value).name
        ),
      },
      {
        name: 'owner',
        text: lang.sahip,
        type: 'text',
        filterable: true,
        searchable: true,
      },
    ];
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomDrawer
          anchor="left"
          open={filterDrawer}
          {...(!isWidthDown('sm', this.props.width)
            ? { variant: 'persistent' }
            : {
              onClose: () => this.props.setFilterDrawer(false),
              onOpen: () => this.props.setFilterDrawer(true),
            })}

        >
          <FilteredSidebar>
            <div className="title">
              {lang.filtre}
              {!isWidthDown('sm', this.props.width)
                && <CustomButton onClick={() => this.props.setFilterDrawer(false)} startIcon={<LeftArrow width={12} />}>{lang.geri}</CustomButton>}
            </div>
            <div className="filterContent">
              {
                columns.map((item, index) => (
                  item.filterable && (
                    item.type === 'select'
                      ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Input
                              type="select"
                              fullWidth
                              data={item.selectData}
                              value={this.state[item.name]}
                              onChange={(e) => this.changeFilter(item.name, true, e.target.value, 'select', e.target.value, item.text)}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )
                      : item.type === 'array'
                        ? (
                          <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <p>{item.text}</p>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                              {
                                this.getArrFilters(item.name, item.arrayPath).map((arrFilter, count) => (
                                  <Input
                                    type="checkbox"
                                    title={`${arrFilter} (${this.getArrFilters(item.name, item.arrayPath, true, arrFilter)})`}
                                    value={filter.find((a) => a.value === arrFilter) !== undefined}
                                    onChange={(e) => this.changeFilter(`${item.arrayPath}.${item.name}${index + 1 * count + 1}`, e.target.checked, arrFilter, 'checkbox', arrFilter, item.text)}
                                  />
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        )
                        : item.filterLocation
                          ? (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  devices && [...new Set(devices.map((items) => items[item.name]))].map((licence, count) => (
                                    <Input
                                      type="checkbox"
                                      title={`${this.props[item.filterLocation].find((f) => f.id === licence) && this.props[item.filterLocation].find((f) => f.id === licence).name} (${devices.filter((fltr) => fltr[item.name] === licence).length})`}
                                      value={filter.find((a) => (a.column === item.name) && (a.value === licence)) !== undefined}
                                      onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', this.props[item.filterLocation].find((f) => f.id === licence).name, item.text)}
                                    />
                                  ))
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                          : (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  devices && [...new Set(devices.map((items) => items[item.name]))].map((licence, count) => (
                                    <Input
                                      type="checkbox"
                                      title={`${licence} (${devices.filter((fltr) => fltr[item.name] === licence).length})`}
                                      value={filter.find((a) => a.value === licence) !== undefined}
                                      onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', licence, item.text)}
                                    />
                                  ))
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                  )
                ))
              }
            </div>
            <div className="filterDrawefooter">
              {devices && <p><span>{`(${devices.length})`}</span> {lang.envanterSayisi}</p>}
              <CustomButton onClick={() => this.props.setFilterDrawer(false)}>{lang.uygula}</CustomButton>
              <CustomButton transparent onClick={() => this.changeFilter(null, null, null, 'resetAll')}>{lang.sifirla}</CustomButton>
            </div>
          </FilteredSidebar>
        </CustomDrawer>
        <PageWrapper drawer={!isWidthDown('sm', this.props.width) && filterDrawer}>
          {/* <PageHeader style={{ justifyContent: 'flex-end' }}>
            <h1>{lang.envanter}</h1>
            <div className="buttonGroup">
              <div>
                <CustomButton
                  onClick={(e) => this.handleExcelMenu(e)}
                  startIcon={<CloudExport width={20} />}
                >Excel
                </CustomButton>
                <CustomMenu
                  anchorEl={this.state.excelMenu}
                  open={Boolean(this.state.excelMenu)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  onClose={() => this.setState({ excelMenu: null })}
                >
                  <>
                    <MenuItem onClick={() => this.hiddenInput.current.click()}><CloudImport />{lang.iceAktar}</MenuItem>
                    <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.excelUpload(e)} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
                  </>
                  <MenuItem onClick={() => this.excelDownlaod('', 'Envanter Listesi')}><CloudExport />{lang.disaAktar}</MenuItem>
                  <MenuItem onClick={() => this.excelDownlaod('/sample', 'Sample')}><CloudExport />{lang.ornekIndir}</MenuItem>
                </CustomMenu>
              </div>
              <Link to="/InventoryRegister"><CustomButton startIcon={<CirclePlus color={Colors.white} />}>{lang.yeniEnvanter}</CustomButton></Link>
            </div>
          </PageHeader>  */}
          <PageContent>
            <div className="pageInner" style={{ paddingTop: 0 }}>
              <Grid container spacing={3} style={{ paddingTop: 0 }}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                  <TableWrapper style={{ minHeight: '60vh' }}>
                    {
                      devices ? (
                        <Table
                          id="hardwareInventoryTable"
                          data={[...devices]}
                          // searchFieldNotFilteredData={false}
                          rowClick={(row) => {
                            // this.props.getHardwareDeviceDetail(Number(row.id));
                            history.push(`/HardwareDetail/${row.id}`);
                          }}
                          columns={columns}
                          options={{
                            header: {
                              visible: true,
                              columnSetting: true,
                            },
                            filter: {
                              show: true,
                              filters: filter,
                              onChange: this.changeFilter,
                              drawerChange: () => this.props.setFilterDrawer(!filterDrawer),
                            },
                            pagination: {
                              active: true,
                            },
                          }}
                        />
                      ) : (
                        <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                      )
                    }
                  </TableWrapper>
                </Grid>
              </Grid>
            </div>
          </PageContent>
        </PageWrapper>
        <NewHardwareInventory
          // open
          open={Boolean(this.props.appConfig[hardwareInventoryModalEnum.NEW_HARDWARE])}
          close={() => this.props.setAppConfig(hardwareInventoryModalEnum.NEW_HARDWARE, false)}
          history={this.props.history}
        />
      </div>
    );
  }
}

HardwareInventories.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  // getApplications: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  // applications: PropTypes.array.isRequired,
  // vendors: PropTypes.array.isRequired,
  // getApplicationDetail: PropTypes.func.isRequired,
  getHardwareDeviceDetail: PropTypes.func.isRequired,
  getHardwareModalCategory: PropTypes.func.isRequired,
  getHardwareModalTypes: PropTypes.func.isRequired,
  getHardwareDevices: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  situations: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  filterDrawer: PropTypes.bool.isRequired,
  setFilterDrawer: PropTypes.func.isRequired,
  excelDownload: PropTypes.func.isRequired,
  history: PropTypes.string.isRequired,
  excelUpload: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  devices: PropTypes.array.isRequired,
  hardwareType: PropTypes.array.isRequired,
  getInfoHardware: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  // departments: selectInfos('Departments'),
  // locations: selectInfos('Locations'),
  // platforms: selectInfos('Platforms'),
  // vendors: selectInfos('Vendors'),
  // users: selectUsers(),

  //--------
  appConfig: selectAppConfig(),
  filterDrawer: selectFilterDrawer(),
  departments: selectInfos('Departments'),
  locations: selectInfos('Locations'),
  currencies: selectInfos('Currencies'),
  producers: selectInfos('HardwareProducers'),
  memories: selectInfos('MemoryTypes'),
  situations: selectInfos('Situations'),
  OSPlatforms: selectInfos('OSPlatforms'),
  users: selectUsers(),
  apps: selectApplications(),
  devices: selectHardwareDevices(),
  devicesDetail: selectHardwareDeviceDetail(),
  hardwareType: selectHardwareModalTypes(),
  hardwareCategory: selectHardwareModalCategory(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getApplications,
      // getApplicationDetail,
      setFilterDrawer,
      excelDownload,
      excelUpload,
      setAppConfig,

      //-------
      getHardwareDeviceDetail,
      getHardwareDevices,
      getHardwareModalCategory,
      getHardwareModalTypes,
      getInfoHardware,
    }, dispatch)
  )
);

const HardwareInventoriesWithWidth = withWidth()(HardwareInventories);

export default connect(mapStateToProps, mapDispatchToProps)(HardwareInventoriesWithWidth);

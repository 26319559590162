import styled from 'styled-components';
import { scrSize } from '../commons/utils';
import { Colors } from './statics';
// import { hexToRgbA, scrSize } from '../commons/utils';
// import {HeaderBottomSheet} from '../components/BottomSheet';
export const CalendarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.theme.boxBackg};
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
`;
export const DayBox = styled.div`
  border-radius: 20px;
  flex: 0 0 12%;
  height: 100%;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  margin: 10px;
  cursor: ${(props) => !props.otherMonth && 'pointer'};
  background-color: ${(props) => props.theme.calendarDayBox};
  ${(props) => props.today && `
    transform: scale(1.12);
    filter: drop-shadow(0px 26px 36px rgba(18, 33, 96, 0.2));
    z-index: 1;
    border: 0.5px solid ${props.theme.todayBorder};
  `}
  ${(props) => props.otherMonth && 'filter: brightness(0.80);'}
  .dayNumber {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.text1};
    z-index: 99;
    font-size: 23px;
    font-weight: 700;
  }
  
`;

export const EventDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 20px;
  padding: 0 0 5px 10px;
  :before {
    content: "";
    position: absolute;
    left: -5px;
    height: 90%;
    width: 3px;
    border-radius: 5.52846px;
    background: ${(props) => props.statu && props.statu};
  }
  p {
    color: ${(props) => props.theme.text1};
    font-weight: 700;
    font-size: 14px;
    height: 17px;
    overflow: hidden;
  }
  span {
    color: ${(props) => props.theme.text2};
    font-weight: 300;
    font-size: 12px;
    height: 15px;
    overflow: hidden;
    display: block;
  }
`;

export const CalendarHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.text1};
  background: ${(props) => props.theme.boxBackg};
  padding: 20px 25px 15px 25px;
  .date {
    color: ${(props) => props.theme.text1};
    font-weight: 700;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 40px;
      color: ${Colors.blue};
    }
  }
  svg {
    margin: 0 10px;
    cursor: pointer;
  }
`;

export const CalendarTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${DayBox} {
    background-color: transparent;
    height: 25px;
    max-height: 25px;
    min-height: 25px;
    border-radius: 0;
    cursor: default;
    p {
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.text3};
      text-align: center;
    }
  }
`;

export const DayEvents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const DayEventsItem = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: ${(props) => (props.color || Colors.darkBlackBlue)};
  border: 2px solid ${Colors.lightGrey800};
  transform: ${(props) => props.index && `translate(${props.index * 10}px, 0px)`};
  z-index: ${(props) => 99 - props.index};
`;

export const AwasomeSwitch = styled.div`
  background: ${(props) => props.theme.calendarDayBox};
  border: 1px solid ${(props) => props.theme.calendarDayBox};  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  border-radius: 16px;
  position: relative;
`;

export const AwasomeSwitchItem = styled.div`
  padding: 10px 25px;
  z-index: 2;
  cursor: pointer;
  width: 35px;
  text-align: center;
`;

export const AwasomeSwitchIndicator = styled.div`
  cursor: default!important;
  z-index: 1;
  position: absolute;
  left: ${(props) => (100 / props.total) * props.active}%;
  top: 0;
  background: ${(props) => props.theme.calendarSwitchItemButton};
  box-shadow: 0px 6px 20px ${(props) => props.theme.shadow};
  border-radius: 16px;
  width: ${(props) => 100 / props.total}%;
  height: 100%;
  padding: 0;
  transition: all .5s cubic-bezier(0.31, 0.67, 0.44, 1.05);
`;

export const WeekView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;
  
`;

export const WeekDay = styled.div`
  ${(props) => props.today && 'filter: drop-shadow(0px 13.26px 12.24px rgba(18, 33, 96, 0.1));'}
  height: 75px;
  flex: 1;
  display: flex;
  margin-bottom: 15px;
  cursor: pointer;
  > span {
    font-weight: 600;
    width: 150px;
    min-width: 150px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.text3}
  }
  .weekEvent {
    border-radius: 10px;
    background: ${(props) => props.theme.calendarDayBox};
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 50px;
    min-height: 25px;
    max-width: calc(100% - 150px);
    .weekScroller {
      display: flex;
      align-items: center;
      max-width: calc(100% - 65px);
      overflow-x: auto;
      overflow-y: hidden;
      height: 100%;
      width: 100%;
    }
    span {
      position: absolute;
      left: 15px;
      top: 10px;
      font-weight: 600;
      font-size: 22px;
      font-weight: 600;
      color: ${(props) => props.theme.text3};
    }
    ${EventDetails} {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      margin-right: 15px;
      min-width: 125px;
      span {
        font-size: 16px;
        font-weight: 600;
        color: ${(props) => props.theme.text3};
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
`;

export const YearView = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  width: 100%;
  .month {
    margin-bottom: 15px;
    flex: 0 0 25%;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    > span {
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.text3};
      width: 100%;
      padding: 0 15px;
    }
    ${DayBox} {
      min-height: 50px;
      height: 50px;
      margin: 2px;
      border-radius: 3px;
      display: flex;
      align-items: flex-end;
      p {
        font-size: 10px;
        font-weight: 600;
      }
      .dayNumber {
        top: 5px;
        left: auto;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        width: 100%;
        height: fit-content;
      }
      ${DayEvents} {
        position: relative;
        top: auto;
        right: auto;
        bottom: 4px;
        ${DayEventsItem} {
          width: 15px;
          height: 15px;
          min-width: 15px;
          min-height: 15px;
        }
      }
    }
    ${CalendarTop} {
      ${DayBox} {
        height: 20px;
        min-height: 20px;
      }
    }
  }
`;
export const CalendarWrapperMobile = styled.div`
  margin: 10px auto;
  .calendarHeader {
    color: ${(props) => props.theme.text1};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    position: relative;
    svg {
        width: 32px;
        height: auto;
        color: ${Colors.blue};
        cursor: pointer;
      }
    .datePickerButton {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 10px;
      padding: 0;
      
    }
    .monthTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: ${(props) => props.theme.text1};
    }
  }
`;

export const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, auto);
  scroll-snap-align: center;
  grid-gap: 5px;
    .weeks {
      display: inline-flex;
      color: ${(props) => props.theme.text1};
      justify-content: center;
      align-items: flex-end;
      margin: 0;
      height: auto;
      font-weight: bold;
    }
  @media ${scrSize('sm', 'md')} {
    grid-gap: 10px;
  }
`;

export const DayCell = styled.div`
  display: flex;
  color: ${(props) => props.theme.text1};
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  aspect-ratio: 1;
  min-height: 45px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px 0px ${(props) => props.theme.shadow35};
  cursor: ${(props) => !props.otherMonth && 'pointer'};
  background-color: ${(props) => props.theme.calendarDayBox};
  ${(props) => props.today && `
    transform: scale(1.12);
    filter: drop-shadow(0px 26px 36px rgba(18, 33, 96, 0.2));
    z-index: 1;
  `}
  ${(props) => props.otherMonth && 'filter: brightness(0.80);'}
  .dayEventDetailDotted {
    color: ${(props) => props.theme.blueText};
    font-weight: bold;
    position: absolute;
    margin: 0;
    right: -4%;
    top: -4%;
    font-size: 0.8rem;
    border-radius: 28px;
    width: 5vw;
    height: 5vw;
    box-sizing: border-box;
    padding: 5px;
  }
  @media ${scrSize('xs')} {
  border-radius: 20px;
  }
`;

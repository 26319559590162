/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem, TableBody, TableHead, Tooltip,
} from '@material-ui/core';
import {
  ExpandLess, ExpandMore, Search, UnfoldMore,
} from '@material-ui/icons';
import ExpandTable, {
  ExpandableRow, ExpandTableTh, ExpandTableTr, TableTh, TableTr, ExpandTableTd, TableTd, CustomCollapse, ExpandTableTdWithoutHead,
} from '../../assets/styledExpandTable';
import { FormatCurrency, FormatUTCLocaleDateStr, tra } from '../../commons/utils';
import Input from '../../components/Input';
import { CustomButton, CustomMenu } from '../../assets/styledNew';
import { getDepartmentReport } from '../../store/actions';

const ExpandRowSec = ({ item, opened, currency }) => {
  const [open, setOpen] = useState(false);

  return useMemo(() => (
    <>
      <ExpandableRow>
        <ExpandTableTh style={{ background: '#F6F8FB' }} colSpan={1} />
        <ExpandTableTh style={{ background: '#E6ECF4' }} colSpan={4}>
          <CustomCollapse in={opened}>
            <ExpandTable>
              <TableBody>
                <ExpandTableTr hover onClick={() => { setOpen(!open); }}>
                  <ExpandTableTdWithoutHead textAlign="start">{item.name}</ExpandTableTdWithoutHead>
                  <ExpandTableTdWithoutHead textAlign="end">{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTdWithoutHead>
                </ExpandTableTr>
                <ExpandableRow>
                  <ExpandTableTh colSpan={5}>
                    <CustomCollapse in={open}>
                      <div
                        style={{
                          background: 'white',
                          padding: 20,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                        }}
                      >
                        <ExpandTable>
                          <TableHead>
                            <TableTh style={{ textAlign: 'start' }}>{tra('Masraf_Tipi')}</TableTh>
                            <TableTh style={{ textAlign: 'start' }}>{tra('Kategori')}</TableTh>
                            <TableTh style={{ textAlign: 'start' }}>{tra('Sozlesme')}</TableTh>
                            <TableTh style={{ textAlign: 'start' }}>{tra('Servis')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Percentage')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Vendor')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Baslangic_Tarihi')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Bitis_Tarihi')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Full_Period')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Cost')} {currency}</TableTh>
                          </TableHead>
                          <br />
                          <TableBody>
                            {item.Contracts.map((costRowItem) => (
                              <>
                                <TableTr fc={costRowItem.fc === 1}>
                                  <TableTd textAlign="start">{costRowItem.costing_type}</TableTd>
                                  <TableTd textAlign="start">{costRowItem.costing_category}</TableTd>
                                  <Tooltip title={costRowItem.aggrement}>
                                    <TableTd style={{ textAlign: 'start' }}>{costRowItem.aggrement}</TableTd>
                                  </Tooltip>
                                  <Tooltip title={costRowItem.service}>
                                    <TableTd style={{ textAlign: 'start' }}>{costRowItem.service}</TableTd>
                                  </Tooltip>
                                  <TableTd textAlign="center">{costRowItem.allocation}%</TableTd>
                                  <TableTd textAlign="center">{costRowItem.vendor}</TableTd>
                                  <TableTd textAlign="center">{FormatUTCLocaleDateStr(costRowItem.contract_start)}</TableTd>
                                  <TableTd textAlign="center">{FormatUTCLocaleDateStr(costRowItem.contract_end)}</TableTd>
                                  <TableTd textAlign="center">{FormatCurrency(costRowItem.full_period)}</TableTd>
                                  <TableTd textAlign="center">{FormatCurrency(costRowItem.total_inv)}</TableTd>
                                </TableTr>
                                <br />
                              </>
                            ))}
                          </TableBody>
                        </ExpandTable>
                      </div>
                    </CustomCollapse>
                  </ExpandTableTh>
                </ExpandableRow>
              </TableBody>
            </ExpandTable>
          </CustomCollapse>
        </ExpandTableTh>
      </ExpandableRow>
    </>
  ), [opened, open, item]);
};

const ExpandingRow = ({ item, currency }) => {
  const [open, setOpen] = useState(false);
  return useMemo(() => (
    <>
      <ExpandTableTr hover onClick={() => setOpen(!open)}>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.name}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'start' }}>{FormatCurrency(item.total)}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'end' }}>{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTd>
      </ExpandTableTr>
      {open && <br />}
      {item.Applications.map((appRowItem) => (
        <>
          <ExpandRowSec item={appRowItem} opened={open} currency={currency} />
          {open && <br />}
        </>
      ))}
    </>
  ), [open, item]);
};

const DepartmentReport = ({ currency }) => {
  const depReports = useSelector((state) => state.departmentReport);
  const [searchText, setSearchText] = useState('');
  const [yearMenu, setYearMenu] = useState(null);
  const [tableYear, setTableYear] = useState(new Date().getFullYear());
  const [searchableList, setSearchableList] = useState([]);
  const dispatch = useDispatch();
  const contractYears = useSelector((state) => state.contractYears);

  useEffect(() => {
    if (depReports.length > 0) {
      const keys = Object.keys(depReports[0]);
      setSearchableList(keys);
    }
  }, [depReports]);

  const isTableData = (tableItem, searchVal) => {
    const allReturn = [];
    for (let i = 0; i < searchableList.length; ++i) {
      if (Array.isArray(tableItem[searchableList[i]])) {
        const subArrKeys = Object.keys(tableItem[searchableList[i]][0]);
        for (let k = 0; k < subArrKeys.length; ++k) {
          const arrayTempData = tableItem[searchableList[i]].map((item) => item[subArrKeys[k]]).toString().toUpperCase().includes(searchVal.toUpperCase());
          allReturn.push(arrayTempData);
        }
      } else { allReturn.push([tableItem[searchableList[i]]].toString().toUpperCase().includes(searchVal.toUpperCase())); }
    }

    return allReturn.some((e) => e);
  };

  const searchData = (data) => {
    if (searchText.length > 1 && data && searchableList) {
      const filteredData = data.filter((tableItem) => isTableData(tableItem, searchText));
      return filteredData;
    }
    return data;
  };

  const changeTableYear = (val) => {
    dispatch(getDepartmentReport(val));
    setTableYear(val);
    setYearMenu(null);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
        <Input startAdornment={<Search />} placeholder={tra('Arama_Yap')} type="text" onChange={(e) => setSearchText(e.target.value)} />
        <CustomButton
          opacity
          style={{ marginLeft: 20, height: 40 }}
          onClick={(e) => setYearMenu(e.currentTarget)}
          endIcon={<UnfoldMore />}
        >
          {tableYear}
        </CustomButton>
        <CustomMenu
          anchorEl={yearMenu}
          open={Boolean(yearMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => setYearMenu(null)}
        >
          {contractYears.map((year) => (
            <MenuItem onClick={() => changeTableYear(year)}>{year}</MenuItem>
          ))}
        </CustomMenu>
      </div>
      <ExpandTable>
        <TableHead>
          <TableTh style={{ textAlign: 'start' }}>{tra('Departman')}</TableTh>
          <TableTh style={{ textAlign: 'start' }}>{tra('Toplam')} {currency}</TableTh>
          <TableTh />
        </TableHead>
        <br />
        <TableBody>
          {searchData(depReports).map((itemRow) => (
            <>
              <ExpandingRow item={itemRow} currency={currency} />
              <br />
            </>
          ))}
        </TableBody>
      </ExpandTable>
    </>

  );
};

ExpandingRow.propTypes = {
  item: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

ExpandRowSec.propTypes = {
  item: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
};

DepartmentReport.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default DepartmentReport;

import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const deviceDetail = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'Cabinet',
    inputArray: [
      {
        type: 'text',
        title: tra('Isim'),
        name: 'name', // değişecek
        value: '1920 X 1080',
      },
      // {
      //   type: 'text',
      //   title: tra('Kabinet_Boyutu'),
      //   name: 'cabinet_size', // değişecek
      //   value: '1920 X 1080',
      // },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'text',
        title: tra('Kabinet_Turu'),
        name: 'cabinet_type', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
];

export default [[...inventoryPages, deviceDetail]];

import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ErrorRounded, PauseCircleFilled, PlayCircleFilled, ReportProblemRounded,
} from '@material-ui/icons';
import Table from '../../../components/Table';
import TableFilterMenu from './TableFilterMenu';
import { tra } from '../../../commons/utils';
import { Colors } from '../../../assets/statics';
import { getIntegrationChannels } from '../../../store/actions';
import useResponseStatu from '../../../commons/hook/useResponseStatu';

// icon
const channel_column_type_enum = Object.freeze({
  STARTED: 'STARTED',
  ERROR: 'ERROR',
  STOPPED: 'STOPPED',
  INACTIVE: 'INACTIVE',
});

const channelTypeIcon = (val) => {
  switch (val) {
    case channel_column_type_enum.STARTED:
      return <PlayCircleFilled style={{ color: Colors.green }} />;
    case channel_column_type_enum.ERROR:
      return <ErrorRounded style={{ color: Colors.red }} />;
    case channel_column_type_enum.STOPPED:
      return <PauseCircleFilled style={{ color: Colors.grey }} />;
    case channel_column_type_enum.INACTIVE:
      return <ReportProblemRounded style={{ color: Colors.yellow }} />;
    default: return '';
  }
};

const channel_column_table = [
  {
    filterable: true,
    name: 'activation_state',
    text: tra('Type'),
    type: 'text',
    align: 'left',
    sortable: true,
    searchable: true,
    customBody: (val) => channelTypeIcon(val),
  },
  {
    filterable: true,
    name: 'channel_name',
    text: tra('Ismi'),
    type: 'text',
    align: 'left',
    sortable: true,
    searchable: true,
  },
  {
    filterable: true,
    name: 'component',
    text: tra('Component'),
    type: 'text',
    align: 'left',
    sortable: true,
    searchable: true,
  },
  {
    filterable: true,
    name: 'party',
    text: tra('Party'),
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    filterable: true,
    name: 'adapter_type',
    text: tra('Adapter_Type'),
    type: 'text',
    align: 'left',
    sortable: true,
  },
];

export default function ChannelMonitoringTab() {
  const dispatch = useDispatch();
  // STORE
  const integrationChannels = useSelector((s) => s?.integrationChannels);
  // STATE
  const [tableReset, setTableReset] = useState(false);

  const { loading } = useResponseStatu(getIntegrationChannels().type);

  useEffect(() => {
    if (!integrationChannels?.length) {
      dispatch(getIntegrationChannels());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={4} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <TableFilterMenu
          tableColumn={channel_column_table}
          tableData={integrationChannels}
        >
          {(filterProps) => (
            <Table
              lazyLoading={loading}
              id="channelMonitoringTable"
              data={integrationChannels}
              columns={channel_column_table}
              rowClick={(row) => window.open(row.link, '_blank').focus()}
              options={{
                header: {
                  visible: true,
                  search: false,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  ...filterProps,
                  // filters: filter,
                  // onChange: this.changeFilter,
                  // drawerChange: () => this.props.setFilterDrawer(!this.props.filterDrawer),
                },
                pagination: {
                  active: true,
                  perPage: 13,
                },
              }}
              tableResetFilter={tableReset}
              onTableResetFilter={() => setTableReset(false)}
            />
          )}
        </TableFilterMenu>
      </Grid>
    </Grid>
  );
}

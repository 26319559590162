import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // MenuItem,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Input from '../../components/Input';
import Table from '../../components/Table';
import {
  CustomButton, CustomDrawer, TableWrapper, PageWrapper, FilteredSidebar, PageContent, ComponentLoader,
} from '../../assets/styledNew';
import {
  LeftArrow,
} from '../../assets/icons';

import { tra } from '../../commons/utils';
import { selectAppConfig, selectFilterDrawer, selectLicenseFollow } from '../../store/selectors';
import {
  getLicenseFollow, setAppConfig, setFilterDrawer,
} from '../../store/actions';

class LicenseFollow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: JSON.parse(localStorage.getItem('licenseFollowFilter')) || [],
      excelMenu: null,
      drawer: true,
    };

    props.getLicenseFollow();
  }

  componentWillUnmount() {
    this.props.setFilterDrawer(false);
  }

  handleWithStateProps = (data, callback) => {
    const [key, value] = [Object.keys(data).toString(), ...Object.values(data)];
    this.props.setAppConfig(key, value);
    this.setState({ [key]: value });
    if (callback) callback();
  }

  getArrFilters = (itemName, arrayPath, count, countKey) => {
    const { license } = this.props;
    const data = [];
    for (let i = 0; i < license.length; i++) {
      for (let j = 0; j < license[i][arrayPath].length; j++) {
        data.push(license[i][arrayPath][j][itemName]);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  }

  changeFilter = (state, value, text, type, label, header) => {
    const { filter } = this.state;
    if (type === 'resetAll') {
      this.setState({ filter: [] });
      const localFilter = JSON.stringify([]);
      localStorage.setItem('licenseFollowFilter', localFilter);
    } else {
      const stateName = state.replace(/[0-9]/g, '');
      let oldValue = false;
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state);
        filter.splice(index, 1);
      }
      if (type === 'select') {
        oldValue = this.state.filter.find((item) => item.column === stateName);
      } else {
        oldValue = this.state.filter.find((item) => item.column === stateName && item.value === text);
      }
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value) {
        filter.push({
          column: stateName, value: text, label, header,
        });
      }
      this.setState({
        filter,
        [state]: type === 'select' ? text : value,
      });
      const localFilter = JSON.stringify(filter);
      localStorage.setItem('licenseFollowFilter', localFilter);
    }
  }

  render() {
    const {
      filterDrawer, license,
    } = this.props;
    const { filter } = this.state;
    const columns = [

      {
        name: 'agreement',
        text: tra('Lisans_Sozlesmesi'),
        // sortable: true,
        // filterable: false,
        type: 'text',
        searchable: true,
        align: 'center',
      },
      {
        name: 'application_name',
        text: tra('Uygulama_Ismi'),
        type: 'text',
        tooltip: false,
        // searchable: true,
        // filterLocation: 'hardwareType',
        filterable: true,
        align: 'center',
      },
      {
        name: 'license_type',
        text: tra('Lisans_Turu'),
        type: 'text',
        tooltip: false,
        // searchable: true,
        // filterLocation: 'hardwareType',
        filterable: true,
        align: 'center',
      },
      {
        name: 'quantity',
        text: tra('Lisans_Adedi'),
        sortable: true,
        tooltip: true,
        // searchable: true,
        align: 'center',
      },
      {
        name: 'user_quantity',
        text: tra('Kullanici_Adedi'),
        sortable: true,
        type: 'text',
        // filterable: true,
        // searchable: true,
        align: 'center',
      },

    ];

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomDrawer
          anchor="left"
          open={filterDrawer}
          {...(!isWidthDown('sm', this.props.width)
            ? { variant: 'persistent' }
            : {
              onClose: () => this.props.setFilterDrawer(false),
              onOpen: () => this.props.setFilterDrawer(true),
            })}

        >
          <FilteredSidebar>
            <div className="title">
              {tra('Filtre')}
              { !isWidthDown('sm', this.props.width)
              && <CustomButton onClick={() => this.props.setFilterDrawer(false)} startIcon={<LeftArrow width={12} />}>{tra('Geri')}</CustomButton>}
            </div>
            <div className="filterContent">
              {
              columns.map((item, index) => (
                item.filterable && (
                  item.type === 'select'
                    ? (
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <p>{item.text}</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Input
                            type="select"
                            fullWidth
                            data={item.selectData}
                            value={this.state[item.name]}
                            onChange={(e) => this.changeFilter(item.name, true, e.target.value, 'select', e.target.value, item.text)}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )
                    : item.type === 'array'
                      ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                            {
                              this.getArrFilters(item.name, item.arrayPath).map((arrFilter, count) => (
                                <Input
                                  type="checkbox"
                                  title={`${arrFilter} (${this.getArrFilters(item.name, item.arrayPath, true, arrFilter)})`}
                                  value={filter.find((a) => a.value === arrFilter) !== undefined}
                                  onChange={(e) => this.changeFilter(`${item.arrayPath}.${item.name}${index + 1 * count + 1}`, e.target.checked, arrFilter, 'checkbox', arrFilter, item.text)}
                                />
                              ))
                            }
                          </AccordionDetails>
                        </Accordion>
                      )
                      : item.filterLocation
                        ? (
                          <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <p>{item.text}</p>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                              {
                                license && [...new Set(license.map((items) => items[item.name]))].map((licence, count) => (
                                  <Input
                                    type="checkbox"
                                    title={`${this.props[item.filterLocation].find((f) => f.id === licence) && this.props[item.filterLocation].find((f) => f.id === licence).name} (${license.filter((fltr) => fltr[item.name] === licence).length})`}
                                    value={filter.find((a) => (a.column === item.name) && (a.value === licence)) !== undefined}
                                    onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', this.props[item.filterLocation].find((f) => f.id === licence).name, item.text)}
                                  />
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        )
                        : (
                          <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <p>{item.text}</p>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                              {
                                license && [...new Set(license.map((items) => items[item.name]))].map((licence, count) => (
                                  <Input
                                    type="checkbox"
                                    title={`${licence} (${license.filter((fltr) => fltr[item.name] === licence).length})`}
                                    value={filter.find((a) => a.value === licence) !== undefined}
                                    onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', licence, item.text)}
                                  />
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        )
                )
              ))
            }
            </div>
            <div className="filterDrawefooter">
              {license && <p><span>{`(${license.length})`}</span> {tra('Envanter_Sayisi')}</p>}
              <CustomButton onClick={() => this.props.setFilterDrawer(false)}>{tra('Uygula')}</CustomButton>
              <CustomButton transparent onClick={() => this.changeFilter(null, null, null, 'resetAll')}>{tra('Sifirla')}</CustomButton>
            </div>
          </FilteredSidebar>
        </CustomDrawer>
        <PageWrapper drawer={!isWidthDown('sm', this.props.width) && filterDrawer}>

          <PageContent>
            <div className="pageInner" style={{ paddingTop: 0 }}>
              <Grid container spacing={3} style={{ paddingTop: 0 }}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                  <TableWrapper style={{ minHeight: '60vh' }}>
                    {
                      license ? (
                        <Table
                          id="licenseFollowTable"
                          data={[...license]}
                          // searchFieldNotFilteredData={false}
                          rowClick={(row) => {
                            // this.props.getHardwareDeviceDetail(Number(row.id));
                            // history.push(`/LicenseFollowDetail/${row.id}`);

                            window.location.href = `#/LicenseFollowDetail/${row.id}`;
                          }}
                          columns={columns}
                          options={{
                            header: {
                              visible: true,
                              columnSetting: true,
                            },
                            filter: {
                              show: true,
                              filters: filter,
                              onChange: this.changeFilter,
                              drawerChange: () => this.props.setFilterDrawer(!filterDrawer),
                            },
                            pagination: {
                              active: true,
                            },
                          }}
                        />
                      ) : (
                        <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                      )
                    }
                  </TableWrapper>
                </Grid>
              </Grid>
            </div>
          </PageContent>
        </PageWrapper>
      </div>
    );
  }
}

LicenseFollow.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  width: PropTypes.object.isRequired,
  setFilterDrawer: PropTypes.func.isRequired,
  license: PropTypes.array.isRequired,
  filterDrawer: PropTypes.bool.isRequired,
  getLicenseFollow: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  appConfig: selectAppConfig(),
  filterDrawer: selectFilterDrawer(),
  license: selectLicenseFollow(),

});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setFilterDrawer,
      setAppConfig,
      getLicenseFollow,
    }, dispatch)
  )
);

const LicenseFollowWithWidth = withWidth()(LicenseFollow);

export default connect(mapStateToProps, mapDispatchToProps)(LicenseFollowWithWidth);

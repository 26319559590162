import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  AccordionDetails, AccordionSummary, Grid, IconButton,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  ComponentLoader,
  CustomButton, NoData, PageContent, PageWrapper,
} from '../assets/styledNew';
import {
  getSurveyQuestions, setSurveyQuestions, answerSurvey, getAssignedSurveys, setAnswerBookmark,
} from '../store/actions';
import { selectAssignedSurveys, selectInfos, selectSurveyQuestions } from '../store/selectors';
import Input from '../components/Input';
import {
  CustomAccordion, SurveyAnswerBox, SurveyHeaderApp, SurveyOptionBox, SurveySectionHeader, SurveyAnswerBottomBar,
} from '../assets/styledSurveyQuestions';
import { Left, Right } from '../assets/icons';
import { tra } from '../commons/utils';
import NoDataImg from '../assets/img/noData.png';

class SurveyAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Answers: [],
      selectedAppId: '',
    };
    props.getSurveyQuestions(props.match.params.id, props.match.params.appId);
    props.getAssignedSurveys();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.inputTypes.length && Object.keys(nextProps.surveyQuestions).length > 0 && nextProps.surveyQuestions.Sections.length > 0 && (prevState.Answers.length === 0 || nextProps.match.params.appId !== prevState.selectedAppId)) {
      const tempArr = [];
      for (let i = 0; i < nextProps.surveyQuestions.Sections.length; ++i) {
        for (let j = 0; j < nextProps.surveyQuestions.Sections[i].Questions.length; ++j) {
          const obj = {
            question_id: nextProps.surveyQuestions.Sections[i].Questions[j].id,
            option_choice_id: '',
            answer_text: '',
          };
          const inputType = nextProps.inputTypes.find((item) => item.id === nextProps.surveyQuestions.Sections[i].Questions[j].input_type_id).type;
          const selectedOpt = nextProps.surveyQuestions.Sections[i].Questions[j].OptionChoices.find((item) => item.answer === true);
          switch (inputType) {
            case 0:
              obj.option_choice_id = nextProps.surveyQuestions.Sections[i].Questions[j].OptionChoices[0].id;
              obj.answer_text = nextProps.surveyQuestions.Sections[i].Questions[j].OptionChoices[0].answer_text;
              tempArr.push(obj);
              break;
            case 1:
              if (selectedOpt) {
                obj.option_choice_id = selectedOpt.id;
                obj.answer_text = 'answered';
              }
              tempArr.push(obj);
              break;
            case 2:
              if (selectedOpt) {
                obj.option_choice_id = selectedOpt.id;
                obj.answer_text = 'answered';
                tempArr.push(obj);
              }
              break;
            default:
              break;
          }
        }
      }
      return { Answers: tempArr, selectedAppId: nextProps.match.params.appId };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.setSurveyQuestions(false);
    this.setState({ Answers: [] });
  }

  changeRadioButton = (e, id) => {
    const { Answers } = this.state;
    const selectedIndex = Answers.findIndex((item) => item.question_id === id);
    Answers[selectedIndex].option_choice_id = parseInt(e.target.value, 10);
    Answers[selectedIndex].answer_text = 'answered';
    this.setState({ Answers });
    this.props.setAnswerBookmark(Answers);
  }

  changeCheckbox = (e, choice) => {
    const { Answers } = this.state;
    if (Answers.filter((item) => item.option_choice_id === choice.id).length === 0) {
      const answer = {
        option_choice_id: choice.id,
        answer_text: 'answered',
        question_id: choice.question_id,
      };
      Answers.push(answer);
    } else {
      const selectedIndex = Answers.findIndex((item) => item.option_choice_id === choice.id);
      Answers.splice(selectedIndex, 1);
    }
    this.setState({ Answers });
  }

  changeText = (e, id) => {
    const { Answers } = this.state;
    const selectedIndex = Answers.findIndex((item) => item.question_id === id);
    Answers[selectedIndex].answer_text = e.target.value;
    this.setState({ Answers });
    this.props.setAnswerBookmark(Answers);
  }

  answerRenderer = (id, choices, qId) => {
    const { Answers } = this.state;
    const { match, assignedSurveys, inputTypes } = this.props;
    const selectedSurvey = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.params.id, 10));
    const selectedQuestion = Answers.find((item) => item.question_id === qId);
    const selectedApp = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.params.id, 10)).SurveyCounts.find((app) => app.id === parseInt(match.params.appId, 10));
    const inputType = inputTypes.find((item) => item.id === id).type;
    switch (inputType) {
      case 0:
        return (
          <div className="paragraph">
            <Input
              type="text"
              placeholder={choices[0].text}
              onChange={(e) => this.changeText(e, qId)}
              value={selectedQuestion.answer_text}
              disabled={selectedSurvey.active === 0 || selectedApp.situation === 1}
            />
          </div>
        );
      case 1:
        return (
          <div className="radioButton">
            <Input
              type="radioButtonGroup"
              data={choices}
              onChange={(e) => this.changeRadioButton(e, qId)}
              value={selectedQuestion.option_choice_id}
              disabled={selectedSurvey.active === 0 || selectedApp.situation === 1}
            />
          </div>
        );
      case 2:
        return (
          <div className="checkbox">
            {choices.map((choice, index) => (
              <Input
                type="checkbox"
                onChange={(e) => this.changeCheckbox(e, choice, index)}
                defaultChecked={choice.answer}
                disabled={selectedSurvey.active === 0 || selectedApp.situation === 1}
                title={choice.text}
              />
            ))}
          </div>
        );
      default:
        return (
          <div>Cant find type of {inputType}</div>
        );
    }
  }

  handleReturn = () => {
    const { history, match } = this.props;
    if (match.params.isSelectedApp === '1') {
      history.replace(`/Surveys/${match.params.appId}`);
    } else {
      window.location.href = '#/Surveys';
    }
  }

  /* type === 1 gönder // type === 2 taslak */
  handleSaveSurvey = (type) => {
    const { Answers } = this.state;
    const { match, surveyQuestions } = this.props;
    const data = {
      app_id: match.params.appId,
      Answers: Answers.filter((item) => (item.option_choice_id !== '' && (item.answer_text !== ''))),
    };
    this.props.answerSurvey(data, match.params.id, type, surveyQuestions.update);
  }

  handleChangeApp = (type, appId) => {
    const { match, assignedSurveys } = this.props;
    const applications = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.params.id, 10)).SurveyCounts;
    const selectedIndex = applications.findIndex((item) => item.id === appId);
    if (type === 'next') {
      if (applications[selectedIndex + 1]) {
        this.props.getSurveyQuestions(match.params.id, applications[selectedIndex + 1].id);
        window.location.href = `#/Surveys/SurveyAnswer/${match.params.id}/${applications[selectedIndex + 1].id}/${match.params.isSelectedApp}`;
      } else {
        this.props.getSurveyQuestions(match.params.id, applications[0].id);
        window.location.href = `#/Surveys/SurveyAnswer/${match.params.id}/${applications[0].id}/${match.params.isSelectedApp}`;
      }
    } if (type === 'prev') {
      if (applications[selectedIndex - 1]) {
        this.props.getSurveyQuestions(match.params.id, applications[selectedIndex - 1].id);
        window.location.href = `#/Surveys/SurveyAnswer/${match.params.id}/${applications[selectedIndex - 1].id}/${match.params.isSelectedApp}`;
      } else {
        this.props.getSurveyQuestions(match.params.id, applications[applications.length - 1].id);
        window.location.href = `#/Surveys/SurveyAnswer/${match.params.id}/${applications[applications.length - 1].id}/${match.params.isSelectedApp}`;
      }
    }
    this.props.setSurveyQuestions(false);
  }

  render() {
    const { Answers } = this.state;
    const {
      surveyQuestions, assignedSurveys, match, inputTypes,
    } = this.props;
    const selectedSurvey = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.params.id, 10));
    const selectedApp = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.params.id, 10)).SurveyCounts.find((app) => app.id === parseInt(match.params.appId, 10));
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner" style={{ paddingBottom: 100 }}>
            <Grid container>
              <Grid item xs={12}>
                <h3 style={{ fontWeight: 600, fontSize: 24, color: this.props.theme.text2 }}>{surveyQuestions.name}</h3>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid container justify="center" item xs={12}>
                <SurveyHeaderApp>
                  <IconButton disabled={selectedSurvey.SurveyCounts && selectedSurvey.SurveyCounts.length < 2} onClick={() => this.handleChangeApp('prev', selectedApp.id)}><Left /></IconButton>
                  <h4>{selectedApp.name}</h4>
                  <IconButton disabled={selectedSurvey.SurveyCounts && selectedSurvey.SurveyCounts.length < 2} onClick={() => this.handleChangeApp('next', selectedApp.id)}><Right /></IconButton>
                </SurveyHeaderApp>
              </Grid>
              <Grid item xs={12}>
                <SurveyAnswerBox>
                  <p>{tra('Anket_Uyari_Metni')}</p>
                  <h5>* {tra('Gerekli')}</h5>
                </SurveyAnswerBox>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              {
                surveyQuestions ? (
                  Object.keys(surveyQuestions).length > 0
                    ? surveyQuestions.Sections.map((section, i) => (
                      <Grid item xs={12}>
                        <CustomAccordion defaultExpanded={i === 0}>
                          <AccordionSummary>
                            <div className="summaryWrapper">
                              <SurveySectionHeader answer><p>{section.name}</p></SurveySectionHeader>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="contentWrapper">
                              <Grid container item xs={12} spacing={4}>
                                {section.Questions.map((question, index) => (
                                  <Grid item xs={12}>
                                    <SurveyAnswerBox>
                                      <h4>{index + 1}. {question.question_text} {!question.allow_null && <span style={{ color: '#B91033' }}>* {tra('Gerekli')}</span>}</h4>
                                      <div className="answers">
                                        <SurveyOptionBox>
                                          {(Answers.length > 0 || (inputTypes.length > 0 && inputTypes.find((item) => item.id === question.input_type_id).type === 2)) && (
                                            this.answerRenderer(question.input_type_id, question.OptionChoices, question.id))}
                                        </SurveyOptionBox>
                                      </div>
                                    </SurveyAnswerBox>
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                          </AccordionDetails>
                        </CustomAccordion>
                      </Grid>
                    )) : (
                      <Grid item xs={12}>
                        <NoData>
                          <img src={NoDataImg} alt="No Data" />
                          <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                        </NoData>
                      </Grid>
                    )
                ) : (
                  <Grid item xs={12}>
                    <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                  </Grid>
                )
              }
            </Grid>
            {isWidthDown('sm', this.props.width)
              ? (
                selectedSurvey.active !== 0 && selectedApp.situation !== 1
                && (
                  <SurveyAnswerBottomBar>
                    <CustomButton onClick={() => this.handleSaveSurvey(1)}>{tra('Gonder')}</CustomButton>
                  </SurveyAnswerBottomBar>
                )
              )
              : (
                <SurveyAnswerBottomBar>
                  <Grid container>
                    <Grid container justify="space-between" item xs={12}>
                      <CustomButton onClick={() => this.handleReturn()}>{tra('Geri_Don')}</CustomButton>
                      <div>
                        {selectedSurvey.active !== 0 && selectedApp.situation !== 1 && <CustomButton outlined style={{ marginRight: 10 }} onClick={() => this.handleSaveSurvey(0)}>{tra('Taslak_Olarak_Kaydet')}</CustomButton>}
                        {selectedSurvey.active !== 0 && selectedApp.situation !== 1 && <CustomButton onClick={() => this.handleSaveSurvey(1)}>{tra('Gonder')}</CustomButton>}
                      </div>
                    </Grid>
                  </Grid>
                </SurveyAnswerBottomBar>
              )}
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

SurveyAnswer.propTypes = {
  getSurveyQuestions: PropTypes.func.isRequired,
  surveyQuestions: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  setSurveyQuestions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  answerSurvey: PropTypes.func.isRequired,
  getAssignedSurveys: PropTypes.func.isRequired,
  assignedSurveys: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  setAnswerBookmark: PropTypes.func.isRequired,
  inputTypes: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  surveyQuestions: selectSurveyQuestions(),
  assignedSurveys: selectAssignedSurveys(),
  inputTypes: selectInfos('InputTypes'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getSurveyQuestions,
      setSurveyQuestions,
      answerSurvey,
      getAssignedSurveys,
      setAnswerBookmark,
    }, dispatch)
  )
);

const SurveyAnswerWithTheme = withTheme(SurveyAnswer);
const SurveyAnswerWithWidth = withWidth()(SurveyAnswerWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAnswerWithWidth);

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';

import {
  ModalHeader,
  ModalContent,
  CustomModal,
  AddFileComponent,
  CustomButton,
  BottomSheetWrapper,
} from '../../assets/styledNew';
import { DocumentPDF } from '../../assets/icons';
import {
  setToast, setInventoryRegisterFiles, setInventoryRegisterFileUrls,
} from '../../store/actions';
import BottomSheet from '../BottomSheet';
import { tra } from '../../commons/utils';
import { selectInventoryRegisterFiles, selectInventoryRegisterFileUrls } from '../../store/selectors';
import Input from '../Input';

class InventoryDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        dokuman: tra('Dokuman'),
        dosya_bulunamadi: tra('Bu_uygulama_icin_belge_bulunamadı'),
        dosyaEkle: tra('Dosya_Ekle'),
        appUrls: tra('Dosya_Linkleri'),
      },
      urlText: '',
    };
    this.hiddenInput = React.createRef();
  }

  handleExit = () => {
    this.setState({ urlText: '' });
  }

  uploadFileState = (e) => {
    const { inventoryRegisterFiles } = this.props;
    const tempArr = [...inventoryRegisterFiles];
    if ((e.target.files.length + inventoryRegisterFiles.length) < 4) {
      for (let i = 0; i < e.target.files.length; ++i) {
        tempArr.push(e.target.files[i]);
      }
      this.props.setInventoryRegisterFiles(tempArr);
      this.props.setFileArr(tempArr);
      this.props.setToast(true, tra('File_Added'), 'warning');
    } else {
      this.props.setToast(true, tra('You_can_add_max_3_files'), 'warning');
    }
  }

  uploadFile = (e) => {
    const { uploadFile, id } = this.props;
    uploadFile(e.target.files, id);
  }

  saveAppUrls = () => {
    const { inventoryRegisterFileUrls } = this.props;
    const { urlText } = this.state;
    if (urlText.length > 2) {
      const tempFileUrls = inventoryRegisterFileUrls;
      tempFileUrls.push(urlText);
      this.setState({ urlText: '' });
      this.props.setFileUrlArr(tempFileUrls);
      this.props.setInventoryRegisterFileUrls(tempFileUrls);
      this.props.setToast(true, tra('URL_Added'), 'warning');
    } else {
      this.props.setToast(true, tra('URL_Field_Req_Message'), 'warning');
    }
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;

    const {
      close, type, disabled,
    } = this.props;
    const { lang, urlText } = this.state;
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{lang.dokuman}</h1>
          <CloseIcon onClick={close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <AddFileComponent onClick={() => this.hiddenInput.current.click()}>
                  <DocumentPDF width={30} />
                  <p>{lang.dosyaEkle}</p>
                </AddFileComponent>
                <input
                  multiple
                  onClick={(e) => { e.target.value = ''; }}
                  onChange={type === 'register' ? this.uploadFileState : this.uploadFile}
                  ref={this.hiddenInput}
                  type="file"
                  style={{ display: 'none' }}
                />
              </Grid>
              <Grid container alignItems="center" item xs={8}>
                <p>
                  {tra('You_Can_Upload_File_From_Here')}. <br />
                  {tra('File_Type_Detail')}.
                </p>
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                  <Input
                    type="text"
                    disabled={disabled}
                    title={tra('App_Url')}
                    value={urlText}
                    onChange={(e) => this.setState({ urlText: e.target.value })}
                  />
                  <CustomButton onClick={() => this.saveAppUrls()} style={{ marginLeft: 15 }}>{tra('Add_Url')}</CustomButton>
                </div>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal onExit={() => this.handleExit()} open={this.props.open} onClose={this.props.close} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} snapPoint={[400, 0]} isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

InventoryDocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setFileUrlArr: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  setToast: PropTypes.func.isRequired,
  setInventoryRegisterFiles: PropTypes.func.isRequired,
  setInventoryRegisterFileUrls: PropTypes.func.isRequired,
  inventoryRegisterFiles: PropTypes.array.isRequired,
  inventoryRegisterFileUrls: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setFileArr: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  inventoryRegisterFiles: selectInventoryRegisterFiles(),
  inventoryRegisterFileUrls: selectInventoryRegisterFileUrls(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setToast,
      setInventoryRegisterFiles,
      setInventoryRegisterFileUrls,
    }, dispatch)
  )
);

const InventoryDocumentModalWithTheme = withTheme(InventoryDocumentModal);
const InventoryDocumentModalWithWidth = withWidth()(InventoryDocumentModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDocumentModalWithWidth);

/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  CustomModal, CustomButton, CustomUpdateWarnModal,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import BottomSheet from '../BottomSheet';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  contentPage = () => {
    const {
      close, message, deleteFunc,
    } = this.props;
    return (
      <CustomUpdateWarnModal className="content">
        <div className="header">
          <CloseIcon onClick={close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </div>
        <div className="content">
          <h3>{tra('Deleting')}</h3>
          <p>{message}</p>
        </div>
        <div className="actions">
          <CustomButton opacity onClick={close}>{tra('Vazgec')}</CustomButton>
          <CustomButton onClick={deleteFunc}>{tra('Sil')}</CustomButton>
        </div>
      </CustomUpdateWarnModal>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  deleteFunc: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
    }, dispatch)
  )
);

const DeleteModalWithTheme = withTheme(DeleteModal);
const DeleteModalWithWidth = withWidth()(DeleteModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModalWithWidth);

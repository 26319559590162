/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import React, {
  useContext, useMemo,
} from 'react';
import { CostingContext } from '../../commons/context/CostingContext';
// import { FormatCurrency } from '../commons/utils';
import Input from '../Input';

const CostFastInput = (props) => {
  const {
    CostingState, CostingPageVariables,
  } = useContext(CostingContext);

  const val = props.name ? props.stateType === 'page' ? CostingPageVariables[props.name] : CostingState[props.name] : props.value;

  return useMemo(() => (
    <Input
      {...props}
      data={props.data}
      error={props.error}
      helperText={props.helperText}
      fullWidth
      value={val}
      onChange={props.onChange}
      InputProps={{
        endAdornment: props.endAdornment,
        startAdornment: props.startAdornment,
      }}
    />
  ), [val, props.data, props.error, props.helperText, props.disabled, props.minDate, props.title, props.telCode]);
};

CostFastInput.propTypes = {
};

export default CostFastInput;

import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const secondPages = [
  [
    {
      subtitle: tra('Cihaz_Detaylari'),
      objectGroupName: 'MobileDevices',
      inputArray: [
        {
          type: 'text',
          title: tra('Isim'),
          name: 'name', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'text',
          title: tra('Urun'),
          name: 'product', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'text',
          title: tra('Imei'),
          name: 'imei', // değişecek
          value: '1920 X 1080',
          validateRules: 'numeric|min:15',
          inputProps: {
            maxLength: 15,
          },
        },
        // {
        //   type: 'text',
        //   title: tra('Seri_Numarasi'),
        //   name: 'monitor_type', // değişecek
        //   value: '1920 X 1080',
        // },
        {
          type: 'text',
          title: tra('Mevcut_Kapasitesi'),
          name: 'capacity', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'select',
          title: tra('Platform'),
          name: 'platform_id', // değişecek
          value: '1920 X 1080',
          data: 'OSPlatforms',
          inputProps: {
            emptyItem: true,
          },
          // childrenInput: [
          //   {
          //     type: 'number',
          //     title: tra('Surumu'),
          //     name: 'version_id', // değişecek
          //     value: '1920 X 1080',
          //   },
          // ],
        },
      ],
    },
  ], [
    {
      subtitle: tra('Gsm'),
      addGroupName: 'GSM',
      addGroupIndex: 0,
      addButton: true,
      inputArray: [
        {
          type: 'tel',
          title: tra('Gsm_No'),
          name: 'gsm_no', // değişecek
          value: '1920 X 1080',
          validateRules: 'min:10',
        },
        {
          type: 'text',
          title: tra('Servis_Saglayici'),
          name: 'service_provider', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'text',
          title: tra('Tarife_paket'),
          name: 'recipe', // değişecek
          value: '1920 X 1080',
        },
      ],
    },
  ],
];

export default [inventoryPages, secondPages];

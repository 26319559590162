import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, TableHead,
} from '@material-ui/core';
import {
  ExpandLess, ExpandMore,
} from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { Colors } from '../../../../assets/statics';
import { FormatUTCLocaleDateStr, tra } from '../../../../commons/utils';
import ExpandTable, {
  CustomCollapse, ExpandTableTdWithoutHead, ExpandTableTh, ExpandTableTr, ExpandableRow, TableTd, TableTh, TableTr,
} from '../../../../assets/styledExpandTable';

const ExpandRowSec = ({ item, opened }) => {
  const [open, setOpen] = useState(false);

  return useMemo(() => (
    <>
      <ExpandableRow>
        <ExpandTableTh style={{ background: '#F6F8FB' }} colSpan={1} />
        <ExpandTableTh style={{ background: '#E6ECF4' }} colSpan={4}>
          <CustomCollapse in={opened}>
            <ExpandTable>
              <TableBody>
                <ExpandTableTr hover onClick={() => { setOpen(!open); }}>
                  <ExpandTableTdWithoutHead textAlign="start">{`${item.name}  ${item.surname}`}</ExpandTableTdWithoutHead>
                  <ExpandTableTdWithoutHead textAlign="end">{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTdWithoutHead>
                </ExpandTableTr>
                <ExpandableRow>
                  <ExpandTableTh colSpan={5}>
                    <CustomCollapse in={open}>
                      <div
                        style={{
                          background: 'white',
                          padding: 20,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                        }}
                      >
                        <ExpandTable>
                          <TableHead>
                            <TableTh style={{ textAlign: 'start' }}>{tra('Lisans_Key')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Lisans_Turu')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Description')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Baslangic_Tarihi')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Bitis_Tarihi')}</TableTh>
                            <TableTh style={{ textAlign: 'center' }}>{tra('Zimmet')}</TableTh>
                          </TableHead>
                          <br />
                          <TableBody>
                            {item.LicenseKeys.map((costRowItem) => (
                              <>
                                <TableTr fc={costRowItem.fc === 1}>
                                  <TableTd textAlign="start">{costRowItem.key}</TableTd>
                                  <TableTd textAlign="center">{costRowItem.license_type}</TableTd>
                                  <TableTd textAlign="center">{costRowItem.description}</TableTd>
                                  <TableTd textAlign="center">{FormatUTCLocaleDateStr(costRowItem.contract_start)}</TableTd>
                                  <TableTd textAlign="center">{FormatUTCLocaleDateStr(costRowItem.contract_end)}</TableTd>
                                  <TableTd textAlign="center">{costRowItem.owner ? <CheckBoxIcon htmlColor={Colors.lightBlue100} /> : <CheckBoxOutlineBlankIcon htmlColor={Colors.lightGrey200} />}</TableTd>
                                </TableTr>
                                <br />
                              </>
                            ))}
                          </TableBody>
                        </ExpandTable>
                      </div>
                    </CustomCollapse>
                  </ExpandTableTh>
                </ExpandableRow>
              </TableBody>
            </ExpandTable>
          </CustomCollapse>
        </ExpandTableTh>
      </ExpandableRow>
    </>
  ), [opened, open, item]);
};

ExpandRowSec.propTypes = {
  item: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
};
export default ExpandRowSec;

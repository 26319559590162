/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { DialogContent, Grid, MenuItem } from '@material-ui/core';
import { CloseOutlined, Edit } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudExport, CloudImport, Plus } from '../../assets/icons';
import {
  CustomButton, CustomMenu, CustomModal, ModalContent, ModalHeader, PeopleManagementTabPage, SwitchWrapper, TableWrapper,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import Table from '../../components/Table';
import {
  adminExcelDownload, adminExcelUpload, setAppConfig, userStatuChange,
} from '../../store/actions';
import Input from '../../components/Input';
import AdminAddUpdateModal from '../../components/modals/AdminAddUpdateModal';
import AddUserLdap from '../../components/modals/AddUserLdap';

const ManageUsers = () => {
  const [userStatusId, setUserStatusId] = useState(null);
  const [editedUserId, setEditedUserId] = useState(null);
  const [excelMenu, setExcelMenu] = useState(null);
  const [ldapModal, setLdapModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);

  const allInfo = useSelector((state) => state.infos);
  const appConfig = useSelector((state) => state.appConfig);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const hiddenInput = useRef(null);

  const handleEditUserModal = (id) => {
    setAddUserModal(true);
    setEditedUserId(id);
  };

  const userColumns = [
    {
      name: 'isRemoved',
      text: tra('Durum'),
      align: 'center',
      cellWidth: 100,
      customBody: (value, name, index, row) => (
        <SwitchWrapper>
          <Input
            type="switch"
            value={!value}
            onChange={() => {
              setUserStatusId(row.id);
              dispatch(userStatuChange(row.id));
            }}
          />
        </SwitchWrapper>
      ),
    },
    {
      name: 'only_name',
      text: tra('Adi'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'only_surname',
      text: tra('Soyad'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'phone',
      text: tra('Telefon'),
      editable: false,
      type: 'text',
      align: 'center',
      searchable: true,
    },
    {
      name: 'title',
      text: tra('Unvan'),
      align: 'center',
      editable: false,
      type: 'text',
      searchable: true,
    },
    {
      name: 'department_id',
      text: tra('Departman'),
      editable: false,
      align: 'center',
      type: 'select',
      selectData: allInfo.Departments,
      customBody: (value) => (
        allInfo.Departments.find((item) => item.id === value) && allInfo.Departments.find((item) => item.id === value).name
      ),
    },
    {
      name: 'email',
      text: tra('Email'),
      align: 'center',
      searchable: true,
    },
    {
      name: 'permission_id',
      text: tra('Yetki'),
      editable: false,
      align: 'center',
      type: 'select',
      selectData: allInfo.Permissions,
      customBody: (value) => (
        allInfo.Permissions.find((item) => item.id === value) && allInfo.Permissions.find((item) => item.id === value).name
      ),
    },
    {
      name: 'id',
      text: tra('Duzenle'),
      editable: false,
      align: 'center',
      customBody: (value) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleEditUserModal(value)} aria-hidden>
          <Edit width={15} />
        </div>
      ),
    },
  ];

  const excelDownlaod = (type, fileName) => {
    dispatch(adminExcelDownload(type, fileName));
    setExcelMenu(null);
  };

  const excelUpload = (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch(adminExcelUpload(e.target.files, type));
    }
    setExcelMenu(null);
  };

  return (
    <PeopleManagementTabPage>
      <TableWrapper>
        <div className="header">
          <h4>{tra('Kullanici_Islemleri')}</h4>
        </div>
        <>
          <Table
            data={users}
            columns={userColumns}
            options={{
              header: {
                visible: true,
              },
              pagination: {
                active: true,
                position: 'left',
              },
              editable: false,
              extraSearchList: ['name'],
            }}
          />
          <div className="buttonWrapper">
            <CustomButton
              style={{ marginRight: 10 }}
              onClick={(e) => setExcelMenu(e.currentTarget)}
              startIcon={<CloudExport width={14} />}
            >
              Excel
            </CustomButton>
            <CustomMenu
              anchorEl={excelMenu}
              open={Boolean(excelMenu)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              onClose={() => setExcelMenu(null)}
            >
              <>
                <MenuItem onClick={() => hiddenInput.current.click()}><CloudImport />{tra('Ice_Aktar')}</MenuItem>
                <input onClick={(e) => { e.target.value = ''; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => excelUpload(e, '/user')} ref={hiddenInput} type="file" style={{ display: 'none' }} />
              </>
              <MenuItem onClick={() => excelDownlaod('/users', 'Kullanıcı Listesi')}><CloudExport width={14} />{tra('Disa_Aktar')}</MenuItem>
              <MenuItem onClick={() => excelDownlaod('/sampleUser', 'Sample')}><CloudExport width={14} />{tra('Ornek_Indir')}</MenuItem>
            </CustomMenu>
            <CustomButton style={{ marginRight: 10 }} onClick={() => setLdapModal(true)}><Plus width={14} />{tra('LDAP_Kullanici_Ekleme')}</CustomButton>
            <CustomButton onClick={() => setAddUserModal(true)}><Plus width={14} /> {tra('Yeni_Kullanici_Ekle')}</CustomButton>
          </div>
        </>
      </TableWrapper>
      <CustomModal open={appConfig.userSureModal && appConfig.userSureModal.open} onClose={() => dispatch(setAppConfig('userSureModal', { open: false, text: '' }))} maxWidth="sm" fullWidth="true" scroll="body">
        <DialogContent className="content">
          <ModalHeader>
            <h1>Kullanıcı Statüsünü Değiştirme</h1>
            <CloseOutlined onClick={() => dispatch(setAppConfig('userSureModal', { open: false, text: '' }))} style={{ cursor: 'pointer' }} />
          </ModalHeader>
          <ModalContent>
            <div className="pageInner">
              <Grid container spacing={4}>
                <Grid container justify="flex-end" item xs={12}>
                  {/* <p>{appConfig.userSureModal && appConfig.userSureModal.text}</p> */}
                </Grid>
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={() => dispatch(userStatuChange(userStatusId, true))} style={{ margin: '0 15px' }}>{tra('Evet')}</CustomButton>
                <CustomButton onClick={() => dispatch(setAppConfig('userSureModal', { open: false, text: '' }))} outlined>{tra('Hayir')}</CustomButton>
              </Grid>
            </div>
          </ModalContent>
        </DialogContent>
      </CustomModal>
      <AdminAddUpdateModal
        open={addUserModal}
        userId={editedUserId}
        close={() => {
          setAddUserModal(false);
          setEditedUserId('');
        }}
      />
      <AddUserLdap
        open={ldapModal}
        close={() => setLdapModal(false)}
      />
    </PeopleManagementTabPage>
  );
};

ManageUsers.propTypes = {
};

export default ManageUsers;

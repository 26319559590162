/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { ResolvePathObject, CommaStringToNumber } from '../../../commons/utils';

export default class InputOnChange {
  constructor({ context = {}, inputProps = {}, reactComp = {} }) {
    this.context = context;
    this.inputProps = inputProps;
    this.reactComp = reactComp;
    this.pathData = {};
    this._contextPathData();
  }

  _contextPathData = () => {
    const {
      inputObject, objectGroupName, addGroupIndex, addGroupName,
    } = this.inputProps;
    this.pathData = [objectGroupName, addGroupName, addGroupIndex, inputObject.name].filter((i) => i || Number.isInteger(i)).join('.');
    this.pathDataDefined = inputObject.setDefinedName ? [objectGroupName, addGroupName, addGroupIndex, inputObject.setDefinedName].filter((i) => i || Number.isInteger(i)).join('.') : false;
  }

  /*
   *   ---------------------  Context  ---------------------
  */

  _setContextSubmit(value) {
    const {
      inputObject, objectGroupName, addGroupIndex, addGroupName,
    } = this.inputProps;
    if (![undefined, null].some((i) => i === value) && inputObject.noService !== true) {
      this.context.setContextSubmitData({
        objectGroupName, addGroupIndex, addGroupName, value, name: inputObject.name,
      });
    }
  }

  _setContextSingleDelete(value) {
    if ((value && this.context.isHardwareDetail) ? JSON.stringify(value) === JSON.stringify(this.helpers.deepDataFind(this.context?.ServiceData)) : this.helpers.nullCheck()) {
      this.context.setContextDeleteSubmitData({ removePath: this.pathData });
    }
  }

  _setContextScreenData(value) {
    this.context.setContextScreenData({
      value,
      path: this.pathData,
    });
  }

  _setContextChangingFields(value) {
    const { inputObject } = this.inputProps;
    if (inputObject.setDefinedName && this.pathDataDefined) {
      this.context.setContextChangingFields({ value, path: this.pathDataDefined });
    }
  }

  _setContextDeleteSumbitDataDefined({ option }) {
    const { data, name } = this.inputProps.inputObject;
    switch (name) {
      case 'ServerAccessories':
        {
          const { fieldName } = data && data.find((i) => i.id === option.id);
          this.context.setContextDeleteSumbitDataDefined({ fieldName });
        }
        break;
      default:
        break;
    }
  }

  /*   ---------------------///  Context  ---------------------*/

  /**
  * @param {string} value
  */
  text = (value) => {
    this.reactComp.setState({ value }, () => {
      this._setContextSubmit(value);
      this._setContextSingleDelete(value);
    });
  }

  number = (value) => {
    this.reactComp.setState({ value }, () => {
      this._setContextSubmit(CommaStringToNumber(value));
      this._setContextSingleDelete(CommaStringToNumber(value));
    });
  }

  tel = (value) => {
    this.reactComp.setState({ value }, () => {
      this._setContextSubmit(this.helpers.phoneFormatString(value));
      this._setContextSingleDelete(this.helpers.phoneFormatString(value));
    });
  }

  telCode = (value) => {
    const { dial_code } = value;
    const {
      objectGroupName, addGroupIndex, addGroupName,
    } = this.inputProps;
    this.reactComp.setState({ phone_code: value }, () => {
      if (![undefined, null].some((i) => i === value)) {
        this.context.setContextSubmitData({
          objectGroupName, addGroupIndex, addGroupName, value: dial_code, name: 'phone_code',
        });
      }
      // TODO: Detayları düzenle
      // if ((value && this.context.isHardwareDetail) ? JSON.stringify(value) === JSON.stringify(this.helpers.deepDataFind(this.context?.ServiceData)) : this.helpers.nullCheck()) {
      //   const telPathData = [objectGroupName, addGroupName, addGroupIndex, 'phone_code'].filter((i) => i || Number.isInteger(i)).join('.');
      //   this.context.setContextDeleteSubmitData({ removePath: telPathData });
      // }
    });
  }

  date = (value) => {
    this.reactComp.setState({ value }, () => {
      this._setContextSubmit(value);
      this._setContextSingleDelete(value);
    });
  }

  /**
   *
   * @param {*} e
   * @param {Array} value
   * @param {String} reason select-option & remove-option
   * @param {Object} details
   */
  autoComplete = (e, value, reason, details) => {
    if (Array.isArray(value)) {
      this.reactComp.setState({ value }, () => {
        let tempArrayId = [];
        tempArrayId = value.map((i) => ({ id: i.id }));
        this._setContextSubmit(tempArrayId);
        this._setContextScreenData(value);
        this._setContextChangingFields([...value.map((i) => i.id)]);
        this._setContextSingleDelete(value);

        if (reason === 'remove-option') {
          this._setContextDeleteSumbitDataDefined({ ...details });
        }
      });
    } else {
      this.reactComp.setState({ value }, () => {
        this._setContextSubmit(value?.id);
        this._setContextScreenData(value);
        this._setContextChangingFields(value);
        this._setContextSingleDelete(value);

        if (reason === 'remove-option') {
          this._setContextDeleteSumbitDataDefined({ ...details });
        }
      });
    }
  }

  checkBox = (value) => {
    this.reactComp.setState((state) => ({ value: !state.value }), () => {
      this._setContextSubmit(value);
      this._setContextScreenData(value);
      this._setContextChangingFields(value);
      this._setContextSingleDelete(value);
    });
  }

  /**
   *  type number olan inputların artı eksi butonlarının tektiklediği fonksiyon
   * @param {Number} count
   * @param {String} type minus ? plus
   */
  quantityButton = ({ count = 1, type = 'plus' }) => {
    let value = this.reactComp?.state?.value;
    if (!value) value = 0;
    value = CommaStringToNumber(value);
    const tempNumber = type === 'plus' ? Number(value + count) : Number(value - count);
    this.number(tempNumber);
  }

  /**
   * field alanların value durumlarını kontrol eden yardımcı fonksiyonlar
   */
  helpers = {
    nullCheck: () => [null, undefined].some((i) => i === this.reactComp?.state?.value),
    deepDataFind: (obj = this.context.ServiceData) => ResolvePathObject(obj, this.pathData) || null,
    phoneFormatString: (value) => [...value].filter((i) => i !== ' ' && Number.isInteger(Number(i))).join(''),
  }
}

import React from 'react';
import { hexToRgbA, tra } from '../commons/utils';
import {
  Key, Comment, Edit, Calendar,
} from './icons';

const setColor = {
  white: '#FFFFFF',
  black: '#000000',
  yellow: '#FFBE57',
  darkYellow: '#FFA900',
  green: '#187D7D',
  lightGreen: '#DEFFEE',
  pink: '#FE5578',
  lightPink: '#FFEEF2',
  red: '#B91033',
  lightRed: '#FF5242',
  darkBlackBlue: '#101426',
  darkBlue400: '#1C2632',
  darkBlue300: '#292D3E',
  darkBlue200: '#414554',
  darkBlue100: '#555866',
  darkBlue: '#27272E',
  blue: '#2144D4',
  lightBlue100: '#4C6FFF',
  lightBlue200: '#3E5970',
  darkGrey: '#75828F',
  grey: '#787A85',
  lightGrey100: '#7C818C',
  lightGrey200: '#8898AA',
  lightGrey300: '#9CACC7',
  lightGrey400: '#C1D1E2',
  lightGrey500: '#CAD1DD',
  lightGrey600: '#D9DADD',
  lightGrey700: '#E4ECF7',
  lightGrey800: '#F6F8FB',
  purple: '#353F66',
  lightPurple: '#6C6C8F',
  lightPurple100: '#7E87AD',
  lightPurple200: '#DEE3F9',
  lightPurple300: '#E1E6F7',
};

export const Colors = {
  // SINGLE COLOR
  white: setColor.white,
  black: setColor.black,
  yellow: setColor.yellow,
  green: setColor.green,
  lightGreen: setColor.lightGreen,
  pink: setColor.pink,
  lightPink: setColor.lightPink,
  red: setColor.red,
  lightRed: setColor.lightRed,
  darkBlackBlue: setColor.darkBlackBlue,
  darkBlue400: setColor.darkBlue400,
  darkBlue300: setColor.darkBlue300,
  darkBlue200: setColor.darkBlue200,
  darkBlue100: setColor.darkBlue100,
  darkBlue: setColor.darkBlue,
  blue: setColor.blue,
  lightBlue100: setColor.lightBlue100,
  lightBlue200: setColor.lightBlue200,
  darkGrey: setColor.darkGrey,
  grey: setColor.grey,
  lightGrey100: setColor.lightGrey100,
  lightGrey200: setColor.lightGrey200,
  lightGrey300: setColor.lightGrey300,
  lightGrey400: setColor.lightGrey400,
  lightGrey500: setColor.lightGrey500,
  lightGrey600: setColor.lightGrey600,
  lightGrey700: setColor.lightGrey700,
  lightGrey800: setColor.lightGrey800,
  purple: setColor.purple,
  lightPurple: setColor.lightPurple,
  lightPurple200: setColor.lightPurple200,
  lightPurple300: setColor.lightPurple300,

  // ROOT
  mainBackg: setColor.lightGrey800,
  boxBackg: setColor.white,
  greyBackg: setColor.lightGrey800,
  buttonBackg: setColor.darkBlue300,
  notificationBorder: setColor.white,
  lightBorderBlue: setColor.darkBlue300,
  pageHeaderLine: setColor.darkBlue,
  border: setColor.lightGrey300,
  menuGrey: setColor.lightGrey200,
  notificationTimeText: setColor.pink,
  notificationTimeBackg: hexToRgbA(setColor.pink, 0.1),
  hover: setColor.lightGrey800,
  inputBackg: setColor.white,
  inputBorder: setColor.lightGrey400,
  greenBackg: setColor.lightGreen,
  greenText: setColor.green,
  purpleBackg: setColor.lightPurple200,
  blueBackg: hexToRgbA(setColor.blue, 0.1),
  blueText: setColor.blue,
  purpleText: setColor.purple,
  shadow: hexToRgbA(setColor.lightGrey200, 0.5),
  shadow35: hexToRgbA(setColor.lightGrey200, 0.35),
  redText: setColor.red,
  redBackg: hexToRgbA(setColor.red, 0.1),
  // HEADER
  hoverHeaderWindowBackg: setColor.lightGrey600,

  // WIDGET COMP.
  widgetShadow: hexToRgbA(setColor.darkBlue300, 0.02),
  widgetIconBackgClose: setColor.purple,
  widgetIconBackg: setColor.lightBlue100,
  widgetInfoBackg: setColor.lightGreen,
  widgetInfoText: setColor.green,

  // TABLE
  tableCellBorder: setColor.darkBlue400,
  tablePagiBorder: setColor.blue,
  // tableHeader: setColor.darkBlue400,
  tablePagiColorBox: hexToRgbA(setColor.blue, 0.1),
  tableTop: setColor.darkBlue400,
  tableRowOdd: setColor.white,
  tableHoverTr: setColor.lightPurple200,

  // SIDEBAR
  sidebarBorder: setColor.darkBlue400,
  sidebarBackg: setColor.darkBlue300,
  sidebarButton: setColor.pink,
  sidebarMenuBackg: setColor.lightGrey800,
  sidebarIcon: setColor.lightGrey200,
  sidebarActiveIcon: setColor.purple,
  sidebarActiveBackg: setColor.white,
  sidebarActiveBorder: setColor.lightGrey800,

  // CALENDAR
  dateboxBorder: setColor.darkBlue400,
  dateboxLine: setColor.lightGrey200,
  calendarTop: setColor.darkBlue400,
  dateBoxBackg: setColor.blue,
  newEventBoxBackg: setColor.lightGrey800,
  calendarDayBox: setColor.lightGrey800,
  calendarSwitchItemButton: setColor.white,
  // TEXT
  text1: setColor.darkBlue,
  text2: setColor.lightBlue200,
  text3: setColor.lightGrey100,
  text4: setColor.white,

  // TAB COMP
  tabHeadOtherBackg: setColor.lightPurple200,

  // ADMIN
  surveyCardBackg: setColor.lightGrey800,
  surveyCardHover: setColor.white,

  // SURVEY PAGE
  surveyFormBackg: setColor.lightGrey700,

  // SURVEY DETAIL
  surveyProgressBackg: setColor.lightGrey700,

  // GRAPH COMP
  graphLine: setColor.purple,
  graphGridStroke: setColor.lightGrey600,

  // GARTNER TİME
  circleGreen: setColor.green,
  circleRed: setColor.red,
  circleBlue: setColor.blue,
  circlePink: setColor.pink,
  circleWhite: setColor.white,

  // HARDWARE PAGE
  hardwareTypeCardBackg: setColor.lightGrey800,

  // INTEGRATİON
  edgeChartOutsideSelectLine: setColor.lightGrey300,
  edgeChartOutsideSelectText: setColor.lightGrey300,
  edgeChartReceiver: setColor.darkYellow,
  edgeChartSender: setColor.lightRed,
  edgeChartSenderMutual: setColor.green,
};
export const DarkTheme = {
  // SINGLE COLOR
  white: setColor.white,
  black: setColor.black,
  yellow: setColor.yellow,
  green: setColor.green,
  lightGreen: setColor.lightGreen,
  pink: setColor.pink,
  lightPink: setColor.lightPink,
  red: setColor.red,
  lightRed: setColor.lightRed,
  darkBlackBlue: setColor.darkBlackBlue,
  darkBlue400: setColor.darkBlue400,
  darkBlue300: setColor.darkBlue300,
  darkBlue200: setColor.darkBlue200,
  darkBlue100: setColor.darkBlue100,
  darkBlue: setColor.darkBlue,
  blue: setColor.blue,
  lightBlue100: setColor.lightBlue100,
  lightBlue200: setColor.lightBlue200,
  darkGrey: setColor.darkGrey,
  grey: setColor.grey,
  lightGrey100: setColor.lightGrey100,
  lightGrey200: setColor.lightGrey200,
  lightGrey300: setColor.lightGrey300,
  lightGrey400: setColor.lightGrey400,
  lightGrey500: setColor.lightGrey500,
  lightGrey600: setColor.lightGrey600,
  lightGrey700: setColor.lightGrey700,
  lightGrey800: setColor.lightGrey800,
  purple: setColor.purple,
  lightPurple: setColor.lightPurple,
  lightPurple200: setColor.lightPurple200,
  lightPurple300: setColor.lightPurple300,
  // ROOT
  mainBackg: setColor.darkBlackBlue,
  boxBackg: setColor.darkBlue300,
  greyBackg: setColor.darkBlackBlue,
  buttonBackg: setColor.darkBlue300,
  notificationBorder: setColor.white,
  pageHeaderLine: setColor.white,
  border: setColor.lightGrey600,
  menuGrey: setColor.white,
  notificationTimeText: setColor.lightPink,
  notificationTimeBackg: hexToRgbA(setColor.red, 0.6),
  hover: setColor.lightGrey800,
  inputBackg: setColor.darkBlue200,
  inputBorder: setColor.darkBlue300,
  greenBackg: hexToRgbA(setColor.green, 0.8),
  greenText: setColor.lightGreen,
  purpleBackg: hexToRgbA(setColor.purple, 0.8),
  blueBackg: hexToRgbA(setColor.blue, 0.8),
  blueText: setColor.lightPurple300,
  purpleText: setColor.lightPurple300,
  shadow: 'transparent',
  shadow35: 'transparent',
  redText: setColor.lightPink,
  redBackg: hexToRgbA(setColor.pink, 0.8),

  // HEADER
  hoverHeaderWindowBackg: setColor.darkBlue100,

  // WIDGET
  widgetShadow: 'unset',
  widgetIconBackgClose: setColor.purple,
  widgetIconBackg: setColor.lightBlue100,
  widgetInfoBackg: hexToRgbA(setColor.green, 0.8),
  widgetInfoText: setColor.lightGreen,

  // TABLE
  tableCellBorder: setColor.darkBlue400,
  tablePagiBorder: setColor.lightGrey600,
  tableHeader: setColor.darkBlue400,
  tablePagiColorBox: setColor.darkBlue400,
  tableTop: setColor.darkBlue400,
  tableRowOdd: setColor.darkBlue300,
  tableHoverTr: setColor.purple,

  // SIDERBAR
  sidebarMenuBackg: setColor.darkBlue200,
  sidebarIcon: setColor.white,
  sidebarActiveIcon: setColor.white,
  sidebarActiveBackg: setColor.lightGrey200,
  sidebarActiveBorder: setColor.lightGrey300,

  // CALENDAR
  dateboxBorder: setColor.darkBlue400,
  dateboxLine: setColor.lightGrey200,
  calendarTop: setColor.darkBlue400,
  dateBoxBackg: hexToRgbA(setColor.blue, 0.5),
  newEventBoxBackg: hexToRgbA(setColor.lightGrey800, 0.2),
  calendarDayBox: setColor.darkBlue100,
  calendarSwitchItemButton: hexToRgbA(setColor.lightGrey400, 0.5),
  todayBorder: setColor.lightGrey600,

  // TEXT
  text1: setColor.white,
  text2: setColor.white,
  text3: setColor.white,
  text4: setColor.white,

  // TAB COMP
  tabHeadOtherBackg: hexToRgbA(setColor.lightPurple200, 0.2),

  // ADMIN
  surveyCardBackg: hexToRgbA(setColor.white, 0.2),
  surveyCardHover: setColor.darkBlue200,

  // SURVEY PAGE
  surveyFormBackg: setColor.darkBlackBlue,

  // SURVEY DETAIL
  surveyProgressBackg: setColor.darkBlue300,

  // GRAPH COMP
  graphLine: setColor.lightPurple300,
  graphGridStroke: setColor.lightPurple,

  // GARTNER TİME
  circleGreen: setColor.lightGreen,
  circleRed: setColor.lightRed,
  circleBlue: setColor.lightPurple300,
  circlePink: setColor.lightPink,
  circleWhite: setColor.white,

  // HARDWARE PAGE
  hardwareTypeCardBackg: setColor.darkBlackBlue,

  // INTEGRATİON
  edgeChartOutsideSelectLine: setColor.lightGrey800,
  edgeChartOutsideSelectText: setColor.lightGrey300,
  edgeChartReceiver: setColor.lightRed,
  edgeChartSender: setColor.darkYellow,
  edgeChartSelect: setColor.blue,
};

export const storyTabs = [
  {
    tabValue: 0,
    label: 'Tümü',
    id: 'simple-tab-0',
    tabPanel: 'simple-tabpanel-0',
  },
  {
    tabValue: 1,
    label: 'Onay Bekleyenler',
    id: 'simple-tab-1',
    tabPanel: 'simple-tabpanel-1',
  },
  {
    tabValue: 2,
    label: 'Onaylananlar',
    id: 'simple-tab-2',
    tabPanel: 'simple-tabpanel-2',
  },
  {
    tabValue: 3,
    label: 'Reddedilenler',
    id: 'simple-tab-3',
    tabPanel: 'simple-tabpanel-3',
  },
  {
    tabValue: 4,
    label: 'Onayımı Bekleyenler',
    id: 'simple-tab-4',
    tabPanel: 'simple-tabpanel-4',
  },
  {
    tabValue: 5,
    label: 'Taslaklar',
    id: 'simple-tab-5',
    tabPanel: 'simple-tabpanel-5',
  },
];

export const dashboardStatus = [tra('Lisans_Bitisi'), tra('Lisans_Baslangici'), tra('Kisisel_Hatirlatici')];

export const dashboardCalendarData = [
  {
    id: 1, status: 0, date: '16.10.2020', licence: 'Microsoft Office 365', statuText: 'End of Licence',
  },
  {
    id: 2, status: 1, date: '21.11.2020', licence: 'Adobe Creative Cloud Tüm Uygulamalar', statuText: 'Renovation of Licence',
  },
  {
    id: 3, status: 1, date: '16.02.2021', licence: 'SAP BW on Hana / NetWeafer OpenHub', statuText: 'Renovation of Licence',
  },
  {
    id: 4, status: 1, date: '21.02.2021', licence: 'Bitbucket', statuText: 'Renovation of Licence',
  },
  {
    id: 1, status: 0, date: '16.10.2020', licence: 'Microsoft Office 365', statuText: 'End of Licence',
  },
  {
    id: 2, status: 1, date: '21.11.2020', licence: 'Adobe Creative Cloud Tüm Uygulamalar', statuText: 'Renovation of Licence',
  },
  {
    id: 3, status: 1, date: '16.02.2021', licence: 'SAP BW on Hana / NetWeafer OpenHub', statuText: 'Renovation of Licence',
  },
  {
    id: 4, status: 1, date: '21.02.2021', licence: 'Bitbucket', statuText: 'Renovation of Licence',
  },
];

export const dashboardTableData = [
  {
    id: 1, desc: 'ibm', status: 0, name: 'IBM', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 2, desc: 'microsoft', status: 1, name: 'Microsoft', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 3, desc: 'oracle', status: 1, name: 'Oracle', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 4, desc: 'sap', status: 0, name: 'Sap', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 5, desc: 'vmware inc.', status: 0, name: 'VMware Inc.', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 1, desc: 'ibm', status: 0, name: 'IBM', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 2, desc: 'microsoft', status: 1, name: 'Microsoft', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 3, desc: 'oracle', status: 1, name: 'Oracle', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 4, desc: 'sap', status: 0, name: 'Sap', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
  {
    id: 5, desc: 'vmware inc.', status: 0, name: 'VMware Inc.', month1: `$${parseInt(Math.random() * 10000, 10)}.00`, month2: `$${parseInt(Math.random() * 10000, 10)}.00`, month3: `$${parseInt(Math.random() * 10000, 10)}.00`,
  },
];

export const dayList = [
  { id: 0, text: tra('Pazartesi'), sort: tra('Pzt') },
  { id: 1, text: tra('Salı'), sort: tra('Sal') },
  { id: 2, text: tra('Çarşamba'), sort: tra('Çar') },
  { id: 3, text: tra('Perşembe'), sort: tra('Per') },
  { id: 4, text: tra('Cuma'), sort: tra('Cum') },
  { id: 5, text: tra('Cumartesi'), sort: tra('Cmt') },
  { id: 6, text: tra('Pazar'), sort: tra('Paz') },
];

export const monthList = [
  { id: 0, text: tra('Ocak'), sort: tra('Ock') },
  { id: 1, text: tra('Şubat'), sort: tra('Şub') },
  { id: 2, text: tra('Mart'), sort: tra('Mrt') },
  { id: 3, text: tra('Nisan'), sort: tra('Nis') },
  { id: 4, text: tra('Mayıs'), sort: tra('May') },
  { id: 5, text: tra('Haziran'), sort: tra('Haz') },
  { id: 6, text: tra('Temmuz'), sort: tra('Tem') },
  { id: 7, text: tra('Agustos'), sort: tra('Agu') },
  { id: 8, text: tra('Eylül'), sort: tra('Eyl') },
  { id: 9, text: tra('Ekim'), sort: tra('Eki') },
  { id: 10, text: tra('Kasım'), sort: tra('Kas') },
  { id: 11, text: tra('Aralık'), sort: tra('Ara') },
];

export const templateTr = {
  accepted: tra('accepted'),
  after: tra('after'),
  after_or_equal: tra('after_or_equal'),
  alpha: tra('alpha'),
  alpha_space: tra('alpha_space'),
  alpha_num: tra('alpha_num'),
  alpha_num_space: tra('alpha_num_space'),
  alpha_num_dash: tra('alpha_num_dash'),
  alpha_num_dash_space: tra('alpha_num_dash_space'),
  array: tra('array'),
  before: tra('before'),
  before_or_equal: tra('before_or_equal'),
  date: tra('date'),
  date_equals: tra('date_equals'),
  email: tra('email'),
  in: tra('in'),
  integer: tra('integer'),
  max: tra('max'),
  min: tra('min'),
  not_in: tra('not_in'),
  not_regex: tra('not_regex'),
  numeric: tra('numeric'),
  phone: tra('phone'),
  regex: tra('regex'),
  required: tra('required'),
  size: tra('size'),
  string: tra('string'),
  typeof: tra('typeof'),
  url: tra('url'),
};

export const Years = [];
for (let i = 0; i < 11; i++) {
  Years.push({ id: new Date().getFullYear() - i, name: (new Date().getFullYear() - i).toString() });
}

export const eventColor = [Colors.red, Colors.green, Colors.blue];

export const notificationsTypes = [
  // types
  // 0 -> Takvim
  // 1 -> comment
  // 2 -> system owner
  // 3 -> technic owner
  // 4 -> business owner
  // 5 -> survey
  // 6 -> App update Noti
  {
    color: Colors.widgetIconBackgClose, link: '/Schedule', icon: <Calendar width={20} color={Colors.white} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Comment width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/Surveys/SurveyAnswer', icon: <Edit color={Colors.white} width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
  {
    color: Colors.blue, link: '/InventoryDetail', icon: <Key width={20} />, button: tra('Goruntule'),
  },
];

export const SheetWithModalEnum = {
  inventoryDetailModalEnum: {
    ARCHIVE: 'Archive',
    INVENTORY_DOCUMENT_MODAL: 'InventoryDocumentModal',
    COMMENT: 'Comment',
    ADD_APP_TO_CONTRACT: 'AddAppToContract',
  },
  hardwareInventoryModalEnum: {
    NEW_HARDWARE: 'NewHardwareInventoryModal',
  },
};

export const allPaths = [
  '/',
  '/Inventories',
  '/Inventories/',
  // '/Schedule',
  // '/Schedule/',
  // '/Schedule/:id',
  // '/Schedule/:id/',
  '/InventoryRegister/:type',
  '/InventoryRegister/:type/',
  '/Notification',
  '/Notification/',
  '/InventoryDetail/:id',
  '/InventoryDetail/:id/',
  '/InventoryDetail',
  '/InventoryDetail/',
  '/Integration',
  '/Integration/',
  '/Reports',
  '/Reports/',
  '/Admin',
  '/Admin/',
  '/Admin/Survey',
  '/Admin/Survey/',
  '/Admin/SurveyUpdate',
  '/Admin/SurveyUpdate/',
  '/Admin/SurveyUpdate/:id',
  '/Admin/SurveyUpdate/:id/',
  '/Admin/SurveyDetail/:id/:appId',
  '/Admin/SurveyDetail/:id/:appId/',
  '/Admin/SurveyDetailApps/:id',
  '/Admin/SurveyDetailApps/:id/',
  '/Admin/SurveyDetail/:id/:appId/SingleSurveyDetail/:userId',
  '/Admin/SurveyDetail/:id/:appId/SingleSurveyDetail/:userId/',
  '/Admin/SurveyChartDetail/:id/:appId',
  '/Admin/SurveyChartDetail/:id/:appId/',
  '/Surveys',
  '/Surveys/',
  '/Surveys/:appId',
  '/Surveys/:appId/',
  '/Surveys/SurveyAnswer/:id/:appId',
  '/Surveys/SurveyAnswer/:id/:appId/',
  '/Surveys/SurveyAnswer/:id/:appId/:isSelectedApp',
  '/Surveys/SurveyAnswer/:id/:appId/:isSelectedApp/',
  '/ProfilSetting',
  '/ProfilSetting/',
  '/Profil',
  '/HardwareInventories',
  '/HardwareRegister/:type/:unit',
  '/HardwareDetail/:id',
  '/HardwareDetail/:id/',
  '/Profil/',
  '/Costing',
  '/Costing/',
  '/CostingRegister/:type',
  '/CostingDetail/Software/:id',
  '/CostingDetail/Hardware/:id',
  '/LicenseFollow',
  '/LicenseFollowDetail/:id',
  '/LicenseFollowRegister',
  '/404',
];

export const hardwareUnitSelect = [
  // {
  //   typeID: 1,
  //   id: 3,
  //   name: tra('Sanal_Makine'),
  // },
  // {
  //   typeID: 2,
  //   id: 4,
  //   name: tra('Yonlendirici'),
  // },
  {
    typeID: 1,
    id: 1,
    name: tra('Sunucu'),
  },
  {
    typeID: 1,
    id: 2,
    name: tra('Depolama'),
  },
  {
    typeID: 2,
    id: 3,
    name: tra('Switch'),
  },
  {
    typeID: 2,
    id: 4,
    name: tra('Erisim_Noktasi'),
  },
  {
    typeID: 2,
    id: 5,
    name: 'IP Cihazlar',
  },
  {
    typeID: 2,
    id: 6,
    name: 'Depolama Cihazı',
  },
  {
    typeID: 2,
    id: 7,
    name: tra('Kabinet'),
  },
  {
    typeID: 2,
    id: 8,
    name: tra('Network Bileşenleri'),
  },
  {
    typeID: 3,
    id: 9,
    name: tra('Guvenlik_Duvari'),
  },
  {
    typeID: 3,
    id: 10,
    name: tra('Diger_Guvenlik_Cihazlari'),
  },
  {
    typeID: 4,
    id: 11,
    name: tra('Bilgisayar'),
  },
  {
    typeID: 4,
    id: 12,
    name: tra('Telefon'),
  },
  {
    typeID: 4,
    id: 13,
    name: tra('Tablet'),
  },
  {
    typeID: 4,
    id: 14,
    name: tra('El_Terminali'),
  },
  {
    typeID: 5,
    id: 15,
    name: `${tra('Yazici')}/${tra('Tarayici')}`,
  },
  {
    typeID: 4,
    id: 17,
    name: tra('Simcard'),

  },
];

export const ServerAccessory = [
  {
    id: 1,
    name: tra('Mouse'),
    fieldName: 'Mouse',
  },
  {
    id: 2,
    name: tra('Klavye'),
    fieldName: 'Keyboard',
  },
  {
    id: 3,
    name: tra('Monitor'),
    fieldName: 'Monitor',
  },
];

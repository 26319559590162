import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import Input from '../../../components/Input';
import { CustomButton } from '../../../assets/styledNew';
import Table from '../../../components/Table';
import { tra } from '../../../commons/utils';
import { getIntegration, getIntegrationResponsible } from '../../../store/actions';
import useResponseStatu from '../../../commons/hook/useResponseStatu';

const responsible_column_table = [
  {
    name: 'sender_namespace',
    text: tra('Gonderen_Adi'),
    // filterable: true,
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    name: 'receiver_namespace',
    text: tra('Alici_Adi'),
    // filterable: true,
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    name: 'sender_component',
    text: tra('Sender'),
    // filterable: true,
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    name: 'receiver_component',
    text: tra('Receiver'),
    // filterable: true,
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    name: 'IntegrationEmails',
    text: tra('Sorumlu_Kisi'),
    // filterable: true,
    type: 'array',
    align: 'left',
    sortable: true,
    customBody: (val) => val.filter((i) => i?.type === 1).map((i) => i?.name)?.join(',') ?? '',

  },
  {
    name: 'Departments',
    text: tra('Sector'),
    // filterable: true,
    type: 'array',
    align: 'left',
    sortable: true,
    customBody: (val) => val.map((i) => i?.name)?.join(',') ?? '',

  },
];

export default function ReportTab() {
  const dispatch = useDispatch();
  // STORE
  const users = useSelector((s) => s?.users);
  const integrationResponsibleData = useSelector((s) => s?.integrationResponsibleData);
  const infoData = useSelector((s) => s?.infos);
  const integrations = useSelector((s) => s?.integration);

  const getInfoStore = (name) => infoData?.[name] || [];

  const { loading } = useResponseStatu(getIntegrationResponsible().type);
  const { loading: initLoading } = useResponseStatu(getIntegration().type);

  const initData = {
    sender_namespace: '',
    receiver_namespace: '',
    sender_id: '',
    receiver_id: '',
    responsible: [],
    sector: [],
    null_responsible: false,
  };
  const [reportState, setReportState] = useState(initData);
  const {
    sender_namespace,
    receiver_namespace,
    sender_id,
    receiver_id,
    responsible,
    sector,
    null_responsible,
  } = reportState;

  useEffect(() => {
    if (!integrations?.length) {
      dispatch(getIntegration());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HANDLE ACTION
  const getFilteredIntegrationsData = (key) => [...new Map(integrations.map((item) => [item[key], item])).values()];

  const handleResponsibleOnChange = ({ type, name }) => (e, data) => {
    switch (type) {
      case 'autoComplete':
        return setReportState((s) => ({ ...s, [name]: data }));
      case 'checkbox':
        // eslint-disable-next-line no-case-declarations
        const val = e.target.checked;
        return setReportState((s) => ({ ...s, [name]: val }));
      default:
        return setReportState((s) => ({ ...s, [name]: e?.target?.value }));
    }
  };

  const responsibleRequest = () => {
    const requiredNameArr = ['sender_namespace', 'receiver_namespace'];
    // const s = this.state.responsibleReportData;
    const data = {
      sender_interface: sender_namespace?.sender_interface ?? '',
      receiver_interface: receiver_namespace?.receiver_interface ?? '',
      sender_id: sender_id?.sender_id ?? '',
      receiver_id: receiver_id?.receiver_id ?? '',
      responsible: responsible?.map((i) => i?.id) ?? [],
      sector: sector?.map((i) => i?.id) ?? [],
      null_responsible: null_responsible ?? false,
    };
    const mutableData = Object.fromEntries(Object.entries(data).filter((i) => {
      if (requiredNameArr.includes(i[0])) return true;
      return Array.isArray(i[1]) ? i[1]?.length : i[1];
    }));
    dispatch(getIntegrationResponsible(mutableData));
  };

  return (
    initLoading ? (
      <div style={{
        display: 'grid',
        gap: '10px',
        gridTemplateColumns: 'repeat(2, 1fr)',
        padding: '30px',
      }}
      >
        {[...Array(6).keys()].map(() => <Skeleton variant="rect" height={30} />)}
        <Skeleton style={{ gridColumn: '1/3' }} variant="rect" height={300} />
      </div>
    )
      : (
        <Grid container spacing={4} style={{ marginTop: 10 }}>
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Input type="autoComplete" multiple={false} labelKey="sender_component" value={sender_id} data={getFilteredIntegrationsData('sender_id')} onChange={handleResponsibleOnChange({ name: 'sender_id', type: 'autoComplete' })} title={tra('Sender')} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input type="autoComplete" multiple={false} labelKey="receiver_component" value={receiver_id} data={getFilteredIntegrationsData('receiver_id')} onChange={handleResponsibleOnChange({ name: 'receiver_id', type: 'autoComplete' })} title={tra('Receiver')} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input type="autoComplete" multiple={false} labelKey="sender_interface" value={sender_namespace} data={getFilteredIntegrationsData('sender_interface')} onChange={handleResponsibleOnChange({ name: 'sender_namespace', type: 'autoComplete' })} title={tra('Sender_Namespace')} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input type="autoComplete" multiple={false} labelKey="receiver_interface" value={receiver_namespace} data={getFilteredIntegrationsData('receiver_interface')} onChange={handleResponsibleOnChange({ name: 'receiver_namespace', type: 'autoComplete' })} title={tra('Receiver_Namespace')} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input type="autoComplete" value={responsible} data={users} onChange={handleResponsibleOnChange({ name: 'responsible', type: 'autoComplete' })} title={tra('Sorumlu_Kisi')} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input type="autoComplete" value={sector} data={getInfoStore('Departments')} onChange={handleResponsibleOnChange({ name: 'sector', type: 'autoComplete' })} title={tra('Sektor')} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input type="checkbox" value={null_responsible} onChange={handleResponsibleOnChange({ name: 'null_responsible', type: 'checkbox' })} title={tra('Responsible_Bring_Empty')} />
            </Grid>
            <Grid
              container
              item
              justify="flex-end"
              spacing={3}
              xs={12}
            >
              <CustomButton onClick={responsibleRequest}>{tra('Arama_Yap')}</CustomButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              lazyLoading={loading}
              id="integrationResponsibleTable"
              data={integrationResponsibleData || []}
              columns={responsible_column_table}
              options={{
                pagination: {
                  active: true,
                  perPage: 13,
                },
              }}
            />
          </Grid>
        </Grid>
      )
  );
}

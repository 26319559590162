/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  Close, DeleteOutline, ErrorOutline, UnfoldMore, Delete, Public, Check, // Remove,
} from '@material-ui/icons';
import withWidth from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  Colors,
} from '../../assets/statics';
import {
  ActionFooter, CustomButton, GridPageWrapper, InputWrapper, PageContent, PageWrapper,
  CustomMenu,
} from '../../assets/styledNew';
import {
  getCostingCategories, createCosting, setResetState, setCostingFiles, setToast, getVendorUsers,
  getCosting, setCostingFileUrls, setSendToDetailPageID, setSendToView, getHWCostingCategories,
} from '../../store/actions';
import {
  selectApplications, selectCostingCategories, selectCostingFiles, selectInfos, selectResetState,
  selectActiveUser, selectCostingList, selectCostingFileUrls, selectSendToDetailPageID, selectSendToView, selectVendorUsers,
} from '../../store/selectors';
import {
  CommaStringToNumber, FormatCurrency, tra,
} from '../../commons/utils';
import { Plus, DocumentPDF } from '../../assets/icons';
import AllocationPie from '../../components/charts/AllocationPie';
import AddAppToContract from '../../components/modals/AddAppToContract';
import { CostingContext } from '../../commons/context/CostingContext';
import CostingDocModal from '../../components/modals/CostingDocModal';
import FastInput from '../../components/costing/CostFastInput';
import Validator from '../../commons/validator';

class CostingRegister extends Component {
  static contextType = CostingContext;

  constructor(props) {
    super(props);
    this.state = {
      compCurrency: '',
      fileArr: [],
      fileUrlArr: [],
      removeCheckClick: false,
      costPageType: 'Software', // 1: software , 2: hardware
    };
    props.getVendorUsers();
    props.getCosting();
    props.getCostingCategories();
    props.getHWCostingCategories();
  }

  componentDidUpdate() {
    const {
      CostingState, setCostingPageVariables, setCostingState, CostingPageVariables,
    } = this.context;
    const { categories, currencies, user } = this.props;
    // const { costPageType } = this.state;
    if (categories.length > 0 && CostingState.category_id === '') {
      const tempCost = {
        agreement: '',
        service: '',
        vendor_id: null,
        category_id: categories[0].CostingCategories[0].id,
        vendorContact_person: '',
        vendorContact_mail: '',
        vendorContact_phone: '',
        phone_code: null,
        comments: '',
        total: 0,
        total_inv: 0,
        Applications: [],
        ContractUrls: [],
        fc: 0,
        GenericContItems: [
          {
            monthly_inv: 0,
            total_inv: 0,
            total_cost: 0,
            monthly_cost: 0,
            invCurr_type: '',
            period: 0,
            contract_start: new Date(),
            contract_end: new Date(),
            calculated_end: new Date(),
            purchase_date: null,
            fx_rate: 1,
            tco_agg: '',
          },
        ],
      };
      setCostingState(tempCost);
    }
    if (this.props.resetState) {
      this.context.setReset();
      this.resetState();
    }
    if (Number.isInteger(this.props.sendToDetailID) && this.props.sendToView) {
      /**
       * send to detail page if registration is successful by server
       */
      const obj = {
        id: null,
        tabOpen: false,
      };
      this.props.setSendToView(false);
      localStorage.setItem('costfrominventory', JSON.stringify(obj));
      // type gelicek
      this.props.history.push(`/CostingDetail/Software/${this.props.sendToDetailID}`);
    }
    if (categories.length > 0 && CostingPageVariables.costType === '') {
      setCostingPageVariables({
        ...CostingPageVariables,
        costType: categories[0].id,
      });
    }
    if (CostingState.GenericContItems[0].invCurr_type === '' && this.state.compCurrency !== '' && currencies.length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = user.companies && user.companies.find((item) => item.id.toString() === activeComp);
      const currency = currencies.find((item) => item.id === selectedComp.currency_id);
      setCostingState((prvState) => {
        const tempContracts = CostingState.GenericContItems;
        tempContracts[0].invCurr_type = currency.id;
        return {
          ...prvState,
          GenericContItems: tempContracts,
        };
      });
      setCostingPageVariables((prvState) => ({
        ...prvState,
        currency: currency.name,
      }));
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const pathArr = props.history.location.pathname.split('/');
    if (state.costPageType === '' && state.costPageType !== pathArr[2]) {
      return {
        costPageType: pathArr[2],
      };
    }
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.name;
      return {
        compCurrency: currency,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.resetState();
    this.context.setReset();
    this.props.setSendToDetailPageID(false);
  }

  resetState = () => {
    this.setState({ fileArr: [], fileUrlArr: [] });
    this.props.setResetState(false);
    this.props.setCostingFiles([]);
    this.props.setCostingFileUrls([]);
    Validator.hideMessages();
  }

  changeCostType = (costId, categoryId, type) => {
    const { compCurrency } = this.state;
    const contractTypes = this.props.categories.find((item) => item.id === costId).CostingCategories;
    const { currencies } = this.props;
    this.props.setCostingFiles([]);
    this.props.setCostingFileUrls([]);
    const tempCost = {
      agreement: '',
      service: '',
      vendor_id: null,
      category_id: type === 'costType' ? contractTypes[0].id : categoryId,
      vendorContact_person: '',
      vendorContact_mail: '',
      vendorContact_phone: '',
      phone_code: null,
      comments: '',
      total: 0,
      total_inv: 0,
      Applications: [],
      ContractUrls: [],
      fc: 0,
      GenericContItems: [
        {
          monthly_inv: 0,
          total_inv: 0,
          total_cost: 0,
          monthly_cost: 0,
          invCurr_type: currencies.find((item) => item.name === compCurrency).id,
          period: 0,
          contract_start: new Date(),
          contract_end: new Date(),
          calculated_end: new Date(),
          purchase_date: null,
          fx_rate: 1,
          tco_agg: '',
        },
      ],
    };
    this.context.setCostingState(tempCost);
    this.context.setCostingPageVariables({
      costType: costId,
      addAppModal: false,
      allocationTotal: 0,
      sendApplications: [],
      costingDocModal: false,
      currency: compCurrency,
      appValueStatus: 1,
      appValueStatusMenu: null,
    });
    Validator.hideMessages();
    this.forceUpdate();
  }

  createCost = (sendToView) => {
    const { costingFiles, costingFileUrls } = this.props;
    const { CostingState, CostingPageVariables } = this.context;
    const sendData = { ...CostingState };
    if (CostingPageVariables.sendApplications.length > 0) {
      if (!Validator.allValid()) {
        Validator.showMessages();
        this.forceUpdate();
        this.props.setToast(true, tra('Lutfen_Zorunlu_Alanlari_Doldurun'), 'warning');
      } else {
        sendData.Applications = CostingPageVariables.sendApplications;
        sendData.ContractUrls = costingFileUrls;
        sendData.vendor_id = CostingState?.vendor_id?.id;
        for (let i = 0; i < sendData.GenericContItems.length; ++i) {
          sendData.GenericContItems[i].fx_rate = CommaStringToNumber(sendData.GenericContItems[i].fx_rate);
          const contractStart = new Date(sendData.GenericContItems[i].contract_start).setHours(13, 0, 0, 0);
          const contractEnd = new Date(sendData.GenericContItems[i].contract_end).setHours(13, 0, 0, 0);
          const calculatedEnd = new Date(sendData.GenericContItems[i].calculated_end).setHours(13, 0, 0, 0);
          if (sendData.GenericContItems[i].purchase_date !== null) {
            const purchaseDate = new Date(sendData.GenericContItems[i].purchase_date).setHours(13, 0, 0, 0);
            sendData.GenericContItems[i].purchase_date = new Date(purchaseDate);
          }
          // const timeOffSetInMs = contractStart.getTimezoneOffset() * 60000;
          // const newDate = contractStart.setTime(contractStart.getTime() + timeOffSetInMs);
          sendData.GenericContItems[i].contract_start = new Date(contractStart);
          sendData.GenericContItems[i].contract_end = new Date(contractEnd);
          sendData.GenericContItems[i].calculated_end = new Date(calculatedEnd);
        }
        sendData.total_inv = CommaStringToNumber(sendData.total_inv);
        sendData.total = CommaStringToNumber(sendData.total);
        sendData.phone_code = sendData.phone_code?.dial_code;

        this.props.createCosting(sendData, costingFiles, sendToView);
      }
    } else {
      this.props.setToast(true, tra('Uygulama_Ekle_Mesaji'), 'warning');
    }
  }

  handleSubmitToFromViewDetail = () => {
    this.createCost(true);
  }

  render() {
    const {
      compCurrency, fileArr, fileUrlArr, removeCheckClick, costPageType,
    } = this.state;
    const {
      CostingState, CostingPageVariables, removeApp, setCostingPageVariables, handleChangeAppValue, changeAppValueStatusMenu,
      handleChangeAppValueAllocation, handleDateChange, handleChangeCurrency, changeFxRate, addAppToContract, handleChange,
      handlePhoneChange, changeAgrAutoComplete, handleChangeDepreciationPeriod, addNewContract, deleteContract, changeAppValueStatus,
      handlePhoneCodeChange, changeVendorUserFree, autoCompChange,
    } = this.context;
    const {
      applications, categories, vendors, currencies, tcoAggregations, costingList, locations, history,
      vendorUsers, // match,
    } = this.props;
    Validator.purgeFields();
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <GridPageWrapper style={{ width: '100%', paddingBottom: 40 }}>
                <InputWrapper>
                  <Grid container spacing={2}>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={6}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Masraf_Ekle')}</h4>
                              <p>{tra('Add_Costing_Detail')}.</p>
                            </div>
                          </div>
                          <div className="inputList">
                            <FastInput
                              type="select"
                              title={tra('Masraf_Kategorisi')}
                              data={categories}
                              name="costType"
                              stateType="page"
                              onChange={(e) => this.changeCostType(e.target.value, '', 'costType')}
                            />
                            <FastInput
                              type="select"
                              title={tra('Masraf_Tipi')}
                              data={(CostingState.category_id !== '') ? categories.find((item) => item.id === CostingPageVariables.costType) && categories.find((item) => item.id === CostingPageVariables.costType).CostingCategories : []}
                              name="category_id"
                              onChange={(e) => this.changeCostType(CostingPageVariables.costType, e.target.value)}
                            />
                            <FastInput
                              type="autoCompleteCreatable"
                              title={tra('Tanim')}
                              data={costingList}
                              labelKey="agreement"
                              onChange={(e, newValue) => changeAgrAutoComplete(e, newValue)}
                              freeSolo
                              name="agreement"
                              indicate
                              error={Validator.message('agreement', CostingState.agreement, 'required')}
                              helperText={Validator.message('agreement', CostingState.agreement, 'required')}
                            />
                            {costPageType === 'Software' && CostingPageVariables.costType !== 3 && (
                              <FastInput
                                type="text"
                                title={tra('Notlar')}
                                row="4"
                                name="comments"
                                onChange={(e) => handleChange(e, 'comments')}
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid className="rowRight" item xs={6}>
                        <div className="box">
                          <div className="headerGrid" />
                          <div className="inputList">
                            {costPageType === 'Software' ? (
                              <>
                                <div className="multipleInputWithChecbox">
                                  <FastInput
                                    type="text"
                                    title={tra('Servis')}
                                    name="service"
                                    onChange={(e) => handleChange(e, 'service')}
                                    indicate
                                    error={Validator.message('service', CostingState.service, 'required')}
                                    helperText={Validator.message('service', CostingState.service, 'required')}
                                  />
                                  <FastInput
                                    type="checkbox"
                                    title={tra('Tahmini')}
                                    name="fc"
                                    onChange={(e) => handleChange(e, 'fc')}
                                  />
                                </div>
                                {CostingPageVariables.costType !== 3 ? (
                                  <>
                                    <FastInput
                                      type="autoComplete"
                                      name="vendor_id"
                                      title={tra('Vendor')}
                                      onChange={(e, newValue) => autoCompChange(newValue, 'vendor_id')}
                                      data={vendors}
                                      multiple={false}
                                    />
                                    <FastInput
                                      type="autoCompleteCreatable"
                                      title={tra('Tedarikci_Kisi')}
                                      data={vendorUsers.filter((item) => item.vendor_id === CostingState?.vendor_id?.id) || []}
                                      labelKey="person"
                                      secondLabelKey="mail"
                                      onChange={(e, newValue) => changeVendorUserFree(e, newValue)}
                                      freeSolo
                                      name="vendorContact_person"
                                    />
                                    <FastInput
                                      type="text"
                                      title={tra('Tedarikci_Eposta')}
                                      name="vendorContact_mail"
                                      onChange={(e) => handleChange(e, 'vendorContact_mail')}
                                    />
                                    <FastInput
                                      type="tel"
                                      title={tra('Tedarikci_Numarasi')}
                                      name="vendorContact_phone"
                                      onValueChange={(e) => handlePhoneChange(e, 'vendorContact_phone')}
                                      valueKey="dial_code"
                                      data={locations}
                                      telCode={CostingState.phone_code}
                                      onChangeTelCode={(e, newValue) => handlePhoneCodeChange(e, newValue)}
                                    />
                                  </>
                                ) : (
                                  <FastInput
                                    type="text"
                                    title={tra('Notlar')}
                                    row="4"
                                    name="comments"
                                    onChange={(e) => handleChange(e, 'comments')}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <FastInput
                                  type="text"
                                  title={tra('Notlar')}
                                  row="4"
                                  name="comments"
                                  onChange={(e) => handleChange(e, 'comments')}
                                />
                                <FastInput
                                  type="select"
                                  emptyItem
                                  name="expense_type_id"
                                  title={tra('Expense_Type')}
                                  onChange={(e) => handleChange(e, 'expense_type_id')}
                                  data={vendors}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {CostingState.GenericContItems.map((rowItem, index) => (
                      <Grid className={index !== CostingState.GenericContItems.length - 1 ? 'gridRowDashed' : 'gridRow'} container item xs={12} spacing={3}>
                        {((index !== 0) || CostingState.GenericContItems.length > 1) && (
                          <Grid container justify="flex-end" item xs={12}>
                            <CustomButton
                              style={{ padding: 0, marginRight: 40 }}
                              opacity
                              onClick={() => deleteContract(index)}
                            >
                              <DeleteOutline />
                            </CustomButton>
                          </Grid>
                        )}
                        <Grid className="rowLeft" item xs={6}>
                          <div className="box">
                            {index === 0 && (
                              <div className="headerGrid">
                                <div className="left">
                                  <h4>{tra('Date_Information')}</h4>
                                  <p>{tra('Date_Information_Detail')}.</p>
                                </div>
                              </div>
                            )}
                            <div className="inputList">
                              {CostingState.category_id === 2 ? (
                                <>
                                  <FastInput
                                    type="date"
                                    title={tra('Satin_Alma_Tarihi')}
                                    value={rowItem.purchase_date}
                                    onChange={handleDateChange('purchase_date', index)}
                                  />
                                  <FastInput
                                    type="date"
                                    title={tra('Begining_Of_Depreciation')}
                                    value={rowItem.contract_start}
                                    onChange={handleDateChange('contract_start', index)}
                                  />
                                  <FastInput
                                    type="currency"
                                    decimalScale={2}
                                    title={tra('Depreciation_Period')}
                                    value={rowItem.period}
                                    onChange={handleChangeDepreciationPeriod(index)}
                                    indicate
                                    error={Validator.message('period', rowItem.period, 'required')}
                                    helperText={Validator.message('period', rowItem.period, 'required')}
                                  />
                                  <FastInput
                                    type="date"
                                    title={tra('Calculated_End_Date')}
                                    value={rowItem.calculated_end}
                                    disabled
                                  />
                                </>
                              ) : (
                                <>
                                  <FastInput
                                    type="date"
                                    title={tra('Sozlesme_Baslangic')}
                                    value={rowItem.contract_start}
                                    onChange={handleDateChange('contract_start', index)}
                                  />
                                  <FastInput
                                    type="date"
                                    title={tra('Sozlesme_Bitis')}
                                    value={rowItem.contract_end}
                                    minDate={CostingState.contract_start}
                                    onChange={handleDateChange('contract_end', index)}
                                  />
                                  {CostingPageVariables.costType !== 3 && (
                                    <FastInput
                                      type="date"
                                      title={tra('Satin_Alma_Tarihi')}
                                      value={rowItem.purchase_date}
                                      onChange={handleDateChange('purchase_date', index)}
                                    />
                                  )}
                                  <FastInput
                                    type="currency"
                                    decimalScale={2}
                                    title={tra('Aylik_Periyot')}
                                    disabled
                                    value={rowItem.period}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid className="rowRight" item xs={6}>
                          <div className="box">
                            {index === 0 && (
                              <div className="headerGrid">
                                <div className="left">
                                  <h4>{tra('Cost_Information')}</h4>
                                  <p>{tra('Cost_Information_Detail')}.</p>
                                </div>
                              </div>
                            )}
                            <div className="inputList">
                              <div className="multipleInputWrapper">
                                <FastInput
                                  type="select"
                                  title={tra('Invoice_Currency')}
                                  data={currencies}
                                  value={rowItem.invCurr_type}
                                  onChange={(e) => handleChange(e, 'invCurr_type', index)}
                                />
                                <FastInput
                                  disabled={currencies.find((item) => item.id === rowItem.invCurr_type)?.name === compCurrency}
                                  type="number"
                                  title={tra('Fx_Rate')}
                                  value={rowItem.fx_rate}
                                  indicate
                                  error={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                                  helperText={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                                  onChange={(e) => changeFxRate(e, 'change', '', index)}
                                  plusClick={() => changeFxRate('', 'buttonClick', 'plus', index)}
                                  minusClick={() => changeFxRate('', 'buttonClick', 'minus', index)}
                                />
                              </div>
                              <div className="multipleInputWrapper">
                                <FastInput
                                  type="currency"
                                  decimalScale={2}
                                  title={`${tra('Total_Amount_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                                  value={rowItem.total_cost}
                                  onChange={(e) => {
                                    Validator.showMessageFor('allocationTotal');
                                    handleChangeCurrency(e, index);
                                  }}
                                  indicate
                                  error={Validator.message('total_cost', rowItem.total_cost, 'required')}
                                  helperText={Validator.message('total_cost', rowItem.total_cost, 'required')}
                                />
                                <FastInput
                                  type="currency"
                                  decimalScale={2}
                                  title={`${CostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                                  value={rowItem.monthly_cost}
                                  disabled
                                />
                              </div>
                              {currencies.find((item) => item.id === rowItem.invCurr_type)?.name !== compCurrency && (
                                <div className="multipleInputWrapper">
                                  <FastInput
                                    type="currency"
                                    decimalScale={2}
                                    title={`${tra('Total_Amount_In')} ${compCurrency}`}
                                    disabled
                                    value={rowItem.total_inv}
                                  />
                                  <FastInput
                                    type="currency"
                                    decimalScale={2}
                                    title={`${CostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${compCurrency}`}
                                    value={rowItem.monthly_inv}
                                    disabled
                                  />
                                </div>
                              )}
                              <div className="multipleInputWrapper">
                                <FastInput
                                  type="select"
                                  title={tra('Run_Cost_Aggrigation')}
                                  value={rowItem.tco_agg}
                                  data={tcoAggregations}
                                  onChange={(e) => handleChange(e, 'tco_agg', index)}
                                  indicate
                                  error={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                                  helperText={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        {index === CostingState.GenericContItems.length - 1 && (
                          <Grid item xs={12}>
                            <CustomButton
                              transparent
                              startIcon={<Plus width={12} />}
                              onClick={addNewContract}
                            >{tra('Split_Existing_Contract')}
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={8}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Allocation_Of_Cost_To_Applications')}</h4>
                              <p>{tra('Allocation_Of_Cost_To_Applications_Detail')}.</p>
                            </div>
                          </div>
                          {CostingPageVariables.sendApplications.length > 0 && (
                            <div className="allocationTable">
                              <Table>
                                <TableHead>
                                  <TableCell />
                                  <TableCell>{tra('Allocation')} %</TableCell>
                                  <TableCell>{tra('Allocation_In')} {currencies.find((item) => item.id === CostingState.GenericContItems[0].invCurr_type)?.name}</TableCell>
                                  <TableCell />
                                </TableHead>
                                <TableBody>
                                  {CostingPageVariables.sendApplications.map((item) => (
                                    <TableRow>
                                      <TableCell><p>{applications.find((app) => app.id === item.id).name}</p></TableCell>
                                      <TableCell>
                                        <FastInput
                                          type="currency"
                                          decimalScale={3}
                                          suffix="%"
                                          value={item.allocation}
                                          onChange={(e) => {
                                            handleChangeAppValueAllocation(e, item.id);
                                            Validator.showMessageFor('allocationTotal');
                                          }}
                                          disabled={CostingPageVariables.appValueStatus === 1}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FastInput
                                          type="currency"
                                          decimalScale={2}
                                          value={FormatCurrency(item.value)}
                                          onChange={(e) => {
                                            handleChangeAppValue(e, item.id);
                                            Validator.showMessageFor('allocationTotal');
                                          }}
                                          disabled={CostingPageVariables.appValueStatus === 2}
                                        />
                                      </TableCell>
                                      <TableCell><IconButton onClick={() => removeApp(item.id)}><Close /></IconButton></TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow className="totalRow">
                                    <TableCell><p>{tra('Total_Allocated')} {CostingPageVariables.appValueStatus === 1 ? tra('Amount') : tra('Percentage')} {CostingPageVariables.appValueStatus === 1 && `in ${currencies.find((item) => item.id === CostingState.GenericContItems[0].invCurr_type)?.name}`}</p></TableCell>
                                    <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(CostingPageVariables.allocationTotal)}{CostingPageVariables.appValueStatus === 2 && '%'}</p></TableCell>
                                  </TableRow>
                                  {CostingPageVariables.allocationTotal - (CostingPageVariables.appValueStatus === 2 ? 100 : CostingState.total) !== 0 && (
                                    <TableRow className="varianceRow">
                                      <TableCell><div style={{ display: 'flex', alignItems: 'center' }}><ErrorOutline /><p style={{ marginLeft: 15 }}>{tra('Fark')}</p></div></TableCell>
                                      <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(CostingPageVariables.allocationTotal - (CostingPageVariables.appValueStatus === 2 ? 100 : CostingState.total))}{CostingPageVariables.appValueStatus === 2 && '%'}</p></TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid style={{ padding: 0 }} className="rowWithoutInfo" item xs={4}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="right">
                              <>
                                <CustomButton
                                  opacity
                                  endIcon={<UnfoldMore width={12} />}
                                  onClick={(e) => changeAppValueStatusMenu(e.currentTarget)}
                                >{CostingPageVariables.appValueStatus === 1 ? tra('Absolute_Value') : tra('Percentage')}
                                </CustomButton>
                                <CustomMenu
                                  anchorEl={CostingPageVariables.appValueStatusMenu}
                                  open={Boolean(CostingPageVariables.appValueStatusMenu)}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  keepMounted
                                  onClose={() => changeAppValueStatusMenu(null)}
                                >
                                  <MenuItem onClick={() => changeAppValueStatus(1)}>{tra('Absolute_Value')}</MenuItem>
                                  <MenuItem onClick={() => changeAppValueStatus(2)}>{tra('Percentage')}</MenuItem>
                                </CustomMenu>
                              </>
                              <CustomButton
                                onClick={() => setCostingPageVariables({
                                  ...CostingPageVariables,
                                  addAppModal: true,
                                })}
                                startIcon={<Plus width={12} />}
                              >{tra('Uygulama_Ekle')}
                              </CustomButton>
                            </div>
                          </div>
                          {CostingPageVariables.sendApplications.length > 0 && (
                            <div className="chartWrapper">
                              <AllocationPie applications={applications} data={CostingPageVariables.sendApplications} />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={12}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Add_Document_And_Link')}</h4>
                              <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>
                            </div>
                            <div>
                              <CustomButton
                                onClick={() => setCostingPageVariables({
                                  ...CostingPageVariables,
                                  costingDocModal: true,
                                })}
                                startIcon={<Plus width={12} />}
                                active
                              >
                                {tra('Ekle')}
                              </CustomButton>
                            </div>
                          </div>
                          <div className="documentList">
                            {(fileArr.length > 0 || fileUrlArr.length > 0) && (
                              <Table>
                                <TableHead>
                                  <TableCell colSpan={4}>{fileArr.length} {tra('Dokuman')} / {fileUrlArr.length} Link</TableCell>
                                </TableHead>
                                <TableBody>
                                  {fileArr.map((file, index) => (
                                    <TableRow>
                                      <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                                      {/* <TableCell style={{ width: 'auto' }}>{match.params.type === 'register' ? file.name : <a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a>}</TableCell> */}
                                      <TableCell style={{ width: 'auto' }}>{file.name}</TableCell>
                                      <TableCell style={{ width: 150, textAlign: 'center' }}>{parseInt(file.size / 1024, 10)} Kb</TableCell>
                                      <TableCell style={{ width: 50, textAlign: 'center' }}>
                                        {removeCheckClick === index
                                          ? (
                                            <div style={{ display: 'flex' }}>
                                              <IconButton
                                                onClick={() => {
                                                  // if (match.params.type !== 'register') {
                                                  //   this.props.deleteFile(file.id, match.params.type, 'draft');
                                                  // } else {
                                                  fileArr.splice(index, 1);
                                                  this.props.setCostingFiles(fileArr);
                                                  this.setState({ fileArr });
                                                  // }
                                                }}
                                                style={{ padding: 5 }}
                                              >
                                                <Check style={{ color: Colors.green }} />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => this.setState({ removeCheckClick: false })}
                                                style={{ padding: 5 }}
                                              >
                                                <Close style={{ color: Colors.red }} />
                                              </IconButton>
                                            </div>
                                          )
                                          : (
                                            <IconButton
                                              onClick={() => this.setState({ removeCheckClick: index })}
                                              style={{ padding: 5 }}
                                            ><Delete />
                                            </IconButton>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  {fileUrlArr.map((url, index) => (
                                    <TableRow>
                                      <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                                      <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                                      <TableCell style={{ width: 50, textAlign: 'center' }}>
                                        <IconButton
                                          onClick={() => {
                                            fileUrlArr.splice(index, 1);
                                            this.props.setCostingFileUrls(fileUrlArr);
                                            this.setState({ fileUrlArr });
                                          }}
                                          style={{ padding: 5 }}
                                        ><Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </InputWrapper>
                <ActionFooter>
                  <CustomButton onClick={() => history.push('/Costing')}>{tra('Iptal_Et')}</CustomButton>
                  <CustomButton onClick={() => this.createCost(false)}>{tra('Save_Close')}</CustomButton>
                  <CustomButton onClick={() => this.handleSubmitToFromViewDetail()}>{tra('Save_View')}</CustomButton>
                </ActionFooter>
              </GridPageWrapper>
            </div>
          </div>
        </PageContent>
        <AddAppToContract
          addApp={addAppToContract}
          open={CostingPageVariables.addAppModal}
          close={() => setCostingPageVariables({
            ...CostingPageVariables,
            addAppModal: false,
          })}
          type={CostingPageVariables.appValueStatus}
          sendApps={CostingPageVariables.sendApplications}
          contractType={CostingState.category_id}
          invoiceCurrency={currencies.find((i) => i.id === CostingState?.GenericContItems?.[0]?.invCurr_type)?.name ?? compCurrency}
        />
        <CostingDocModal
          open={CostingPageVariables.costingDocModal}
          close={() => setCostingPageVariables({
            ...CostingPageVariables,
            costingDocModal: false,
          })}
          type="register"
          fileType="software"
          setFileArr={(files) => this.setState({ fileArr: files })}
          setFileUrlArr={(fileUrl) => this.setState({ fileUrlArr: fileUrl })}
        />
      </PageWrapper>
    );
  }
}

CostingRegister.propTypes = {
  applications: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  costingFiles: PropTypes.array.isRequired,
  getCostingCategories: PropTypes.func.isRequired,
  createCosting: PropTypes.func.isRequired,
  setResetState: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  setCostingFiles: PropTypes.func.isRequired,
  tcoAggregations: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  costingList: PropTypes.array.isRequired,
  getCosting: PropTypes.func.isRequired,
  costingFileUrls: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  setCostingFileUrls: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setSendToDetailPageID: PropTypes.func.isRequired,
  sendToDetailID: PropTypes.number.isRequired,
  sendToView: PropTypes.bool.isRequired,
  setSendToView: PropTypes.func.isRequired,
  getVendorUsers: PropTypes.func.isRequired,
  vendorUsers: PropTypes.array.isRequired,
  getHWCostingCategories: PropTypes.func.isRequired,
  // match: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  applications: selectApplications(),
  categories: selectCostingCategories(),
  vendors: selectInfos('Vendors'),
  currencies: selectInfos('Currencies'),
  tcoAggregations: selectInfos('TcoAggregations'),
  locations: selectInfos('Locations'),
  resetState: selectResetState(),
  costingFiles: selectCostingFiles(),
  user: selectActiveUser(),
  costingList: selectCostingList(),
  costingFileUrls: selectCostingFileUrls(),
  sendToDetailID: selectSendToDetailPageID(),
  sendToView: selectSendToView(),
  vendorUsers: selectVendorUsers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getCostingCategories,
      getHWCostingCategories,
      createCosting,
      setResetState,
      setCostingFiles,
      setToast,
      getCosting,
      setCostingFileUrls,
      setSendToDetailPageID,
      setSendToView,
      getVendorUsers,
    }, dispatch)
  )
);
const CostingRegisterWithTheme = withTheme(CostingRegister);
const CostingRegisterWithWidth = withWidth()(CostingRegisterWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(CostingRegisterWithWidth);

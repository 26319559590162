import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  TextField,
  Tooltip,
  Zoom,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  ClickAwayListener,
  MenuItem,
} from '@material-ui/core';
import {
  EditOutlined,
  SaveAltOutlined,
  AddCircleOutlineOutlined,
  // ViewColumnOutlined,
  // VisibilityOutlined,
  // VisibilityOffOutlined,
  CloseOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowDownOutlined,
  Search,
  Remove,
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
// import { Skeleton } from '@material-ui/lab';
import { FormatUTCLocaleDateStr, isIsoDate, tra } from '../commons/utils';
import { Colors } from '../assets/statics';
import CustomTable, {
  // SettingMenu,
  // SettingMenuItem,
  CustomTableTh,
  CustomTableTr,
  CustomTableTd,
  TablePagination,
  PagiItem,
  CustomTableHeader,
  CustomTableFilter,
  CustomizeColumns,
  CustomMobileTableHeader,
  TablePagiPerPage,
} from '../assets/styledTable';
import Input from './Input';
import {
  Filter,
  ColumnIcon,
} from '../assets/icons';
import { CustomButton, NoData } from '../assets/styledNew';
// import { Comment, Bell } from '../assets/icons';
import NoDataImg from '../assets/img/noData.png';
import { Skeleton } from '@material-ui/lab';

class Table extends Component {
  constructor(props) {
    super(props);
    const { data, options } = props;
    this.state = {
      searchableList: [],
      isActiveFocus: null,
      anchorEl: null,
      allData: Array.isArray(data) ? [...data] : [],
      data: data || [],
      activePage: 1,
      pagiPerPage: options.pagination && options.pagination.perPage ? options.pagination.perPage : JSON.parse(localStorage.getItem(`${this.props.id}Sort`))?.perPage ? JSON.parse(localStorage.getItem(`${this.props.id}Sort`)).perPage : 20,
      searchInputVisible: true,
      sort: {
        column: JSON.parse(localStorage.getItem(`${this.props.id}Sort`))?.column?.name,
        direction: JSON.parse(localStorage.getItem(`${this.props.id}Sort`))?.direction,
      },
      filteredData: [],
      lang: {
        envanterSayisi: tra('Envanter_Sayisi'),
        filtrele: tra('Filtrele'),
        tumunuSil: tra('Tumunu_Sil'),
        sutunFiltrele: tra('Sutun_Filtrele'),
        sutunGizleGoster: tra('Sutun_Gizle_Goster'),
        iptalEt: tra('Iptal_Et'),
        buSatiriKaydet: tra('Bu_Satiri_Kaydet'),
        buSatiriDuzenle: tra('Bu_Satiri_Duzenle'),
        veriYok: tra('Veri_Yok'),
        yeniOgeEkle: tra('Yeni_Oge_Ekle'),
      },
    };
    let columnList = JSON.parse(localStorage.getItem(`columnList${this.props.id}`));
    if (columnList === null) {
      columnList = {};
    }
    this.setState({ ...columnList });
  }

  componentDidMount() {
    let columnList = JSON.parse(localStorage.getItem(`columnList${this.props.id}`));
    const sortTable = JSON.parse(localStorage.getItem(`${this.props.id}Sort`));
    if (sortTable !== null && sortTable.column) {
      this.setSorting(sortTable.column);
    }
    const searchableList = this.props.columns && this.props.columns.filter((i) => i.searchable === true && !i.arrayPath).map((e) => e.name);
    const searchableListArray = this.props.columns && this.props.columns.filter((i) => i.searchable === true && i.arrayPath).map((e) => e.arrayPath);
    if (columnList === null) {
      columnList = {};
    }
    this.setState({
      ...columnList,
      searchableList: [
        ...[this.props.options?.extraSearchList ? this.props.options.extraSearchList : []],
        ...searchableList
      ],
      searchableListArray
    });
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    const sortTable = JSON.parse(localStorage.getItem(`${this.props.id}Sort`));

    if (sortTable !== null ? (data.length !== nextProps.data.length) : (JSON.stringify(data) !== JSON.stringify(nextProps.data))) {
      this.setState({
        data: nextProps.data ? nextProps.data : [],
        allData: Array.isArray(nextProps.data) ? [...nextProps.data] : [],
        activePage: 1,
        isActiveFocus: null,
      });
    }
    // if (nextProps.options?.filter?.filters && JSON.stringify(this.state.filteredData) !== JSON.stringify(nextProps.options?.filter?.filters)) {
    //   this.setFilter(nextProps.options.filter.filters);
    // }

    if (nextProps.options?.filter?.filters) {
      this.setFilter(nextProps.options.filter.filters);
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.tableResetFilter !== this.props.tableResetFilter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filteredData: [] });
      this.props.onTableResetFilter(true);
    }
  }

  saveFunc = (index) => {
    this.props.options.onSave(index);
  }

  cancel = (index) => {
    this.setState({ [`edit${index}`]: false });
  }

  onDelete = (index) => {
    this.props.options.onDelete(index);
  }

  changeColumnVisiblity = (column, text) => {
    let columnList = JSON.parse(localStorage.getItem(`columnList${this.props.id}`));
    if (columnList === null) {
      columnList = {};
    }
    columnList[`columnVisibility${column}${text}`] = !columnList[`columnVisibility${column}${text}`];
    localStorage.setItem(`columnList${this.props.id}`, JSON.stringify(columnList));
    this.setState((prevState) => ({ [`columnVisibility${column}${text}`]: !prevState[`columnVisibility${column}${text}`] }));
  }

  setFilter = (filter) => {
    const { allData } = this.state;
    const sortTable = JSON.parse(localStorage.getItem(`${this.props.id}Sort`));
    if (filter && filter.length) {
      const uniqColumnList = [...new Set(filter.map((item) => item.column))];
      if (uniqColumnList && uniqColumnList.length) {
        let newData = [];
        for (let i = 0; i < uniqColumnList.length; i++) {
          let filteredData = [];
          const valueList = filter.filter((f) => f.column === uniqColumnList[i]);
          if (uniqColumnList[i].split('.').length === 1) {
            if (newData.length === 0) {
              for (let j = 0; j < valueList.length; j++) {
                filteredData = filteredData.concat(allData.filter((item) => item[uniqColumnList[i]] === valueList[j].value));
              }
            } else {
              for (let j = 0; j < valueList.length; j++) {
                newData.filter((item) => item[uniqColumnList[i]] === valueList[j].value && filteredData.push(item));
              }
            }
          } else if (newData.length === 0) {
            for (let j = 0; j < valueList.length; j++) {
              filteredData = filteredData.concat(allData.filter((item) => (JSON.stringify(item[valueList[j].column.split('.')[0]])?.indexOf(valueList[j].value) > -1 && item)));
            }
          } else {
            for (let j = 0; j < valueList.length; j++) {
              newData.filter((item) => JSON.stringify(item[uniqColumnList[i].split('.')[0]]).indexOf(valueList[j].value) > -1 && filteredData.push(item));
            }
          }
          newData = filteredData;
        }
        const total = [...new Set(newData)];
        const tempFilterData = [];
        for (let i = 0; i < filter.length; ++i) {
          tempFilterData.push(filter[i]);
        }
        this.setState({ data: total, filteredData: tempFilterData }, () => {
          if (sortTable && sortTable?.column) {
            this.setSorting(sortTable.column);
          }
        });
      }
    } else {
      this.setState({ filteredData: filter });
    }
  }

  getPagiNumber = () => {
    const { data, pagiPerPage } = this.state;
    const { options } = this.props;
    if (options.pagination.active) {
      const count = data.length / pagiPerPage;
      const pagiArr = [];
      for (let i = 0; i < count; i++) {
        pagiArr.push(i + 1);
      }
      return pagiArr;
    }
    return ['1'];
  }

  changePage = (pageNum) => {
    this.setState({
      activePage: pageNum,
    });
  }

  changePageButtom = (pageNum, type) => {
    if (type === 'prev' && pageNum !== 1) {
      this.setState({
        activePage: pageNum - 1,
      });
    }
    if (type === 'next' && pageNum < this.getPagiNumber().length) {
      this.setState({
        activePage: pageNum + 1,
      });
    }
  }

  getTableData = () => {
    const { data } = this.state;
    const { options } = this.props;
    if (options && options.pagination && options.pagination.active) {
      const { activePage, pagiPerPage } = this.state;
      const datas = [...data];
      const rData = datas.splice((activePage === 0 ? 1 : activePage - 1) * pagiPerPage, pagiPerPage);
      return rData;
    }
    return data;
  }

  openSearch = () => {
    this.setState((prevState) => ({ searchInputVisible: !prevState.searchInputVisible }));
  }

  // searchChange = (val) => {
  //   this.setState({ searchValue: val });
  //   const { allData } = this.state;
  //   if (val.length > 2) {
  //     const filteredData = allData.filter((item) =>
  //       (item.desc && item.desc.toUpperCase().includes(val.toUpperCase()))
  //       || (item.details && item.details.toUpperCase().includes(val.toUpperCase()))
  //       || (item.name && item.name.toUpperCase().includes(val.toUpperCase()))
  //       || (item.phone && item.phone.toUpperCase().includes(val.toUpperCase()))
  //       || (item.email && item.email.toUpperCase().includes(val.toUpperCase()))
  //       || (item.owner_name && item.owner_name.toUpperCase().includes(val.toUpperCase()))
  //       || (item.agreement && item.agreement.toUpperCase().includes(val.toUpperCase()))
  //       || (item.comments && item.comments.toUpperCase().includes(val.toUpperCase()))
  //       || (item.costing_type && item.costing_type.toUpperCase().includes(val.toUpperCase()))
  //       || (item.vendor && item.vendor.toUpperCase().includes(val.toUpperCase()))
  //       || (item.service && item.service.toUpperCase().includes(val.toUpperCase()))
  //       || (item.costing_category && item.costing_category.toUpperCase().includes(val.toUpperCase())
  //       ));
  //     this.setState({ data: filteredData, activePage: 1 });
  //   } else {
  //     this.setState({ data: allData });
  //   }
  // }

  searchChange = (val) => {
    const { allData, searchableList } = this.state;
    if (val.length > 1 && allData && searchableList) {
      const filteredData = allData.filter((tableItem) => {
        return this.isTableData(tableItem, val)
      });
      this.setState({ data: filteredData, activePage: 1 });
    } else { this.setState({ data: allData }); }
  }

  isTableData = (tableItem, searchVal) => {
    const { searchableList, searchableListArray } = this.state;
    const { columns } = this.props;
    const allReturn = [];
 
    // eslint-disable-next-line no-restricted-syntax

    for (const keysName of searchableList) {
      // arancak sütünlar listeleniyor
      const customBodyObject = columns.find((i) => i.name === keysName && i.customBody);

      if (customBodyObject && !React.isValidElement(customBodyObject.customBody(Number(tableItem[keysName])))) {
        allReturn.push([customBodyObject.customBody(Number(tableItem[keysName]))].toString().toUpperCase().includes(searchVal.toUpperCase()));
      } else {
        allReturn.push([tableItem[keysName]].toString().toUpperCase().includes(searchVal.toUpperCase()));
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const keysName of searchableListArray) {
      // arancak sütünlar listeleniyor

      const customBodyObject = columns.find((i) => i.arrayPath === keysName && i.customBody);
      if (customBodyObject && !React.isValidElement(customBodyObject.customBody(Number(tableItem[keysName])))) {
        allReturn.push([customBodyObject.customBody(Number(tableItem[customBodyObject.name]))].toString().toUpperCase().includes(searchVal.toUpperCase()));
      } else if (Array.isArray(tableItem[keysName])) {
        const mapName = columns.find((i) => i.arrayPath === keysName).name;
        const arrayTempData = tableItem[keysName].map((i) => i[mapName]).toString().toUpperCase().includes(searchVal.toUpperCase());
        allReturn.push(arrayTempData);
      } else { allReturn.push([tableItem[keysName]].toString().toUpperCase().includes(searchVal.toUpperCase())); }
    }

    return allReturn.some((e) => e);
  }

  setSorting = (column) => {
    const { data, sort, pagiPerPage } = this.state;
    if (column?.sortable) {
      if (sort.direction === 'desc') {
        const sortedData = data.sort((x, y) => {
          const a = x[column.name] ? typeof x[column.name] !== 'number' ? x[column.name].toUpperCase() : x[column.name] : '';
          const b = y[column.name] ? typeof y[column.name] !== 'number' ? y[column.name].toUpperCase() : y[column.name] : '';
          return a === b ? 0 : a > b ? 1 : -1;
        });
        this.setState({
          sort: { column: column.name, direction: 'asc' },
          data: sortedData,
        });
      } else {
        const sortedData = data.sort((x, y) => {
          const a = x[column.name] ? typeof x[column.name] !== 'number' ? x[column.name].toUpperCase() : x[column.name] : '';
          const b = y[column.name] ? typeof y[column.name] !== 'number' ? y[column.name].toUpperCase() : y[column.name] : '';
          return a === b ? 0 : a < b ? 1 : -1;
        });
        this.setState({
          sort: { column: column.name, direction: 'desc' },
          data: sortedData,
        });
      }
      const obj = {
        column,
        direction: sort.direction,
        perPage: pagiPerPage,
      };
      localStorage.setItem(`${this.props.id}Sort`, JSON.stringify(obj));
    }
  }

  changeFilter = (state, value, text, type) => {
    const { options } = this.props;
    if (options.filter) {
      if (type === 'resetAll') {
        this.setState({ filteredData: [] });
      }
      options.filter.onChange(state, value, text, type);
    }
  }

  handlePagiPages = (pagiArr, activePage) => {
    const tempArr = [];
    if (pagiArr.length < 6) {
      return pagiArr;
    }
    if (activePage === pagiArr[pagiArr.length - 1]) {
      tempArr.push(pagiArr[pagiArr.length - 4]);
      tempArr.push(pagiArr[pagiArr.length - 3]);
    }
    if (activePage === pagiArr[pagiArr.length - 2]) {
      tempArr.push(pagiArr[pagiArr.length - 4]);
    }
    for (let i = 0; i < pagiArr.length; ++i) {
      if (activePage === pagiArr[i] || activePage - 1 === pagiArr[i] || activePage + 1 === pagiArr[i]) {
        tempArr.push(pagiArr[i]);
      }
    }
    if (activePage === 1) {
      tempArr.push(pagiArr[2]);
      tempArr.push(pagiArr[3]);
    }
    if (activePage === 2) {
      tempArr.push(pagiArr[3]);
    }
    return tempArr;
  }

  handleClick = (index, row) => {
    const { rowClick } = this.props;
    if (this.state.isActiveFocus === index) this.setState({ isActiveFocus: null });
    else this.setState({ isActiveFocus: index });
    return rowClick && rowClick(row, index);
  }

  render() {
    const { options, columns, rowClick, rowColorKey, rowColorCase, rowColorBg } = this.props;
    const {
      // searchInputVisible,
      searchValue,
      data,
      sort,
      activePage,
      filteredData,
      lang,
      pagiPerPage,
    } = this.state;
    const pagiNumbers = this.getPagiNumber();
    return (
      <>
        {!isWidthDown('sm', this.props.width) && options.filter && options.filter.show && (
          <CustomTableFilter>
            <div className="boxWrapper">
              {
                data.length > 0 && (
                  <CustomButton onClick={options.filter.drawerChange} startIcon={<Filter />}>
                    {lang.filtrele}
                  </CustomButton>
                )
              }
              {filteredData.map((r) => (
                <div className="filterTag">
                  <IconButton onClick={() => this.changeFilter(r.column, false, null, 'delete')} className="icon">
                    <Remove />
                  </IconButton>
                  <div className="info">
                    <p>{r.header}</p>
                    <p>{isIsoDate(r.label) ? FormatUTCLocaleDateStr(r.label) : r.label}</p>
                  </div>
                </div>
              ))}
              {filteredData.length > 0 && <Link style={{ borderBottom: '1px solid #3E5970' }} to="#" onClick={() => this.changeFilter(null, null, null, 'resetAll')} className="resetAll">{lang.tumunuSil}</Link>}
            </div>
            <div className="descWrapper">
              <p><span>{`(${data.length})`}</span> {lang.envanterSayisi}</p>
            </div>
          </CustomTableFilter>
        )}
        {options.header && options.header.visible && (
          <CustomMobileTableHeader>
            <CustomTableHeader search={options.header.search}>
              <TextField
                type="text"
                placeholder={tra('Arama_Yap')}
                // disabled={!options.header.search || searchInputVisible}
                value={searchValue}
                onChange={(e) => this.searchChange(e.target.value)}
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <div className="headerIcons">
                {options.header.search && (
                  <Tooltip placement="top" TransitionComponent={Zoom} title={tra('Arama_Yap')}>
                    <IconButton onClick={() => this.openSearch()}>
                      <Search style={{ color: this.props.theme.text3 }} />
                    </IconButton>
                  </Tooltip>
                )}
                {options.header.columnSetting && (
                  <CustomizeColumns>
                    <Tooltip placement="top" TransitionComponent={Zoom} title={lang.sutunGizleGoster}>
                      <CustomButton onClick={() => this.setState((prevState) => ({ columnMenu: !prevState.columnMenu }))}>
                        <ColumnIcon />
                        {!isWidthDown('sm', this.props.width) && lang.sutunFiltrele}
                      </CustomButton>
                    </Tooltip>
                    {
                      this.state.columnMenu && (
                        <ClickAwayListener onClickAway={() => this.setState({ columnMenu: false })}>
                          <div className="menu">
                            <div className="wrapper">
                              {
                                columns.map((item) => (
                                  !item.disableSetting && (
                                    <div className="item">
                                      <FormControlLabel
                                        onChange={() => this.changeColumnVisiblity(item.name, item.text)}
                                        control={(
                                          <Checkbox
                                            color="primary"
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            name={item.name}
                                            checked={!this.state[`columnVisibility${item.name}${item.text}`]}
                                          />
                                        )}
                                        label={item.text}
                                      />
                                    </div>
                                  )
                                ))
                              }
                            </div>
                          </div>
                        </ClickAwayListener>
                      )
                    }
                  </CustomizeColumns>
                )}
              </div>
            </CustomTableHeader>
            {isWidthDown('sm', this.props.width) && options.filter && options.filter.show
              && <CustomButton onClick={options.filter.drawerChange}><Filter width={14} /></CustomButton>}
          </CustomMobileTableHeader>
        )}
        <div style={{ width: '100%', overflow: options.scroll ? 'auto' : 'overlay', maxHeight: options.scroll ? 400 : 'unset' }}>
          <CustomTable isRow={options.isRow} pagipos={options.pagination.position}>
            <thead>
              {
                columns && columns.map((column) => (
                  <CustomTableTh
                    textAlign={column.align && column.align}
                    visible={this.state[`columnVisibility${column.name}${column.text}`]}
                    onClick={() => this.setSorting(column)}
                    style={{
                      cursor: column.sortable && 'pointer',
                      paddingRight: column.sortable ? 25 : 8,
                    }}
                  >
                    {column.text}
                    {
                      column.sortable && (
                        <div className="sortable">
                          <KeyboardArrowUpOutlined style={{ color: sort.column === column.name && column.text !== '' && sort.direction === 'desc' && Colors.purple }} />
                          <KeyboardArrowDownOutlined style={{ color: sort.column === column.name && column.text !== '' && sort.direction === 'asc' && Colors.purple }} />
                        </div>
                      )
                    }
                    {/* {
                      sort.column === column.name && column.text !== '' && (
                        sort.direction === 'desc' ? (<ArrowDownward style={{ fontSize: 15, marginLeft: 5 }} />) : (<ArrowUpward style={{ fontSize: 15, marginLeft: 5 }} />)
                      )
                    } */}
                  </CustomTableTh>
                ))
              }
              {data.length !== 0 && options.editable && (<CustomTableTh> </CustomTableTh>)}
            </thead>
            {
              this.props.lazyLoading ?
                <tbody >
                  {[...Array(10)].map((i, index) => <tr key={`loading${index}`} style={{ border: '15px solid transparent' }}>
                    <td style={{ overflow: 'hidden' }} colSpan={columns?.length || 1}><Skeleton variant="rect" height={30} /></td>
                  </tr>)}
                </tbody> :
                <tbody>
                  {
                    data && data.length > 0 && (
                      this.getTableData().map((row, index) => (
                        <CustomTableTr
                          editing={this.state[`edit${index}`]}
                          onClick={() => this.handleClick(index, row)}
                          rowColor={row?.[rowColorKey] ? (row?.[rowColorKey] === rowColorCase) : false}
                          rowColorBg={rowColorBg}
                          style={{ cursor: rowClick && 'pointer' }}
                        >
                          {
                            columns && columns.map((column) => (
                              <Tooltip disableHoverListener={!column.tooltip} title={row[column.name]}>
                                <CustomTableTd
                                  cellWidth={column.cellWidth}
                                  textAlign={column.align && column.align}
                                  visible={this.state[`columnVisibility${column.name}${column.text}`]}
                                  activeFocus={this.props.activeFocus && index === this.state.isActiveFocus}
                                >
                                  {
                                    options.editable && column.editable && this.state[`edit${index}`]
                                      ? (
                                        <Input
                                          type={column.type}
                                          data={column.selectData}
                                          value={row[column.name]}
                                          title=""
                                          onChange={(e) => options.onChange(e, column.name, index, row)}
                                        />
                                      ) : (
                                        column.customBody
                                          ? (
                                            column.customBody(row[column.name], column.name, index, row)
                                          ) : (
                                            column.type === 'date'
                                              ? (
                                                new Date(row[column.name]).toLocaleDateString()
                                              )
                                              : (
                                                column.type === 'select' ? (
                                                  column.name.split('.').length === 1 ? (
                                                    column.selectData.find((item) =>
                                                      item.id === row[column.name]) && column.selectData.find((item) => item.id === row[column.name]).desc
                                                  )
                                                    : column.name.split('.').length === 2
                                                    && (
                                                      column.selectData.find((x) =>
                                                        x.id === row[column.name.split('.')[0]][column.name.split('.')[1] && column.name.split('.')[1]])
                                                      && column.selectData.find((x) =>
                                                        x.id === row[column.name.split('.')[0]][column.name.split('.')[1] && column.name.split('.')[1]]).desc
                                                    )
                                                )
                                                  : column.type === 'array' ? (
                                                    [...new Set(row[column.arrayPath].map((test, i) => test[column.name] + (i !== row[column.arrayPath].length - 1 ? ', ' : '')))]
                                                  )
                                                    : (
                                                      row[column.name]
                                                    )
                                              )
                                          )
                                      )
                                  }
                                </CustomTableTd>
                              </Tooltip>
                            ))
                          }
                          {
                            data.length !== 0 && options.editable && (
                              <CustomTableTd textAlign="center">
                                {
                                  this.state[`edit${index}`]
                                    ? (
                                      <>
                                        <Tooltip TransitionComponent={Zoom} title={lang.buSatiriKaydet}>
                                          <SaveAltOutlined onClick={() => this.saveFunc(index)} style={{ cursor: 'pointer', fontSize: 18 }} />
                                        </Tooltip>
                                        <Tooltip TransitionComponent={Zoom} title={lang.iptalEt}>
                                          <CloseOutlined onClick={() => this.cancel(index)} style={{ cursor: 'pointer', fontSize: 18, marginLeft: 15 }} />
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip TransitionComponent={Zoom} title={lang.buSatiriDuzenle}>
                                          <EditOutlined onClick={() => this.setState({ [`edit${index}`]: true })} style={{ cursor: 'pointer', fontSize: 18, marginRight: 10 }} />
                                        </Tooltip>
                                        {/* <Tooltip TransitionComponent={Zoom} title="Delete This Row">
                                  <DeleteOutlineOutlined onClick={() => this.onDelete(index)} style={{ cursor: 'pointer', fontSize: 18 }} />
                                </Tooltip> */}
                                      </>
                                    )
                                }
                              </CustomTableTd>
                            )
                          }
                        </CustomTableTr>
                      ))
                    )

                  }
                  {
                    data.length === 0 && (
                      <CustomTableTd colSpan={columns && columns.length} style={{ textAlign: 'center', borderWidth: '1px', borderRadius: '15px' }}>
                        <NoData style={{ margin: '50px 0' }}>
                          <img src={NoDataImg} alt="No Data" />
                          <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}</p>
                        </NoData>
                      </CustomTableTd>
                    )
                  }
                  {
                    options.addItem && (
                      <CustomTableTr>
                        <CustomTableTd colSpan={data.length !== 0 && options.editable ? columns.length + 1 : columns.length} style={{ padding: 15 }}>
                          <Button small bg="transparent" fcolor={Colors.purple} onClick={() => options.onAddItem()}>{lang.yeniOgeEkle}<AddCircleOutlineOutlined /></Button>
                        </CustomTableTd>
                      </CustomTableTr>
                    )
                  }
                </tbody>
            }

          </CustomTable>
        </div>
        {
          options.pagination && options.pagination.active && (

            <div style={{ display: 'flex', justifyContent: options.pagination.perPage ? 'flex-end' : 'space-between', alignItems: 'center' }}>
              {!options.pagination.perPage && (
                <TablePagiPerPage>
                  <p>{tra('Rows_Per_Page')}: </p>
                  <TextField
                    select
                    variant="outlined"
                    value={pagiPerPage}
                    onChange={(e) => {
                      this.setState({ pagiPerPage: e.target.value });
                      const obj = {
                        column: JSON.parse(localStorage.getItem(`${this.props.id}Sort`))?.column,
                        direction: sort.direction,
                        perPage: e.target.value,
                      };
                      localStorage.setItem(`${this.props.id}Sort`, JSON.stringify(obj));
                    }}
                  >
                    {
                      [20, 40, 60].map((item) => (
                        <MenuItem value={item}>{item.toString()}</MenuItem>
                      ))
                    }
                  </TextField>
                </TablePagiPerPage>
              )}
              {pagiNumbers.length > 1 && (
                <TablePagination>
                  {
                    activePage !== 1 && (
                      <PagiItem disabled={activePage === 1} onClick={() => this.changePageButtom(activePage, 'prev')} style={{ marginRight: 5 }}><KeyboardArrowLeftOutlined /> {tra('Önceki')}</PagiItem>
                    )
                  }
                  {
                    // first index
                    pagiNumbers.length > 5 && (pagiNumbers[0] < activePage - 1) && (
                      <PagiItem onClick={() => this.changePage(1)}>{pagiNumbers[0]}</PagiItem>
                    )
                  }
                  {
                    pagiNumbers.length > 5 && activePage > 3 && (
                      <PagiItem>...</PagiItem>
                    )
                  }
                  {
                    this.handlePagiPages(pagiNumbers, activePage).map((i) => (
                      <PagiItem active={activePage === i} onClick={() => this.changePage(i)}>{i}</PagiItem>
                    ))
                  }
                  {
                    pagiNumbers.length > 5 && (activePage !== pagiNumbers[pagiNumbers.length - 1] && activePage !== pagiNumbers[pagiNumbers.length - 2] && activePage !== pagiNumbers[pagiNumbers.length - 3]) && (
                      <PagiItem>...</PagiItem>
                    )
                  }
                  {
                    // lastIndex
                    pagiNumbers.length > 5 && (pagiNumbers[pagiNumbers.length - 1] - 1 > activePage) && (
                      <PagiItem onClick={() => this.changePage(pagiNumbers[pagiNumbers.length - 1])}>{pagiNumbers[pagiNumbers.length - 1]}</PagiItem>
                    )
                  }
                  {
                    activePage !== pagiNumbers.length && (
                      <PagiItem disabled={activePage === pagiNumbers.length} onClick={() => this.changePageButtom(activePage, 'next')} style={{ marginLeft: 5 }}>{tra('Sonraki')} <KeyboardArrowRightOutlined /></PagiItem>
                    )
                  }
                </TablePagination>
              )}
            </div>
          )
        }
      </>
    );
  }
}

// columns = { // Columns Props
//   disableSetting: false,
//   name: 'isRemoved',
//   text: 'Durum',
//   editable: false,
//   align: 'center',
//   sortable: true,
//   customBody: (value) => {},
//   tooltip: false,
//   filterable: false,
//   filterLocation: 'locations',
//   arrayPath: 'BusinessOwners',
// };

Table.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
  rowClick: PropTypes.func,
  theme: PropTypes.object.isRequired,
  activeFocus: PropTypes.bool,
  tableResetFilter: PropTypes.bool,
  rowColorBg: PropTypes.string,
  lazyLoading: PropTypes.bool
};

Table.defaultProps = {
  lazyLoading: false,
  options: {
    isRow: false,
    pagination: {
      active: false,
      perPage: 0,
    },
    footer: false,
    editable: false,
    addItem: false,
    row: {},
    filter: [],
    onChange: () => { },
    onDelete: () => { },
    onSave: () => { },
    onAddItem: () => { },
    header: {
      visible: false,
      columnSetting: false,
      search: false,
    },
  },
  rowClick: false,
  activeFocus: false,
  tableResetFilter: false,
  rowColorBg: 'rgba(33, 69, 212, 0.5)'
};

const TableWithTheme = withTheme(Table);
const TableWithWidth = withWidth()(TableWithTheme);

export default TableWithWidth;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid, IconButton, MenuItem, Tooltip, Slide,
} from '@material-ui/core';
import { AssignmentTurnedIn } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Colors } from '../../assets/statics';
import { DragToScroll } from '../../commons/touch';
import {
  PageWrapper, PageContent, CustomButton, CustomTabHead, CustomTabs, TabWrapper,
  AdminSurveyTabs, CustomMenu,
  BottomNavigationMobile, Placeholder, NoData, CustomBottomNavigationAction,
} from '../../assets/styledNew';
import {
  File, Configuration, Plus, Search, RightArrow, Person, Draft, EyeFilled, CheckedCircle, Business,
  Pen, PieIcon, PersonFilled, IntegrationIcon,
} from '../../assets/icons';
import Input from '../../components/Input';
import {
  getUsers, getSurveyList, getSurveyDetail, getSingleSurvey, endSurvey, setResetState,
  getAdminCompanies, getAdminCompanyUsers, getCompanyDepartments, getSurveyDetailApps, getVendorUsers,
} from '../../store/actions';
import {
  selectResetState, selectSurveyList, selectUserModule, selectUsers,
} from '../../store/selectors';
import { tra, hexToRgbA, isUserModule } from '../../commons/utils';
import { SurveyCard, SurveyListMobile } from '../../assets/styledSurveyQuestions';
import NoDataImg from '../../assets/img/noData.png';
import Validator from '../../commons/validator';
import ManageVendor from './ManageVendor';
import ManageUsers from './ManageUsers';
import ManageIntegration from './ManageIntegration';
import ManageCompany from './ManageCompany';
import Configurations from './Configurations';
import moduleType from '../../commons/enum/moduleType';
import { ModuleSwitch } from '../../commons/ModuleSwitch';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      surveyCardFilter: 0,
      surveySearch: '',
      surveyAllPageMobile: new Array(3),
      scroll: {
        lastValue: 0,
        status: true,
      },
      selectedAppsForSurvey: [],
      selectedAppForDetail: '',
    };
    props.getUsers();
    props.getVendorUsers();
    props.getSurveyList();
    props.getAdminCompanies();
    this.hiddenInput = React.createRef();
  }

  componentDidMount() {
    document.querySelector('.mainContent').addEventListener('scroll', (e) => this.handlescroll(e));
  }

  componentDidUpdate() {
    if (this.state.tabValue === 0 && this.props.resetState) {
      this.props.setResetState(false);
    }
  }

  componentWillUnmount() {
    document.querySelector('.mainContent').addEventListener('scroll', (e) => this.handlescroll(e));
    this.setState({
      selectedAppsForSurvey: [],
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (localStorage.getItem('manageSurvey') === '2') {
      localStorage.setItem('manageSurvey', '0');
      return {
        tabValue: 1,
      };
    }
    if ((nextProps.surveyList.length > 0 && prevState.selectedAppsForSurvey.length === 0) || (nextProps.surveyList.length > 0 && (nextProps.surveyList.length !== prevState.selectedAppsForSurvey.length))) {
      const temp = [];
      for (let i = 0; i < nextProps.surveyList.length; ++i) {
        const obj = {
          surveyId: nextProps.surveyList[i].id,
        };
        if (nextProps.surveyList[i].Applications.length > 1) {
          obj.appId = nextProps.surveyList[i].Applications[0].id;
        }
        temp.push(obj);
      }
      return { selectedAppsForSurvey: temp };
    }
    return null;
  }

  isUserModuleFunc = (modulTypeArr) => isUserModule(modulTypeArr)(this.props.initModule);

  handlescroll = (e) => {
    const { scroll } = this.state;
    const currentScroll = e.target.scrollTop;
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
    this.timer = window.setTimeout(() => {
      if (currentScroll >= scroll.lastValue + 10) {
        scroll.status = false;
      } else {
        scroll.status = true;
      }
      scroll.lastValue = currentScroll;
      this.setState({ scroll });
    }, 100);
  }

  handleCardMenu = (e, id) => {
    this.setState({ cardMenu: e.currentTarget, selectedId: id });
  }

  handleUpdateSurvey = (id) => {
    const { history } = this.props;
    this.props.getSingleSurvey(id);
    history.push(`Admin/SurveyUpdate/${id}`);
  }

  endSurvey = (id) => {
    this.props.endSurvey(id);
    this.setState({ cardMenu: false });
  }

  handleCardIcon = (active, type) => {
    if (active) {
      switch (type) {
        case 0:
          return <File width={25} />;
        case 1:
          return <AssignmentTurnedIn />;
        case 2:
          return <Draft width={25} />;
        default:
          return <File width={25} />;
      }
    }
    return <AssignmentTurnedIn />;
  }

  handleCardList = () => {
    const { seeAll, surveyCardFilter } = this.state;
    const { surveyList } = this.props;
    const temp = [];
    for (let i = 0; i < surveyList.length; ++i) {
      switch (surveyCardFilter) {
        case 0:
          temp.push(surveyList[i]);
          break;
        case 1:
          if (surveyList[i].active === 1) {
            temp.push(surveyList[i]);
          }
          break;
        case 2:
          if (surveyList[i].active === 0) {
            temp.push(surveyList[i]);
          }
          break;
        default:
          break;
      }
    }
    if (seeAll) {
      return temp;
    }
    if (!seeAll && temp.length > 11) {
      const temp2 = [];
      for (let i = 0; i < 11; ++i) {
        temp2.push(temp[i]);
      }
      return temp2;
    }
    return temp;
  }

  handleSearchCards = (cards) => {
    const { surveySearch } = this.state;
    const temp = [];
    if (surveySearch !== '') {
      for (let i = 0; i < cards.length; ++i) {
        if (cards[i].name.toUpperCase().indexOf(surveySearch.toUpperCase()) > -1) {
          temp.push(cards[i]);
        }
      }
      return temp;
    }
    return cards;
  }

  onChangeApp = (e, surveyId) => {
    const { selectedAppsForSurvey } = this.state;
    selectedAppsForSurvey.find((item) => item.surveyId === surveyId).appId = e.target.value;
    this.setState({ selectedAppsForSurvey, selectedAppForDetail: e.target.value });
  }

  surveyCard = (item) => {
    const {
      selectedId, selectedAppsForSurvey, selectedAppForDetail,
    } = this.state;
    const { theme } = this.props;
    const createdDate = new Date(item.createdAt);
    const date = {
      year: createdDate.toLocaleDateString('tr', { year: 'numeric' }),
      day: createdDate.toLocaleDateString('tr', { day: 'numeric' }),
      month: createdDate.toLocaleDateString('tr', { month: 'long' }),
    };
    return (
      <SurveyCard style={{ height: (!isWidthDown('sm', this.props.width) ? 180 : 1500) }} key={item.id} started={item.isDraft === 0} bar={item.total_complete_survey_count} volume={item.total_survey_count} color={item.color}>
        <div className="createdCard">
          <div className="cardHeader">
            {/* <div className="smallCircle" /> */}
          </div>
          <div className="content">
            <div className="icon">
              {this.handleCardIcon(item.active === 1, item.isDraft)}
            </div>
            <div className="info">
              <Tooltip title={item.name}>
                <h4>{item.name}</h4>
              </Tooltip>
            </div>
            <div className="menu">
              <IconButton onClick={(e) => this.handleCardMenu(e, item.id)}>
                <MoreVertIcon style={{ color: this.props.theme.border }} />
              </IconButton>
              <CustomMenu
                key={item.id}
                anchorEl={this.state.cardMenu}
                open={Boolean(this.state.cardMenu) && selectedId === item.id}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                onClose={() => this.setState({ cardMenu: null })}
              >
                <Link onClick={() => this.props.getSurveyDetail(item.id, item.Applications[0]?.id)} to={`/Admin/SurveyDetail/${item.id}/${item.Applications.length === 1 ? item.Applications[0]?.id : (selectedAppForDetail !== '' ? selectedAppForDetail : item.Applications[0]?.id)}`}>
                  <MenuItem><EyeFilled color={this.props.theme.blueText} />{tra('Anketi_Gor')}</MenuItem>
                </Link>
                {item.active === 1 && <MenuItem button onClick={() => this.endSurvey(item.id)}><CheckedCircle color={this.props.theme.blueText} />{tra('Anketi_Bitir')}</MenuItem>}
                {/* <MenuItem><SendNew color={this.props.theme.blueText} /> Hızlı Paylaş</MenuItem> */}
              </CustomMenu>
            </div>
          </div>
          <div className="cardBar">
            {item.Applications.length > 1 ? (
              <Input
                type="select"
                data={item.Applications}
                value={selectedAppsForSurvey.length > 0 ? selectedAppsForSurvey.find((app) => app.surveyId === item.id).appId : ''}
                onChange={(e) => this.onChangeApp(e, item.id)}
              />
            ) : item.Applications.length > 0 && (
              <p className="singleApp">{item.Applications[0]?.name}</p>
            )}
            <div className="barInfo">
              <span>{tra('Tamamlandi')}</span>
              <span>{item.total_complete_survey_count}/{item.total_survey_count}</span>
            </div>
            <div className="progressBar" />
          </div>
          <div className="footer">
            <Tooltip title={`${tra('Yaratilma_Tarihi')}: ${tra(date.day)} ${tra(date.month)} ${date.year}`}>
              <div className="date">
                <p className="date">{tra('Yaratilma_Tarihi')}: {tra(date.day)} {tra(date.month)} {date.year}</p>
              </div>
            </Tooltip>
            <div className="iconButtons">
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      localStorage.setItem('manageSurvey', '1');
                      this.props.getSurveyDetailApps(item.id);
                    }}
                    to={`/Admin/SurveyDetailApps/${item.id}`}
                  >
                    <PieIcon width={20} color={hexToRgbA(theme.text3, 0.8)} />
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      localStorage.setItem('manageSurvey', '1');
                      this.props.getSurveyDetail(item.id, item.Applications[0]?.id);
                    }}
                    to={`/Admin/SurveyDetail/${item.id}/${item.Applications.length === 1 ? item.Applications[0]?.id : (selectedAppForDetail !== '' ? selectedAppForDetail : item.Applications[0]?.id)}`}
                  >
                    <PersonFilled width={20} color={hexToRgbA(theme.text3, 0.8)} />
                  </Link>
                </li>
                {item.isDraft === 1 && <li onClick={() => this.handleUpdateSurvey(item.id)}><Pen width={15} color={hexToRgbA(theme.text3, 0.8)} /></li>}
              </ul>
            </div>
          </div>
        </div>
      </SurveyCard>
    );
  }

  addSurveyCard = () => {
    const { history } = this.props;
    return (
      <SurveyCard
        style={{ height: (!isWidthDown('sm', this.props.width) ? 180 : 'fit-content') }}
        add
        onClick={() => {
          localStorage.setItem('manageSurvey', '1');
          history.push('Admin/Survey');
        }}
      >
        <div className="create">
          <div className="content">
            <div className="icon"><Plus width={30} color={Colors.lightBlue100} /></div>
            <div className="info">
              <h4>{tra('Anket_Olustur')}</h4>
              <p>{tra('Yeni_Anket_Olusturmak_Icin_Tiklayin')}</p>
            </div>
          </div>
        </div>
      </SurveyCard>
    );
  }

  surveyTabsPage = () => {
    const {
      surveyCardFilter, seeAll, scroll,
    } = this.state;
    const { surveyList } = this.props;
    return (
      <AdminSurveyTabs>
        {isWidthDown('sm', this.props.width)
          ? (
            <>
              <Slide
                appear={false}
                direction="down"
                in={scroll.status}
              >
                <div className="search">
                  <Input onChange={(e) => this.setState({ surveySearch: e.target.value })} type="text" placeholder={tra('Anket_Ara')} />
                  <Search />
                </div>
              </Slide>
              <Placeholder height={40} />
            </>
          )
          : (
            <div className="header">
              <div className="topHeader">
                <h4>{tra('Sorumlu_Envanter')}</h4>
                <div className="buttonGroup">
                  <CustomButton onClick={() => this.setState({ surveyCardFilter: 0 })} outlined={surveyCardFilter !== 0}>{tra('Tumu')}</CustomButton>
                  <CustomButton onClick={() => this.setState({ surveyCardFilter: 1 })} outlined={surveyCardFilter !== 1}>{tra('Devam_Edenler')}</CustomButton>
                  <CustomButton onClick={() => this.setState({ surveyCardFilter: 2 })} outlined={surveyCardFilter !== 2}>{tra('Bitenler')}</CustomButton>
                </div>
              </div>
              <div>
                <div className="search">
                  <Input onChange={(e) => this.setState({ surveySearch: e.target.value })} type="text" placeholder={tra('Anket_Ara')} />
                  <Search />
                </div>
                {
                  surveyList.length > 11 && (
                    !seeAll
                      ? (
                        <CustomButton onClick={() => this.setState({ seeAll: true })} transparent>{tra('Tum_Anketleri_Gor')}<RightArrow width={14} /></CustomButton>
                      ) : (
                        <CustomButton onClick={() => this.setState({ seeAll: false })} transparent>{tra('Daralt')}<RightArrow width={14} /></CustomButton>
                      )
                  )
                }
              </div>
            </div>
          )}
        <div className="cardList">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              {this.addSurveyCard()}
            </Grid>
            {
              !isWidthDown('sm', this.props.width)
                ? surveyList.length > 0 && (
                  this.handleSearchCards(this.handleCardList()).map((item) => (
                    <Grid item xs={12} md={6} lg={3}>
                      {/* {item.Applications.length> 0 && )}  bu koşul eklenebilir app silinince patlamasın diye */}
                      {this.surveyCard(item)}
                    </Grid>
                  )))
                : surveyList.length > 0 && (
                  [...Array(3).keys()].map((item) => this.surveyListSwitchMobile(item))
                )
            }
          </Grid>
        </div>
      </AdminSurveyTabs>
    );
  }

  tabPanelPageSwitch = (id) => {
    switch (id) {
      case 0:
        return <Configurations width={this.props.width} />;
      case 1:
        return (
          <ModuleSwitch software>
            {this.surveyTabsPage()}
          </ModuleSwitch>
        );
      case 2:
        return <ManageUsers />;
      case 3:
        return <ManageCompany />;
      case 4:
        return <ManageVendor />;
      case 5:
        return (
          <ModuleSwitch integration>
            <ManageIntegration />
          </ModuleSwitch>
        );
      default:
        return '404 Not Found';
    }
  }

  onChangeTab = (e, newValue) => {
    Validator.hideMessages();
    this.setState({ tabValue: newValue });
  }

  displayDesktop = (storyAdminTabs) => {
    const { tabValue } = this.state;
    return (
      <TabWrapper>
        <CustomTabs value={tabValue} onChange={(event, newValue) => this.onChangeTab(event, newValue)}>
          {storyAdminTabs.map((tab) => (
            <CustomTabHead style={tab.disabled ? { display: 'none' } : {}} icon={tab.icon} tabValue={tabValue} active={tabValue === tab.tabValue} label={tab.label} id={tab.id} />
          ))}
        </CustomTabs>
        {storyAdminTabs.map((tab) => (
          <div className="tabPanel" hidden={tabValue !== tab.tabValue} id={tab.tabPanel}>
            {this.tabPanelPageSwitch(tab.tabValue)}
          </div>
        ))}
      </TabWrapper>
    );
  }

  displayMobile = (storyAdminTabs) => {
    const { tabValue } = this.state;
    return (
      <>
        {storyAdminTabs.map((tab) => (
          <div className="tabPanel" hidden={tabValue !== tab.tabValue} id={tab.tabPanel}>
            {this.tabPanelPageSwitch(tab.tabValue)}
          </div>
        ))}
        <BottomNavigationMobile
          value={tabValue}
          onChange={(event, newValue) => this.onChangeTab(event, newValue)}
          showLabels
          tabIndex={tabValue}
        >
          {
            storyAdminTabs.map((item) => <CustomBottomNavigationAction active={tabValue === item.tabValue} label={item.label} icon={item.icon} />)
          }
        </BottomNavigationMobile>
        <Placeholder height={100} />
      </>
    );
  }

  surveyListSwitchMobile = (filter) => {
    const { surveyList } = this.props;
    // const surveyList = deneme;
    switch (filter) {
      case 0: {
        const list = this.handleSearchCards(surveyList);
        return list.length > 0 ? this.surveyListMobile(this.handleSearchCards(surveyList), tra('Tumu'), 0)
          : (
            <NoData style={{ magin: '100px 0' }}>
              <img src={NoDataImg} alt="No Data" />
              <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
            </NoData>
          );
      }
      case 1: {
        const list = this.handleSearchCards(surveyList.filter((item) => item.active === 1 && item));
        return list.length > 0 && this.surveyListMobile(list, tra('Devam_Edenler'), 1);
      }
      case 2: {
        const list = this.handleSearchCards(surveyList.filter((item) => item.active === 0 && item));
        return list.length > 0 && this.surveyListMobile(list, tra('Bitenler'), 2);
      }
      default:
        throw new Error('Undefined surveylist id');
    }
  }

  handleAllPageMobile = (id) => {
    const { surveyAllPageMobile } = this.state;
    surveyAllPageMobile[id] = !surveyAllPageMobile[id];
    this.setState({ surveyAllPageMobile });
  }

  surveyListMobile = (item, title, id) => (
    <SurveyListMobile allPage={Boolean(this.state.surveyAllPageMobile[id])}>
      <div className="header">
        <h4>{title}</h4>
        <CustomButton transparent onClick={() => this.handleAllPageMobile(id)}>{tra('Tumu')} <RightArrow width={14} /></CustomButton>
      </div>
      <DragToScroll>
        <div className="content">
          {
            item && item.map((element) => (
              <Grid item sm={this.state.surveyAllPageMobile[id] ? 10 : 7} xs={this.state.surveyAllPageMobile[id] ? 11 : 10} md={3}>
                {this.surveyCard(element)}
              </Grid>
            ))
          }
        </div>
      </DragToScroll>
    </SurveyListMobile>
  )

  render() {
    const storyAdminTabs = [
      {
        tabValue: 0,
        label: tra('Konfigurasyonlar'),
        id: 'simple-tab-0',
        tabPanel: 'simple-tabpanel-0',
        icon: <Configuration width={18} />,
      },
      {
        tabValue: 1,
        label: tra('Anket_Islemleri'),
        id: 'simple-tab-1',
        tabPanel: 'simple-tabpanel-1',
        icon: <File width={18} />,
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        tabValue: 2,
        label: tra('Kisi_Yonetimi'),
        id: 'simple-tab-2',
        tabPanel: 'simple-tabpanel-2',
        icon: <Person width={18} />,
      },
      {
        tabValue: 3,
        label: tra('Sirket_Yonetimi'),
        id: 'simple-tab-3',
        tabPanel: 'simple-tabpanel-3',
        icon: <Business width={18} />,
      },
      {
        tabValue: 4,
        label: tra('Manage_Vendors'),
        id: 'simple-tab-4',
        tabPanel: 'simple-tabpanel-4',
        icon: <Person width={18} />,
      },
      {
        tabValue: 5,
        label: tra('Entegrasyon'),
        id: 'simple-tab-2',
        tabPanel: 'simple-tabpanel-2',
        icon: <IntegrationIcon width={18} />,
        disabled: !this.isUserModuleFunc(moduleType.INTEGRATION),
      },
    ];
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            {isWidthDown('sm', this.props.width) ? this.displayMobile(storyAdminTabs) : this.displayDesktop(storyAdminTabs)}
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

Admin.propTypes = {
  getUsers: PropTypes.func.isRequired,
  history: PropTypes.string.isRequired,
  getSurveyList: PropTypes.func.isRequired,
  getSurveyDetail: PropTypes.func.isRequired,
  surveyList: PropTypes.array.isRequired,
  getSingleSurvey: PropTypes.func.isRequired,
  endSurvey: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  getAdminCompanies: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  getSurveyDetailApps: PropTypes.func.isRequired,
  getVendorUsers: PropTypes.func.isRequired,
  initModule: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  users: selectUsers(),
  surveyList: selectSurveyList(),
  resetState: selectResetState(),
  initModule: selectUserModule(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getUsers,
      getSurveyList,
      getSurveyDetail,
      getSingleSurvey,
      endSurvey,
      setResetState,
      getAdminCompanies,
      getAdminCompanyUsers,
      getCompanyDepartments,
      getSurveyDetailApps,
      getVendorUsers,
    }, dispatch)
  )
);

const AdminWithTheme = withTheme(Admin);
const AdminWithWidth = withWidth()(AdminWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AdminWithWidth);

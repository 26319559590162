import { CircularProgress, Grid, MenuItem } from '@material-ui/core';
import { PostAddOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { CloudExport, CloudImport } from '../../../assets/icons';
import { Colors } from '../../../assets/statics';
import { CustomButton, CustomMenu } from '../../../assets/styledNew';
import { tra } from '../../../commons/utils';
import * as actions from '../../../store/actions';
import IntegrationGraphicFormModal from '../../../components/modals/IntegrationGraphicFormModal';
import NewIntegrarationModal from '../../../components/modals/NewIntegrationModal';
import Table from '../../../components/Table';
import TableFilterMenu from './TableFilterMenu';
import useResponseStatu from '../../../commons/hook/useResponseStatu';

export default function ListTab() {
  const dispatch = useDispatch();

  // STORE
  const integration = useSelector((s) => s?.integration);
  const integrationComponentsDetailUser = useSelector((s) => s?.integrationComponentsDetailUser);
  const appConfig = useSelector((s) => s?.appConfig);
  const [excelMenu, setExcelMenu] = useState(false);
  const [selectTableItemID, setSelectTableItemID] = useState(false);

  // STATE
  const initState = {
    integrationModal: false,
  };
  const [listState, setListState] = useState(initState);

  const { loading: integraionLoading } = useResponseStatu(actions.getIntegration().type);
  const { loading: updatePiDataLoading } = useResponseStatu([actions.updateIntegrationPiData().type, actions.getIntegrationPiUrl().type]);

  useEffect(() => {
    if (!integration?.length) {
      dispatch(actions.getIntegration());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HANDLE ACTION
  const handleGraphicTableModalShow = (id) => {
    dispatch(actions.getIntegrationComponentsDetailUser(id));
    setSelectTableItemID(id);
    // this.setState(((state) => ({ selectedRowId: state.selectedRowId !== id ? id : '' })));
  };
  const handleWithStateProps = (data, callback) => {
    const [key, value] = [Object.keys(data).toString(), ...Object.values(data)];
    dispatch(actions.setAppConfig(key, value));
    setListState((s) => ({ ...s, [key]: value }));
    if (callback) callback();
  };

  const excelUpload = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      dispatch(actions.uploadIntegrationExcel(e.target.files));
    }
  };

  const columnData = [
    {
      name: 'sender_component',
      text: tra('Gonderen_Bileseni'),
      filterable: true,
      type: 'text',
      align: 'left',
      sortable: true,
      searchable: true,
    },
    {
      name: 'sender_interface',
      text: tra('Gonderen_Arayuzu'),
      filterable: true,
      type: 'text',
      align: 'left',
      sortable: true,
      searchable: true,
    },
    {
      name: 'sender_namespace',
      text: tra('Gonderen_Adi'),
      filterable: true,
      type: 'text',
      align: 'left',
      sortable: true,
      searchable: true,
    },
    {
      name: 'receiver_component',
      text: tra('Alici_Bileseni'),
      filterable: true,
      type: 'text',
      align: 'left',
      sortable: true,
      searchable: true,
    },
    {
      name: 'receiver_interface',
      text: tra('Alici_Arayuzu'),
      filterable: true,
      type: 'text',
      align: 'left',
      sortable: true,
      searchable: true,
    },
    {
      name: 'receiver_namespace',
      text: tra('Alici_Adi'),
      filterable: true,
      type: 'text',
      align: 'left',
      sortable: true,
      searchable: true,
    },
    {
      name: 'isRemoved',
      text: tra('Aktif_Pasif'),
      filterable: true,
      type: 'text',
      align: 'center',
      sortable: true,
      searchable: true,
      customBody: (value) => (
        <div style={{
          width: 10,
          height: 10,
          borderRadius: '100%',
          background: !value ? Colors.green : Colors.red,
          margin: '0 auto',
        }}
        />
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid container item xs={12} justify="flex-end">
          <CustomButton
            style={{ marginRight: 20 }}
            onClick={updatePiDataLoading ? () => { } : () => dispatch(actions.updateIntegrationPiData())}
            startIcon={updatePiDataLoading && <CircularProgress style={{ color: 'white' }} size={20} />}
          >
            {tra('Sap_Guncelle')}
          </CustomButton>
          <div style={{ marginRight: 20 }}>
            <CustomButton
              onClick={(e) => setExcelMenu(e.currentTarget)}
              startIcon={<CloudExport width={14} />}
            >
              Excel
            </CustomButton>
            <CustomMenu
              anchorEl={excelMenu}
              open={Boolean(excelMenu)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              onClose={() => setExcelMenu(null)}
            >
              <MenuItem component="label">
                <CloudImport />{tra('Excel_İçe_Aktar')}
                <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" name="file" hidden onChange={excelUpload} />
              </MenuItem>
              <MenuItem onClick={() => { dispatch(actions.getIntegrationExcel()); setExcelMenu(null); }}><CloudExport width={14} />{tra('Excel_Dışa_Aktar')}</MenuItem>
              <MenuItem onClick={() => { dispatch(actions.getIntegrationExcelSample()); setExcelMenu(null); }}><CloudExport width={14} />{tra('Ornek_Indir')}</MenuItem>
            </CustomMenu>
          </div>
          <CustomButton onClick={() => setListState((s) => ({ ...s, integrationModal: true }))} startIcon={<PostAddOutlined width={12} />}>{tra('Yeni_Entegrasyon')}</CustomButton>
        </Grid>
        <Grid item xs={12}>
          <TableFilterMenu
            tableData={integration}
            tableColumn={columnData}
          >
            {(filterProps) => (
              <Table
                lazyLoading={integraionLoading}
                id="integrationListTable"
                data={integration}
                columns={columnData}
                rowClick={(row) => handleGraphicTableModalShow(row.id)}
                options={{
                  header: {
                    visible: true,
                    search: false,
                    columnSetting: true,
                  },
                  filter: {
                    show: true,
                    ...filterProps,
                    // filters: this.state.filter,
                    // onChange: this.changeFilter,
                    // drawerChange: () => this.props.setFilterDrawer(!this.props.filterDrawer),
                  },
                  pagination: {
                    active: true,
                    perPage: 13,
                  },
                }}
                tableResetFilter={listState.tableResetFilter || false}
                onTableResetFilter={() => setListState((s) => ({ ...s, tableResetFilter: false }))}
              />
            )}
          </TableFilterMenu>
        </Grid>
      </Grid>
      <IntegrationGraphicFormModal
        open={!!selectTableItemID}
        selectedRowId={selectTableItemID}
        close={() => setSelectTableItemID(false)}
        itegrationComponentsDetailUser={integrationComponentsDetailUser}
      />
      <NewIntegrarationModal
        open={listState?.integrationModal || appConfig.integrationModal}
        close={() => handleWithStateProps({ integrationModal: false })}
      />
    </>
  );
}

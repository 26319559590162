import styled, { css } from 'styled-components';
// import MUIDataTable from 'mui-datatables';
import { Menu, MenuItem } from '@material-ui/core';
import { Colors } from './statics';
import { hexToRgbA, scrSize } from '../commons/utils';
import { CustomButton } from './styledNew';

const CustomTable = styled.table`
  width: 100%;
  border-collapse:separate; 
  border-spacing: 0 10px; 
  white-space: nowrap;
  tr:nth-child(odd), tr:not(.pagination)  {
    :hover {
      background: ${(props) => props.theme.tableHoverTr};
      tr, td, th {
        border-bottom: 1px solid ${Colors.grey};
        border-top: 1px solid ${Colors.grey};
        :first-child {
          border-color: ${Colors.grey};
        }
        :last-child {
          border-color: ${Colors.grey};
        }
      }
    }
  }
  tr:nth-child(odd) {
    background: ${(props) => props.theme.tableRowOdd};
  }
  tr, td, th {
    border-spacing: 0px;
    color: ${(props) => props.theme.text1};
    font-size: 12px;
    transition: all 0.5s ease;
    border-bottom: 1px solid ${hexToRgbA(Colors.lightGrey400, 0.5)};
    border-top: 1px solid ${hexToRgbA(Colors.lightGrey400, 0.5)};
    :first-child {
      border-width: 1px 0px 1px 1px;
      border-style: solid;
      border-color: ${hexToRgbA(Colors.lightGrey400, 0.5)};
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    :last-child {
      border-width: 1px 1px 1px 0px;
      border-style: solid;
      border-color: ${hexToRgbA(Colors.lightGrey400, 0.5)};
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    a {
      color: ${(props) => props.theme.text3};
    }
  }
  th {
    user-select: none;
    background-color: ${hexToRgbA(Colors.blue, 0.1)};
    text-align: center;
    color: ${(props) => props.theme.text1};
    font-weight: 600;
    position: relative;
    border: none;
    :first-child, :last-child {
      border: none;
    }
    .sortable {
      position: absolute;
      right: 0;
      top: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(0, -50%);
      overflow: hidden;
      svg {
        color: ${Colors.lightGrey200};
      }
      svg:first-of-type {
        /* top: -7px; */
      }
      svg:last-of-type {
        margin-top: -15px;
      }
    }
  }
  input {
    font-size: 12px;
  }
  .MuiInput-underline:after, .MuiInput-underline:before {
    display: flex!important;
  }
  /* tbody tr:nth-child(odd) {
    background-color: #FFFFFF;
  }
  tbody tr:nth-child(even) {
    background-color: #F7FAFC;
  }
  tbody td:not(:first-child) * {
    font-size: 13px;
  } */
  .MuiCheckbox-root {
    color: ${Colors.green}!important;
  }
  h6 {
    display: none!important;
  }
  .pagination {
    position: absolute;
    border: none !important;
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.pagipos === 'left' ? 'flex-start' : props.pagipos === 'right' ? 'flex-end' : 'center')};
    /* padding: 0 !important; */
    bottom: 0;
    td {
      border: none;
      /* padding: 15px 0px!important; */
    }
  }
`;

export const CustomTableTh = styled.th`
  text-align: ${(props) => props.textAlign}!important;
  display: ${(props) => props.visible && 'none'};
  color: ${Colors.lightGrey200};
  font-weight: 400;
  padding: 15px;
`;
export const CustomTableTr = styled.tr`
  ${(props) => props.rowColor && css`
    background: ${props.rowColorBg} !important
  `};
  background: ${(props) => props.editing && props.theme.tableHeader};
`;

export const CustomTableTd = styled.td`
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.cellWidth}px;
  text-align: ${(props) => props.textAlign};
  display: ${(props) => props.visible && 'none'};
  width: ${(props) => props.cellWidth}px;
  ${(props) => props.activeFocus && `
    background: ${props.theme.tableHoverTr};
    tr, td, th {
      border-bottom: 1px solid ${Colors.grey};
      border-top: 1px solid ${Colors.grey};
      :first-child {
        border-color: ${Colors.grey};
      }
      :last-child {
        border-color: ${Colors.grey};
      }
    }
  `}
`;

export const SettingMenu = styled(Menu)`

`;

export const SettingMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  font-size: 13;
  background: ${Colors.lightGrey200};
  color: ${Colors.white};
  opacity: ${(props) => (props.active ? 1 : 0.8)};
  :focus, :active {
    background: ${Colors.lightGrey200};
  }
`;

export const TablePagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TablePagiPerPage = styled.div`
  display: flex;
  align-items: center;
  > p {
    font-size: 12px;
    margin-right: 10px;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 5px 30px 5px 20px;
    font-size: 12px;
  }
`;

export const PagiItem = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid ${Colors.darkBlue}; */
  color: ${(props) => props.theme.text2};
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  font-weight: 400;
  > svg {
    path:nth-child(2) {
      fill: ${(props) => props.theme.textBlue};
    }
  }
  ${(props) => props.active && `
    color: ${props.theme.blueText};
    background-color: ${props.theme.blueBackg};
  `}
  ${(props) => props.disabled && `
    cursor: not-allowed;
    > svg {
      path:nth-child(2) {
        opacity: 0.5;
      }
    }
  `}
`;

export const CustomTableHeader = styled.div`
  /* background-color: ${(props) => (props.search ? props.theme.tableHeader : props.theme.inputBackg)}; */
  background: ${(props) => props.theme.inputBackg};
  border: 1px solid ${(props) => props.theme.inputBorder};
  border-radius: 12px;
  position: relative;
  padding: 5px 10px;
  /* margin-bottom: 5px; */
  /* overflow: hidden; */
  .headerIcons {
    > .MuiIconButton-root {
      padding: 5px!important;
    }
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -5px;
    padding-right: 10px;
    top: 0;
    border-radius: 12px;
    height: 100%;
    /* background-color: ${Colors.blue}; */
    svg {
      /* color: ${Colors.white}; */
    }
  }
  .MuiInputBase-root {
    color: ${(props) => props.theme.text1};
  }
  @media ${scrSize('xs', 'sm')} {
    .headerIcons {
      right: 0px;
      top: 0px;
      bottom: 0px;
      padding: 0;
      >div:first-child {
        height: 100%;
      }
      .menu {
        width: fit-content;
        left: auto;
        top: auto;
      }
      .MuiButtonBase-root {
      padding: 0 15px;
      height: 100%;
      border-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      svg {
        padding: 0;
      }
      }
    }
  }
`;

export const CustomMobileTableHeader = styled.div`
  @media ${scrSize('sm', 'xs')}{
    display: flex;
    /* padding-top: 15px; */
    align-items: center;
    > ${CustomButton} {
      border-radius: 50%;
      box-sizing: border-box;
      padding: 0;
      min-width: 40px;
      min-height: 40px;
      margin-left: 20px;
    }
    ${CustomTableHeader}{
      width: -webkit-fill-available;
    }
    }
`;

export const CustomTableFilter = styled.div`
  margin-bottom: 20px;
  .boxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    padding: 10px 0px;
    .resetAll {
      transition: all 0.5s ease;
      font-weight: 600;
      color: ${(props) => props.theme.text2};
      margin-left: 15px;
    } 
    .filterTag {
      transition: all 0.5s ease;
      display: flex;
      flex-direction: row;
      justify-content: space-betwen;
      padding: 10px;
      align-items: center;
      background-color: ${(props) => props.theme.purpleBackg};
      border-radius: 5px;
      :not(:first-child) {
        margin-left: 15px;
      }
      .icon {
        box-shadow: 0px 3px 5px rgba(53, 63, 102, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: ${Colors.blue};
        svg {
          fill: ${(props) => props.theme.lightPurple300};
        }
      }
      .info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        p {
          font-size: 12px;
          font-weight: 600;
          :first-child {
            color: ${(props) => props.theme.blueText};
          }
          :last-child {
            margin-top: 5px;
            color: ${(props) => props.theme.purpleText};
          }
        }
      }
    }
  }
  .descWrapper {
    margin-top: 10px;
    p {
      font-size: 12px;
      font-weight: 600;
      color: ${(props) => props.theme.text2};
      span {
        color: ${(props) => props.theme.blueText};
      }
    }
  }
`;

export const CustomizeColumns = styled.div`
  position: relative;
  .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    background-color: ${(props) => props.theme.boxBackg};
    z-index: 999;
    padding: 10px;
    .wrapper {
      max-height: 400px;
      overflow: auto;
      padding-right: 10px;
    }
    .item {
      padding-left: 10px;
      border: 1px solid ${hexToRgbA(Colors.lightGrey400, 0.5)};
      margin-top: 7px;
      box-sizing: border-box;
      border-radius: 4px;
      span {
        color: ${((props) => props.theme.text1)};
        font-size: 11px;
        font-weight: 600;
      }
      .MuiSvgIcon-root {
        color: ${(props) => props.theme.blueText}!important;
      }
    }
  }
`;

export default CustomTable;

import React, { useState } from 'react';
import { Edit } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Remove } from '../../assets/icons';
import {
  CustomButton, PeopleManagementTabPage, SwitchWrapper, TableWrapper,
} from '../../assets/styledNew';
import Table from '../../components/Table';
import AddUpdateVendorUser from '../../components/modals/AddUpdateVendorUser';
import { tra } from '../../commons/utils';
import Input from '../../components/Input';
import { deleteVendorUser, vendorUserStatusChange } from '../../store/actions';
import DeleteModal from '../../components/modals/DeleteModal';

const ManageVendor = () => {
  const vendors = useSelector((state) => state.vendorUsers);
  const [vendorUserId, setVendorUserId] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editedUserId, setEditedUserId] = useState(false);

  const dispatch = useDispatch();

  const handleEditUserModal = (id) => {
    setUserModal(true);
    setEditedUserId(id);
  };

  const handleDeleteModal = (id) => {
    setVendorUserId(id);
    setDeleteModal(true);
  };

  const vendorColumns = [
    {
      name: 'active',
      text: tra('Durum'),
      align: 'center',
      cellWidth: 100,
      customBody: (value, name, index, row) => (
        <SwitchWrapper>
          <Input
            type="switch"
            value={value}
            onChange={() => {
              dispatch(vendorUserStatusChange(row.id));
            }}
          />
        </SwitchWrapper>
      ),
    },
    {
      name: 'person',
      text: tra('Adi'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'title',
      text: tra('Görevi'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'mail',
      text: tra('Email'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'phone_code',
      text: tra('Alan_Kodu'),
      editable: false,
      align: 'center',
      searchable: true,
    },
    {
      name: 'phone',
      text: tra('Telefon'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'vendor_name',
      text: tra('Vendor'),
      editable: false,
      type: 'text',
      align: 'center',
      searchable: true,
    },
    {
      name: 'id',
      text: tra('Duzenle'),
      editable: false,
      align: 'center',
      customBody: (value) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleEditUserModal(value)} aria-hidden>
          <Edit width={13} />
        </div>
      ),
    },
    {
      name: 'id',
      text: tra('Sil'),
      editable: false,
      align: 'center',
      customBody: (value) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleDeleteModal(value)} aria-hidden>
          <Remove width={13} />
        </div>
      ),
    },
  ];
  return (
    <PeopleManagementTabPage>
      <TableWrapper>
        <div className="header">
          <h4>{tra('Manage_Vendors')}</h4>
        </div>
        <>
          <Table
            data={vendors}
            columns={vendorColumns}
            options={{
              header: {
                visible: true,
                search: true,
              },
              pagination: {
                active: true,
              },
              editable: false,
            }}
          />
          <div className="buttonWrapper">
            <CustomButton onClick={() => setUserModal(true)}><Plus width={14} />{tra('Add_Vendor_User')}</CustomButton>
          </div>
        </>
      </TableWrapper>
      <AddUpdateVendorUser
        open={userModal}
        userId={editedUserId}
        close={() => {
          setUserModal(false);
          setEditedUserId('');
        }}
      />
      <DeleteModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        message={tra('Kullanici_Silme_Mesaji')}
        deleteFunc={() => {
          dispatch(deleteVendorUser(vendorUserId));
          setDeleteModal(false);
        }}
      />
    </PeopleManagementTabPage>
  );
};

ManageVendor.propTypes = {
};

export default ManageVendor;

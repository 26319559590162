/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withRouter } from 'react-router-dom';
import {
  Colors,
} from '../../assets/statics';
import Input from '../Input';
import {
  CustomButton, BottomSheetWrapper, InputWrapper, CustomModal, ModalHeader, ModalContent,
} from '../../assets/styledNew';
import BottomSheet from '../BottomSheet';
import { tra } from '../../commons/utils';
import { setToast, updateLicenseFollowKey, updateLicenseFollowUser } from '../../store/actions';
// import Input from '../licenseFollow/LicenesInput';
import Validator from '../../commons/validator';

class NewLicenseAddUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        user_id: '',
        owner: false,
        key: '',
        LicenseKeyHardwares: [],
        contract_start: new Date(),
        contract_end: new Date(),
        description: '',
      },
      registiryNoValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    // OPEN
    if (this.props.open && this.props.open !== prevProps.open) {
      this.resetUserData(this.props.selectUser?.data ?? {});
      this.setInitAggrement();
      Validator.hideMessages();
    }
    // CLOSE
    if (!this.props.open && this.props.open !== prevProps.open) {
      this.resetUserData();
      this.props.selectUser.reset();
      Validator.hideMessages();
    }
  }

  setInitAggrement = () => {
    const { selectUser, agreement: { contract_start, contract_end } } = this.props;
    if ((contract_start && contract_end) && !selectUser?.index >= 0) {
      this.setState((s) => ({ userData: { ...s.userData, contract_start, contract_end } }));
    }
  }

  resetUserData = (resetVal = {}) => this.setState({
    userData: {
      user_id: '',
      owner: false,
      key: '',
      LicenseKeyHardwares: [],
      contract_start: new Date(),
      contract_end: new Date(),
      description: '',
      ...resetVal,
    },
  });

  setUserData = (key, data) => this.setState((s) => ({ userData: { ...s.userData, [key]: data } }));

  getUserData = (key) => this.state.userData?.[key]

  // valid
  validator = (name, validateRules) => {
    // modaldaki valid key silinmediğinden Validator.allValid koşuldan geçmesi yazıldı
    const modalValid = validateRules || (this.props.open ? 'required' : 'closeValid');
    return ({
      error: Validator.message(name, this.getUserData(name), modalValid),
      helperText: Validator.message(name, this.getUserData(name), modalValid),
    });
  }

  validatorCheck = () => {
    const isValid = Validator.allValid();
    if (!isValid) {
      Validator.showMessages();
      return this.forceUpdate();
    }
    return isValid;
  }

  // multipleUser -create page
  handleMultipleUserAdd = () => {
    if (this.validatorCheck()) {
      const { value: userArrVal, onChange } = this.props.multipleUserAdd;
      const { userData } = this.state;

      onChange([...userArrVal, {
        ...userData,
        description: userData.description || null,
      }], () => this.resetUserData());
      this.props.setToast(true, 'Kullanıcı Eklendi', 'success');
      Validator.hideMessages();
      this.forceUpdate();
    }
  }

  // multipleUserUpdate -create page
  handleSelectUserUpdate = () => {
    if (this.validatorCheck()) {
      const {
        multipleUserAdd: { value: userArrVal, onChange },
        selectUser: {
          index,
        },

      } = this.props;
      const { userData } = this.state;
      userArrVal[index] = userData;
      onChange([...userArrVal]);
      this.props.setToast(true, 'Kullanıcı Güncellendi', 'success');
      Validator.hideMessages();
      this.forceUpdate();
    }
  }

  // userUpdate - detail Page
  handleUpdateDetailUser = () => {
    const license_id = this.props.history.location.pathname.split('/').at(-1);
    if (this.validatorCheck() && Number(+license_id)) {
      const { userData } = this.state;
      const {
        selectUser: {
          data: {
            id: key_id,
          },
        },
      } = this.props;

      const data = {
        ...userData,
        description: userData.description || null,
        user_id: userData?.user_id?.id ?? null,
        LicenseKeyHardwares: userData?.LicenseKeyHardwares?.map((hwa) => hwa?.id && ({
          hardware_inventory_id: hwa?.id,
        })),
      };

      delete data.HardwareInventories;
      delete data.id;
      // delete data.isRemoved;
      delete data.registry_no;
      delete data.name;

      this.props.updateLicenseFollowKey({ key_id, data, license_id });
    }
  }

  // userAdd - detail Page
  handleDetailUserAdd = () => {
    const license_id = this.props.history.location.pathname.split('/').at(-1);
    if (this.validatorCheck() && Number(+license_id)) {
      const { userData } = this.state;
      const data = {
        users: [
          {
            ...userData,
            description: userData.description || null,
            user_id: userData?.user_id?.id,
            LicenseKeyHardwares: userData?.LicenseKeyHardwares?.map((hwa) => hwa?.id && ({
              hardware_inventory_id: hwa?.id,
            })),
          },
        ],
      };

      this.props.updateLicenseFollowUser({ id: license_id, data });
    }
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;

    const {
      user: userInputData,
      selectUser,
      createMode,
    } = this.props;

    const {
      registiryNoValue, // sicil no sabit
      userData, // input bilgileri
    } = this.state;

    // select user hwa data
    const setUserSelectData = (val) => userInputData?.find((i) => i?.id === val)?.HardwareInventories?.map((i) => ({ ...i, tag: i.tag || 'empty' })) || [];
    const userDataHWA = setUserSelectData(userData?.user_id?.id);

    return (
      <ContentWrapper className="content">
        <ModalHeader>
          <h1>{selectUser?.index >= 0 ? tra('Kullanici_Düzenleme') : tra('Yeni_Kullanici_Ekle')}</h1>
        </ModalHeader>
        <ModalContent style={{ overflow: 'hidden', padding: '20px 0px' }}>
          <InputWrapper>
            <div>
              <Input
                type="checkbox"
                title={tra('Zimmetli_Kullanici')}
                value={this.getUserData('owner')}
                onChange={(e) => {
                  const val = e.target.checked;
                  this.setUserData('owner', val);
                }}
              />
            </div>
            <Input
              type="autoComplete"
              value={this.getUserData('user_id')}
              onChange={(e, other) => {
                this.setState((s) => ({
                  registiryNoValue: other?.registry_no,
                  userData: {
                    ...s.userData,
                    user_id: other,
                    LicenseKeyHardwares: [],
                  },
                }));
              }}
              labelKey="full_name"
              closeIcon
              data={userInputData}
              title={tra('Kullanici_Adi')}
              emptyItem
              multiple={false}
              indicate={createMode}
              {...(createMode ? this.validator('user_id') : {})}
            />
            <Input
              type="text"
              title={tra('Sicil_No')}
              value={registiryNoValue || ''}
              disabled
            />
            <Input
              type="text"
              title={tra('Lisans_Key')}
              value={this.getUserData('key')}
              onChange={(e) => this.setUserData('key', e.target.value)}
              indicate
              {...this.validator('key')}
            />
            <Input
              type="autoComplete"
              multiple
              title={tra('Donanim_No')}
              value={this.getUserData('LicenseKeyHardwares')}
              onChange={(e, data) => this.setUserData('LicenseKeyHardwares', data)}
              labelKey="tag"
              data={userDataHWA}
            />
            <Input
              type="date"
              title={tra('Baslangic_Tarihi')}
              value={this.getUserData('contract_start')}
              onChange={(e) => this.setUserData('contract_start', e)}
            />
            <Input
              type="date"
              title={tra('Bitis_Tarihi')}
              value={this.getUserData('contract_end')}
              onChange={(e) => this.setUserData('contract_end', e)}

            />
            <Input
              type="text"
              title={tra('Description')}
              value={this.getUserData('description')}
              onChange={(e) => this.setUserData('description', e.target.value)}
            />

            <div className="buttonWrapperRight">
              {
                (!createMode && !Object.values(selectUser?.data ?? {})?.length) && (
                  <Input
                    type="checkbox"
                    value={this.props.alwaysOpenMode?.value}
                    onChange={() => this.props.alwaysOpenMode?.onChange()}
                    title="Form ekranı sürekli açık"
                  />
                )
              }
              {
                // register page
                createMode && (
                  selectUser?.index >= 0
                    ? <CustomButton onClick={() => this.handleSelectUserUpdate()} color={Colors.blue}>{tra('Kaydet')}</CustomButton>
                    : <CustomButton onClick={() => this.handleMultipleUserAdd()} color={Colors.blue}>{tra('Kullanici_Ekle')}</CustomButton>
                )
              }
              {
                // detail page
                !createMode && (
                  selectUser?.index >= 0
                    ? <CustomButton onClick={() => this.handleUpdateDetailUser()} color={Colors.blue}>{tra('Kaydet')}</CustomButton>
                    : <CustomButton onClick={() => this.handleDetailUserAdd()} color={Colors.blue}>{tra('Kullanici_Ekle')}</CustomButton>
                )
              }
            </div>
          </InputWrapper>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

NewLicenseAddUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  createMode: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  user: PropTypes.array.isRequired,
  agreement: PropTypes.object.isRequired,
  alwaysOpenMode: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
  }),
  multipleUserAdd: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
  }),
  // selectUser: PropTypes.any,
  setToast: PropTypes.func.isRequired,
  selectUser: PropTypes.shape({
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
  }),
  history: PropTypes.object.isRequired,
  updateLicenseFollowKey: PropTypes.func.isRequired,
  updateLicenseFollowUser: PropTypes.func.isRequired,
};

NewLicenseAddUserModal.defaultProps = {
  // selectUser: false,
  selectUser: false,
  alwaysOpenMode: {},
  multipleUserAdd: {},
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setToast,
      updateLicenseFollowKey,
      updateLicenseFollowUser,
    }, dispatch)
  )
);

export default compose(
  withWidth(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewLicenseAddUserModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';
import { withTheme } from 'styled-components';
import withWidth from '@material-ui/core/withWidth';
import {
  ComponentLoader,
  PageContent, PageWrapper,
} from '../../assets/styledNew';
import Table from '../../components/Table';
import {
  CheckedCircle, CircleMinus, Clock, HalfCircle, Users,
} from '../../assets/icons';
import { getSurveyDetail, getSingleSurveyDetail } from '../../store/actions';
import { selectSurveyDetail } from '../../store/selectors';
import {
  StatusBox, SurveyDetailHeader, SurveyDetailHeaderBox, SurveyHeaderContainer,
} from '../../assets/styledSurveyQuestions';
import Input from '../../components/Input';
import { tra } from '../../commons/utils';
import { DragToScroll } from '../../commons/touch';

const headerUsers = [
  {
    id: 1,
    text: tra('TOPLAM_KATILIMCI'),
    icon: 'users',
    key: 'total_user_count',
    color: '#FFA900',
    backg: 'rgba(255, 169, 0, 0.1)',
  },
  {
    id: 2,
    text: tra('TOPLAM_YANITLAYAN'),
    key: 'total_finish_count',
    icon: 'users',
    color: '#4C6FFF',
    backg: 'rgba(76, 111, 255, 0.1)',
  },
  {
    id: 3,
    text: tra('TOPLAM_YANITLAMAYANLAR'),
    key: 'total_unstart_count',
    icon: 'users',
    color: '#FF5242',
    backg: 'rgba(255, 82, 66, 0.1)',
  },
  {
    id: 4,
    text: tra('YARIM_KALAN_ANKETLER'),
    key: 'total_unfinish_count',
    icon: 'half',
    color: '#6C6C8F',
    backg: 'rgba(108, 108, 143, 0.1)',
  },
];

const searchUserTitles = [
  {
    id: 1,
    name: tra('TOPLAM_KATILIMCI'),
  },
  {
    id: 2,
    name: tra('TOPLAM_YANITLAYAN'),
  },
  {
    id: 3,
    name: tra('TOPLAM_YANITLAMAYANLAR'),
  },
  {
    id: 4,
    name: tra('YARIM_KALAN_ANKETLER'),
  },
];

class SurveyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApp: props.match.params.appId,
      selectedFilter: 1,
    };
    props.getSurveyDetail(props.match.params.id, props.match.params.appId);
  }

  headerIconDecider = (icon, color) => {
    switch (icon) {
      case 'users':
        return <Users width={25} color={color} />;
      case 'half':
        return <HalfCircle width={25} color={color} />;
      case 'time':
        return <Clock width={25} color={color} />;
      default:
        return <Users width={25} color={color} />;
    }
  }

  handlePercantage = (value) => {
    const { surveyDetail } = this.props;
    if (value > 0) {
      return (value / surveyDetail.total_user_count) * 100;
    }
    return 0;
  }

  handleChangeApp = (e) => {
    const { match } = this.props;
    this.props.getSurveyDetail(match.params.id, e.target.value);
    this.setState({ selectedApp: e.target.value });
  }

  changeFilter = (users) => {
    const { selectedFilter } = this.state;
    const temp = [];
    for (let i = 0; i < users.length; ++i) {
      switch (selectedFilter) {
        case 1:
          temp.push(users[i]);
          break;
        case 2:
          if (users[i].situation === 1) {
            temp.push(users[i]);
          }
          break;
        case 3:
          if (users[i].situation === 3) {
            temp.push(users[i]);
          } break;
        case 4:
          if (users[i].situation === 2) {
            temp.push(users[i]);
          }
          break;
        default:
          break;
      }
    }
    return temp;
  }

  render() {
    const {
      surveyDetail, history, match,
    } = this.props;
    const { selectedApp, selectedFilter } = this.state;
    const columns = [
      {
        name: 'complete',
        text: tra('Durum'),
        editable: false,
        align: 'center',
        sortable: true,
        cellWidth: 100,
        customBody: (value) => (
          value ? <CheckedCircle width={15} color="#187D7D" /> : <CircleMinus width={15} color="#2144D4" />
        ),
      },
      {
        name: 'name',
        text: tra('Ad_Soyad'),
        sortable: true,
        filterable: true,
        align: 'start',
        type: 'text',
        searchable: true,
      },
      {
        name: 'permission',
        text: tra('Yetki'),
        type: 'text',
        align: 'start',
        filterable: true,
        cellWidth: 150,
      },
      {
        name: 'department',
        text: tra('Departman'),
        type: 'text',
        align: 'start',
      },
      {
        name: 'situation',
        text: tra('Durum'),
        type: 'text',
        align: 'center',
        cellWidth: 150,
        customBody: (value) => (
          <StatusBox type={value}><p>{value === 1 ? tra('Yanıtlandı') : value === 2 ? tra('Yarım_Kaldı') : tra('Yanıtlamadı')}</p></StatusBox>
        ),
      },
    ];
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container spacing={3}>
              <Grid container item xs={12} spacing={4}>
                <Grid item xs={9}>
                  <SurveyDetailHeader
                    answered={this.handlePercantage(surveyDetail.total_finish_count)}
                    notAnswered={this.handlePercantage(surveyDetail.total_unstart_count)}
                    halfAnswered={this.handlePercantage(surveyDetail.total_unfinish_count)}
                  >
                    <div className="info">
                      <div className="circle" />
                      <div className="title">
                        <h4>{surveyDetail.name}</h4>
                        <p>{surveyDetail.description}</p>
                        {surveyDetail && surveyDetail.Applications && (
                          <Input
                            type="select"
                            data={surveyDetail.Applications.length > 0 ? surveyDetail.Applications : []}
                            value={selectedApp}
                            onChange={(e) => this.handleChangeApp(e)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="progressBar">
                      {surveyDetail.total_finish_count > 0 && <div className="answered" />}
                      {surveyDetail.total_unstart_count > 0 && <div className="notAnswered" />}
                      {surveyDetail.total_unfinish_count > 0 && <div className="halfAnswered" />}
                    </div>
                  </SurveyDetailHeader>
                </Grid>
              </Grid>
              <DragToScroll>
                <SurveyHeaderContainer container item xs={12} spacing={3}>
                  {headerUsers.map((header) => (
                    <Grid item xs>
                      <SurveyDetailHeaderBox active={header.id === selectedFilter} backg={header.backg}>
                        {this.headerIconDecider(header.icon, header.color)}
                        <p
                          style={{
                            color: header.color,
                            fontSize: 20,
                            fontWeight: 600,
                            marginTop: 10,
                          }}
                        >{surveyDetail[header.key]}
                        </p>
                        <p
                          style={{
                            color: header.color,
                            fontSize: 15,
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >{header.text}
                        </p>
                      </SurveyDetailHeaderBox>
                    </Grid>
                  ))}
                </SurveyHeaderContainer>
              </DragToScroll>
            </Grid>
            <Grid container spacing={3}>
              <Grid container justify="flex-end" item xs={12}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p style={{ marginRight: 15, whiteSpace: 'nowrap', color: this.props.theme.text1 }}>{tra('Suna_Gore_Sirala')}</p>
                    <Input
                      type="select"
                      value={selectedFilter}
                      data={searchUserTitles}
                      onChange={(e) => this.setState({ selectedFilter: e.target.value })}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {
                  surveyDetail ? (
                    Object.keys(surveyDetail).length > 0 && surveyDetail.Users.length > 0 && (
                      <Table
                        data={this.changeFilter(surveyDetail.Users)}
                        columns={columns}
                        rowClick={(row) => {
                          history.push(`${match.params.appId}/SingleSurveyDetail/${row.id}`);
                          this.props.getSingleSurveyDetail(row.id, match.params.id, selectedApp);
                        }}
                        options={{
                          header: {
                            visible: true,
                          },
                          pagination: {
                            active: true,
                            perPage: 5,
                            position: 'left',
                          },
                          editable: false,
                        }}
                      />
                    )) : (
                      <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                  )
                }
              </Grid>
            </Grid>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

SurveyDetail.propTypes = {
  getSurveyDetail: PropTypes.func.isRequired,
  surveyDetail: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getSingleSurveyDetail: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  surveyDetail: selectSurveyDetail(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getSurveyDetail,
      getSingleSurveyDetail,
    }, dispatch)
  )
);

const SurveyDetailWithTheme = withTheme(SurveyDetail);
const SurveyDetailWithWidth = withWidth()(SurveyDetailWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailWithWidth);

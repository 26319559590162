/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
import {
  put, takeEvery, takeLatest, takeLeading,
} from 'redux-saga/effects';
import { setLoading, setResponseState } from '../actions';
import responseType from '../../commons/enum/responseType';
import sagaWatcherType from '../../commons/enum/sagaWatcherType';

export function* callEffect({
  action, func, takeType, useIdle = true, globalLoading = false,
} = {}) {
  if (![action, func, takeType].every((i) => i)) {
    throw Error('CallEffect: missing arguments');
  }

  const responseLoading = (sagaFunc) => function* SagaFunc({ type, ...payload }) {
    yield put(setResponseState(type, responseType.LOADING));

    if (globalLoading)
      yield put(setLoading(true));
    const result = yield sagaFunc(payload);
    if (result?.status)
      yield put(setResponseState(type, responseType.SUCCESS));
    else
      yield put(setResponseState(type, responseType.FAILURE));
    if (useIdle)
      yield put(setResponseState(type, responseType.IDLE));

    if (globalLoading)
      yield put(setLoading(false));
  };

  function* watchers() {
    switch (takeType) {
      case sagaWatcherType.TAKE_LEADING:
        yield takeLeading(action, responseLoading(func));
        break;
      case sagaWatcherType.TAKE_LATEST:
        yield takeLatest(action, responseLoading(func));
        break;
      case sagaWatcherType.TAKE_EVERY:
        yield takeEvery(action, responseLoading(func));
        break;
      default:
        throw Error('takeType is required');
    }
  }
  return yield watchers();
}

/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  ClickAwayListener,
  Drawer, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  UnfoldMore, MoreVert, DeleteOutline, Delete, Public, Check, Close,
} from '@material-ui/icons';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Colors, SheetWithModalEnum } from '../../assets/statics';
import ModalComment from '../../components/modals/NewInventoryComment';
import {
  CommentNew, EditNew, SaveNew, Plus, Printer, SurveyIcon, ActiveIcon, InactiveIcon, StoppedIcon, HistoryIcon,
  FilledStar, DocumentPDF, FilledCircle,
} from '../../assets/icons';
import {
  getApplicationLog, getComments, doComment, uploadAppFile, getApplicationDetail, updateApplication, followApplication, setInventoryRegisterFiles,
  setAppConfig, setInventoryRegisterFileUrls, getApplicationContracts, getCostingCategories, getHWCostingCategories, getCostingDetail, setApplicationContracts,
  setApplicationDetail, setApplicationLog, getCostGroup, deleteFile, deleteApplication, getContractYears, setToast,
} from '../../store/actions';
import {
  selectApplications, selectInfos, selectUsers, selectComments, selectApplicationLog, selectApplicationDetail,
  selectAppConfig, selectApplicationContracts, selectInventoryRegisterFiles, selectInventoryRegisterFileUrls,
  selectActiveUser, selectCostGroup, selectApplicationLifecycle, selectContractYears, selectCostingConfig,
} from '../../store/selectors';
import {
  tra, sendByMentions, gartnerModel, FormatCurrency,
} from '../../commons/utils';
import Logs from '../../components/Logs';
import {
  StarRating, InputWrapper, PageContent, CustomButton, SwitchStatus, TabWrapper,
  CustomTabs, CustomTabHead, PageWrapper, GridPageWrapper, ActionFooter, CustomMenu, RatingMenu, LifeCycleWrapper,
} from '../../assets/styledNew';
import InventoryDocumentModal from '../../components/modals/InventoryDocumentModal';
// import Table from '../../components/Table';
import { InventoryContext } from '../../commons/context/InventoryContext';
import InventoryFastInput from '../../components/inventory/InventoryFastInput';
import Validator from '../../commons/validator';
import UpdateWarnModal from '../../components/modals/UpdateWarnModal';
import InventoryStatusModal from '../../components/modals/InventoryStatusModal';
import CustomTable from '../../assets/styledTable';
import LifecycleChart from '../../components/charts/LifecycleChart';
import DeleteModal from '../../components/modals/DeleteModal';

const storyAdminTabs = [
  {
    tabValue: 0,
    label: tra('Application'),
    id: 'simple-tab-0',
    tabPanel: 'simple-tabpanel-0',
  },
  {
    tabValue: 1,
    label: tra('Masraf'),
    id: 'simple-tab-1',
    tabPanel: 'simple-tabpanel-1',
  },
  {
    tabValue: 2,
    label: tra('Application_Lifecycle'),
    id: 'simple-tab-2',
    tabPanel: 'simple-tabpanel-2',
  },
];

const costingTotalTabs = [
  {
    tabValue: 0,
    label: tra('Previous_Year'),
    id: 'simple-tab-1',
    tabPanel: 'simple-tabpanel-1',
  },
  {
    tabValue: 1,
    label: tra('Current_Year'),
    id: 'simple-tab-2',
    tabPanel: 'simple-tabpanel-2',
  },
  {
    tabValue: 2,
    label: tra('Next_Year'),
    id: 'simple-tab-3',
    tabPanel: 'simple-tabpanel-3',
  },
];

class InventoryDetail extends Component {
  static contextType = InventoryContext;

  constructor(props) {
    super(props);
    this.state = {
      appId: '',
      compCurrency: '',
      statusMenu: null,
      otherMenu: null,
      yearMenu: null,
      costTotalTabVal: 1,
      tableYear: new Date().getFullYear(),
      tempAppStatus: 0,
      fileUrlArr: [],
      removeCheckClick: false,
    };
    props.getApplicationDetail(props.match.params.id);
    props.getApplicationLog(props.match.params.id);
    props.getApplicationContracts(props.match.params.id, new Date().getFullYear());
    props.getCostGroup(props.match.params.id);
    props.getContractYears();
  }

  componentDidUpdate(prevProps, prevState) {
    const appId = this.props.match.params.id;
    const {
      application, inventoryRegisterFiles, legalOwners, costGroup, applicationContracts, locations,
    } = this.props;
    const { InventoryState, InventoryPageState } = this.context;
    const costFromInventory = JSON.parse(localStorage.getItem('costfrominventory'));
    if (
      applicationContracts
      && applicationContracts.months
      && Object.keys(applicationContracts.months).length > 0
      && Object.keys(costGroup).length > 0
      && costFromInventory?.tabOpen
      && InventoryPageState.tabValue !== 1
    ) {
      const obj = {
        id: appId,
        tabOpen: false,
      };
      localStorage.setItem('costfrominventory', JSON.stringify(obj));
      this.context.setInventoryPageState((prvState) => ({
        ...prvState,
        tabValue: 1,
      }));
    }
    if (
      application && application.id
      && (application.id.toString() !== appId
        || application.id !== prevState.appId
        || application.AppFiles.length !== inventoryRegisterFiles.length
        || application.statu !== InventoryState.statu
      ) && legalOwners.length > 0 && locations.length > 0) {
      const selectedCountry = locations.find((item) => item.id === application.country_id);
      const selectedLocation = selectedCountry?.Locations.find((item) => item.id === application.location_id);
      const tempAppPlans = [];
      for (let i = 0; i < application.AppPlans.length; ++i) {
        const newObj = {
          data_plan_id: application.AppPlans[i].data_plan_id,
          plan_id: application.AppPlans[i].plan_id,
          year: new Date(application.AppPlans[i].year.toString()),
          desc: application.AppPlans[i].desc,
        };
        tempAppPlans.push(newObj);
      }
      const newData = {
        ...application,
        leg_id: legalOwners.find((item) => item.id === application.leg_id),
        Tags: application.Tags.map((item) => item.name),
        AppUrls: application.AppUrls.map((item) => item.url),
        AppPlans: tempAppPlans,
        location_id: selectedLocation,
        country_id: selectedCountry,
      };
      this.props.setInventoryRegisterFiles(application.AppFiles);
      this.props.setInventoryRegisterFileUrls(application.AppUrls.map((item) => item.url));
      this.context.setInventoryState(newData);
      this.context.setInventoryPageState((prvState) => ({
        ...prvState,
        sendSysOwners: application.SysOwners.map((item) => ({ id: item.id })),
        sendTechnicOwners: application.TechnicOwners.map((item) => ({ id: item.id })),
        sendProcesses: application.Processes.map((item) => ({ id: item.id })),
        sendBusinessOwners: application.BusinessOwners.map((item) => ({ id: item.id })),
        sendPreApps: application.PreApps.map((item) => ({ id: item.id })),
        sendSucApps: application.SucApps.map((item) => ({ id: item.id })),
      }));
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const appId = nextProps.match.params.id;
    const { currencies, user, applications } = nextProps;
    if (nextProps?.application && applications?.length > 0 && applications?.filter((item) => +item?.id === +appId).length === 0) {
      window.location.href = '#/404';
      nextProps.setApplicationDetail(null);
      return null;
    }
    if (appId !== undefined && nextProps?.application && +nextProps?.application?.id !== +appId) {
      nextProps.getApplicationDetail(appId);
      nextProps.getApplicationLog(appId);
      nextProps.getApplicationContracts(appId, new Date().getFullYear());
    }
    if (nextProps.application && nextProps.application.id && (nextProps.application.id !== parseInt(appId, 10) || nextProps.application.id !== state.appId)) {
      return {
        appId: nextProps.application.id,
        fileUrlArr: nextProps.application.AppUrls.map((item) => item.url),
      };
    }
    if (state.compCurrency === '' && currencies.length > 0 && user && Object.keys(user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = user.companies && user.companies.find((item) => item.id.toString() === activeComp);
      const currency = currencies.find((item) => item.id === selectedComp.currency_id)?.name;
      return {
        compCurrency: currency,
      };
    }
    return null;
  }

  componentWillUnmount() {
    const appId = this.props.match.params.id;
    this.props.setApplicationDetail({});
    this.props.setApplicationLog([]);
    this.props.setApplicationContracts([]);
    this.props.setInventoryRegisterFileUrls([]);
    this.props.setInventoryRegisterFiles([]);
    this.props.setAppConfig([{ disabled: true }]);
    const obj = {
      id: appId,
      tabOpen: false,
    };
    localStorage.setItem('costfrominventory', JSON.stringify(obj));
  }

  statusSwitcher = (type, index) => {
    const { InventoryPageState } = this.context;
    const { applicationContracts } = this.props;
    const data = applicationContracts[InventoryPageState.costTabValue];
    if (data[index].fc === 0) {
      switch (type) {
        case 1:
          return 'PY';
        case 2:
          return 'CY';
        case 3:
          return 'NY';
        default:
          return 'PY';
      }
    } else {
      return 'FC';
    }
  }

  isOpenModal = (modalEnum) => {
    const { InventoryPageState } = this.context;
    const { appConfig } = this.props;
    return Boolean((modalEnum === InventoryPageState.openModalInventoryDetail) || (modalEnum === appConfig.openModalInventoryDetail));
  }

  handleWithStateProps = (data, callback) => {
    const [key, value] = [Object.keys(data).toString(), ...Object.values(data)];
    this.props.setAppConfig(key, value);
    this.context.setInventoryPageState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    if (callback) callback();
  }

  openComments = () => {
    const { application } = this.props;
    this.props.getComments(application.id);
    this.context.setInventoryPageState((prevState) => ({
      ...prevState,
      openModalInventoryDetail: SheetWithModalEnum.inventoryDetailModalEnum.COMMENT,
    }));
  }

  doComment = (comment) => {
    if (comment) {
      const allUser = comment.match(/@\[\d+\]/g);
      const mentioned = [];
      if (allUser !== null) {
        for (let i = 0; i < allUser.length; i++) {
          mentioned.push(parseInt(allUser[i].match(/\d+/g), 10));
        }
      }
      const data = {
        comment: sendByMentions(comment),
        mentioned: [...new Set(mentioned)],
      };
      this.props.doComment(this.props.application.id, data);
    }
  }

  handleStarHoverChange = (num, numHover) => {
    if (num === 0 || num === null || num === undefined) {
      return numHover === -1 ? 0 : numHover;
    }
    return num;
  }

  handleSaveApp = () => {
    const appId = this.props.match.params.id;
    const { inventoryRegisterFileUrls } = this.props;
    const { InventoryState, setInventoryPageState, InventoryPageState } = this.context;
    const sendData = { ...InventoryState };
    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      this.props.setToast(true, tra('Lutfen_Zorunlu_Alanlari_Doldurun'), 'warning');
      setInventoryPageState((prevState) => ({
        ...prevState,
        controlModal: false,
      }));
      this.handleWithStateProps({ controlModal: false });
      return false;
    }
    const tempPlans = [];
    for (let i = 0; i < sendData.AppPlans.length; ++i) {
      const newObj = { ...sendData.AppPlans[i] };
      newObj.year = newObj.year.getFullYear();
      tempPlans.push(newObj);
    }
    delete sendData.id;
    delete sendData.user_id;
    delete sendData.createdAt;
    delete sendData.follow;
    delete sendData.isRemoved;
    delete sendData.remove_desc;
    delete sendData.statu;
    delete sendData.updatedAt;
    delete sendData.AppFiles;
    delete sendData.statuStart;
    delete sendData.delete;
    // geçici
    delete sendData.Vendor;
    sendData.AppUrls = inventoryRegisterFileUrls;
    sendData.location_id = InventoryState.location_id ? InventoryState.location_id.id : null;
    sendData.country_id = InventoryState.country_id.id;
    sendData.SysOwners = InventoryPageState.sendSysOwners;
    sendData.TechnicOwners = InventoryPageState.sendTechnicOwners;
    sendData.Processes = InventoryPageState.sendProcesses;
    sendData.BusinessOwners = InventoryPageState.sendBusinessOwners;
    sendData.leg_id = InventoryState.leg_id.id;
    sendData.PreApps = InventoryPageState.sendPreApps;
    sendData.SucApps = InventoryPageState.sendSucApps;
    sendData.AppPlans = tempPlans;
    // input type: select -> autocomplete
    sendData.vendor_id = InventoryPageState.sendvendor_id?.id;
    this.props.updateApplication(sendData, appId);
    this.handleWithStateProps({ controlModal: false });

    this.props.setAppConfig([{ disabled: true }]);
    return true;
  }

  handleFollow = (type) => {
    const appId = this.props.match.params.id;
    this.props.followApplication(appId, type);
  }

  handleBackFirstData = () => {
    const { application } = this.props;
    const tempAppPlans = [];
    const firstDataAppUrls = application.AppUrls.map((item) => item.url);
    for (let i = 0; i < application.AppPlans.length; ++i) {
      const newObj = {
        data_plan_id: application.AppPlans[i].data_plan_id,
        plan_id: application.AppPlans[i].plan_id,
        year: new Date(application.AppPlans[i].year.toString()),
        desc: application.AppPlans[i].desc,
      };
      tempAppPlans.push(newObj);
    }
    const firstData = {
      id: application.id,
      name: application.name,
      desc: application.desc,
      version: application.version,
      platform_id: application.platform_id,
      location_id: application.location_id,
      country_id: application.country_id,
      total_cost: application.total_cost,
      budget_owner: application.budget_owner,
      vendor_id: application.vendor_id,
      SysOwners: application.SysOwners,
      TechnicOwners: application.TechnicOwners,
      BusinessOwners: application.BusinessOwners,
      Tags: application.Tags.map((item) => item.name),
      AppUrls: firstDataAppUrls,
      Processes: application.Processes,
      user_id: application.user_id,
      AppFiles: application.AppFiles,
      business_value: application.business_value,
      it_quality: application.it_quality,
      time_value: application.time_value,
      scope_id: application.scope_id,
      func_id: application.func_id,
      cat_id: application.cat_id,
      leg_id: this.props.legalOwners.find((item) => item.id === application.leg_id),
      managed_by: application.managed_by,
      AppPlans: tempAppPlans,
      PreApps: application.PreApps,
      SucApps: application.SucApps,
      user_base: application.user_base,
      business_critically: application.business_critically,
      cost_fitness: application.cost_fitness,
    };
    this.context.setInventoryState(firstData);
    this.context.setInventoryPageState((prevState) => ({
      ...prevState,
      sendSysOwners: application.SysOwners.map((item) => ({ id: item.id })),
      sendTechnicOwners: application.TechnicOwners.map((item) => ({ id: item.id })),
      sendProcesses: application.Processes.map((item) => ({ id: item.id })),
      sendBusinessOwners: application.BusinessOwners.map((item) => ({ id: item.id })),
      sendPreApps: application.PreApps.map((item) => ({ id: item.id })),
      sendSucApps: application.SucApps.map((item) => ({ id: item.id })),
    }));
    this.props.setAppConfig([{ disabled: true }]);
    this.props.setInventoryRegisterFileUrls(firstDataAppUrls);
    this.setState({ fileUrlArr: firstDataAppUrls });
  }

  modalClose = () => {
    this.context.setInventoryPageState((prevState) => ({
      ...prevState,
      openModalInventoryDetail: false,
    }));
  }

  changeStatus = (val) => {
    this.handleWithStateProps({ statusModal: true });
    this.setState({ tempAppStatus: val, statusMenu: null });
  }

  changeTableYear = (year) => {
    this.props.getApplicationContracts(this.props.match.params.id, year);
    this.setState({ yearMenu: null, tableYear: year });
  }

  render() {
    const {
      appId, costTotalTabVal, compCurrency, tableYear, tempAppStatus,
      fileUrlArr, removeCheckClick,
    } = this.state;
    const {
      InventoryState, handleStarChange, InventoryPageState, setInventoryPageState, handleChange, onChangeAutoComplete,
      onChangeTags, onChangeTab, addAppPlan, deleteAppPlan, handleChangeAppPlanData,
      RatingArr,
    } = this.context;
    const {
      departments, users, comments, logs, application, platforms, tags, processes, vendors, scopes, applications,
      categories, legalOwners, applicationContracts, history, userBases, costGroup, applicationLifecycle, dataPlans,
      plans, contractYears, locations, costingConfig,
    } = this.props;
    const { disabled } = this.props.appConfig;
    const { inventoryDetailModalEnum } = SheetWithModalEnum;
    Validator.purgeFields();
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Drawer
              open={InventoryPageState.logDrawer}
              onClose={() => {
                setInventoryPageState((prevState) => ({
                  ...prevState,
                  logDrawer: false,
                }));
              }}
              anchor="right"
            >
              <Logs data={logs} />
            </Drawer>
            {
              application && (
                <GridPageWrapper>
                  <InputWrapper>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="header" style={isWidthDown('sm', this.props.width) ? { display: 'none' } : { padding: '0px' }}>
                          <div className="buttonWrapperLeft">
                            <h3 className="title">{application.name}</h3>
                            {
                              application.follow
                                ? (
                                  <CustomButton opacity onClick={() => this.handleFollow(1)}>
                                    {tra('Takipten_Cik')}
                                  </CustomButton>
                                )
                                : (
                                  <CustomButton onClick={() => this.handleFollow(0)}>
                                    <Plus width={16} /> {tra('Takip_Et')}
                                  </CustomButton>
                                )
                            }
                          </div>
                          <div className="buttonWrapperRight" style={{ marginTop: 0 }}>
                            {RatingArr.map((rate) => (
                              <StarRating starColor={rate.color}>
                                <p>{rate.title}</p>
                                <p style={{ color: rate.color }} className="ratingNumber">
                                  {this.handleStarHoverChange(InventoryState[rate.valueKey], InventoryPageState[rate.hoverKey])}
                                </p>
                                <ClickAwayListener onClickAway={() => (rate.title === this.state.ratingMenu) && this.setState({ ratingMenu: null })}>
                                  <RatingMenu open={this.state.ratingMenu === rate.title}>
                                    <IconButton disabled={disabled} onClick={() => this.setState({ ratingMenu: rate.title })}>
                                      <FilledStar color={rate.color} />
                                    </IconButton>
                                    <div className="menu">
                                      <Rating
                                        onChange={(event, newValue) => handleStarChange(newValue, rate.valueKey)}
                                        onChangeActive={(event, newHover) => {
                                          setInventoryPageState({
                                            ...InventoryPageState,
                                            [rate.hoverKey]: newHover,
                                          });
                                        }}
                                        value={InventoryState[rate.valueKey]}
                                        size="small"
                                        precision={0.5}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                      />
                                    </div>
                                  </RatingMenu>
                                </ClickAwayListener>
                              </StarRating>
                            ))}
                            {
                              gartnerModel.find((m) => m.value === InventoryState.time_value) && (
                                <>
                                  <SwitchStatus active>
                                    <span>{tra('Sistem_Onerisi')}</span>/
                                    <span>{gartnerModel.find((m) => m.value === InventoryState.time_value).name}</span>
                                  </SwitchStatus>
                                </>
                              )
                            }
                            <CustomButton
                              opacity
                              onClick={(e) => this.setState({ statusMenu: e.currentTarget })}
                              endIcon={<UnfoldMore />}
                            >
                              {tra('Statu')} / {InventoryState.statu === 2 ? tra('Aktif') : (InventoryState.statu === 1 ? tra('Pasif') : tra('Stopped'))}
                            </CustomButton>
                            <CustomMenu
                              anchorEl={this.state.statusMenu}
                              open={Boolean(this.state.statusMenu)}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              keepMounted
                              onClose={() => this.setState({ statusMenu: null })}
                            >
                              <MenuItem onClick={() => this.changeStatus(2)}><ActiveIcon />{tra('Aktif')}</MenuItem>
                              <MenuItem onClick={() => this.changeStatus(1)}><InactiveIcon />{tra('Pasif')}</MenuItem>
                              <MenuItem onClick={() => this.changeStatus(0)}><StoppedIcon />{tra('Stopped')}</MenuItem>
                            </CustomMenu>
                            {disabled && (
                              <CustomButton
                                onClick={() => this.props.setAppConfig('disabled', false)}
                                startIcon={<EditNew color={Colors.blue} width="16" />}
                              >
                                {tra('Duzenle')}
                              </CustomButton>
                            )}
                            <CustomButton
                              opacity
                              onClick={() => {
                                setInventoryPageState((prevState) => ({
                                  ...prevState,
                                  logDrawer: true,
                                }));
                              }}
                              startIcon={<HistoryIcon />}
                            >
                              {tra('Tarihce')}
                            </CustomButton>
                            <CustomButton
                              style={{ padding: 0 }}
                              opacity
                              onClick={(e) => this.setState({ otherMenu: e.currentTarget })}
                            >
                              <MoreVert />
                            </CustomButton>
                            <CustomMenu
                              anchorEl={this.state.otherMenu}
                              open={Boolean(this.state.otherMenu)}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              keepMounted
                              onClose={() => this.setState({ otherMenu: null })}
                            >
                              <MenuItem onClick={() => window.print()}><Printer />{tra('Cikti_Al')}</MenuItem>
                              {application.delete && (
                                <MenuItem
                                  onClick={() => {
                                    this.setState({ otherMenu: false });
                                    this.handleWithStateProps({ deleteModal: true });
                                  }}
                                >
                                  <Delete />{tra('Delete_Application')}
                                </MenuItem>
                              )}
                            </CustomMenu>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TabWrapper>
                          <CustomTabs opacity value={InventoryPageState.tabValue} onChange={(event, newValue) => onChangeTab(event, newValue, 'tabValue')} style={{ borderBottom: '1px solid #C1D1E2' }}>
                            {storyAdminTabs.map((tab) => (
                              <CustomTabHead opacity tabValue={InventoryPageState.tabValue} active={InventoryPageState.tabValue === tab.tabValue} label={tab.label} id={tab.id} />
                            ))}
                          </CustomTabs>
                          {InventoryPageState.tabValue === 0 && (
                            <div className="tabPanel" id="tab-panel-0" style={{ marginTop: 20 }}>
                              <Grid container spacing={3}>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Uygulama_Aciklamasi')}</h4>
                                          <p>{tra('You_Can_Examine_All_The_Info_About_Your_Application_Below')}.</p>
                                        </div>
                                      </div>
                                      <div className="inputList">
                                        <InventoryFastInput
                                          type="text"
                                          title={tra('Uygulama_ID')}
                                          disabled
                                          name="id"
                                        />
                                        <InventoryFastInput
                                          type="text"
                                          title={tra('Uygulama_Adi')}
                                          disabled={disabled}
                                          name="name"
                                          onChange={(e) => handleChange(e, 'name')}
                                          indicate
                                          error={Validator.message('name', InventoryState.name, 'required|min:3')}
                                          helperText={Validator.message('name', InventoryState.name, 'required|min:3')}
                                        />
                                        <InventoryFastInput
                                          type="text"
                                          title={tra('Versiyon')}
                                          disabled={disabled}
                                          name="version"
                                          onChange={(e) => handleChange(e, 'version')}
                                          indicate
                                          error={Validator.message('version', InventoryState.version, 'required')}
                                          helperText={Validator.message('version', InventoryState.version, 'required')}
                                        />
                                        <InventoryFastInput
                                          disabled={disabled}
                                          type="select"
                                          emptyItem
                                          title={tra('User_Base')}
                                          info={tra('What_Is_That_Mean_User_Base')}
                                          name="user_base"
                                          onChange={(e) => handleChange(e, 'user_base')}
                                          indicate
                                          error={Validator.message('user_base', InventoryState.user_base, 'required')}
                                          helperText={Validator.message('user_base', InventoryState.user_base, 'required')}
                                          data={userBases}
                                        />
                                        <InventoryFastInput
                                          disabled={disabled}
                                          type="select"
                                          data={scopes}
                                          title={tra('Kapsam')}
                                          name="scope_id"
                                          onChange={(e) => handleChange(e, 'scope_id')}
                                          indicate
                                          error={Validator.message('scope_id', InventoryState.scope_id, 'required')}
                                          helperText={Validator.message('scope_id', InventoryState.scope_id, 'required')}
                                        />
                                        <InventoryFastInput
                                          disabled={disabled}
                                          type="autoCompleteFree"
                                          multiple
                                          title={tra('Etiket')}
                                          data={tags.map((item) => item.name)}
                                          freeSolo
                                          name="Tags"
                                          onChange={(e, newValue) => onChangeTags(e, newValue, 'Tags')}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid className="rowRight" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid" />
                                      <div className="inputList">
                                        <InventoryFastInput
                                          disabled={disabled}
                                          type="select"
                                          data={departments}
                                          title={tra('Business_Domain')}
                                          name="func_id"
                                          onChange={(e) => handleChange(e, 'func_id')}
                                          indicate
                                          error={Validator.message('func_id', InventoryState.func_id, 'required')}
                                          helperText={Validator.message('func_id', InventoryState.func_id, 'required')}
                                        />
                                        <InventoryFastInput
                                          disabled={disabled}
                                          type="select"
                                          data={categories}
                                          title={tra('Categories')}
                                          name="cat_id"
                                          onChange={(e) => handleChange(e, 'cat_id')}
                                          indicate
                                          error={Validator.message('cat_id', InventoryState.cat_id, 'required')}
                                          helperText={Validator.message('cat_id', InventoryState.cat_id, 'required')}
                                        />
                                        <InventoryFastInput
                                          title={tra('Business_Capability')}
                                          multiple
                                          disabled={disabled}
                                          type="autoComplete"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'Processes')}
                                          data={processes}
                                          name="Processes"
                                          indicate
                                          error={Validator.message('Processes', InventoryState.Processes, 'required')}
                                          helperText={Validator.message('Processes', InventoryState.Processes, 'required')}
                                        />
                                        <InventoryFastInput
                                          type="text"
                                          title={tra('Uygulama_Aciklamasi')}
                                          row="4"
                                          disabled={disabled}
                                          onChange={(e) => handleChange(e, 'desc')}
                                          name="desc"
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Technical_Information')}</h4>
                                          <p>{tra('Info_Regarding_The_Technical_Aspect_Of_Your_Application_Are_Shown_Below')}</p>
                                        </div>
                                      </div>
                                      <div className="inputList">
                                        <div className="multipleInputWrapper">
                                          <InventoryFastInput
                                            type="autoComplete"
                                            disabled={disabled}
                                            multiple={false}
                                            data={vendors}
                                            title={tra('Uretici')}
                                            value={vendors.find((i) => i.id === (InventoryPageState.sendvendor_id?.id ?? InventoryState.vendor_id))}
                                            indicate
                                            onChange={(e, newValue, reason) => onChangeAutoComplete(e, newValue, 'vendor_id', reason)}
                                            error={Validator.message('vendor_id', InventoryState.vendor_id, 'required')}
                                            helperText={Validator.message('vendor_id', InventoryState.vendor_id, 'required')}
                                          />
                                          <InventoryFastInput
                                            type="select"
                                            emptyItem
                                            data={platforms}
                                            title={tra('Saglayici_Platformu')}
                                            disabled={disabled}
                                            onChange={(e) => handleChange(e, 'platform_id')}
                                            name="platform_id"
                                            indicate
                                            error={Validator.message('platform_id', InventoryState.platform_id, 'required')}
                                            helperText={Validator.message('platform_id', InventoryState.platform_id, 'required')}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid className="rowRight" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid" />
                                      <div className="inputList">
                                        <div className="multipleInputWrapper">
                                          <InventoryFastInput
                                            disabled={disabled}
                                            title={tra('Saglayici_Ulkesi')}
                                            name="country_id"
                                            multiple={false}
                                            type="autoComplete"
                                            data={locations}
                                            onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'country_id')}
                                            indicate
                                            error={Validator.message('country_id', InventoryState.country_id, 'required')}
                                            helperText={Validator.message('country_id', InventoryState.country_id, 'required')}
                                          />
                                          <InventoryFastInput
                                            disabled={disabled || InventoryState.country_id === null}
                                            title={tra('Saglayici_Sehri')}
                                            multiple={false}
                                            type="autoComplete"
                                            name="location_id"
                                            data={InventoryState?.country_id?.Locations}
                                            onChange={(e, newValue, reason) => {
                                              onChangeAutoComplete(e, newValue, 'location_id', reason);
                                            }}
                                            closeIcon
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Owner_Details')}</h4>
                                          <p>{tra('You_Can_Find_Info_About_The_People_In_Contact_With_Your_Application_Here')}.</p>
                                        </div>
                                      </div>
                                      <div className="inputList">
                                        <InventoryFastInput
                                          type="autoComplete"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'SysOwners')}
                                          title={tra('Sistem_Sorumlulari')}
                                          disabled={disabled}
                                          multiple
                                          data={users}
                                          name="SysOwners"
                                          indicate
                                          error={Validator.message('SysOwners', InventoryState.SysOwners, 'required')}
                                          helperText={Validator.message('SysOwners', InventoryState.SysOwners, 'required')}
                                        />
                                        <InventoryFastInput
                                          type="autoComplete"
                                          title={tra('Is_Birimi_Sorumlulari')}
                                          multiple
                                          data={users}
                                          disabled={disabled}
                                          name="BusinessOwners"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'BusinessOwners')}
                                          indicate
                                          error={Validator.message('BusinessOwners', InventoryState.BusinessOwners, 'required')}
                                          helperText={Validator.message('BusinessOwners', InventoryState.BusinessOwners, 'required')}
                                        />
                                        <InventoryFastInput
                                          type="autoComplete"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'TechnicOwners')}
                                          title={tra('Teknik_Sorumlular')}
                                          disabled={disabled}
                                          multiple
                                          data={users}
                                          name="TechnicOwners"
                                          indicate
                                          error={Validator.message('TechnicOwners', InventoryState.TechnicOwners, 'required')}
                                          helperText={Validator.message('TechnicOwners', InventoryState.TechnicOwners, 'required')}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid className="rowRight" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid" />
                                      <div className="inputList">
                                        <InventoryFastInput
                                          type="autoComplete"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'leg_id')}
                                          title={tra('Yasal_Sorumlular')}
                                          disabled={disabled}
                                          multiple={false}
                                          data={legalOwners}
                                          name="leg_id"
                                          indicate
                                          error={Validator.message('leg_id', InventoryState.leg_id, 'required')}
                                          helperText={Validator.message('leg_id', InventoryState.leg_id, 'required')}
                                        />
                                        <InventoryFastInput
                                          type="select"
                                          emptyItem
                                          data={departments}
                                          disabled={disabled}
                                          title={tra('Butce_Sorumlulari')}
                                          onChange={(e) => handleChange(e, 'budget_owner')}
                                          name="budget_owner"
                                          indicate
                                          error={Validator.message('budget_owner', InventoryState.budget_owner, 'required')}
                                          helperText={Validator.message('budget_owner', InventoryState.budget_owner, 'required')}
                                        />
                                        <InventoryFastInput
                                          type="select"
                                          emptyItem
                                          data={departments}
                                          disabled={disabled}
                                          title={tra('Yonetim')}
                                          onChange={(e) => handleChange(e, 'managed_by')}
                                          name="managed_by"
                                          indicate
                                          error={Validator.message('managed_by', InventoryState.managed_by, 'required')}
                                          helperText={Validator.message('managed_by', InventoryState.managed_by, 'required')}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={12}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Add_Document_And_Link')}</h4>
                                          <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>
                                        </div>
                                        <div>
                                          <CustomButton
                                            onClick={() => {
                                              this.context.setInventoryPageState((prevState) => ({
                                                ...prevState,
                                                openModalInventoryDetail: inventoryDetailModalEnum.INVENTORY_DOCUMENT_MODAL,
                                              }));
                                            }}
                                            startIcon={<Plus width={12} />}
                                            active
                                          >
                                            {tra('Ekle')}
                                          </CustomButton>
                                        </div>
                                      </div>
                                      <div className="documentList">
                                        {(InventoryState.AppFiles.length > 0 || fileUrlArr.length > 0) && (
                                          <Table>
                                            <TableHead>
                                              <TableCell colSpan={4}>{InventoryState.AppFiles.length} Document / {fileUrlArr.length} Link</TableCell>
                                            </TableHead>
                                            <TableBody>
                                              {InventoryState.AppFiles.map((file, index) => (
                                                <TableRow>
                                                  <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                                                  <TableCell style={{ width: 'auto' }}><a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a></TableCell>
                                                  <TableCell style={{ width: 150, textAlign: 'center' }}>{(parseInt(file.size / 1024, 10))} Kb</TableCell>
                                                  <TableCell style={{ width: 50, textAlign: 'center' }}>
                                                    {removeCheckClick === index
                                                      ? (
                                                        <div style={{ display: 'flex' }}>
                                                          <IconButton
                                                            onClick={() => this.props.deleteFile(file.id, appId)}
                                                            style={{ padding: 5 }}
                                                          >
                                                            <Check style={{ color: Colors.green }} />
                                                          </IconButton>
                                                          <IconButton
                                                            onClick={() => this.setState({ removeCheckClick: false })}
                                                            style={{ padding: 5 }}
                                                          >
                                                            <Close style={{ color: Colors.red }} />
                                                          </IconButton>
                                                        </div>
                                                      )
                                                      : (
                                                        <IconButton
                                                          onClick={() => this.setState({ removeCheckClick: index })}
                                                          style={{ padding: 5 }}
                                                        ><Delete />
                                                        </IconButton>
                                                      )}
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                              {fileUrlArr.map((url, index) => (
                                                <TableRow>
                                                  <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                                                  <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                                                  <TableCell style={{ width: 50, textAlign: 'center' }}>
                                                    <IconButton
                                                      onClick={() => {
                                                        fileUrlArr.splice(index, 1);
                                                        this.props.setInventoryRegisterFileUrls(fileUrlArr);
                                                        this.setState({ fileUrlArr });
                                                      }}
                                                      style={{ padding: 5 }}
                                                      disabled={disabled}
                                                    ><Delete />
                                                    </IconButton>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          )}
                          {InventoryPageState.tabValue === 1 && (
                            <div className="tabPanel" id="tab-panel-1" style={{ marginTop: 20 }}>
                              <Grid container spacing={3}>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={4}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Costing_Summary')}</h4>
                                          <p>{tra('Costing_Summary_Detail')}.</p>
                                        </div>
                                      </div>
                                      {/* <div className="inputList">
                                        <p>Below, changes of cost according to the data you have provided are shown. You can view detailed info of cost data on the.</p>
                                      </div> */}
                                    </div>
                                  </Grid>
                                  <Grid className="rowRight" item xs={8}>
                                    <div className="box">
                                      <div className="headerGrid" />
                                      <div className="costDetailBox">
                                        <TabWrapper>
                                          <CustomTabs opacity value={costTotalTabVal} onChange={(event, newValue) => this.setState({ costTotalTabVal: newValue })} style={{ borderBottom: '2px solid #C1D1E2', marginBottom: 10 }}>
                                            {costingTotalTabs.map((tab) => (
                                              <CustomTabHead opacity tabValue={costTotalTabVal} active={costTotalTabVal === tab.tabValue} label={tab.label} id={tab.id} />
                                            ))}
                                          </CustomTabs>
                                          <div className="costList">
                                            <div className="item"><p>{tra('Licenses')}</p><p>{FormatCurrency(costGroup[costTotalTabVal + 1][1].total_inv || 0)} {compCurrency}</p></div>
                                            <div className="item"><p>{tra('Other_Contracts')}</p><p>{FormatCurrency(costGroup[costTotalTabVal + 1][2].total_inv || 0)} {compCurrency}</p></div>
                                            <div className="item"><p>{tra('Other_Costs')}</p><p>{FormatCurrency(costGroup[costTotalTabVal + 1][3].total_inv || 0)} {compCurrency}</p></div>
                                            <div className="line" />
                                            <div className="item"><p>{tra('Run_Cost_Per_Year')}</p><p>{FormatCurrency((costGroup[costTotalTabVal + 1][1].total_inv || 0) + (costGroup[costTotalTabVal + 1][2].total_inv || 0) + (costGroup[costTotalTabVal + 1][3].total_inv || 0))} {compCurrency}</p></div>
                                          </div>
                                        </TabWrapper>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={12} style={{ paddingRight: 12 }}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Costing_Detail')}</h4>
                                          <p>{tra('Costing_Summary_Detail')}.</p>
                                        </div>
                                      </div>
                                      <div>
                                        <CustomButton
                                          opacity
                                          onClick={(e) => this.setState({ yearMenu: e.currentTarget })}
                                          endIcon={<UnfoldMore />}
                                        >
                                          {tableYear}
                                        </CustomButton>
                                        <CustomMenu
                                          anchorEl={this.state.yearMenu}
                                          open={Boolean(this.state.yearMenu)}
                                          getContentAnchorEl={null}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                          keepMounted
                                          onClose={() => this.setState({ yearMenu: null })}
                                        >
                                          {contractYears.map((year) => (
                                            <MenuItem onClick={() => this.changeTableYear(year)}>{year}</MenuItem>
                                          ))}
                                        </CustomMenu>
                                        <CustomTable className="costTable">
                                          <TableHead>
                                            <TableCell>{tra('Masraf_Kategorisi')}</TableCell>
                                            <TableCell>{tra('Masraf_Tipi')}</TableCell>
                                            <TableCell>{tra('Tanim')}</TableCell>
                                            <TableCell>{tra('Allocation')}</TableCell>
                                            <TableCell>{tra('Ock')}</TableCell>
                                            <TableCell>{tra('Şub')}</TableCell>
                                            <TableCell>{tra('Mrt')}</TableCell>
                                            <TableCell>{tra('Nis')}</TableCell>
                                            <TableCell>{tra('May')}</TableCell>
                                            <TableCell>{tra('Haz')}</TableCell>
                                            <TableCell>{tra('Tem')}</TableCell>
                                            <TableCell>{tra('Agu')}</TableCell>
                                            <TableCell>{tra('Eyl')}</TableCell>
                                            <TableCell>{tra('Eki')}</TableCell>
                                            <TableCell>{tra('Kas')}</TableCell>
                                            <TableCell>{tra('Ara')}</TableCell>
                                            <TableCell>{tra('Toplam')}</TableCell>
                                          </TableHead>
                                          <TableBody>
                                            {Object.keys(applicationContracts).length > 0 && applicationContracts.agreements.map((item) => (
                                              <TableRow
                                                onClick={() => {
                                                  if (costingConfig.costingTypeMenu === 'Software') {
                                                    this.props.getCostingCategories();
                                                  } else {
                                                    this.props.getHWCostingCategories();
                                                  }
                                                  const obj = {
                                                    id: appId,
                                                    tabOpen: false,
                                                  };
                                                  localStorage.setItem('costfrominventory', JSON.stringify(obj));
                                                  // history.push(`/CostingDetail/${costingConfig.costingTypeMenu}/${item.id}`);
                                                  history.push(`/CostingDetail/${costingConfig.costingTypeMenu}/${item.id}`);
                                                  this.props.getCostingDetail(item.id);
                                                }}
                                                style={{ cursor: 'pointer', background: item.fc === 1 && 'rgba(33, 69, 212, 0.5)' }}
                                              >
                                                <TableCell>{item?.costing_type}</TableCell>
                                                <TableCell>{item?.category_name}</TableCell>
                                                <TableCell>{item?.agreement}</TableCell>
                                                <TableCell>{item?.allocation}%</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['01'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['02'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['03'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['04'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['05'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['06'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['07'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['08'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['09'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['10'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['11'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.month['12'] || 0)}</TableCell>
                                                <TableCell>{FormatCurrency(item?.total_inv)}</TableCell>
                                              </TableRow>
                                            ))}
                                            <TableRow className="coloredCells">
                                              <TableCell colSpan={4}>Monthly Cost in {compCurrency}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['01'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['02'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['03'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['04'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['05'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['06'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['07'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['08'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['09'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['10'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['11'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.months['12'] || 0)}</TableCell>
                                              <TableCell>{FormatCurrency(applicationContracts?.mainTotal)}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </CustomTable>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          )}
                          {InventoryPageState.tabValue === 2 && (
                            <div className="tabPanel" id="tab-panel-1" style={{ marginTop: 20 }}>
                              <Grid container spacing={3}>
                                <Grid className="gridRow" container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Uygulama_Aciklamasi')}</h4>
                                          <p>{tra('Application_Details_Detail')}.</p>
                                        </div>
                                      </div>
                                      <div className="inputList">
                                        <InventoryFastInput
                                          type="autoComplete"
                                          multiple
                                          title={tra('Predecessor_Application')}
                                          data={applications}
                                          disabled={disabled}
                                          name="PreApps"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'PreApps')}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid className="rowRight" item xs={6}>
                                    <div className="box">
                                      <div className="headerGrid" />
                                      <div className="inputList">
                                        <InventoryFastInput
                                          type="autoComplete"
                                          multiple
                                          data={applications}
                                          title={tra('Succesor_Application')}
                                          disabled={disabled}
                                          name="SucApps"
                                          onChange={(e, newValue) => onChangeAutoComplete(e, newValue, 'SucApps')}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid className="gridRow" container item xs={12}>
                                  <LifeCycleWrapper>
                                    <LifecycleChart data={applicationLifecycle} />
                                    <div className="statusWrapper">
                                      <div className="statusBox"><FilledCircle color="#187D7D" width={10} /> <p>{tra('Aktif')}</p></div>
                                      <div className="statusBox"><FilledCircle color="#F0C832" width={10} /> <p>{tra('Pasif')}</p></div>
                                      <div className="statusBox"><FilledCircle color="#FF5242" width={10} /> <p>{tra('Stopped')}</p></div>
                                    </div>
                                  </LifeCycleWrapper>
                                </Grid>
                                <Grid className={InventoryState.AppPlans.length > 0 ? 'gridRowWithoutAfter' : 'gridRow'} container item xs={12} spacing={3}>
                                  <Grid className="rowLeft" item xs={12}>
                                    <div className="box">
                                      <div className="headerGrid">
                                        <div className="left">
                                          <h4>{tra('Application_Plan')}</h4>
                                          <p>{tra('Application_Plan_Detail')}.</p>
                                        </div>
                                        <div>
                                          <CustomButton
                                            disabled={disabled}
                                            startIcon={<Plus width={12} />}
                                            onClick={addAppPlan}
                                          >
                                            {tra('Add')}
                                          </CustomButton>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  {InventoryState.AppPlans.map((rowItem, index) => (
                                    <Grid className={index !== InventoryState.AppPlans.length - 1 ? 'gridRowDashed' : 'gridRow'} container item xs={12} spacing={3}>
                                      <Grid container justify="flex-end" item xs={12}>
                                        <CustomButton
                                          style={{ padding: 0, marginRight: 40 }}
                                          opacity
                                          onClick={() => deleteAppPlan(index)}
                                          disabled={disabled}
                                        >
                                          <DeleteOutline />
                                        </CustomButton>
                                      </Grid>
                                      <Grid className="rowLeft" item xs={6}>
                                        <div className="box">
                                          <div className="inputList">
                                            <InventoryFastInput
                                              type="date"
                                              value={rowItem.year}
                                              onlyYear
                                              title={tra('Yil_Secimi')}
                                              onChange={(e) => handleChangeAppPlanData(e, 'year', index)}
                                              disabled={disabled}
                                            />
                                            <div className="multipleInputWrapper">
                                              <InventoryFastInput
                                                type="select"
                                                data={plans}
                                                title={tra('Plan_App')}
                                                value={rowItem.plan_id}
                                                onChange={(e) => handleChangeAppPlanData(e, 'plan_id', index)}
                                                disabled={disabled}
                                              />
                                              <InventoryFastInput
                                                type="select"
                                                data={dataPlans}
                                                title={tra('Plan_Data')}
                                                value={rowItem.data_plan_id}
                                                onChange={(e) => handleChangeAppPlanData(e, 'data_plan_id', index)}
                                                disabled={disabled}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid className="rowRight" item xs={6}>
                                        <div className="box">
                                          <div className="inputList">
                                            <InventoryFastInput
                                              type="text"
                                              row="4"
                                              title={tra('Action_App_Notes')}
                                              value={rowItem.desc}
                                              onChange={(e) => handleChangeAppPlanData(e, 'desc', index)}
                                              disabled={disabled}
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </TabWrapper>
                      </Grid>
                    </Grid>
                  </InputWrapper>
                  <ActionFooter multiple>
                    <div>
                      <CustomButton
                        opacity
                        onClick={() => { window.location.href = `#/Surveys/${appId}`; }}
                        startIcon={<SurveyIcon />}
                      >
                        {tra('Anketler')}
                      </CustomButton>
                      <CustomButton startIcon={<CommentNew width={16} color={Colors.blue} />} style={{ marginTop: isWidthDown('sm', this.props.width) ? 10 : 0 }} onClick={() => this.openComments()}>
                        {tra('Yorumlar')}
                      </CustomButton>
                    </div>
                    {!disabled && (
                      <div>
                        <CustomButton
                          onClick={() => this.handleBackFirstData()}
                          outlined
                        >
                          {tra('Vazgec')}
                        </CustomButton>
                        <CustomButton
                          onClick={() => this.handleWithStateProps({ controlModal: true })}
                        >
                          <SaveNew color={Colors.blue} width="16" />
                          {tra('Kaydet')}
                        </CustomButton>
                      </div>
                    )}
                  </ActionFooter>
                </GridPageWrapper>
              )
            }
          </div>
        </PageContent>
        <ModalComment
          open={this.isOpenModal(inventoryDetailModalEnum.COMMENT)}
          close={this.modalClose}
          data={comments}
          users={users}
          departments={departments}
          doComment={(comment) => this.doComment(comment)}
        />
        <InventoryDocumentModal
          open={this.isOpenModal(inventoryDetailModalEnum.INVENTORY_DOCUMENT_MODAL)}
          close={this.modalClose}
          disabled={disabled}
          id={appId}
          uploadFile={this.props.uploadAppFile}
          setFileUrlArr={(fileUrl) => this.setState({ fileUrlArr: fileUrl })}
        />
        <UpdateWarnModal
          open={InventoryPageState.controlModal}
          close={() => this.handleWithStateProps({ controlModal: false })}
          message={tra('Uygulama_Guncelleme_Sorusu')}
          update={() => this.handleSaveApp()}
        />
        <DeleteModal
          open={InventoryPageState.deleteModal}
          close={() => this.handleWithStateProps({ deleteModal: false })}
          message={tra('Uygulama_Silme_Sorusu')}
          deleteFunc={() => {
            this.props.deleteApplication(appId);
            this.handleWithStateProps({ deleteModal: false });
          }}
        />
        <InventoryStatusModal
          open={InventoryPageState.statusModal}
          close={() => this.handleWithStateProps({ statusModal: false })}
          appId={appId}
          statu={tempAppStatus}
        />
      </PageWrapper>
    );
  }
}

InventoryDetail.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  getApplicationLog: PropTypes.func.isRequired,
  applications: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  processes: PropTypes.array.isRequired,
  getComments: PropTypes.func.isRequired,
  comments: PropTypes.array.isRequired,
  doComment: PropTypes.func.isRequired,
  uploadAppFile: PropTypes.func.isRequired,
  logs: PropTypes.array.isRequired,
  application: PropTypes.object.isRequired,
  getApplicationDetail: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateApplication: PropTypes.func.isRequired,
  followApplication: PropTypes.func.isRequired,
  setInventoryRegisterFileUrls: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  scopes: PropTypes.string.isRequired,
  categories: PropTypes.string.isRequired,
  legalOwners: PropTypes.string.isRequired,
  getApplicationContracts: PropTypes.func.isRequired,
  applicationContracts: PropTypes.array.isRequired,
  getCostingCategories: PropTypes.func.isRequired,
  getHWCostingCategories: PropTypes.func.isRequired,
  inventoryRegisterFiles: PropTypes.object.isRequired,
  setInventoryRegisterFiles: PropTypes.func.isRequired,
  setApplicationContracts: PropTypes.func.isRequired,
  setApplicationDetail: PropTypes.func.isRequired,
  setApplicationLog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  userBases: PropTypes.array.isRequired,
  dataPlans: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired,
  inventoryRegisterFileUrls: PropTypes.array.isRequired,
  getCostGroup: PropTypes.func.isRequired,
  costGroup: PropTypes.object.isRequired,
  applicationLifecycle: PropTypes.object.isRequired,
  getCostingDetail: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  getContractYears: PropTypes.func.isRequired,
  contractYears: PropTypes.array.isRequired,
  setToast: PropTypes.func.isRequired,
  costingConfig: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  applications: selectApplications(),
  application: selectApplicationDetail(),
  departments: selectInfos('Departments'),
  locations: selectInfos('Locations'),
  platforms: selectInfos('Platforms'),
  vendors: selectInfos('Vendors'),
  tags: selectInfos('Tags'),
  processes: selectInfos('Processes'),
  legalOwners: selectInfos('LegalOwners'),
  scopes: selectInfos('Scopes'),
  currencies: selectInfos('Currencies'),
  categories: selectInfos('Categories'),
  userBases: selectInfos('UserBases'),
  dataPlans: selectInfos('DataPlans'),
  plans: selectInfos('Plans'),
  users: selectUsers(),
  comments: selectComments(),
  logs: selectApplicationLog(),
  appConfig: selectAppConfig(),
  applicationContracts: selectApplicationContracts(),
  inventoryRegisterFiles: selectInventoryRegisterFiles(),
  inventoryRegisterFileUrls: selectInventoryRegisterFileUrls(),
  user: selectActiveUser(),
  costGroup: selectCostGroup(),
  applicationLifecycle: selectApplicationLifecycle(),
  contractYears: selectContractYears(),
  costingConfig: selectCostingConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      doComment,
      getApplicationLog,
      getComments,
      uploadAppFile,
      getApplicationDetail,
      updateApplication,
      followApplication,
      setAppConfig,
      setInventoryRegisterFileUrls,
      getApplicationContracts,
      getCostingCategories,
      getHWCostingCategories,
      getCostingDetail,
      setInventoryRegisterFiles,
      setApplicationContracts,
      setApplicationDetail,
      setApplicationLog,
      getCostGroup,
      deleteFile,
      deleteApplication,
      getContractYears,
      setToast,
    }, dispatch)
  )
);

const InventoryDetailWithWidth = withWidth()(InventoryDetail);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailWithWidth);

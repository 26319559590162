import React from 'react';

export default {
  Giris: {
    Giris_Yap: 'Giriş Yap',
    Hesap_Yarat: 'Hesap Yarat',
    Tebrikler: 'Tebrikler',
    Devam: 'Devam',
    Parolami_Unuttum: 'Parolamı Unuttum',
    Tamamla: 'Tamamla',
    Sifirlama_Postasi_Gonder: 'Sıfırlama E-postası Gönder',
    Sirket_Aktivasyon: 'Şirket Aktivasyon',
    Aktivasyon_Kodunu_Giriniz: 'Aktivasyon Kodunu Giriniz',
    Kodu_Tekrar_Gonder: 'Kodu Tekrar Gönder',
    Hosgeldiniz_Mesaji: (
      <h5>Hoş geldiniz! İşlem yapmak için lütfen giriş yapınız.</h5>
    ),
    Hesap_Yok_Mesaji: (
      <>Henüz bir hesabım yok, hesap yarat! </>
    ),
    Zaten_Hesap_Var_Mesaji: (
      <>Zaten bir hesabım var, giriş yap! </>
    ),
    Hesap_Yarat_Mesaji: (
      <h5>Hoş geldiniz! 2 dakika içerisinde yeni bir Loggle hesabı edinin.</h5>
    ),
    Hesap_Yaratildi_Mesaji: (
      <h5>Hesabınız yaratıldı! E-postanıza gelen aktivasyon linkini kullanarak hesabınızı aktive  ettikten sonra kullanıma başlayabilirsiniz.</h5>
    ),
    Sifre_Sifirlama_Mesaji: (
      <h5>Hoş geldiniz! İşlem yapmak için lütfen e-posta adresinizi giriniz.</h5>
    ),
    Aktivasyon_Mesaji: (
      <h5>Hoş geldiniz! İşlem yapmak için lütfen aktivasyon kodunuzu giriniz.</h5>
    ),
    Hesap_Aktive_Edildi_Mesaji: (
      <h5>Hesabınız aktive edildi! E-postanıza gelen şifreyi kullanarak giriş yapabilir ve kullanıma başlayabilirsiniz.</h5>
    ),
    Sifre_Sifirlama_Tamamlandi_Mesaji: (
      <h5>Hesabınızın şifresi sıfırlandı! Oluşturduğunuz yeni şifreyi kullanarak giriş yapabilirsiniz.</h5>
    ),
  },
  Bildirim: {
    Bildirim_Ekrani: 'Bildirim Ekranı',
    Yaklasanlar: 'Yaklaşanlar',
    Bugun: 'Bugün',
    Dun: 'Dün',
    Yorum_Yap: 'Yorum Yap',
    Goruntule: 'Görüntüle',
    Tumunu_Temizle: 'Tümünü Temizle',
    Yarın_için_hatırlatıcı_bulunmaktadır: 'Yarın için hatırlatıcı bulunmaktadır ',
    bir_yorumda_sizden_bahsetti: ' bir yorumda sizden bahsetti.',
    Sistem_Sorumlusu_olarak_atandınız: 'Sistem Sorumlusu olarak atandınız ',
    Teknik_Sorumlu_olarak_atandınız: 'Teknik Sorumlu olarak atandınız ',
    İş_Sorumlusu_olarak_atandınız: 'İş Sorumlusu olarak atandınız ',
    Bir_yeni_anketiniz_var: 'Bir yeni anketiniz var ',
    uygulamanın_sözleşme_bitiş_tarihi_yaklaşıyor: 'Uygulamanın sözleşme bitiş tarihi yaklaşıyor',
  },
  Sifre_Sifirlama: {
    Sifre_Sifirlama: 'Şifre Sıfırlama',
    Sifirlama_Mesaji: (
      <h5>Hoş geldiniz! İşlem yapmak için  lütfen yeni şifrenizi giriniz.</h5>
    ),
  },
  Anasayfa: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Department_Budget_Graphic: 'Departman Bütçe Grafiği',
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Yearly_Cost: 'Yıllık Maliyet',
    Costs_Per_It_Domain: 'IT Alanına Göre Maliyet',
    Costs_Per_Business_Capability: 'İş Alanına Göre Maliyet',
    Licence_sw_Maint: 'Licence/SW Maint',
    Sla_Contract_Fix_Per_Year: 'Yıla Göre SLA Anlaşmaları',
    Variable_Costs_Per_Year: 'Yıla Göre Değişken Maliyet',
    Yearly_Cost_Application_Name_And_Cost_Type: 'Yıllık Maliyet Uygulama Adı ve Maliyet Tipi',
    Location_And_Countries: 'Lokasyon ve Ülkeler',
    Kullanilan_Butce: 'Kullanılan Bütçe',
    Bilgi_Kutusu_Ekle: 'Bilgi Kutusu Ekle',
    Yatirim_Yap: 'Yatırım Yap',
    Yorumlar: 'Yorumlar',
    Sistem_Onerisi: 'Sistem Önerisi',
    Maliyet_Grafigi: 'Maliyet Grafiği',
    Toplam_Maliyet: 'Toplam Maliyet',
    Dashboard_duzeni: 'Görünüşü Düzenle',
    Sirket_Secimi: 'Şirket Seçimi',
    Toplam_Uygulama: 'Toplam Uygulama',
    Silinen_Uygulama: 'Silinen Uygulama',
    Arşivlenen_Uygulama: 'Arşivlenen Uygulama',
    Aktif_Uygulama: 'Aktif Uygulama',
    TIME_Grafigi: 'TIME Grafiği',
    Sistem_Sorumlusu_Olduğum_Uygulama: 'Sistem Sorumlusu',
    Teknik_Sorumlusu_Olduğum_Uygulama: 'Teknik Sorumlu',
    Süresi_Bitmek_Üzere_Olan_Lisans: 'Süresi Bitmek Üzere',
    Geçen_Ay_Oluşturulan_Uygulamalar: 'Geçen Ay Oluşturalan Uygulamalar',
    İş_Sorumlusu_Olduğum_Uygulama: 'İş Sorumlusu Olduğum Uygulama',
    Istatistik_Artis: 'İstatistik Artış',
    Her_Seyi_Arttir: 'Her Şeyi arttır',
    Lisans_Bitisi: 'Lisans Bitişi',
    Lisans_Baslangici: 'Lisans Başlangıcı',
    Kisisel_Hatirlatici: 'Kişisel Hatırlatıcı',
  },
  Envanter_Detay: {
    Application_Plan_Detail: 'Uygulamanın teknik planlamasına dair bilgiler burada gösterilmektedir.',
    Application_Plan: 'Uygulama Planı',
    Application_Details_Detail: 'Bu alanda Uygulama Yaşam Döngüsü işlenen uygulamanın bilgileri gösterilmektedir.',
    Percentage: 'Yüzde',
    Costing_Detail_Detail: 'Ürünün aylık kırılımları görüntülenir. Maliyet bilgileri için harcama kalemine tıklayınız.',
    Run_Cost_Per_Year: 'Yıla Göre Çalıştırma Maliyeti',
    Other_Costs: 'Diğer Maliyetler',
    Other_Contracts: 'Diğer Kontratlar',
    Licenses: 'Lisanslar',
    Costing_Summary_Detail: 'Bu alanda, girilen maliyet bilgilerinden hareketle oluşturulan finansal kırımlar gösterilmektedir. Detaylı maliyet bilgileri maliyet ekranında görüntülenebilir.',
    Costing_Summary: 'Maliyet Özeti',
    You_Can_Add_Your_Documents_And_Save_Your_Data: 'Doküman yükleyebilir ve bilgileri kaydedebilirsiniz',
    Add_Document_And_Link: 'Doküman veya Link Ekle',
    You_Can_Find_Info_About_The_People_In_Contact_With_Your_Application_Here: 'Uygulamayla alakalı çalışanların bilgileri burada görüntülenebilir',
    Owner_Details: 'Sorumlu Bilgileri',
    Info_Regarding_The_Technical_Aspect_Of_Your_Application_Are_Shown_Below: 'Ürünün teknik bilgileri bu bölümde gösterilmektedir.',
    Technical_Information: 'Teknik Bilgiler',
    What_Is_That_Mean_User_Base: 'Kullanıcı kitlesi, ürünü kullanan personel sayısını belirtir.',
    You_Can_Examine_All_The_Info_About_Your_Application_Below: 'Uygulamaya dair tüm bilgiler bu alanda görüntülenebilir',
    Next_Year: 'Gelecek Sene',
    Current_Year: 'Cari Sene',
    Previous_Year: 'Geçen Sene',
    Application_Lifecycle: 'Uygulama Yaşam Döngüsü',
    Application: 'Uygulama',
    Business_Criticality: 'İş Kritikliği',
    Cost_Fitness: 'Maliyet Uygunluğu',
    Business_Fitness: 'İş Uygunluğu',
    Technical_Fitness: 'Teknik Uygunluk',
    Delete_Cost: 'Maliyeti Sil',
    Envanter_Sayisi: 'adet Envanter Bulundu',
    Dosya_Linkleri: 'Dosya Linkleri',
    Yeni_Envanter: 'Yeni Uygulama',
    Yeni_Envanter_Girisi: 'Yeni Envanter Girişi',
    Envanter_Detay: 'Envanter Detay',
    Ilgili_Anketleri_Goruntule: 'İlgili Anketleri Görüntüle',
    Is_Degeri: 'İş Değeri',
    Teknik_Kalite_Degeri: 'Teknik Kalite Değeri',
    Goruntule: 'Görüntüle',
    Yukle: 'Yükle',
    Yorumlari_Goruntule: 'Yorumları Görüntüle',
    Yasam_Dongusu: 'Yaşam Döngüsü',
    Yeni_Lisans_Ekle: 'Yeni Lisans Ekle',
    Lisans_Islemleri: 'Lisans İşlemleri',
    Bt_Kalitesi: 'BT Kalitesi',
    Sistem_Onerisi: 'Sistem Önerisi',
    Yatirim_Yap: 'Yatırım Yap',
    Yorumlar: 'Yorumlar',
    Uygulama_Guncelleme_Sorusu: 'Uygulamayı güncellemek istediğinize emin misiniz',
    Uygulama_Silme_Sorusu: 'Lisansı silmek istediğinize emin misiniz',
    Stop: 'Yenilenmesin',
    Continue: 'Yenilensin',
    Bu_uygulama_icin_belge_bulunamadı: 'Bu uygulama için belge bulunamadı!',
    // yeni alan
    Manifacturer: 'İmalatçı',
    Application_Save_Draft_Message: 'Envanter draft olarak kaydedildi',
    // ///yeni alan
  },
  Takvim: {
    Yeni_Etkinlik: 'Yeni Etkinlik',
    Hatirlatici_Kur: 'Hatırlatıcı Kur',
    Yeni_Form: 'Yeni Form Oluştur',
    Yeni_Lisans_Uygulama_Ekle: 'Bu tarih için bir uygulama veya lisans ekleyin',
    Etkinlik_Kaydedildi: 'Etkinliğiniz başarılı bir şekilde kayıt edildi',
    Hatirlatici_Olusturulamaz_Mesaji: 'Geçmiş tarihe hatırlatıcı oluşturamazsın',
    Hatirlatici_Olusturabilir_Mesaji: 'Bu tarih için hatırlatıcı oluşturabilirsiniz',
    Listeler: 'Listeler',
    Ay: 'Ay',
    Yil: 'Yıl',
    Hafta: 'Hafta',
  },
  Yonetici: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Key_Contact_For_Company: 'Şirket İletişim Bilgisi',
    Yeni_Url_Ekle: 'Yeni Url Ekle',
    Url_Guncelle: 'URL Güncelle',
    Genel_Rapor_Mail_Kullanicilari: 'Genel Rapor Mail Kullanıcıları',
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Manage_Vendors: 'Müşteri Temsilcilerini Yönet',
    Yonetici: 'Yönetici',
    Zorluk: 'Zorluk',
    Renk: 'Renk',
    Para_Birimi: 'Para Birimi',
    Dil_Secimi: 'Dil Seçimi',
    Zaman_Asim_Suresi: 'Zaman Aşım Süresi',
    Logo: 'Logo',
    Sirket_Islemleri: 'Şirket İşlemleri',
    Genel_Ayarlar: 'Genel Ayarlar',
    Sifre_Politikasi: 'Şifre Politikası',
    Envanter_Verilerini_Indir: 'Envanter Verilerini İndir',
    Uygulama_Envanterinde_Degisiklik_Log_Indir: 'Uygulama Envanterindeki Değişiklik Loglarını İndir',
    Yonetici_Ekrani: 'Yönetici Ekranı',
    Kullanici_Islemleri: 'Kullanıcı İşlemleri',
    Yeni_Kullanici_Ekle: 'Yeni Kullanıcı Ekle',
    Kullanici_Ekle: 'Kullanıcı Ekle',
    Etiket_Islemleri: 'Etiket İşlemleri',
    Yeni_Etiket_Ekle: 'Yeni Etiket Ekle',
    Yeni_Etiket: 'Yeni Etiket',
    Veri_Islemleri: 'Veri İşlemleri',
    Veri_Duzenle: 'Veri Düzenle',
    Veriyi_Kaydet: 'Veriyi Kaydet',
    SirketSeciniz: 'Şirket Seciniz',
    SirketEkle: 'Şirket Ekle',
    Yaratan: 'Yaratıcı',
    Konfigurasyonlar: 'Konfigürasyonlar',
    Anket_Islemleri: 'Anket İşlemleri',
    Tamamlandi: 'Tamamlandı',
    Anket_Olustur: 'Anket Oluştur',
    Yeni_Anket_Olusturmak_Icin_Tiklayin: 'Yeni bir anket oluşturmak için tıklayın',
    Tumu: 'Tümü',
    Bitenler: 'Bitenler',
    Devam_Edenler: 'Devam edenler',
    Tum_Anketleri_Gor: 'Tüm Anketleri Gör',
    Kisi_Yonetimi: 'Kişi Yönetimi',
    Sirket_Yonetimi: 'Şirket Yönetimi',
    Tags: 'Etiketler',
    LicenseTypes: 'Lisans Tipleri',
    Locations: 'Konumlar',
    Departments: 'Departmanlar',
    Sirket_Bilgileri: 'Şirket Bilgileri',
    Logo_Eklemek_İçin_Tıklayın: 'Logo Eklemek İçin Tıklayın',
    Şirket_Sahibi_Bilgileri: 'Şirket Sahibi Bilgileri',
    Görevi: 'Görevi',
    Sahibi: 'Sorumlu',
    Kullanıcı_Seç: 'Kullanıcı Seç',
    Şirketine_Kullanıcı_Ekle: 'Şirketine Kullanıcı Ekle',
    Kullanıcı_Arayın: 'Kullanıcı Arayın',
    Ekleyebileceğiniz_Kullanıcı_Bulunmamaktadır: 'Ekleyebileceğiniz Kullanıcı Bulunmamaktadır',
    Şirket_Ekle: 'Şirket Ekle',
    Örnek_Şirket_Adı: 'Örnek Şirket Adı',
    Şirket_Ön_Eki: 'Şirket Ön Eki',
    Örnek_Şirket_Ön_Eki: 'Örnek Şirket Ön Eki',
    Örnek_Kullanıcı_Ünvanı: 'Örnek Kullanıcı Ünvanı',
    Kullanıcı_Ünvanı: 'Kullanıcı Ünvanı',
    Sirket_Logo_Degistir: 'Şirket Logosunu Değiştir',
    LegalOwners: 'Yasal Sorumlular',
    FunctionalAreas: 'Fonksiyonel Alanları',
    Categories: 'IT Alanı',
    Processes: 'İş Alanı',
    Platforms: 'Hosting Tipi',
    Vendors: 'Üretici / Tedarikçi',
    TcoAggregations: 'Küme Çalıştırma Maliyeti',
    Kullanici_Adi: 'Kullanıcı Adı',
    Tum_Kullanici_Gruplari: 'Tüm Kullanıcı Grupları',
    Manuel_Kullanici_Grubu_Ekle: 'Manuel Kullanıcı Grubu Ekle',
    Kullanici_Grubu_Ara: 'Kullanıcı Grubu Ara',
    Kullanici_Grubu_Sec: 'Kullanıcı Grubu Seç',
    Kullanici_Silme_Mesaji: 'Kullanıcıyı silmek istediğinize emin misiniz?',
    Add_Vendor_User: 'Müşteri Temsilcisi Ekle',
    Aggregation_Name: 'Kümeleme İsmi',
    Deger_bos_birakilamaz: 'Değer alanı boş bırakılamaz',
    HostLocations: 'Lokasyon',
  },
  Profil: {
    Profil: 'Profil',
    Kullanici_Profili: 'Kullanıcı Profili',
    Kullanici_Düzenleme: 'Kullanıcı Düzenleme',
    Kisisel_Bilgiler: 'Kişisel Bilgiler',
    Bilgileri_Guncelle: 'Bilgileri Güncelle',
    Bilgileri_Düzenle: 'Bilgileri Düzenle',
    Sifre_Guncelle: 'Şifre Güncelle',
    Sorumlu_Envanter: 'Yarattığınız / Sorumlu Olduğunuz Anketler',
    Profil_Foto_Degistir: 'Profil Fotoğrafını Değiştir',
    Son_Giris: 'Son Giriş',
    Tarih_Hatirlatici_Kur: 'Bu tarih için hatırlatıcı kur',
    Fotograf_Onizleme: 'Fotoğraf Önizleme',
    Duzenle: 'Düzenle',
  },
  Envanter_Lisans: {
    Lisans_Ekleme: 'Lisans Ekleme',
    Lisans_Planlamasi: 'Lisans Planlaması',
    Lisans_Ekleme_Bilgisi: 'Uygulamanın lisans bilgileri bu ekrandan kaydedilir. Bütçe yönetimi ve planlama Lisans Planlama alanından yapılır.',
    Lisans_Planlama_Bilgisi: 'Satın alım lisanslarınızın aksiyon planı ve bütçelendirmesi bu alanda yapılır.',
  },
  Inputs: {
    Sifre: 'Şifre',
    Action_App_Notes: 'Uygulama Notları',
    Plan_Data: 'Veriye Dair Plan',
    Plan_App: 'Uygulamaya Dair Plan',
    Succesor_Application: 'Yerine Geçen Uygulama',
    Predecessor_Application: 'Önceki Uygulama',
    Business_Domain: 'İş Alanı',
    User_Base: 'Kullanıcı Kitlesi',
    Contract_Url: 'Kontrat URL',
    Add_Url: 'URL Ekle',
    Url_Control: 'Lütfen geçerli bir URL giriniz.',
    Dosya_Yukle: 'Dosya Yükle',
    Lutfen_Bir_Dosya_Seciniz: 'Lütfen bir dosya seçiniz',
    Sifre_giriniz: 'Şifre giriniz',
    Sifrenizi_Tekrar_Giriniz: 'Şifrenizi tekrar giriniz',
    Indir: 'İndir',
    Tarih: 'Tarih',
    Etkinlik_Adi: 'Etkinlik Adı',
    Veri_Ara: 'Veri Ara',
    Veri_Tipi: 'Veri Tipi',
    Tanim: 'Tanım',
    Ad_Soyad: 'Ad Soyad',
    Adi: 'Adı',
    Soyadi: 'Soyadı',
    Yetki: 'Yetki',
    Eposta_Adresi: 'E-posta Adresi',
    Sifre_Degisikligi: 'Şifre Değişikliği',
    Yeni_Sifre: 'Yeni Şifre',
    Yeni_Sifre_Tekrar: 'Yeni Şifre Tekrar',
    Uygulama_Adı: 'Uygulama Adı',
    Uygulama_Ara: 'Uygulama Ara',
    Versiyon: 'Versiyon',
    Etiket: 'Etiket',
    Departman: 'Departman',
    Surec: 'Süreç',
    Konum: 'Konum',
    Lokasyon: 'Lokasyon',
    Platform: 'Platform',
    Uygulama_Aciklamasi: 'Uygulama Açıklaması',
    Is_Birimi_Sorumlulari: 'İş Birimi Sorumluları',
    Sistem_Sorumlulari: 'Sistem Sorumluları',
    Yasal_Sorumlular: 'Yasal Sorumlular',
    Butce_Sorumlulari: 'Bütçe Sorumluları',
    Lisans_Girisi: 'Lisans Girişi',
    Numarali_Lisans: 'Numaralı Lisans',
    Dokuman: 'Dokümanlar',
    Yorum_Ekle: 'Yorum Ekle',
    Yeni_Yorum_Ekle: 'Yeni Bir Yorum Ekleyebilirsiniz',
    Platform_Etiket_Ekle: 'Platform & Etiket Ekle',
    Ebeveyn_Uygulamasi: 'Ebeveyn Uygulaması',
    mail_giriniz: 'E-Posta Adresiniz',
    Adiniz_Soyadiniz: 'Adınız Soyadınız',
    Tel_No: 'Telefon Numaranız',
    Sirket_Adi: 'Şirket Adı',
    Sirket_Prefix: 'Şirketin Kısaltması',
    User_Title: 'Ünvanınız',
    Yeni_Sifreniz: 'Yeni Şifreniz',
    Yeni_Sifrenizi_Tekrar: 'Yeni Şifrenizi Tekrar Giriniz',
    Parolaniz_Uyusmuyor: 'Parolanız Uyuşmuyor',
    Sozlesme_Adi: 'Sözleşme Adı',
    Sozlesme_Baslangic_Bitis: 'Sözleşme Başlangıç ve Bitiş Tarihi',
    Lisans_Aciklamasi: 'Lisans Açıklaması',
    Yil_Secimi: 'Yıl Seçimi',
    Lisans_Plani: 'Lisans Planı',
    Aksiyon_Plani: 'Aksiyon Planı',
    Veri_Plani: 'Veri Planı',
    Butce_Alani: 'Bütçe Alanı',
    Bakim_Butcesi: 'Bakım Bütçesi',
    SLA_Butcesi: 'SLA Bütçesi',
    Degisken_Maliyet_Butcesi: 'Değişken Maliyet Bütçesi',
    Kaydet: 'Kaydet',
    Butce: 'Bütçe',
    Sozlesme_Baslangic: 'Sözleşme Başlangıç',
    Sozlesme_Bitis: 'Sözleşme Bitiş',
    AdSoyad_Eksik: 'Ad ve soyadınızı tekrar kontrol ediniz',
    Daha_Fazla_Goster: 'Daha Fazla Göster',
    Takip_Et: 'Takip Et',
    Takipten_Cik: 'Takipten Çık',
    Cikti_Al: 'Çıktı Al',
    Kopyala: 'Kopyala',
    Arsiv: 'Arşiv',
    Duzenle: 'Düzenle',
    Tedarikci: 'Tedarikçi',
    Teknik_Sorumlular: 'Teknik Sorumlular',
    Lisansli_Kullanici_Sayisi: 'Lisanslı Kullanıcı Sayısı',
    Aktif_Kullanici_Sayisi: 'Aktif Kullanıcı Sayısı',
    Yillik_Total_Maliyet: 'Yıllık Total Maliyet',
    Eski_Kullanilan: 'Eski Kullanılan',
    Yerine_Gecen_Uygulama: 'Yerine Geçen Uygulama',
    Butce_Sorumlusu_Departman: 'Bütçe Sorumlusu Departman',
    Firma_Ilgili_Kisi: 'Firma İlgili Kişi',
    Kullanici: 'Kullanıcı',
    Masraf_Yeri: 'Masraf Yeri',
    Kar_Merkezi: 'Kâr Merkezi',
    Sozlesmenin_Olusturuldugu_Tarih: 'Sözleşmenin Oluşturulduğu Tarih',
    Sozlesme_Donemi_Yili: 'Sözleşme Dönemi Yılı',
    Dagitim_Yapilacak_Cari_Ay: 'Dağıtım Yapılacak Cari Ay',
    Yillik_Lisans_Bedeli: 'Yıllık Lisans Bedeli',
    Kdv_Orani_Tutari: 'KDV Oranı ve Tutarı',
    Damga_Vergisi: 'Damga Vergisi',
    Tco: 'TCO',
    Para_Birimi: 'Para Birimi',
    Kur: 'Kur',
    Evet: 'Evet',
    Hayir: 'Hayır',
    Anketler: 'Anketler',
    Uygulama_ID: 'Uygulama ID',
    Kapsam: 'Kapsam',
    Fonksiyonel_Alan: 'Fonksiyonel Alan',
    Kategori: 'Kategori',
    Is_Sureci: 'İş Süreci',
    Saglayici_Platformu: 'Sağlayıcı Platformu',
    Saglayici_Sehri: 'Sağlayıcı Şehri',
    Saglayici_Ulkesi: 'Sağlayıcı Ülkesi',
    Yonetim: 'Yönetim',
    IP_adress: 'Geçeli bir :name adresi girin',
    Uygulama_ara_ve_ekle: 'Uygulama ara ve ekle',
    Donanim_ara_ve_ekle: 'Donanım ara ve ekle',
    Aktif: 'Aktif',
    Pasif: 'Pasif',
    Dur: 'Dur',
    Stopped: 'Kapalı',
    User_Base_Info: <>Uygulamaya erişimi olan toplam çalışan sayısını belirtir.</>,
    Scope_Info: <>Uygulamanın kapsamı, uygulamanın faaliyet bölgesi ile ilgilidir.</>,
    Tag_Info: <>Uygulamaları gruplandırarak ve etiketleyerek daha sonra bulmayı kolaylaştırabilirsiniz.</>,
    IT_Domain_Info: <>BT Etki Alanı, uygulamanın birincil odak alanını ifade eder.</>,
    Business_Capability: 'İş Fonksiyonu',
  },
  Tablo_Kolonlar: {
    Durum: 'Durum',
    Id: 'ID',
    Aciklama: 'Açıklama',
    Yaratilma_Tarihi: 'Yaratılma Tarihi',
    Uygulama_Adi: 'Uygulama Adı',
    Donanim_Adi: 'Donanım Adı',
    Adi: 'Adı',
    Soyad: 'Soyad',
    Telefon: 'Telefon',
    Unvan: 'Unvan',
    Email: 'E-Posta',
  },
  Aylar: {
    Ocak: 'Ocak',
    Şubat: 'Şubat',
    Mart: 'Mart',
    Nisan: 'Nisan',
    Mayis: 'Mayıs',
    Haziran: 'Haziran',
    Temmuz: 'Temmuz',
    Agustos: 'Ağustos',
    Eylul: 'Eylül',
    Ekim: 'Ekim',
    Kasim: 'Kasım',
    Aralik: 'Aralık',
    Ock: 'Ock',
    Şub: 'Şub',
    Mrt: 'Mar',
    Nis: 'Nis',
    May: 'May',
    Haz: 'Haz',
    Tem: 'Tem',
    Agu: 'Ağu',
    Eyl: 'Eyl',
    Eki: 'Eki',
    Kas: 'Kas',
    Ara: 'Ara',
  },
  Gunler: {
    Pazartesi: 'Pazartesi',
    Salı: 'Salı',
    Çarşamba: 'Çarşamba',
    Perşembe: 'Perşembe',
    Cuma: 'Cuma',
    Cumartesi: 'Cumartesi',
    Pazar: 'Pazar',
    Pzts: 'Pzts',
    Sal: 'Sal',
    Çar: 'Çar',
    Per: 'Per',
    Cum: 'Cum',
    Cmts: 'Cmts',
    Pzr: 'Pzr',
  },
  Ortak: {
    Silme_Mesaji: 'Silmek istediğinize emin misiniz?',
    Duzenleme_Kapat: 'Düzenlemeyi Kapat',
    Kayit_Ekle: 'Kayıt Ekle',
    Seciniz: 'Seçiniz',
    Detay: 'Detay',
    Add: 'Ekle',
    Type: 'Tip',
    Kullanici: 'Kullanıcı',
    You_Can_Upload_File_From_Here: 'Buradan doküman yükleyebilirsiniz',
    File_Type_Detail: 'Doküman tipi jpeg, png, svg, pdf olmalıdır',
    Deleting: 'Siliniyor',
    Excel_Error_List: 'Excel Hata Listesi',
    Integration_Credentials: 'Entegrasyon Bilgileri',
    Sirket_Suresi_Doldu: 'Şirket süresi doldu',
    Toplam: 'Toplam',
    Yeni: 'Yeni',
    Save_View: 'Kaydet & Görüntüle',
    Save_Close: 'Kaydet & Kapat',
    Ana_Sayfa: 'Ana Sayfa',
    Sayfa_Bulunamadı: 'Sayfa Bulunamadı',
    Tamam: 'Tamam',
    Temizle: 'Temizle',
    Uygula: 'Uygula',
    Sifirla: 'Sıfırla',
    Geri: 'Geri',
    Sil: 'Sil',
    Genel_Bakis: 'Genel Bakış',
    Envanter: 'Uygulama Envanteri',
    Raporlar: 'Raporlar',
    Masraf: 'Masraf',
    Takvim: 'Takvim',
    Bildirimler: 'Bildirimler',
    Anketler: 'Anketler',
    Ayarlar: 'Ayarlar',
    Cari_Ay: 'Cari Ay',
    Cari_Hafta: 'Cari Hafta',
    Gecen_Ay: 'Geçen Ay',
    Son_Uc_Ayın_Ort: 'Son 3 Ayın Ortalaması',
    Ek_Maliyet_Dokumu: 'Ek Maliyet Dökümü',
    Filtre: 'Filtre',
    Filtrele: 'Filtrele',
    Sutun_Filtrele: 'Sütun Filtrele',
    Sutun_Gizle_Goster: 'Sütun Gizle/Göster',
    Tumunu_Sil: 'Tümünü Sil',
    Iptal_Et: 'İptal Et',
    Bu_Satiri_Kaydet: 'Bu Satırı Kaydet',
    Bu_Satiri_Duzenle: 'Bu Satırı Düzenle',
    Yeni_Satir_Ekle: 'Yeni Satır Ekle',
    Veri_Yok: 'Veri yok',
    Yeni_Oge_Ekle: 'Yeni Öğe Ekle',
    Deger: 'Değer',
    Yonet: 'Yönet',
    Gonder: 'Gönder',
    Yorumlar: 'Yorumlar',
    Yorum_Yap: 'Yorum Yap',
    Yaratici: 'Yaratıcı',
    Sistem_Sorumlusu: 'Sistem Sorumlusu',
    Olusturan: 'Oluşturan',
    Teknik_Sorumlu: 'Teknik Sorumlu',
    Daralt: 'Daralt',
    Anket_Ara: 'Anket Ara',
    Basla: 'Başla',
    Devam_Et: 'Devam Et',
    Gor: 'Gör',
    Daha_Fazla_Gor: 'Daha fazla gör',
    Profil_Duzenle: 'Profil Düzenle',
    Eski_Sifre: 'Eski Şifre',
    Sirket_Degistir: 'Şirket Degiştir',
    Sifre_Degistir: 'Şifre Değiştir',
    Hakkinda: 'Hakkında',
    Yardim: 'Yardım',
    Merhaba: 'Merhaba',
    Uygulama_Lisans_Ara: 'Uygulama lisans ara',
    Ice_Aktar: 'İçe Aktar',
    Disa_Aktar: 'Dışa Aktar',
    Ornek_Indir: 'Örnek İndir',
    Yatirim: 'Yatırım',
    Kaldir: 'Kaldır',
    Birlestir: 'Birleştir',
    Tolere_Et: 'Tolere Et',
    Tema_Degistir: 'Tema Değiştir',
    Dil_Degistir: 'Dil Değiştir',
    Vazgec: 'Vazgeç',
    Dil: 'Dil',
    Arama_Yap: 'Arama Yap',
    Gösterilecek_Veri_Bulunmamaktadır: 'Gösterilecek Veri Bulunmamaktadır',
    Sonraki: 'Sonraki',
    Önceki: 'Önceki',
    Görüntülemek_için_tıklayınız: 'Başlamak için tıklayınız',
    Bağlantı_Zaman_Aşımına_Uğradı: 'Bağlantı Zaman Aşımına Uğradı',
    Yüklediğiniz_dosyada_hata_var_indirilen_dosyayı_kontrol_ediniz: 'Yüklediğiniz dosyada hata var. İndirilen dosyayı kontrol ediniz.',
    Sunucu_ile_bağlantı_kurulamadı: 'Sunucu ile bağlantı kurulamadı!',
    Dosya_Indirilemedi: 'Dosya indirilemedi',
    Duzenlemeyi_Tamamla: 'Düzenlemeyi Tamamla',
    Arsivle: 'Arşivle',
    Uygulamayi_Arsivleyin: 'Uygulamayı Arşivleyin',
    Uygulama_Arsivlendi: 'Uygulama Arşivlendi',
    Arsivden_Kurtar: 'Arşivden Kurtar',
    Arsivleme_Nedeni: 'Arşivleme Nedeni',
    Arsivleme_nedeninizi_giriniz: 'Arşivleme nedeninizi giriniz',
    Uygulamalar: 'Uygulamalar',
    Lisanslar: 'Lisanslar',
    Loglar: 'Loglar',
    Baslangic_Tarihi: 'Başlangıç Tarihi',
    Bitis_Tarihi: 'Bitiş Tarihi',
    Diger_Secenegi_Ekle: <>&quot;Diğer&quot; Seçeneği Ekle</>,
    Anket_Uyari_Metni: <>Bu anket, ilgili envanteri kullanım düzeyinizi belirlemek maksadıyla gönderilmiştir. Sonunda (*) olan sorular mutlaka cevaplanması gereken, diğerleri opsiyonel sorulardır.</>,
    Sayfa_Bulunamadi_Mesaji: <>OOPPS! ARADIĞINIZ SAYFA BULUNAMADI</>,
    Hizmet_Mesaji: <>Özel ve ticari müşteriler için profesyonel bir hizmet sunuyoruz.</>,
    Ana_Sayfaya_Don: 'Ana Sayfaya Dön',
    simdi: 'şimdi',
    dakikaKisa: 'dk',
    saatKisa: 'sa',
    gunKisa: 'g',
    haftaKisa: 'h',
    ayKisa: 'ay',
    Dosya_Ekle: 'Dosya Ekle',
    Tumunu_Gor: 'Tümünü Gör',
    Tarihce: 'Tarihçe',
    Dokuman_Ekle: 'Döküman Ekle',
    Tahmini: 'Tahmini',
    Abonelik: 'Abonelik',
    Secilmedi_Mesaji: <>-- Seçilmedi --</>,
    İptal: 'İptal',
    Guncelle: 'Güncelle',
    Taslak: 'Taslak',
    Lutfen_Zorunlu_Alanlari_Doldurun: 'Lütfen Zorunlu Alanları Doldurunuz',
    Alan_Kodu: 'Alan Kodu',
    Premium_Mesaji: 'Bu alana Premium hesapla ulaşılabilir.',
    LDAP_Kullanici_Ekleme: 'LDAP Kullanıcı Ekleme',
    Tamamlananlar: 'Tamamlananlar',
    Taslaklar: 'Taslaklar',
    Rows_Per_Page: 'Sayfa Başı Satır',
    Full_Period: 'Tam Dönem',
    URL_Added: 'URL Eklendi',
    File_Added: 'Dosya Eklendi',
    Delete_Application: 'Uygulamayı Sil',
    You_can_add_max_3_files: 'En fazla 3 dosya ekleyebilirsiniz',
    URL_Field_Req_Message: 'Uygulama URL alanını doldurmak zorunludur ve minimum 3 karakter olmak zorundadır',
    App_Url: 'Uygulama URL',
    Contract_URL_Field_Req_Message: 'Kontrat URL alanını doldurmak zorunludur ve minimum 3 karakter olmak zorundadır',
    Information: 'Bilgilendirme',
    Select_Date_To_Continue_Message: 'Devam etmek için tarih seçiniz.',
    Delete_License: 'Lisans Sil',
  },
  Kenar_Menu: {
    Cikis_Yap: 'Çıkış Yap',
    Koyu_Moda_Gec: 'Koyu Moda Geç',
    Secilen_Dil: 'Seçilen Dil',
  },
  Anketler: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Yanıtlanacak_Anket: 'Yanıtlanacak Anket',
    Yanıtlanan_Anket: 'Yanıtlanan Anket',
    Yanıtlanmayan_Anket: 'Yanıtlanmayan Anket',
    Yarım_Kalan_Anketler: 'Yarım Kalan Anketler',
    Soru_Sayısı: 'Soru Sayısı',
    /**
      * ---------------------///Yeni Alan ---------------------
      */
    Detayli_Gor: 'Detaylı gör',
    Detayi_Gizle: 'Detayı gizle',
    Soru_Detayi: 'Soru Detayı',
    En_Cok_Verim_Alinan_Uygulama_Sorusu: 'En çok kullandığınız ve verim aldığınız uygulama hangisidir ?',
    Taslak_Olarak_Kaydet: 'Taslak Olarak Kaydet',
    Geri_Don: 'Geri dön',
    Gerekli: 'Gerekli',
    Yanitlanmasi_Gereken_Anketler: 'Yanıtlanması Gereken Anketler',
    Uygulama_Secin: 'Uygulama Seçin',
    Anket_Sonlandirilmistir: 'Anket Sonlandırılmıştır',
    Anketi_Gor: 'Anketi Gör',
    Anketi_Bitir: 'Anketi Bitir',
    En_az_bir_soru_eklemelisiniz: 'En az bir soru eklemelisiniz',
    Soru_alanlarini_bos_birakamazsiniz: 'Soru alanlarını boş bırakamazsınız',
    Coktan_secmeli_sorularda_en_az_bir_secenek_eklemelisiniz: 'Çoktan seçmeli sorularda en az bir seçenek eklemelisiniz',
    Bolumlere_en_az_bir_soru_eklemelisiniz: 'Bölümlere en az bir soru eklemelisiniz',
    Kisa_Yanit_Metni: 'Kısa yanıt metni',
    Uzun_Yanit_Metni: 'Uzun yanit metni',
    Secenek_Ekle: 'Seçenek ekle',
    Soru_Ekle: 'Soru Ekle',
    Anketi_Gozden_Gecir: 'Anketi Gözden Geçir',
    Veya: 'veya',
    Secenegi_Ekle: 'seçeneği ekle',
    Etiket_Istege_Bagli: 'Etiket (İsteğe Bağlı)',
    Soru: 'Soru',
    Tip: 'Tip',
    Diger: 'Diğer',
    Secenek: 'Seçenek',
    Ekle: 'Ekle',
    Ek_Bilgi: 'Ek bilgi',
    Temel_Ayrıntılar: 'Temel Ayrıntılar',
    İçerik_Oluştur: 'İçerik Oluştur',
    İnceleyin_ve_Yayınlayın: 'İnceleyin ve Yayınlayın',
    Başlık: 'Başlık',
    Bölüm: 'Bölüm',
    Bölüm_Ekle: 'Bölüm Ekle',
    Bölümü_Sil: 'Bölümü Sil',
    Anketin_gönderileceği_uygulamalar: 'Anketin gönderileceği uygulamalar',
    Tüm_Uygulamalar: 'Tüm Uygulamalar',
    Manuel_Uygulama_Ekle: 'Manuel Uygulama Ekle',
    Anketin_gönderileceği_kullanıcı_grupları: 'Anketin gönderileceği kullanıcı grupları',
    Tüm_Kullanıcılar: 'Tüm Kullanıcılar',
    Manuel_Kişi_Ekle: 'Manuel Kişi Ekle',
    Kişi_Seç: 'Kişi Seç',
    Uygulama_Seç: 'Uygulama Seç',
    TOPLAM_KATILIMCI: 'Toplam Katılımcı',
    TOPLAM_YANITLAYAN: 'Toplam Yanıtlayan',
    TOPLAM_YANITLAMAYANLAR: 'Toplam Yanıtlamayan',
    YARIM_KALAN_ANKETLER: 'Yarım Kalan Anketler',
    Suna_Gore_Sirala: 'Şuna Göre Sırala',
    Yanıtlandı: 'Yanıtlandı',
    Yarım_Kaldı: 'Yarım Kaldı',
    Yanıtlamadı: 'Yanıtlamadı',
    bölüm_sil_sorusu: 'Bölüm, içindeki sorularla birlikte silinecektir. Onaylıyor musunuz?',
    Henuz_Bir_Cevap_Bulunmamaktadir: 'Henüz Bir Cevap Bulunmamaktadır',
  },
  Entegrasyon: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Receiver: 'Alıcı',
    Sender: 'Gönderici',
    Mutual: 'Karşılıklı',
    Select: 'Seçilen',
    Sektor: 'Sektör',
    Url_Adi: 'Url Adı',
    Entegrasyon_Islemleri: 'Entegrasyon İşlemleri',
    Url_Statusunu_Degistirme: 'URL Statüsünü Değiştirme',
    Gonderen_Adi: 'Gonderen Adı',
    Alici_Adi: 'Alıcı Adı',
    Aktif_Pasif: 'Aktif/Pasif',
    Sap_Guncelle: 'SAP Güncelle',
    URL_Statu_Degistirme_Sorusu: 'URL durumunu değiştirmek istediğinizden emin misiniz ?',
    Responsible_Report: 'Sorumlu Raporu',
    Sender_Namespace: 'Gönderen Adı',
    Receiver_Namespace: 'Alıcı Adı',
    Responsible_Bring_Empty: 'Sorumlu alanı boş olanları getir',
    Error_Detail_Information: 'Hata Detay Bilgisi',
    Monitoring_Tipi: 'Monitoring Tipi',
    Mail_Tipi: 'Mail Tipi',
    Hata_Nedeni: 'Hata Nedeni',
    Hata_Durumu: 'Hata Durumu',
    Cozum_Onerisi: 'Çözüm Önerisi',
    Secilenleri_Mail_Gonder: 'Secilen Logları Mail Gönder',
    /**
     * ---------------------///Yeni Alan ---------------------
     */
    Component: 'Bileşen',
    Party: 'Parti',
    Adapter_Type: 'Adaptör Tipi',
    Count: 'Sayım',
    Sender_Party: 'Gönderici Taraf',
    Interface_Name: 'Arayüz İsmi',
    Interface_Namespace: 'Arayüz İsim Alanı',
    Receiver_Party: 'Alıcı Taraf',
    Message_Details: 'Mesaj Detayları',
    Message_Id: 'Message ID',
    End_Time: 'Bitiş',

    Entegrasyon: 'Entegrasyon',
    Entegrasyon_Haritasi: 'Entegrasyon Haritası',
    Grafik: 'Grafik',
    Liste: 'Liste',
    Yeni_Entegrasyon: 'Yeni Entegrasyon',
    Excel_İçe_Aktar: 'Excel İçe Aktar',
    Excel_Dışa_Aktar: 'Excel Dışa Aktar',
    Dosya_Başarılı_Bir_Şekilde_İndirildi: 'Dosya Başarılı Bir Şekilde İndirildi',
    Gonderen_Bileseni: 'Gönderen Bileşeni',
    Gonderen_Is_Ortagi: 'Gönderen İş Ortağı',
    Gonderen_Arayuzu: 'Gönderen Arayüzü',
    Alici_Bileseni: 'Alıcı Bileşeni',
    Alici_Is_Ortagi: 'Alıcı İş Ortağı',
    Alici_Arayuzu: 'Alıcı Arayüzü',
    Complexity: 'Zorluk Seviyesi',
    Criticality: 'Önem Seviyesi',
    Responsible_email: 'Sorumlu E-Postası',
    Developer: 'Geliştirici',
    Alert_email: 'Bildirim E-Postası',
    Channel_Monitoring: 'Channel Monitoring',
    Message_Monitoring: 'Message Monitoring',
  },
  Kullanıcı_Ayarları: {
    Kullanici_Ayarlari: 'Kullanıcı Ayarları',
    Profil: 'Profil',
    Tema_Degistir: 'Tema Değiştir',
  },
  Donanim: {
    Ulke: 'Ülke',
    Yeni_Donanim: 'Yeni Donanım',
    Donanim: 'Donanım',
    Donanim_Detay: 'Donanım Detayı',
    Donanim_Envanteri: 'Donanım Envanteri',
    Donanim_Tipi: 'Donanım Tipi',
    Donanim_Kategorisi: 'Donanım Kategorisi',
    Marka: 'Marka',
    Model: 'Model',
    Satin_Alma_Tarihi: 'Satın Alma Tarihi',
    Fiyat: 'Fiyat',
    Statu: 'Statü',
    Sahip: 'Sahip',
    Donanim_Modal_Bilgilendirme_Mesaji: 'Hangi depolama alanından ilerlemek istediğinizi lütfen seçiniz',
    Yazici: 'Yazıcı',
    Erisim_Noktasi: 'Erişim Noktası',
    Tarayici: 'Tarayıcı',
    Guvenlik_Duvari: 'Güvenlik Duvarı',
    Depolama: 'Depolama',
    Sunucu: 'Sunucu',
    Sanal_Makine: 'Sanal Makine',
    Son_Kullanici: 'Son Kullanıcı',
    Network_Guvenlik_CevreBirimleri: 'Network, Güvenlik ve Çevre Birimleri',
    Sunucu_Ve_VeriDepolama: 'Sunucu ve Veri Depolama',
    Tablet: 'Tablet',
    Notebook: 'Notebook',
    Bilgisayar: 'Bilgisayar',
    El_Terminali: 'El Terminali',
    Yonlendirici: 'Yönlendirici',
    Switch: 'Switch / Yönlendirici',
    Donanim_Turu: 'Donanım Türü',
  },
  Masraf: {
    Cost: 'Masraf',
    Add_Costing_Detail: 'Uygulamanın lisans bilgileri bu alandan kaydedilir. Bütçe yönetimi ve planlama Lisans Planlama alanından yapılır',
    Add_HW_Costing_Detail: 'Donanımın lisans bilgileri bu alandan kaydedilir. Bütçe yönetimi ve planlama Lisans Planlama alanından yapılır',
    Costing_Detail: 'Maliyet Bilgileri',
    Date_Information: 'Tarih Bilgileri',
    Date_Information_Detail: 'Uygulamanın kontrat tarihlerine dair bilgileri giriniz.',
    Date_Information_Detail_HW: 'Donanımın kontrat tarihlerine dair bilgileri giriniz.',
    Begining_Of_Depreciation: 'Değer Kaybı Başlangıcı',
    Depreciation_Period: 'Değer Kaybı Periyodu',
    Calculated_End_Date: 'Hesaplanan Bitiş Tarihi',
    Cost_Information: 'Maliyet Bilgileri',
    Cost_Information_Detail: 'Bu alana uygulamanın finansal bilgilerini giriniz',
    Cost_Information_Detail_HW: 'Bu alana donanımın finansal bilgilerini giriniz',
    Invoice_Currency: 'Fatura Para Birimi',
    Fx_Rate: 'Döviz Kuru',
    Total_Amount_In: 'Toplam Meblağ',
    Monthly_Depreciation_In: 'Aylık Değer Kaybı',
    Run_Cost_Aggrigation: 'Küme Uygulama Maliyeti',
    Split_Existing_Contract: 'Mevcut Kontratı Böl',
    Allocation_Of_Cost_To_Applications: 'Maliyetin Uygulama Payları',
    Allocation_Of_Cost_To_Applications_Detail: 'İlişkili maliyetleri bir ya da birden çok uygulamaya paylaştırabilirsiniz.',
    Allocation_Of_Cost_To_Hardwares: 'Maliyetin Donanım Payları',
    Allocation_Of_Cost_To_Hardwares_Detail: 'İlişkili maliyetleri bir ya da birden çok donanıma paylaştırabilirsiniz.',
    Allocation: 'Paylaştırma',
    Allocation_In: 'Paylaştırma',
    Amount: 'Meblağ',
    Total_Allocated: 'Toplam Paylaştırılan',
    Absolute_Value: 'Kesin Değer',
    Application_Costing: 'Uygulama Maliyeti',
    Vendor: 'Tedarikçi',
    Variance_Control_Message: 'Toplam tutar doğru değil, varyansı kontrol edin!',
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Uygulama_İsmi_Giriniz: 'Uygulama İsmi Giriniz:',
    Monthly_Cost_In: 'Aylık Maliyet',
    /**
     * ---------------------///Yeni Alan ---------------------
     */

    Sozlesme: 'Sözleşme',
    Servis: 'Servis',
    Tedarikci_Kisi: 'Tedarikçi Kişi',
    Tedarikci_Eposta: 'Tedarikçi E-posta',
    Tedarikci_Numarasi: 'Tedarikçi Numara',
    Sozlesme_Imzalama_Tarihi: 'Sözleşme İmzalama Tarihi',
    Aylik_Periyot: 'Aylık Periyot',
    Aylik_Masraflar: 'Aylık Masraflar',
    Toplam_Masraflar: 'Toplam Masraflar',
    Notlar: 'Notlar',
    Masraf_Ekle: 'Masraf Ekle',
    Masraf_Tipi: 'Masraf Tipi',
    Masraf_Kategorisi: 'Masraf Kategorisi',
    Sozlesme_Tipi: 'Sözleşme Tipi',
    Masraf_Detay: 'Masraf Detay',
    Bos_Alan: 'Boş Alan',
    Limit_Mesaji: 'Limiti Aştınız. Lütfen Girdiğiniz değerleri kontrol edin.',
    Uygulama_Ismi_Bulunumadi: 'Uygulama ismi bulunamadı.',
    Uygulama_Ekle: 'Uygulama Ekle',
    Donanim_Ekle: 'Donanım Ekle',
    Yeni_Masraf: 'Yeni Masraf',
    Uygulama_Ekle_Mesaji: 'En az bir uygulama eklemeniz gerekmektedir!',
    Donanim_Ekle_Mesaji: 'En az bir donanım eklemeniz gerekmektedir!',
    Sozlesme_Guncelleme_Mesaji: 'Sözleşmeyi güncellemek istediğinize emin misiniz?',
    Sozlesme_Silme_Mesaji: 'Sözleşmeyi silmek istediğinize emin misiniz?',
    Fark: 'Fark',
    Periyot: 'Periyot',
    Run_Costs: 'Uygulama Maliyeti',
    Expense_Type: 'Gider Türü',
    Fatura_Baslangic: 'Fatura Başlangıç',
    Fatura_Bitis: 'Fatura Bitiş',
  },
  Hardware: {

    Kabinet: 'Kabinet',
    Network_Bileşenleri: 'Network Bileşenleri',
    Diger_Guvenlik_Cihazlari: 'Diğer Güvenlik Cihazları',
    Ip_Cihazlari: 'IP Cihazları',
    Depolama_Cihazi: 'Depolama Cihazı',
    Diger_Guvenlik_Duvari: 'Diğer Güvenlik Duvarı',
    Simcard: 'Sim Kart',
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Device_Register_Info_Desc: 'Donanım envanter üzerinde gerekli alanları doldurarak kayıt edebilirsiniz',
    Barkod: 'Barkod',
    Teslim_Alan_Kisi: 'Teslim Alan Kişi',
    Teslim_Alma_Tarihi: 'Teslim Alma Tarihi',
    Simcard_Detaylari: 'Sim Kart Detayları',
    Hat_Turu: 'Hat Türü',
    Kullanan_Kisi: 'Kullanan Kişi',
    Ses: 'Ses',
    Data: 'Data',
    Sms: 'Sms',
    Pin_Kodu: 'Pin Kodu',
    Puk_Kodu: 'Puk Kodu',
    Kategori_Icerik_Detayi: 'Kategori İçerik Detayı',
    /**
     * ---------------------Yeni Alan ---------------------
     */

    Ram: 'RAM',
    Adi: 'Adı',
    Boyutu: 'Boyutu',
    Tipi: 'Tipi',
    Modeli: 'Modeli',
    Seri_No: 'Seri No',
    Ureticisi_: 'Üreticisi ',
    Hard_Diskler: 'Hard Diskler',
    Ismi: 'İsim',
    Model: 'Model',
    Uretici: 'Üretici',
    Kapasite: 'Kapasite',
    Okuma_Yazma_Hizi: 'Okuma Yazma Hızı (RPM)',
    Tur: 'Tür',
    Soket_Tipi: 'Soket Tipi',
    Ekran_Karti: 'Ekran Kartı',
    Marka: 'Marka',
    Cihaz_Detaylari: 'Cihaz Detayları',
    Servis_Etiketi: 'Servis Etiketi',
    Islemci_Modeli: 'İşlemci Modeli',
    Islemci_Bilgisi: 'İşlemci Bilgisi',
    Islemci_Cekirdek_Bilgisi: 'İşlemci Çekirdek Bilgisi',
    Islemci_Hizi: 'İşlemci Hızı',
    Cekirdek_Sayisi: 'Çekirdek Sayısı',
    Isletim_Sistemi: 'İşletim Sistemi',
    Isletim_Sistemi_Surumu: 'İşletim Sistemi Sürümü',
    Paket_Surumu: 'Paket Sürümü',
    Aksesuarlar: 'Aksesuarlar',
    Girdi_Cikti_Cihazlar: 'Girdi Çıktı Cihazlar',
    Klavye: 'Klavye',
    Klavye_Type: 'Klavye Türü',
    Klavye_Turu: 'Klavye Türü',
    Klavye_Uretici: 'Klavye Üretici',
    Mouse: 'Mouse',
    Mouse_Type: 'Mouse Type',
    Mouse_Turu: 'Mouse Türü',
    Mouse_Uretici: 'Mouse Üretici',
    Monitor: 'Monitör',
    Cozunurluk: 'Çözünürlük',
    Monitor_Turu: 'Monitör Türü',
    Isim: 'İsim',
    Urun: 'Ürün',
    Imei: 'IMEI',
    Seri_Numarasi: 'Seri Numarası',
    Mevcut_Kapasitesi: 'Mevcut Kapasitesi',
    Platform: 'Platform',
    Surumu: 'Sürümü',
    Gsm: 'GSM',
    Gsm_No: 'GSM No',
    Servis_Saglayici: 'Servis Sağlayıcı',
    Tarife_paket: 'Tarife/Paket',
    Disk_Boyutu: 'Disk Boyutu',
    Urun_Turu: 'Ürün Türü',
    Baglanti_Tipi: 'Bağlantı Tipi',
    Anten_Sayisi_Ve_Ozelligi: 'Anten Sayısı ve Özelliği',
    Port_Sayisi: 'Port Sayısı',
    Anten_Turu: 'Anten Türü',
    Kabinet_Boyutu: 'Kabinet Boyutu',
    Kabinet_Turu: 'Kabinet Türü',
    Urun_Boyutu: 'Ürün Boyutu',
    Urun_Uzunluk: 'Ürün Uzunluk',
    Uplink_Portu: 'Uplink Portu',
    Katman_Sayisi: 'Katman Sayısı',
    Poe_Ozelligi_Var: 'Poe Özelliği var',
    Genel_Detaylar: 'Genel Detaylar',
    Bilgisayar_Ismi: 'Bilgisayar İsmi',
    Sanal_Makine: 'Sanal Makine',
    Sanal_Makine_Platform: 'Sanal Makine Platform',
    Islemci_Markasi: 'İşlemci Markası',
    Islemci_Cekirdek_Sayisi: 'İşlemci Çekirdek Sayısı',
    Bellek: 'Bellek',
    Bellek_Adi: 'Bellek Adı',
    Bellek_Boyutu: 'Bellek Boyutu',
    Bellek_Tipi: 'Bellek Tipi',
    Bellek_Modeli: 'Bellek Modeli',
    Bellek_Seri_No: 'Bellek Seri No',
    Bellek_Ureticisi_: 'Bellek Üreticisi ',
    Hard_Disk_Ismi: 'Hard disk ismi',
    Network_Karti_Detaylari: 'Network kartı detayları',
    Ip_Adresi: 'IP Adresi',
    Mac_Adres: 'MAC Adres',
    Nic: 'NIC',
    Ag: 'Ağ',
    Varsayilan_Ag_Gecidi: 'Varsayılan Ağ Geçidi',
    Dhcp_Etkin: 'DHCP Etkin',
    Dhcp_Server: 'DHCP Server',
    Server_Ismi: 'Server İsmi',
    Sanal_Makine_Ekle: 'Sanal Makine Ekle',
    Sanal_Makine_Detaylari: 'Sanal Makine Detayları',
    Envanter_Etiketi: 'Envanter Etiketi',
    Lisans_Anahtari: 'Lisans Anahtarı',
    Tedarikci: 'Tedarikçi',
    Lokasyon: 'Lokasyon',
    Satin_Alinma_Tarihi: 'Satın Alınma Tarihi',
    Sona_Erme_Tarihi: 'Sona Erme Tarihi',
    Durum: 'Durum',
    Departman: 'Departman',
    Sorumlu_Kisi: 'Sorumlu Kişi',
    Satin_Alma_Maliyeti: 'Satın Alma Maliyeti',
    Para_Birimi: 'Para Birimi',
    Bilgisayar_Detaylari: 'Bilgisayar Detayları',
    Vm_Tipi: 'VM Tipi',
    Hizmet_Paketi: 'Hizmet Paketi',
    Sistem_Merkezi: 'Sistem Merkezi',
    Virtual_Center: 'Virtual Center',
    Sanal_Veri_Merkezi: 'Sanal Veri Merkezi',
    Vpn_Lisans_Sayisi: 'VPN Lisans Sayısı',
    Baglanti_Sekli: 'Bağlantı Şekli',
    Baski_Yonetimi: 'Baskı Yönetimi',
    Baski_Hizi: 'Baskı Hızı',
    Garanti_Suresi_Bitis_Tarihi: 'Garanti Süresi Bitiş Tarihi',
    Donanima_Bagli_Yazilimlar: 'Donanıma Bağlı Yazılımlar',
    Envanter_Ayrintilari: 'Envanter Ayrıntıları',
    Son_Kullanici: 'Son Kullanıcı',
    Guvenlik_Cihazi: 'Güvenlik Cihazı',
    Network_Cihazlari: 'Network Cihazları',
    Sayisini_Gecemez: ':name sayısını geçemez',
    Sayisindan_Az_Olamaz: ':name sayısından az olamaz',
    Girilmeli: 'girilmeli',
  },
  Reports: {
    Uygulama_Raporlari: 'Uygulama Raporları',
    Report: 'Rapor',
    Yazilim_Envanteri: 'Yazılım Envanteri',
    Yazilim: 'Yazılım',
    Total_Software: 'Toplam Yazılım',
    Total_Hardware: 'Toplam Donanım',
    Zimmet: 'Zimmet',
    Users: 'Kullanıcılar',
    Director: 'Müdürlük',

    /**
     *
     * ---------------------/// Yeni Alan ---------------------
     *
     *  */
    Application_Name: 'Uygulama İsmi',
    /*
     * ---------------------Yeni Alan ---------------------
     */
    Full_Period: 'Full Period',
    Publisher: 'Yayıncı',
    Aggregation_Name: 'Aggregation Name',
    /**
      * ---------------------Yeni Alan ---------------------
      */
  },
  ValidateMessage: {
    tum_alanlar_doldurun: 'Lütfen bütün alanları doldurunuz.',
    accepted: 'Lütfen kabul ediniz.',
    after: ':attribute :date alanından sonra gelmelidir.',
    after_or_equal: ':attribute :date alanından sonra veya aynı anda gelmelidir.',
    alpha: 'Sadece harf giriniz.',
    alpha_space: 'Sadece harf veya boşluk giriniz.',
    alpha_num: 'Sadece harf veya rakam giriniz.',
    alpha_num_space: ':attribute sadece harf, numara, boşluk içerebilir.',
    alpha_num_dash: ':attribute sadece harf, numara, tire içerebilir.',
    alpha_num_dash_space: ':attribute sadece harf, numara, tire, boşluk içerebilir.',
    array: ':attribute bir dizi olmalıdır.',
    before: ':attribute :date alanından önce gelmelidir.',
    before_or_equal: ':attribute :date alanından önce veya aynı anda gelmelidir.',
    date: 'Lütfen geçerli bir tarih giriniz.',
    date_equals: ':attribute :date ile beraber olmalıdır.',
    email: 'Lütfen geçerli bir e-mail adresi giriniz.',
    in: 'Seçilen :attribute :values olmalıdır.',
    integer: ':attribute tam sayı olmalıdır.',
    max: 'En fazla :max karakter girebilirsiniz.',
    min: 'Minimum :min karakter giriniz.',
    not_in: 'Seçili :attribute :values olmamalıdır.',
    not_regex: ':attribute gerekli modele uymamalıdır.',
    numeric: 'Bu alana sadece rakamlar girilebilir.',
    phone: 'Lütfen geçerli bir telefon numarası giriniz.',
    regex: ':attribute geçerli modele uymalıdır.',
    required: 'Bu alan gereklidir.',
    size: 'Bu alan :size karakter olmalıdır.',
    string: ':attribute bir dize olmalıdır.',
    typeof: ':attribute doğru :type değil.',
    url: ':attribute URL olmalıdır.',
  },
  License_Takibi: {
    // yeni alan
    Lisans_Takibi_Detay: 'Lisans Takibi Detay',
    Lisans_Takibi_Kayit: 'Lisans Takibi Kayıt',
    Lisans_Takibi: 'Lisans Takibi',
    Lisans_Sozlesmesi: 'Lisans Sözleşmesi',
    Lisans_Turu: 'Lisans Türü',
    Lisans_Adedi: 'Lisans Adedi',
    Kullanici_Adedi: 'Kullanıcı Adedi',
    Sozlesme_Adi: 'Lisans Adı',
    Lisans_Bitis_Tarihi: 'Lisans Bitiş Tarihi',
    Sicil_No: 'Sicil No',
    Lisans_Key: 'Lisans Key',
    Donanim_No: 'Donanım No',
    Description: 'Açıklama',
    Zimmetli_Kullanici: 'Zimmetli Kullanıcı',
    Lisans_Secimi: 'Lisans Seçimi',
    Kullanici_Durumu: 'Kullanıcı Durumu',
    Uygulama_Ismi: 'Uygulama İsmi',
    Lisans_Kullanicisi_Eklendi: 'Lisans Kullanicisi Eklendi',
    // ///yeni alan
  },
};

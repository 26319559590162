import React from 'react';

export default {
  Giris: {
    Giris_Yap: 'Log In',
    Hesap_Yarat: 'Create Account',
    Tebrikler: 'Congratulations',
    Devam: 'Continue',
    Parolami_Unuttum: 'Forgot Password',
    Tamamla: 'Complete',
    Sifirlama_Postasi_Gonder: 'Reset Password',
    Sirket_Aktivasyon: 'Company Activation',
    Aktivasyon_Kodunu_Giriniz: 'Please Enter Activation Code',
    Kodu_Tekrar_Gonder: 'Resend Activation Code',
    Hosgeldiniz_Mesaji: (
      <h5>Welcome! Please log in to continue. </h5>
    ),
    Hesap_Yok_Mesaji: (
      <>I do not have an account yet, sign up! </>
    ),
    Zaten_Hesap_Var_Mesaji: (
      <>I already have an account, sign in! </>
    ),
    Hesap_Yarat_Mesaji: (
      <h5>Welcome! Create your Loggle account in just 2 minutes.</h5>
    ),
    Hesap_Yaratildi_Mesaji: (
      <h5>Account created! An activation code was sent to your email. Please check your inbox to activate your account.</h5>
    ),
    Sifre_Sifirlama_Mesaji: (
      <h5>Please enter your email address to continue.</h5>
    ),
    Aktivasyon_Mesaji: (
      <h5>Please enter your activation code to continue.</h5>
    ),
    Hesap_Aktive_Edildi_Mesaji: (
      <h5>Account activation completed! A password was sent to your email. Please check your inbox to login your account.</h5>
    ),
    Sifre_Sifirlama_Tamamlandi_Mesaji: (
      <h5>Account password resetted! You can use your new password to login your account.</h5>
    ),
  },
  Bildirim: {
    Bildirim_Ekrani: 'Notification Screen',
    Yaklasanlar: 'Upcoming',
    Bugun: 'Today',
    Dun: 'Yesterday',
    Yorum_Yap: 'Add Comment',
    Goruntule: 'View',
    Tumunu_Temizle: 'Clear All',
    Yarın_için_hatırlatıcı_bulunmaktadır: 'You have a reminder for tomorrow ',
    bir_yorumda_sizden_bahsetti: ' mentioned you in a comment.',
    Sistem_Sorumlusu_olarak_atandınız: 'You have been assigned as a System Owner ',
    Teknik_Sorumlu_olarak_atandınız: 'You have been assigned as a Technical Owner ',
    İş_Sorumlusu_olarak_atandınız: 'You have been assigned as a Business Owner ',
    Bir_yeni_anketiniz_var: 'You have a new survey assigned',
    uygulamanın_sözleşme_bitiş_tarihi_yaklaşıyor: 'The contract of the application is about to expire',
  },
  Sifre_Sifirlama: {
    Sifre_Sifirlama: 'Reset Password',
    Sifirlama_Mesaji: (
      <h5>Welcome! Please enter your new password to continue.</h5>
    ),
  },
  Anasayfa: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Department_Budget_Graphic: 'Department Budget Graphic',
    /**
      * ---------------------Yeni Alan ---------------------
      */
    Yearly_Cost: 'Annual Cost',
    Costs_Per_It_Domain: 'Costs per IT Domain',
    Costs_Per_Business_Capability: 'Costs per Business Capability',
    Licence_sw_Maint: 'Licence/SW Maint',
    Sla_Contract_Fix_Per_Year: 'SLA Contract Fix per year',
    Variable_Costs_Per_Year: 'Variable Costs per year',
    Yearly_Cost_Application_Name_And_Cost_Type: 'Annual Cost Application Name and Cost Type',
    Location_And_Countries: 'Location and Countries',
    Kullanilan_Butce: 'Budget Used',
    Bilgi_Kutusu_Ekle: 'Add Info Box',
    Yatirim_Yap: 'Invest',
    Yorumlar: 'Comments',
    Sistem_Onerisi: 'System Tips',
    Maliyet_Grafigi: 'Cost Graph',
    Toplam_Maliyet: 'Total Cost',
    Dashboard_duzeni: 'Edit View',
    Sirket_Secimi: 'Select Company',
    Toplam_Uygulama: 'Total Applications',
    Silinen_Uygulama: 'Deleted Applications',
    Arşivlenen_Uygulama: 'Archived Applications',
    Aktif_Uygulama: 'Active Applications',
    TIME_Grafigi: 'TIME Graph',
    Sistem_Sorumlusu_Olduğum_Uygulama: 'Applications Where I\'m System Owner',
    Teknik_Sorumlusu_Olduğum_Uygulama: 'Applications Where I\'m Technical Owner',
    Süresi_Bitmek_Üzere_Olan_Lisans: 'About to Expire',
    Geçen_Ay_Oluşturulan_Uygulamalar: 'Applications Created Last Month',
    İş_Sorumlusu_Olduğum_Uygulama: 'Applications Where I\'m Business Owner',
    Istatistik_Artis: 'Growth',
    Her_Seyi_Arttir: 'Increase All',
    Lisans_Bitisi: 'Licence Expire',
    Lisans_Baslangici: 'Licence Start',
    Kisisel_Hatirlatici: 'Personal Reminder',
  },
  Envanter_Detay: {
    Application_Plan_Detail: 'Information regarding the technical planning of the application is shown below',
    Application_Plan: 'Application Plan',
    Application_Details_Detail: 'In this section, the details of the application subjected to Application Lifecycle can be examined',
    Percentage: 'Percentage',
    Costing_Detail_Detail: 'Monthly breakdown of the product can be viewed. Click to the expense item to see the Costing Details',
    Run_Cost_Per_Year: 'Run cost per year',
    Other_Costs: 'Other Costs',
    Other_Contracts: 'Other Contracts',
    Licenses: 'Licenses',
    Costing_Summary_Detail: 'Below, the financial breakdown according to the provided cost data is presented. Detailed information of the cost data can be viewed on Costing Screen.',
    Costing_Summary: 'Costing Summary',
    You_Can_Add_Your_Documents_And_Save_Your_Data: 'You can add your documents and save your data',
    Add_Document_And_Link: 'Add Document and Link',
    You_Can_Find_Info_About_The_People_In_Contact_With_Your_Application_Here: 'You can find info about the people relevant to your application here',
    Owner_Details: 'Owner Details',
    Info_Regarding_The_Technical_Aspect_Of_Your_Application_Are_Shown_Below: 'Info regarding the technical aspect of your application are shown below',
    Technical_Information: 'Technical Information',
    What_Is_That_Mean_User_Base: 'User Base is the total number of employees in the company',
    You_Can_Examine_All_The_Info_About_Your_Application_Below: 'You can examine all the info about your application below',
    Next_Year: 'Next Year',
    Current_Year: 'Current Year',
    Previous_Year: 'Previous Year',
    Application_Lifecycle: 'Application Lifecycle',
    Application: 'Application',
    Business_Criticality: 'Business Criticality',
    Cost_Fitness: 'Cost Fitness',
    Business_Fitness: 'Business Fitness',
    Technical_Fitness: 'Technical Fitness',
    Delete_Cost: 'Delete Cost',
    Envanter_Sayisi: 'Entries Found',
    Dosya_Linkleri: 'File Links',
    Yeni_Envanter: 'New Application',
    Yeni_Envanter_Girisi: 'Add New Entry',
    Envanter_Detay: 'Entry Details',
    Ilgili_Anketleri_Goruntule: 'See Relevant Surveys',
    Is_Degeri: 'Business Value',
    Teknik_Kalite_Degeri: 'Technical Quality',
    Goruntule: 'View',
    Yukle: 'Upload',
    Yorumlari_Goruntule: 'View Comments',
    Yasam_Dongusu: 'Lifecycle',
    Yeni_Lisans_Ekle: 'Add New License',
    Lisans_Islemleri: 'License Activities',
    Bt_Kalitesi: 'IT Quality',
    Sistem_Onerisi: 'System Suggestion',
    Yatirim_Yap: 'Invest',
    Yorumlar: 'Comments',
    Uygulama_Guncelleme_Sorusu: 'Are you sure you want to update this application',
    Uygulama_Silme_Sorusu: 'Are you sure you want to delete this application',
    Stop: 'Stop',
    Continue: 'Continue',
    Bu_uygulama_icin_belge_bulunamadı: 'No document found for this application!',
    // yeni alan
    Manifacturer: 'Manufacturer',
    Application_Save_Draft_Message: 'Application saved as a draft',
    // ///yeni alan

  },
  Takvim: {
    Yeni_Etkinlik: 'New Event',
    Hatirlatici_Kur: 'Set Reminder',
    Yeni_Form: 'Create New Form',
    Yeni_Lisans_Uygulama_Ekle: 'Add an application or license to this date',
    Etkinlik_Kaydedildi: 'Your event was successfully created',
    Hatirlatici_Olusturulamaz_Mesaji: 'Cannot set reminders for past dates',
    Hatirlatici_Olusturabilir_Mesaji: 'Set a reminder for this date',
    Listeler: 'Lists',
    Ay: 'Month',
    Yil: 'Year',
    Hafta: 'Week',
  },
  Yonetici: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Key_Contact_For_Company: 'Key contact for company',
    Yeni_Url_Ekle: 'Add New Url',
    Url_Guncelle: 'URL Update',
    Genel_Rapor_Mail_Kullanicilari: 'General Report Mail Users',
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Manage_Vendors: 'Manage Vendors',
    Yonetici: 'Manager',
    Zorluk: 'Difficulty',
    Renk: 'Color',
    Para_Birimi: 'Currency',
    Dil_Secimi: 'Language',
    Zaman_Asim_Suresi: 'Session Expired',
    Logo: 'Logo',
    Sirket_Islemleri: 'Manage Company Details',
    Genel_Ayarlar: 'General Settings',
    Sifre_Politikasi: 'Password Policy',
    Envanter_Verilerini_Indir: 'Download Inventory Data',
    Uygulama_Envanterinde_Degisiklik_Log_Indir: 'Download Inventory Change Logs',
    Yonetici_Ekrani: 'Admin Screen',
    Kullanici_Islemleri: 'Manage User Accounts',
    Yeni_Kullanici_Ekle: 'Add New User',
    Kullanici_Ekle: 'Add User',
    Etiket_Islemleri: 'Tagging',
    Yeni_Etiket_Ekle: 'Add New Tag',
    Yeni_Etiket: 'New Tag',
    Veri_Islemleri: 'Manage Data',
    Veri_Duzenle: 'Edit Data',
    Veriyi_Kaydet: 'Save Data',
    SirketSeciniz: 'Select Company',
    SirketEkle: 'Add Company',
    Konfigurasyonlar: 'Configurations',
    Anket_Islemleri: 'Manage Surveys',
    Tamamlandi: 'Completed',
    Anket_Olustur: 'Create Survey',
    Yeni_Anket_Olusturmak_Icin_Tiklayin: 'Click to Create New Survey',
    Tumu: 'All',
    Bitenler: 'Completed',
    Devam_Edenler: 'In Progress',
    Tum_Anketleri_Gor: 'View All Surveys',
    Kisi_Yonetimi: 'Manage Users',
    Sirket_Yonetimi: 'Manage Company',
    Tags: 'Tags',
    LicenseTypes: 'License Types',
    Locations: 'Locations',
    Departments: 'Departments',
    Sirket_Bilgileri: 'Company Info',
    Logo_Eklemek_İçin_Tıklayın: 'Click for Add Logo',
    Şirket_Sahibi_Bilgileri: 'Company Owner Info',
    Görevi: 'Title',
    Sahibi: 'Owner',
    Kullanıcı_Seç: 'Select User',
    Şirketine_Kullanıcı_Ekle: 'Add User to',
    Kullanıcı_Arayın: 'Search User',
    Ekleyebileceğiniz_Kullanıcı_Bulunmamaktadır: 'No available user found to add to this company',
    Şirket_Ekle: 'Add Company',
    Örnek_Şirket_Adı: 'Example Company Name',
    Şirket_Ön_Eki: 'Company Prefix',
    Örnek_Şirket_Ön_Eki: 'Example Company Prefix',
    Örnek_Kullanıcı_Ünvanı: 'Example User Title',
    Kullanıcı_Ünvanı: 'User Title',
    Sirket_Logo_Degistir: 'Change Company Logo',
    LegalOwners: 'Legal Owners',
    FunctionalAreas: 'Functional Areas',
    Categories: 'IT Domain',
    Processes: 'Business Capability',
    Platforms: 'Type of Hosting',
    Vendors: 'Manufacturer / Vendor',
    TcoAggregations: 'Run Cost Aggregations',
    Kullanici_Adi: 'Username',
    Tum_Kullanici_Gruplari: 'All User Groups',
    Manuel_Kullanici_Grubu_Ekle: 'Add User Group Manually',
    Kullanici_Grubu_Ara: 'Search User Group',
    Kullanici_Grubu_Sec: 'Select User Group',
    Kullanici_Silme_Mesaji: 'Are you sure want to delete this user?',
    Add_Vendor_User: 'Add Vendor User',
    Deger_bos_birakilamaz: 'Value field cannot be left blank',
    HostLocations: 'Location',
  },
  Profil: {
    Profil: 'Profile',
    Kullanici_Profili: 'User Profile',
    Kullanici_Düzenleme: 'Personal Edit',
    Kisisel_Bilgiler: 'Personal Information',
    Bilgileri_Guncelle: 'Update',
    Bilgileri_Düzenle: 'Edit',
    Sifre_Guncelle: 'Update Password',
    Sorumlu_Envanter: 'Surveys You Created / Surveys You Oversee',
    Profil_Foto_Degistir: 'Change Profile Photo',
    Son_Giris: 'Last Entry',
    Tarih_Hatirlatici_Kur: 'Set a reminder for this date',
    Fotograf_Onizleme: 'Preview Picture',
    Duzenle: 'Edit',
  },
  Envanter_Lisans: {
    Lisans_Ekleme: 'Add License',
    Lisans_Planlamasi: 'Licensing Plan',
    Lisans_Ekleme_Bilgisi: 'On this screen you can add licencing details for your applications. For budget management and planning please go to the "Licence Planning" page',
    Lisans_Planlama_Bilgisi: 'Manage your action plan and budgeting for your licences here',
  },
  Inputs: {
    Sifre: 'Password',
    Action_App_Notes: 'Action App Notes',
    Plan_Data: 'Plan Data',
    Plan_App: 'Plan App',
    Succesor_Application: 'Succesor Application',
    Predecessor_Application: 'Predecessor Application',
    Business_Domain: 'Business Domain',
    User_Base: 'User Base',
    Contract_Url: 'Contract URL',
    Add_Url: 'Add URL',
    Url_Control: 'Must be a URL',
    Dosya_Yukle: 'Upload file',
    Lutfen_Bir_Dosya_Seciniz: 'Please select file',
    Sifre_giriniz: 'Enter Password',
    Sifrenizi_Tekrar_Giriniz: 'Re-enter Password',
    Indir: 'Download',
    Tarih: 'Date',
    Etkinlik_Adi: 'Event Name',
    Veri_Ara: 'Search Data',
    Veri_Tipi: 'Data Type',
    Aciklama: 'Details',
    Tanim: 'Description',
    Ad_Soyad: 'Name & Surname',
    Adi: 'Name',
    Soyadi: 'Surname',
    Yetki: 'Authorization',
    Eposta_Adresi: 'E-mail Address',
    Sifre_Degisikligi: 'Password Reset',
    Yeni_Sifre: 'New Password',
    Yeni_Sifre_Tekrar: 'Re-enter New Password',
    Uygulama_Adı: 'Application Name',
    Uygulama_Ara: 'Search App',
    Versiyon: 'Version',
    Etiket: 'Tag',
    Departman: 'Department',
    Surec: 'Process',
    Konum: 'Location',
    Lokasyon: 'Location',
    Platform: 'Platform',
    Uygulama_Aciklamasi: 'Application Details',
    Is_Birimi_Sorumlulari: 'Business Owners',
    Sistem_Sorumlulari: 'System Owners',
    Yasal_Sorumlular: 'Legal Owners',
    Butce_Sorumlulari: 'Budget Owners',
    Lisans_Girisi: 'License Entry',
    Numarali_Lisans: 'Numbered License',
    Dokuman: 'Documents',
    Yorum_Ekle: 'Add Comment',
    Yeni_Yorum_Ekle: 'Add New Commment',
    Platform_Etiket_Ekle: 'Add Platform & Tag',
    Ebeveyn_Uygulamasi: 'Parent Application',
    mail_giriniz: 'E-mail Address',
    Adiniz_Soyadiniz: 'Name & Surname',
    Tel_No: 'Telephone Number',
    Sirket_Adi: 'Company Name',
    Sirket_Prefix: 'Company Prefix',
    Yaratan: 'Creator',
    User_Title: 'Title',
    Yeni_Sifreniz: 'New Password',
    Yeni_Sifrenizi_Tekrar: 'Re-enter New Password',
    Parolaniz_Uyusmuyor: 'Passwords do not match. Please try again',
    Sozlesme_Baslangic_Bitis: 'License Beginning & End Date',
    Lisans_Aciklamasi: 'License Details',
    Yil_Secimi: 'Selected Year',
    Lisans_Plani: 'License Plan',
    Aksiyon_Plani: 'Action Plan',
    Veri_Plani: 'Data Plan',
    Butce_Alani: 'Budget Area',
    Bakim_Butcesi: 'Maintenance Budget',
    SLA_Butcesi: 'SLA Budget',
    Degisken_Maliyet_Butcesi: 'Variable Budget',
    Kaydet: 'Save',
    Butce: 'Budget',
    Sozlesme_Baslangic: 'Beginning of Contract',
    Sozlesme_Bitis: 'End of Contract',
    AdSoyad_Eksik: 'Please check name and surname',
    Daha_Fazla_Goster: 'Show More',
    Takip_Et: 'Follow',
    Takipten_Cik: 'Unfollow',
    Cikti_Al: 'Print',
    Kopyala: 'Copy',
    Arsiv: 'Archive',
    Duzenle: 'Edit',
    Tedarikci: 'Provider',
    Teknik_Sorumlular: 'IT Responsible',
    Lisansli_Kullanici_Sayisi: 'Number of Licensed Users',
    Aktif_Kullanici_Sayisi: 'Number of Active Users',
    Yillik_Total_Maliyet: 'Annual Total Cost',
    Eski_Kullanilan: 'Previously Used',
    Yerine_Gecen_Uygulama: 'Successor Application',
    Butce_Sorumlusu_Departman: 'Budget Owner Department',
    Firma_Ilgili_Kisi: 'Company Contact',
    Kullanici: 'User',
    Masraf_Yeri: 'Cost Location',
    Kar_Merkezi: 'Profit Center',
    Sozlesmenin_Olusturuldugu_Tarih: 'Contract Date',
    Sozlesme_Donemi_Yili: 'Contract Year',
    Dagitim_Yapilacak_Cari_Ay: 'Accounting Month',
    Yillik_Lisans_Bedeli: 'Annual Licensing Cost',
    Kdv_Orani_Tutari: 'VAT Rate & Amount',
    Damga_Vergisi: 'Stamp Duty',
    Tco: 'TCO',
    Para_Birimi: 'Currency',
    Kur: 'Rate',
    Evet: 'Yes',
    Hayir: 'No',
    Anketler: 'Surveys',
    Uygulama_ID: 'Application ID',
    Kapsam: 'Scope',
    Fonksiyonel_Alan: 'Functional Area',
    Kategori: 'Category',
    Is_Sureci: 'Business Process',
    Saglayici_Platformu: 'Type of Hosting',
    Saglayici_Sehri: 'Hosting City',
    Saglayici_Ulkesi: 'Hosting Country',
    Yonetim: 'Managed By',
    IP_adress: 'The :name must be a valid IP address',
    Uygulama_ara_ve_ekle: 'Search and add application',
    Donanim_ara_ve_ekle: 'Search and add hardware',
    Aktif: 'Active',
    Pasif: 'Inactive',
    Dur: 'Stop',
    Stopped: 'Stopped',
    User_Base_Info: <>The total number of employees who have access to the application is referred to as the User Base.</>,
    Scope_Info: <>The scope of the application is concerned with the application&apos;s region of activity.</>,
    Tag_Info: <>By grouping and labelling applications, you can make it easier to find them later on.</>,
    IT_Domain_Info: <>The IT Domain refers to the application&apos;s primary area of focus.</>,
    Business_Capability: 'Business Capability',
  },
  Tablo_Kolonlar: {
    Durum: 'Status',
    Id: 'ID',
    Aciklama: 'Explanation',
    Yaratilma_Tarihi: 'Date Created',
    Uygulama_Adi: 'Application Name',
    Donanim_Adi: 'Hardware Name',
    Adi: 'Name',
    Soyad: 'Surname',
    Telefon: 'Telephone',
    Unvan: 'Title',
    Email: 'E-mail',
  },
  Aylar: {
    Ocak: 'January',
    Şubat: 'February',
    Mart: 'March',
    Nisan: 'April',
    Mayıs: 'May',
    Haziran: 'June',
    Temmuz: 'July',
    Agustos: 'August',
    Eylül: 'September',
    Ekim: 'October',
    Kasım: 'November',
    Aralık: 'December',
    Ock: 'Jan',
    Şub: 'Feb',
    Mrt: 'Mrt',
    Nis: 'Apr',
    May: 'May',
    Haz: 'Jun',
    Tem: 'Jul',
    Agu: 'Agu',
    Eyl: 'Sep',
    Eki: 'Oct',
    Kas: 'Nov',
    Ara: 'Dec',
  },
  Gunler: {
    Pazartesi: 'Monday',
    Salı: 'Tuesday ',
    Çarşamba: 'Wednesday',
    Perşembe: 'Thursday',
    Cuma: 'Friday',
    Cumartesi: 'Saturday',
    Pazar: 'Sunday',
    Pzts: 'Mon',
    Sal: 'Tue',
    Çar: 'Wed',
    Per: 'Thu',
    Cum: 'Fri',
    Cmts: 'Sat',
    Pzr: 'Sun',
  },
  Ortak: {
    Silme_Mesaji: 'Are you sure you want to delete?',
    Duzenleme_Kapat: 'Close Edit',
    Kayit_Ekle: 'Add Save',
    Seciniz: 'Choose',
    Detay: 'Detail',
    Add: 'Add',
    Type: 'Type',
    Kullanici: 'User',
    You_Can_Upload_File_From_Here: 'You can upload file here',
    File_Type_Detail: 'Your file type should be jpeg, png, svg, pdf',
    Deleting: 'Deleting',
    Excel_Error_List: 'Excel Error List',
    Integration_Credentials: 'Integration Credentials',
    Sirket_Suresi_Doldu: 'Company session expired',
    Toplam: 'Total',
    Yeni: 'New',
    Save_View: 'Save & View',
    Save_Close: 'Save & Close',
    Ana_Sayfa: 'Home Page',
    Sayfa_Bulunamadı: 'Page Not Found',
    Tamam: 'Ok',
    Temizle: 'Clear',
    Uygula: 'Apply',
    Sifirla: 'Reset',
    Sil: 'Delete',
    Geri: 'Back',
    Genel_Bakis: 'Overview',
    Envanter: 'Application Inventory',
    Raporlar: 'Reports',
    Takvim: 'Calendar',
    Masraf: 'Costing',
    Bildirimler: 'Notifications',
    Anketler: 'Surveys',
    Ayarlar: 'Settings',
    Cari_Ay: 'Current Month',
    Cari_Hafta: 'Current Week',
    Gecen_Ay: 'Last Month',
    Son_Uc_Ayın_Ort: 'Average for Last 3 Months',
    Ek_Maliyet_Dokumu: 'Additional Cost Breakdown',
    Filtre: 'Filter',
    Filtrele: 'Filter',
    Sutun_Filtrele: 'Filter Column',
    Sutun_Gizle_Goster: 'Hide/Show Column',
    Tumunu_Sil: 'Clear All',
    Iptal_Et: 'Cancel',
    Bu_Satiri_Kaydet: 'Save This Row',
    Bu_Satiri_Duzenle: 'Edit This Row',
    Yeni_Satir_Ekle: 'Add New Row',
    Veri_Yok: 'No Data',
    Yeni_Oge_Ekle: 'Add New Item',
    Deger: 'Value',
    Yonet: 'Manage',
    Gonder: 'Send',
    Yorumlar: 'Comment',
    Yorum_Yap: 'Add Comment',
    Yaratici: 'Creator',
    Sistem_Sorumlusu: 'System Owner',
    Olusturan: 'Creator',
    Teknik_Sorumlu: 'Technical Owner',
    Daralt: 'Collapse',
    Anket_Ara: 'Search Survey',
    Basla: 'Start',
    Devam_Et: 'Continue',
    Gor: 'See',
    Daha_Fazla_Gor: 'See More',
    Profil_Duzenle: 'Edit Profile',
    Sirket_Degistir: 'Change Company',
    Eski_Sifre: 'Old Password',
    Sifre_Degistir: 'Change Password',
    Hakkinda: 'About',
    Yardim: 'Help',
    Merhaba: 'Hello',
    Uygulama_Lisans_Ara: 'Search Application License',
    Ice_Aktar: 'Import',
    Disa_Aktar: 'Export',
    Ornek_Indir: 'Download Sample',
    Yatirim: 'Invest',
    Kaldir: 'Eliminate',
    Birlestir: 'Migrate',
    Tolere_Et: 'Tolerate',
    Dil_Degistir: 'Change Language',
    Tema_Degistir: 'Change Theme',
    Vazgec: 'Cancel',
    Dil: 'Language',
    Arama_Yap: 'Search',
    Sonraki: 'Next',
    Önceki: 'Prev',
    Görüntülemek_için_tıklayınız: 'Click for details',
    Bağlantı_Zaman_Aşımına_Uğradı: 'Connection Timed Out ',
    Yüklediğiniz_dosyada_hata_var_indirilen_dosyayı_kontrol_ediniz: 'There is an error in the file you uploaded. Check the downloaded file.',
    Sunucu_ile_bağlantı_kurulamadı: 'Connection with the server could not be established!',
    Dosya_Indirilemedi: 'There is no file',
    Gösterilecek_Veri_Bulunmamaktadır: 'No Data To Show',
    Duzenlemeyi_Tamamla: 'Complete Editing',
    Arsivle: 'Archive',
    Arsivlendi: 'Archived',
    Arsivden_Kurtar: 'Unarchive',
    Arsivleme_Nedeni: 'Archive Reason',
    Arsivleme_nedeninizi_giriniz: 'Please enter your archive reason',
    Uygulamayi_Arsivleyin: 'Archive Application',
    Uygulama_Arsivlendi: 'Application Archived',
    Uygulamalar: 'Applications',
    Lisanslar: 'Licences',
    Loglar: 'Logs',
    Baslangic_Tarihi: 'Start Date',
    Bitis_Tarihi: 'End Date',
    Diger_Secenegi_Ekle: <>Add &quot;Other&quot;</>,
    Anket_Uyari_Metni: <>This survey was sent to determine the level of use of the relevant inventory. Questions with (*) at the end are required to be answered, others are optional. </>,
    Sayfa_Bulunamadi_Mesaji: <>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN&apos;T BE FOUND</>,
    Hizmet_Mesaji: <>We provide a professional service for private and commercial customers.</>,
    Ana_Sayfaya_Don: 'Back to Home',
    simdi: 'now',
    dakikaKisa: 'min',
    saatKisa: 'h',
    gunKisa: 'd',
    haftaKisa: 'wk',
    ayKisa: 'mo',
    Dosya_Ekle: 'Add File',
    Tumunu_Gor: 'See All',
    Tarihce: 'History',
    Dokuman_Ekle: 'Add Document',
    Tahmini: 'Forecast',
    Abonelik: 'Subscription',
    Secilmedi_Mesaji: 'Not Selected',
    İptal: 'Cancel',
    Guncelle: 'Update',
    Taslak: 'Draft',
    Lutfen_Zorunlu_Alanlari_Doldurun: 'Please fill in the mandatory fields',
    Alan_Kodu: 'Area Code',
    Premium_Mesaji: 'This segment can only be displayed on a Premium Account.',
    LDAP_Kullanici_Ekleme: 'Add LDAP User',
    Tamamlananlar: 'Completed',
    Taslaklar: 'Drafts',
    Rows_Per_Page: 'Rows Per Page',
    Full_Period: 'Full Period',
    URL_Added: 'URL Added',
    File_Added: 'File Added',
    Delete_Application: 'Delete Application',
    You_can_add_max_3_files: 'You can add max 3 files',
    URL_Field_Req_Message: 'App Url fields is required, min 3 character',
    App_Url: 'App URL',
    Contract_URL_Field_Req_Message: 'Contract Url field is required, min 3 character',
    Information: 'Information',
    Select_Date_To_Continue_Message: 'Please select a date to continue.',
    Delete_License: 'Delete license',

  },
  Kenar_Menu: {
    Cikis_Yap: 'Sign Out',
    Koyu_Moda_Gec: 'Switch to Dark Mode',
    Secilen_Dil: 'Selected Language',
  },
  Anketler: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Yanıtlanacak_Anket: 'Survey To Be Answered',
    Yanıtlanan_Anket: 'Completed Survey',
    Yanıtlanmayan_Anket: 'Unanswered Survey',
    Yarım_Kalan_Anketler: 'Unfinished Surveys',
    Soru_Sayısı: 'Question Count',
    /**
     * ---------------------///Yeni Alan ---------------------
     */
    Detayli_Gor: 'More details',
    Detayi_Gizle: 'Hide details',
    Soru_Detayi: 'Question Details',
    En_Cok_Verim_Alinan_Uygulama_Sorusu: 'What is the most useful and efficient application?',
    Taslak_Olarak_Kaydet: 'Save Draft',
    Geri_Don: 'Go Back',
    Gerekli: 'Required',
    Yanitlanmasi_Gereken_Anketler: 'Surveys Required to be Completed',
    Uygulama_Secin: 'Select Application',
    Anket_Sonlandirilmistir: 'Survey Closed',
    Anketi_Gor: 'Show Survey',
    Anketi_Bitir: 'Finish Survey',
    En_az_bir_soru_eklemelisiniz: 'You have to add at least one question',
    Soru_alanlarini_bos_birakamazsiniz: 'You have to fill in the question fields',
    Coktan_secmeli_sorularda_en_az_bir_secenek_eklemelisiniz: 'For multiple choice questions, you must add at least one option',
    Bolumlere_en_az_bir_soru_eklemelisiniz: 'You have to add at least one question to sections',
    Kisa_Yanit_Metni: 'Short Reply Text',
    Uzun_Yanit_Metni: 'Long Reply Text',
    Secenek_Ekle: 'Add Option',
    Soru_Ekle: 'Add Question',
    Anketi_Gozden_Gecir: 'Review Survey',
    Veya: 'or',
    Secenegi_Ekle: 'Add Option',
    Etiket_Istege_Bagli: 'Tag (Optional)',
    Soru: 'Question',
    Tip: 'Type',
    Diger: 'Other',
    Secenek: 'Option',
    Ekle: 'Add',
    Ek_Bilgi: 'Additional Information',
    Temel_Ayrıntılar: 'Basic Details',
    İçerik_Oluştur: 'Create Content',
    İnceleyin_ve_Yayınlayın: 'Review and Publish',
    Başlık: 'Heading',
    Detay: 'Detail',
    Bölüm: 'Section',
    Bölüm_Ekle: 'Add Section',
    Bölümü_Sil: 'Delete Section',
    Anketin_gönderileceği_uygulamalar: 'Asign a survey to the application',
    Tüm_Uygulamalar: 'All application',
    Manuel_Uygulama_Ekle: 'Add App Manually',
    Anketin_gönderileceği_kullanıcı_grupları: 'User groups to be assigned',
    Tüm_Kullanıcılar: 'All Users',
    Manuel_Kişi_Ekle: 'Add User Manually',
    Kişi_Seç: 'Select User',
    Uygulama_Seç: 'Select App',
    TOPLAM_KATILIMCI: 'Total Participants',
    TOPLAM_YANITLAYAN: 'Who Responded',
    TOPLAM_YANITLAMAYANLAR: 'Who Didn’t Respond',
    YARIM_KALAN_ANKETLER: 'Uncompleted Survey',
    Suna_Gore_Sirala: 'Sort by',
    Yanıtlandı: 'Answered',
    Yarım_Kaldı: 'Unfinished',
    Yanıtlamadı: 'Not Answered',
    bölüm_sil_sorusu: 'Section with questions about to be deleted. Are you sure?',
    Henuz_Bir_Cevap_Bulunmamaktadir: 'No Answers Yet',
  },
  Entegrasyon: {
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Receiver: 'Receiver',
    Sender: 'Sender',
    Mutual: 'Mutual',
    Select: 'Select',
    Sektor: 'Sector',
    Url_Adi: 'Url Name',
    Entegrasyon_Islemleri: 'Integration Procedures',
    Url_Statusunu_Degistirme: 'Changing URL Status',
    Gonderen_Adi: 'Sender Name',
    Alici_Adi: 'Receiver Name',
    Aktif_Pasif: 'Active/Passive',
    Sap_Guncelle: 'SAP Update',
    URL_Statu_Degistirme_Sorusu: 'Are you sure you want to change the URL status?',
    Responsible_Report: 'Responsible Report',
    Sender_Namespace: 'Sender Interface',
    Receiver_Namespace: 'Receiver Interface',
    Responsible_Bring_Empty: 'The responsible area bring the empty one',
    Error_Detail_Information: 'Error Detail Information',
    Monitoring_Tipi: 'Monitoring Type',
    Mail_Tipi: 'Mail Type',
    Hata_Nedeni: 'Cause of Error',
    Hata_Durumu: 'Error Status',
    Cozum_Onerisi: 'Solution Suggestion',
    Secilenleri_Mail_Gonder: 'Send selected log mail',
    /**
     * ---------------------///Yeni Alan ---------------------
     */
    Component: 'Component',
    Party: 'Party',
    Adapter_Type: 'Adapter Type',
    Count: 'Count',
    Sender_Party: 'Sender Party',
    Interface_Name: 'Interface Name',
    Interface_Namespace: 'Interface Namespace',
    Receiver_Party: 'Receiver Party',
    Message_Details: 'Message Details',
    Message_Id: 'Message ID',
    End_Time: 'End Time',
    Entegrasyon: 'Integration',
    Entegrasyon_Haritasi: 'Integration Map',
    Grafik: 'Graphic',
    Liste: 'List',
    Yeni_Entegrasyon: 'New Integration',
    Excel_İçe_Aktar: 'Excel Import',
    Excel_Dışa_Aktar: 'Excel Export',
    Dosya_Başarılı_Bir_Şekilde_İndirildi: 'File is Downloaded Succesfully',
    Gonderen_Bileseni: 'Sender Component',
    Gonderen_Is_Ortagi: 'Sender Partner',
    Gonderen_Arayuzu: 'Sender Interface',
    Alici_Bileseni: 'Receiver Component',
    Alici_Is_Ortagi: 'Receiver Partner',
    Alici_Arayuzu: 'Receiver Interface',
    Complexity: 'Complexity',
    Criticality: 'Criticality',
    Responsible_email: 'Responsible E-mail',
    Developer: 'Developer',
    Alert_email: 'Alert E-Mail',
    Channel_Monitoring: 'Channel Monitoring',
    Message_Monitoring: 'Message Monitoring',
  },
  Kullanıcı_Ayarları: {
    Kullanici_Ayarlari: 'Profil Settings',
    Profil: 'Profile',
    Tema_Degistir: 'Change Theme',
    Dil_Degistir: 'Change Language',
  },
  Donanim: {
    Ulke: 'Country',
    Yeni_Donanim: 'New Hardware',
    Donanim: 'Hardware',
    Donanim_Detay: 'Hardware Detail',
    Donanim_Envanteri: 'Hardware Inventory',
    Donanim_Tipi: 'Hardware Type',
    Donanim_Kategorisi: 'Hardware Category',
    Marka: 'Brand',
    Model: 'Model',
    Satin_Alma_Tarihi: 'Purchase Date',
    Fiyat: 'Price',
    Statu: 'Status',
    Sahip: 'Owner',
    Donanim_Modal_Bilgilendirme_Mesaji: 'Please select which storage area you want to proceed with',
    Yazici: 'Printer',
    Erisim_Noktasi: 'Access Point',
    Tarayici: 'Scanner',
    Guvenlik_Duvari: 'Firewall',
    Depolama: 'Storage',
    Sunucu: 'Server',
    Sanal_Makine: 'Virtual Machine',
    Son_Kullanici: 'End User',
    Network_Guvenlik_CevreBirimleri: 'Network, Security and Peripherals',
    Sunucu_Ve_VeriDepolama: 'Server and Data Storage',
    Tablet: 'Tablet',
    Notebook: 'Notebook',
    Bilgisayar: 'Computer',
    El_Terminali: 'Handheld Devices',
    Yonlendirici: 'Router',
    Switch: 'Switch / Router',
    Donanim_Turu: 'Hardware Type',
  },
  Masraf: {
    Cost: 'Cost',
    Add_Costing_Detail: <>This screen allows you to add financial information to your application. Budget management and planning can be done from &apos;Cost Planning&apos; section</>,
    Add_HW_Costing_Detail: <>This screen allows you to add financial information to your hardware. Budget management and planning can be done from &apos;Cost Planning&apos; section</>,
    Costing_Detail: 'Costing Details',
    Date_Information: 'Date  Information',
    Date_Information_Detail: 'Please enter the dates of your contract for your application in the fields below.',
    Date_Information_Detail_HW: 'Please enter the dates of your contract for your hardware in the fields below.',
    Begining_Of_Depreciation: 'Begining of Depreciation',
    Depreciation_Period: 'Depreciation Period',
    Calculated_End_Date: 'Calculated End Date',
    Cost_Information: 'Cost Information',
    Cost_Information_Detail: 'Add financial details of your application below',
    Cost_Information_Detail_HW: 'Add financial details of your hardware below',
    Invoice_Currency: 'Invoice Currency',
    Fx_Rate: 'FX Rate',
    Total_Amount_In: 'Total Amount in',
    Monthly_Depreciation_In: 'Monthly Depreciation in',
    Run_Cost_Aggrigation: 'Run Cost Aggrigation',
    Split_Existing_Contract: 'Split Existing Contract',
    Allocation_Of_Cost_To_Applications: 'Allocation of Cost to Applications',
    Allocation_Of_Cost_To_Applications_Detail: 'You have the option of allocating the expenditures to one or more applications.',
    Allocation_Of_Cost_To_Hardwares: 'Allocation of Cost to Hardwares',
    Allocation_Of_Cost_To_Hardwares_Detail: 'You have the option of allocating the expenditures to one or more hardwares.',
    Allocation: 'Allocation',
    Allocation_In: 'Allocation in',
    Amount: 'Amount',
    Total_Allocated: 'Total Allocated',
    Absolute_Value: 'Absolute Value',
    Application_Costing: 'Application Costing',
    Vendor: 'Vendor',
    Variance_Control_Message: 'Total amount not correct, check variance!',
    /**
     * ---------------------Yeni Alan ---------------------
     */
    Uygulama_İsmi_Giriniz: 'Enter the application name:',
    Monthly_Cost_In: 'Monthly Cost in',
    /**
     * ---------------------///Yeni Alan ---------------------
     */

    Sozlesme: 'Agreement',
    Servis: 'Service',
    Tedarikci_Kisi: 'Vendor contact person',
    Tedarikci_Eposta: 'Vendor contact e-mail',
    Tedarikci_Numarasi: 'Vendor contact phone',
    Sozlesme_Imzalama_Tarihi: 'Signing of the Contract Date',
    Aylik_Periyot: 'Period in months',
    Aylik_Masraflar: 'Monthly Costs',
    Toplam_Masraflar: 'Total Costs',
    Notlar: 'Notes',
    Masraf_Ekle: 'Add Costing',
    Masraf_Tipi: 'Cost Type',
    Masraf_Kategorisi: 'Cost Category',
    Sozlesme_Tipi: 'Contract Type',
    Masraf_Detay: 'Cost Detail',
    Bos_Alan: 'Empty Space',
    Limit_Mesaji: 'You have exceeded the limit, please check the values.',
    Uygulama_Ismi_Bulunumadi: 'Application name cannot be found',
    Uygulama_Ekle: 'Add Application',
    Donanim_Ekle: 'Add Hardware',
    Yeni_Masraf: 'New Cost',
    Uygulama_Ekle_Mesaji: 'You have to add at least one application!',
    Donanim_Ekle_Mesaji: 'You have to add at least one hardware!',
    Sozlesme_Guncelleme_Mesaji: 'Are you sure you want to update contract?',
    Sozlesme_Silme_Mesaji: 'Are you sure you want to delete this contract?',
    Fark: 'Variance',
    Periyot: 'Period',
    Run_Costs: 'Run Costs',
    Expense_Type: 'Expense Type',
    Fatura_Baslangic: 'Invoice Start',
    Fatura_Bitis: 'Invoice End',
  },
  Hardware: {

    Kabinet: 'Cabinet',
    Network_Bileşenleri: 'Network Components',
    Diger_Guvenlik_Cihazlari: 'Other Security Devices',
    Ip_Cihazlari: 'IP Devices',
    Depolama_Cihazi: 'Storage Devices',
    Diger_Guvenlik_Duvari: 'Other Firewall',
    Simcard: 'Simcard',

    /**
     * ---------------------Yeni Alan ---------------------
     */
    Device_Register_Info_Desc: 'You can register by filling the necessary fields on the hardware inventory',
    Barkod: 'Barcode',
    Teslim_Alan_Kisi: 'Delivery Person',
    Teslim_Alma_Tarihi: 'Delivery Date',
    Simcard_Detaylari: 'SIM Card Details',
    Hat_Turu: 'Hat Type',
    Kullanan_Kisi: 'Used Person',
    Ses: 'Sound',
    Data: 'Data',
    Sms: 'Sms',
    Pin_Kodu: 'Pin Code',
    Puk_Kodu: 'Puk Code',
    Kategori_Icerik_Detayi: 'Category Content Detail',

    /**
     * ---------------------///Yeni Alan ---------------------
     */
    Ram: 'RAM',
    Adi: 'Name',
    Boyutu: 'Size',
    Tipi: 'Type',
    Modeli: 'Model',
    Seri_No: 'Serial Number',
    Ureticisi_: 'Provider',
    Hard_Diskler: 'Hard Disks',
    Ismi: 'Name',
    Model: 'Model',
    Uretici: 'Manufacturer',
    Kapasite: 'Capacity',
    Okuma_Yazma_Hizi: 'Revolutions per Minute (RPM)',
    Tur: 'Type',
    Soket_Tipi: 'Socket Type',
    Ekran_Karti: 'Display Card',
    Marka: 'Brand',
    Cihaz_Detaylari: 'Device Details',
    Servis_Etiketi: 'Service Tag',
    Islemci_Modeli: 'Processor Model',
    Islemci_Bilgisi: 'Processor Information',
    Islemci_Cekirdek_Bilgisi: 'Processor Core Information',
    Islemci_Hizi: 'Processor Speed',
    Cekirdek_Sayisi: 'Number of Cores',
    Isletim_Sistemi: 'OS',
    Isletim_Sistemi_Surumu: 'Operating system version',
    Paket_Surumu: 'Package version',
    Aksesuarlar: 'Server Accessories',
    Girdi_Cikti_Cihazlar: 'Input-Output Devices',
    Klavye: 'Keyboard',
    Klavye_Type: 'Keyboard Type',
    Klavye_Turu: 'Keyboard type',
    Klavye_Uretici: 'Keyboard manufacturer',
    Mouse: 'Mouse',
    Mouse_Type: 'Mouse Type',
    Mouse_Turu: 'Mouse type',
    Mouse_Uretici: 'Mouse manufacturer',
    Monitor: 'Monitor',
    Cozunurluk: 'Resolution',
    Monitor_Turu: 'Monitor type',
    Isim: 'Name',
    Urun: 'Product',
    Imei: 'IMEI',
    Seri_Numarasi: 'Serial number',
    Mevcut_Kapasitesi: 'Current capacity',
    Platform: 'platform',
    Surumu: 'Version',
    Gsm: 'GSM',
    Gsm_No: 'GSM No',
    Servis_Saglayici: 'Service provider',
    Tarife_paket: 'Tariff / Package',
    Disk_Boyutu: 'Disk size',
    Urun_Turu: 'Product Type',
    Baglanti_Tipi: 'Connection Type',
    Anten_Sayisi_Ve_Ozelligi: 'Number and Features of Antennas',
    Port_Sayisi: 'Number of Ports',
    Anten_Turu: 'Antenna Type',
    Kabinet_Boyutu: 'Cabinet size',
    Kabinet_Turu: 'Cabinet Type',
    Urun_Boyutu: 'Product Size',
    Urun_Uzunluk: 'Product Length',
    Uplink_Portu: 'Uplink Port',
    Katman_Sayisi: 'Number of Layers',
    Poe_Ozelligi_Var: 'PoE',
    Genel_Detaylar: 'General Details',
    Bilgisayar_Ismi: 'Computer Name',
    Sanal_Makine: 'Virtual Machine',
    Sanal_Makine_Platform: 'Virtual Machine Platform',
    Islemci_Markasi: 'Processor Trademark',
    Islemci_Cekirdek_Sayisi: 'Number of Processor Cores',
    Bellek: 'Memory',
    Bellek_Adi: 'Memory Name',
    Bellek_Boyutu: 'Memory size',
    Bellek_Tipi: 'Memory Type',
    Bellek_Modeli: 'Memory Model',
    Bellek_Seri_No: 'Memory Serial No.',
    Bellek_Ureticisi_: 'Memory Manufacturer',
    Hard_Disk_Ismi: 'Hard Disk Name',
    Network_Karti_Detaylari: 'Network Card Details',
    Ip_Adresi: 'IP Address',
    Mac_Adres: 'MAC address',
    Nic: 'NIC',
    Ag: 'Network',
    Varsayilan_Ag_Gecidi: 'Default Gateway',
    Dhcp_Etkin: 'DHCP enabled',
    Dhcp_Server: 'DHCP Server',
    Server_Ismi: 'Server Name',
    Sanal_Makine_Ekle: 'Add Virtual Machine',
    Sanal_Makine_Detaylari: 'Virtual Machine Details',
    Envanter_Etiketi: 'Inventory tag',
    Lisans_Anahtari: 'License Key',
    Tedarikci: 'Provider',
    Lokasyon: 'Location.',
    Satin_Alinma_Tarihi: 'Date of Purchase',
    Sona_Erme_Tarihi: 'Date of Expiration',
    Durum: 'Status',
    Departman: 'Department',
    Sorumlu_Kisi: 'Person in Charge',
    Satin_Alma_Maliyeti: 'Cost of Purchase',
    Para_Birimi: 'Currency',
    Bilgisayar_Detaylari: 'Computer Details',
    Vm_Tipi: 'VM Types',
    Hizmet_Paketi: 'Service pack',
    Sistem_Merkezi: 'System Center',
    Virtual_Center: 'Virtual Center',
    Sanal_Veri_Merkezi: 'Virtual Data Center',
    Vpn_Lisans_Sayisi: 'Number of VPN Licenses',
    Baglanti_Sekli: 'Connection Style',
    Baski_Yonetimi: 'Print Management',
    Baski_Hizi: 'Print Speed',
    Garanti_Suresi_Bitis_Tarihi: 'Warranty Period End Date',
    Donanima_Bagli_Yazilimlar: 'Hardware-bound Software',
    Envanter_Ayrintilari: 'Inventory Details',
    Son_Kullanici: 'End user',
    Guvenlik_Cihazi: 'Safety Device',
    Network_Cihazlari: 'Network Device',
    Sayisini_Gecemez: ':name cannot be more',
    Sayisindan_Az_Olamaz: ':name cannot be less',
    Girilmeli: 'should be entered',
  },
  Reports: {
    Uygulama_Raporlari: 'Application Reports',
    Report: 'Report',
    Application_Name: 'Application Name',
    Yazilim_Envanteri: 'Software Inventory',
    Yazilim: 'Software',
    Total_Software: 'Total Software',
    Total_Hardware: 'Total Hardware',
    Zimmet: 'Debit',
    /*
     * ---------------------Yeni Alan ---------------------
     */
    Publisher: 'Publisher',
    Aggregation_Name: 'Aggregation Name',
    /**
     * ---------------------Yeni Alan ---------------------
     */
  },
  ValidateMessage: {
    tum_alanlar_doldurun: 'Pease fill in all the spaces.',
    accepted: 'Please accept.',
    after: ':Attribute should be after :date.',
    after_or_equal: 'The :attribute must be after or on :date.',
    alpha: 'Insert letters only.',
    alpha_space: 'Insert letters of spaces only.',
    alpha_num: 'Insert letters or numbers only.',
    alpha_num_space: 'The :attribute may only contain letters, numbers, and spaces.',
    alpha_num_dash: 'The :attribute may only contain letters, numbers, and dashes.',
    alpha_num_dash_space: 'The :attribute may only contain letters, numbers, dashes, and spaces.',
    array: 'The :attribute must be an array.',
    before: 'The :attribute must be before :date.',
    before_or_equal: 'The :attribute must be before or on :date.',
    date: 'Please enter a valid date.',
    date_equals: 'The :attribute must be on :date.',
    email: 'Please enter a valid e-mail address.',
    in: 'The selected :attribute must be :values.',
    integer: 'The :attribute must be a whole number.',
    max: 'You can only enter :max characters.',
    min: 'Enter at least :min characters.',
    not_in: 'The selected :attribute must not be :values.',
    not_regex: 'The :attribute must not match the required pattern.',
    numeric: 'You can only enter number in this area.',
    phone: 'Please enter a valid phone number.',
    regex: 'The :attribute must match the required pattern.',
    required: 'This area is required.',
    size: 'This area should be :size characters.',
    string: 'The :attribute must be a string.',
    typeof: 'The :attribute is not the correct type of :type.',
    url: 'The :attribute must be a URL.',
  },
  License_Takibi: {
    // yeni alan
    Lisans_Takibi_Detay: 'License Follow Detail',
    Lisans_Takibi_Kayit: 'License Follow Create',
    Lisans_Takibi: 'License Tracking',
    Lisans_Sozlesmesi: 'License Agreement',
    Lisans_Turu: 'License Type',
    Lisans_Adedi: 'Number of Licenses',
    Kullanici_Adedi: 'Number of Users',
    Sozlesme_Adi: 'Contract name',
    Lisans_Bitis_Tarihi: 'End Date',
    Sicil_No: 'Registration Number',
    Lisans_Key: 'Licence Key',
    Donanim_No: 'Hardware No',
    Description: 'Description',
    Zimmetli_Kullanici: 'Debit User',
    Lisans_Secimi: 'License Selection',
    Uygulama_Ismi: 'Application Name',
    // ///yeni alan
    Kullanici_Durumu: 'User Status',
    Lisans_Kullanicisi_Eklendi: 'Language User Added',
  },
};

/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  CompanyCard,
  SelectCompanyWrapper,
} from '../../assets/styledNew';
import {
  Colors,
} from '../../assets/statics';
import {
  Plus,
} from '../../assets/icons';
import {
  tra,
} from '../../commons/utils';

import {
  startRequiresServices,
  addCompanyModal,
  setLoading,
  getApplications,
  getIntegrationComponents,
  getIntegration,
  getUsers,
  getSurveyList,
  getAdminCompanies,
} from '../../store/actions';
import { selectActiveUser } from '../../store/selectors';
import { DragToScroll } from '../../commons/touch';

class CompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        sirketSeciniz: tra('SirketSeciniz'),
        sirketEkle: tra('SirketEkle'),
      },
    };
  }

  selectCompany = (id) => {
    localStorage.setItem('company', id);
    this.props.getApplications();
    this.props.getIntegrationComponents();
    this.props.getIntegration();
    this.props.getUsers();
    this.props.getSurveyList();
    this.props.getAdminCompanies();
    this.props.startRequiresServices();
    if (this.props.focusClose) this.props.focusClose();
    else this.props.close();
  }

  handleAddCompanyModal = () => {
    this.props.addCompanyModal(true);
    this.props.close();
  }

  companyCard = (item) => {
    const id = localStorage.getItem('company');
    return (
      <CompanyCard active={item.id === parseInt(id, 10)}>
        <div className="card" onClick={() => Math.sign(new Date(item.registration_end) - new Date()) !== -1 && this.selectCompany(item.id)} aria-hidden>
          <div className="image"><img src={item.company_image} alt="" /></div>
          <p className="name">{item.name}</p>
          <p className="desc">{Math.sign(new Date(item.registration_end) - new Date()) !== -1 ? this.state.lang.sirketSeciniz : tra('Sirket_Suresi_Doldu')}</p>
        </div>
      </CompanyCard>
    );
  }

  companyCardAdd = () => (
    <CompanyCard>
      <div className="card" onClick={() => this.handleAddCompanyModal()} aria-hidden>
        <div className="image"><Plus width={42} color={Colors.lightBlue100} /></div>
        <p className="name">{this.state.lang.sirketEkle}</p>
      </div>
    </CompanyCard>
  );

  render() {
    const {
      open,
      close,
      user,
    } = this.props;
    return (
      <DragToScroll>
        <SelectCompanyWrapper open={open} onClose={close} fullWidth="true">
          <div className="companyContainer">
            {user && user.companies && user.companies.map((item) => this.companyCard(item))}
            {/* {this.companyCardAdd()} */}
          </div>
        </SelectCompanyWrapper>
      </DragToScroll>
    );
  }
}

CompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  focusClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  startRequiresServices: PropTypes.func.isRequired,
  addCompanyModal: PropTypes.func.isRequired,
  getApplications: PropTypes.func.isRequired,
  getIntegrationComponents: PropTypes.func.isRequired,
  getIntegration: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getSurveyList: PropTypes.func.isRequired,
  getAdminCompanies: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectActiveUser(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      startRequiresServices,
      addCompanyModal,
      setLoading,
      getApplications,
      getIntegrationComponents,
      getIntegration,
      getUsers,
      getSurveyList,
      getAdminCompanies,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyModal);

import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const deviceDetail = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'Firewall',
    inputArray: [
      {
        type: 'text',
        title: tra('Isim'),
        name: 'name', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'number',
        title: tra('Port_Sayisi'),
        name: 'port_number', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'text',
        title: tra('Ip_Adresi'),
        name: 'ip_address', // değişecek
        validateRules: 'ip:IP',
        value: '1920 X 1080',
        inputProps: {
          maxLength: 15,
        },
      },
      {
        type: 'number',
        title: tra('Vpn_Lisans_Sayisi'),
        name: 'vpn_licence_number', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
];

export default [[...inventoryPages, deviceDetail]];

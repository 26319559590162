import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import responseType from '../enum/responseType';
import { setResponseState } from '../../store/actions';

export default function useResponseStatu(thunEnumType, autoIdleAction = true) {
  const Helper = {
    some: (arr, checkResponse) => arr.some((i) => {
      const item = Object.entries(i || {})[0];
      return item[1] === checkResponse;
    }),
    every: (arr, checkResponse) => arr.every((i) => {
      const item = Object.entries(i || {})[0];
      return item[1] === checkResponse;
    }),
    notEvery: (arr, checkResponse) => arr.every((i) => {
      const item = Object.entries(i || {})[0];
      return item[1] !== checkResponse;
    }),
  };
  const dispatch = useDispatch();
  const result = useSelector((s) => {
    if (Array.isArray(thunEnumType)) {
      return thunEnumType?.map((key) => ({ [key]: s?.responseState?.[key] ?? responseType.IDLE }));
    }
    return [{ [thunEnumType]: s?.responseState?.[thunEnumType] ?? responseType.IDLE }];
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idleAction = (key) => dispatch(setResponseState(key, responseType.IDLE));
  useEffect(() => () => {
    if (autoIdleAction && Helper.some(result, responseType.LOADING) === false) {
      result.forEach((i) => {
        const item = Object.entries(i ?? {})[0];
        // eslint-disable-next-line no-shadow
        if ([responseType.FAILURE, responseType.SUCCESS].some((i) => i === item[1])) {
          idleAction(item[0]);
        }
      });
    }
  }, [Helper, autoIdleAction, idleAction, result]);

  return useMemo(() => ({
    responseStore: result,
    loading: Helper.some(result, responseType.LOADING),
    success: Helper.every(result, responseType.SUCCESS),
    failure: Helper.some(result, responseType.FAILURE),
    idle: Helper.every(result, responseType.IDLE),
  }), [Helper, result]);
}

/**
 * EXAMPLE
 */

// const { responseWatch, responseStore, responseState } = useResponseStatu(thunkEnum.getAnnouncementsPrivate)

// const { responseWatch, responseStore, responseState } = useResponseStatu([
//   thunkEnum.getAnnouncementsPrivate,
//   thunkEnum.getListTopUserSuggestions
// ])

// console.log(responseState.LOADING, 'loading')
// console.log(responseState.SUCCESS, 'success')

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { withTheme } from 'styled-components';
import {
  PageWrapper,
  PageContent,
  CustomButton,
  CustomModal,
  PageHeader,
} from '../../assets/styledNew';
import Input from '../../components/Input';
import { hexToRgbA, tra } from '../../commons/utils';
import {
  Plus, ViewAgenda, EyeFilled, Person, WebAsset, Draft,
} from '../../assets/icons';
import SurveyQuestions from '../../components/SurveyQuestions';
import {
  CustomTextField,
  PublishHeader, SurveyCardWrapper, SurveyStepper, SurveyWrapper,
} from '../../assets/styledSurveyQuestions';
import {
  selectApplications, selectInfos, selectSingleSurvey, selectSurveyAssignData,
} from '../../store/selectors';
import {
  createSurvey, getSingleSurvey, setSurveyAssignData, updateSurvey, setSingleSurvey, setToast,
} from '../../store/actions';
import SurveySelection from '../../components/modals/SurveySelection';

// multiple_select questions ın içinde bi alan. checkbox olunca true radio button olunca false

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        diger: tra('Diger'),
        soruEkle: tra('Soru_Ekle'),
      },
      title: '',
      description: '',
      stepIndex: 1,
      selectionModal: false,
      selectionType: '',
      Sections: [
        {
          name: `${tra('Bölüm')} 1`,
          description: '',
          Questions: [],
        },
      ],
      addUserGroupManuel: false,
      addAppManuel: false,
      counter: 0,
      selectedSurveyId: '',
    };
    if (this.props.match.params.id) {
      this.props.getSingleSurvey(this.props.match.params.id);
    }
    this.validator = new SimpleReactValidator({
      locale: 'tr',
      autoForceUpdate: this,
      element: false,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.appUserTypes.length > 0 && nextProps.match.params.id && Object.keys(nextProps.singleSurvey).length > 0 && (prevState.counter === 0 || (nextProps.match.params.id !== prevState.selectedSurveyId))) {
      const temp = [];
      for (let i = 0; i < nextProps.singleSurvey.UserTypes.length; ++i) {
        const existUserGroup = nextProps.appUserTypes.find((item) => item.id.toString() === nextProps.singleSurvey.UserTypes[i].toString());
        temp.push(existUserGroup);
      }
      const obj = {
        selectedApps: nextProps.singleSurvey.Applications,
        selectedDepartments: temp,
      };
      nextProps.setSurveyAssignData(obj);
      return {
        title: nextProps.singleSurvey.name,
        description: nextProps.singleSurvey.description,
        Sections: nextProps.singleSurvey.SurveySections,
        counter: 1,
        selectedSurveyId: nextProps.match.params.id,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({
      title: '',
      description: '',
      stepIndex: 1,
      selectionModal: false,
      selectionType: '',
      Sections: [
        {
          name: `${tra('Bölüm')} 1`,
          description: '',
          Questions: [],
        },
      ],
      addUserGroupManuel: false,
      addAppManuel: false,
      counter: 0,
    });
    this.props.setSurveyAssignData({});
    this.props.setSingleSurvey({});
  }

  changePage = (index) => {
    this.setState({ stepIndex: index });
  }

  expandChange = (event, isExpanded) => {
    if (!isExpanded) {
      const { Sections } = this.state;
      this.setState({ Sections });
    }
  }

  addSection = () => {
    const { Sections } = this.state;
    const section = {
      name: `${tra('Bölüm')} ${Sections.length + 1}`,
      description: '',
      Questions: [],
    };
    Sections.push(section);
    this.setState({ Sections });
  }

  addQuestion = (index) => {
    const { inputTypes } = this.props;
    const { Sections } = this.state;
    const prgrId = inputTypes.find((item) => item.type === 0).id;
    const newQuestion = {
      question_text: '',
      description: '',
      allow_null: true,
      input_type_id: prgrId,
      multiple_select: false,
      OptionChoices: [
        {
          text: 'Kısa Yanıt Metni',
          description: '',
          factor: 0,
        },
      ],
    };
    Sections[index].Questions.push(newQuestion);
    this.setState({ Sections });
  }

  copyQuestion = (sectionIndex, qIndex) => {
    const { Sections } = this.state;
    const tempArr = [];
    for (let i = 0; i < Sections[sectionIndex].Questions[qIndex].OptionChoices.length; ++i) {
      const option = {
        text: Sections[sectionIndex].Questions[qIndex].OptionChoices[i].text,
        description: Sections[sectionIndex].Questions[qIndex].OptionChoices[i].description,
        factor: Sections[sectionIndex].Questions[qIndex].OptionChoices[i].factor,
      };
      tempArr.push(option);
    }
    const newQuestion = {
      question_text: Sections[sectionIndex].Questions[qIndex].question_text,
      description: Sections[sectionIndex].Questions[qIndex].description,
      allow_null: Sections[sectionIndex].Questions[qIndex].allow_null,
      input_type_id: Sections[sectionIndex].Questions[qIndex].input_type_id,
      OptionChoices: tempArr,
      multiple_select: Sections[sectionIndex].Questions[qIndex].multiple_select,
    };
    Sections[sectionIndex].Questions.push(newQuestion);
    this.setState({ Sections });
  }

  /* Change Question Text */
  onChangeQuestion = (e, sectionIndex, qIndex) => {
    const { Sections } = this.state;
    Sections[sectionIndex].Questions[qIndex].question_text = e.target.value;
  }

  /* Change Question Description */
  onChangeDetail = (e, sectionIndex, qIndex) => {
    const { Sections } = this.state;
    Sections[sectionIndex].Questions[qIndex].description = e.target.value;
  }

  /* Change Question Type */
  onChangeType = (e, sectionIndex, qIndex) => {
    const { Sections } = this.state;
    const { inputTypes } = this.props;
    Sections[sectionIndex].Questions[qIndex].input_type_id = e.target.value;
    Sections[sectionIndex].Questions[qIndex].multiple_select = false;
    const inputType = inputTypes.find((item) => item.id === e.target.value).type;
    if (inputType === 0) {
      Sections[sectionIndex].Questions[qIndex].OptionChoices = [
        {
          text: 'Kısa Yanıt Metni',
          description: '',
          factor: 0,
        },
      ];
    } else {
      Sections[sectionIndex].Questions[qIndex].OptionChoices = [];
      if (inputType === 2) {
        Sections[sectionIndex].Questions[qIndex].multiple_select = true;
      }
    }
    this.setState({ Sections });
  }

  /* Change Question Answer Necessary or not */
  onChangeNecessary = (e, sectionIndex, qIndex) => {
    const { Sections } = this.state;
    Sections[sectionIndex].Questions[qIndex].allow_null = !e.target.checked;
    this.setState({ Sections });
  }

  addOption = (sectionIndex, qIndex, otherOpt) => {
    const { Sections, lang } = this.state;
    if (otherOpt) {
      if (Sections[sectionIndex].Questions[qIndex].OptionChoices.filter((item) => item.text === lang.diger).length === 0) {
        const option = {
          text: lang.diger,
          description: '',
          factor: 0,
        };
        Sections[sectionIndex].Questions[qIndex].OptionChoices.push(option);
      }
    } else {
      const option = {
        text: '',
        description: '',
        factor: 0,
      };
      if (Sections[sectionIndex].Questions[qIndex].OptionChoices.filter((item) => item.text === lang.diger).length > 0) {
        Sections[sectionIndex].Questions[qIndex].OptionChoices.splice(Sections[sectionIndex].Questions[qIndex].OptionChoices.length - 1, 0, option);
      } else {
        Sections[sectionIndex].Questions[qIndex].OptionChoices.push(option);
      }
    }
    this.setState({ Sections });
  }

  /* Change Questions Option Choices text */
  onChangeOption = (e, sectionIndex, qIndex, optIndex) => {
    const { Sections } = this.state;
    Sections[sectionIndex].Questions[qIndex].OptionChoices[optIndex].text = e.target.value;
    this.setState({ Sections });
  }

  /* Change Questions Option Choices factor */
  onChangeFactor = (sectionIndex, qIndex, optIndex, type) => {
    const { Sections } = this.state;
    const oldVal = Sections[sectionIndex].Questions[qIndex].OptionChoices[optIndex].factor;
    if (type === 'inc') {
      Sections[sectionIndex].Questions[qIndex].OptionChoices[optIndex].factor += 1;
    } else if (oldVal > 0) {
      Sections[sectionIndex].Questions[qIndex].OptionChoices[optIndex].factor -= 1;
    }
    this.setState({ Sections });
  }

  deleteQuestion = (sectionIndex, qIndex) => {
    const { Sections } = this.state;
    Sections[sectionIndex].Questions.splice(qIndex, 1);
    this.setState({ Sections });
  }

  deleteOption = (sectionIndex, qIndex, optIndex) => {
    const { Sections } = this.state;
    Sections[sectionIndex].Questions[qIndex].OptionChoices.splice(optIndex, 1);
    this.setState({ Sections });
  }

  deleteSection = () => {
    const { Sections, deleteSectionIndex } = this.state;
    if (Sections.length !== 1 && deleteSectionIndex !== null) {
      Sections.splice(deleteSectionIndex, 1);
      this.setState({ Sections, deleteSectionIndex: null, deleteSectionModal: false });
    }
  }

  handleChangeMultiple = (e, newValue, state) => {
    const sendArr = `send${state}`;
    const tempArr = [];
    if (newValue !== undefined && newValue !== '' && newValue !== null) {
      for (let i = 0; i < newValue.length; ++i) {
        const obj = { id: newValue[i].id };
        tempArr.push(obj);
      }
      this.setState({ [state]: newValue, [sendArr]: tempArr });
    }
  }

  handleChange = (text) => (e) => {
    this.setState({ [text]: e.target.value });
  }

  doneSurvey = (status) => {
    const {
      surveyAssignData, match, appUserTypes, apps,
    } = this.props;
    const {
      Sections, title, description, addAppManuel, addUserGroupManuel,
    } = this.state;

    const sendUserGroups = [];
    const sendApps = [];

    if (addUserGroupManuel) {
      for (let i = 0; i < surveyAssignData.selectedDepartments.length; ++i) {
        sendUserGroups.push(surveyAssignData.selectedDepartments[i].id);
      }
    } else {
      for (let i = 0; i < appUserTypes.length; ++i) {
        sendUserGroups.push(appUserTypes[i].id);
      }
    }
    if (addAppManuel) {
      for (let k = 0; k < surveyAssignData.selectedApps.length; ++k) {
        const obj = { id: surveyAssignData.selectedApps[k].id };
        sendApps.push(obj);
      }
    } else {
      for (let k = 0; k < apps.length; ++k) {
        if (apps[k].isRemoved !== 1) {
          const obj = { id: apps[k].id };
          sendApps.push(obj);
        }
      }
    }

    const data = {
      isDraft: status,
      name: title,
      description,
      UserTypes: sendUserGroups,
      Applications: sendApps,
      Sections,
    };
    if (match.params.id) {
      this.props.updateSurvey(match.params.id, data);
    } else {
      this.props.createSurvey(data);
    }
  }

  changePage = (val) => {
    const { Sections } = this.state;
    switch (val) {
      case 2:
        if (this.validator.fieldValid('title') && this.validator.fieldValid('detail')) {
          this.validator.hideMessages();
          this.setState({ stepIndex: val });
        } else {
          this.validator.showMessageFor('title');
          this.validator.showMessageFor('detail');
        }
        break;
      case 3:
        if (Sections && Sections[0].Questions && Sections[0].Questions.length > 0) {
          const sectionQuestionEmpty = [];
          const tempQuestionEmpty = [];
          const tempChoiceEmpty = [];
          for (let i = 0; i < Sections.length; ++i) {
            sectionQuestionEmpty.push(Sections[i].Questions.length > 0);
            for (let j = 0; j < Sections[i].Questions.length; ++j) {
              tempQuestionEmpty.push(Sections[i].Questions[j].question_text !== '');
              tempChoiceEmpty.push(Sections[i].Questions[j].OptionChoices.length > 0);
            }
          }
          if (sectionQuestionEmpty.filter((item) => item === false).length > 0) {
            this.props.setToast(true, tra('Bolumlere_en_az_bir_soru_eklemelisiniz'), 'warning');
          } else if (tempQuestionEmpty.filter((item) => item === false).length > 0) {
            this.props.setToast(true, tra('Soru_alanlarini_bos_birakamazsiniz'), 'warning');
          } else if (tempChoiceEmpty.filter((item) => item === false).length > 0) {
            this.props.setToast(true, tra('Coktan_secmeli_sorularda_en_az_bir_secenek_eklemelisiniz'), 'warning');
          } else {
            this.validator.hideMessages();
            this.setState({ stepIndex: val });
          }
        } else {
          this.validator.showMessages();
          this.props.setToast(true, tra('En_az_bir_soru_eklemelisiniz'), 'warning');
        }
        break;
      default:
        break;
    }
  }

  basicDetails = () => {
    const { description, title } = this.state;
    return (
      <>
        <Grid container spacing={4} style={{ marginTop: 35 }}>
          {!isWidthDown('sm', this.props.width) && <Grid item xs={3}> </Grid>}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: 45 }}>
                <Input
                  type="text"
                  name="title"
                  title={tra('Başlık')}
                  onChange={this.handleChange('title')}
                  value={title}
                  error={this.validator.message('title', title, 'required')}
                  helperText={this.validator.message('title', title, 'required')}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15, marginBottom: 35 }}>
                <Input
                  type="text"
                  name="detail"
                  title={tra('Detay')}
                  onChange={this.handleChange('description')}
                  value={description}
                  error={this.validator.message('detail', description, 'required')}
                  helperText={this.validator.message('detail', description, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <CustomButton
                    onClick={() => {
                      if (localStorage.getItem('manageSurvey') === '1') {
                        localStorage.setItem('manageSurvey', '2');
                      }
                      this.props.history.goBack('Admin');
                    }}
                    outlined
                  >
                    {tra('Vazgec')}
                  </CustomButton>
                  <CustomButton onClick={() => this.changePage(2)}>{tra('Devam')}</CustomButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  contentBuilder = () => {
    const { Sections, lang } = this.state;
    const { inputTypes } = this.props;
    return (
      <>
        <Grid container justify="center" spacing={4}>
          <Grid container item xs={12} md={10}>
            {Sections.map((section, index) => (
              <SurveyWrapper>
                <div className="header">
                  <CustomTextField
                    value={section.name}
                    onChange={(e) => {
                      if (e.target.value.length < 100) {
                        Sections[index].name = e.target.value;
                        this.setState({ Sections });
                      }
                    }}
                  />
                  {
                    Sections.length > 1 && (
                      <Tooltip title={tra('Bölümü_Sil')} TransitionComponent={Zoom}>
                        <IconButton onClick={() => this.setState({ deleteSectionModal: true, deleteSectionIndex: index })}>
                          <DeleteForeverOutlined style={{ color: this.props.theme.text2 }} />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </div>
                {section.Questions.map((q, i) => (
                  <div className="surveyItem">
                    <SurveyQuestions
                      inputTypes={inputTypes}
                      type={q.input_type_id}
                      index={i + 1}
                      question={q.question_text}
                      detail={q.description}
                      onChangeQuestion={(e) => this.onChangeQuestion(e, index, i)}
                      onChangeDetail={(e) => this.onChangeDetail(e, index, i)}
                      onChangeType={(e) => this.onChangeType(e, index, i)}
                      onChangeNecessary={(e) => this.onChangeNecessary(e, index, i)}
                      onChangeOption={this.onChangeOption}
                      onChangeFactor={this.onChangeFactor}
                      deleteQuestion={() => this.deleteQuestion(index, i)}
                      addOption={this.addOption}
                      deleteOption={this.deleteOption}
                      sectionIndex={index}
                      questionIndex={i}
                      options={q.OptionChoices}
                      expandChange={(event, isExpanded) => this.expandChange(event, isExpanded)}
                      copyQuestion={() => this.copyQuestion(index, i)}
                    />
                  </div>
                ))}
                <Button
                  onClick={() => this.addQuestion(index)}
                  startIcon={<Plus width={15} color={this.props.theme.darkGrey} />}
                  style={{
                    fontWeight: 600,
                    marginTop: 20,
                    color: this.props.theme.darkGrey,
                    width: '100%',
                    borderRadius: 10,
                    minHeight: 50,
                    background: hexToRgbA(this.props.theme.white, 0.8),
                    border: `1px solid ${this.props.theme.darkGrey}`,
                  }}
                >{lang.soruEkle}
                </Button>
              </SurveyWrapper>
            ))}
          </Grid>
        </Grid>
        <Grid justify="center" container spacing={4}>
          <Grid item md={10} xs={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <CustomButton onClick={() => this.setState({ stepIndex: 1 })} outlined>{tra('Geri')}</CustomButton>
              <Button onClick={() => this.addSection()} startIcon={<ViewAgenda color={this.props.theme.text3} />} style={{ fontWeight: 600, color: this.props.theme.text3 }}>{tra('Bölüm_Ekle')}</Button>
              <CustomButton onClick={() => this.changePage(3)}>{tra('Devam')}</CustomButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  reviewAndPublish = () => {
    const {
      addAppManuel, addUserGroupManuel, selectionModal, selectionType,
    } = this.state;
    return (
      <Grid container item justify="center" spacing={4}>
        <Grid container item xs={8}>
          <PublishHeader>
            <EyeFilled color={this.props.theme.blue} width={20} /><p>{tra('Anketi_Gozden_Gecir')}</p>
          </PublishHeader>
        </Grid>
        <Grid container justify="center" item xs={8}>
          <SurveyCardWrapper active={addAppManuel}>
            <div className="surveyCard">
              <div className="iconContainer">
                <WebAsset />
                <div className="smallIcon"><Plus /></div>
              </div>
              <div className="title">
                <p>{tra('Anketin_gönderileceği_uygulamalar')}</p>
              </div>
              <div className="actions">
                <Link to="#" onClick={() => this.setState({ addAppManuel: false })}>{tra('Tüm_Uygulamalar')}</Link>
                <Link to="#" onClick={() => this.setState({ addAppManuel: true, selectionModal: true, selectionType: 'app' })}>{tra('Manuel_Uygulama_Ekle')}</Link>
              </div>
            </div>
          </SurveyCardWrapper>
          <SurveyCardWrapper style={{ marginLeft: isWidthDown('sm', this.props.width) && 0, marginTop: isWidthDown('sm', this.props.width) && 20 }} active={addUserGroupManuel}>
            <div className="surveyCard">
              <div className="iconContainer">
                <Person />
                <div className="smallIcon"><Plus /></div>
              </div>
              <div className="title">
                <p>{tra('Anketin_gönderileceği_kullanıcı_grupları')}</p>
              </div>
              <div className="actions">
                <Link to="#" onClick={() => this.setState({ addUserGroupManuel: false })}>{tra('Tum_Kullanici_Gruplari')}</Link>
                <Link to="#" onClick={() => this.setState({ addUserGroupManuel: true, selectionModal: true, selectionType: 'user' })}>{tra('Manuel_Kullanici_Grubu_Ekle')}</Link>
              </div>
            </div>
          </SurveyCardWrapper>
        </Grid>
        <Grid container justify="space-between" item md={10} xs={12} spacing={isWidthDown('sm', this.props.width) ? 2 : 0}>
          <Grid container justify={isWidthDown('sm', this.props.width) ? 'center' : 'flex-start'} item xs={12} sm={4}>
            <CustomButton onClick={() => this.setState({ stepIndex: 2 })} outlined>{tra('Geri')}</CustomButton>
          </Grid>
          <Grid container justify={isWidthDown('sm', this.props.width) ? 'center' : 'flex-end'} item xs={12} sm={8}>
            {this.props.match.params.id === undefined && <CustomButton startIcon={<Draft />} style={{ marginRight: 15 }} onClick={() => this.doneSurvey(1)}>{tra('Taslak_Olarak_Kaydet')}</CustomButton>}
            <CustomButton onClick={() => this.doneSurvey(0)}>{tra('Gonder')}</CustomButton>
          </Grid>
        </Grid>
        <SurveySelection
          open={selectionModal}
          type={selectionType}
          close={() => this.setState({ selectionModal: false, selectionType: '' })}
        />
      </Grid>
    );
  }

  render() {
    const { stepIndex, deleteSectionModal } = this.state;
    return (
      <PageWrapper>
        <PageContent>
          <Grid container>
            <Grid container justify="center" item xs={12}>
              <SurveyStepper stepIndex={stepIndex}>
                <div className="step">
                  <div className="circle">
                    <span>1</span>
                    <p>{tra('Temel_Ayrıntılar')}</p>
                  </div>
                </div>
                <div className="step">
                  <div className="circle">
                    <span>2</span>
                    <p>{tra('İçerik_Oluştur')}</p>
                  </div>
                </div>
                <div className="step">
                  <div className="circle">
                    <span>3</span>
                    <p>{tra('İnceleyin_ve_Yayınlayın')}</p>
                  </div>
                </div>
              </SurveyStepper>
            </Grid>
          </Grid>
          <div className="pageInner">
            {stepIndex === 1 && (
              this.basicDetails()
            )}
            {stepIndex === 2 && (
              this.contentBuilder()
            )}
            {stepIndex === 3 && (
              this.reviewAndPublish()
            )}
          </div>
        </PageContent>
        <CustomModal open={deleteSectionModal} onClose={() => this.setState({ deleteSectionModal: false, deleteSectionIndex: null })}>
          <DialogContent>
            <PageHeader>
              <h1 style={{ fontSize: 20 }}>{tra('bölüm_sil_sorusu')}</h1>
            </PageHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 20,
              }}
            >
              <CustomButton style={{ marginRight: 10 }} onClick={() => this.setState({ deleteSectionModal: false, deleteSectionIndex: null })}>{tra('Vazgec')}</CustomButton>
              <CustomButton onClick={() => this.deleteSection()}>{tra('Evet')}</CustomButton>
            </div>
          </DialogContent>
        </CustomModal>
      </PageWrapper>
    );
  }
}

Survey.propTypes = {
  inputTypes: PropTypes.array.isRequired,
  createSurvey: PropTypes.func.isRequired,
  surveyAssignData: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  singleSurvey: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setSurveyAssignData: PropTypes.func.isRequired,
  getSingleSurvey: PropTypes.func.isRequired,
  updateSurvey: PropTypes.func.isRequired,
  apps: PropTypes.array.isRequired,
  appUserTypes: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  setSingleSurvey: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  apps: selectApplications(),
  inputTypes: selectInfos('InputTypes'),
  surveyAssignData: selectSurveyAssignData(),
  singleSurvey: selectSingleSurvey(),
  appUserTypes: selectInfos('AppUserTypes'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createSurvey,
      getSingleSurvey,
      setSurveyAssignData,
      updateSurvey,
      setSingleSurvey,
      setToast,
    }, dispatch)
  )
);

const SurveyWithWithTheme = withTheme(Survey);
const SurveyWithWidth = withWidth()(SurveyWithWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyWithWidth);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ColorBox, LoginBox, CustomButton } from '../assets/styledNew';
import Input from '../components/Input';
import { Colors } from '../assets/statics';
import { companyActivation, resendActivationCode, setActivationComplete } from '../store/actions';
import { tra } from '../commons/utils';
import validator from '../commons/validator';
import { selectActivationComplete } from '../store/selectors';
import { LogoColor } from '../assets/icons';

class CompanyActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prefix: window.location.href.split('/')[window.location.href.split('/').length - 1],
      activation_code: '',
      stepIndex: 1,
      lang: {
        sirketAktivasyon: tra('Sirket_Aktivasyon'),
        aktivasyonMesaji: tra('Aktivasyon_Mesaji'),
        tamamla: tra('Tamamla'),
        tebrikler: tra('Tebrikler'),
        hesapAktiveEdildi: tra('Hesap_Aktive_Edildi_Mesaji'),
        girisYap: tra('Giris_Yap'),
        koduTekrarGonder: tra('Kodu_Tekrar_Gonder'),
        aktivasyonKodunuGiriniz: tra('Aktivasyon_Kodunu_Giriniz'),
      },
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.activationComplete) {
      nextProps.setActivationComplete(false);
      return {
        stepIndex: 2,
      };
    }
    return null;
  }

  handleActivateCompany = () => {
    const { activation_code } = this.state;
    if (this.isCheck('activation_code')) {
      this.props.companyActivation({ activationCode: activation_code });
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  resendActivationCode = () => {
    const { prefix } = this.state;
    this.props.resendActivationCode({ prefix });
  }

  isCheck = (...arr) => {
    const arrayValidate = [];
    arr.forEach((item) => {
      arrayValidate.push(validator.fieldValid(item));
      validator.showMessageFor(item);
    });
    this.setState({ asd: '' }); // bu olmassa validator geç setlenmiyor.
    return arrayValidate.every((i) => i === true);
  }

  render() {
    const {
      lang, activation_code, stepIndex,
    } = this.state;
    return (
      <ColorBox state>
        <LoginBox stepIndex={stepIndex} reverse={false}>
          <div className="headerArea">
            <LogoColor />
          </div>
          <div className="formGroup">
            <div className="formArea">
              <div>
                <h1>{lang.sirketAktivasyon}</h1>
                {lang.aktivasyonMesaji}
              </div>
              <div className="inputArea">
                <Input
                  type="text"
                  name="activation_code"
                  variant="standard"
                  value={activation_code}
                  onChange={(e) => this.handleChange(e)}
                  error={validator.message('activation_code', activation_code, 'required|size:6,string')}
                  helperText={validator.message('activation_code', activation_code, 'required|size:6,string')}
                  placeholder={lang.aktivasyonKodunuGiriniz}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomButton style={{ marginRight: 20 }} borderRadius="5px" color={Colors.blue} onClick={() => this.resendActivationCode()}>{lang.koduTekrarGonder}</CustomButton>
                <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => this.handleActivateCompany()}>{lang.tamamla}</CustomButton>
              </div>
            </div>
            <div className="formArea">
              <div>
                <h1>{lang.tebrikler}</h1>
                {lang.hesapAktiveEdildi}
              </div>
              <div
                style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 150,
                }}
              >
                <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => { window.location = '#/'; }}>{lang.girisYap}</CustomButton>
              </div>
            </div>
            <div className="formArea" />
            <div className="formArea" />
          </div>
        </LoginBox>
      </ColorBox>
    );
  }
}

CompanyActivation.propTypes = {
  companyActivation: PropTypes.func.isRequired,
  resendActivationCode: PropTypes.func.isRequired,
  setActivationComplete: PropTypes.func.isRequired,
  activationComplete: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activationComplete: selectActivationComplete(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      companyActivation,
      resendActivationCode,
      setActivationComplete,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyActivation);

import {
  Badge,
  Checkbox, CircularProgress, Fade, Grid, MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { CustomButton, CustomMenu, WithIconCell } from '../../../assets/styledNew';
import Input from '../../../components/Input';

import {
  CancelledFillIcon, Check, CloudExport, ErrorFillIcon, Info,
} from '../../../assets/icons';
import { tra } from '../../../commons/utils';
import Table from '../../../components/Table';
import { Colors } from '../../../assets/statics';
import * as actions from '../../../store/actions';
import TableFilterMenu from './TableFilterMenu';
import IntegrationMessageMonitoringErrorModal from '../../../components/modals/IntegrationMessageMonitoringErrorModal';
import useResponseStatu from '../../../commons/hook/useResponseStatu';
// icon
const message_column_type_enum = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'systemError',
  CANCELLED: 'canceled',
});

const messageTypeIcon = (val) => {
  switch (val) {
    case message_column_type_enum.SUCCESS:
      return <WithIconCell color={Colors.green}><Check width={21} color={Colors.green} /> Success </WithIconCell>;
    case message_column_type_enum.ERROR:
      return <WithIconCell color={Colors.red}><ErrorFillIcon /> Error </WithIconCell>;
    case message_column_type_enum.CANCELLED:
      return <WithIconCell color={Colors.lightPurple}><CancelledFillIcon /> Cancelled </WithIconCell>;
    default: return '';
  }
};

const message_detail_column_table = [
  {
    filterable: true,
    name: 'message_details_text',
    text: tra('Message_Details'),
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    filterable: true,
    name: 'message_id',
    text: tra('Message_Id'),
    type: 'text',
    align: 'left',
    sortable: true,
  },
  {
    filterable: true,
    name: 'start_date',
    text: tra('Baslangic_Tarihi'),
    type: 'text',
    align: 'left',
    sortable: true,
    customBody: (val) => moment(val).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    filterable: true,
    name: 'end_date',
    text: tra('End_Time'),
    type: 'text',
    align: 'left',
    sortable: true,
    customBody: (val) => moment(val).format('DD/MM/YYYY HH:mm:ss'),
  },
];

export default function MessageMonitoringTab() {
  const dispatch = useDispatch();

  // SELECTOR
  const integrationMessages = useSelector((s) => s?.integrationMessages);
  const messageMonitoringTypes = useSelector((s) => s?.infos?.MessageMonitoringTypes);

  // STATE
  const initData = {
    sendMailData: [],
    selectedDetailsTable: [],
    channelMessageExcelMenu: false,
    tableResetFilter: false,
    messageMonitoringErrorModalId: false,
    messageStartDate: new Date(new Date().setHours(0, 0, 0, 0)),
    messageEndData: new Date(new Date().setHours(24, 0, 0, 0)),
    status: [],
    messageDetailsTableShow: false,
  };
  const [messageState, setMessageState] = useState(initData);
  const {
    sendMailData,
    selectedDetailsTable,
    channelMessageExcelMenu,
    tableResetFilter,
    messageMonitoringErrorModalId,
    messageStartDate,
    messageEndData,
    status,
    messageDetailsTableShow,
  } = messageState;

  const { loading: updateIntegration } = useResponseStatu(actions.updateIntegrationMessages().type);
  const { loading: getIntegraionMessageLoading } = useResponseStatu(actions.getIntegrationMessages().type);
  const { loading: sendMailLoading, success: sendMailSuccess } = useResponseStatu(actions.getMessageMonitoringSendMailLog().type);

  useEffect(() => {
    setMessageState((s) => ({ ...s, status: messageMonitoringTypes?.filter((data) => data?.id === 3) }));
  }, [messageMonitoringTypes]);

  useEffect(() => {
    if (sendMailSuccess) {
      setMessageState((s) => ({ ...s, sendMailData: [] }));
    }
  }, [sendMailSuccess]);

  // HANDLE ACTION
  const selectCheckboxEmail = (id, checked) => {
    if (checked) {
      setMessageState((s) => ({ ...s, sendMailData: [...new Set([...sendMailData, id])] }));
    } else {
      setMessageState((s) => ({ ...s, sendMailData: sendMailData.filter((i) => i !== id) }));
    }
  };

  const message_monitor_column_table = [
    {
      name: 'id',
      text: '',
      type: 'text',
      align: 'center',
      customBody: (id) => (
        <Checkbox
          color="primary"
          onChange={(e, checked) => selectCheckboxEmail(id, checked)}
          checked={sendMailData?.some((i) => i === id) ?? false}
        />
      ),
    },
    {
      name: 'type',
      text: tra('Type'),
      type: 'text',
      align: 'left',
      customBody: (val) => messageTypeIcon(val),
      // sortable: true,
    },
    {
      name: 'id',
      text: tra('Detay'),
      type: 'text',
      align: 'center',
      customBody: (val, name, index, row) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={() => setMessageState((s) => ({ ...s, messageMonitoringErrorModalId: val }))}
        >
          <Badge color="primary" variant="dot" invisible={!row.logs}>
            <Info color={Colors.blue} />
          </Badge>
        </div>
      ),
      // sortable: true,
    },
    {
      filterable: true,
      name: 'count',
      text: tra('Count'),
      type: 'text',
      align: 'left',
      searchable: true,
      sortable: true,
    },
    {
      filterable: true,
      name: 'sender_party',
      text: tra('Sender_Party'),
      type: 'text',
      align: 'left',
      searchable: true,
      sortable: true,
    },
    {
      filterable: true,
      name: 'sender_component',
      text: tra('Gonderen_Bileseni'),
      type: 'text',
      align: 'left',
      sortable: true,
    },
    {
      filterable: true,
      name: 'interface_name',
      text: tra('Interface_Name'),
      type: 'text',
      align: 'left',
      sortable: true,
    },
    {
      filterable: true,
      name: 'interface_namespace',
      text: tra('Interface_Namespace'),
      type: 'text',
      align: 'left',
      sortable: true,
    },
    {
      filterable: true,
      name: 'receiver_party',
      text: tra('Receiver_Party'),
      type: 'text',
      align: 'left',
      sortable: true,
    },
    {
      filterable: true,
      name: 'receiver_component',
      text: tra('Alici_Bileseni'),
      type: 'text',
      align: 'left',
      sortable: true,
    },
    {
      filterable: true,
      name: 'Departments',
      text: tra('Sektor'),
      arrayPath: 'name',
      type: 'array',
      align: 'left',
      customBody: (value) => value.map((d) => (
        <div>
          {d.name}
        </div>
      )),
    },
  ];

  return (
    <>
      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid item container xs={12} spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Input type="dateAndTime" format="dd/MM/yyyy HH:mm" value={messageStartDate} onChange={(e) => setMessageState((s) => ({ ...s, messageStartDate: new Date(e) }))} title={tra('Baslangic_Tarihi')} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input type="dateAndTime" format="dd/MM/yyyy HH:mm" value={messageEndData} onChange={(e) => setMessageState((s) => ({ ...s, messageEndData: new Date(e) }))} title={tra('Bitis_Tarihi')} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input type="autoComplete" value={status} data={messageMonitoringTypes} onChange={(e, data) => setMessageState((s) => ({ ...s, status: data }))} title={tra('Status')} />
          </Grid>
          <Grid
            container
            item
            alignContent="flex-start"
            spacing={3}
            xs={12}
            md={12}
            style={{
              paddingBottom: '14px', alignItems: 'center', display: 'flex', gap: '15px',
            }}
          >
            <CustomButton
              onClick={() => {
                dispatch(actions.getIntegrationMessages({
                  startDate: messageStartDate,
                  endDate: messageEndData,
                  status: status?.map((i) => i?.id) || [],
                }));
              }}
              startIcon={getIntegraionMessageLoading && <CircularProgress style={{ color: 'white' }} size={20} />}
            >
              {/* <RefreshIcon /> */}
              {tra('Arama_Yap')}
            </CustomButton>

            <CustomButton
              onClick={updateIntegration ? () => { } : () => dispatch(actions.updateIntegrationMessages())}
              startIcon={updateIntegration && <CircularProgress style={{ color: 'white' }} size={20} />}

            >{tra('Sap_Guncelle')}

            </CustomButton>
            <CustomButton
              disabled={!sendMailData?.length}
              onClick={() => dispatch(actions.getMessageMonitoringSendMailLog(sendMailData))}
              startIcon={sendMailLoading && <CircularProgress style={{ color: 'white' }} size={20} />}
            >{tra('Secilenleri_Mail_Gonder')}
            </CustomButton>
            <div style={{ marginRight: 20 }}>
              <CustomButton
                onClick={(e) => setMessageState((s) => ({ ...s, channelMessageExcelMenu: e.currentTarget }))}
                startIcon={<CloudExport width={14} />}
              >
                Excel
              </CustomButton>
              <CustomMenu
                anchorEl={channelMessageExcelMenu}
                open={Boolean(channelMessageExcelMenu)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                onClose={() => setMessageState((s) => ({ ...s, channelMessageExcelMenu: null }))}
              >
                <MenuItem onClick={() => {
                  dispatch(actions.getIntegrationMessageExcel({
                    startDate: messageStartDate,
                    endDate: messageEndData,
                  }));
                  setMessageState((s) => ({ ...s, channelMessageExcelMenu: null }));
                }}
                ><CloudExport width={14} />{tra('Excel_Dışa_Aktar')}
                </MenuItem>
              </CustomMenu>
            </div>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <TableFilterMenu>
            {(filterProps) => (
              <Table
                lazyLoading={getIntegraionMessageLoading}
                id="messageMonitoringTable"
                data={integrationMessages}
                columns={message_monitor_column_table}
                rowClick={(row) => setMessageState((s) => ({ ...s, selectedDetailsTable: row.messageDetails || [], messageDetailsTableShow: true }))}
                options={{
                  header: {
                    visible: true,
                    search: false,
                    columnSetting: true,
                  },
                  filter: {
                    show: true,
                    ...filterProps,
                    // filters: this.state.filter,
                    // onChange: this.changeFilter,
                    // drawerChange: () => this.props.setFilterDrawer(!this.props.filterDrawer),
                  },
                  pagination: {
                    active: true,
                    perPage: 13,
                  },
                }}
                tableResetFilter={tableResetFilter || false}
                onTableResetFilter={() => setMessageState((s) => ({ ...s, tableResetFilter: false }))}
              />
            )}
          </TableFilterMenu>

        </Grid>
        <Fade in={messageDetailsTableShow}>
          <Grid item xs={12}>
            <Table
              id="messageMonitoringDetailsTable"
              data={selectedDetailsTable}
              rowClick={(row) => window.open(row.message_link, '_blank').focus()}
              columns={message_detail_column_table}
              options={{
                header: {
                  visible: true,
                  search: false,
                  columnSetting: true,
                },
                pagination: {
                  active: true,
                  perPage: 13,
                },
              }}
            />
          </Grid>
        </Fade>
      </Grid>
      <IntegrationMessageMonitoringErrorModal
        open={messageMonitoringErrorModalId > 0}
        id={messageMonitoringErrorModalId}
        close={() => setMessageState((s) => ({ ...s, messageMonitoringErrorModalId: false }))}
      />
    </>

  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import { Drawer, Grid, Slide } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Archive } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import Validator from '../../commons/validator';
import {
  EditNew, HistoryIcon, Printer, SaveNew,
} from '../../assets/icons';
import {
  PageWrapper, PageContent, CustomButton, HardwareDetailBottomSheet, ComponentLoader, CustomDivider,
} from '../../assets/styledNew';
import {
  selectHardwareDeviceDetail, selectAppConfig, selectHardwareLogs, selectResetState,
} from '../../store/selectors';
import {
  getHardwareDeviceDetail, setAppConfig,
  updateHardware, setResetState,
} from '../../store/actions';
import HardwareSwitchData, { hwaPathData } from '../../assets/hardwareStatic';
import { tra } from '../../commons/utils';
import { Colors, hardwareUnitSelect } from '../../assets/statics';
import PagesRender from '../../components/hardware/PagesRender';
import { HardwareContext } from '../../commons/context/HardwareContext';
import Logs from '../../components/Logs';

class HardwareDetail extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = HardwareContext

  constructor(props, context) {
    super(props);
    this.state = {
      logDrawer: false,
      lang: {
        ekle: tra('Ekle'),
        duzenle: tra('Duzenle'),
        vazgec: tra('Vazgec'),
        kaydet: tra('Kaydet'),
        ciktiAl: tra('Cikti_Al'),
        arsiv: tra('Arsiv'),
        tarihce: tra('Tarihce'),
        dokuman: tra('Dokuman'),
      },
    };
    // Validator.hideMessages();
    props.getHardwareDeviceDetail(Number(this.props.match.params.id)); // detayları geriren servis
    context.setContextHardwareDetailToggle(); // Detay sayfasında olduğunu gösteren koşul
  }

  componentDidMount() {
    this.context.setContextServiceData(JSON.parse(JSON.stringify(this.props.devicesDetail)) || {});
  }

  componentDidUpdate(prevProps) {
    const { devicesDetail } = this.props;
    if (JSON.stringify(prevProps.devicesDetail) !== JSON.stringify(devicesDetail)) {
      this.context.setContextServiceData(JSON.parse(JSON.stringify(this.props.devicesDetail)));
    }
    if (this.props.resetState === true) {
      this.context.setContexDeleteAllSubmitData();
      this.props.setAppConfig([{ disabled: true }]);
      this.props.setResetState(false);
    }
    // if (prevProps.reRender !== this.props.reRender) {
    //   this.forceUpdate();
    // }
  }

  componentWillUnmount() {
    this.props.setAppConfig([{ disabled: true }]);
    this.context.setReset();
    Validator.hideMessages();
    Validator.visibleFields = [];
  }

  scrollToErrorField=() => {
    setTimeout(() => document.querySelector('.MuiFormHelperText-root')?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 900);
  }

  handleSubmitButton = () => {
    const { PagesValidate, ServiceData } = this.context;
    const { hardware_category_id } = this.props.devicesDetail;
    // const postTypePath = ['server', 'network', 'lastUserProduct'];
    const typeId = hardwareUnitSelect.find((i) => i.id === hardware_category_id).typeID;
    // if (Object.entries(PagesValidate).map((i) => i[1]).flat(1).length === 0 || this.isCheck(...Object.entries(PagesValidate).map((i) => i[1]).flat(1))) {
    if (Validator.allValid()) {
      this.props.updateHardware(
        {
          data: {
            ...this.context.SubmitData,
            hardware_category_id: ServiceData.hardware_category_id,
          },
          hardwareType: hwaPathData.find((i) => i.id === Number(typeId)).path,
          id: this.props.devicesDetail.id,
        },
      );
      // this.props.getHardwareDeviceDetail(Number(this.props.match.params.id));
      this.context.setContextServiceData(this.props.devicesDetail);
      // detayları geriren servis
    } else {
      Validator.showMessages();
      this.context.setContextParentReRender();
      this.scrollToErrorField();
    }
  }

  // isCheck = (...arr) => {
  //   const arrayValidate = [];
  //   for (let index = 0; index < arr.length; index++) {
  //     const element = arr[index];
  //     arrayValidate.push(Validator.fieldValid(element));
  //     Validator.showMessageFor(element);
  //   }
  //   this.setState({ asd: '' }); // bu olmassa validator geç setlenmiyor.
  //   return arrayValidate.every((i) => i === true);
  // }

  handleBackFirstData = () => {
    // this.context.setContextAppState(this.props.devicesDetail);
    this.context.setContexDeleteAllSubmitData();
    this.props.setAppConfig([{ disabled: false }]);
  }

  handleSave=() => {
    this.props.setAppConfig('disabled', false);
    // this.context.setContexDeleteAllSubmitData();
  }

  menuButton=() => {
    const { lang } = this.state;
    return !isWidthDown('sm', this.props.width) && (
      <div className="detailButtonMenu">

        {/* <CustomButton
          transparent
          onClick={() => window.print()}
          startIcon={<Printer width={16} />}
        >
          {lang.ciktiAl}
        </CustomButton> */}
        {/* <CustomButton
          transparent
          // onClick={() => this.handleWithStateProps({ openModalInventoryDetail: inventoryDetailModalEnum.ARCHIVE })}
          startIcon={<Archive color={Colors.blue} width="16" />}
        >
          {lang.arsiv}
        </CustomButton> */}
        <CustomButton
          // onClick={() => this.props.setAppConfig('disabled', false)}
          onClick={() => this.handleSave()}
          startIcon={<EditNew color={Colors.blue} width="16" />}
        >
          {lang.duzenle}
        </CustomButton>
        {/* <CustomButton
          opacity
          startIcon={<DescriptionIcon style={{ width: '16px' }} />}
          onClick={() => this.setState({ })}
        > {lang.dokuman}
        </CustomButton> */}
        <CustomButton
          opacity
          onClick={() => this.setState({ logDrawer: true })}
          startIcon={<HistoryIcon />}
        >
          {lang.tarihce}
        </CustomButton>
      </div>
    );
  }

  render() {
    Validator.purgeFields();
    const { lang } = this.state;
    const { disabled } = this.props.appConfig;
    const categoryID = this.props.devicesDetail.hardware_category_id;
    const UIPagesDataStaticFilter = HardwareSwitchData(categoryID).filter((item) => item.flat(Infinity).some((e) => (e.inputArray.length !== 0 && !e.definedName) || item.flat(Infinity).some((element) => this.context.setContextDefinedControl(element.definedName))));
    const UIPagesData = Number.isSafeInteger(categoryID) ? UIPagesDataStaticFilter : false;
    return (
      <>
        <Drawer open={this.state.logDrawer} onClose={() => this.setState({ logDrawer: false })} anchor="right">
          <Logs data={this.props.logs} />
        </Drawer>
        <PageWrapper>
          <PageContent>
            <div className="pageInner">
              {
                    UIPagesData ? UIPagesData.map((pagesData, pagesIndex) => (
                      <>
                        <PagesRender
                          key={uuidv4()}
                          pagesData={pagesData}
                          pagesIndex={pagesIndex}
                        >
                          {pagesIndex === 0 && this.menuButton()}
                        </PagesRender>
                        {pagesIndex !== UIPagesData.length - 1 && <CustomDivider style={{ width: '95%', margin: '0 auto' }} />}
                      </>
                    ))
                      : <ComponentLoader style={{ height: '100vh' }}><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>

                }
            </div>
          </PageContent>
        </PageWrapper>
        <Slide
          appear={false}
          direction="up"
          in={!disabled}
        >
          <HardwareDetailBottomSheet show={!disabled}>
            <Grid container>
              <Grid container justify="flex-end" item xs={12}>
                <div className="buttonGroup">
                  <CustomButton outlined onClick={() => this.handleBackFirstData()}>{lang.vazgec}</CustomButton>
                  <CustomButton onClick={() => this.handleSubmitButton()} startIcon={<SaveNew color={Colors.blue} width="16" />}>{lang.kaydet}</CustomButton>
                </div>
              </Grid>
            </Grid>
          </HardwareDetailBottomSheet>
        </Slide>
      </>
    );
  }
}

HardwareDetail.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  getHardwareDeviceDetail: PropTypes.func.isRequired,
  updateHardware: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  devicesDetail: PropTypes.object.isRequired,
  width: PropTypes.object.isRequired,
  logs: PropTypes.object.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({

  devicesDetail: selectHardwareDeviceDetail(),
  appConfig: selectAppConfig(),
  logs: selectHardwareLogs(),
  resetState: selectResetState(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getHardwareDeviceDetail,
      setAppConfig,
      updateHardware,
      setResetState,
    }, dispatch)
  )
);

const HardwareDetailWithTheme = withTheme(HardwareDetail);

const HardwareDetailWithWidth = withWidth()(HardwareDetailWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(HardwareDetailWithWidth);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ChevronLeft, ChevronRight,
  HighlightOffOutlined,
} from '@material-ui/icons';
import {
  Grid,
  Popper,
  Button,
  Grow,
  MenuItem,
  Paper,
  ClickAwayListener,
  Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import Touch, { DragToScroll } from '../commons/touch';
import {
  Colors,
  dashboardStatus,
  monthList,
} from '../assets/statics';
import {
  Widget,
  PageContent,
  PageWrapper,
  CarouselSlider,
  ComponentLoader,
  NoData,
  DashboardInfoCardContainer,
  CustomMenuList,
  CustomMenu,
  CostingDashCardGrid,

} from '../assets/styledNew';
import {
  // DateIcon,
  Window,
  Message,
  FilledCircle,
  TotalCostIcon,
} from '../assets/icons';
// import LineChart from '../components/charts/LineChart';
import GartnerTime from '../components/charts/GartnerTime';
import { FormatCurrency, tra } from '../commons/utils';
import {
  getDashboard,
  getCostGraph,
  getAdditionalCost,
  getCalendar,
  getVendorProcessMatrix,
  getAppCostReport,
  getPerITDomain,
  getPerBusinessCapability,
  getPerBusinessDomain,
  getDashboardCostingDetail,
} from '../store/actions';
import {
  selectDashboard,
  selectActiveUser,
  selectAdditionalCost,
  selectCostGraph,
  selectAppConfig,
  selectCalendar,
  selectInfos,
  selectVendorProcessMatrix,
  selectTimeModel,
  selectAppCostReport,
  selectPerBusinessCapability,
  selectPerITDomain,
  selectPerBusinessDomain,
  selectDashboardCostingDetail,
} from '../store/selectors';
import NoDataImg from '../assets/img/noData.png';
import YearlyCostAppReport from '../components/charts/YearlyCostAppReport';
import DepartmentBudgetReport from '../components/charts/DepartmentBudgetReport';
import BusinessCapabilityReport from '../components/charts/BusinessCapabilityReport';
import ITDomainReport from '../components/charts/ITDomainReport';
import WorldMapDash from '../components/WorldMapDash';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderStepIndex: 0,
      edit: false,
      compCurrency: '',
      miniBoxOrder: localStorage.getItem('miniBoxOrder') ? JSON.parse(localStorage.getItem('miniBoxOrder')) : ['box0', 'box1', 'box2', 'box3'],
      dashboardMiniBoxdata: {},
    };
    this.props.getDashboard();
    this.props.getDashboardCostingDetail();
    this.props.getCostGraph({ minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` });
    this.props.getAdditionalCost({ minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` });
    this.props.getVendorProcessMatrix({ minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` });
    this.props.getCalendar({ minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` });
    this.props.getAppCostReport(new Date().getFullYear());
    this.props.getPerITDomain();
    this.props.getPerBusinessCapability();
    this.props.getPerBusinessDomain();
  }

  componentWillUnmount() {
    clearInterval(this.state.sliderSetInterval);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { user, currencies } = nextProps;
    if (Object.keys(prevState.dashboardMiniBoxdata).length === 0) {
      const dashboardMiniBoxdata = {};
      if (nextProps.dashboardData.infoCard) {
        for (let i = 0; i < nextProps.dashboardData.infoCard.length; i++) {
          dashboardMiniBoxdata[`box${i}`] = {
            text: tra(nextProps.dashboardData.infoCard[i].name),
            count: nextProps.dashboardData.infoCard[i].count,
          };
        }
        return { dashboardMiniBoxdata };
      }
    }
    if (prevState.compCurrency === '' && currencies.length > 0 && user && user.companies && user.companies.length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = user.companies.find((item) => item.id.toString() === activeComp);
      const currency = currencies.find((item) => item.id === selectedComp?.currency_id);
      return {
        compCurrency: currency?.symbol,
      };
    }
    return null;
  }

  handleSlider = (direction, sliderLength) => {
    const { sliderStepIndex } = this.state;
    if (direction === 'left') {
      return sliderStepIndex < 0
        ? this.setState({ sliderStepIndex: sliderLength })
        : this.setState({ sliderStepIndex: sliderStepIndex - 1 });
    }

    return sliderStepIndex > sliderLength
      ? this.setState({ sliderStepIndex: 0 })
      : this.setState({ sliderStepIndex: sliderStepIndex + 1 });
  }

  deleteBox = (boxId) => {
    const { miniBoxOrder } = this.state;
    const index = miniBoxOrder.findIndex((item) => item === boxId);
    miniBoxOrder[index] = '';
    this.setState({ miniBoxOrder });
    localStorage.setItem('miniBoxOrder', JSON.stringify(miniBoxOrder));
  }

  handleListKeyDown = (event, index) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ [`boxMenu${index}`]: false });
    }
  }

  handleClose = (event, index) => {
    if (this[`box${index}Ref`]?.current && this[`box${index}Ref`]?.current.contains(event.target)) {
      return;
    }
    this.setState({ [`boxMenu${index}`]: false });
  };

  selectMiniBox = (event, index, boxId) => {
    const { miniBoxOrder } = this.state;
    miniBoxOrder[index] = boxId;
    this.setState({ miniBoxOrder });
    this.handleClose(event, index);
    localStorage.setItem('miniBoxOrder', JSON.stringify(miniBoxOrder));
  }

  costingDetailComp = ({
    cyFcTotal,
    cyTotal,
    nyFcTotal,
    nyTotal,
    pyFcTotal,
    pyTotal,
  }) => (
    <CostingDashCardGrid>
      <div className="costingCard">
        <div className="costingIcon"><TotalCostIcon color={Colors.grey} /></div>
        <p className="costingTitle">{new Date().getFullYear() - 1}</p>
        <p className="costingPrice">{`${pyTotal ? FormatCurrency(pyTotal) : '0,00'} ${this.state.compCurrency}`}</p>
        <p className="costingTotalPrice">{`${pyFcTotal ? FormatCurrency(pyFcTotal) : '0,00'} ${this.state.compCurrency}`}</p>
      </div>
      <div className="costingCard">
        <div className="costingIcon"><TotalCostIcon color={this.props.theme.greenText} /></div>
        <p className="costingTitle">{tra('Yearly_Cost')}</p>
        <p className="costingPrice">{`${cyTotal ? FormatCurrency(cyTotal) : '0,00'} ${this.state.compCurrency}`}</p>
        <p className="costingTotalPrice">{`${cyFcTotal ? FormatCurrency(cyFcTotal) : '0,00'} ${this.state.compCurrency}`}</p>
      </div>
      <div className="costingCard">
        <div className="costingIcon"><TotalCostIcon color={Colors.grey} /></div>
        <p className="costingTitle">{new Date().getFullYear() + 1}</p>
        <p className="costingPrice">{`${nyTotal ? FormatCurrency(nyTotal) : '0,00'} ${this.state.compCurrency}`}</p>
        <p className="costingTotalPrice">{`${nyFcTotal ? FormatCurrency(nyFcTotal) : '0,00'} ${this.state.compCurrency}`}</p>
      </div>
    </CostingDashCardGrid>
  )

  render() {
    const {
      miniBoxOrder, dashboardMiniBoxdata, sliderStepIndex, compCurrency,
    } = this.state;
    const {
      dashboardData,
      appCostReport,
      appConfig,
      perITDomain,
      perBusinessCapability,
      timeModel,
      perBusinessDomain,
    } = this.props;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container spacing={3}>
              <Grid container item md={8} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Widget style={{ overflow: 'unset' }}>
                      <div className="content">
                        <p style={{ paddingBottom: '15px' }}>{tra('Costing_Detail')}</p>
                        {this.costingDetailComp(this.props.dashboardCostingDetail)}
                      </div>
                    </Widget>
                  </Grid>
                  <Grid item xs={12}>
                    <Widget style={{ maxHeight: 540 }}>
                      <div className="content">
                        <div className="header">
                          <h4>{tra('Yearly_Cost_Application_Name_And_Cost_Type')}</h4>
                        </div>
                        <div className="legendHeader">
                          <div className="legendItem">
                            <FilledCircle width={12} color="#C1D1E2" />
                            <p>{tra('Lisanslar')}</p>
                          </div>
                          <div className="legendItem">
                            <FilledCircle width={12} color="#8584FF" />
                            <p>{tra('Other_Contracts')}</p>
                          </div>
                          <div className="legendItem">
                            <FilledCircle width={12} color="#DEE3F9" />
                            <p>{tra('Other_Costs')}</p>
                          </div>
                        </div>
                        {
                          appCostReport ? (
                            appCostReport.length > 0 ? (
                              <YearlyCostAppReport cur={compCurrency} data={appCostReport} />
                            ) : (
                              <NoData>
                                <img src={NoDataImg} alt="No Data" />
                                <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                              </NoData>
                            )
                          ) : (
                            <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                          )
                        }
                      </div>
                    </Widget>
                  </Grid>
                  <Grid item xs={12}>
                    <Widget style={{ maxHeight: 540 }}>
                      <div className="content">
                        <div className="header">
                          <h4>{tra('Department_Budget_Graphic')}</h4>
                        </div>
                        <div className="legendHeader">
                          <div className="legendItem">
                            <FilledCircle width={12} color="#4C6FFF" />
                            <p>{tra('Kullanilan_Butce')}</p>
                          </div>
                        </div>
                        {
                          perBusinessDomain ? (
                            perBusinessDomain.length > 0 ? (
                              <DepartmentBudgetReport data={perBusinessDomain} cur={compCurrency} />
                            ) : (
                              <NoData>
                                <img src={NoDataImg} alt="No Data" />
                                <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                              </NoData>
                            )
                          ) : (
                            <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                          )
                        }
                      </div>
                    </Widget>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={4} xs={12}>
                <Grid container spacing={3}>
                  {
                    Object.keys(dashboardMiniBoxdata).length > 0 ? miniBoxOrder.map((item, index) => (
                      <Grid item xs={12}>
                        <Widget style={{ height: 100 }}>
                          {
                            item
                              ? (
                                <>
                                  <div className="content" style={{ width: 'calc(100% - 45px)' }}>
                                    <Tooltip title={dashboardMiniBoxdata[item].text}>
                                      <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>{dashboardMiniBoxdata[item].text}</p>
                                    </Tooltip>
                                    <h3>{dashboardMiniBoxdata[item].count}</h3>
                                  </div>
                                  {
                                    appConfig.editDashboard ? (
                                      <div className="icon" style={{ background: this.props.theme.widgetIconBackgClose }}>
                                        <HighlightOffOutlined onClick={() => this.deleteBox(item)} style={{ cursor: 'pointer', color: 'white' }} />
                                      </div>
                                    )
                                      : (
                                        <div className="icon">
                                          <Window />
                                        </div>
                                      )
                                  }
                                </>
                              ) : (
                                <div style={{
                                  display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center',
                                }}
                                >
                                  <Button
                                    className="infoCardAddButton"
                                    onClick={(e) => this.setState({ [`boxMenu${index}`]: e.currentTarget })}
                                  >
                                    {tra('Bilgi_Kutusu_Ekle')}
                                  </Button>
                                  <CustomMenu
                                    open={Boolean(this.state[`boxMenu${index}`])}
                                    anchorEl={this.state[`boxMenu${index}`]}
                                    onClose={() => this.setState({ [`boxMenu${index}`]: null })}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                    keepMounted
                                  >
                                    {
                                      Object.keys(dashboardMiniBoxdata).map((menu) => (
                                        <MenuItem
                                          onClick={(e) => this.selectMiniBox(e, index, menu)}
                                          disabled={miniBoxOrder.some((o) => o === menu)}
                                        >
                                          {dashboardMiniBoxdata[menu].text}
                                        </MenuItem>
                                      ))
                                    }
                                  </CustomMenu>
                                  {/* <Popper open={this.state[`boxMenu${index}`]} anchorEl={this[`box${index}Ref`]?.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                      <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                      >
                                        <Paper>
                                          <ClickAwayListener onClickAway={(e) => this.handleClose(e, index)}>
                                            <CustomMenuList autoFocusItem={this.state[`boxMenu${index}`]} id="menu-list-grow" onKeyDown={(e) => this.handleListKeyDown(e, index)}>

                                            </CustomMenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Grow>
                                    )}
                                  </Popper> */}
                                </div>
                              )
                          }
                        </Widget>
                      </Grid>
                    ))
                      : (
                        Array.from({ length: 4 }).map((item) => (
                          <Grid item xs={12}>
                            <Widget style={{ overflow: 'inherit' }}>
                              <div className="content">
                                <Skeleton variant="text" animation="pulse" width="75%"><p>{item}</p></Skeleton>
                                <Skeleton variant="text" animation="pulse" width="15%"><p>{item}</p></Skeleton>
                                <div className="info">
                                  <Skeleton variant="text" animation="pulse" width="50%"><span>{item}</span></Skeleton>
                                </div>
                              </div>
                              <Skeleton variant="circle" animation="pulse" width={40} height={40} style={{ position: 'absolute', right: 5, top: 5 }}>
                                <div className="icon"><Window /></div>
                              </Skeleton>
                            </Widget>
                          </Grid>
                        ))
                      )
                  }
                  <Grid item xs={12}>
                    <Widget style={{ overflow: 'unset', maxHeight: 'fit-content' }}>
                      <div className="content">
                        <p style={{ paddingBottom: '15px' }}>{tra('Location_And_Countries')}</p>
                        <WorldMapDash />
                      </div>
                    </Widget>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Widget style={{ maxHeight: perBusinessCapability && perBusinessCapability.length > 5 ? 800 : 400 }}>
                      <div className="content">
                        <div className="header">
                          <h4>{tra('Costs_Per_Business_Capability')}</h4>
                        </div>
                        <div className="legendHeader">
                          <div className="legendItem">
                            <FilledCircle width={12} color="#C9D4FF" />
                            <p>{tra('Kullanilan_Butce')}</p>
                          </div>
                        </div>
                        {
                          perBusinessCapability ? (
                            perBusinessCapability.length > 0 ? (
                              <BusinessCapabilityReport data={perBusinessCapability} cur={compCurrency} />
                            ) : (
                              <NoData>
                                <img src={NoDataImg} alt="No Data" />
                                <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                              </NoData>
                            )
                          ) : (
                            <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                          )
                        }
                      </div>
                    </Widget>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Widget style={{ maxHeight: perITDomain && perITDomain.length > 5 ? 800 : 400 }}>
                      <div className="content">
                        <div className="header">
                          <h4>{tra('Costs_Per_It_Domain')}</h4>
                        </div>
                        <div className="legendHeader">
                          <div className="legendItem">
                            <FilledCircle width={12} color="#D6E7F9" />
                            <p>{tra('Kullanilan_Butce')}</p>
                          </div>
                        </div>
                        {
                          perITDomain ? (
                            perITDomain.length > 0 ? (
                              <ITDomainReport data={perITDomain} cur={compCurrency} />
                            ) : (
                              <NoData>
                                <img src={NoDataImg} alt="No Data" />
                                <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                              </NoData>
                            )
                          ) : (
                            <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                          )
                        }
                      </div>
                    </Widget>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

Dashboard.propTypes = {
  activeUser: PropTypes.object.isRequired,
  getDashboard: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
  getCostGraph: PropTypes.func.isRequired,
  getAdditionalCost: PropTypes.func.isRequired,
  additionalCost: PropTypes.array.isRequired,
  costGraph: PropTypes.array.isRequired,
  appConfig: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  getCalendar: PropTypes.func.isRequired,
  processes: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  getVendorProcessMatrix: PropTypes.func.isRequired,
  vendorProcessMatrix: PropTypes.array.isRequired,
  timeModel: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  appCostReport: PropTypes.array.isRequired,
  getAppCostReport: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  getPerBusinessCapability: PropTypes.func.isRequired,
  getPerITDomain: PropTypes.func.isRequired,
  getPerBusinessDomain: PropTypes.func.isRequired,
  perITDomain: PropTypes.array.isRequired,
  perBusinessCapability: PropTypes.array.isRequired,
  perBusinessDomain: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activeUser: selectActiveUser(),
  dashboardData: selectDashboard(),
  additionalCost: selectAdditionalCost(),
  costGraph: selectCostGraph(),
  appConfig: selectAppConfig(),
  events: selectCalendar(),
  processes: selectInfos('Processes'),
  vendors: selectInfos('Vendors'),
  vendorProcessMatrix: selectVendorProcessMatrix(),
  timeModel: selectTimeModel(),
  appCostReport: selectAppCostReport(),
  currencies: selectInfos('Currencies'),
  user: selectActiveUser(),
  perBusinessCapability: selectPerBusinessCapability(),
  perITDomain: selectPerITDomain(),
  perBusinessDomain: selectPerBusinessDomain(),
  dashboardCostingDetail: selectDashboardCostingDetail(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getVendorProcessMatrix,
      getCostGraph,
      getAdditionalCost,
      getDashboard,
      getCalendar,
      getAppCostReport,
      getPerBusinessCapability,
      getPerITDomain,
      getPerBusinessDomain,
      getDashboardCostingDetail,
    }, dispatch)
  )
);

const DashboardWithTheme = withTheme(Dashboard);
const DashboardWithWidth = withWidth()(DashboardWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWithWidth);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Input from '../../components/Input';
import Table from '../../components/Table';
import { Colors } from '../../assets/statics';
import {
  CustomButton, CustomDrawer, TableWrapper, PageWrapper, FilteredSidebar, PageContent, ComponentLoader,
} from '../../assets/styledNew';
import {
  LeftArrow,
} from '../../assets/icons';
import { FormatCurrency, tra } from '../../commons/utils';
import {
  getApplications, getApplicationDetail, setFilterDrawer, excelDownload, excelUpload, getApplicationDraft,
  setInventoryConfig,
} from '../../store/actions';
import {
  selectActiveUser,
  selectApplicationDrafts,
  selectApplications, selectFilterDrawer, selectInfos, selectInventoryConfig, selectUsers,
} from '../../store/selectors';

const Statuses = [
  {
    id: 0,
    name: tra('Stopped'),
  },
  {
    id: 1,
    name: tra('Pasif'),
  },
  {
    id: 2,
    name: tra('Aktif'),
  },
];

class Inventories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: JSON.parse(localStorage.getItem('inventoryfilter')) || [],
      excelMenu: null,
      drawer: true,
      tableType: '',
      tableData: [],
      compCurrency: '',
    };
    props.getApplications();
    this.hiddenInput = React.createRef();
  }

  componentWillUnmount() {
    this.props.setFilterDrawer(false);
    this.setState({ tableData: [], tableType: '' });
    this.props.setInventoryConfig('tableType', 'Completed');
  }

  static getDerivedStateFromProps(props, state) {
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.symbol;
      return {
        compCurrency: currency,
      };
    }
    if (state.tableType === '' && props.applications.length > 0) {
      return {
        tableData: props.applications,
        tableType: props.inventoryConfig.tableType,
      };
    }
    if (state.tableType !== '' && state.tableType !== props.inventoryConfig.tableType && (props.inventoryConfig.tableType === 'Completed' ? props.applications.length > 0 : props.drafts.length > 0)) {
      return {
        tableType: props.inventoryConfig.tableType,
        tableData: props.inventoryConfig.tableType === 'Completed' ? props.applications : props.drafts,
      };
    }
    if (props.inventoryConfig.tableType === 'Completed' && props.applications.length !== state.tableData) {
      return {
        tableData: props.applications,
      };
    }
    if (props.inventoryConfig.tableType !== 'Completed' && props.drafts.length !== state.tableData) {
      return {
        tableData: props.drafts,
      };
    }
    return null;
  }

  getArrFilters = (itemName, arrayPath, count, countKey) => {
    const { tableData } = this.state;
    const data = [];
    for (let i = 0; i < tableData.length; i++) {
      for (let j = 0; j < tableData[i][arrayPath].length; j++) {
        data.push(tableData[i][arrayPath][j].name);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  }

  changeFilter = (state, value, text, type, label, header) => {
    const { filter } = this.state;
    if (type === 'resetAll') {
      this.setState({ filter: [] });
      const localFilter = JSON.stringify([]);
      localStorage.setItem('inventoryfilter', localFilter);
    } else {
      const stateName = state.replace(/[0-9]/g, '');
      let oldValue = false;
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state);
        filter.splice(index, 1);
      }
      if (type === 'select') {
        oldValue = this.state.filter.find((item) => item.column === stateName);
      } else {
        oldValue = this.state.filter.find((item) => item.column === stateName && item.value === text);
      }
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value) {
        filter.push({
          column: stateName, value: text, label, header,
        });
      }
      this.setState({
        filter,
        [state]: type === 'select' ? text : value,
      });
      const localFilter = JSON.stringify(filter);
      localStorage.setItem('inventoryfilter', localFilter);
    }
  }

  excelDownlaod = (type, fileName) => {
    // uploadAppFile = (e) => {
    //   const { files } = this.state;
    //   files.push(e.target.files[0]);
    //   this.setState({ files });
    // }
    this.props.excelDownload(type, fileName);
    this.setState({ excelMenu: null });
  }

  excelUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.props.excelUpload(e.target.files);
    }
    this.setState({ excelMenu: null });
  }

  handleExcelMenu = (e) => {
    this.setState({ excelMenu: e.currentTarget });
  }

  render() {
    const {
      vendors, filterDrawer, history, scopes, categories, departments, inventoryConfig, users,
    } = this.props;
    const {
      filter, tableData, compCurrency,
    } = this.state;
    const columns = [
      {
        name: 'statu',
        text: tra('Durum'),
        editable: false,
        align: 'center',
        sortable: true,
        filterable: true,
        customBody: (value) => (
          <div style={{
            width: 10,
            height: 10,
            borderRadius: '100%',
            background: value === 0 ? Colors.red : value === 1 ? Colors.yellow : Colors.green,
            margin: '0 auto',
          }}
          />
        ),
      },
      {
        name: 'id',
        text: 'ID',
        sortable: true,
        filterable: false,
        type: 'text',
        align: 'start',
      },
      {
        name: 'name',
        text: tra('Uygulama_Adi'),
        sortable: true,
        filterable: false,
        type: 'text',
        searchable: true,
        align: 'start',
      },
      // {
      //   name: 'desc',
      //   text: lang.aciklama,
      //   sortable: true,
      //   tooltip: true,
      // },
      {
        name: 'version',
        text: tra('Versiyon'),
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        searchable: true,
      },
      {
        name: 'vendor_id',
        text: tra('Uretici'),
        arrayPath: 'vendors',
        type: 'arrayPathWithId',
        sortable: true,
        filterable: true,
        align: 'start',
        searchable: true,
        customBody: (value) => (
          vendors.find((item) => item.id === value) && vendors.find((item) => item.id === value).name
        ),
      },
      {
        name: 'scope_id',
        text: tra('Kapsam'),
        sortable: true,
        arrayPath: 'scopes',
        type: 'arrayPathWithId',
        filterable: true,
        align: 'start',
        searchable: true,
        customBody: (value) => (
          scopes && scopes.length > 0 && scopes.find((item) => item.id === value) && scopes.find((item) => item.id === value).name
        ),
      },
      {
        name: 'func_id',
        text: tra('Business_Domain'),
        sortable: true,
        arrayPath: 'departments',
        type: 'arrayPathWithId',
        filterable: true,
        align: 'start',
        searchable: true,
        customBody: (value) => (
          departments && departments.length > 0 && departments.find((item) => item.id === value) && departments.find((item) => item.id === value).name
        ),
      },

      {
        name: 'cat_id',
        text: tra('Kategori'),
        sortable: true,
        filterable: true,
        arrayPath: 'categories',
        type: 'arrayPathWithId',
        align: 'start',
        customBody: (value) => (
          categories && categories.length > 0 && categories.find((item) => item.id === value) && categories.find((item) => item.id === value).name
        ),
      },
      {
        name: 'SysOwners',
        text: tra('Sistem_Sorumlulari'),
        arrayPath: 'SysOwners',
        type: 'array',
        filterable: true,
        align: 'start',
        searchable: true,
        customBody: (value) => {
          let str = '';
          for (let i = 0; i < value.length; ++i) {
            if (i === value.length - 1) {
              str += inventoryConfig.tableType === 'Completed' ? value[i].name : users.find((item) => item.id === value[i].id)?.name;
            } else {
              str += inventoryConfig.tableType === 'Completed' ? `${value[i].name},` : `${users.find((item) => item.id === value[i].id)?.name},`;
            }
          }
          return str;
        },
      },
      {
        name: 'BusinessOwners',
        text: tra('Is_Birimi_Sorumlulari'),
        arrayPath: 'BusinessOwners',
        type: 'array',
        filterable: true,
        align: 'start',
        searchable: true,
        customBody: (value) => {
          let str = '';
          for (let i = 0; i < value.length; ++i) {
            if (i === value.length - 1) {
              str += inventoryConfig.tableType === 'Completed' ? value[i].name : users.find((item) => item.id === value[i].id)?.name;
            } else {
              str += inventoryConfig.tableType === 'Completed' ? `${value[i].name},` : `${users.find((item) => item.id === value[i].id)?.name},`;
            }
          }
          return str;
        },
      },
      {
        name: 'Platform',
        text: `${tra('Platforms')}`,
        sortable: false,
        type: 'text',
        filterable: false,
        align: 'end',
        customBody: (value) => (
          <p>{value && value.name}</p>
        ),
      },
      {
        name: 'py',
        text: `PY.${tra('Run_Costs')} ${compCurrency}`,
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'end',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
      {
        name: 'total',
        text: `CY.${tra('Run_Costs')} ${compCurrency}`,
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'end',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
      {
        name: 'ny',
        text: `NY.${tra('Run_Costs')} ${compCurrency}`,
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'end',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
    ];
    if (inventoryConfig.tableType !== 'Completed') {
      columns.splice(1, 1);
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomDrawer
          anchor="left"
          open={filterDrawer}
          {...(!isWidthDown('sm', this.props.width)
            ? { variant: 'persistent' }
            : {
              onClose: () => this.props.setFilterDrawer(false),
              onOpen: () => this.props.setFilterDrawer(true),
            })}

        >
          <FilteredSidebar>
            <div className="title">
              {tra('Filtre')}
              {!isWidthDown('sm', this.props.width)
                && <CustomButton onClick={() => this.props.setFilterDrawer(false)} startIcon={<LeftArrow width={12} />}>{tra('Geri')}</CustomButton>}
            </div>
            <div className="filterContent">
              {
                columns.map((item, index) => (
                  item.filterable && (
                    item.type === 'select'
                      ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Input
                              type="select"
                              fullWidth
                              data={item.selectData}
                              value={this.state[item.name]}
                              onChange={(e) => this.changeFilter(item.name, true, e.target.value, 'select', e.target.value, item.text)}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )
                      : item.type === 'arrayPathWithId' ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                            {
                              tableData && [...new Set(tableData.map((items) => items[item.name]))].map((cost, count) => (
                                cost !== null && (
                                  <Input
                                    type="checkbox"
                                    title={this.props[item.arrayPath] && this.props[item.arrayPath].find((a) => a.id === cost)?.name}
                                    value={filter.find((a) => (a.column === item.name) && (a.value === cost)) !== undefined}
                                    onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, cost, 'checkbox', this.props[item.arrayPath].find((a) => a.id === cost)?.name, item.text)}
                                  />
                                )
                              ))
                            }
                          </AccordionDetails>
                        </Accordion>
                      )
                        : item.type === 'array'
                          ? (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  this.getArrFilters(item.name, item.arrayPath).map((arrFilter, count) => (
                                    <Input
                                      type="checkbox"
                                      title={`${arrFilter} (${this.getArrFilters(item.name, item.arrayPath, true, arrFilter)})`}
                                      value={filter.find((a) => a.value === arrFilter) !== undefined}
                                      onChange={(e) => this.changeFilter(`${item.arrayPath}.${item.name}${index + 1 * count + 1}`, e.target.checked, arrFilter, 'checkbox', arrFilter, item.text)}
                                    />
                                  ))
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                          : item.filterLocation
                            ? (
                              <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                  <p>{item.text}</p>
                                </AccordionSummary>
                                <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                  {
                                    tableData && [...new Set(tableData.map((items) => items[item.name]))].map((licence, count) => (
                                      <Input
                                        type="checkbox"
                                        title={`${this.props[item.filterLocation].find((f) => f.id === licence) && this.props[item.filterLocation].find((f) => f.id === licence).name} (${tableData.filter((fltr) => fltr[item.name] === licence).length})`}
                                        value={filter.find((a) => (a.column === item.name) && (a.value === licence)) !== undefined}
                                        onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', this.props[item.filterLocation].find((f) => f.id === licence).name, item.text)}
                                      />
                                    ))
                                  }
                                </AccordionDetails>
                              </Accordion>
                            )
                            : (
                              <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                  <p>{item.text}</p>
                                </AccordionSummary>
                                <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                  {
                                    tableData && [...new Set(tableData.map((items) => items[item.name]))].map((licence, count) => (
                                      licence !== null && (
                                        <Input
                                          type="checkbox"
                                          title={Statuses.find((it) => it.id === licence)?.name}
                                          value={filter.find((a) => (a.column === item.name) && (a.value === licence)) !== undefined}
                                          onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', Statuses.find((it) => it.id === licence)?.name, item.text)}
                                        />
                                      )
                                    ))
                                  }
                                </AccordionDetails>
                              </Accordion>
                            )
                  )
                ))
              }
            </div>
            <div className="filterDrawefooter">
              {tableData && <p><span>{`(${tableData.length})`}</span> {tra('Envanter_Sayisi')}</p>}
              <CustomButton onClick={() => this.props.setFilterDrawer(false)}>{tra('Uygula')}</CustomButton>
              <CustomButton transparent onClick={() => this.changeFilter(null, null, null, 'resetAll')}>{tra('Sifirla')}</CustomButton>
            </div>
          </FilteredSidebar>
        </CustomDrawer>
        <PageWrapper drawer={!isWidthDown('sm', this.props.width) && filterDrawer}>
          <PageContent>
            <div className="pageInner" style={{ paddingTop: 0 }}>
              <Grid container spacing={3} style={{ paddingTop: 0 }}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                  <TableWrapper style={{ minHeight: '60vh' }}>
                    {
                      tableData ? (
                        <Table
                          id="inventoryTable"
                          data={tableData}
                          rowClick={(row) => {
                            if (inventoryConfig.tableType === 'Completed') {
                              const obj = {
                                id: row.id,
                                tabOpen: false,
                              };
                              localStorage.setItem('costfrominventory', JSON.stringify(obj));
                              history.push(`/InventoryDetail/${row.id}`);
                              this.props.getApplicationDetail(row.id);
                            } else {
                              history.push(`/InventoryRegister/${row._id}`);
                              this.props.getApplicationDraft(row._id);
                            }
                          }}
                          columns={columns}
                          options={{
                            header: {
                              visible: true,
                              columnSetting: true,
                            },
                            filter: {
                              show: true,
                              filters: filter,
                              onChange: this.changeFilter,
                              drawerChange: () => this.props.setFilterDrawer(!filterDrawer),
                            },
                            pagination: {
                              active: true,
                            },
                          }}
                        />
                      ) : (
                        <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                      )
                    }
                  </TableWrapper>
                </Grid>
              </Grid>
            </div>
          </PageContent>
        </PageWrapper>
      </div>
    );
  }
}

Inventories.propTypes = {
  getApplications: PropTypes.func.isRequired,
  applications: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  scopes: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  getApplicationDetail: PropTypes.func.isRequired,
  filterDrawer: PropTypes.bool.isRequired,
  setFilterDrawer: PropTypes.func.isRequired,
  excelDownload: PropTypes.func.isRequired,
  history: PropTypes.string.isRequired,
  excelUpload: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  inventoryConfig: PropTypes.object.isRequired,
  drafts: PropTypes.array.isRequired,
  getApplicationDraft: PropTypes.func.isRequired,
  setInventoryConfig: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  applications: selectApplications(),
  departments: selectInfos('Departments'),
  locations: selectInfos('Locations'),
  platforms: selectInfos('Platforms'),
  vendors: selectInfos('Vendors'),
  scopes: selectInfos('Scopes'),
  categories: selectInfos('Categories'),
  users: selectUsers(),
  filterDrawer: selectFilterDrawer(),
  inventoryConfig: selectInventoryConfig(),
  drafts: selectApplicationDrafts(),
  currencies: selectInfos('Currencies'),
  user: selectActiveUser(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getApplications,
      getApplicationDetail,
      setFilterDrawer,
      excelDownload,
      excelUpload,
      getApplicationDraft,
      setInventoryConfig,
    }, dispatch)
  )
);

const InventoriesWithWidth = withWidth()(Inventories);

export default connect(mapStateToProps, mapDispatchToProps)(InventoriesWithWidth);

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { useSelector } from 'react-redux';
import { compose } from 'redux';
import Dashboard from '../pages/Dashboard';
import Inventories from '../pages/Inventory/Inventories';
import Admin from '../pages/Admin/Admin';
import Schedule from '../pages/Schedule';
import InventoryRegister from '../pages/Inventory/InventoryRegister';
import InventoryDetail from '../pages/Inventory/InventoryDetail';
import Notification from '../pages/Notification';
import Survey from '../pages/AdminSurvey/Survey';
import SurveyDetail from '../pages/AdminSurvey/SurveyDetail';
import SingleSurveyDetail from '../pages/AdminSurvey/SingleSurveyDetail';
import Surveys from '../pages/Surveys';
import SurveyAnswer from '../pages/SurveyAnswer';
// import Integration from '../pages/Integration';
// import Profil from '../pages/mobil/Profil';
import Profil from '../pages/Profil';
import ProfilSetting from '../pages/mobil/ProfilSetting';
import SurveyChartDetail from '../pages/AdminSurvey/SurveyChartDetail';
import SurveyDetailApps from '../pages/AdminSurvey/SurveyDetailApps';
import HardwareInventories from '../pages/Hardware/HardwareInventories';
import HardwareRegister from '../pages/Hardware/HardwareRegister';
import HardwareDetail from '../pages/Hardware/HardwareDetail';
import HardwareRegisterProvider from './context/HardwareContext';
import Costing from '../pages/Costing/Costing';
import CostingProvider from './context/CostingContext';
import CostingList from '../pages/Costing/CostingList';
import CostingDetail from '../pages/Costing/CostingDetail';
import InventoryProvider from './context/InventoryContext';
import Report from '../pages/Reports/Report';
import HWCostingProvider from './context/HWCostingContext';
import HWCosting from '../pages/Costing/HWCosting';
import HWCostingDetail from '../pages/Costing/HWCostingDetail';
import LicenseFollow from '../pages/LicenseFollow/LicenseFollow';
import LicenseFollowDetail from '../pages/LicenseFollow/LicenseFollowDetail';
import LicenseFollowRegister from '../pages/LicenseFollow/LicenseFollowRegister';
import { isUserModule } from './utils';
import moduleType from './enum/moduleType';
import NewIntegration from '../pages/Integration/Integration';

const Routes = ({ isAdmin, width }) => {
  const initModule = useSelector((s) => s?.activeUser?.modules?.filter((i) => i?.active).map((i) => i?.id)) ?? [];
  const activeNotFound = useSelector((s) => s?.activeUser?.modules ?? []);
  const isUserModuleFunc = useCallback((modulTypeArr) => isUserModule(modulTypeArr)(initModule), [initModule]);
  return (
    <div>
      <Switch>

        <Route path="/" exact component={Dashboard} />
        <Route path="/Profil" exact component={Profil} />
        <Route path="/Schedule" exact component={Schedule} />
        <Route path="/Schedule/:id" exact component={Schedule} />
        <Route path="/Notification" exact component={Notification} />
        <Route path="/Reports" exact component={Report} />

        {isWidthDown('md', width) && <Route path="/ProfilSetting" exact component={ProfilSetting} />}

        {/* HWA & SOFTWARE MODULE */}

        {
          (isUserModuleFunc([moduleType.SOFTWARE]) || isUserModuleFunc([moduleType.HARDWARE]))
          && [
            <Route path="/Reports" exact component={Report} />,
            <Route path="/Costing" exact component={CostingList} />,
          ]
        }

        {/* INTEGRATION MODULE */}

        {
          isUserModuleFunc([moduleType.INTEGRATION])
          && <Route path="/Integration" component={NewIntegration} />
        }

        {/* HARDWARE MODULE */}

        {
          isUserModuleFunc([moduleType.HARDWARE])
          && [
            <Route path="/HardwareInventories" exact component={HardwareInventories} />,
            <Route
              path="/HardwareRegister/:type/:unit"
              exact
              render={(routeProps) => (
                <HardwareRegisterProvider>
                  <HardwareRegister {...routeProps} />
                </HardwareRegisterProvider>
              )}
            />,
            <Route
              path="/HardwareDetail/:id"
              exact
              render={(routeProps) => (
                <HardwareRegisterProvider>
                  <HardwareDetail {...routeProps} />
                </HardwareRegisterProvider>
              )}
            />,
            <Route
              path="/CostingRegister/Hardware"
              exact
              render={(routeProps) => (
                <HWCostingProvider>
                  <HWCosting {...routeProps} />
                </HWCostingProvider>
              )}
            />,
            <Route
              path="/CostingRegister/Software"
              exact
              render={(routeProps) => (
                <CostingProvider>
                  <Costing {...routeProps} />
                </CostingProvider>
              )}
            />,
            <Route
              path="/CostingRegister/Hardware"
              exact
              render={(routeProps) => (
                <HWCostingProvider>
                  <HWCosting {...routeProps} />
                </HWCostingProvider>
              )}
            />,
            <Route
              path="/CostingDetail/Hardware/:id"
              exact
              render={(routeProps) => (
                <HWCostingProvider>
                  <HWCostingDetail {...routeProps} />
                </HWCostingProvider>
              )}
            />,
          ]
        }

        {/* SOFTWARE MODULE */}

        {
          isUserModuleFunc([moduleType.SOFTWARE])
          && [
            <Route path="/Inventories" exact component={Inventories} />,
            <Route
              path="/InventoryRegister/:type"
              exact
              render={(routeProps) => (
                <InventoryProvider>
                  <InventoryRegister {...routeProps} />
                </InventoryProvider>
              )}
            />,
            <Route
              path="/InventoryDetail/:id"
              exact
              render={(routeProps) => (
                <InventoryProvider detail>
                  <InventoryDetail {...routeProps} />
                </InventoryProvider>
              )}
            />,
            <Route
              path="/CostingDetail/Software/:id"
              exact
              render={(routeProps) => (
                <CostingProvider>
                  <CostingDetail {...routeProps} />
                </CostingProvider>
              )}
            />,
            <Route
              path="/CostingRegister/Software"
              exact
              render={(routeProps) => (
                <CostingProvider>
                  <Costing {...routeProps} />
                </CostingProvider>
              )}
            />,
            <Route path="/Surveys" exact component={Surveys} />,
            <Route path="/Surveys/:appId" exact component={Surveys} />,
            <Route path="/Surveys/SurveyAnswer" exact component={Surveys} />,
            <Route path="/Surveys/SurveyAnswer/:id/:appId" exact component={SurveyAnswer} />,
            <Route path="/Surveys/SurveyAnswer/:id/:appId/:isSelectedApp" exact component={SurveyAnswer} />,
            <Route path="/LicenseFollow" exact component={LicenseFollow} />,
            <Route
              path="/LicenseFollowRegister"
              exact
              component={LicenseFollowRegister}
            />,
            <Route
              path="/LicenseFollowDetail/:id"
              exact
              component={LicenseFollowDetail}
            />,
          ]
        }

        {/* ADMIN */}

        {isAdmin
          && [
            <Route path="/Admin" exact component={Admin} />,
          ]}

        {/* ADMIN && SOFWARE */}

        {
          isUserModuleFunc([moduleType.SOFTWARE]) && isAdmin
          && [<Route path="/Admin/Survey" exact component={Survey} />,
            <Route path="/Admin/SurveyUpdate" exact component={Survey} />,
            <Route path="/Admin/SurveyUpdate/:id" exact component={Survey} />,
            <Route path="/Admin/SurveyDetail/:id/:appId" exact component={SurveyDetail} />,
            <Route path="/Admin/SurveyDetailApps/:id" exact component={SurveyDetailApps} />,
            <Route path="/Admin/SurveyDetail/:id/:appId/SingleSurveyDetail/:userId" exact component={SingleSurveyDetail} />,
            <Route path="/Admin/SurveyChartDetail/:id/:appId" exact component={SurveyChartDetail} />]
        }
        {/* NOT FOUND */}
        <Route>
          {
            activeNotFound?.length > 0 && <Redirect to="/404" />
          }
        </Route>
      </Switch>
    </div>
  );
};

Routes.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(withWidth(), memo)(Routes);

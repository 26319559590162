import styled from 'styled-components';
import { TableRow, Collapse, TableCell } from '@material-ui/core';
import { Colors } from './statics';
import { hexToRgbA } from '../commons/utils';

const ExpandTable = styled.table`
  .MuiCollapse-hidden {
    max-height: 0px!important;
  }
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0px; 
  tr, td, th {
    border-spacing: 0px;
    color: ${(props) => props.theme.text1};
    font-size: 12px;
    transition: all 0.5s ease;
    border-bottom: 1px solid ${hexToRgbA(Colors.lightGrey400, 0.5)};
    border-top: 1px solid ${hexToRgbA(Colors.lightGrey400, 0.5)};
    :first-child {
      border-width: 1px 0px 1px 1px;
      border-style: solid;
      border-color: ${hexToRgbA(Colors.lightGrey400, 0.5)};
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    :last-child {
      border-width: 1px 1px 1px 0px;
      border-style: solid;
      border-color: ${hexToRgbA(Colors.lightGrey400, 0.5)};
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    a {
      color: ${(props) => props.theme.text3};
    }
  }
  th {
    user-select: none;
    background-color: ${hexToRgbA(Colors.blue, 0.1)};
    text-align: center;
    color: ${(props) => props.theme.text1};
    font-weight: 600;
    position: relative;
    border: none;
    :first-child, :last-child {
      border: none;
    }
    .sortable {
      position: absolute;
      right: 0;
      top: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(0, -50%);
      overflow: hidden;
      svg {
        color: ${Colors.lightGrey200};
      }
      svg:last-of-type {
        margin-top: -15px;
      }
    }
  }
  input {
    font-size: 12px;
  }
  .MuiInput-underline:after, .MuiInput-underline:before {
    display: flex!important;
  }
  .MuiCheckbox-root {
    color: ${Colors.green}!important;
  }
  h6 {
    display: none!important;
  }
`;

export const TableTh = styled.th`
  text-align: ${(props) => props.textAlign}!important;
  display: ${(props) => props.visible && 'none'};
  color: ${Colors.lightGrey200};
  font-weight: 400;
  padding: 10px;
`;

export const TableTr = styled(TableRow)`
  background: ${(props) => (props.fc ? 'rgba(33, 69, 212, 0.5)' : Colors.white)};
  :hover {
    > tr, td, th {
      border-bottom: 1px solid ${Colors.grey};
      border-top: 1px solid ${Colors.grey};
      :first-child {
        border-color: ${Colors.grey};
      }
      :last-child {
        border-color: ${Colors.grey};
      }
    }
  }
`;

export const TableTd = styled.td`
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  max-width: 100px;
  text-align: ${(props) => props.textAlign};
  display: ${(props) => props.visible && 'none'};
  width: ${(props) => props.cellWidth}px;
  ${(props) => props.activeFocus && `
    > background: ${props.theme.tableHoverTr};
    tr, td, th {
      border-bottom: 1px solid ${Colors.grey};
      border-top: 1px solid ${Colors.grey};
      :first-child {
        border-color: ${Colors.grey};
      }
      :last-child {
        border-color: ${Colors.grey};
      }
    }
  `}
`;

export const ExpandTableTh = styled.th`

`;

export const ExpandTableTr = styled.tr`
  background: ${Colors.white};
  :hover {
    tr, td, th {
      border-bottom: 1px solid ${Colors.grey};
      border-top: 1px solid ${Colors.grey};
      :first-child {
        border-color: ${Colors.grey};
      }
      :last-child {
        border-color: ${Colors.grey};
      }
    }
  }
`;

export const ExpandTableTd = styled.td`
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.cellWidth}px;
  text-align: ${(props) => props.textAlign};
  display: ${(props) => props.visible && 'none'};
  width: ${(props) => props.cellWidth}px;
  ${(props) => props.activeFocus && `
    background: ${props.theme.tableHoverTr};
    > tr, td, th {
      border-bottom: 1px solid ${Colors.grey};
      border-top: 1px solid ${Colors.grey};
      :first-child {
        border-color: ${Colors.grey};
      }
      :last-child {
        border-color: ${Colors.grey};
      }
    }
  `}
`;

export const ExpandableRow = styled(TableRow)`
  ${ExpandTableTr} {
    background: #E6ECF4!important;
  }
  border: none;
  background: #E6ECF4!important;
  > th {
    background: transparent;
    padding: 0px;
  }
`;

export const ExpandTableTdWithoutHead = styled(TableCell)`
  max-width: 100px;
  width: 100px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(props) => props.textAlign};
  display: ${(props) => props.visible && 'none'};
  ${(props) => props.activeFocus && `
    background: ${props.theme.tableHoverTr};
    tr, td, th {
      border-bottom: 1px solid ${Colors.grey};
      border-top: 1px solid ${Colors.grey};
      :first-child {
        border-color: ${Colors.grey};
      }
      :last-child {
        border-color: ${Colors.grey};
      }
    }
  `}
`;

export const CustomCollapse = styled(Collapse)`

`;

export default ExpandTable;

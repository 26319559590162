/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import React, {
  useContext, useMemo,
} from 'react';
import { HWCostingContext } from '../../commons/context/HWCostingContext';
import Input from '../Input';

const HWCostingFastInput = (props) => {
  const {
    HWCostingState, HWCostingPageVariables,
  } = useContext(HWCostingContext);

  const val = props.name ? props.stateType === 'page' ? HWCostingPageVariables[props.name] : HWCostingState[props.name] : props.value;

  return useMemo(() => (
    <Input
      {...props}
      data={props.data}
      error={props.error}
      helperText={props.helperText}
      fullWidth
      value={val}
      onChange={props.onChange}
      InputProps={{
        endAdornment: props.endAdornment,
        startAdornment: props.startAdornment,
      }}
    />
  ), [val, props.data, props.error, props.helperText, props.disabled, props.minDate, props.title, props.telCode]);
};

HWCostingFastInput.propTypes = {
};

export default HWCostingFastInput;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import { ThemeContext } from 'styled-components';
import { ChartCustomTooltip } from '../../assets/styledNew';
import { Colors } from '../../assets/statics';
import { tra } from '../../commons/utils';

const prepareData = (data) => {
  const temp = [];
  for (let i = 0; i < data.length; ++i) {
    if (parseInt(data[i].total_count, 10) !== 0) {
      const obj = {
        id: data[i].text,
        label: data[i].text,
        value: parseInt(data[i].total_count, 10),
        color: '#D7DDFF',
      };
      temp.push(obj);
    }
  }
  return temp;
};

// const calculatePercent = (value, data) => {
//   const percent = 100 * parseInt(value / , 10);
//   return percent;
// }

export default function SurveyPieChart({ data }) {
  const [maxVal, setMaxVal] = React.useState(0);
  const themeContext = useContext(ThemeContext);

  React.useEffect(() => {
    let max = 0;
    for (let i = 0; i < data.length; ++i) {
      max += parseInt(data[i].total_count, 10);
    }
    setMaxVal(max);
  }, [data]);

  return (
    prepareData(data).length > 0 ? (
      <ResponsivePie
        data={prepareData(data)}
        margin={{
          top: 40, right: 80, bottom: 80, left: 80,
        }}
        sliceLabel={(item) => `%${maxVal !== 0 && parseInt(100 * (item.value / maxVal), 10)}`}
        innerRadius={0.25}
        padAngle={5}
        colors={{ scheme: 'blues' }}
        tooltip={(p) => (
          <ChartCustomTooltip>
            <p>{p.datum.label}</p>
            <h4>{p.datum.value}</h4>
            <p>{tra('Kullanıcı')}</p>
          </ChartCustomTooltip>
        )}
        activeOuterRadiusOffset={8}
      // colors={(d) => d.data.color}
        enableRadialLabels={false}
        borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
        arcLinkLabelsColor={{ from: 'color' }}
        sliceLabelsTextColor={Colors.blue}
        legends={[]}
      />
    ) : (
      <h4 style={{ color: themeContext.text1 }}>{tra('Henuz_Bir_Cevap_Bulunmamaktadir')}</h4>
    )
  );
}

SurveyPieChart.propTypes = {
  data: PropTypes.object.isRequired,
};

// export default SurveyPieChart;

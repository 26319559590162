/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  ModalHeader,
  ModalContent,
  CustomModal,
  CustomButton,
  InputWrapper,
} from '../../assets/styledNew';
import {
  CalculatePercentage, CommaStringToNumber, tra,
} from '../../commons/utils';
import Input from '../Input';
import BottomSheet from '../BottomSheet';
import { selectHardwareDevices } from '../../store/selectors';
import { HWCostingContext } from '../../commons/context/HWCostingContext';

class AddHWToContract extends Component {
  static contextType = HWCostingContext;

  constructor(props) {
    super(props);
    this.state = {
      hardware: null,
      allocation: 0,
      value: 0,
    };
    this.validator = new SimpleReactValidator({
      locale: 'tr',
      autoForceUpdate: this,
      element: false,
      validators: {
        limitControl: {
          message: tra('Limit_Mesaji'),
          rule: (val, params) => {
            const commaParam = `${params[0]},${params[1]}`;
            return CommaStringToNumber(val) <= CommaStringToNumber(commaParam);
          },
        },
      },
    });
  }

  resetState = () => {
    this.setState({
      hardware: null,
      allocation: '',
      value: 0,
    });
    this.validator.hideMessages();
  }

  onChangeAutoComplete = (e, newValue) => {
    let { hardware } = this.state;
    if (newValue !== undefined && newValue !== '' && newValue !== null) {
      hardware = newValue;
      this.setState({ hardware });
    }
  }

  addHWToContract = () => {
    const {
      hardware, allocation, value,
    } = this.state;
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return false;
    }
    const val = CommaStringToNumber(value);
    this.props.addHardware(hardware, allocation, val);
    return true;
  }

  handleChange = (stateText) => (e) => {
    const { HWCostingState } = this.context;
    if (stateText === 'value') {
      const calculatedAlloc = CalculatePercentage(e.target.value, HWCostingState.total);
      this.setState({ allocation: calculatedAlloc, value: e.target.value });
    }
    if (stateText === 'allocation') {
      const val = CommaStringToNumber(e.target.value.replace('%', ''));
      const calculatedVal = (CommaStringToNumber(HWCostingState.total) * val) / 100;
      this.setState({ value: calculatedVal, allocation: e.target.value });
    }
  }

  compareArray = () => {
    const { hardwares, sendHardwares } = this.props;
    const temp = [];
    if (Array.isArray(hardwares)) {
      for (let i = 0; i < hardwares.length; ++i) {
        if (!sendHardwares.find((hw) => hw.inventory_id === hardwares[i].id)) {
          temp.push(hardwares[i]);
        }
      }
    }
    return temp;
  }

  contentPage = () => {
    const { close, type, compCurrency } = this.props;
    const {
      hardware, allocation, value,
    } = this.state;
    return (
      <DialogContent className="content">
        <ModalHeader>
          <h1>{tra('Donanim_Ekle')}</h1>
          <CloseIcon onClick={close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <InputWrapper>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    multiple={false}
                    data={this.compareArray()}
                    title={tra('Donanim_Adi')}
                    placeholder={`${tra('Donanim_ara_ve_ekle')}: SAP BW on Hana`}
                    value={hardware}
                    onChange={(e, newValue) => this.onChangeAutoComplete(e, newValue)}
                    labelKey="model"
                    error={this.validator.message('hardware', hardware, 'required')}
                    helperText={this.validator.message('hardware', hardware, 'required')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    title={tra('Allocation')}
                    type="currency"
                    suffix="%"
                    value={allocation}
                    decimalScale={3}
                    onChange={this.handleChange('allocation')}
                    disabled={type === 1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type="currency"
                    title={`${tra('Allocation_In')} ${compCurrency}`}
                    value={value}
                    onChange={this.handleChange('value')}
                    disabled={type === 2}
                  />
                </Grid>
                <Grid container justify="flex-end" item xs={12}>
                  <CustomButton onClick={() => this.addHWToContract()}>{tra('Ekle')}</CustomButton>
                </Grid>
              </Grid>
            </InputWrapper>
          </div>
        </ModalContent>
      </DialogContent>
    );
  }

  displayDesktop = () => <CustomModal onExit={() => this.resetState()} open={this.props.open} onClose={this.props.close} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AddHWToContract.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  addHardware: PropTypes.func.isRequired,
  hardwares: PropTypes.array.isRequired,
  sendHardwares: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  compCurrency: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hardwares: selectHardwareDevices(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
    }, dispatch)
  )
);

const AddHWToContractWithTheme = withTheme(AddHWToContract);
const AddHWToContractWithWidth = withWidth()(AddHWToContractWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AddHWToContractWithWidth);

import React, { useState } from 'react';
import { PersonAdd } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Plus } from '../../assets/icons';
import { CustomButton, PeopleManagementTabPage, TableWrapper } from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import AddUserToCompany from '../../components/modals/AddUserToCompany';
import Table from '../../components/Table';
import { addCompanyModal, getAdminCompanyUsers, getCompanyDepartments } from '../../store/actions';
import { Colors } from '../../assets/statics';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 19px ${Colors.shadow};`,
    fontSize: 12,
    color: Colors.darkBlue,
    fontWeight: 600,
    padding: 15,
    fontFamily: 'Inter',
  },
  arrow: {
    color: Colors.white,
  },
}))(Tooltip);

const ManageCompany = () => {
  const [addUserModal, setAddUserModal] = useState(null);
  const [selectedComp, setSelectedComp] = useState(null);

  const adminCompanies = useSelector((state) => state.adminCompanies);
  const currencies = useSelector((state) => state.infos.Currencies);
  const languages = useSelector((state) => state.infos.Languages);
  const dispatch = useDispatch();

  const handleGetAdminCompanyUsers = (id) => {
    dispatch(getAdminCompanyUsers(id));
    dispatch(getCompanyDepartments(id));
    setAddUserModal(true);
    setSelectedComp(id);
  };

  const companyColumns = [
    {
      name: 'company_image',
      text: 'Logo',
      editable: false,
      align: 'center',
      customBody: (value) => (
        <img src={value} alt="cmpLogo" style={{ width: 40, borderRadius: '50%' }} />
      ),
    },
    {
      name: 'name',
      text: tra('Adi'),
      editable: false,
      align: 'center',
      searchable: true,
    },
    {
      name: 'owner_name',
      text: tra('Yaratan'),
      editable: false,
      align: 'center',
      searchable: true,
    },
    {
      name: 'currency_id',
      text: tra('Para_Birimi'),
      editable: false,
      align: 'center',
      customBody: (value) => (
        <p>{currencies.find((item) => item.id === value) && currencies.find((item) => item.id === value).name}</p>
      ),
    },
    {
      name: 'language',
      text: tra('Dil'),
      align: 'center',
      editable: false,
      customBody: (value) => (
        <p>{languages.find((item) => item.id === value)?.language}</p>
      ),
    },
    {
      name: 'id',
      text: tra('Kullanici_Ekle'),
      editable: false,
      align: 'center',
      customBody: (value) => (
        <div style={{ cursor: 'pointer' }}>
          <LightTooltip title={tra('Premium_Mesaji')}>
            <span>
              <IconButton disabled>
                <PersonAdd color="green" onClick={() => handleGetAdminCompanyUsers(value)} />
              </IconButton>
            </span>
          </LightTooltip>
        </div>
      ),
    },
  ];

  return (
    <PeopleManagementTabPage>
      <TableWrapper>
        <div className="header">
          <h4>{tra('Sirket_Islemleri')}</h4>
        </div>
        <>
          <Table
            data={adminCompanies}
            columns={companyColumns}
            options={{
              header: {
                visible: true,
                search: true,
              },
              pagination: {
                active: true,
                perPage: 5,
                position: 'left',
              },
              editable: false,
            }}
          />
          <div className="buttonWrapper">
            <LightTooltip title={tra('Premium_Mesaji')}>
              <span>
                <CustomButton
                  disabled
                  onClick={() => dispatch(addCompanyModal(true))}
                >
                  <Plus width={14} /> {tra('Şirket_Ekle')}
                </CustomButton>
              </span>
            </LightTooltip>
          </div>
        </>
      </TableWrapper>
      <AddUserToCompany
        open={addUserModal}
        close={() => setAddUserModal(false)}
        compId={selectedComp}
      />
    </PeopleManagementTabPage>
  );
};

ManageCompany.propTypes = {
};

export default ManageCompany;

import {
  call,
  take,
  all,
  put,
  select,
} from 'redux-saga/effects';
import { io } from 'socket.io-client';
import moment from 'moment';
import {
  request,
  filesRequest,
  fileDownload,
  tra,
} from '../commons/utils';
import * as url from '../commons/constants';
import * as constants from './constants';
import * as actions from './actions';
import { selectNotifications } from './selectors';
import { callEffect } from './helper/callEffect';
import sagaWatcherType from '../commons/enum/sagaWatcherType';

function* userInit() {
  const result = yield call(request, url.USER_INIT, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLoginState(false));
      yield put(actions.setActiveUser(result.data));
      const socket = io(url.SOCKET_URL);
      socket.on('connect', () => {
        socket.emit('authorization', { token: localStorage.getItem('token') });
      });
      yield put(actions.setSocket(socket));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setLoginState(true));
  }
  return result;
}

function* getUsers() {
  const result = yield call(request, url.USERS, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setUsers(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getInfo() {
  const result = yield call(request, url.INFO, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setInfo(result.data));
      yield call(getUsers);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getInfoHardware() {
  const result = yield call(request, `${url.INFO}/hardware`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setInfo(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getInfoMonitoring() {
  const result = yield call(request, `${url.INFO}/montioring`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setInfo(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getDashboard() {
  const result = yield call(request, url.DASHBOARD, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboard(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getCostGraph(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.DASHBOARD}/costGraph`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCostGraph(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getAdditionalCost(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.DASHBOARD}/additionalCost`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAdditionalCost(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getVendorProcessMatrix(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.DASHBOARD}/vendorProcessMatrix`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setVendorProcessMatrix(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  }
  return result;
}

function* getCalendar() {
  // const jsonStr = JSON.stringify(dates);
  // const result = yield call(request, url.CALENDAR, 'POST', jsonStr, true);
  // if (result !== 'invalid') {
  //   if (result.status) {
  //     yield put(actions.setCalendar(result.data));
  //   } else {
  //     yield put(actions.setToast(true, result.message, 'error'));
  //   }
  // }
  // return result;
}

function* getTimeModel() {
  const result = yield call(request, url.TIME_MODEL, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setTimeModel(result.data));
    }
  }
  return result;
}

function* getCostingCategories() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COSTING}/categories`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCostingCategories(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHWCostingCategories() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.HWCOSTING}/categories`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHWCostingCategories(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getCosting(sendToView) {
  yield put(actions.setLoading(true));
  const result = yield call(request, url.COSTING, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCosting(result.data));
      if (sendToView) {
        yield put(actions.setSendToView(true));
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getApplications(sendToView) {
  yield put(actions.setLoading(true));
  const result = yield call(request, url.APPLICATIONS, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplications(result.data));
      if (sendToView) {
        yield put(actions.setSendToView(true));
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getAppCostReport(year) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.DASHBOARD}/appCost/${year}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAppCostReport(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getPerBusinessCapability() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.DASHBOARD}/perBusinessCapability`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setPerBusinessCapability(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getPerITDomain() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.DASHBOARD}/perItDomain`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setPerITDomain(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getPerBusinessDomain() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.DASHBOARD}/perBusinessDomain`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setPerBusinessDomain(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getDashboardCostingDetail() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.DASHBOARD}/mainTotal`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardCostingDetail(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getDashboardMapReport() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.DASHBOARD}/perLocationApp`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardMapReport(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* startRequiresServices() {
  yield put(actions.setInıtLoading(true));
  const company = localStorage.getItem('company');
  const token = localStorage.getItem('token');
  if (token) {
    yield call(userInit);
    if (company) {
      yield all([
        call(getInfo),
        call(getUsers),
        call(getAppCostReport, new Date().getFullYear()),
        call(getPerITDomain),
        call(getPerBusinessCapability),
        call(getPerBusinessDomain),
        call(getDashboard),
        call(getTimeModel),
        call(getApplications),
        call(getCosting),
        call(getCostingCategories),
        call(getHWCostingCategories),
        call(getCostGraph, { minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` }),
        call(getAdditionalCost, { minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` }),
        call(getDashboardCostingDetail),
        call(getDashboardMapReport),
      ]);

      // yield call(getVendorProcessMatrix, { minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` });
      // yield call(getCalendar, { minDate: `${new Date().getFullYear()}-01-01`, maxDate: `${new Date().getFullYear()}-12-31` });
    }
  }
  yield put(actions.setInıtLoading(false));
  // return result;
}

function* doLogin(email, password) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify({ name: email, password });
  const result = yield call(request, url.LOGIN, 'POST', jsonStr, false);
  if (result !== 'invalid') {
    if (result.status) {
      localStorage.setItem('token', result.data.token);
      yield put(actions.setLoginState(false));
      yield put(actions.setActiveUser(result.data));
      const selectedCompany = localStorage.getItem('company');
      if (result.data.companies) {
        if (result.data.companies.length > 0) {
          localStorage.setItem('company', result.data.companies[0].id);
          yield call(startRequiresServices);
        } else {
          const isCompanyExist = result.data.companies.some((c) => c.id.toString() === selectedCompany);
          if (!isCompanyExist) {
            localStorage.removeItem('company');
          } else {
            yield call(startRequiresServices);
          }
        }
      }
      yield put(actions.setLoading(false));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
      yield put(actions.setLoginState(true));
    }
  } else {
    yield put(actions.setLoginState(true));
    yield put(actions.setLoading(false));
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* companyRegister(params) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(params);
  const result = yield call(request, url.COMPANY_REGISTER, 'POST', jsonStr, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setRegisterComplete(true));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createInfo(header, data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const newUrl = `${url.CREATE_INFO}/${header}`;
  const result = yield call(request, newUrl, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getInfo);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateInfo(id, header, data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.CREATE_INFO}/${header}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getInfo);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* changeInfoStatu(header, id) {
  yield put(actions.setLoading(true));
  const newUrl = `${url.CHANGE_INFO_STATU}/${header}/${id}`;
  const result = yield call(request, newUrl, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getInfo);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* forgotPassword(email) {
  yield put(actions.setLoading(true));
  const userInfoJson = JSON.stringify({ email });
  const result = yield call(request, `${url.FORGOT_PASSWORD}`, 'POST', userInfoJson);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* resetPasword(password) {
  yield put(actions.setLoading(true));
  const userInfoJson = JSON.stringify({ password });
  const result = yield call(request, url.RESET_PASSWORD, 'POST', userInfoJson, true);
  localStorage.removeItem('token');
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setPasswordResetComplete(true));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getApplicationDrafts() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.APPLICATIONS}/draft`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplicationDrafts(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createApplication(data, createType, id, sendToView) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, createType === 'draft' ? `${url.APPLICATIONS}/draft` : `${url.APPLICATIONS}/${id || 0}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSendToDetailPageID(result.data.id));
      yield put(actions.getApplications(sendToView));
      yield put(actions.setToast(true, createType === 'draft' ? tra('Application_Save_Draft_Message') : result.message, 'success'));
      yield put(actions.setResetState(true));
      yield put(actions.getInfo());
      yield put(actions.getTimeModel());
      yield put(actions.getDashboard());
      yield put(actions.setInventoryRegisterFiles([]));
      yield put(actions.setInventoryRegisterFileUrls([]));
      if (createType === 'draft' || id) {
        window.location.href = '#/Inventories';
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteApplication(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_APPLICATION}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplications());
      window.location.href = '#/Inventories';
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createAppWithFile(data, files, createType, sendToView) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, createType === 'draft' ? `${url.APPLICATIONS}/draftFile` : `${url.APPLICATIONS}/withFile`, 'POST', 'file', files, data);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSendToDetailPageID(result.data.id));
      yield put(actions.getApplications(sendToView));
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setResetState(true));
      yield put(actions.getDashboard());
      // yield put(actions.getInfo());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateApplication(data, appId) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.APPLICATIONS}/${appId}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplicationDetail(appId));
      yield put(actions.getApplicationLog(appId));
      yield put(actions.getInfo());
      yield put(actions.getDashboard());
      yield put(actions.getTimeModel());
      yield put(actions.getApplications());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateApplicationDraft(data, id) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.APPLICATIONS}/draft/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getInfo());
      yield put(actions.getDashboard());
      yield put(actions.getTimeModel());
      yield put(actions.getApplicationDrafts());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* archiveApplication(data, appId) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.APPLICATIONS}/delete/${appId}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplications());
      yield put(actions.getApplicationLog(appId));
      yield put(actions.getDashboard());
      window.location.href = '#/Inventories';
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* unarchiveApplication(appId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.APPLICATIONS}/active/${appId}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplicationDetail(appId));
      yield put(actions.getApplicationLog(appId));
      yield put(actions.getDashboard());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* changeAppStatus(id, data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.APPLICATIONS}/statu/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplicationDetail(id));
      yield put(actions.getApplicationLog(id));
      yield put(actions.getDashboard());
      yield put(actions.getApplications());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getApplicationDetail(id) {
  yield put(actions.setApplicationDetail(null));
  const result = yield call(request, `${url.APPLICATIONS}/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getApplicationLifecycle(id));
      yield put(actions.setApplicationDetail(result.data));
    } else {
      yield put(actions.setApplicationDetail({}));
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    // yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/404';
  }
  return result;
}

function* getApplicationLifecycle(id) {
  const result = yield call(request, `${url.APPLICATIONS}/lifeCycle/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplicationLifecycle(result.data));
    } else {
      yield put(actions.setApplicationLifecycle({}));
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    // yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/404';
  }
  return result;
}

function* getApplicationDraft(id) {
  const result = yield call(request, `${url.APPLICATIONS}/draft/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplicationDraft(result.data));
    } else {
      yield put(actions.setApplicationDraft({}));
      window.location.href = '#/404';
    }
  } else {
    // yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/404';
  }
  return result;
}

function* usersOperation(data, id, isRemove) {
  yield put(actions.setLoading(true));
  let newUrl = url.ADMIN_USER;
  if (id) {
    newUrl = `${url.ADMIN_USER}/${id}`;
  } else if (id && isRemove) {
    newUrl = `${url.ADMIN_USER}/removed/${id}`;
  }
  const tData = data;
  delete tData.id;
  const jsonStr = JSON.stringify(tData);
  const result = yield call(request, newUrl, id ? 'PUT' : 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getUsers());
      yield put(actions.setResetState(true));
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getApplicationLog(id) {
  const newUrl = `${url.APPLICATIONS}/logs/${id}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplicationLog(result.data));
    }
  }
  return result;
}

function* getComments(id) {
  const newUrl = `${url.COMMENTS}/${id}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setComments(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* doComment(id, data) {
  const newUrl = `${url.COMMENTS}/${id}`;
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, newUrl, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getComments(id));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
}

/**
 * Profil bilgilerini getirir
 */

function* getProfileInfo() {
  const newUrl = `${url.PROFIL}/getProfileInfo`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setProfileInfo(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getProfileHardware() {
  const newUrl = `${url.PROFIL}/hardware`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setProfileHardware(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getProfileApplication() {
  const newUrl = `${url.PROFIL}/applications`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setProfileApplication(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getProfileSurvey() {
  const newUrl = `${url.PROFIL}/survey`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setProfileSurvey(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
}

/**
 *
 * @param {number} id 1:hardware 2: Application 3: Costing
 *
 */
function* getProfileLog(id) {
  yield put(actions.setLoading(true));
  const newUrl = `${url.PROFIL}/logs/${id}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setProfileLog(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* profileInit() {
  yield put(actions.setLoading(true));
  yield call(getProfileInfo);
  yield call(getProfileHardware);
  yield call(getProfileApplication);
  yield call(getProfileSurvey);
  yield put(actions.setLoading(false));
  yield call(getProfileLog, 1);
}

/*
*  //Profil bilgilerini getirir
*/

function* uploadProfile(id, data) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.UPLOAD}/profile`, 'POST', 'file', [data]);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getProfileInfo);
      yield call(userInit);
      yield call(getUsers);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* uploadAppFile(data, id) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.UPLOAD}/applicationFile/${id}`, 'POST', 'file', data);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getApplicationDetail, id);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* uploadAppDraftFile(data, id) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.UPLOAD}/draftFile/${id}`, 'POST', 'file', data);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getApplicationDraft, id);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* excelDownload(excelType, fileName) {
  return yield call(fileDownload, `${url.EXCEL}${excelType}`, 'GET', false, fileName);
}

function* excelUpload(files, excelType) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, excelType ? `${url.EXCEL}/${excelType}` : `${url.EXCEL}`, 'POST', 'file', files, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getApplications);
    } else {
      const errorList = result.message.split(',');
      yield put(actions.setExcelErrorList(errorList));
      yield put(actions.setExcelErrorModal(true));
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* adminExcelDownload(excelType, fileName) {
  return yield call(fileDownload, `${url.ADMIN_EXCEL}${excelType}`, 'GET', false, fileName);
}

function* adminExcelUpload(files, excelType) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, excelType ? `${url.ADMIN_EXCEL}/${excelType}` : `${url.EXCEL}`, 'POST', 'file', files, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      if (excelType === '/user') {
        yield put(actions.getUsers());
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getEvents() {
  // yield put(actions.setLoading(true));
  // const jsonStr = JSON.stringify(dates);
  // const result = yield call(request, url.EVENT, 'POST', jsonStr, true);
  // if (result !== 'invalid') {
  //   if (result.status) {
  //     yield put(actions.setEvents(result.data, new Date(dates.minDate).getFullYear()));
  //   } else {
  //     yield put(actions.setToast(true, result.message, 'error'));
  //   }
  // } else {
  //   yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  // }
  // yield put(actions.setLoading(false));
  // return result;
}

function* createEvent(params) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(params);
  const result = yield call(request, `${url.EVENT}/event`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setEventCreate(result.data));
      yield call(getEvents, { minDate: `${params.date.getFullYear()}-01-01`, maxDate: `${params.date.getFullYear()}-12-31` });
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createUser(data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, url.ADMIN_USER, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getUsers);
      yield put(actions.setResetState(true));
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* userStatuChange(id, isForce) {
  yield put(actions.setLoading(true));
  const newUrl = `${url.CHANGE_USER_STATU}/${id}/${isForce ? 1 : 0}`;
  const result = yield call(request, newUrl, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setAppConfig('userSureModal', { open: false, text: '' }));
      yield call(getUsers);
    } else if (result.isOwner) {
      yield put(actions.setAppConfig('userSureModal', { open: true, text: result.message }));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createSurvey(data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, url.SURVEY, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getAssignedSurveys());
      window.location.href = '#/Admin';
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getSurveyUsers() {
  const result = yield call(request, `${url.SURVEY}/users`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSurveyUsers(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getSurveyList() {
  const result = yield call(request, `${url.SURVEY}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSurveyList(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getSurveyDetail(id, appId) {
  const result = yield call(request, `${url.SURVEY}/${id}/${appId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSurveyDetail(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/Admin';
  }
  return result;
}

function* getSingleSurveyDetail(userId, id, appId) {
  const result = yield call(request, `${url.SURVEY}/user/${id}/${userId}/${appId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSingleSurveyDetail(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getSurveyDetailApps(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.SURVEY_REPORT}/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSurveyDetailApps(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getSurveyReportQuestions(id, appId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.SURVEY_REPORT}/App/${id}/${appId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSurveyReportQuestions(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getAnswerOptionUsers(id, appId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.SURVEY_REPORT}/Option/${id}/${appId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAnswerOptionUsers(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* readNotification(id) {
  const result = yield call(request, `${url.NOTIFICATION}/${id}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      const notifications = yield select(selectNotifications());
      const index = notifications.findIndex((n) => n.id === id);
      notifications[index].read = 1;
      yield put(actions.setNotifications(notifications));
    }
  }
  return result;
}

function* readAllNotifications() {
  const result = yield call(request, `${url.NOTIFICATION}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      const notifications = yield select(selectNotifications());
      for (let i = 0; i < notifications.length; i++) {
        notifications[i].read = 1;
      }
      // const notReadedNotifications = notifications.filter((item) => item.read === 0);
      // for (let i = 0; i < notReadedNotifications.length; ++i) {
      //   notReadedNotifications[i].read = 1;
      // }
      yield put(actions.setNotifications(notifications));
    }
  }
  return result;
}

function* getAssignedSurveys() {
  const result = yield call(request, `${url.PRIVATE_SURVEY}/assignedSurveys`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAssignedSurveys(result.data));
    }
  }
  return result;
}

function* getSurveyQuestions(id, appId) {
  const result = yield call(request, `${url.PRIVATE_SURVEY}/questions/${id}/${appId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSurveyQuestions(result.data));
    }
  } else {
    window.location.href = '#/Surveys';
  }
  return result;
}

function* answerSurvey(data, id, complete, saveType) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.PRIVATE_SURVEY}/answers/${id}/${complete}`, saveType ? 'PUT' : 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getAssignedSurveys());
      window.location.href = '#/Surveys';
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getSingleSurvey(id) {
  const result = yield call(request, `${url.SURVEY}/info/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSingleSurvey(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* endSurvey(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.SURVEY}/deactivate/${id}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getSurveyList());
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateSurvey(id, data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.SURVEY}/draftToRecord/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getAssignedSurveys());
      window.location.href = '#/Admin';
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* followApplication(id, followType) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.APPLICATIONS}/follow/${followType}/${id}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplicationDetail(id));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* changePassword(data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.PASSWORD}/change`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setResetState(true));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteFile(fileId, appId, fileType) {
  yield put(actions.setLoading(true));
  const result = yield call(request, fileType ? (fileType === 'contract' ? `${url.UPLOAD}/${fileType}/${appId}/${fileId}` : `${url.UPLOAD}/${fileType}/${fileId}`) : `${url.UPLOAD}/${fileId}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      switch (fileType) {
        case 'integration':
          yield put(actions.getIntegrationComponentsDetailUser(appId));
          break;
        case 'contract':
          yield put(actions.getCostingDetail(appId));
          break;
        case 'draft':
          yield put(actions.getApplicationDraft(appId));
          break;
        default:
          yield put(actions.getApplicationDetail(appId));
          break;
      }
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* companyLogoUpload(file) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.COMPANY}/logo`, 'POST', 'file', [file], false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(userInit);
      yield call(getUsers);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* companyUpdate(data) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COMPANY}/`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getAdminCompanies());
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createCompany(data) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COMPANY}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.userInit());
      yield put(actions.getAdminCompanies());
      yield put(actions.setResetState(true));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getAdminCompanies() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COMPANY}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAdminCompanies(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getAdminCompanyUsers(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_USER}/all/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAdminCompanyUsers(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* addUserToCompany(data, id) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_USER}/add/${id}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getAdminCompanyUsers(id));
      yield put(actions.getCompanyDepartments(id));
      yield put(actions.getUsers());
      yield put(actions.setResetState(true));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getCompanyDepartments(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COMPANY}/departments/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCompanyDepartments(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getIntegrationComponents(payload) {
  const emptyData = { senders: [], receivers: [] };
  const jsonStr = JSON.stringify(payload ?? emptyData);
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  if (companyPiUrl > 0) {
    const result = yield call(request, `${url.INTEGRATION}/components/${companyPiUrl}`, 'POST', jsonStr, true, false);
    if (result !== 'invalid') {
      if (result.status) {
        if (!payload) {
          yield put(actions.setIntegrationComponentInitData(result?.data?.components ?? []));
        }
        yield put(actions.setIntegrationComponents(result.data));
      }
    } else {
      yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    }
    return result;
  }
  // eslint-disable-next-line no-use-before-define
  return yield call(getIntegrationPiUrl, false, -1, getIntegrationComponents, payload);
}

function* getIntegrationComponentsDetailUser(id) {
  const result = yield call(request, `${url.INTEGRATION}/detail/${id}`, 'GET', false, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setIntegrationComponentsDetailUser(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getIntegrationComponentsDetail(id) {
  const result = yield call(request, `${url.INTEGRATION_COMPONENTS}/detail/${id}`, 'GET', false, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setIntegrationComponentsDetail(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* saveIntegrationComponentsDetailUser(id, data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.INTEGRATION}/${id}`, 'PUT', jsonStr, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getIntegrationComponentsDetailUser, id);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* uploadIntegrationFile(file, id) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.UPLOAD}/integration/${id}`, 'POST', 'file', file);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getIntegrationComponentsDetailUser, id);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createLdapUser(data) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LDAP}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.userInit());
      yield put(actions.setResetState(true));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getLdapUsers(value) {
  const jsonStr = JSON.stringify({ name: value });
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LDAP}/searchUser`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLdapUsers(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getIntegration() {
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  if (companyPiUrl > 0) {
    const result = yield call(request, `${url.INTEGRATION}/${companyPiUrl}`, 'GET', false, true, false);
    if (result !== 'invalid') {
      if (result.status) {
        yield put(actions.setIntegration(result.data));
      } else {
        yield put(actions.setToast(true, result.message, 'warning'));
      }
    } else {
      yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    }
    return result;
  }
  // eslint-disable-next-line no-use-before-define
  return yield call(getIntegrationPiUrl, false, -1, getIntegration);
}

function* createIntegration(data) {
  // yield put(actions.setLoading(true));
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  const jsonStr = JSON.stringify({ ...data, pi_url_id: companyPiUrl });
  const result = yield call(request, url.INTEGRATION, 'POST', jsonStr, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
      yield call(getIntegration);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  // yield put(actions.setLoading(false));
  return result;
}

function* getIntegrationExcel() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.INTEGRATION}/Excel`, 'GET', false, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getIntegrationExcelSample() {
  const result = yield call(request, `${url.INTEGRATION}/sample`, 'GET', false, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* uploadIntegrationExcel(file) {
  const result = yield call(filesRequest, `${url.INTEGRATION}/Excel`, 'POST', 'file', file, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
      yield put(actions.getIntegrationComponents());
      yield put(actions.getIntegration());
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getRegCompInit() {
  yield put(actions.setLoading(true));
  const result = yield call(request, url.COMPANY_INIT, 'GET', false, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setRegAccountTypes(result.data.accountTypes));
      yield put(actions.setRegCurrencies(result.data.currencies));
      yield put(actions.setRegLanguages(result.data.languages));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setLoginState(true));
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* companyActivation(params) {
  const jsonStr = JSON.stringify(params);
  const result = yield call(request, url.COMPANY_ACTIVATION, 'POST', jsonStr, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
      yield put(actions.setActivationComplete(true));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* resendActivationCode(params) {
  const jsonStr = JSON.stringify(params);
  const result = yield call(request, url.RESEND_ACTIVATION_CODE, 'POST', jsonStr, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* downloadInventoryData(params) {
  const jsonStr = JSON.stringify(params);
  return yield call(fileDownload, `${url.DOWNLOAD_INVENTORY}`, 'POST', jsonStr, params.dataType === 0 ? tra('Uygulamalar') : params.dataType === 1 ? tra('Lisanslar') : tra('Loglar'));
}

function* createHardware(data, hardwareType, sendToView) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);

  const result = yield call(request, `${url.HARDWARE}/${hardwareType}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setResetState(true));
      if (sendToView && 'id' in result.data) {
        yield put(actions.setSendToDetailPageID(result.data.id));
        yield put(actions.setSendToView(true));
      } else {
        window.location.href = '#/HardwareInventories';
      }
      // yield put(actions.getInfo());
      // yield put(actions.getTimeModel());
      // yield put(actions.getDashboard());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createCosting(data, files, sendToView) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  let result;
  if (files.length > 0) {
    result = yield call(filesRequest, `${url.COSTING}/1`, 'POST', 'file', files, data);
  } else {
    result = yield call(request, `${url.COSTING}/0`, 'POST', jsonStr, true);
  }
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSendToDetailPageID(result.data.id));
      yield put(actions.getCosting(sendToView));
      yield put(actions.setToast(true, result.message, 'Success'));
      yield put(actions.setResetState(true));
      yield put(actions.setCostingFiles([]));
      yield put(actions.getContractYears());
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateCosting(data, costId) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.COSTING}/${costId}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getCostingDetail(costId));
      // yield put(actions.getApplicationLog(costId));
      yield put(actions.getInfo());
      yield put(actions.getDashboard());
      yield put(actions.getTimeModel());
      yield put(actions.getCosting());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteCosting(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COSTING}/delete/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getCosting());
      window.location.href = '#/Costing';
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHardwareModalTypes() {
  const result = yield call(request, `${url.HARDWARE}/types`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHardwareModalTypes(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getCostingLogs(costId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.COSTING}/log/${costId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCostingLogs(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHWCostingLogs(costId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.HWCOSTING}/log/${costId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHWCostingLogs(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getApplicationContracts(appId, year) {
  const result = yield call(request, `${url.APPLICATIONS}/contracts/${appId}/${year}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplicationContracts(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getContractYears() {
  // 0 atınca software 1 atınca hardware
  const result = yield call(request, `${url.INFO}/years/0`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setContractYears(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getHardwareModalCategory(id) {
  const result = yield call(request, `${url.HARDWARE}/categories/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHardwareModalCategory(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHardwareDevices() {
  yield put(actions.setLoading(true));
  const result = yield call(request, url.HARDWARE, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHardwareDevices(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getCostingDetail(id) {
  yield put(actions.setCostingDetail(null));
  const result = yield call(request, `${url.COSTING}/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCostingDetail(result.data));
      yield put(actions.getCostingLogs(id));
    } else {
      yield put(actions.setCostingDetail(null));
      yield put(actions.setToast(true, result.message, 'error'));
      window.location.href = '#/404';
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/404';
  }
  return result;
}

function* getHardwareDeviceDetail(id) {
  const result = yield call(request, `${url.HARDWARE}/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status
      && Array.isArray(result.data) ? result.data.length > 2 : Object.keys(result.data).length > 1) {
      window.location.href = '#/404';
    } else if (result.status) {
      yield put(actions.setLoading(true));
      yield put(actions.setHardwareDeviceDetail(Object.assign({}, ...result.data)));
      yield put(actions.getHardwareLogs(Number(Object.assign({}, ...result.data).hardware_type), id));
      yield put(actions.setLoading(false));
    }
  } else {
    // yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/404';
  }
  return result;
}

function* getCostGroup(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.APPLICATIONS}/costGroup/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCostGroup(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* uploadCostFile(data, id) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.UPLOAD}/contract/${id}`, 'POST', 'file', data);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getCostingDetail, id);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateHardware(params) {
  const { data, hardwareType, id } = params;
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.HARDWARE}/${hardwareType}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getHardwareDeviceDetail(id));
      yield put(actions.setResetState(true));
      // yield put(actions.getInfo());
      // yield put(actions.getTimeModel());
      // yield put(actions.getDashboard());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteCostFile(fileId, costId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.UPLOAD}/contract/${costId}/${fileId}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getCostingDetail, costId);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHardwareLogs(typeID, id) {
  const newUrl = `${url.HARDWARE}/singleLog/${id}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHardwareLogs(result.data));
    }
  }
  return result;
}

function* getIntegrationMessages(payload) {
  const { startDate, endDate } = payload;
  let status = payload?.status?.length > 0
    ? payload?.status
    : yield select((s) => s?.infos?.MessageMonitoringTypes?.map((i) => i?.id)) ?? [];
  const [oneWeekStart, oneWeekEnd] = [moment(startDate, 'yyyy-MM-DD"T"HH:mm:SS').format(), moment(endDate, 'yyyy-MM-DD"T"HH:mm:SS').format()];

  if (!status?.length) {
    yield call(getInfo);
    status = yield select((s) => s?.infos?.MessageMonitoringTypes?.map((i) => i?.id)) ?? [];
  }
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  const jsonStr = JSON.stringify({ status });
  if (companyPiUrl > 0) {
    const result = yield call(request, `${url.INTEGRATION}/getMessage/${oneWeekStart}/${oneWeekEnd}/${companyPiUrl}`, 'POST', jsonStr, true, false);
    if (result !== 'invalid') {
      if (result.status) {
        yield put(actions.setIntegrationMessages(result.data));
      }
    }
    return result;
  }
  // eslint-disable-next-line no-use-before-define
  return yield call(getIntegrationPiUrl, false, -1, getIntegrationMessages, { startDate, endDate, status });
}

function* getIntegrationChannels() {
  // const [action, party, service, channel, showProcessLog, status] = ['status', '%2A', '%2A', '%2A', 'true', 'all'];
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  if (companyPiUrl > 0) {
    const result = yield call(request, `${url.INTEGRATION}/channelList/${companyPiUrl}`, 'GET', false, true, false);
    if (result !== 'invalid') {
      if (result.status) {
        yield put(actions.setIntegrationChannels(result.data));
      }
    }
    return result;
  }
  // eslint-disable-next-line no-use-before-define
  return yield call(getIntegrationPiUrl, false, -1, getIntegrationChannels);
}

function* getVendorUsers() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.INFO}/vendors`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setVendorUsers(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createVendorUser(data) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.CREATE_INFO}/vendorUser`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'Success'));
      yield put(actions.setResetState(true));
      yield put(actions.getVendorUsers());
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateVendorUser(data, id) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.CREATE_INFO}/vendorUser/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getVendorUsers());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* vendorUserStatusChange(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.CREATE_INFO}/vendorUserStatu/${id}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getVendorUsers());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteVendorUser(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.CREATE_INFO}/vendorUser/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getVendorUsers());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getApplicationReports() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.REPORT}/application`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setApplicationReports(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getAggregationReports(year) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.REPORT}/aggregations/${year}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAggregationReports(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHWAggregationReports(year) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.REPORT}/aggregations/hardware/${year}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHWAggregationReports(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getUserReports() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.REPORT}/users/`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setUserReports(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getUsersReports(year) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.REPORT}/department/users/${year}`, 'GET', false, true);

  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setUsersReports(result.data));
    }
  }
  yield put(actions.setLoading(false));
}

function* getDepartmentReport(year) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.REPORT}/department/${year}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDepartmentReport(result.data));
    }
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createHWCosting(data, files, sendToView) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  let result;
  if (files.length > 0) {
    result = yield call(filesRequest, `${url.HWCOSTING}/1`, 'POST', 'file', files, data);
  } else {
    result = yield call(request, `${url.HWCOSTING}/0`, 'POST', jsonStr, true);
  }
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSendToDetailPageID(result.data.id));
      yield put(actions.getHWCostingList(sendToView));
      yield put(actions.setToast(true, result.message, 'Success'));
      yield put(actions.setResetState(true));
      yield put(actions.setCostingFiles([]));
      yield put(actions.getContractYears());
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHWCostingDetail(id) {
  yield put(actions.setHWCostingDetail({}));
  const result = yield call(request, `${url.HWCOSTING}/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHWCostingDetail(result.data));
      yield put(actions.getHWCostingLogs(id));
    } else {
      yield put(actions.setHWCostingDetail(null));
      yield put(actions.setToast(true, result.message, 'error'));
      window.location.href = '#/404';
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    window.location.href = '#/404';
  }
  return result;
}

function* getHWCostingList(sendToView) {
  yield put(actions.setLoading(true));
  const result = yield call(request, url.HWCOSTING, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHWCostingList(result.data));
      if (sendToView) {
        yield put(actions.setSendToView(true));
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getHWCostingExpenses() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.HWCOSTING}/expenses`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setHWCostingExpenses(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteHWCosting(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.HWCOSTING}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setCostingConfig('costingTypeMenu', 'Hardware'));
      yield put(actions.getHWCostingList());
      window.location.href = '#/Costing';
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getLicenseFollow() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLicenseFollow(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteLicenseFollow(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getApplications());
      window.location.href = '#/LicenseFollow';
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateHWCosting(data, costId) {
  yield put(actions.setLoading(true));
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.HWCOSTING}/${costId}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.getHWCostingDetail(costId));
      yield put(actions.getHWCostingLogs(costId));
      yield put(actions.getInfo());
      yield put(actions.getDashboard());
      yield put(actions.getTimeModel());
      yield put(actions.getHWCostingList());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getLicenseFollowAgreement() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}/agreement`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLicenseFollowAgreement(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getLicenseFollowUser() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}/user`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLicenseFollowUser(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* getLicenseFollowDetailLog(id) {
  const result = yield call(request, `${url.LICENSE_FOLLOW}/log/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLicenseFollowLog(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getLicenseFollowDetail(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (!result.data) {
        window.location.href = '#/404';
      }
      yield put(actions.setLicenseFollowDetail(result.data));
      yield call(getLicenseFollowDetailLog, id);
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createLicenseFollow(data, sendToView) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getLicenseFollow);

      if (sendToView && 'id' in result.data) {
        const { id } = result.data;
        window.location.href = `#/LicenseFollowDetail/${id}`;
      } else {
        window.location.href = '#/LicenseFollow';
      }
    } else {
      yield put(actions.setToast(true, result?.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* uploadHWCostFile(data, id) {
  yield put(actions.setLoading(true));
  const result = yield call(filesRequest, `${url.UPLOAD}/hwContract/${id}`, 'POST', 'file', data);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getHWCostingDetail, id);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateLicenseFollowUser(payload) {
  const { id, data } = payload;
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}/user/${id}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, tra('Lisans_Kullanicisi_Eklendi'), 'success'));
      yield put(actions.setResetState(true));
      yield call(getLicenseFollowDetail, id);
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteHWCostFile(fileId, costId) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.UPLOAD}/hwContract/${costId}/${fileId}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getHWCostingDetail, costId);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateLicenseFollowKey(payload) {
  const { key_id, data, license_id } = payload;
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.LICENSE_FOLLOW}/key/${key_id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setResetState(true));
      yield call(getLicenseFollowDetail, license_id);
    } else {
      yield put(actions.setToast(true, result?.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* deleteAdminIntegration(id) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_INTEGRATION}/url/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield put(actions.setAppConfig('integrationSureModal', { open: false }));
      yield call(getInfo);
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createAdminIntegration(data) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_INTEGRATION}/url`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getInfo);
    } else {
      yield put(actions.setToast(true, result?.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateAdminIntegration(data, id) {
  const jsonStr = JSON.stringify(data);
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_INTEGRATION}/url/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getInfo);
    } else {
      yield put(actions.setToast(true, result?.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* updateIntegrationPiData() {
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  const result = yield call(request, `${url.INTEGRATION}/updatePiData/${companyPiUrl}`, 'GET', false, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      // eslint-disable-next-line no-use-before-define
      yield call(getIntegrationPiUrl, true, -1);
      // yield put(actions.setLoading(false));
    } else {
      yield put(actions.setToast(true, result?.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getIntegrationPiUrl(refresh, id, callFunc, callFuncData) {
  let companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  if (companyPiUrl < 0) {
    yield call(getInfo);
    companyPiUrl = yield select((s) => s.infos?.CompanyPiUrls?.[0]?.id) ?? -1;
    yield put(actions.setIntegrationPiUrl(companyPiUrl));
    return yield call(callFunc, callFuncData);
  }

  if (id > 0) {
    yield put(actions.setIntegrationPiUrl(id));
  }

  if (refresh) {
    // yield put(actions.setLoading(true));
    yield all([
      call(getIntegration),
      call(getIntegrationComponents),
      call(getIntegrationChannels),
      call(getIntegrationMessages, { startDate: new Date(new Date().setHours(0, 0, 0, 0)), endDate: new Date(new Date().setHours(24, 0, 0, 0)) }),
    ]);
    // yield put(actions.setLoading(false));
    return true;
  }
  return false;
}

function* getIntegrationMessageExcel(payload) {
  const { startDate, endDate } = payload;
  const [oneWeekStart, oneWeekEnd] = [moment(startDate, 'yyyy-MM-DD"T"HH:mm:SS').format(), moment(endDate, 'yyyy-MM-DD"T"HH:mm:SS').format()];
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  if (companyPiUrl > 0) {
    const fileStatus = yield call(fileDownload, `${url.INTEGRATION}/getMessage/excel/${oneWeekStart}/${oneWeekEnd}/${companyPiUrl}`, 'GET', false, 'Integration_Message');
    if (fileStatus === 'invalid') {
      yield put(actions.setToast(true, tra('Dosya_Indirilemedi'), 'warning'));
    }
    return fileStatus;
  }
  return yield call(getIntegrationPiUrl, false, -1, getIntegrationMessageExcel, payload);
}

function* getIntegrationResponsible(payload) {
  const jsonStr = JSON.stringify(payload);
  const result = yield call(request, `${url.REPORT}/responsible`, 'POST', jsonStr, true, false);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setIntegrationResponsible(result.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* updateIntegrationMessages() {
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  if (companyPiUrl > 0) {
    // yield put(actions.setLoading(true));
    const result = yield call(request, `${url.INTEGRATION}/updateMessages/${companyPiUrl}`, 'GET', false, true, false);
    if (result !== 'invalid') {
      if (result.status) {
        // yield put(actions.setIntegrationResponsible(result.data));
        yield put(actions.setToast(true, result?.message, 'success'));
      }
    } else {
      yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    }
    return result;
  }
  // yield put(actions.setLoading(false));
  return yield call(getIntegrationPiUrl, false, -1, updateIntegrationMessages);
}

function* getMessageMonitoringSendMailLog(payload) {
  const companyPiUrl = yield select((s) => s?.integrationPiUrlId);
  const jsonStr = JSON.stringify({ ids: payload });
  if (companyPiUrl > 0) {
    const result = yield call(request, `${url.INTEGRATION}/sendLogEmail/${companyPiUrl}`, 'POST', jsonStr, true, false);
    if (result !== 'invalid') {
      if (result.status) {
        // yield put(actions.setIntegrationResponsible(result.data));
        yield put(actions.setToast(true, result?.message, 'success'));
        yield put(actions.setLoading(false));
        yield put(actions.setResetState(true));
      }
    } else {
      yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
    }
    return result;
  }
  return yield call(getIntegrationPiUrl, false, -1, getMessageMonitoringSendMailLog, payload);
}

function* getIntegrationMessageLog(id) {
  const result = yield call(request, `${url.INTEGRATION}/getMessageLogs/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setIntegrationMessageLog(result?.data));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
}
function* deleteIntegrationMessageLog(payload) {
  const { id, modal_id } = payload;
  const result = yield call(request, `${url.INTEGRATION}/deleteMessageLog/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result?.message, 'success'));
      yield call(getIntegrationMessageLog, modal_id);
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
}
function* createIntegrationMessageLog(payload) {
  const { data, id, modal_id } = payload;
  const jsonStr = JSON.stringify({ data });
  const result = yield call(request, `${url.INTEGRATION}/createLogs/${id}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result?.message, 'success'));
      yield put(actions.setResetState(true));
      yield call(getIntegrationMessageLog, modal_id);
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  return result;
}

function* getMessageMonitoringSendMailUsers() {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_INTEGRATION}/monitoringAdmin`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setMessageMonitoringSendMailUsers(result?.data));
      // yield put(actions.setToast(true, result?.message, 'success'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
  return result;
}

function* createMessageMonitoringSendMailUsers(payload) {
  const jsonStr = JSON.stringify({ ids: [payload] });
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_INTEGRATION}/monitoringAdmin`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getMessageMonitoringSendMailUsers);
      yield put(actions.setToast(true, result?.message, 'success'));
      yield put(actions.setResetState(true));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
}
function* deleteMessageMonitoringSendMailUsers(payload) {
  yield put(actions.setLoading(true));
  const result = yield call(request, `${url.ADMIN_INTEGRATION}/monitoringAdmin/${payload}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getMessageMonitoringSendMailUsers);
      yield put(actions.setToast(true, result?.message, 'success'));
    }
  } else {
    yield put(actions.setToast(true, tra('Sunucu_ile_bağlantı_kurulamadı'), 'warning'));
  }
  yield put(actions.setLoading(false));
}

// ========== Watchers ==========
function* getIntegrationComponentsDetailUserWatcher() {
  while (true) {
    const action = yield take(constants.GET_INTEGRATION_COMPONENTS_DETAIL_USER);
    yield call(getIntegrationComponentsDetailUser, action.id);
  }
}
function* saveIntegrationComponentsDetailUserWatcher() {
  while (true) {
    const action = yield take(constants.SAVE_INTEGRATION_COMPONENTS_DETAIL_USER);
    yield call(saveIntegrationComponentsDetailUser, action.id, action.data);
  }
}
function* uploadIntegrationExcelWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_INTEGRATION_EXCEL);
    yield call(uploadIntegrationExcel, action.file);
  }
}
function* getIntegrationExcelWatcher() {
  while (true) {
    yield take(constants.GET_INTEGRATION_EXCEL);
    yield call(getIntegrationExcel);
  }
}
function* getIntegrationExcelSampleWatcher() {
  while (true) {
    yield take(constants.GET_INTEGRATION_EXCEL_SAMPLE);
    yield call(getIntegrationExcelSample);
  }
}
function* getIntegrationWatcher() {
  yield callEffect({
    action: constants.GET_INTEGRATION,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: () => getIntegration(),
  });
}
function* createIntegrationWatcher() {
  // while (true) {
  //   const action = yield take(constants.CREATE_INTEGRATION);
  //   yield call(createIntegration, action.data);
  // }
  yield callEffect({
    action: constants.CREATE_INTEGRATION,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => createIntegration(p.data),
  });
}
function* getIntegrationComponentsDetailWatcher() {
  // while (true) {
  //   const action = yield take(constants.GET_INTEGRATION_COMPONENTS_DETAIL);
  //   yield call(getIntegrationComponentsDetail, action.id);
  // }
  yield callEffect({
    action: constants.GET_INTEGRATION_COMPONENTS_DETAIL,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => getIntegrationComponentsDetail(p.id),
  });
}
function* getIntegrationComponentsWatcher() {
  // while (true) {
  //   const action = yield take(constants.GET_INTEGRATION_COMPONENTS);
  //   yield call(getIntegrationComponents, action.payload);
  // }
  yield callEffect({
    action: constants.GET_INTEGRATION_COMPONENTS,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => getIntegrationComponents(p.payload),
  });
}
function* getTimeModelWatcher() {
  while (true) {
    const action = yield take(constants.GET_TIME_MODEL);
    yield call(getTimeModel, action);
  }
}
function* deleteFileWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_FILE);
    yield call(deleteFile, action.fileId, action.appId, action.fileType);
  }
}
function* readNotificationWatcher() {
  while (true) {
    const action = yield take(constants.READ_NOTIFICATION);
    yield call(readNotification, action.id);
  }
}
function* readAllNotificationsWatcher() {
  while (true) {
    const action = yield take(constants.READ_ALL_NOTIFICATIONS);
    yield call(readAllNotifications, action);
  }
}
function* startRequiresServicesWatcher() {
  while (true) {
    const action = yield take(constants.START_REQUIRED_SERVICES);
    yield call(startRequiresServices, action);
  }
}
function* createUserWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_USER);
    yield call(createUser, action.data);
  }
}
function* getEventsWatcher() {
  while (true) {
    const action = yield take(constants.GET_EVENTS);
    yield call(getEvents, action.dates);
  }
}
function* createEventWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_EVENT);
    yield call(createEvent, action.params);
  }
}
function* getCalendarWatcher() {
  while (true) {
    const action = yield take(constants.GET_CALENDAR);
    yield call(getCalendar, action.dates);
  }
}
function* getVendorProcessMatrixWatcher() {
  while (true) {
    const action = yield take(constants.GET_VENDORPROCESSMATRIX);
    yield call(getVendorProcessMatrix, action.data);
  }
}
function* getAdditionalCostWatcher() {
  while (true) {
    const action = yield take(constants.GET_ADDITIONALCOST);
    yield call(getAdditionalCost, action.data);
  }
}
function* getCostGraphWatcher() {
  while (true) {
    const action = yield take(constants.GET_COSTGRAPH);
    yield call(getCostGraph, action.data);
  }
}
function* getDashboardWatcher() {
  while (true) {
    const action = yield take(constants.GET_DASHBOARD);
    yield call(getDashboard, action);
  }
}
// function* getProfileWatcher() {
//   while (true) {
//     const action = yield take(constants.GET_PROFILE);
//     yield call(getProfile, action.id);
//   }
// }
function* profileInitWatcher() {
  while (true) {
    yield take(constants.PROFIL_INIT);
    yield call(profileInit);
  }
}
function* getProfileInfoWatcher() {
  while (true) {
    yield take(constants.GET_PROFILE_INFO);
    yield call(getProfileInfo);
  }
}
function* getProfileHardwareWatcher() {
  while (true) {
    yield take(constants.GET_PROFILE_HARDWARE);
    yield call(getProfileHardware);
  }
}
function* getProfileApplicationWatcher() {
  while (true) {
    yield take(constants.GET_PROFILE_APPLICATION);
    yield call(getProfileApplication);
  }
}
function* getProfileSurveyWatcher() {
  while (true) {
    yield take(constants.GET_PROFILE_SURVEY);
    yield call(getProfileSurvey);
  }
}
function* getProfileLogWatcher() {
  while (true) {
    const action = yield take(constants.GET_PROFILE_LOG);
    yield call(getProfileLog, action.id);
  }
}

function* userInitWatcher() {
  while (true) {
    yield take(constants.USER_INIT);
    yield call(userInit);
  }
}
function* doCommentWatcher() {
  while (true) {
    const action = yield take(constants.DO_COMMENT);
    yield call(doComment, action.id, action.data);
  }
}
function* getCommentsWatcher() {
  while (true) {
    const action = yield take(constants.GET_COMMENTS);
    yield call(getComments, action.id);
  }
}
function* getApplicationLogWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATIONS_LOG);
    yield call(getApplicationLog, action.id);
  }
}
function* usersOperationWatcher() {
  while (true) {
    const action = yield take(constants.USERS_OPERATION);
    yield call(usersOperation, action.data, action.id, action.isRemove);
  }
}
function* getApplicationsWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATIONS);
    yield call(getApplications, action.sendToView);
  }
}
function* getUsersWatcher() {
  while (true) {
    yield take(constants.GET_USERS);
    yield call(getUsers);
  }
}
function* createInfoWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_INFO);
    yield call(createInfo, action.header, action.data, action.id);
  }
}
function* getInfoWatcher() {
  while (true) {
    yield take(constants.GET_INFO);
    yield call(getInfo);
  }
}
function* getInfoMonitoringWatcher() {
  yield callEffect({
    action: constants.GET_INFO_MONITORING,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: () => getInfoMonitoring(),
  });
}
function* getInfoHardwareWatcher() {
  yield callEffect({
    action: constants.GET_INFO_HARDWARE,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: () => getInfoHardware(),
  });
}
function* doLoginWatcher() {
  while (true) {
    const action = yield take(constants.DO_LOGIN);
    yield call(doLogin, action.email, action.password);
  }
}
function* companyRegisterWatcher() {
  while (true) {
    const action = yield take(constants.COMPANY_REGISTER);
    yield call(companyRegister, action.params);
  }
}
function* forgotPasswordWatcher() {
  while (true) {
    const action = yield take(constants.FORGOT_PASSWORD);
    yield call(forgotPassword, action.email);
  }
}
function* resetPaswordWatcher() {
  while (true) {
    const action = yield take(constants.RESET_PASSWORD);
    yield call(resetPasword, action.password);
  }
}
function* uploadProfileWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_PROFILE);
    yield call(uploadProfile, action.id, action.data);
  }
}
function* uploadAppFileWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_APP_FILE);
    yield call(uploadAppFile, action.data, action.id);
  }
}
function* createApplicationWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_APPLICATION);
    yield call(createApplication, action.data, action.createType, action.id, action.sendToView);
  }
}
function* getApplicationDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATION_DETAIL);
    yield call(getApplicationDetail, action.id);
  }
}
function* updateApplicationWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_APPLICATION);
    yield call(updateApplication, action.data, action.appId);
  }
}
function* archiveApplicationWatcher() {
  while (true) {
    const action = yield take(constants.ARCHIVE_APPLICATION);
    yield call(archiveApplication, action.data, action.appId);
  }
}
function* unarchiveApplicationWatcher() {
  while (true) {
    const action = yield take(constants.UNARCHIVE_APPLICATION);
    yield call(unarchiveApplication, action.appId);
  }
}
function* excelDownloadWatcher() {
  while (true) {
    const action = yield take(constants.EXCEL_DOWNLOAD);
    yield call(excelDownload, action.excelType, action.fileName);
  }
}
function* createSurveyWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_SURVEY);
    yield call(createSurvey, action.data);
  }
}
function* getSurveyUsersWatcher() {
  while (true) {
    const action = yield take(constants.GET_SURVEY_USERS);
    yield call(getSurveyUsers, action);
  }
}
function* getSurveyListWatcher() {
  while (true) {
    const action = yield take(constants.GET_SURVEY_LIST);
    yield call(getSurveyList, action);
  }
}
function* getSurveyDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_SURVEY_DETAIL);
    yield call(getSurveyDetail, action.id, action.appId);
  }
}
function* getSingleSurveyDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_SINGLE_SURVEY_DETAIL);
    yield call(getSingleSurveyDetail, action.userId, action.id, action.appId);
  }
}
function* getAssignedSurveysWatcher() {
  while (true) {
    const action = yield take(constants.GET_ASSIGNED_SURVEYS);
    yield call(getAssignedSurveys, action);
  }
}
function* getSurveyQuestionsWatcher() {
  while (true) {
    const action = yield take(constants.GET_SURVEY_QUESTIONS);
    yield call(getSurveyQuestions, action.id, action.appId);
  }
}
function* answerSurveyWatcher() {
  while (true) {
    const action = yield take(constants.ANSWER_SURVEY);
    yield call(answerSurvey, action.data, action.id, action.complete, action.saveType);
  }
}
function* changeInfoStatuWatcher() {
  while (true) {
    const action = yield take(constants.CHANGE_INFO_STATU);
    yield call(changeInfoStatu, action.header, action.id);
  }
}
function* getSingleSurveyWatcher() {
  while (true) {
    const action = yield take(constants.GET_SINGLE_SURVEY);
    yield call(getSingleSurvey, action.id);
  }
}
function* endSurveyWatcher() {
  while (true) {
    const action = yield take(constants.END_SURVEY);
    yield call(endSurvey, action.id);
  }
}
function* updateSurveyWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_SURVEY);
    yield call(updateSurvey, action.id, action.data);
  }
}
function* createAppWithFileWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_APP_WITH_FILE);
    yield call(createAppWithFile, action.data, action.files, action.createType, action.sendToView);
  }
}
function* deleteApplicationWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_APPLICATION);
    yield call(deleteApplication, action.id);
  }
}
function* followApplicationWatcher() {
  while (true) {
    const action = yield take(constants.FOLLOW_APPLICATION);
    yield call(followApplication, action.id, action.followType);
  }
}
function* excelUploadWatcher() {
  while (true) {
    const action = yield take(constants.EXCEL_UPLOAD);
    yield call(excelUpload, action.files, action.excelType);
  }
}
function* adminExcelUploadWatcher() {
  while (true) {
    const action = yield take(constants.ADMIN_EXCEL_UPLOAD);
    yield call(adminExcelUpload, action.files, action.excelType);
  }
}
function* adminExcelDownloadWatcher() {
  while (true) {
    const action = yield take(constants.ADMIN_EXCEL_DOWNLOAD);
    yield call(adminExcelDownload, action.excelType, action.fileName);
  }
}
function* changePasswordWatcher() {
  while (true) {
    const action = yield take(constants.CHANGE_PASSWORD);
    yield call(changePassword, action.data);
  }
}
function* companyLogoUploadWatcher() {
  while (true) {
    const action = yield take(constants.COMPANY_LOGO_UPLOAD);
    yield call(companyLogoUpload, action.file);
  }
}
function* companyUpdateWatcher() {
  while (true) {
    const action = yield take(constants.COMPANY_UPDATE);
    yield call(companyUpdate, action.data);
  }
}
function* createCompanyWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_COMPANY);
    yield call(createCompany, action.data);
  }
}
function* userStatuChangeWatcher() {
  while (true) {
    const action = yield take(constants.USER_STATUS_CHANGE);
    yield call(userStatuChange, action.id, action.isForce);
  }
}
function* getAdminCompaniesWatcher() {
  while (true) {
    const action = yield take(constants.GET_ADMIN_COMPANIES);
    yield call(getAdminCompanies, action);
  }
}
function* getAdminCompanyUsersWatcher() {
  while (true) {
    const action = yield take(constants.GET_ADMIN_COMPANY_USERS);
    yield call(getAdminCompanyUsers, action.id);
  }
}
function* addUserToCompanyWatcher() {
  while (true) {
    const action = yield take(constants.ADD_USER_TO_COMPANY);
    yield call(addUserToCompany, action.data, action.id);
  }
}
function* getCompanyDepartmentsWatcher() {
  while (true) {
    const action = yield take(constants.GET_COMPANY_DEPARTMENTS);
    yield call(getCompanyDepartments, action.id);
  }
}
function* getRegCompInitWatcher() {
  while (true) {
    const action = yield take(constants.GET_REG_COMP_INIT);
    yield call(getRegCompInit, action);
  }
}
function* companyActivationWatcher() {
  while (true) {
    const action = yield take(constants.COMPANY_ACTIVATION);
    yield call(companyActivation, action.params);
  }
}
function* resendActivationCodeWatcher() {
  while (true) {
    const action = yield take(constants.RESEND_ACTIVATION_CODE);
    yield call(resendActivationCode, action.params);
  }
}
function* downloadInventoryDataWatcher() {
  while (true) {
    const action = yield take(constants.DOWNLOAD_INVENTORY_DATA);
    yield call(downloadInventoryData, action.params);
  }
}
function* getSurveyDetailAppsWatcher() {
  while (true) {
    const action = yield take(constants.GET_SURVEY_DETAIL_APPS);
    yield call(getSurveyDetailApps, action.id);
  }
}
function* getSurveyReportQuestionsWatcher() {
  while (true) {
    const action = yield take(constants.GET_SURVEY_REPORT_QUESTIONS);
    yield call(getSurveyReportQuestions, action.id, action.appId);
  }
}
function* getAnswerOptionUsersWatcher() {
  while (true) {
    const action = yield take(constants.GET_ANSWER_OPTION_USERS);
    yield call(getAnswerOptionUsers, action.id, action.appId);
  }
}
function* uploadIntegrationFileWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_INTEGRATION_FILE);
    yield call(uploadIntegrationFile, action.file, action.id);
  }
}
function* createCostingWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_COSTING);
    yield call(createCosting, action.data, action.files, action.sendToView);
  }
}
function* getCostingCategoriesWatcher() {
  while (true) {
    const action = yield take(constants.GET_COSTING_CATEGORIES);
    yield call(getCostingCategories, action);
  }
}
function* getCostingWatcher() {
  while (true) {
    const action = yield take(constants.GET_COSTING);
    yield call(getCosting, action.sendToView);
  }
}
function* getCostingDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_COSTING_DETAIL);
    yield call(getCostingDetail, action.id);
  }
}
function* getApplicationContractsWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATION_CONTRACTS);
    yield call(getApplicationContracts, action.appId, action.year);
  }
}
function* updateCostingWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_COSTING);
    yield call(updateCosting, action.data, action.costId);
  }
}
function* uploadCostFileWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_COST_FILE);
    yield call(uploadCostFile, action.data, action.id);
  }
}
function* deleteCostFileWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_COST_FILE);
    yield call(deleteCostFile, action.fileId, action.costId);
  }
}
function* createHardwareWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_HARDWARE_SERVER);
    yield call(createHardware, action.params, action.hardwareType, action.sendToView);
  }
}
function* getHardwareModalTypesWatcher() {
  while (true) {
    yield take(constants.GET_HARDWARE_MODAL_TYPES);
    yield call(getHardwareModalTypes);
  }
}
function* getHardwareModalCategoryWatcher() {
  while (true) {
    const action = yield take(constants.GET_HARDWARE_MODAL_CATEGORY);
    yield call(getHardwareModalCategory, action.id);
  }
}
function* getHardwareDevicesWatcher() {
  while (true) {
    yield take(constants.GET_HARDWARE_DEVICES);
    yield call(getHardwareDevices);
  }
}
function* getHardwareDeviceDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_HARDWARE_DEVICE_DETAIL);
    yield call(getHardwareDeviceDetail, action.id);
  }
}
function* updateHardwareWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_HARDWARE_DEVICE);
    yield call(updateHardware, action.params);
  }
}
function* getCostingLogsWatcher() {
  while (true) {
    const action = yield take(constants.GET_COSTING_LOGS);
    yield call(getCostingLogs, action.costId);
  }
}
function* getHardwareLogsWatcher() {
  while (true) {
    const action = yield take(constants.GET_HARDWARE_LOG);
    yield call(getHardwareLogs, action.typeID, action.id);
  }
}
function* changeAppStatusWatcher() {
  while (true) {
    const action = yield take(constants.CHANGE_APP_STATUS);
    yield call(changeAppStatus, action.id, action.data);
  }
}
function* getCostGroupWatcher() {
  while (true) {
    const action = yield take(constants.GET_COST_GROUP);
    yield call(getCostGroup, action.id);
  }
}
function* getApplicationDraftsWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATION_DRAFTS);
    yield call(getApplicationDrafts, action);
  }
}
function* getApplicationDraftWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATION_DRAFT);
    yield call(getApplicationDraft, action.id);
  }
}
function* updateApplicationDraftWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_APPLICATION_DRAFT);
    yield call(updateApplicationDraft, action.data, action.id);
  }
}
function* uploadAppDraftFileWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_APP_DRAFT_FILE);
    yield call(uploadAppDraftFile, action.data, action.id);
  }
}
function* getApplicationLifecycleWatcher() {
  while (true) {
    const action = yield take(constants.GET_APPLICATION_LIFECYCLE);
    yield call(getApplicationLifecycle, action.id);
  }
}

function* getIntegrationMessagesWatcher() {
  // while (true) {
  //   const action = yield take(constants.GET_INTEGRATION_MESSAGES);
  //   yield call(getIntegrationMessages, action.payload);
  // }
  // sagaWatcherType
  yield callEffect({
    action: constants.GET_INTEGRATION_MESSAGES,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => getIntegrationMessages(p.payload),
  });
}
function* getIntegrationChannelsWatcher() {
  yield callEffect({
    action: constants.GET_INTEGRATION_CHANNEL,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: () => getIntegrationChannels(),
  });
}
function* getLdapUsersWatcher() {
  while (true) {
    const action = yield take(constants.GET_LDAP_USERS);
    yield call(getLdapUsers, action.value);
  }
}
function* createLdapUserWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_LDAP_USER);
    yield call(createLdapUser, action.data);
  }
}
function* deleteCostingWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_COSTING);
    yield call(deleteCosting, action.costId);
  }
}
function* getContractYearsWatcher() {
  while (true) {
    const action = yield take(constants.GET_CONTRACT_YEARS);
    yield call(getContractYears, action);
  }
}
function* getVendorUsersWatcher() {
  while (true) {
    const action = yield take(constants.GET_VENDOR_USERS);
    yield call(getVendorUsers, action);
  }
}
function* createVendorUserWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_VENDOR_USER);
    yield call(createVendorUser, action.data);
  }
}
function* updateVendorUserWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_VENDOR_USER);
    yield call(updateVendorUser, action.data, action.id);
  }
}
function* getApplicationReportsWatchers() {
  while (true) {
    const action = yield take(constants.GET_APPLICATION_REPORTS);
    yield call(getApplicationReports, action);
  }
}
function* getAggregationReportsWatchers() {
  while (true) {
    const action = yield take(constants.GET_AGGREGATION_REPORTS);
    yield call(getAggregationReports, action.year);
  }
}
function* getHWAggregationReportsWatchers() {
  while (true) {
    const action = yield take(constants.GET_HW_AGGREGATION_REPORTS);
    yield call(getHWAggregationReports, action.year);
  }
}
function* updateInfoWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_INFO);
    yield call(updateInfo, action.id, action.header, action.data);
  }
}
function* getAppCostReportWatcher() {
  while (true) {
    const action = yield take(constants.GET_APP_COST_REPORT);
    yield call(getAppCostReport, action.year);
  }
}
function* vendorUserStatusChangeWatcher() {
  while (true) {
    const action = yield take(constants.VENDOR_USER_STATUS_CHANGE);
    yield call(vendorUserStatusChange, action.id);
  }
}
function* deleteVendorUserWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_VENDOR_USER);
    yield call(deleteVendorUser, action.id);
  }
}
function* getPerBusinessCapabilityWatcher() {
  while (true) {
    const action = yield take(constants.GET_PER_BUSINESS_CAPABILITY);
    yield call(getPerBusinessCapability, action);
  }
}
function* getPerITDomainWatcher() {
  while (true) {
    const action = yield take(constants.GET_PER_IT_DOMAIN);
    yield call(getPerITDomain, action);
  }
}
function* getPerBusinessDomainWatcher() {
  while (true) {
    const action = yield take(constants.GET_PER_BUSINESS_DOMAIN);
    yield call(getPerBusinessDomain, action);
  }
}
function* getDashboardMapReportWatcher() {
  while (true) {
    yield take(constants.GET_DASHBOARD_MAP_REPORT);
    yield call(getDashboardMapReport);
  }
}
function* getDashboardCostingDetailWatcher() {
  while (true) {
    yield take(constants.GET_DASHBOARD_COSTING_DETAIL);
    yield call(getDashboardCostingDetail);
  }
}
function* getUserReportsWatcher() {
  while (true) {
    yield take(constants.GET_USER_REPORTS);
    yield call(getUserReports);
  }
}
function* getUsersReportsWatcher() {
  while (true) {
    const action = yield take(constants.GET_USERS_REPORTS);
    yield call(getUsersReports, action.year);
  }
}
function* getDepartmentReportWatcher() {
  while (true) {
    const action = yield take(constants.GET_DEPARTMENT_REPORT);
    yield call(getDepartmentReport, action.year);
  }
}
function* createHWCostingWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_HWCOSTING);
    yield call(createHWCosting, action.data, action.files, action.sendToView);
  }
}
function* getHWCostingDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_HWCOSTING_DETAIL);
    yield call(getHWCostingDetail, action.id);
  }
}
function* getHWCostingCategoriesWatcher() {
  while (true) {
    const action = yield take(constants.GET_HWCOSTING_CATEGORIES);
    yield call(getHWCostingCategories, action);
  }
}
function* getHWCostingExpensesWatcher() {
  while (true) {
    const action = yield take(constants.GET_HWCOSTING_EXPENSES);
    yield call(getHWCostingExpenses, action);
  }
}
function* getHWCostingListWatcher() {
  while (true) {
    const action = yield take(constants.GET_HWCOSTING_LIST);
    yield call(getHWCostingList, action.sendToView);
  }
}
function* deleteHWCostingWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_HWCOSTING);
    yield call(deleteHWCosting, action.costId);
  }
}
function* updateHWCostingWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_HWCOSTING);
    yield call(updateHWCosting, action.data, action.costId);
  }
}
function* getHWCostingLogsWatcher() {
  while (true) {
    const action = yield take(constants.GET_HWCOSTING_LOGS);
    yield call(getHWCostingLogs, action.costId);
  }
}
function* uploadHWCostFileWatcher() {
  while (true) {
    const action = yield take(constants.UPLOAD_HWCOST_FILE);
    yield call(uploadHWCostFile, action.data, action.id);
  }
}
function* deleteHWCostFileWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_HWCOST_FILE);
    yield call(deleteHWCostFile, action.fileId, action.costId);
  }
}

function* getLicenseFollowWatcher() {
  while (true) {
    yield take(constants.GET_LICENSE_FOLLOW);
    yield call(getLicenseFollow);
  }
}

function* deleteLicenseFollowWatcher() {
  while (true) {
    const { id } = yield take(constants.DELETE_LICENSE_FOLLOW);
    yield call(deleteLicenseFollow, id);
  }
}

function* getLicenseFollowAgreementWatcher() {
  while (true) {
    yield take(constants.GET_LICENSE_FOLLOW_AGREEMENT);
    yield call(getLicenseFollowAgreement);
  }
}
function* getLicenseFollowUserWatcher() {
  while (true) {
    yield take(constants.GET_LICENSE_FOLLOW_USER);
    yield call(getLicenseFollowUser);
  }
}
function* getLicenseFollowDetailWatcher() {
  while (true) {
    const action = yield take(constants.GET_LICENSE_FOLLOW_DETAIL);
    yield call(getLicenseFollowDetail, action.data);
  }
}

function* createLicenseFollowlWatcher() {
  while (true) {
    const { data, sendToView } = yield take(constants.CREATE_LICENSE_FOLLOW);
    yield call(createLicenseFollow, data, sendToView);
  }
}

function* updateLicenseFollowUserWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_LICENSE_FOLLOW_USER);
    yield call(updateLicenseFollowUser, action.payload);
  }
}

function* updateLicenseFollowKeyWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_LICENSE_FOLLOW_KEY);
    yield call(updateLicenseFollowKey, action.payload);
  }
}

function* createAdminIntegrationWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_ADMIN_INTEGRATION);
    yield call(createAdminIntegration, action.data);
  }
}

function* deleteAdminIntegrationWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_ADMIN_INTEGRATION);
    yield call(deleteAdminIntegration, action.id);
  }
}

function* updateAdminIntegrationWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_ADMIN_INTEGRATION);
    yield call(updateAdminIntegration, action.data, action.id);
  }
}
function* getIntegrationPiUrlWatcher() {
  yield callEffect({
    action: constants.GET_INTEGRATION_PI_URL,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => getIntegrationPiUrl(p?.refresh, p?.id),
  });
}
function* updateIntegrationPiDataWatcher() {
  yield callEffect({
    action: constants.UPDATE_INTEGRATION_PI_DATA,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: () => updateIntegrationPiData(),
  });
}
function* getIntegrationMessageExcelWatcher() {
  while (true) {
    const action = yield take(constants.GET_INTEGRATION_MESSAGE_EXCEL);
    yield call(getIntegrationMessageExcel, action.payload);
  }
}
function* getIntegrationResponsibleWatcher() {
  yield callEffect({
    action: constants.GET_INTEGRATION_RESPONSIBLE,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => getIntegrationResponsible(p?.data),
  });
}
function* updateIntegrationMessagesWatcher() {
  yield callEffect({
    action: constants.UPDATE_INTEGRATION_MESSAGES,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: () => updateIntegrationMessages(),
  });
}
function* createIntegrationMessageLogWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_INTEGRATION_MESSAGE_LOG);
    yield call(createIntegrationMessageLog, action.payload);
  }
}
function* deleteIntegrationMessageLogWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_INTEGRATION_MESSAGE_LOG);
    yield call(deleteIntegrationMessageLog, action.payload);
  }
}
function* getIntegrationMessageLogWatcher() {
  while (true) {
    const action = yield take(constants.GET_INTEGRATION_MESSAGE_LOG);
    yield call(getIntegrationMessageLog, action?.id);
  }
}
function* getMessageMonitoringSendMailLogWatcher() {
  yield callEffect({
    action: constants.GET_MESSAGE_MONITORING_SEND_MAIL_LOG,
    globalLoading: false,
    takeType: sagaWatcherType.TAKE_LEADING,
    func: (p) => getMessageMonitoringSendMailLog(p?.data),
  });
}
function* getMessageMonitoringSendMailUsersWatcher() {
  while (true) {
    const action = yield take(constants.GET_MESSAGE_MONITORING_SEND_MAIL_USERS);
    yield call(getMessageMonitoringSendMailUsers, action?.data);
  }
}
function* createMessageMonitoringSendMailUsersWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_MESSAGE_MONITORING_SEND_MAIL_USERS);
    yield call(createMessageMonitoringSendMailUsers, action?.data);
  }
}
function* deleteMessageMonitoringSendMailUsersWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_MESSAGE_MONITORING_SEND_MAIL_USERS);
    yield call(deleteMessageMonitoringSendMailUsers, action?.data);
  }
}

export default function* rootSaga() {
  yield all([
    saveIntegrationComponentsDetailUserWatcher(),
    getIntegrationComponentsDetailUserWatcher(),
    uploadIntegrationExcelWatcher(),
    getIntegrationWatcher(),
    getIntegrationExcelSampleWatcher(),
    createIntegrationWatcher(),
    getIntegrationComponentsDetailWatcher(),
    getIntegrationComponentsWatcher(),
    getIntegrationMessagesWatcher(),
    getIntegrationChannelsWatcher(),
    getTimeModelWatcher(),
    userStatuChangeWatcher(),
    readNotificationWatcher(),
    readAllNotificationsWatcher(),
    startRequiresServicesWatcher(),
    createUserWatcher(),
    getEventsWatcher(),
    createEventWatcher(),
    getVendorProcessMatrixWatcher(),
    getCalendarWatcher(),
    getAdditionalCostWatcher(),
    getCostGraphWatcher(),
    getDashboardWatcher(),
    // getProfileWatcher(),
    profileInitWatcher(),
    getProfileInfoWatcher(),
    getProfileHardwareWatcher(),
    getProfileApplicationWatcher(),
    getProfileSurveyWatcher(),
    getProfileLogWatcher(),
    userInitWatcher(),
    doCommentWatcher(),
    getCommentsWatcher(),
    getApplicationLogWatcher(),
    usersOperationWatcher(),
    getApplicationsWatcher(),
    getUsersWatcher(),
    createInfoWatcher(),
    getInfoWatcher(),
    getInfoHardwareWatcher(),
    getInfoMonitoringWatcher(),
    doLoginWatcher(),
    forgotPasswordWatcher(),
    uploadProfileWatcher(),
    uploadAppFileWatcher(),
    createApplicationWatcher(),
    getApplicationDetailWatcher(),
    companyRegisterWatcher(),
    updateApplicationWatcher(),
    resetPaswordWatcher(),
    archiveApplicationWatcher(),
    unarchiveApplicationWatcher(),
    excelDownloadWatcher(),
    createSurveyWatcher(),
    getSurveyUsersWatcher(),
    getSurveyListWatcher(),
    getSurveyDetailWatcher(),
    getSingleSurveyDetailWatcher(),
    getAssignedSurveysWatcher(),
    getSurveyQuestionsWatcher(),
    answerSurveyWatcher(),
    getSingleSurveyWatcher(),
    endSurveyWatcher(),
    updateSurveyWatcher(),
    createAppWithFileWatcher(),
    followApplicationWatcher(),
    excelUploadWatcher(),
    deleteFileWatcher(),
    changePasswordWatcher(),
    companyLogoUploadWatcher(),
    companyUpdateWatcher(),
    createCompanyWatcher(),
    changeInfoStatuWatcher(),
    getAdminCompaniesWatcher(),
    getAdminCompanyUsersWatcher(),
    addUserToCompanyWatcher(),
    getCompanyDepartmentsWatcher(),
    getIntegrationExcelWatcher(),
    getRegCompInitWatcher(),
    companyActivationWatcher(),
    resendActivationCodeWatcher(),
    downloadInventoryDataWatcher(),
    getSurveyDetailAppsWatcher(),
    getSurveyReportQuestionsWatcher(),
    getAnswerOptionUsersWatcher(),
    uploadIntegrationFileWatcher(),
    adminExcelUploadWatcher(),
    adminExcelDownloadWatcher(),
    createHardwareWatcher(),
    getHardwareModalTypesWatcher(),
    getHardwareModalCategoryWatcher(),
    getHardwareDevicesWatcher(),
    getHardwareDeviceDetailWatcher(),
    updateHardwareWatcher(),
    createCostingWatcher(),
    getCostingCategoriesWatcher(),
    getCostingWatcher(),
    getCostingDetailWatcher(),
    getApplicationContractsWatcher(),
    updateCostingWatcher(),
    uploadCostFileWatcher(),
    deleteCostFileWatcher(),
    getCostingLogsWatcher(),
    getHardwareLogsWatcher(),
    changeAppStatusWatcher(),
    getCostGroupWatcher(),
    getApplicationDraftsWatcher(),
    getApplicationDraftWatcher(),
    updateApplicationDraftWatcher(),
    uploadAppDraftFileWatcher(),
    getApplicationLifecycleWatcher(),
    deleteApplicationWatcher(),
    getLdapUsersWatcher(),
    createLdapUserWatcher(),
    deleteCostingWatcher(),
    getContractYearsWatcher(),
    getVendorUsersWatcher(),
    createVendorUserWatcher(),
    updateVendorUserWatcher(),
    getApplicationReportsWatchers(),
    getAggregationReportsWatchers(),
    updateInfoWatcher(),
    getAppCostReportWatcher(),
    vendorUserStatusChangeWatcher(),
    deleteVendorUserWatcher(),
    getPerBusinessCapabilityWatcher(),
    getPerITDomainWatcher(),
    getPerBusinessDomainWatcher(),
    getDashboardMapReportWatcher(),
    getDashboardCostingDetailWatcher(),
    getUserReportsWatcher(),
    getUsersReportsWatcher(),
    getHWCostingCategoriesWatcher(),
    getHWCostingExpensesWatcher(),
    getHWCostingListWatcher(),
    getHWCostingDetailWatcher(),
    createHWCostingWatcher(),
    getDepartmentReportWatcher(),
    deleteHWCostingWatcher(),
    updateHWCostingWatcher(),
    getHWCostingLogsWatcher(),
    uploadHWCostFileWatcher(),
    deleteHWCostFileWatcher(),
    getLicenseFollowWatcher(),
    getLicenseFollowAgreementWatcher(),
    getLicenseFollowUserWatcher(),
    getLicenseFollowDetailWatcher(),
    createLicenseFollowlWatcher(),
    updateLicenseFollowUserWatcher(),
    updateLicenseFollowKeyWatcher(),
    getHWAggregationReportsWatchers(),
    createAdminIntegrationWatcher(),
    deleteAdminIntegrationWatcher(),
    updateAdminIntegrationWatcher(),
    getIntegrationPiUrlWatcher(),
    updateIntegrationPiDataWatcher(),
    getIntegrationMessageExcelWatcher(),
    getIntegrationResponsibleWatcher(),
    updateIntegrationMessagesWatcher(),
    getIntegrationMessageLogWatcher(),
    deleteIntegrationMessageLogWatcher(),
    createIntegrationMessageLogWatcher(),
    deleteMessageMonitoringSendMailUsersWatcher(),
    createMessageMonitoringSendMailUsersWatcher(),
    getMessageMonitoringSendMailUsersWatcher(),
    getMessageMonitoringSendMailLogWatcher(),
    deleteLicenseFollowWatcher(),
  ]);
}

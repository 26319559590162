import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, NoData } from '../../../assets/styledNew';
import NoDataImg from '../../../assets/img/noData.png';
import Vega from '../../../components/charts/Vega';
import Table from '../../../components/Table';
import { tra } from '../../../commons/utils';
import * as actions from '../../../store/actions';
import { Colors } from '../../../assets/statics';
import useResponseStatu from '../../../commons/hook/useResponseStatu';
import IntegrationGraphicFormModal from '../../../components/modals/IntegrationGraphicFormModal';
import ChartFilterDrawerMenu from './ChartFilterDrawerMenu';
import TableFilterMenu from './TableFilterMenu';
// import IntegrationGraphicFormModal from '../../../components/modals/IntegrationGraphicFormModal';

export default function GraphTab() {
  const dispatch = useDispatch();

  // STORE
  const integrationComponents = useSelector((s) => s?.integrationComponents);
  const integrationComponentsDetail = useSelector((s) => s?.integrationComponentsDetail);
  const integrationComponentsDetailUser = useSelector((s) => s?.integrationComponentsDetailUser);

  const { loading: integraionLoading } = useResponseStatu(actions.getIntegrationComponents().type);
  const { loading: integraionDetailLoading } = useResponseStatu(actions.getIntegrationComponentsDetail().type);

  // STATE
  const [selectTableItemID, setSelectTableItemID] = useState(false);
  const [selectID, setSelectID] = useState(-1);
  const [filterShow, setFilterShow] = useState(false);

  // const [test, setTest] = useState(false)

  useEffect(() => {
    if (!integrationComponents?.integrations?.length) {
      dispatch(actions.getIntegrationComponents());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HANDLE ACTION
  const handleSelectData = (data) => {
    if (data?.id) {
      setSelectID(data?.id);
      dispatch(actions.getIntegrationComponentsDetail(data.id));
    } else {
      setSelectID(false);
      dispatch(actions.setIntegrationComponentsDetail([]));
    }
  };

  const handleGraphicTableModalShow = (id) => {
    dispatch(actions.getIntegrationComponentsDetailUser(id));
    setSelectTableItemID(id);
    // this.setState(((state) => ({ selectedRowId: state.selectedRowId !== id ? id : '' })));
  };

  const getColumns = (hideColor) => {
    // const { selectedDataId } = this.state;
    const columnData = [
      {
        name: 'sender_component',
        text: tra('Gonderen_Bileseni'),
        filterable: true,
        type: 'text',
        align: 'left',
        sortable: true,
        searchable: true,
      },
      {
        name: 'sender_interface',
        text: tra('Gonderen_Arayuzu'),
        filterable: true,
        type: 'text',
        align: 'left',
        sortable: true,
        searchable: true,
      },
      {
        name: 'sender_namespace',
        text: tra('Gonderen_Adi'),
        filterable: true,
        type: 'text',
        align: 'left',
        sortable: true,
        searchable: true,
      },
      {
        name: 'receiver_component',
        text: tra('Alici_Bileseni'),
        filterable: true,
        type: 'text',
        align: 'left',
        sortable: true,
        searchable: true,
      },
      {
        name: 'receiver_interface',
        text: tra('Alici_Arayuzu'),
        filterable: true,
        type: 'text',
        align: 'left',
        sortable: true,
        searchable: true,
      },
      {
        name: 'receiver_namespace',
        text: tra('Alici_Adi'),
        filterable: true,
        type: 'text',
        align: 'left',
        sortable: true,
        searchable: true,
      },
      {
        name: 'isRemoved',
        text: tra('Aktif_Pasif'),
        filterable: true,
        type: 'text',
        align: 'center',
        sortable: true,
        searchable: true,
        customBody: (value) => (
          <div style={{
            width: 10,
            height: 10,
            borderRadius: '100%',
            background: !value ? Colors.green : Colors.red,
            margin: '0 auto',
          }}
          />
        ),
      },
    ];
    if (!hideColor) {
      columnData.unshift({
        name: 'sender_id',
        text: tra('Statu'),
        align: 'center',
        sortable: true,
        display: !hideColor,
        customBody: (value) => (
          <div style={{
            width: 10,
            height: 10,
            borderRadius: '100%',
            background: value === selectID ? Colors.green : Colors.red,
            margin: '0 auto',
          }}
          />
        ),
      });
    }
    return columnData;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <CustomButton disabled={integraionLoading} style={{ marginTop: '10px' }} onClick={() => setFilterShow(true)}>Chart {tra('Filtre')}</CustomButton>
        </Grid>
        <Grid item xs={12} justify="space-evenly" direction="row" alignContent="space-between" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          {
            integraionLoading || integrationComponents?.integrations?.length > 0 ? (
              <Vega
                lazyLoading={integraionLoading}
                data={integrationComponents.components}
                dataMap={integrationComponents.integrations}
                selected={handleSelectData}
              />
            ) : (
              <NoData>
                <img src={NoDataImg} alt="No Data" />
                <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}</p>
              </NoData>
            )
          }
        </Grid>
        <Grid item xs={12}>
          {selectID !== -1
            && (
              <TableFilterMenu
                tableData={integrationComponentsDetail}
                tableColumn={getColumns()}
              >
                {(filterProps) => (
                  <Table
                    lazyLoading={integraionDetailLoading}
                    data={integrationComponentsDetail}
                    columns={getColumns()}
                    rowClick={(row) => handleGraphicTableModalShow(row.id)}
                    activeFocus
                    options={{
                      header: {
                        visible: true,
                        search: false,
                        columnSetting: true,
                      },
                      filter: {
                        show: true,
                        ...filterProps, // tableDrawer geliyor hepsi
                        // filters: this.state.filter,
                        // onChange: this.changeFilter,
                        // drawerChange: () => this.props.setFilterDrawer(!this.props.filterDrawer),
                      },
                      pagination: {
                        active: true,
                        perPage: 20,
                      },
                    }}
                  />
                )}
              </TableFilterMenu>
            )}
        </Grid>
      </Grid>
      <IntegrationGraphicFormModal
        open={!!selectTableItemID}
        selectedRowId={selectTableItemID}
        close={() => setSelectTableItemID(false)}
        itegrationComponentsDetailUser={integrationComponentsDetailUser}
      />
      <ChartFilterDrawerMenu
        open={filterShow}
        onClose={() => setFilterShow(false)}
        onSubmit={(data) => dispatch(actions.getIntegrationComponents(data))}
      />
    </>
  );
}

import * as endUserType from './endUserType';
import * as networkType from './networkType';
import * as serverType from './serverType';
import * as securityType from './securityType';
import * as printerType from './printerType';

/**
 *
 * @param {number} category cihazların kategori id alıyor
 * @returns {Array} hardware tarafı için gerekli olan tüm bilgileri buradan alıyorum.
 *
 */
export default function hardwareSwitchData(category) {
  switch (category) {
    case 1: return serverType.serverUnit;// server
    case 2: return serverType.storageUnit; // Storage
    case 3: return networkType.switchUnit;// switch/router
    case 4: return networkType.accesssPointUnit;// access point
    case 5: return networkType.ipDevicesUnit;// ip cihazı
    case 6: return networkType.storageDeviceUnit;// depolama cihazı
    case 7: return networkType.kabinetUnit;// kabinet
    case 8: return networkType.networkComponentUnit;// network bileşeni
    case 9: return securityType.firewallUnit;// firewall
    case 10: return securityType.otherSecurityUnit; // diğer güvenlik cihazları
    case 11: return endUserType.computerUnit;// bilgisayar
    case 12: return endUserType.phoneUnit;// telefon
    case 13: return endUserType.phoneUnit;// tablet
    case 14: return endUserType.terminalUnit;// El Terminali
    case 15: return printerType.printerUnit; // Yazıcı/tarayıcı
    case 16: return []; // diğer
    case 17: return endUserType.simcardUnit;// simkart
    default: return [];
  }
}

/**
 *  eklenebilir alanların değişkenlerini burada saklıyorum.
 */
export const hardwareAddedArea = ['HardDisks', 'Memories', 'Monitor', 'GSM', 'VirtualMachine', 'GraphicCard'];

/**
 * servise giden path değişkenleri
 * !!! sırası önemli !!!
 */
export const hwaPathData = [
  {
    id: 1,
    path: 'server',
  },
  {
    id: 2,
    path: 'network',
  },
  {
    id: 3,
    path: 'security',
  },
  {
    id: 4,
    path: 'lastUserProduct',
  },
  {
    id: 5,
    path: 'printer',
  },
  {
    id: 6,
    path: 'other',
  },
];

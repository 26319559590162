import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withTheme } from 'styled-components';
import { FormatCurrency } from '../../commons/utils';
import { Colors } from '../../assets/statics';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 19px ${Colors.shadow};`,
    fontSize: 12,
    color: Colors.darkBlue,
    fontWeight: 600,
    padding: 15,
    fontFamily: 'Inter',
  },
  arrow: {
    color: Colors.white,
  },
}))(Tooltip);

const DepartmentBudgetReport = ({ data, cur, theme }) => (
  <ResponsiveBar
    theme={{
      textColor: theme.text1,
    }}
    data={data}
    keys={[
      'total',
    ]}
    indexBy="name"
    margin={{
      top: 10, right: 100, bottom: 100, left: 50,
    }}
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#4C6FFF']}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6,
        ],
      ],
    }}
    axisTop={null}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    axisBottom={{
      format: (val) => (
        val.length > 10 ? (
          <LightTooltip title={val}>
            <tspan style={{ cursor: 'pointer', fontSize: 12, color: Colors.black }}>
              {`${val.substring(0, 10)}...`}
            </tspan>
          </LightTooltip>
        ) : (
          <tspan style={{ fontSize: 12, color: Colors.black }}>{val}</tspan>
        )
      ),
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: 32,
    }}
    axisRight={null}
    label={null}
    labelSkipWidth={12}
    labelSkipHeight={12}
    // enableGridY={false}
    labelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6,
        ],
      ],
    }}
    legends={[]}
    tooltip={(tooltipData) => (
      <div>
        <p>{FormatCurrency(tooltipData.data[tooltipData.id])} {cur}</p>
      </div>
    )}
    role="application"
    ariaLabel="Nivo bar chart demo"
  // barAriaLabel={function (e) { return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`; }}
  />
);

DepartmentBudgetReport.propTypes = {
  data: PropTypes.array.isRequired,
  cur: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(DepartmentBudgetReport);

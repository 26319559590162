import inventoryPages from '../public/publicInventory';
import { tra } from '../../../commons/utils';

const deviceDetail = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'AccessPoint',
    inputArray: [
      {
        type: 'text',
        title: tra('Isim'),
        name: 'name', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Anten_Sayisi_Ve_Ozelligi'),
        name: 'antenna_number_attribute', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Ip_Adresi'),
        name: 'ip_address', // değişecek
        validateRules: 'ip:IP',
        value: '1920 X 1080',
        inputProps: {
          maxLength: 15,
        },
      },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'number',
        title: tra('Port_Sayisi'),
        name: 'port_number', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'select',
        title: tra('Anten_Turu'),
        name: 'antenna_type', // değişecek
        value: '1920 X 1080',
        data: 'AntennaTypes',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  }],
];

export default [[...inventoryPages, deviceDetail]];

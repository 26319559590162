import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { withWidth } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTheme } from 'styled-components';
import { CustomButton, HardwarePagesUnitArea, Placeholder } from '../../assets/styledNew';
import InputRender from './InputRender';
import { HardwareContext } from '../../commons/context/HardwareContext';
import { TrashIcon } from '../../assets/icons';
import { tra } from '../../commons/utils';
import {
  selectAppConfig, selectApplications, selectHardwareDevices, selectInfos, selectUsers,
} from '../../store/selectors';

class InputGroupRender extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType=HardwareContext

  constructor(props) {
    super(props);

    const { addGroupName, objectGroupName } = this.props.data;
    this.pathDataRemove = [objectGroupName, addGroupName].filter((i) => i).join('.');
  }

  hwaInput=(inputData) => {
    const {
      addGroupName, addGroupIndex, objectGroupName,
    } = this.props.data;
    const { pagesIndex } = this.props;

    return inputData.childrenInput ? (
      <div className="inputFlexRow">
        <InputRender
          key={uuidv4()}
          inputObject={inputData}
          objectGroupName={objectGroupName}
          addGroupName={addGroupName}
          addGroupIndex={addGroupIndex}
          pagesNumber={pagesIndex}
        />
        {
      inputData.childrenInput.map((element) => (
        <InputRender
          key={uuidv4()}
          inputObject={element}
          objectGroupName={objectGroupName}
          addGroupName={addGroupName}
          addGroupIndex={addGroupIndex}
          pagesNumber={pagesIndex}
        />
      ))
        }
      </div>
    ) : (
      <InputRender
        key={uuidv4()}
        inputObject={inputData}
        objectGroupName={objectGroupName}
        addGroupName={addGroupName}
        addGroupIndex={addGroupIndex}
        pagesNumber={pagesIndex}
      />
    );
  }

  handleRemove=() => {
    this.context.setContextDeleteSumbitDataDefined({ fieldName: this.pathDataRemove, definedNamePath: this.props.data.definedName });
  }

  render() {
    const { data } = this.props;
    const {
      title, subtitle, inputArray, spacerTitle, removeButton, propsChildren, desc,
    } = data;
    const buttonDisabled = this.context.isHardwareDetail ? !this.props.appConfig.disabled : true;
    return (
      <HardwarePagesUnitArea isTitle={title} descPadding={Boolean(desc)}>
        {(title || subtitle)
          ? (
            <div className="inputGroupHeader">
              <div>
                <h4>
                  {title || subtitle}
                </h4>
                {desc && desc !== 'spacer' && <p className="desc">{desc} <p style={{ color: this.props.theme.redText }}>* {tra('Lutfen_Zorunlu_Alanlari_Doldurun')}</p></p>}
              </div>
              {buttonDisabled && removeButton && <CustomButton transparent onClick={() => this.handleRemove()} startIcon={<TrashIcon />}>{tra('Sil')}</CustomButton>}
            </div>

          )
          : propsChildren && this.context.isHardwareDetail
            ? (
              this.props.propsChildren
            )
            : spacerTitle === true && <Placeholder height={desc ? 75 : 30} />}
        <div className="inputContainer">
          {inputArray.map((inputData) => (
            this.hwaInput(inputData)
          ))}
        </div>
      </HardwarePagesUnitArea>
    );
  }
}

InputGroupRender.propTypes = {
  data: PropTypes.object.isRequired,
  pagesIndex: PropTypes.number.isRequired,
  appConfig: PropTypes.object.isRequired,
  // width: PropTypes.string.isRequired,
  propsChildren: PropTypes.node,
  theme: PropTypes.object.isRequired,

};
InputGroupRender.defaultProps = {
  propsChildren: false,
};

const mapStateToProps = createStructuredSelector({
  departments: selectInfos('Departments'),
  locations: selectInfos('Locations'),
  currencies: selectInfos('Currencies'),
  producers: selectInfos('HardwareProducers'),
  memories: selectInfos('MemoryTypes'),
  situations: selectInfos('Situations'),
  OSPlatforms: selectInfos('OSPlatforms'),
  users: selectUsers(),
  apps: selectApplications(),
  devices: selectHardwareDevices(),
  appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ...
    }, dispatch)
  )
);

const InputGroupRenderWithTheme = withTheme(InputGroupRender);

const InputGroupRenderWithWidth = withWidth()(InputGroupRenderWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(InputGroupRenderWithWidth);

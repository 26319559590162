import { tra } from '../../../commons/utils';
import { ServerAccessory } from '../../statics';
import inventoryPages from '../public/publicInventory';
/*
 *   ---------------------  secondPages  ---------------------
*/

const secondPagesRow_1_column_1 = [{
  title: tra('Genel_Detaylar'),
  objectGroupName: 'Computer',
  inputArray: [
    {
      type: 'text',
      title: tra('Server_Ismi'),
      name: 'name',
      value: 'deneme bilgisayar',
      validateRules: 'required',
    },
    {
      type: 'text',
      title: tra('Servis_Etiketi'),
      name: 'service_brand',
      value: 'test makine',
      // validateRules: 'required',
    },
    {
      type: 'select',
      title: tra('Islemci_Markasi'),
      name: 'producer_id',
      value: 3,
      data: 'producers',
      inputProps: {
        emptyItem: true,
      },
      validateRules: 'required',
    },
    {
      type: 'text',
      title: tra('Islemci_Modeli'),
      name: 'model',
      value: 'thinkpad',
      // validateRules: 'required',
      childrenInput: [
        {
          type: 'number',
          title: tra('Islemci_Cekirdek_Sayisi'),
          name: 'process_core_number',
          value: '',
          validateRules: 'required',
          inputProps: {
            // allowNegative: true,
          },
        },
      ],
    },
    {
      setDefinedName: 'vm_type_platform_defined',
      type: 'checkbox', // kontrol et
      title: tra('Sanal_Makine_Ekle'),
      name: 'vm',
      value: 1,
    },
  ],
}];

const secondPagesRow_1_column_2 = [
  {
    // subtitle: '',
    spacerTitle: true,
    objectGroupName: 'OperatingSystem',
    inputArray: [
      {
        type: 'select',
        title: tra('Isletim_Sistemi'),
        name: 'os_producer_id',
        value: 1,
        data: 'OSPlatforms',
        inputProps: {
          emptyItem: true,
        },
        validateRules: 'required',
      },
      {
        type: 'text',
        title: tra('Isletim_Sistemi_Surumu'),
        name: 'os_version',
        value: 'OS X',
        validateRules: 'required',
      },
      {
        type: 'text',
        title: tra('Paket_Surumu'),
        name: 'service_pack',
        value: 'BIG-SUR',
        // validateRules: 'required',
      },

    ],
  },
  {
    inputArray: [
      {
        setDefinedName: 'ServerAccessories_defined',
        noService: true,
        type: 'autoComplete',
        title: tra('Aksesuarlar'),
        name: 'ServerAccessories',
        data: ServerAccessory,
        // value: 'BIG-SUR',

      },
    ],
  },
  {
    objectGroupName: 'Computer',
    inputArray: [{
      definedName: 'Computer.vm_type_platform_defined.true',
      type: 'select', // checkBox tıklanınca gelecek
      title: tra('Sanal_Makine_Platform'),
      name: 'vm_type_platform',
      value: 1,
      data: 'VMTypePlatforms',
      validateRules: 'required',
      inputProps: {
        emptyItem: true,
      },
    }],
  },
];

const secondPagesRow_2_column_1 = [{
  subtitle: tra('Ram'),
  addGroupName: 'Memories',
  groupValidateCheck: true,
  addButton: true,
  addGroupIndex: 0,
  inputArray: [
    // {
    //   type: 'text',
    //   title: tra('Adi'),
    //   name: 'name',
    //   value: 'example name',
    // },
    {
      type: 'text',
      title: tra('Boyutu'),
      name: 'capacity',
      value: '5 GB',
      validateRules: 'groupInputControl',
    },
    {
      type: 'select',
      title: tra('Tipi'),
      name: 'memory_type_id',
      value: 1,
      data: 'memories',
      inputProps: {
        emptyItem: true,
      },
      validateRules: 'groupInputControl',

    },
    {
      type: 'text',
      title: tra('Modeli'),
      name: 'model',
      value: 'deneme model',
      validateRules: 'groupInputControl',

    },
    {
      type: 'text',
      title: tra('Seri_No'),
      name: 'serial_no',
      value: 1,
      validateRules: 'groupInputControl',

    },
    {
      type: 'select',
      title: tra('Ureticisi_'),
      name: 'producer_id',
      value: 1,
      data: 'producers',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
  ],
}];

const secondPagesRow_2_column_2 = [{
  subtitle: tra('Hard_Diskler'),
  addGroupName: 'HardDisks',
  groupValidateCheck: true,
  addButton: true,
  addGroupIndex: 0,
  inputArray: [
    // {
    //   type: 'text',
    //   title: tra('Ismi'),
    //   name: 'name',
    //   value: 'deneme harddisk',
    // },
    {
      type: 'text',
      title: tra('Model'),
      name: 'model',
      value: '',
      validateRules: 'groupInputControl',
    },
    {
      type: 'text',
      title: tra('Seri_No'),
      name: 'serial_no',
      value: '111-AF-222',
      validateRules: 'groupInputControl',
    },
    {
      type: 'select',
      title: tra('Uretici'),
      name: 'producer_id',
      value: 1,
      data: 'producers',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
    {
      type: 'text',
      title: tra('Kapasite'),
      name: 'capacity',
      value: '8 GB',
      validateRules: 'groupInputControl',
    },
    {
      type: 'currency',
      title: tra('Okuma_Yazma_Hizi'),
      name: 'read_speed',
      value: '8 GB',
      validateRules: 'groupInputControl',
    },
    {
      type: 'select',
      title: tra('Tur'),
      name: 'disk_type',
      value: '8 GB',
      data: 'DiskTypes',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
    {
      type: 'select',
      title: tra('Soket_Tipi'),
      name: 'socket_type',
      value: '8 GB',
      data: 'SocketTypes',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
  ],
},
];

const secondPagesRow_3_column_1 = [
  {
    subtitle: tra('Network_Karti_Detaylari'),
    objectGroupName: 'NetworkCard',
    inputArray: [
      {
        type: 'text',
        title: tra('Ip_Adresi'),
        name: 'ip_address',
        value: '123.12.21.1',
        validateRules: 'required|ip:IP',
        inputProps: {
          maxLength: 15,
        },
      },
      {
        type: 'text',
        title: tra('Mac_Adres'),
        name: 'mac_address',
        value: 'FR:F4:43:56:66:I8:98',
        inputProps: {
          maxLength: 17,
        },
        validateRules: 'required|macAdress:MAC',
      },
      {
        type: 'number',
        title: tra('Nic'),
        name: 'nic',
        value: '10',
        // validateRules: 'required',
      },

    ],
  }];
const secondPagesRow_3_column_2 = [
  {
    // subtitle: tra('Network_Karti_Detaylari'),
    spacerTitle: true,
    objectGroupName: 'NetworkCard',
    inputArray: [{
      type: 'text',
      title: tra('Varsayilan_Ag_Gecidi'),
      name: 'estimated_network',
      value: 1,
      // validateRules: 'required|ip:Varsayılan Ağ Geçidi',
      validateRules: 'required|ip:IP',
      inputProps: {
        maxLength: 15,
      },
    },
    {
      setDefinedName: 'dhcp_active_defined',
      type: 'checkbox',
      title: tra('Dhcp_Etkin'),
      name: 'dhcp_active',
      value: 'true',
    },
    {
      definedName: 'NetworkCard.dhcp_active_defined.true',
      type: 'text',
      title: tra('Dhcp_Server'),
      name: 'dhcp_server',
      value: 1,
      validateRules: 'required|ip:DHCP Server',
      inputProps: {
        maxLength: 15,
      },
    },
    // {
    //   type: 'text',
    //   title: tra('Tur'),
    //   name: 'type',
    //   value: 'NORMAL',
    // }
    ],
  },
];
const secondPages = [
  secondPagesRow_1_column_1,
  secondPagesRow_1_column_2,
  secondPagesRow_2_column_1,
  secondPagesRow_2_column_2,
  secondPagesRow_3_column_1,
  secondPagesRow_3_column_2,
];

/*   ---------------------///  secondPages  ---------------------*/

const thirdPAgesRow_1_column_1 = [
  {
    title: tra('Girdi_Cikti_Cihazlar'),
    inputArray: [],
  },
];
const thirdPAgesRow_1_column_2 = [
  {
    spacerTitle: true,
    inputArray: [],
  },
];
const thirdPAgesRow_2_column_1 = [
  {
    definedName: 'ServerAccessories_defined.2',
    subtitle: tra('Klavye'),
    objectGroupName: 'Keyboard',
    removeButton: true,
    validateRules: 'required',
    inputArray: [
      {
        type: 'select',
        title: tra('Klavye_Type'),
        name: 'device_type',
        value: 'Keyboard',
        validateRules: 'required',
        data: 'DeviceTypes',
        inputProps: {
          emptyItem: true,
        },
      },
      // {
      //   type: 'text',
      //   title: tra('Klavye_Turu'),
      //   name: 'device_type',
      //   value: 2, // klavye olduğu anlamına geliyor 2 kullanıcının bu alanı doldurmasına gerek arka tarafta servise gönderilmesi gerekiyor
      // },
      {
        type: 'select',
        title: tra('Klavye_Uretici'),
        name: 'producer_id',
        value: 1,
        data: 'producers',
        validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  },
];

const thirdPAgesRow_2_column_2 = [
  {
    // defined: true,
    definedName: 'ServerAccessories_defined.1',
    subtitle: tra('Mouse'),
    objectGroupName: 'Mouse',
    removeButton: true,
    validateRules: 'required',
    inputArray: [
      {
        type: 'select',
        title: tra('Mouse_Type'),
        name: 'device_type',
        value: 'Mouse',
        data: 'DeviceTypes',
        inputProps: {
          emptyItem: true,
        },
      },
      // {
      //   type: 'text',
      //   title: tra('Mouse_Turu'),
      //   name: 'device_type', // id mouse anlamına geliyor. gizlenmesi lazım
      //   value: 1,
      // },
      {
        type: 'select',
        title: tra('Mouse_Uretici'),
        name: 'producer_id',
        value: 1,
        data: 'producers',
        validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  },
];

const thirdPAgesRow_3_column_1 = [
  {
    // defined: true,
    definedName: 'ServerAccessories_defined.3',
    subtitle: tra('Monitor'),
    addGroupName: 'Monitor',
    addButton: true,
    addGroupIndex: 0,
    removeButton: true,
    inputArray: [
      {
        type: 'text',
        title: tra('Seri_No'),
        name: 'serial_no',
        value: '4354-DERF-4343',
        validateRules: 'required',

      },
      {
        type: 'select',
        title: tra('Uretici'),
        name: 'producer_id',
        value: 1,
        data: 'producers',
        validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
      {
        type: 'select',
        title: tra('Cozunurluk'),
        name: 'resolution',
        value: '1920 X 1080',
        data: 'Resolutions',
        validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
      {
        type: 'select',
        title: tra('Monitor_Turu'),
        name: 'monitor_type', // değişecek
        value: '1920 X 1080',
        data: 'MonitorTypes',
        validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  },
];

const thirdPages = [
  thirdPAgesRow_1_column_1,
  thirdPAgesRow_1_column_2,
  thirdPAgesRow_2_column_1,
  thirdPAgesRow_2_column_2,
  thirdPAgesRow_3_column_1,
];

const fourthPages = [
  [[
    {
      definedName: 'Computer.vm_type_platform_defined.true',
      subtitle: tra('Sanal_Makine_Detaylari'),
      addGroupName: 'VirtualMachine',
      inputArray: [
        {
          type: 'text',
          title: tra('Envanter_Etiketi'),
          name: 'tag', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'text',
          title: tra('Lisans_Anahtari'),
          name: 'licence_key', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'select',
          title: tra('Ureticisi_'),
          name: 'producer_id',
          value: 1,
          data: 'producers',
          validateRules: 'required',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'text',
          title: tra('Model'),
          name: 'model', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'select',
          title: tra('Tedarikci'),
          name: 'vendor_id', // değişecek
          value: '1920 X 1080',
          data: 'vendors',
          // validateRules: 'required',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'select',
          title: tra('Lokasyon'),
          name: 'location_id', // değişecek
          value: '1920 X 1080',
          data: 'locations',
          validateRules: 'required',
          inputProps: {
            emptyItem: true,
          },
        },
      ],
    },
  ], [
    {
      definedName: 'Computer.vm_type_platform_defined.true',
      spacerTitle: true,
      inputArray: [
        {
          type: 'date',
          title: tra('Satin_Alinma_Tarihi'),
          name: 'first_usage_date', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'date',
          title: tra('Sona_Erme_Tarihi'),
          name: 'exp_date', // değişecek
          value: '1920 X 1080',
        },
        {
          type: 'select',
          title: tra('Durum'),
          name: 'situation',
          value: 1,
          data: 'situations',
          validateRules: 'required',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'select',
          title: tra('Departman'),
          name: 'department_id',
          value: 1,
          data: 'departments',
          validateRules: 'required',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'select',
          title: tra('Sorumlu_Kisi'),
          name: 'user_id',
          value: 7,
          data: 'users',
          inputProps: {
            emptyItem: true,
          },
          validateRules: 'required',
        },
        {
          type: 'currency',
          title: tra('Satin_Alma_Maliyeti'),
          name: 'cost', // değişecek
          value: '1920 X 1080',
          childrenInput: [
            {
              type: 'select',
              title: tra('Para_Birimi'),
              name: 'currencies_id',
              value: 1,
              data: 'currencies',
              inputProps: {
                emptyItem: true,
              },
              validateRules: 'required',
            },
          ],
        },
      ],
    },
  ]],
];

export default [inventoryPages, secondPages, thirdPages, fourthPages];

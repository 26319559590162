import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sheet from 'react-modal-sheet';

const SheetStyle = styled(Sheet)`
  z-index: 0 !important;
  position: inherit !important;
  .react-modal-sheet-backdrop {
    /* custom styles */
  }
  .react-modal-sheet-container {
    position: fixed !important;
    background: ${(props) => props.theme.mainBackg} !important;
    /* custom styles */
  }
  .react-modal-sheet-header {
    background: ${(props) => props.theme.mainBackg} !important;
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    /* custom styles */
  }
  .react-modal-sheet-content {
    /* custom styles */
  }
`;

export default function BottomSheet(props) {
  const [open, setOpen] = React.useState(false);
  const [childHeight, setChildHeight] = React.useState(100);
  const [snapPoint, setSnapPoint] = React.useState(0);
  const [initialSnap, setInitialSnap] = React.useState(0);
  const [initialSheetConfig, setInitialSheetConfig] = React.useState({});

  React.useEffect(() => {
    setOpen(props.open);
    setSnapPoint(props.snapPoint);
    setInitialSnap(props.initialSnap);
    const snapPointConfig = snapPoint.length === 1 ? [childHeight, snapPoint[0]] : snapPoint;
    setInitialSheetConfig(childHeight < 700 ? { snapPoints: snapPointConfig, initialSnap } : {});
    return () => {
      setOpen(false);
      setSnapPoint(0);
      setInitialSnap(0);
      setInitialSheetConfig({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleSwipe = (value) => {
    setOpen(value);
    props.isOpen(value);
  };

  return (
    <SheetStyle
      isOpen={open}
      onClose={() => handleSwipe(false)}
      {...initialSheetConfig}
      springConfig={{ stiffness: 150, damping: 20, mass: 1 }}
    >
      <SheetStyle.Container>
        <SheetStyle.Header />
        <SheetStyle.Content>
          {
            React.cloneElement(
              props.children,
              {
                ref: (node) => node && setChildHeight(node.clientHeight + 50),
              },
            )
          }
        </SheetStyle.Content>
      </SheetStyle.Container>

      <SheetStyle.Backdrop style={props.backdrop ? {} : { display: 'none' }} />
    </SheetStyle>

  );
}

BottomSheet.propTypes = {
  open: PropTypes.func.isRequired,
  isOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
  backdrop: PropTypes.string,
  snapPoint: PropTypes.array,
  initialSnap: PropTypes.number,
};

BottomSheet.defaultProps = {
  backdrop: false,
  snapPoint: [0],
  initialSnap: 0,
  isOpen: () => { },
};

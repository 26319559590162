import * as constants from './constants';

export const initialState = {
  responseState: {},
  toast: { open: false, message: '', variant: 'success' },
  loginState: false,
  loading: false,
  initLoading: false,
  infos: {
    Plans: [],
    Tags: [],
    Platforms: [],
    Processes: [],
    Locations: [],
    Departments: [],
    Permissions: [],
    Vendors: [],
    Headers: [],
    LicensePlan: [],
    InputTypes: [],
    Currencies: [],
    Languages: [],
    LegalOwners: [],
    def: [],
    AppUserTypes: [],
    Situations: [],
    All: [],
  },
  users: [],
  applications: false,
  applicationLog: false,
  comments: false,
  activeUser: {},
  profileData: {},
  resetState: false,
  application: null,
  dashboardData: {},
  costGraph: false,
  additionalCost: false,
  vendorProcessMatrix: false,
  timeModel: false,
  calendar: false,
  appConfig: {
    editDashboard: false,
    disabled: true,
    theme: 'light',
  },
  inventoryConfig: {
    tableType: 'Completed',
  },
  costingConfig: {
    costingTypeMenu: 'Software',
  },
  filterDrawer: false,
  createdEvent: {},
  events: {
    [new Date().getFullYear()]: [],
  },
  surveyAssignData: {},
  surveyUsers: [],
  surveyList: false,
  surveyDetail: false,
  singleSurveyDetail: false,
  notifications: false,
  socket: null,
  assignedSurveys: false,
  surveyQuestions: false,
  singleSurvey: {},
  addCompanyModal: false,
  adminCompanies: [],
  adminCompanyUsers: {},
  companyDepartments: [],
  integrationComponents: false,
  integrationComponentsDetail: [],
  integration: [],
  integrationComponentsDetailUser: [],
  surveyAnswerBookmark: [],
  regAccountTypes: [],
  regLanguages: [],
  regCurrencies: [],
  regComplete: false,
  activationComplete: false,
  passResetComplete: false,
  surveyDetailApps: {},
  surveyReportQuestions: {},
  answerOptionUsers: [],
  activeNotFound: false,
  inventoryRegisterFiles: [],
  inventoryRegisterFileUrls: [],
  hardwareModalTypes: [],
  hardwareModalCategory: [],
  hardwareDevices: [],
  hardwareDeviceDetail: {},
  hardwareLog: false,
  costingCategories: [],
  costingList: false,
  costingDetail: null,
  costingFiles: [],
  costingFileUrls: [],
  costingLogs: [],
  applicationContracts: [],
  costGroup: {},
  integrationMessages: [],
  integrationChannels: [],
  applicationDrafts: [],
  applicationDraft: {},
  applicationLifecycle: {},
  integrationRegisterFiles: [],
  integrationRegisterFileUrls: [],
  excelErrorList: [],
  excelErrorModal: false,
  ldapUsers: [],
  sendToDetailPageID: false,
  contractYears: [],
  sendToView: false,
  vendorUsers: [],
  applicationReports: [],
  aggregationReports: [],
  hwAggregationReports: [],
  generalReportType: 'applications',
  appCostReport: [],
  perBusinessCapability: null,
  perITDomain: null,
  perBusinessDomain: null,
  dashboardMapReports: [],
  dashboardCostingDetail: {},
  userReports: [],
  usersReports: [],
  departmentReport: [],
  hwCostingList: false,
  hwCostingDetail: {},
  hwCostingCategories: [],
  hwCostingExpenses: [],
  hwCostingLogs: [],
  licenseFollow: {
    tableList: [],
    agreement: [],
    user: [],
  },
  licenseFollowDetail: {},
  licenseFollowDetailLog: [],
  integrationPiUrlId: -1,
  integrationInitComponentData: [],
  integrationResponsibleData: [],
  integrationMessageLog: [],
  integrationMessageMonitoringSendMailUser: [],

};

export default function store(state = initialState, action) {
  const actions = action;
  switch (actions.type) {
    case constants.RESPONSE_STATE: {
      return { ...state, responseState: { ...state.responseState, [action.name]: action.statu } };
    }
    case constants.SET_TOAST: {
      const oldToast = state.toast;
      const toast = { open: action.value, message: action.message, variant: action.variant };
      if (!action.value) {
        toast.message = '';
        toast.variant = oldToast.variant;
      }
      return { ...state, toast };
    }
    case constants.SET_LOGIN_STATE: {
      return {
        ...state,
        loginState: action.value,
      };
    }
    case constants.SET_INFO: {
      const datas = action.data;
      datas.def = [
        'Plans', 'Tags', 'Platforms', 'Processes', 'Locations', 'Departments', 'TcoAggregations',
        'Headers', 'Permissions', 'Vendors', 'LicensePlan', 'InputTypes', 'Currencies', 'DataPlans',
        'Languages', 'Scopes', 'FunctionalAreas', 'Categories', 'LegalOwners', 'UserBases', 'AppUserTypes',
      ];
      return { ...state, infos: { ...state.infos, ...datas } };
    }
    case constants.SET_USERS: {
      return { ...state, users: action.data };
    }
    case constants.SET_APPLICATIONS: {
      return { ...state, applications: action.data };
    }
    case constants.SET_APPLICATIONS_LOG: {
      return { ...state, applicationLog: action.data };
    }
    case constants.SET_COMMENTS: {
      return { ...state, comments: action.data };
    }
    case constants.SET_ACTIVE_USER: {
      return { ...state, activeUser: action.data };
    }
    // case constants.SET_PROFILE: {
    //   return { ...state, profileData: action.data };
    // }
    case constants.SET_PROFILE_INFO: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          info: action.data,
        },
      };
    }
    case constants.SET_PROFILE_HARDWARE: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          hardware: action.data,
        },
      };
    }
    case constants.SET_PROFILE_APPLICATION: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          application: action.data,
        },
      };
    }
    case constants.SET_PROFILE_SURVEY: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          survey: action.data,
        },
      };
    }
    case constants.SET_PROFILE_LOG: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          log: action.data,
        },
      };
    }
    case constants.SET_RESET_STATE: {
      return { ...state, resetState: action.value };
    }
    case constants.SET_APPLICATION_DETAIL: {
      return { ...state, application: action.data };
    }
    case constants.SET_DASHBOARD: {
      return { ...state, dashboardData: action.data };
    }
    case constants.SET_COSTGRAPH: {
      return { ...state, costGraph: action.data };
    }
    case constants.SET_ADDITIONALCOST: {
      return { ...state, additionalCost: action.data };
    }
    case constants.SET_VENDORPROCESSMATRIX: {
      return { ...state, vendorProcessMatrix: action.data };
    }
    case constants.SET_APPCONFIG: {
      const appConfig = { ...state.appConfig };
      if (typeof action.key === 'object') {
        for (let i = 0; i < action.key.length; i++) {
          appConfig[Object.keys(actions.key[i])] = Object.values(actions.key[i]).toString();
        }
      } else {
        appConfig[action.key] = action.value;
      }
      return { ...state, appConfig };
    }
    case constants.SET_CALENDAR: {
      return { ...state, calendar: action.data };
    }
    case constants.SET_FILTER_DRAWER: {
      return { ...state, filterDrawer: action.value };
    }
    case constants.SET_EVENT_CREATE: {
      return { ...state, createdEvent: action.data };
    }
    case constants.SET_EVENTS: {
      const eventList = { ...state.events };
      eventList[action.year] = action.data;
      // if (!eventList[action.year + 1]) {
      //   eventList[action.year + 1] = [];
      // }
      // if (!eventList[action.year - 1]) {
      //   eventList[action.year - 1] = [];
      // }
      return { ...state, events: eventList };
    }
    case constants.SET_SURVEY_ASSIGN_DATA: {
      return { ...state, surveyAssignData: action.data };
    }
    case constants.SET_SURVEY_USERS: {
      return { ...state, surveyUsers: action.data };
    }
    case constants.SET_SURVEY_ANSWER_BOOKMARK: {
      return { ...state, surveyAnswerBookmark: action.data };
    }
    case constants.SET_SOCKET: {
      return { ...state, socket: action.socket };
    }
    case constants.SET_NOTIFICATIONS: {
      if (action.isNew) {
        const oldNotifications = [...state.notifications];
        if (oldNotifications.findIndex((n) => n.id === action.data[0].id) === -1) {
          oldNotifications.unshift(...action.data);
          return { ...state, notifications: oldNotifications };
        }
        return { ...state, notifications: oldNotifications };
      }
      return { ...state, notifications: action.data };
    }
    case constants.SET_SURVEY_LIST: {
      return { ...state, surveyList: action.data };
    }
    case constants.SET_SURVEY_DETAIL: {
      return { ...state, surveyDetail: action.data };
    }
    case constants.SET_SINGLE_SURVEY_DETAIL: {
      return { ...state, singleSurveyDetail: action.data };
    }
    case constants.SET_ASSIGNED_SURVEYS: {
      return { ...state, assignedSurveys: action.data };
    }
    case constants.SET_SURVEY_QUESTIONS: {
      return { ...state, surveyQuestions: action.data };
    }
    case constants.SET_SINGLE_SURVEY: {
      return { ...state, singleSurvey: action.data };
    }
    case constants.SET_LOADING: {
      return { ...state, loading: action.value };
    }
    case constants.SET_INIT_LOADING: {
      return { ...state, initLoading: action.value };
    }
    case constants.ADD_COMPANY_MODAL: {
      return { ...state, addCompanyModal: action.value };
    }
    case constants.SET_TIME_MODEL: {
      return { ...state, timeModel: action.data };
    }
    case constants.SET_ADMIN_COMPANIES: {
      return { ...state, adminCompanies: action.data };
    }
    case constants.SET_ADMIN_COMPANY_USERS: {
      return { ...state, adminCompanyUsers: action.data };
    }
    case constants.SET_COMPANY_DEPARTMENTS: {
      return { ...state, companyDepartments: action.data };
    }
    case constants.SET_INTEGRATION_COMPONENTS: {
      return { ...state, integrationComponents: action.data };
    }
    case constants.SET_INTEGRATION_COMPONENTS_DETAIL: {
      return { ...state, integrationComponentsDetail: action.data };
    }
    case constants.SET_INTEGRATION: {
      return { ...state, integration: action.data };
    }
    case constants.SET_INTEGRATION_COMPONENTS_DETAIL_USER: {
      return { ...state, integrationComponentsDetailUser: action.data };
    }
    case constants.SET_REG_ACCOUNT_TYPES: {
      return { ...state, regAccountTypes: action.data };
    }
    case constants.SET_REG_CURRENCIES: {
      return { ...state, regCurrencies: action.data };
    }
    case constants.SET_REG_LANGUAGES: {
      return { ...state, regLanguages: action.data };
    }
    case constants.SET_REGISTER_COMPLETE: {
      return { ...state, regComplete: action.value };
    }
    case constants.SET_ACTIVATION_COMPLETE: {
      return { ...state, activationComplete: action.value };
    }
    case constants.SET_PASSWORD_RESET_COMPLETE: {
      return { ...state, passResetComplete: action.value };
    }
    case constants.SET_SURVEY_DETAIL_APPS: {
      return { ...state, surveyDetailApps: action.data };
    }
    case constants.SET_SURVEY_REPORT_QUESTIONS: {
      return { ...state, surveyReportQuestions: action.data };
    }
    case constants.SET_ANSWER_OPTION_USERS: {
      return { ...state, answerOptionUsers: action.data };
    }
    case constants.SET_ACTIVE_NOT_FOUND: {
      return { ...state, activeNotFound: action.value };
    }
    case constants.SET_INVENTORY_REGISTER_FILES: {
      return { ...state, inventoryRegisterFiles: action.data };
    }
    case constants.SET_INVENTORY_REGISTER_FILE_URLS: {
      return { ...state, inventoryRegisterFileUrls: action.data };
    }
    case constants.SET_HARDWARE_MODAL_TYPES: {
      return { ...state, hardwareModalTypes: action.data };
    }
    case constants.SET_HARDWARE_MODAL_CATEGORY: {
      return { ...state, hardwareModalCategory: action.data };
    }
    case constants.SET_HARDWARE_DEVICES: {
      return { ...state, hardwareDevices: action.data };
    }
    case constants.SET_HARDWARE_DEVICE_DETAIL: {
      return { ...state, hardwareDeviceDetail: action.data };
    }
    case constants.SET_COSTING_CATEGORIES: {
      return { ...state, costingCategories: action.data };
    }
    case constants.SET_COSTING: {
      return { ...state, costingList: action.data };
    }
    case constants.SET_COSTING_DETAIL: {
      return { ...state, costingDetail: action.data };
    }
    case constants.SET_COSTING_FILES: {
      return { ...state, costingFiles: action.data };
    }
    case constants.SET_APPLICATION_CONTRACTS: {
      return { ...state, applicationContracts: action.data };
    }
    case constants.SET_COSTING_LOGS: {
      return { ...state, costingLogs: action.data };
    }
    case constants.SET_HARDWARE_LOG: {
      return { ...state, hardwareLog: action.data };
    }
    case constants.SET_COSTING_FILE_URLS: {
      return { ...state, costingFileUrls: action.data };
    }
    case constants.SET_COST_GROUP: {
      return { ...state, costGroup: action.data };
    }
    case constants.SET_INTEGRATION_MESSAGES: {
      return { ...state, integrationMessages: action.data };
    }
    case constants.SET_INTEGRATION_CHANNEL: {
      return { ...state, integrationChannels: action.data };
    }
    case constants.SET_APPLICATION_DRAFTS: {
      return { ...state, applicationDrafts: action.data };
    }
    case constants.SET_APPLICATION_DRAFT: {
      return { ...state, applicationDraft: action.data };
    }
    case constants.SET_APPLICATION_LIFECYCLE: {
      return { ...state, applicationLifecycle: action.data };
    }
    case constants.SET_INVENTORY_CONFIG: {
      const inventoryConfig = { ...state.inventoryConfig };
      if (typeof action.key === 'object') {
        for (let i = 0; i < action.key.length; i++) {
          inventoryConfig[Object.keys(actions.key[i])] = Object.values(actions.key[i]).toString();
        }
      } else {
        inventoryConfig[action.key] = action.value;
      }
      return { ...state, inventoryConfig };
    }
    case constants.SET_COSTING_CONFIG: {
      const costingConfig = { ...state.costingConfig };
      if (typeof action.key === 'object') {
        for (let i = 0; i < action.key.length; i++) {
          costingConfig[Object.keys(actions.key[i])] = Object.values(actions.key[i]).toString();
        }
      } else {
        costingConfig[action.key] = action.value;
      }
      return { ...state, costingConfig };
    }
    case constants.SET_INTEGRATION_REGISTER_FILES: {
      return { ...state, integrationRegisterFiles: action.data };
    }
    case constants.SET_INTEGRATION_REGISTER_FILE_URLS: {
      return { ...state, integrationRegisterFilesUrls: action.data };
    }
    case constants.SET_EXCEL_ERROR_LIST: {
      return { ...state, excelErrorList: action.data };
    }
    case constants.SET_EXCEL_ERROR_MODAL: {
      return { ...state, excelErrorModal: action.value };
    }
    case constants.SET_LDAP_USERS: {
      return { ...state, ldapUsers: action.data };
    }
    case constants.SET_SEND_TO_DETAIL_PAGE_ID: {
      return { ...state, sendToDetailPageID: action.id };
    }
    case constants.SET_CONTRACT_YEARS: {
      return { ...state, contractYears: action.data };
    }
    case constants.SET_SEND_TO_VIEW: {
      return { ...state, sendToView: action.value };
    }
    case constants.SET_VENDOR_USERS: {
      return { ...state, vendorUsers: action.data };
    }
    case constants.SET_APPLICATION_REPORTS: {
      return { ...state, applicationReports: action.data };
    }
    case constants.SET_AGGREGATION_REPORTS: {
      return { ...state, aggregationReports: action.data };
    }
    case constants.SET_HW_AGGREGATION_REPORTS: {
      return { ...state, hwAggregationReports: action.data };
    }
    case constants.SET_USER_REPORTS: {
      return { ...state, userReports: action.data };
    }
    case constants.SET_USERS_REPORTS: {
      return { ...state, usersReports: action.data };
    }
    case constants.SET_GENERAL_REPORT_TYPE: {
      return { ...state, generalReportType: action.value };
    }
    case constants.SET_APP_COST_REPORT: {
      return { ...state, appCostReport: action.data };
    }
    case constants.SET_PER_BUSINESS_CAPABILITY: {
      return { ...state, perBusinessCapability: action.data };
    }
    case constants.SET_PER_IT_DOMAIN: {
      return { ...state, perITDomain: action.data };
    }
    case constants.SET_PER_BUSINESS_DOMAIN: {
      return { ...state, perBusinessDomain: action.data };
    }
    case constants.SET_DASHBOARD_MAP_REPORT: {
      return { ...state, dashboardMapReports: action.data };
    }
    case constants.SET_DASHBOARD_COSTING_DETAIL: {
      return { ...state, dashboardCostingDetail: action.data };
    }
    case constants.SET_HWCOSTING_LIST: {
      return { ...state, hwCostingList: action.data };
    }
    case constants.SET_HWCOSTING_DETAIL: {
      return { ...state, hwCostingDetail: action.data };
    }
    case constants.SET_DEPARTMENT_REPORT: {
      return { ...state, departmentReport: action.data };
    }
    case constants.SET_HWCOSTING_CATEGORIES: {
      return { ...state, hwCostingCategories: action.data };
    }
    case constants.SET_HWCOSTING_EXPENSES: {
      return { ...state, hwCostingExpenses: action.data };
    }
    case constants.SET_HWCOSTING_LOGS: {
      return { ...state, hwCostingLogs: action.data };
    }
    case constants.SET_LICENSE_FOLLOW: {
      return {
        ...state,
        licenseFollow: { ...state.licenseFollow, tableList: action.data },
      };
    }
    case constants.SET_LICENSE_FOLLOW_AGREEMENT: {
      return {
        ...state,
        licenseFollow: { ...state.licenseFollow, agreement: action.data },
      };
    }
    case constants.SET_LICENSE_FOLLOW_USER: {
      return {
        ...state,
        licenseFollow: { ...state.licenseFollow, user: action.data },
      };
    }
    case constants.SET_LICENSE_FOLLOW_DETAIL: {
      return {
        ...state, licenseFollowDetail: action.data,
      };
    }
    case constants.SET_LICENSE_FOLLOW_LOG: {
      return { ...state, licenseFollowDetailLog: action.data };
    }
    case constants.SET_INTEGRATION_PI_URL: {
      return { ...state, integrationPiUrlId: action.id };
    }
    case constants.SET_INTEGRATION_COMPONENT_INIT_DATA: {
      return { ...state, integrationInitComponentData: action.data };
    }
    case constants.SET_INTEGRATION_RESPONSIBLE: {
      return { ...state, integrationResponsibleData: action.data };
    }
    case constants.SET_INTEGRATION_MESSAGE_LOG: {
      return { ...state, integrationMessageLog: action.data };
    }
    case constants.SET_MESSAGE_MONITORING_SEND_MAIL_USERS: {
      return { ...state, integrationMessageMonitoringSendMailUser: action.data };
    }
    default: {
      return state;
    }
  }
}

/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Check,
  Close, Delete, DeleteOutline, ErrorOutline, MoreVert, Public, UnfoldMore, // Remove,
} from '@material-ui/icons';
import {
  Drawer, Grid, IconButton, MenuItem, Slide, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Colors } from '../../assets/statics';
import {
  DocumentPDF, EditNew, Plus, SaveNew, HistoryIcon,
} from '../../assets/icons';
import {
  getCostingDetail, getCostingCategories, setCostingDetail, updateCosting, setToast, getCosting,
  setCostingFileUrls, deleteFile, getApplications, deleteCosting, getVendorUsers,
} from '../../store/actions';
import {
  selectApplications, selectInfos, selectCostingDetail, selectCostingCategories, selectCostingLogs, selectActiveUser, selectCostingList, selectCostingFileUrls, selectVendorUsers,
} from '../../store/selectors';
import {
  CommaStringToNumber, encryptTokenLink, FormatCurrency,
  tra,
} from '../../commons/utils';
import Logs from '../../components/Logs';
import {
  InputWrapper, PageWrapper, PageContent, CustomButton, GridPageWrapper, ActionFooter, CustomMenu,
} from '../../assets/styledNew';
// import AllocationPie from '../../components/charts/AllocationPie';
import { CostingContext } from '../../commons/context/CostingContext';
import CostingDocModal from '../../components/modals/CostingDocModal';
import AddAppToContract from '../../components/modals/AddAppToContract';
import FastInput from '../../components/costing/CostFastInput';
import Validator from '../../commons/validator';
import UpdateWarnModal from '../../components/modals/UpdateWarnModal';
import DeleteModal from '../../components/modals/DeleteModal';
import AllocationPie from '../../components/charts/AllocationPie';

class CostingDetail extends Component {
  static contextType = CostingContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      costId: '',
      logDrawer: false,
      disabled: true,
      controlModal: false,
      compCurrency: '',
      contractsArr: [],
      fileUrlArr: [],
      removeCheckClick: false,
    };
    props.getVendorUsers();
    props.getCosting();
    props.getCostingCategories();
    props.getApplications();
    props.getCostingDetail(props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const costId = this.props.match.params.id;
    const { setCostingState, setCostingPageVariables, CostingPageVariables } = this.context;
    const {
      costingDetail, applications, locations, vendors,
    } = this.props;
    if (costingDetail && (costingDetail?.vendor_id || vendors) && applications.length > 0 && costingDetail && costingDetail?.id && (costingDetail?.phone_code === null || locations.length > 0) && (costingDetail?.id.toString() !== costId || costingDetail?.id.toString() !== prevState.costId.toString())) {
      const costingApps = applications.filter((item) => costingDetail.Applications.find((app) => item.id === app.id));
      const selectedPhoneCode = locations.find((item) => item.dial_code === costingDetail.phone_code);
      const firstDataCostUrls = costingDetail.ContractUrls.map((item) => item.url);
      const vendor = vendors.find((item) => item.id === costingDetail?.vendor_id);
      const tempGenericContItems = [];
      for (let i = 0; i < costingDetail.GenericContItems.length; ++i) {
        const obj = {
          monthly_inv: costingDetail.GenericContItems[i].monthly_inv,
          total_inv: costingDetail.GenericContItems[i].total_inv,
          total_cost: costingDetail.GenericContItems[i].total_cost,
          monthly_cost: costingDetail.GenericContItems[i].monthly_cost,
          invCurr_type: costingDetail.GenericContItems[i].invCurr_type,
          period: costingDetail.GenericContItems[i].period,
          contract_start: costingDetail.GenericContItems[i].contract_start,
          contract_end: costingDetail.GenericContItems[i].contract_end,
          calculated_end: costingDetail.GenericContItems[i].calculated_end,
          purchase_date: costingDetail.GenericContItems[i].purchase_date,
          fx_rate: costingDetail.GenericContItems[i].fx_rate,
          tco_agg: costingDetail.GenericContItems[i].tco_agg,
        };
        tempGenericContItems.push(obj);
      }
      const newData = {
        ...costingDetail,
        vendor_id: vendor,
        phone_code: selectedPhoneCode,
        Applications: costingApps,
        ContractUrls: firstDataCostUrls,
        GenericContItems: tempGenericContItems,
      };
      this.props.setCostingFileUrls(firstDataCostUrls);
      const newSendApps = [];
      let allocTotal = 0;
      for (let i = 0; i < costingDetail.Applications.length; ++i) {
        const newObj = {
          id: costingDetail.Applications[i].id,
          allocation: costingDetail.Applications[i].allocation,
          value: costingDetail.Applications[i].value,
          value_inv: 1,
        };
        allocTotal += CostingPageVariables.appValueStatus === 2 ? costingDetail.Applications[i].allocation : costingDetail.Applications[i].value;
        newSendApps.push(newObj);
      }
      setCostingState(newData);
      setCostingPageVariables((prvState) => ({
        ...prvState,
        allocationTotal: allocTotal,
        sendApplications: newSendApps,
        costType: costingDetail.costing_type,
        addAppModal: false,
        costingDocModal: false,
      }));
      this.setState({
        costId: costingDetail.id,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const paramCostId = props.match.params.id;
    const { costingDetail, categories, costingList } = props;
    if (costingList.length > 0 && costingList.filter((item) => item.id.toString() === paramCostId.toString()).length === 0) {
      props.setCostingDetail(null);
      window.location.href = '#/404';
      return null;
    }
    if (paramCostId !== undefined && props.costingDetail && props.costingDetail?.id !== parseInt(paramCostId, 10)) {
      props.getCostingDetail(paramCostId);
    }
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.name;
      return {
        compCurrency: currency,
      };
    }
    if (costingDetail && costingDetail.costing_type && categories.length > 0 && state.contractsArr.length === 0) {
      return {
        contractsArr: categories.find((item) => item.id === costingDetail.costing_type).CostingCategories,
        fileUrlArr: costingDetail.ContractUrls.map((item) => item.url),
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({
      costId: '',
      logDrawer: false,
      disabled: true,
      controlModal: false,
    });
    this.props.setCostingDetail(null);
    this.context.setReset();
  }

  handleBackFirstData = () => {
    const {
      costingDetail, applications, locations, vendors,
    } = this.props;
    const costingApps = applications.filter((item) => costingDetail.Applications.find((app) => item.id === app.id));
    const selectedPhoneCode = locations.find((item) => item.dial_code === costingDetail.phone_code);
    const firstDataCostUrls = costingDetail.ContractUrls.map((item) => item.url);
    const vendor = vendors.find((item) => item.id === costingDetail?.vendor_id);
    const tempGenericContItems = [];
    for (let i = 0; i < costingDetail.GenericContItems.length; ++i) {
      const obj = {
        monthly_inv: costingDetail.GenericContItems[i].monthly_inv,
        total_inv: costingDetail.GenericContItems[i].total_inv,
        total_cost: costingDetail.GenericContItems[i].total_cost,
        monthly_cost: costingDetail.GenericContItems[i].monthly_cost,
        invCurr_type: costingDetail.GenericContItems[i].invCurr_type,
        period: costingDetail.GenericContItems[i].period,
        contract_start: costingDetail.GenericContItems[i].contract_start,
        contract_end: costingDetail.GenericContItems[i].contract_end,
        calculated_end: costingDetail.GenericContItems[i].calculated_end,
        purchase_date: costingDetail.GenericContItems[i].purchase_date,
        fx_rate: costingDetail.GenericContItems[i].fx_rate,
        tco_agg: costingDetail.GenericContItems[i].tco_agg,
      };
      tempGenericContItems.push(obj);
    }
    const newData = {
      ...costingDetail,
      vendor_id: vendor,
      phone_code: selectedPhoneCode,
      Applications: costingApps,
      ContractUrls: firstDataCostUrls,
      GenericContItems: tempGenericContItems,
    };
    let allocTotal = 0;
    const newSendApps = [];
    for (let i = 0; i < costingDetail.Applications.length; ++i) {
      const newObj = {
        id: costingDetail.Applications[i].id,
        allocation: costingDetail.Applications[i].allocation,
        value: costingDetail.Applications[i].value,
        value_inv: 1,
      };
      allocTotal += costingDetail.Applications[i].value;
      newSendApps.push(newObj);
    }
    const pageData = {
      sendApplications: newSendApps,
      allocationTotal: allocTotal,
      costType: costingDetail.costing_type,
      addAppModal: false,
      costingDocModal: false,
    };
    newData.phone_code = selectedPhoneCode;
    this.context.setContextCostingState(newData);
    this.props.setCostingFileUrls(firstDataCostUrls);
    this.context.setCostingPageVariables(pageData);
    Validator.hideMessages();
    this.setState({ disabled: true, fileUrlArr: firstDataCostUrls });
  }

  handleSaveContract = () => {
    const { CostingState, CostingPageVariables } = this.context;
    const { costingFileUrls } = this.props;
    const updatedData = { ...CostingState };
    updatedData.Applications = CostingPageVariables.sendApplications;
    updatedData.ContractUrls = costingFileUrls;
    for (let i = 0; i < updatedData.GenericContItems.length; ++i) {
      delete updatedData.GenericContItems[i].id;
      delete updatedData.GenericContItems[i].updatedAt;
      delete updatedData.GenericContItems[i].createdAt;
      delete updatedData.GenericContItems[i].contract_id;
      updatedData.GenericContItems[i].fx_rate = CommaStringToNumber(updatedData.GenericContItems[i].fx_rate);
      const contractStart = new Date(updatedData.GenericContItems[i].contract_start).setHours(13, 0, 0, 0);
      const contractEnd = new Date(updatedData.GenericContItems[i].contract_end).setHours(13, 0, 0, 0);
      const calculatedEnd = new Date(updatedData.GenericContItems[i].calculated_end).setHours(13, 0, 0, 0);
      if (updatedData.GenericContItems[i].purchase_date !== null) {
        const purchaseDate = new Date(updatedData.GenericContItems[i].purchase_date).setHours(13, 0, 0, 0);
        updatedData.GenericContItems[i].purchase_date = new Date(purchaseDate);
      }
      updatedData.GenericContItems[i].contract_start = new Date(contractStart);
      updatedData.GenericContItems[i].contract_end = new Date(contractEnd);
      updatedData.GenericContItems[i].calculated_end = new Date(calculatedEnd);
    }
    updatedData.total = CommaStringToNumber(updatedData.total);
    updatedData.total_inv = CommaStringToNumber(updatedData.total_inv);
    updatedData.phone_code = updatedData.phone_code?.dial_code;
    updatedData.vendor_id = updatedData?.vendor_id?.id;
    delete updatedData.id;
    delete updatedData.costing_type;
    delete updatedData.ContractFiles;
    if (CostingPageVariables.sendApplications.length > 0) {
      if (!Validator.allValid()) {
        Validator.showMessages();
        this.setState({ controlModal: false });
        this.forceUpdate();
        this.props.setToast(true, tra('Lutfen_Zorunlu_Alanlari_Doldurun'), 'warning');
      } else if (CostingPageVariables.allocationTotal - (CostingPageVariables.appValueStatus === 2 ? 100 : CostingState.total) !== 0) {
        this.props.setToast(true, tra('Variance_Control_Message'), 'warning');
        this.setState({ controlModal: false });
      } else {
        this.props.updateCosting(updatedData, CostingState.id);
        this.setState({ disabled: true, controlModal: false });
      }
    } else {
      this.props.setToast(true, tra('Uygulama_Ekle_Mesaji'), 'warning');
    }
  }

  fileGenerateLink = async (fileObj) => {
    const { costingDetail } = this.props;
    const data = JSON.stringify({
      contract_id: costingDetail.id,
      extension: fileObj.extension,
      path: fileObj.filename,
      timeout: 60,
      date: new Date(),
    });
    const token = await encryptTokenLink(data);
    window.open(`${fileObj.base_url}?token=${token.toString()}`, '_blank').focus();
  }

  renderCosting = () => {
    const {
      CostingState, CostingPageVariables, removeApp, changeFxRate, setCostingPageVariables, handleChangeAppValue, handlePhoneCodeChange,
      handleChangeAppValueAllocation, handleDateChange, handleChangeCurrency, handleChange, changeAgrAutoComplete, changeVendorUserFree,
      handlePhoneChange, handleChangeDepreciationPeriod, addNewContract, deleteContract, changeAppValueStatus, changeAppValueStatusMenu,
      autoCompChange,
    } = this.context;
    const {
      disabled, compCurrency, contractsArr, fileUrlArr, removeCheckClick,
    } = this.state;
    const {
      categories, applications, vendors, currencies, tcoAggregations, costingList, locations, costingDetail, vendorUsers,
    } = this.props;
    const costId = this.props.match.params.id;
    Validator.purgeFields();
    return (
      <Grid container spacing={2}>
        <Grid className="gridRow" container item xs={12} spacing={3}>
          <Grid className="rowLeft" item xs={6}>
            <div className="box">
              <div className="headerGrid">
                <div className="left">
                  <h4>{tra('Masraf_Ekle')}</h4>
                  <p>{tra('Add_Costing_Detail')}</p>
                </div>
              </div>
              <div className="inputList">
                <FastInput
                  type="select"
                  disabled
                  data={categories}
                  title={tra('Masraf_Kategorisi')}
                  name="costing_type"
                />
                <FastInput
                  type="select"
                  disabled
                  data={contractsArr}
                  title={tra('Masraf_Tipi')}
                  name="category_id"
                />
                <FastInput
                  type="autoCompleteCreatable"
                  title={tra('Tanim')}
                  disabled={disabled}
                  data={costingList}
                  labelKey="agreement"
                  onChange={(e, newValue) => changeAgrAutoComplete(e, newValue)}
                  freeSolo
                  name="agreement"
                  indicate
                  error={Validator.message('agreement', CostingState.agreement, 'required')}
                  helperText={Validator.message('agreement', CostingState.agreement, 'required')}
                />
                {CostingState.costing_type !== 3 && (
                  <FastInput
                    disabled={disabled}
                    type="text"
                    title={tra('Notlar')}
                    row="4"
                    name="comments"
                    onChange={(e) => handleChange(e, 'comments')}
                  />
                )}
              </div>
            </div>
          </Grid>
          <Grid className="rowRight" item xs={6}>
            <div className="box">
              <div className="headerGrid" />
              <div className="inputList">
                <div className="multipleInputWithChecbox">
                  <FastInput
                    type="text"
                    disabled={disabled}
                    title={tra('Servis')}
                    name="service"
                    onChange={(e) => handleChange(e, 'service')}
                    indicate
                    error={Validator.message('service', CostingState.service, 'required')}
                    helperText={Validator.message('service', CostingState.service, 'required')}
                  />
                  <FastInput
                    disabled={disabled}
                    type="checkbox"
                    title={tra('Tahmini')}
                    name="fc"
                    onChange={(e) => handleChange(e, 'fc')}
                  />
                </div>
                {CostingState.costing_type !== 3 ? (
                  <>
                    <FastInput
                      disabled={disabled}
                      type="autoComplete"
                      name="vendor_id"
                      title={tra('Vendor')}
                      onChange={(e, newValue) => autoCompChange(newValue, 'vendor_id')}
                      data={vendors}
                      multiple={false}
                    />
                    <FastInput
                      disabled={disabled}
                      type="autoCompleteCreatable"
                      title={tra('Tedarikci_Kisi')}
                      data={vendorUsers.filter((item) => item.vendor_id === CostingState.vendor_id?.id) || []}
                      labelKey="person"
                      secondLabelKey="mail"
                      onChange={(e, newValue) => changeVendorUserFree(e, newValue)}
                      freeSolo
                      name="vendorContact_person"
                    />
                    <FastInput
                      type="text"
                      disabled={disabled}
                      title={tra('Tedarikci_Eposta')}
                      onChange={(e) => handleChange(e, 'vendorContact_mail')}
                      name="vendorContact_mail"
                    />
                    <FastInput
                      disabled={disabled}
                      type="tel"
                      title={tra('Tedarikci_Numarasi')}
                      name="vendorContact_phone"
                      onValueChange={(e) => handlePhoneChange(e, 'vendorContact_phone')}
                      valueKey="dial_code"
                      data={locations}
                      telCode={CostingState.phone_code}
                      onChangeTelCode={(e, newValue) => handlePhoneCodeChange(e, newValue)}
                    />
                  </>
                ) : (
                  <FastInput
                    disabled={disabled}
                    type="text"
                    title={tra('Notlar')}
                    row="4"
                    name="comments"
                    onChange={(e) => handleChange(e, 'comments')}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        {CostingState.GenericContItems && CostingState.GenericContItems.map((rowItem, index) => (
          <Grid className={index !== CostingState.GenericContItems.length - 1 ? 'gridRowDashed' : 'gridRow'} container item xs={12} spacing={3}>
            {((index !== 0) || CostingState.GenericContItems.length > 1) && (
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton
                  style={{ padding: 0, marginRight: 40 }}
                  opacity
                  disabled={disabled}
                  onClick={() => deleteContract(index)}
                >
                  <DeleteOutline />
                </CustomButton>
              </Grid>
            )}
            <Grid className="rowLeft" item xs={6}>
              <div className="box">
                {index === 0 && (
                  <div className="headerGrid">
                    <div className="left">
                      <h4>{tra('Date_Information')}</h4>
                      <p>{tra('Date_Information_Detail')}</p>
                    </div>
                  </div>
                )}
                <div className="inputList">
                  {CostingState.category_id === 2 ? (
                    <>
                      <FastInput
                        type="date"
                        disabled={disabled}
                        title={tra('Date_Information_Detail')}
                        value={rowItem.contract_start}
                        onChange={handleDateChange('contract_start', index)}
                      />
                      <FastInput
                        disabled={disabled}
                        type="currency"
                        title={tra('Depreciation_Period')}
                        value={rowItem.period}
                        onChange={handleChangeDepreciationPeriod(index)}
                        indicate
                        error={Validator.message('period', rowItem.period, 'required')}
                        helperText={Validator.message('period', rowItem.period, 'required')}
                      />
                      <FastInput
                        type="date"
                        title={tra('Calculated_End_Date')}
                        value={rowItem.calculated_end}
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <FastInput
                        type="date"
                        disabled={disabled}
                        title={tra('Sozlesme_Baslangic')}
                        value={rowItem.contract_start}
                        onChange={handleDateChange('contract_start', index)}
                      />
                      <FastInput
                        type="date"
                        disabled={disabled}
                        title={tra('Sozlesme_Bitis')}
                        value={rowItem.contract_end}
                        onChange={handleDateChange('contract_end', index)}
                        minDate={CostingState.contract_start}
                      />
                      {CostingState.costing_type !== 3 && (
                        <FastInput
                          type="date"
                          disabled={disabled}
                          title={tra('Satin_Alma_Tarihi')}
                          value={rowItem.purchase_date}
                          onChange={handleDateChange('purchase_date', index)}
                        />
                      )}
                      <FastInput
                        type="currency"
                        title={tra('Aylik_Periyot')}
                        disabled
                        value={rowItem.period}
                      />
                    </>
                  )}
                </div>
              </div>
            </Grid>
            <Grid className="rowRight" item xs={6}>
              <div className="box">
                {index === 0 && (
                  <div className="headerGrid">
                    <div className="left">
                      <h4>{tra('Cost_Information')}</h4>
                      <p>{tra('Cost_Information_Detail')}.</p>
                    </div>
                  </div>
                )}
                <div className="inputList">
                  <div className="multipleInputWrapper">
                    <FastInput
                      type="select"
                      title={tra('Invoice_Currency')}
                      data={currencies}
                      value={rowItem.invCurr_type}
                      onChange={(e) => handleChange(e, 'invCurr_type', index)}
                      disabled={disabled}
                    />
                    <FastInput
                      disabled={disabled ? true : currencies.find((item) => item.id === rowItem.invCurr_type)?.name === compCurrency}
                      type="number"
                      title={tra('Fx_Rate')}
                      value={rowItem.fx_rate}
                      indicate
                      error={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                      helperText={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                      onChange={(e) => changeFxRate(e, 'change', '', index)}
                      plusClick={() => changeFxRate('', 'buttonClick', 'plus', index)}
                      minusClick={() => changeFxRate('', 'buttonClick', 'minus', index)}
                    />
                  </div>
                  <div className="multipleInputWrapper">
                    <FastInput
                      disabled={disabled}
                      type="currency"
                      title={`${tra('Total_Amount_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                      value={rowItem.total_cost}
                      onChange={(e) => {
                        Validator.showMessageFor('allocationTotal');
                        handleChangeCurrency(e, index);
                      }}
                      indicate
                      error={Validator.message('total_cost', rowItem.total_cost, 'required')}
                      helperText={Validator.message('total_cost', rowItem.total_cost, 'required')}
                    />
                    <FastInput
                      type="currency"
                      title={`${CostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                      value={rowItem.monthly_cost}
                      disabled
                    />
                  </div>
                  {compCurrency !== currencies.find((item) => item.id === rowItem.invCurr_type)?.name && (
                    <div className="multipleInputWrapper">
                      <FastInput
                        type="currency"
                        title={`${tra('Total_Amount_In')} ${compCurrency}`}
                        disabled
                        value={rowItem.total_inv}
                      />
                      <FastInput
                        type="currency"
                        title={`${CostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${compCurrency}`}
                        value={rowItem.monthly_inv}
                        disabled
                      />
                    </div>
                  )}
                  <div className="multipleInputWrapper">
                    <FastInput
                      type="select"
                      title={tra('Run_Cost_Aggrigation')}
                      value={rowItem.tco_agg}
                      data={tcoAggregations}
                      disabled={disabled}
                      onChange={(e) => handleChange(e, 'tco_agg', index)}
                      indicate
                      error={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                      helperText={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            {index === CostingState.GenericContItems.length - 1 && (
              <Grid item xs={12}>
                <CustomButton
                  disabled={disabled}
                  transparent
                  startIcon={<Plus width={12} />}
                  onClick={addNewContract}
                >{tra('Split_Existing_Contract')}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        ))}
        <Grid className="gridRow" container item xs={12} spacing={3}>
          <Grid className="rowLeft" item xs={8}>
            <div className="box">
              <div className="headerGrid">
                <div className="left">
                  <h4>{tra('Allocation_Of_Cost_To_Applications')}</h4>
                  <p>{tra('Allocation_Of_Cost_To_Applications_Detail')}</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className="rowLeft" item xs={4}>
            <div className="box">
              <div className="headerGrid">
                <div className="right">
                  <>
                    <CustomButton
                      disabled={disabled}
                      opacity
                      endIcon={<UnfoldMore width={12} />}
                      onClick={(e) => changeAppValueStatusMenu(e.currentTarget)}
                    >{CostingPageVariables.appValueStatus === 1 ? tra('Absolute_Value') : tra('Percentage')}
                    </CustomButton>
                    <CustomMenu
                      anchorEl={CostingPageVariables.appValueStatusMenu}
                      open={Boolean(CostingPageVariables.appValueStatusMenu)}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      keepMounted
                      onClose={() => changeAppValueStatusMenu(null)}
                    >
                      <MenuItem onClick={() => changeAppValueStatus(1)}>{tra('Absolute_Value')}</MenuItem>
                      <MenuItem onClick={() => changeAppValueStatus(2)}>{tra('Percentage')}</MenuItem>
                    </CustomMenu>
                  </>
                  <CustomButton
                    disabled={disabled}
                    onClick={() => setCostingPageVariables({
                      ...CostingPageVariables,
                      addAppModal: true,
                    })}
                    startIcon={<Plus width={12} />}
                  >
                    {tra('Uygulama_Ekle')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className="rowLeft" item xs={8}>
            <div className="box">
              {CostingPageVariables.sendApplications.length > 0 && (
                <div className="allocationTable">
                  <Table>
                    <TableHead>
                      <TableCell />
                      <TableCell>{tra('Allocation')} %</TableCell>
                      <TableCell>{tra('Allocation_In')} {currencies.find((item) => item.id === CostingState.GenericContItems[0].invCurr_type)?.name}</TableCell>
                      <TableCell />
                    </TableHead>
                    <TableBody>
                      {CostingPageVariables.sendApplications.map((item) => (
                        <TableRow>
                          <TableCell><p>{applications.length > 0 && applications.find((app) => app.id === item.id).name}</p></TableCell>
                          <TableCell>
                            <FastInput
                              type="currency"
                              suffix="%"
                              decimalScale={3}
                              allowDecimal={false}
                              value={item.allocation}
                              onChange={(e) => {
                                handleChangeAppValueAllocation(e, item.id);
                                Validator.showMessageFor('allocationTotal');
                              }}
                              disabled={disabled || (CostingPageVariables.appValueStatus === 1)}
                            />
                          </TableCell>
                          <TableCell>
                            <FastInput
                              type="currency"
                              value={FormatCurrency(item.value)}
                              onChange={(e) => {
                                handleChangeAppValue(e, item.id);
                                Validator.showMessageFor('allocationTotal');
                              }}
                              disabled={disabled || (CostingPageVariables.appValueStatus === 2)}
                            />
                          </TableCell>
                          <TableCell><IconButton disabled={disabled} onClick={() => removeApp(item.id)}><Close /></IconButton></TableCell>
                        </TableRow>
                      ))}
                      <TableRow className="totalRow">
                        <TableCell><p>{tra('Total_Allocated')} {CostingPageVariables.appValueStatus === 1 ? tra('Amount') : tra('Percentage')} {CostingPageVariables.appValueStatus === 1 && `in ${currencies.find((item) => item.id === CostingState.GenericContItems[0].invCurr_type)?.name}`}</p></TableCell>
                        <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(CostingPageVariables.allocationTotal)}{CostingPageVariables.appValueStatus === 2 && '%'}</p></TableCell>
                      </TableRow>
                      {CostingPageVariables.allocationTotal - CostingPageVariables.appValueStatus === 2 ? 100 : CostingState.total !== 0 && (
                        <TableRow className="varianceRow">
                          <TableCell><div style={{ display: 'flex', alignItems: 'center' }}><ErrorOutline /><p style={{ marginLeft: 15 }}>{tra('Fark')}</p></div></TableCell>
                          <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(CostingPageVariables.allocationTotal - (CostingPageVariables.appValueStatus === 2 ? 100 : CostingState.total))}{CostingPageVariables.appValueStatus === 2 && '%'}</p></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          </Grid>
          <Grid style={{ padding: 0 }} className="rowWithoutInfo" item xs={4}>
            <div className="box" style={{ height: '100%' }}>
              {CostingPageVariables.sendApplications.length > 0 && (
                <div className="chartWrapper">
                  <AllocationPie applications={applications} data={CostingPageVariables.sendApplications} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid className="gridRow" container item xs={12} spacing={3}>
          <Grid className="rowLeft" item xs={12}>
            <div className="box">
              <div className="headerGrid">
                <div className="left">
                  <h4>{tra('Add_Document_And_Link')}</h4>
                  <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>
                </div>
                <div>
                  <CustomButton
                    onClick={() => setCostingPageVariables({
                      ...CostingPageVariables,
                      costingDocModal: true,
                    })}
                    startIcon={<Plus width={12} />}
                    active
                    disabled={disabled}
                  >
                    {tra('Ekle')}
                  </CustomButton>
                </div>
              </div>
              <div className="documentList">
                {(costingDetail?.ContractFiles?.length > 0 || fileUrlArr.length > 0) && (
                  <Table>
                    <TableHead>
                      <TableCell colSpan={4}>{costingDetail?.ContractFiles?.length} Document / {fileUrlArr.length} Link</TableCell>
                    </TableHead>
                    <TableBody>
                      {costingDetail?.ContractFiles.map((file, index) => (
                        <TableRow>
                          <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                          {/* <TableCell style={{ width: 'auto' }}><a onClick={() => this.fileGenerateLink(file)} target="_blank">{file.filename}</a></TableCell> */}
                          <TableCell style={{ width: 'auto' }}><a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a></TableCell>
                          <TableCell style={{ width: 150, textAlign: 'center' }}>{(parseInt(file.size / 1024, 10))} Kb</TableCell>
                          <TableCell style={{ width: 50, textAlign: 'center' }}>
                            {removeCheckClick === index
                              ? (
                                <div style={{ display: 'flex' }}>
                                  <IconButton
                                    onClick={() => this.props.deleteFile(file.id, costId, 'contract')}
                                    style={{ padding: 5 }}
                                  >
                                    <Check style={{ color: Colors.green }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => this.setState({ removeCheckClick: false })}
                                    style={{ padding: 5 }}
                                  >
                                    <Close style={{ color: Colors.red }} />
                                  </IconButton>
                                </div>
                              )
                              : (
                                <IconButton
                                  onClick={() => this.setState({ removeCheckClick: index })}
                                  style={{ padding: 5 }}
                                ><Delete />
                                </IconButton>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {fileUrlArr.map((url, index) => (
                        <TableRow>
                          <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                          <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                          <TableCell style={{ width: 50, textAlign: 'center' }}>
                            <IconButton
                              onClick={() => {
                                fileUrlArr.splice(index, 1);
                                this.props.setCostingFileUrls(fileUrlArr);
                                this.setState({ fileUrlArr });
                              }}
                              style={{ padding: 5 }}
                              disabled={disabled}
                            ><Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      CostingPageVariables, setCostingPageVariables, addAppToContract, CostingState,
    } = this.context;
    const {
      logDrawer, disabled, controlModal, compCurrency, deleteModal,
    } = this.state;
    const {
      costingDetail, costingLogs, currencies,
    } = this.props;
    const costId = this.props.match.params.id;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Drawer open={logDrawer} onClose={() => this.setState({ logDrawer: false })} anchor="right">
              <Logs data={costingLogs} />
            </Drawer>
            {
              costingDetail && (
                <Grid item xs={12}>
                  <GridPageWrapper>
                    <InputWrapper>
                      <Grid container spacing={isWidthDown('sm', this.props.width) ? 0 : 2}>
                        <Grid item xs={12}>
                          <div className="header" style={isWidthDown('sm', this.props.width) ? { display: 'none' } : { padding: '0px' }}>
                            <div className="buttonWrapperLeft" />
                            <div className="buttonWrapperRight" style={{ marginTop: 0 }}>
                              {
                                disabled && (
                                  <CustomButton onClick={() => this.setState({ disabled: false })}>
                                    <EditNew color={Colors.blue} width="16" />
                                    {tra('Duzenle')}
                                  </CustomButton>
                                )
                              }
                              <CustomButton
                                opacity
                                onClick={() => this.setState({ logDrawer: true })}
                                startIcon={<HistoryIcon />}
                              >
                                {tra('Tarihce')}
                              </CustomButton>
                              <CustomButton
                                style={{ padding: 0 }}
                                opacity
                                onClick={(e) => this.setState({ otherMenu: e.currentTarget })}
                              >
                                <MoreVert />
                              </CustomButton>
                              <CustomMenu
                                anchorEl={this.state.otherMenu}
                                open={Boolean(this.state.otherMenu)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                keepMounted
                                onClose={() => this.setState({ otherMenu: null })}
                              >
                                <MenuItem
                                  onClick={() => {
                                    this.setState({ otherMenu: false, deleteModal: true });
                                  }}
                                >
                                  <Delete />{tra('Delete_Cost')}
                                </MenuItem>
                              </CustomMenu>
                            </div>
                          </div>
                        </Grid>
                        {this.renderCosting()}
                      </Grid>
                    </InputWrapper>
                  </GridPageWrapper>
                </Grid>
              )
            }
          </div>
        </PageContent>
        <Slide
          appear={false}
          direction="up"
          in={!disabled}
        >
          <ActionFooter>
            <div>
              <CustomButton
                onClick={() => this.handleBackFirstData()}
              >
                {tra('Vazgec')}
              </CustomButton>
              <CustomButton
                onClick={() => this.setState({ controlModal: true })}
              >
                <SaveNew color={Colors.blue} width="16" />
                {tra('Kaydet')}
              </CustomButton>
            </div>
          </ActionFooter>
        </Slide>
        <AddAppToContract
          addApp={addAppToContract}
          open={CostingPageVariables.addAppModal}
          close={() => setCostingPageVariables({
            ...CostingPageVariables,
            addAppModal: false,
          })}
          type={CostingPageVariables.appValueStatus}
          sendApps={CostingPageVariables.sendApplications}
          contractType={costingDetail?.category_id}
          invoiceCurrency={currencies.find((i) => i.id === CostingState?.GenericContItems?.[0]?.invCurr_type)?.name ?? compCurrency}
        />
        <CostingDocModal
          open={CostingPageVariables.costingDocModal}
          close={() => setCostingPageVariables({
            ...CostingPageVariables,
            costingDocModal: false,
          })}
          id={costId}
          disabled={disabled}
          fileType="software"
          setFileUrlArr={(fileUrl) => this.setState({ fileUrlArr: fileUrl })}
        />
        <UpdateWarnModal
          open={controlModal}
          close={() => this.setState({ controlModal: false })}
          message={tra('Sozlesme_Guncelleme_Mesaji')}
          update={() => this.handleSaveContract()}
        />
        <DeleteModal
          open={deleteModal}
          close={() => this.setState({ deleteModal: false })}
          message={tra('Sozlesme_Silme_Mesaji')}
          deleteFunc={() => {
            this.props.deleteCosting(costId);
            this.setState({ deleteModal: false });
          }}
        />
      </PageWrapper>
    );
  }
}

CostingDetail.propTypes = {
  match: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  costingDetail: PropTypes.object.isRequired,
  getCostingDetail: PropTypes.func.isRequired,
  getCostingCategories: PropTypes.func.isRequired,
  setCostingDetail: PropTypes.func.isRequired,
  updateCosting: PropTypes.func.isRequired,
  costingLogs: PropTypes.array.isRequired,
  tcoAggregations: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  costingList: PropTypes.array.isRequired,
  getCosting: PropTypes.func.isRequired,
  costingFileUrls: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  setCostingFileUrls: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  getApplications: PropTypes.func.isRequired,
  deleteCosting: PropTypes.func.isRequired,
  getVendorUsers: PropTypes.func.isRequired,
  vendorUsers: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  vendors: selectInfos('Vendors'),
  categories: selectCostingCategories(),
  currencies: selectInfos('Currencies'),
  tcoAggregations: selectInfos('TcoAggregations'),
  locations: selectInfos('Locations'),
  applications: selectApplications(),
  costingDetail: selectCostingDetail(),
  costingLogs: selectCostingLogs(),
  user: selectActiveUser(),
  costingList: selectCostingList(),
  costingFileUrls: selectCostingFileUrls(),
  vendorUsers: selectVendorUsers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getCostingDetail,
      getCostingCategories,
      setCostingDetail,
      updateCosting,
      setToast,
      getCosting,
      setCostingFileUrls,
      deleteFile,
      getApplications,
      deleteCosting,
      getVendorUsers,
    }, dispatch)
  )
);

const CostingDetailWithWidth = withWidth()(CostingDetail);

export default connect(mapStateToProps, mapDispatchToProps)(CostingDetailWithWidth);

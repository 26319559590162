import { tra } from '../../../commons/utils';

const date = new Date(); // tarhilerin min max degerini ayarlıyorum

export default [
  // leftGrid
  [
    {
      subtitle: tra('Envanter_Ayrintilari'),
      desc: tra('Device_Register_Info_Desc'),
      inputArray: [
        {
          type: 'text',
          title: tra('Envanter_Etiketi'),
          name: 'tag',
          value: 'example tag',
          validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Barkod'),
          name: 'barcode',
          value: '11223344',
        },
        {
          type: 'currency',
          title: tra('Satin_Alma_Maliyeti'),
          name: 'cost',
          value: '150',
          validateRules: 'required|customCurrency',
          childrenInput: [
            {
              type: 'select',
              title: tra('Para_Birimi'),
              name: 'currencies_id',
              value: 1,
              data: 'currencies',
              inputProps: {
                emptyItem: true,
              },
              validateRules: 'required',
            },
          ],
        },
        // {
        //   type: 'select',
        //   title: tra('Ulke'),
        //   name: 'location_id',
        //   value: 5,
        //   data: 'locations',
        //   inputProps: {
        //     emptyItem: true,
        //   },
        // },
        {
          type: 'autoComplete',
          title: tra('Ulke'),
          name: 'location_id',
          value: 5,
          data: 'locations',
          inputProps: {
            emptyItem: true,
            multiple: false,
          },
        },
        {
          type: 'select',
          title: tra('Lokasyon'),
          name: 'host_location_id',
          value: 5,
          data: 'HostLocations',
          inputProps: {
            emptyItem: true,
            // multiple: false,
          },
        },
        {
          type: 'select',
          title: tra('Tedarikci'),
          name: 'vendor_id', // değişecek
          value: '1920 X 1080',
          data: 'vendors',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'autoComplete',
          title: tra('Uretici'),
          name: 'producer_id',
          value: 4,
          data: 'producers',
          inputProps: {
            emptyItem: true,
            multiple: false,
          },
          validateRules: 'required',
        },
        // {
        //   type: 'text',
        //   title: tra('Marka'),
        //   name: 'brand',
        //   value: 'Lenova',
        //   validateRules: 'required',
        // },
        {
          type: 'text',
          title: tra('Model'),
          name: 'model',
          value: 'Thinkpad',
          validateRules: 'required',
        },
      ],
    },
    {
      // title: tra('Bagli_Yazilimlar'),
      // addGroupName: 'ConnectApplications',
      // addGroupIndex: 0,
      inputArray: [
        {
          type: 'autoComplete',
          title: tra('Donanima_Bagli_Yazilimlar'),
          name: 'ConnectApplications',
          value: [],
          data: 'apps',
          inputProps: {
            multiply: false,
          },
        },
      ],
    },
  ],
  // rightGrid
  [
    {
      propsChildren: true,
      spacerTitle: true,
      desc: 'spacer',
      inputArray: [
        {
          type: 'date',
          title: tra('Satin_Alma_Tarihi'),
          name: 'first_usage_date', // değişebilir
          value: new Date(),
          validateRules: 'required',
          inputProps: {
            maxDate: date.setDate(date.getDate() + 1),
            // maxDate: new Date(),
          },
        },
        {
          type: 'date',
          title: tra('Sona_Erme_Tarihi'),
          name: 'exp_date',
          value: new Date(),
        },
        {
          type: 'date',
          title: tra('Garanti_Suresi_Bitis_Tarihi'),
          name: 'warranty_end_date',
          value: new Date(),
        },

        {
          type: 'select',
          title: tra('Teslim_Alan_Kisi'),
          name: 'receiver_user_id',
          value: 7,
          data: 'users',
          inputProps: {
            emptyItem: true,
          },
          // validateRules: 'required',
        },
        {
          type: 'date',
          title: tra('Teslim_Alma_Tarihi'),
          name: 'delivery_date',
          value: new Date(),
        },

        {
          type: 'select',
          title: tra('Durum'),
          name: 'situation',
          value: 1,
          data: 'situations',
          validateRules: 'required',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'select',
          title: tra('Departman'),
          name: 'department_id',
          value: 1,
          data: 'departments',
          inputProps: {
            emptyItem: true,
          },
        },
        {
          type: 'select',
          title: tra('Sorumlu_Kisi'),
          name: 'user_id',
          value: 7,
          data: 'users',
          inputProps: {
            emptyItem: true,
          },
          validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Seri_No'),
          name: 'serial_no',
          value: '11223344',
          validateRules: 'required',
        },
      ],
    },
  ],
];

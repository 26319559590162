import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { CustomButton, CustomDrawer, FilteredSidebar } from '../../../assets/styledNew';
import { tra } from '../../../commons/utils';
import Input from '../../../components/Input';
import { LeftArrow } from '../../../assets/icons';

function TableFilterMenu(props) {
  /*
  column
  data
  filter
  */

  const [filterShow, setFilterShow] = useState(false);

  const initFilter = {
    filter: [],
  };

  const [filterState, setFilterState] = useState(initFilter);
  const { filter } = filterState;

  const changeFilter = (state, value, text, type, label, header) => {
    if (type === 'resetAll') {
      // this.setState({ filter: [] });
      setFilterState((s) => ({ ...s, filter: [] }));
    } else {
      const stateName = state.replace(/[0-9]/g, '');
      let oldValue = false;
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state);
        filter.splice(index, 1);
      }
      if (type === 'select') {
        oldValue = filter.find((item) => item.column === stateName);
      } else {
        oldValue = filter.find((item) => item.column === stateName && item.value === text);
      }
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value) {
        filter.push({
          column: stateName, value: text, label, header,
        });
      }
      setFilterState((s) => ({ ...s, filter, [state]: type === 'select' ? text : value }));
    }
  };

  const integrationMessages = useSelector((s) => s.integrationMessages);
  const getArrFilters = (itemName, arrayPath, count, countKey) => {
    const data = [];
    for (let i = 0; i < integrationMessages.length; i++) {
      for (let j = 0; j < integrationMessages[i][itemName].length; j++) {
        data.push(integrationMessages[i][itemName][j][arrayPath]);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  };

  const getFilteredData = (name, data) => {
    // const { data } = this.state;
    if (data) {
      const allData = [...data];
      const sKey = filterState[`search${name}`];
      if (sKey && sKey.length > 2) {
        const filteredData = allData.filter((d) => d[name].toString().toLocaleUpperCase().includes(sKey.toLocaleUpperCase()));
        return filteredData;
      }
      return [...new Map(allData?.map((item) => [item[name], item])).values()].splice(0, 10); // Max 50 kayıt önerisi
      // return [...new Map(allData?.map((item) => [item[name], item])).values()];
    }
    return [];
  };

  const childrenData = {
    filters: filter,
    onChange: changeFilter,
    drawerChange: () => setFilterShow(s => !s)
  }
  return (
    <>
      {props.children(childrenData)}
      <CustomDrawer
        anchor="left"
        open={filterShow}
        onClose={() => setFilterShow(false)}
      >
        <FilteredSidebar>
          <div className="title">
            {tra('Filtre')}
            <CustomButton onClick={() => setFilterShow(false)} startIcon={<LeftArrow width={12} />}>{tra('Geri')}</CustomButton>
          </div>
          <div className="filterContent">
            {props.tableColumn && props.tableColumn.map((item, index) => (
              item.filterable && (
                item.type === 'select'
                  ? (
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <p>{item.text}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Input
                          type="select"
                          fullWidth
                          data={item.selectData}
                          value={filterState?.[item?.name] || ''}
                          onChange={(e) => changeFilter(item.name, true, e.target.value, 'select', e.target.value, item.text)}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )
                  : item.type === 'array'
                    ? (
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <p>{item.text}</p>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexDirection: 'column', maxHeight: 200, overflow: 'auto' }}>
                          {getArrFilters(item.name, item.arrayPath).map((arrFilter, count) => (
                            <Input
                              type="checkbox"
                              title={`${arrFilter} (${getArrFilters(item.name, item.arrayPath, true, arrFilter)})`}
                              value={filter.find((a) => a.value === arrFilter) !== undefined}
                              onChange={(e) => changeFilter(`${item.name}.${item.arrayPath}${index + 1 * count + 1}`, e.target.checked, arrFilter, 'checkbox', arrFilter, item.text)}
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    )
                    : item.filterLocation
                      ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: 'column', maxHeight: 200, overflow: 'auto' }}>
                            {props.tableData && [...new Set(props.tableData.map((items) => items[item.name]))].map((licence, count) => (
                              <Input
                                type="checkbox"
                                title={`${this.props[item.filterLocation].find((f) => f.id === licence) && this.props[item.filterLocation].find((f) => f.id === licence).name} (${props.tableData.filter((fltr) => fltr[item.name] === licence).length})`}
                                value={filter.find((a) => (a.column === item.name) && (a.value === licence)) !== undefined}
                                onChange={(e) => changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', this.props[item.filterLocation].find((f) => f.id === licence).name, item.text)}
                              />
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      )
                      : (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: 'column', maxHeight: 200, overflow: 'auto' }}>
                            {props.tableData && [...new Set(props.tableData.map((items) => items[item.name]))].length > 20
                              && (
                                <Input
                                  type="text"
                                  placeholder={tra('Veri_Ara')}
                                  fullWidth
                                  value={filterState?.[`search${item.name}`] || ''}

                                  onChange={(e) => setFilterState(s => ({ ...s, [`search${item.name}`]: e.target.value }))}
                                />
                              )}
                            <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                              {
                                props.tableData && [...new Set(getFilteredData(item.name, props.tableData).map((items) => items[item.name]))].map((licence, count) => (
                                  <Input
                                    type="checkbox"
                                    title={`${licence} (${props.tableData.filter((fltr) => fltr[item.name] === licence).length})`}
                                    value={filter.find((a) => a.value === licence) !== undefined}
                                    onChange={(e) => changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', licence, item.text)}
                                  />
                                ))
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
              )
            ))}
          </div>
          <div className="filterDrawefooter">
            {props.tableData && <p><span>{`(${props.tableData.length})`}</span> {tra('Envanter_Sayisi')}</p>}
            <CustomButton onClick={() => setFilterShow(false)}>{tra('Uygula')}</CustomButton>
            <CustomButton
              transparent
              onClick={() => changeFilter(null, null, null, 'resetAll')}
            >{tra('Sifirla')}
            </CustomButton>
          </div>
        </FilteredSidebar>
      </CustomDrawer>
    </>
  );
}

TableFilterMenu.propTypes = {
  // onClose: PropTypes.any.isRequired,
  // open: PropTypes.any.isRequired,
  tableColumn: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  // onSubmit: PropTypes.func.isRequired,
  // filter: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

export default TableFilterMenu;

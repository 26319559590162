import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withWidth, { } from '@material-ui/core/withWidth';
import {
  ClickAwayListener, Grid, IconButton,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import {
  CustomButton,
  CustomDrawer,
  DrawerButton,
  PageContent,
  PageWrapper,
} from '../../assets/styledNew';
import {
  getApplicationReports, setGeneralReportType, getAggregationReports, getContractYears, getUserReports, getDepartmentReport,
  getUsersReports,
} from '../../store/actions';
import ApplicationReport from './ApplicationReport';
import RunCostReport from './RunCostReport';
import {
  selectActiveUser, selectGeneralReportType, selectInfos, selectUserModule,
} from '../../store/selectors';
import { isUserModule, tra } from '../../commons/utils';
import DebitReport from './DebitReport';
import DepartmentReport from './DepartmentReport';
import { ModuleSwitch } from '../../commons/ModuleSwitch';
import moduleType from '../../commons/enum/moduleType';
import Users from './Users/Users';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
      compCurrency: '',
      activeMenu: 'applications',
    };
    props.getContractYears();
    // props.getApplicationReports();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.symbol;
      return {
        compCurrency: currency,
      };
    }
    return null;
  }

  componentDidMount() {
    this.permissionSwitch();
  }

  componentDidUpdate(nextProps) {
    if (JSON.stringify(this.props.initModule) !== JSON.stringify(nextProps.initModule)) {
      this.permissionSwitch();
    }
  }

  isUserModuleFunc = (modulTypeArr) => isUserModule(modulTypeArr)(this.props.initModule);

  permissionSwitch=() => {
    switch (true) {
      case this.isUserModuleFunc(moduleType.SOFTWARE):
        this.drawerItemOnclick('applications');
        break;
      case this.isUserModuleFunc(moduleType.HARDWARE):
        this.drawerItemOnclick('users');
        break;
      default:
        break;
    }
  }

  switchReport = (type) => {
    const { compCurrency } = this.state;
    switch (type) {
      case 'applications':
        return <ApplicationReport currency={compCurrency} />;
      case 'aggregations':
        return <RunCostReport currency={compCurrency} />;
      case 'users':
        return <DebitReport currency={compCurrency} />;
      case 'departments':
        return <DepartmentReport currency={compCurrency} />;
      case 'user':
        return <Users currency={compCurrency} />;
      default:
        return <ApplicationReport currency={compCurrency} />;
    }
  }

  drawerItemOnclick = (type) => {
    this.props.setGeneralReportType(type);
    switch (type) {
      case 'applications':
        this.props.getApplicationReports();
        break;
      case 'aggregations':
        this.props.getAggregationReports(new Date().getFullYear());
        break;
      case 'users':
        this.props.getUserReports();
        break;
      case 'departments':
        this.props.getDepartmentReport(new Date().getFullYear());
        break;
      case 'user':
        this.props.getUsersReports(new Date().getFullYear());
        break;
      default:
        break;
    }
    this.setState({ activeMenu: type });
  }

  render() {
    const { openDrawer, activeMenu } = this.state;
    const { generalReportType } = this.props;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ClickAwayListener onClickAway={() => openDrawer && this.setState({ openDrawer: false })}>
                  <div style={{ width: 'fit-content' }}>
                    <CustomButton startIcon={<Menu />} onClick={() => this.setState({ openDrawer: !openDrawer })}>{tra('Raporlar')}</CustomButton>
                    <CustomDrawer
                      anchor="left"
                      open={openDrawer}
                      variant="persistent"
                    >
                      <div className="reportDrawer">
                        <div className="reportDrawerHeader">
                          <IconButton onClick={() => this.setState({ openDrawer: false })}>
                            <Menu />
                          </IconButton>
                          <h4>{tra('Report')}</h4>
                        </div>
                        <div className="reportDrawerList">
                          <DrawerButton active={activeMenu === '-'} disabled onClick={() => this.drawerItemOnclick('')}>{tra('Application_Lifecycle')}</DrawerButton>
                          <ModuleSwitch software>
                            <DrawerButton active={activeMenu === 'applications'} onClick={() => this.drawerItemOnclick('applications')}>{tra('Application_Costing')}</DrawerButton>
                          </ModuleSwitch>
                          <ModuleSwitch hardware software isSome>
                            <DrawerButton active={activeMenu === 'aggregations'} onClick={() => this.drawerItemOnclick('aggregations')}>{tra('TcoAggregations')}</DrawerButton>
                          </ModuleSwitch>
                          <ModuleSwitch hardware>
                            <DrawerButton active={activeMenu === 'users'} onClick={() => this.drawerItemOnclick('users')}>{tra('Zimmet')}</DrawerButton>
                          </ModuleSwitch>
                          <ModuleSwitch software>
                            <DrawerButton active={activeMenu === 'departments'} onClick={() => this.drawerItemOnclick('departments')}>{tra('Departments')}</DrawerButton>
                          </ModuleSwitch>
                          <ModuleSwitch software>
                            <DrawerButton active={activeMenu === 'user'} onClick={() => this.drawerItemOnclick('user')}>
                              {tra('Users')}
                            </DrawerButton>
                          </ModuleSwitch>

                          {/* <DrawerButton active={activeMenu === '-'} disabled onClick={() => this.drawerItemOnclick('')}>{tra('Uretici')}</DrawerButton>
                          <DrawerButton active={activeMenu === '-'} disabled onClick={() => this.drawerItemOnclick('')}>{tra('Report')}</DrawerButton> */}
                        </div>
                      </div>
                    </CustomDrawer>
                  </div>
                </ClickAwayListener>
              </Grid>
              <Grid item xs={12}>
                {this.switchReport(generalReportType)}
              </Grid>
            </Grid>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

Reports.propTypes = {
  getApplicationReports: PropTypes.func.isRequired,
  setGeneralReportType: PropTypes.func.isRequired,
  getAggregationReports: PropTypes.func.isRequired,
  generalReportType: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  getContractYears: PropTypes.func.isRequired,
  getUserReports: PropTypes.func.isRequired,
  getDepartmentReport: PropTypes.func.isRequired,
  getUsersReports: PropTypes.func.isRequired,
  initModule: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  generalReportType: selectGeneralReportType(),
  currencies: selectInfos('Currencies'),
  user: selectActiveUser(),
  initModule: selectUserModule(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getApplicationReports,
      setGeneralReportType,
      getAggregationReports,
      getContractYears,
      getUserReports,
      getUsersReports,
      getDepartmentReport,
    }, dispatch)
  )
);

const ReportsWithWidth = withWidth()(Reports);

export default connect(mapStateToProps, mapDispatchToProps)(ReportsWithWidth);

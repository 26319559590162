/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Tooltip, Zoom, ClickAwayListener, MenuItem, Fade,
} from '@material-ui/core';
import { ExpandMoreSharp, UnfoldMore } from '@material-ui/icons';
import { withTheme } from 'styled-components';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import {
  HederWrapper, CustomButton, NotificationWindow, ProfilWindow, NoData,
  CustomMenu, HeaderHardwareSelectButton,
} from '../assets/styledNew';
import {
  Notification, Moon, Earth, Help, Info, Lock, Pen, Plus, Exit, // Printer,// Archive,// File,// Minus, // Refresh,
  CloudExport, CloudImport, Left, Save, ProfilOutlined,
} from '../assets/icons';
import { notificationsTypes, Colors, hardwareUnitSelect } from '../assets/statics';
import {
  tra, getPostDateStr, parseByMentions, pageShow, isUserModule,
} from '../commons/utils';
// import SelectCompanyModal from './modals/SelectCompany';
import {
  setAppConfig,
  // followApplication,
  // excelUpload,
  // excelDownload,
  // answerSurvey,
  readNotification,
  excelUpload,
  excelDownload,
  readAllNotifications,
  setActiveNotFound,
  setLoginState,
  getApplications,
  getApplicationDrafts,
  setInventoryConfig,
  setCostingConfig,
  getCosting,
  getHWCostingList,
  getIntegrationPiUrl,
} from '../store/actions';
import {
  selectActiveUser,
  selectAppConfig,
  selectFilterDrawer,
  selectNotifications,
  selectLoginState,
  selectApplicationDetail,
  selectHardwareDeviceDetail,
  selectInventoryConfig,
  selectGeneralReportType,
  selectCostingConfig,
  selectInfos,
  selectIntegrationPiUrlId,
  selectUserModule,
  // selectSurveyQuestions,
  // selectAssignedSurveys,
  // selectSurveyAnswerBookmark,
} from '../store/selectors';
import ChangePassword from './modals/ChangePassword';
import NoDataImg from '../assets/img/noData.png';
import AddCompanyModal from './modals/AddCompanyModal';
import DisplayMobileHeader from './MobileHeader';
import { ModuleSwitch } from '../commons/ModuleSwitch';
import moduleType from '../commons/enum/moduleType';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        dashboardDuzeni: tra('Dashboard_duzeni'),
        kaydet: tra('Kaydet'),
        bildirimEkrani: tra('Bildirim_Ekrani'),
        Yonetici_Ekrani: tra('Yonetici_Ekrani'),
        genelBakis: tra('Genel_Bakis'),
        envanter: tra('Envanter'),
        yeniEnvanterGirisi: tra('Yeni_Envanter_Girisi'),
        yeniDonanim: tra('Yeni_Donanim'),
        yeniEnvanter: tra('Yeni_Envanter'),
        yeniMasraf: tra('Yeni_Masraf'),
        takvim: tra('Takvim'),
        envanterDetay: tra('Envanter_Detay'),
        dahaFazlaGoster: tra('Daha_Fazla_Goster'),
        Anket_Olustur: tra('Anket_Olustur'),
        dahaFazlaGor: tra('Daha_Fazla_Gor'),
        tumunuTemizle: tra('Tumunu_Temizle'),
        profilDuzenle: tra('Profil_Duzenle'),
        sirketDegistir: tra('Sirket_Degistir'),
        sifreDegistir: tra('Sifre_Degistir'),
        hakkinda: tra('Hakkinda'),
        yardim: tra('Yardim'),
        merhaba: tra('Merhaba'),
        uygulamaLisansAra: tra('Uygulama_Lisans_Ara'),
        Anketler: tra('Anketler'),
        iceAktar: tra('Ice_Aktar'),
        disaAktar: tra('Disa_Aktar'),
        ornekIndir: tra('Ornek_Indir'),
        dilDegistir: tra('Dil_Degistir'),
        entegrasyonHaritasi: tra('Entegrasyon_Haritasi'),
        // kaydet: tra('Kaydet'),
        duzenle: tra('Duzenle'),
        arsiv: tra('Arsiv'),
        ciktiAl: tra('Cikti_Al'),
        lisansIslemleri: tra('Lisans_Islemleri'),
        temaDegistir: tra('Tema_Degistir'),
        cikisYap: tra('Cikis_Yap'),
        donanim_envanteri: tra('Donanim_Envanteri'),
        sonKullanici: tra('Son_Kullanici'),
        guvenlikCihazi: tra('Guvenlik_Cihazi'),
        networkGuvenlikVeCevreBirimleri: tra('Network_Guvenlik_CevreBirimleri'),
        sunucuVeVeriDepolama: tra('Sunucu_Ve_VeriDepolama'),
        masraf: tra('Masraf'),
        masrafDetay: tra('Masraf_Detay'),
        yazici: tra('Yazici'),
        raporlar: tra('Raporlar'),
        users: tra('Users'),
        uygulamaRaporlari: tra('Uygulama_Raporlari'),
        ekle: tra('Add'),
      },
      notificationModalShow: false,
      // selectCompanyModalShow: false,
      // selectCompanyModalShow2: false,
      profilMenu: false,
      languageAnchorEl: null,
      activePath: '',
      hardwareUnitMenu: null,
      inventorytableTypeMenu: null,
      costingTypeMenu: null,
      // notificationExample: NotificationData,
      companyPiUrlId: null,
    };
    this.hiddenInput = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.location.pathname !== prevState.activePath) {
      if (nextProps.history.location.pathname === '/404') {
        nextProps.setActiveNotFound(true);
      } else {
        nextProps.setActiveNotFound(false);
      }
      return {
        activePath: nextProps.history.location.pathname,
      };
    }
    if (!localStorage.getItem('company') && !nextProps.isLogin) {
      return {
        selectCompanyModalShow: true,
      };
    }
    return {
      selectCompanyModalShow: false,
    };
  }

  componentDidMount() {
    this.permissionSwitch();
  }

  componentDidUpdate(nextProps) {
    if (JSON.stringify(nextProps.initModule) !== JSON.stringify(this.props.initModule)) {
      this.permissionSwitch();
    }
  }

  permissionSwitch = () => {
    switch (true) {
      case this.isUserModuleFunc(moduleType.SOFTWARE):
        // Costing Page
        this.props.getCosting();
        this.changeCostingType('Software');
        break;
      case this.isUserModuleFunc(moduleType.HARDWARE):
        // Costing Page
        this.props.getHWCostingList();
        this.changeCostingType('Hardware');
        break;
      default:

        break;
    }
  }

  isUserModuleFunc = (modulTypeArr) => isUserModule(modulTypeArr)(this.props.initModule);

  headerTitle = (route) => {
    const { lang } = this.state;
    // const { history } = this.props;
    switch (route) {
      case pageShow.HOME: return <h1>{lang.genelBakis}</h1>;
      case pageShow.INVENTORY: return <h1>{lang.envanter}</h1>;
      case pageShow.COSTING: return <h1>{lang.masraf}</h1>;
      case pageShow.ADMİN: return <h1>{lang.Yonetici_Ekrani}</h1>;
      case String(route.match(pageShow.SCHEDULE_REGEX)): return <h1>{lang.takvim}</h1>;
      case String(route.match(pageShow.INVENTORY_REGISTER)): return this.inventoryRegisterBreadCrumbs();
      case String(route.match(pageShow.COSTING_REGISTER)): return this.costingRegisterBreadCrumbs();
      case pageShow.NOTIFICATION: return <h1>{lang.bildirimEkrani}</h1>;
      case String(route.match(pageShow.COSTING_DETAIL_REGEX)): return this.costingDetailBreadCrumbs();
      case String(route.match(pageShow.INVENTORY_DETAIL_REGEX)): return this.inventoryDetailBreadCrumbs();
      case pageShow.ADMIN_SURVEY: return <h1>{lang.Anket_Olustur}</h1>;
      case pageShow.SURVEY: return <h1>{lang.Anketler}</h1>;
      case String(route.match(pageShow.INTEGRATION_REGEX)): return <h1>{lang.entegrasyonHaritasi}</h1>;
      case pageShow.HARDWARE_INVENTORY: return <h1>{lang.donanim_envanteri}</h1>;
      case pageShow.REPORTS: return this.reportBreadCrumbs();
      case String(route.match(pageShow.HARDWARE_REGISTER_REGEX)): return this.hardwareRegisterBreadCrumbs();
      case String(route.match(pageShow.HARDWARE_DETAIL_REGEX)): return this.hardwareDetailBreadCrumbs();
      case pageShow.PROFIL: return <h1>{tra('Profil')}</h1>;
      case pageShow.LICENSE_FOLLOW: return this.licenseFollowBreadCrumbs();
      case pageShow.LICENSE_FOLLOW_REGISTER: return this.licenseFollowBreadCrumbs('register');
      case pageShow.LICENSE_FOLLOW_DETAIL_REGEX: return this.licenseFollowBreadCrumbs('detail');
      default: return '';
    }
  }

  /*
   *   ---------------------  breadcrumbs  ---------------------
  */

  licenseFollowBreadCrumbs = (type) => {
    let data = [
      { name: tra('Lisans_Takibi'), link: type ? pageShow.LICENSE_FOLLOW : '' },
    ];
    switch (type) {
      case 'detail':
        data = [
          ...data,
          { name: tra('Detay'), link: '' },
        ];
        break;
      case 'register':
        data = [
          ...data,
          { name: tra('Add'), link: '' },
        ];
        break;
      default:
    }
    return this.breadcrumbs(data);
  }

  hardwareRegisterBreadCrumbs = () => {
    const { lang, hardwareUnitMenu } = this.state;
    const { history } = this.props;
    const typeIndex = history.location.pathname.split('/')[2];
    const unitIndex = history.location.pathname.split('/')[3];
    const typeName = ['', lang.sunucuVeVeriDepolama, lang.networkGuvenlikVeCevreBirimleri, lang.guvenlikCihazi, lang.sonKullanici, lang.yazici];
    const data = [
      { name: lang.donanim_envanteri, link: pageShow.HARDWARE_INVENTORY },
      { name: typeName[typeIndex], link: this.props.history.location.pathname },
    ];
    const unitArray = hardwareUnitSelect.filter((typeSelect) => typeSelect.typeID.toString() === typeIndex && typeSelect);
    return (unitArray.find((c) => c.id.toString() === unitIndex)
      ? (
        <>
          {this.breadcrumbs(data, (
            <HeaderHardwareSelectButton onClick={(event) => this.setState({ hardwareUnitMenu: event.currentTarget })}>
              {unitArray && unitArray.find((c) => c.id.toString() === unitIndex).name}
              <ExpandMoreOutlinedIcon />
            </HeaderHardwareSelectButton>
          ))}
          <CustomMenu
            id="hardwareUnitMenu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            anchorEl={hardwareUnitMenu}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(hardwareUnitMenu)}
            onClose={() => this.setState({ hardwareUnitMenu: null })}
            TransitionComponent={Fade}
          >
            {unitArray.map((item) => <MenuItem onClick={() => this.setState({ hardwareUnitMenu: null }, () => history.push(`/HardwareRegister/${typeIndex}/${item.id}`))}>{item.name}</MenuItem>)}
          </CustomMenu>
        </>
      )
      : history.push('/404')
    );
  }

  hardwareDetailBreadCrumbs = () => {
    const { lang } = this.state;
    const { devicesDetail } = this.props;
    const deviceCategory = devicesDetail && hardwareUnitSelect.find((item) => item.id === devicesDetail.hardware_category_id);
    const typeName = ['', lang.sunucuVeVeriDepolama, lang.networkGuvenlikVeCevreBirimleri, lang.guvenlikCihazi, lang.sonKullanici, lang.yazici];

    const data = deviceCategory && [
      { name: lang.donanim_envanteri, link: pageShow.HARDWARE_INVENTORY },
      { name: typeName[deviceCategory.typeID], link: this.props.history.location.pathname },
      { name: deviceCategory.name, link: this.props.history.location.pathname },
    ];
    return this.breadcrumbs(data);
  }

  inventoryRegisterBreadCrumbs = () => {
    const { lang } = this.state;
    const { history } = this.props;
    const data = [
      { name: lang.envanter, link: pageShow.INVENTORY },
      { name: lang.yeniEnvanterGirisi, link: history.location.pathname },
    ];
    return this.breadcrumbs(data);
  }

  inventoryDetailBreadCrumbs = () => {
    const { lang } = this.state;
    const { history } = this.props;
    const data = [
      { name: lang.envanter, link: pageShow.INVENTORY },
      { name: lang.envanterDetay, link: history.location.pathname },
    ];
    return this.breadcrumbs(data);
  }

  costingDetailBreadCrumbs = () => {
    const { lang } = this.state;
    const { history } = this.props;
    const inventoryFromCost = JSON.parse(localStorage.getItem('costfrominventory'));
    let data = [];
    if (inventoryFromCost.id !== null) {
      const obj = {
        id: inventoryFromCost.id,
        tabOpen: true,
      };
      localStorage.setItem('costfrominventory', JSON.stringify(obj));
      data = [
        { name: lang.envanter, link: pageShow.INVENTORY },
        { name: lang.envanterDetay, link: `/InventoryDetail/${inventoryFromCost.id}` },
        { name: lang.masrafDetay, link: history.location.pathname },
      ];
    } else {
      data = [
        { name: lang.masraf, link: pageShow.COSTING },
        { name: lang.masrafDetay, link: history.location.pathname },
        { name: history.location.pathname.includes('Hardware') ? tra('Donanim') : tra('Yazilim'), link: history.location.pathname },
      ];
    }
    return this.breadcrumbs(data);
  }

  costingRegisterBreadCrumbs = () => {
    const { lang } = this.state;
    const { history } = this.props;
    const data = [
      { name: lang.masraf, link: pageShow.COSTING },
      { name: lang.yeniMasraf, link: history.location.pathname },
      { name: history.location.pathname.includes('Hardware') ? tra('Donanim') : tra('Yazilim'), link: history.location.pathname },
    ];
    return this.breadcrumbs(data);
  }

  reportBreadCrumbs = () => {
    const { lang } = this.state;
    const { generalReportType } = this.props;
    const data = [
      { name: lang.raporlar, link: null },
    ];
    switch (generalReportType) {
      case 'applications':
        data.push({ name: lang.uygulamaRaporlari, link: null });
        break;
      case 'aggregations':
        data.push({ name: tra('TcoAggregations'), link: null });
        break;
      case 'users':
        data.push({ name: tra('Zimmet'), link: null });
        break;
      case 'departments':
        data.push({ name: tra('Departments'), link: null });
        break;
      case 'user':
        data.push({ name: tra('Users'), link: null });
        break;
      default:
        data.push({ name: lang.uygulamaRaporlari, link: null });
        break;
    }
    return this.breadcrumbs(data);
  }

  breadcrumbs = (ArrPath, addTag) => (
    <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ color: this.props.theme.text1 }}>
      {
        ArrPath && ArrPath.map((item) => (
          <h1
            className="breadcrumbsLink"
            onClick={() => this.props.history.push(item.link)}
            aria-hidden
            style={item?.link?.includes(window.location.hash.substring(1)) ? { cursor: 'default' } : {}}
          >
            {item.name}
          </h1>
        ))
      }
      {addTag}
    </Breadcrumbs>
  )

  /*
   *   ---------------------///  breadcrumbs  ---------------------
  */

  logout = () => {
    localStorage.removeItem('token');
    this.props.setLoginState(true);
  }

  profileMenu = (name) => {
    const { profilMenu } = this.state;
    const { theme } = this.props;
    // const closeWindow = { profilMenu: false };
    const { lang } = this.state;
    const menu = [
      // { icon: <ProfilOutlined width={14} color={theme.text1} />, text: 'Kullanıcı Bilgiler', event: '' },
      { icon: <ProfilOutlined width={16} color={theme.text1} />, text: tra('Profil'), event: () => this.setState({ profilMenu: false }, () => this.props.history.push('/Profil')) },
      // { icon: <Refresh width={12} color={theme.text1} />, text: lang.sirketDegistir, event: () => this.setState({ selectCompanyModalShow2: true, profilMenu: false }) },
      { icon: <Lock width={14} color={theme.text1} />, text: lang.sifreDegistir, event: () => this.setState({ changePasswordModal: true, profilMenu: false }) },
      { icon: <Info width={14} color={theme.text1} />, text: lang.hakkinda, event: '' },
      { icon: <Help width={14} color={theme.text1} />, text: lang.yardim, event: '' },
      { icon: <Exit width={14} color={theme.text1} />, text: lang.cikisYap, event: () => this.logout() },
    ];
    return (
      <ProfilWindow profilMenu={profilMenu}>
        <div className="header">
          <b>{lang.merhaba}</b>, {name}!
        </div>
        <ul className="menu">
          {menu.map((item) => <li><a onClick={item.event} aria-hidden>{item.icon} {item.text}</a></li>)}
        </ul>
      </ProfilWindow>
    );
  }

  // changeLanguage = () => {
  //   const currentLang = localStorage.getItem('language');
  //   if (currentLang === 'tr') {
  //     localStorage.setItem('language', 'en');
  //   } else {
  //     localStorage.setItem('language', 'tr');
  //   }
  //   window.location.reload();
  // }
  selectLanguage = (id) => {
    localStorage.setItem('language', id);
    this.setState({ languageAnchorEl: null });
    window.location.reload();
  }

  /*
  *   --------------------- HARDWARE INVENTORY PAGE  ---------------------
  */

  HardwareInventoriesButton = () => {
    const { lang } = this.state;
    const { history } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ excelMenu: e.currentTarget })}
          startIcon={isWidthDown('sm', this.props.width) ? <Plus width={12} /> : <CloudExport width={14} />}
        >
          {isWidthDown('sm', this.props.width)
            ? tra('Yeni')
            : 'Excel'}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.excelMenu}
          open={Boolean(this.state.excelMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ excelMenu: null })}
        >
          <>
            <MenuItem onClick={() => this.hiddenInput.current.click()}><CloudImport color={this.props.theme.blueText} />{lang.iceAktar}</MenuItem>
            <input onClick={(e) => { e.target.value = ''; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.excelUpload(e)} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
          </>
          <MenuItem onClick={() => this.excelDownlaod('', 'Donanım Envanter Listesi')}><CloudExport width={14} color={this.props.theme.blueText} />{lang.disaAktar}</MenuItem>
          <MenuItem onClick={() => this.excelDownlaod('/sample', 'Sample')}><CloudExport width={14} color={this.props.theme.blueText} />{lang.ornekIndir}</MenuItem>
          {isWidthDown('sm', this.props.width) && <MenuItem onClick={() => { history.push('/InventoryRegister'); this.setState({ excelMenu: null }); }}><Plus width={14} color={Colors.purple} />{lang.yeniEnvanter}</MenuItem>}
        </CustomMenu>
      </div>
    );
  }

  /*
  *   ---------------------  INVENTORY PAGE  ---------------------
  */

  changeInventoryTableStatus = (type) => {
    if (type === 'Completed') {
      this.props.getApplications();
    } else {
      this.props.getApplicationDrafts();
    }
    this.props.setInventoryConfig('tableType', type);
    this.setState({ inventorytableTypeMenu: null });
  }

  changeCostingType = (type) => {
    if (type === 'Software') {
      this.props.getApplications();
    } else {
      this.props.getApplicationDrafts();
    }
    this.props.setCostingConfig('costingTypeMenu', type);
    this.setState({ costingTypeMenu: null });
  }

  InventoriesButton = () => {
    const { lang } = this.state;
    const { history } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ excelMenu: e.currentTarget })}
          startIcon={isWidthDown('sm', this.props.width) ? <Plus width={12} /> : <CloudExport width={14} />}
        >
          {isWidthDown('sm', this.props.width)
            ? tra('Yeni')
            : 'Excel'}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.excelMenu}
          open={Boolean(this.state.excelMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ excelMenu: null })}
        >
          <>
            <MenuItem onClick={() => this.hiddenInput.current.click()}><CloudImport color={this.props.theme.blueText} />{lang.iceAktar}</MenuItem>
            <input onClick={(e) => { e.target.value = ''; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.excelUpload(e)} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
          </>
          <MenuItem onClick={() => this.excelDownlaod('', 'Envanter Listesi')}><CloudExport width={14} color={this.props.theme.blueText} />{lang.disaAktar}</MenuItem>
          <MenuItem onClick={() => this.excelDownlaod('/sample', 'Sample')}><CloudExport width={14} color={this.props.theme.blueText} />{lang.ornekIndir}</MenuItem>
          {isWidthDown('sm', this.props.width) && <MenuItem onClick={() => { history.push('/InventoryRegister'); this.setState({ excelMenu: null }); }}><Plus width={14} color={Colors.purple} />{lang.yeniEnvanter}</MenuItem>}
        </CustomMenu>
      </div>
    );
  }

  companyPiUrlId = () => {
    const { integrationPiUrlId, companyPiUrlArr } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ excelMenu: e.currentTarget })}
          endIcon={<UnfoldMore />}
        >
          URL: {companyPiUrlArr?.find((i) => i?.id === integrationPiUrlId)?.name ?? tra('Seciniz')}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.excelMenu}
          open={Boolean(this.state.excelMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ excelMenu: null })}
        >

          {
            companyPiUrlArr?.map((i) => (
              <MenuItem
                onClick={() => this.props.getIntegrationPiUrl(true, i.id)}
                selected={i?.id === integrationPiUrlId}
              >{i.name}
              </MenuItem>
            ))
          }

        </CustomMenu>
      </div>
    );
  }

  excelDownlaod = (type, fileName) => {
    this.props.excelDownload(type, fileName);
    this.setState({ excelMenu: null });
  }

  excelUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.props.excelUpload(e.target.files);
    }
    this.setState({ excelMenu: null });
  }

  /*
  *   ------------------///  INVENTORY PAGE  ---------------------
  */

  /*
  *   ---------------------  Notificaiton Methods  ---------------------
  */

  handleHeaderNotification = () => {
    const { notifications, theme, isLogin } = this.props;

    return !isLogin && (
      <ClickAwayListener onClickAway={() => (this.state.notificationModalShow && this.handleNotification())}>
        <Tooltip title={tra('Bildirimler')}>
          <div className="notifications" onClick={() => this.handleNotification()} aria-hidden>
            <span style={notifications && notifications?.filter((item) => item.read === 0).length ? { transform: 'scale(1)' } : { transform: 'scale(0)' }}>
              {notifications && notifications?.filter((item) => item.read === 0).length}
            </span>
            <Notification color={theme.menuGrey} />
            {this.notificationMenu()}
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  }

  notificationMenu = () => {
    const { notificationModalShow, lang } = this.state;
    const { notifications } = this.props;
    return (
      <NotificationWindow clearAll={notifications && notifications.length > 0 && notifications.filter((notif) => notif.read === 0).length > 10} notification={notificationModalShow}>
        <div className="container">
          {
            notifications && (
              (notifications.length > 0 && notifications.filter((notif) => notif.read === 0).length > 0) ? (
                notifications.filter((notif) => notif.read === 0).slice(0, 10).map((item) => this.notificationCard(item))
              ) : (
                <NoData>
                  <img src={NoDataImg} alt="No Data" />
                  <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                </NoData>
              )
            )
          }
        </div>
        <div className="footer">
          {
            notifications && notifications.length > 0 && notifications.filter((notif) => notif.read === 0).length > 10 && (
              <Link to="/Notification"><CustomButton>{lang.dahaFazlaGor}</CustomButton></Link>
            )
          }
          {
            notifications && notifications.length > 0 && notifications.filter((notif) => notif.read === 0).length > 0 && (
              <CustomButton onClick={() => this.props.readAllNotifications()}>{lang.tumunuTemizle}</CustomButton>
            )
          }
        </div>
      </NotificationWindow>
    );
  }

  notificationCard = (item) => (
    <div onClick={() => this.props.readNotification(item.id)} key={item.id} className={`card ${item.read === 0 && 'readActive'}`}>
      <div className="dot" style={{ border: `3px solid ${notificationsTypes[item.type]?.color}` }} />
      <Link style={{ width: '100%', paddingLeft: 30 }} to={this.generateLink(item.type, item.text)}>
        <div className="cardContainer">
          <div className="cardHeader">
            <div className="title" dangerouslySetInnerHTML={{ __html: parseByMentions(item.text, item.type, true) }} />
            <div className="date">{getPostDateStr(item.createdAt)}</div>
          </div>
          {/* <div className="desc" dangerouslySetInnerHTML={{ __html: parseByMentions(item.text, item.type, true) }} /> */}
        </div>
      </Link>
    </div>
  );

  generateLink = (type, text) => {
    const { link } = notificationsTypes[type];
    let id;
    let idLink;
    if (type === 5) {
      id = text.match(/@survey\[\d+\/\d+]/g);
      idLink = id && id[0].substring(6, id[0].length - 1).substring(2);
    } else if (type === 0) {
      id = text.match(/@event\[\d+\]/g);
      idLink = id && id[0].substring(5, id[0].length - 1).substring(2);
    } else {
      id = text.match(/@app\[\d+\]/g);
      idLink = id && id[0].substring(3, id[0].length - 1).substring(2);
    }
    return `${link}/${idLink}`;
  }

  handleNotification = () => {
    const { notificationModalShow } = this.state;
    //   const { notifications } = this.props;
    //   if (notification) {
    //     notifications.forEach((element, index) => {
    //       notifications[index].isRead = false;
    //       this.setState({ notifications });
    //     });
    //   }
    this.setState({ notificationModalShow: !notificationModalShow });
  }

  /*
  *   ------------------///  Notification Method  ---------------------
  */

  handleDarkmode = () => {
    const theme = localStorage.getItem('theme');
    if (theme !== null) {
      if (theme === 'dark') {
        this.props.setAppConfig('theme', 'light');
        localStorage.setItem('theme', 'light');
      } else {
        localStorage.setItem('theme', 'dark');
        this.props.setAppConfig('theme', 'dark');
      }
    } else {
      localStorage.setItem('theme', 'dark');
      this.props.setAppConfig('theme', 'dark');
    }
  }

  handleBackButtonShow = () => {
    const { history } = this.props;
    let bool = false;
    if (history.location.pathname.includes('Admin/SurveyDetailApps')) {
      bool = true;
    } else if (history.location.pathname.includes('Admin/SurveyChartDetail')) {
      bool = true;
    } else if (history.location.pathname.includes('SingleSurveyDetail')) {
      bool = true;
    } else if (history.location.pathname.includes('Admin/SurveyDetail')) {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  displayDesktop = () => {
    const {
      activeUser, appConfig, filterDrawer, theme, history, inventoryConfig, costingConfig,
    } = this.props;
    const {
      lang, profilMenu, changePasswordModal, languageAnchorEl, // selectCompanyModalShow, selectCompanyModalShow2,
      inventorytableTypeMenu, costingTypeMenu,
    } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <HederWrapper active={history.location.pathname !== '/404'} drawer={!isWidthDown('md', this.props.width) ? filterDrawer : {}}>
          <div className="left">
            {this.headerTitle(history.location.pathname)}
            {this.handleBackButtonShow() && (
              <div className="backButton">
                <CustomButton
                  onClick={() => {
                    if (localStorage.getItem('manageSurvey') === '1') {
                      localStorage.setItem('manageSurvey', '2');
                    }
                    history.goBack();
                  }}
                >
                  <Left />
                </CustomButton>
                <p>{tra('Geri_Don')}</p>
              </div>
            )}
          </div>
          <div className="right">
            {history.location.pathname === pageShow.HOME && (
              <CustomButton onClick={() => this.props.setAppConfig('editDashboard', !appConfig.editDashboard)}>
                {
                  appConfig.editDashboard
                    ? (<><Save width={14} />{lang.kaydet} </>)
                    : (<><Pen width={14} /> {lang.dashboardDuzeni} </>)
                }
              </CustomButton>
            )}
            {history.location.pathname === pageShow.COSTING && (
              <>
                <CustomButton
                  opacity
                  onClick={(e) => {
                    this.setState({ costingTypeMenu: e.currentTarget });
                  }}
                  endIcon={<UnfoldMore />}
                >
                  {tra('Type')} / {tra(costingConfig.costingTypeMenu === 'Software' ? 'Yazilim' : 'Donanim')}
                </CustomButton>
                <CustomMenu
                  anchorEl={costingTypeMenu}
                  open={Boolean(costingTypeMenu)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  onClose={() => this.setState({ costingTypeMenu: null })}
                >
                  <ModuleSwitch software>
                    <MenuItem
                      onClick={() => {
                        this.props.getCosting();
                        this.changeCostingType('Software');
                      }}
                    >
                      {tra('Yazilim')}
                    </MenuItem>
                  </ModuleSwitch>
                  <ModuleSwitch hardware>
                    <MenuItem
                      onClick={() => {
                        this.props.getHWCostingList();
                        this.changeCostingType('Hardware');
                      }}
                    >
                      {tra('Donanim')}
                    </MenuItem>
                  </ModuleSwitch>
                </CustomMenu>
              </>
            )}
            {history.location.pathname === pageShow.COSTING && <Link to={`/CostingRegister/${costingConfig.costingTypeMenu}`} className="headerButton"><CustomButton startIcon={<Plus width={14} color={Colors.white} />}>{lang.yeniMasraf}</CustomButton></Link>}
            {history.location.pathname === pageShow.INVENTORY && (
              <>
                <CustomButton
                  opacity
                  onClick={(e) => this.setState({ inventorytableTypeMenu: e.currentTarget })}
                  endIcon={<UnfoldMore />}
                >
                  {tra('Type')} / {tra(inventoryConfig.tableType === 'Completed' ? 'Tamamlananlar' : 'Taslaklar')}
                </CustomButton>
                <CustomMenu
                  anchorEl={inventorytableTypeMenu}
                  open={Boolean(inventorytableTypeMenu)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  onClose={() => this.setState({ inventorytableTypeMenu: null })}
                >
                  <MenuItem onClick={() => this.changeInventoryTableStatus('Completed')}>{tra('Tamamlananlar')}</MenuItem>
                  <MenuItem onClick={() => this.changeInventoryTableStatus('Draft')}>{tra('Taslaklar')}</MenuItem>
                </CustomMenu>
              </>
            )}
            {history.location.pathname === pageShow.INVENTORY && <Link to="/InventoryRegister/register" className="headerButton"><CustomButton startIcon={<Plus width={14} color={Colors.white} />}>{lang.yeniEnvanter}</CustomButton></Link>}
            {history.location.pathname === pageShow.INVENTORY && this.InventoriesButton()}
            {history.location.pathname === pageShow.HARDWARE_INVENTORY && <CustomButton onClick={() => this.props.setAppConfig('NewHardwareInventoryModal', true)} startIcon={<Plus width={14} color={Colors.white} />}>{lang.yeniDonanim}</CustomButton>}
            {history.location.pathname === pageShow.LICENSE_FOLLOW && <Link to={pageShow.LICENSE_FOLLOW_REGISTER} className="headerButton"><CustomButton startIcon={<Plus width={14} color={Colors.white} />}>{lang.ekle}</CustomButton></Link>}
            {/* {history.location.pathname === pageShow.HARDWARE_INVENTORY && this.InventoriesButton()} */}
            {history.location.pathname.includes(pageShow.INTEGRATION) && this.companyPiUrlId()}

            <div className="actions">
              <Tooltip title={lang.dilDegistir} TransitionComponent={Zoom} onClick={(e) => this.setState({ languageAnchorEl: e.currentTarget })}>
                <div className="language">
                  <Earth color={theme.menuGrey} />
                </div>
              </Tooltip>
              <CustomMenu
                anchorEl={languageAnchorEl}
                open={Boolean(languageAnchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                onClose={() => this.setState({ languageAnchorEl: null })}
              >
                <MenuItem onClick={() => this.selectLanguage('tr')}>TR</MenuItem>
                <MenuItem onClick={() => this.selectLanguage('en')}>EN</MenuItem>
              </CustomMenu>
              <Tooltip title={lang.temaDegistir}>
                <span aria-hidden onClick={this.props.setTheme}><Moon color={theme.menuGrey} /></span>
              </Tooltip>
              {
                this.handleHeaderNotification()
              }
            </div>
            {
              activeUser && activeUser.user_name && (
                <ClickAwayListener onClickAway={() => (profilMenu && this.setState({ profilMenu: false }))}>
                  <div className="profile">
                    <Link to="/Profil">
                      <Tooltip title={activeUser.name} TransitionComponent={Zoom}>
                        <img src={`${activeUser.profile_img}?v=${new Date().getTime()}`} alt={activeUser.name} />
                      </Tooltip>
                    </Link>
                    <ExpandMoreSharp style={{ color: theme.menuGrey }} onClick={() => this.setState({ profilMenu: !profilMenu })} />
                    {this.profileMenu(activeUser.name)}
                  </div>
                </ClickAwayListener>
              )
            }
          </div>
          {/* <SelectCompanyModal
            open={selectCompanyModalShow || selectCompanyModalShow2}
            close={() => this.setState({ selectCompanyModalShow2: false })}
          /> */}
          <ChangePassword
            open={changePasswordModal}
            close={() => this.setState({ changePasswordModal: false })}
          />
          <AddCompanyModal />
        </HederWrapper>
      </div>
    );
  }

  render() {
    return isWidthDown('md', this.props.width)
      ? (
        <DisplayMobileHeader
          notificationsComponent={this.handleHeaderNotification()}
          addCompanyModalComponent={<AddCompanyModal />}
          setTheme={this.props.setTheme}
        />
      )
      : this.displayDesktop();
  }
}

Header.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  setTheme: PropTypes.func.isRequired,
  filterDrawer: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  isLogin: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  excelUpload: PropTypes.func.isRequired,
  excelDownload: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  readAllNotifications: PropTypes.func.isRequired,
  setActiveNotFound: PropTypes.func.isRequired,
  setLoginState: PropTypes.func.isRequired,
  devicesDetail: PropTypes.object.isRequired,
  getApplications: PropTypes.func.isRequired,
  getApplicationDrafts: PropTypes.func.isRequired,
  setInventoryConfig: PropTypes.func.isRequired,
  setCostingConfig: PropTypes.func.isRequired,
  inventoryConfig: PropTypes.object.isRequired,
  generalReportType: PropTypes.string.isRequired,
  costingConfig: PropTypes.object.isRequired,
  getCosting: PropTypes.func.isRequired,
  getHWCostingList: PropTypes.func.isRequired,
  companyPiUrlArr: PropTypes.any.isRequired,
  getIntegrationPiUrl: PropTypes.func.isRequired,
  integrationPiUrlId: PropTypes.number.isRequired,
  initModule: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activeUser: selectActiveUser(),
  appConfig: selectAppConfig(),
  filterDrawer: selectFilterDrawer(),
  notifications: selectNotifications(),
  isLogin: selectLoginState(),
  application: selectApplicationDetail(),
  devicesDetail: selectHardwareDeviceDetail(),
  inventoryConfig: selectInventoryConfig(),
  costingConfig: selectCostingConfig(),
  generalReportType: selectGeneralReportType(),
  companyPiUrlArr: selectInfos('CompanyPiUrls'),
  integrationPiUrlId: selectIntegrationPiUrlId(),
  initModule: selectUserModule(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setAppConfig,
      setInventoryConfig,
      setCostingConfig,
      excelUpload,
      excelDownload,
      readNotification,
      readAllNotifications,
      setActiveNotFound,
      setLoginState,
      getApplications,
      getApplicationDrafts,
      getCosting,
      getHWCostingList,
      getIntegrationPiUrl,
    }, dispatch)
  )
);

const HeaderWithTheme = withTheme(Header);
const HeaderWithWidth = withWidth()(HeaderWithTheme);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderWithWidth));

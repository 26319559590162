import { bindActionCreators, compose } from 'redux';
import { Chip, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropsTypes from 'prop-types';
import React, { Component } from 'react';

import { Edit } from '@material-ui/icons';
import { Plus, Remove } from '../../assets/icons';
import Table from '../../components/Table';

import {
  CustomButton, HardwareDetailBottomSheet, PageContent, PageWrapper, TableWrapper,
} from '../../assets/styledNew';
import Input from '../../components/Input';
// import NoDataImg from '../../assets/img/noData.png';
import { FormatUTCLocaleDateStr, tra } from '../../commons/utils';
import { selectInfos, selectLicenseFollowSelectData } from '../../store/selectors';
import Validator from '../../commons/validator';
import { Colors } from '../../assets/statics';
import NewLicenseAddUserModal from '../../components/modals/NewLicenseAddUserModal';
import { createLicenseFollow, getLicenseFollowAgreement, getLicenseFollowUser } from '../../store/actions';

class LicenseFollowRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreementData: {
        contract_id: '',
        app_id: '',
        license_type_id: '',
        quantity: '',
      },
      userDataArray: [],
      selectUser: {
        index: -1,
        data: false,
      },
      newUserModalShow: false,
    };
  }

  componentDidMount() {
    this.props.getLicenseFollowAgreement();
    this.props.getLicenseFollowUser();
  }

  handleSave = (saveView) => {
    const {
      agreementData: {
        contract_id,
        app_id,
        license_type_id,
        quantity,
      }, userDataArray,
    } = this.state;

    if (Validator.allValid()) {
      const data = {
        // agreement
        contract_id: contract_id?.id,
        app_id: app_id?.id,
        license_type_id: license_type_id?.id,
        quantity,
        // userArray
        LicenseKeys: userDataArray.map((user) => ({
          ...user,
          user_id: user.user_id?.id,
          // userArray-hwa
          LicenseKeyHardwares: user?.LicenseKeyHardwares?.map((hwa) => hwa?.id && ({
            hardware_inventory_id: hwa?.id,
          })),
        })),
      };
      this.props.createLicenseFollow(data, saveView);
      // create(bool);
    } else {
      Validator.showMessages();
      this.forceUpdate();
    }
  }

  setAgreementData = (key, val) => this.setState((s) => ({ agreementData: { ...s.agreementData, [key]: val } }))

  getAgreementData = (key) => this.state.agreementData?.[key]

  // valid
  validator = (name, validateRules = 'required') => ({
    error: Validator.message(name, this.getAgreementData(name), validateRules),
    helperText: Validator.message(name, this.getAgreementData(name), validateRules),
  });

  columns = () => [
    {
      name: 'user_id',
      text: tra('Kullanici_Adi'),
      type: 'text',
      tooltip: false,
      //  searchable: true,
      // filterLocation: 'hardwareType',
      //  filterable: true,
      align: 'center',
      customBody: (val) => val?.full_name ?? '',
    },
    {
      name: 'user_id',
      text: tra('Sicil_No'),
      type: 'text',
      tooltip: false,
      // searchable: true,
      // filterLocation: 'hardwareType',
      // filterable: true,
      align: 'center',
      // customBody: (val) => this.props?.licenseSelectData?.user?.find((i) => i?.id === val)?.registry_no || '',
      customBody: (val) => val?.registry_no ?? '',
    },
    {
      name: 'key',
      text: tra('Lisans_Key'),
      sortable: true,
      tooltip: true,
      // searchable: true,
      align: 'center',
      // customBody: (val) => '',
      cellWidth: 200,
    },
    {
      name: 'LicenseKeyHardwares',
      text: tra('Donanim_No'),
      sortable: true,
      type: 'array',
      // filterable: true,
      // searchable: true,
      align: 'center',
      customBody: (value) => (value ? (
        <div style={{ display: 'inline-flex', gap: '3px' }}>
          {value?.map((i) => <Chip style={{ background: Colors.blueBackg, color: Colors.blueText, borderRadius: '10px' }} label={i?.tag} />)}
        </div>
      ) : ''),
    },
    {
      name: 'contract_start',
      text: tra('Baslangic_Tarihi'),
      sortable: true,
      type: 'text',
      // filterable: true,
      // searchable: true,
      align: 'center',
      customBody: (val) => (val ? FormatUTCLocaleDateStr(val) : ''),
    },
    {
      name: 'contract_end',
      text: tra('Bitis_Tarihi'),
      sortable: true,
      type: 'text',
      // filterable: true,
      // searchable: true,
      align: 'center',
      customBody: (val) => (val ? FormatUTCLocaleDateStr(val) : ''),
    },
    {
      name: 'owner',
      text: tra('Zimmet'),
      sortable: true,
      type: 'text',
      filterable: true,
      // searchable: true,
      align: 'center',
      customBody: (val) => (val ? <CheckBoxIcon htmlColor={Colors.lightBlue100} /> : <CheckBoxOutlineBlankIcon htmlColor={Colors.lightGrey200} />),
    },
    {
      name: 'description',
      text: tra('Description'),
      sortable: true,
      type: 'text',
      // filterable: true,
      searchable: true,
      align: 'center',
      cellWidth: 200,
    },
    {
      name: 'id',
      text: tra('Duzenle'),
      editable: false,
      align: 'center',
      customBody: (value, name, index, row) => (
        <div
          style={{ cursor: 'pointer' }}
          aria-hidden
          onClick={() =>
            this.setState({
              selectUser: {
                index,
                data: row,
              },
              newUserModalShow: true,
            })}
        >
          <Edit width={15} />
        </div>
      ),
    },
    {
      name: 'id',
      text: tra('Sil'),
      editable: false,
      align: 'center',
      customBody: (value, name, index) => (
        <div
          style={{ cursor: 'pointer' }}
          aria-hidden
          onClick={() => this.setState((s) => ({ userDataArray: s?.userDataArray?.filter((val, indx) => indx !== index) }))}
        >
          <Remove color={Colors.red} width={15} />
        </div>
      ),
    },

  ];

  actionShowModal = () => {
    if (Validator.allValid()) {
      this.setState({ newUserModalShow: true });
    } else {
      Validator.showMessages();
      this.forceUpdate();
    }
  }

  actionNumberQuantity = () => {
    const val = +this.getAgreementData('quantity') || 0;
    return {
      plusClick: () => this.setAgreementData('quantity', val + 1),
      minusClick: () => val > 0 && this.setAgreementData('quantity', val - 1),
    };
  }

  render() {
    const {
      licenseTypes,
      licenseSelectData: { agreement, user },
    } = this.props;

    const {
      userDataArray,
      selectUser,
    } = this.state;

    const isSelectDataAgreement = !Object.values(this.state.agreementData?.contract_id || {})?.length;

    if (!('agreement' in this.props.licenseSelectData)) return '...Loading';

    Validator.purgeFields();

    return (
      <>
        <PageWrapper>
          <PageContent>
            <div className="pageInner">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    type="autoComplete"
                    title={tra('Sozlesme_Adi')}
                    multiple={false}
                    value={this.getAgreementData('contract_id')}
                    onChange={(e, data) => {
                      this.setAgreementData('contract_id', data);
                      this.setAgreementData('app_id', '');
                    }}
                    labelKey="agreement"
                    data={agreement}
                    indicate
                    emptyItem
                    {...this.validator('contract_id')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="autoComplete"
                    title={tra('Uygulama_Ismi')}
                    multiple={false}
                    value={this.getAgreementData('app_id')}
                    onChange={(e, data) => this.setAgreementData('app_id', data)}
                    data={this.getAgreementData('contract_id')?.Applications ?? []}
                    disabled={isSelectDataAgreement}
                    indicate
                    emptyItem
                    {...this.validator('app_id')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="autoComplete"
                    value={this.getAgreementData('license_type_id')}
                    onChange={(e, data) => this.setAgreementData('license_type_id', data)}
                    multiple={false}
                    disabled={isSelectDataAgreement}
                    data={licenseTypes}
                    title={tra('Lisans_Turu')}
                    indicate
                    emptyItem
                    {...this.validator('license_type_id')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="number"
                    disabled={isSelectDataAgreement}
                    title={tra('Lisans_Adedi')}
                    value={this.getAgreementData('quantity')}
                    onChange={(e) => this.setAgreementData('quantity', e.target.value)}
                    {...this.actionNumberQuantity()}
                    {...this.validator('quantity')}
                  />
                </Grid>
                <Grid item style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} xs={12} alignItems="center" justifyContent="flex-end">
                  <CustomButton
                    onClick={() => this.actionShowModal()}
                    endIcon={<Plus width={15} />}
                  >
                    {tra('Kullanici_Ekle')}
                  </CustomButton>
                </Grid>
                <Grid container item xs={12}>
                  <TableWrapper style={{ minHeight: '60vh' }}>
                    <Table
                      id="licenseFollowTable"
                      data={userDataArray}
                      columns={this.columns()}
                      options={{
                        header: {
                          visible: false,
                          columnSetting: false,
                        },
                        pagination: {
                          active: true,
                        },
                      }}
                    />

                  </TableWrapper>
                </Grid>
              </Grid>
            </div>
          </PageContent>
        </PageWrapper>
        <NewLicenseAddUserModal
          open={this.state.newUserModalShow}
          close={() => this.setState({ newUserModalShow: false })}
          agreement={this.getAgreementData('contract_id') || {}}
          createMode
          multipleUserAdd={{
            value: userDataArray,
            onChange: (val, callback) => this.setState({ userDataArray: val }, callback),
          }}
          selectUser={{
            index: selectUser?.index ?? -1,
            data: selectUser?.data ?? {},
            reset: () => this.setState({ selectUser: {} }),
          }}
          user={user}
        />

        <HardwareDetailBottomSheet show>
          <Grid container>
            <Grid container justify="space-between" item xs={12}>
              <CustomButton outlined onClick={() => this.props.history.push('/LicenseFollow')}>{tra('Iptal_Et')}</CustomButton>
              <div>
                <CustomButton style={{ marginRight: '5px' }} onClick={() => this.handleSave(true)}>{tra('Save_View')}</CustomButton>
                <CustomButton onClick={() => this.handleSave(false)}>{tra('Save_Close')}</CustomButton>
              </div>
            </Grid>
          </Grid>
        </HardwareDetailBottomSheet>
      </>
    );
  }
}

LicenseFollowRegister.propTypes = {
  licenseSelectData: PropsTypes.object.isRequired,
  agreement: PropsTypes.array.isRequired,
  licenseTypes: PropsTypes.array.isRequired,
  user: PropsTypes.array.isRequired,
  history: PropsTypes.object.isRequired,
  createLicenseFollow: PropsTypes.func.isRequired,
  getLicenseFollowAgreement: PropsTypes.func.isRequired,
  getLicenseFollowUser: PropsTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  licenseSelectData: selectLicenseFollowSelectData(),
  licenseTypes: selectInfos('LicenseTypes'),
});
const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createLicenseFollow,
      getLicenseFollowAgreement,
      getLicenseFollowUser,
    }, dispatch)
  )
);
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(LicenseFollowRegister);

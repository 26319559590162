/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withTheme } from 'styled-components';
import {
  ServerTypeIcon,
  NetworkTypeIcon,
  EndUserTypeIcon,
  VirtualMachineTypeIcon,
  NetworkStorageTypeIcon,
  NetworkServerTypeIcon,
  SwitchTypeIcon,
  RouterTypeIcon,
  PrinterTypeIcon,
  FirewallTypeIcon,
  AccessPointTypeIcon,
  ScannerTypeIcon,
  EndUserHandheldDevicesTypeIcon,
  EndUserComputerTypeIcon,
  EndUserNotebookTypeIcon,
  EndUserTabletTypeIcon,
  EndUserPhoneTypeIcon,
  HardwareOtherDeviceType,
  NetworkDevicesType,
  SwitchRouterTypeIcon,
  IPCameraTypeIcon,
  KabinetTypeIcon,
  NetworkComponentTypeIcon,
  StorageDevicesTypeIcon,
  OtherSecurityTypeIcon,
  SimcardTypeIcon,
  // LeftArrow,
} from '../../assets/icons';

import {
  ModalHeader,
  ModalContent,
  CustomModal,
  HardwareTypeCard,
  BottomSheetWrapper,
} from '../../assets/styledNew';
import BottomSheet from '../BottomSheet';
import { tra } from '../../commons/utils';

import { getHardwareModalCategory, getHardwareModalTypes } from '../../store/actions';
import { selectHardwareModalCategory, selectHardwareModalTypes } from '../../store/selectors';
// import { hwaLang } from '../../assets/hardwareStatic';

const hardwareModalPageEnum = {
  MAİN_MENU: 'MainMenu',
  SERVER_MENU: 'ServerMenu',
  NETWORK_MENU: 'NetworkMenu',
  SECURITY_MENU: 'SecurityMenu',
  ENDUSER_MENU: 'EnduserMenu',
  OTHER_MENU: 'OtherMenu',
  VIRTUAL_MACHINE_MENU: 'VirtualMachineMenu', // kaldır
};
class NewHardwareInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        donanimTipi: tra('Donanim_Tipi'),
        donanimModalBilgilendirmeMesaji: tra('Donanim_Modal_Bilgilendirme_Mesaji'),
        yazici: tra('Yazici'),
        erisimNoktasi: tra('Erisim_Noktasi'),
        guvenlikDuvari: tra('Guvenlik_Duvari'),
        depolama: tra('Depolama'),
        sunucu: tra('Sunucu'),
        sanalMakine: tra('Sanal_Makine'),
        sonKullanici: tra('Son_Kullanici'),
        networkGuvenlikVeCevreBirimleri: tra('Network_Guvenlik_CevreBirimleri'),
        sunucuVeVeriDepolama: tra('Sunucu_Ve_VeriDepolama'),
        tablet: tra('Tablet'),
        notebook: tra('Notebook'),
        bilgisayar: tra('Bilgisayar'),
        elTerminali: tra('El_Terminali'),
        tarayici: tra('Tarayici'),
        yonlendirici: tra('Yonlendirici'),
        switch: tra('Switch'),
        telefon: tra('Telefon'),
        networkCihazlar: tra('Network_Cihazlari'),
        guvenlikCihazi: tra('Guvenlik_Cihazi'),
        diger: tra('Diger'),
      },
      activePage: 'MainMenu',
      hardwareTypesId: 0,
    };
    // props.getHardwareModalTypes();
  }

  /*
 *   ---------------------  Hardware Modal Page  ---------------------
*/

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/no-did-update-set-state
    if (prevProps.open !== this.props.open) this.setState({ activePage: 'MainMenu' });
  }

  mainMenu=() => {
    const { lang } = this.state;
    const cardItem = [
      {
        title: lang.sunucuVeVeriDepolama,
        icon: <ServerTypeIcon color={this.props.theme.blueText} />,
        click: () => { this.setState({ activePage: hardwareModalPageEnum.SERVER_MENU, hardwareTypesId: 1 }); },
      },
      {
        title: lang.networkCihazlar,
        icon: <NetworkDevicesType color={this.props.theme.blueText} />,
        click: () => { this.setState({ activePage: hardwareModalPageEnum.NETWORK_MENU, hardwareTypesId: 2 }); },
      },
      {
        // title: lang.networkGuvenlikVeCevreBirimleri,
        title: lang.guvenlikCihazi,
        icon: <NetworkTypeIcon color={this.props.theme.blueText} />,
        click: () => { this.setState({ activePage: hardwareModalPageEnum.SECURITY_MENU, hardwareTypesId: 3 }); },
      },
      {
        title: lang.sonKullanici,
        icon: <EndUserTypeIcon color={this.props.theme.blueText} />,
        click: () => { this.setState({ activePage: hardwareModalPageEnum.ENDUSER_MENU, hardwareTypesId: 4 }); },
      },
      {
        title: lang.yazici,
        icon: <PrinterTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(5, 15),
        // yazıcı sayfası
      },

      // {
      //   title: lang.diger,
      //   icon: <HardwareOtherDeviceType color={this.props.theme.blueText} />,
      //   // click: () => { this.setState({ activePage: hardwareModalPageEnum.OTHER_MENU, hardwareTypesId: 6 }); },
      // },

    ];
    return this.modalRender({
      cardItem,
      headerTitle: this.state.lang.donanimTipi,
      gridItem: 4,
    });
  }

  serverMenu=(typesID) => {
    const cardItem = [
      {
        title: this.state.lang.sunucu,
        icon: <NetworkServerTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 1),
      },
      {
        title: this.state.lang.depolama,
        icon: <NetworkStorageTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 2),
      },
      // {
      //   title: this.state.lang.sanalMakine,
      //   icon: <VirtualMachineTypeIcon color={this.props.theme.blueText} />,
      //   click: () => this.routerPages(typesID, 3),
      // },
    ];

    return this.modalRender({
      cardItem,
      headerTitle: this.state.lang.sunucuVeVeriDepolama,
      // desc: this.state.lang.donanimModalBilgilendirmeMesaji,
      gridItem: 4,
    });
  }

  networkMenu=(typesID) => {
    const { lang } = this.state;
    const cardItem = [
      {
        title: lang.switch,
        icon: <SwitchRouterTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 3),
      },
      // {
      //   title: lang.yonlendirici,
      //   icon: <RouterTypeIcon color={this.props.theme.blueText} />,
      //   click: () => this.routerPages(typesID, 5),
      // },
      {
        title: lang.erisimNoktasi,
        icon: <AccessPointTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 4),
      },
      {
        title: tra('Ip_Cihazlari'),
        icon: <IPCameraTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 5),
      },
      {
        title: tra('Depolama_Cihazi'),
        icon: <StorageDevicesTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 6),
      },
      {
        title: tra('Kabinet'),
        icon: <KabinetTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 7),
      },
      {
        title: tra('Network_Bileşenleri'),
        icon: <NetworkComponentTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 8),
      },
      // {
      //   title: lang.tarayici,
      //   icon: <ScannerTypeIcon color={this.props.theme.blueText} />,
      //   click: () => this.routerPages(typesID, 9),
      // },
    ];

    return this.modalRender({
      cardItem,
      headerTitle: 'Network',
      desc: lang.donanimModalBilgilendirmeMesaji,
      gridItem: 4,
    });
  }

  endUserMenu=(typesID) => {
    const { lang } = this.state;
    const cardItem = [
      {
        title: lang.telefon,
        icon: <EndUserPhoneTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 12),
      },
      {
        title: lang.tablet,
        icon: <EndUserTabletTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 13),
      },
      // {
      //   title: lang.notebook,
      //   icon: <EndUserNotebookTypeIcon color={this.props.theme.blueText} />,
      //   click: () => this.routerPages(typesID, 12),
      // },

      {
        title: lang.bilgisayar,
        icon: <EndUserComputerTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 11),
      },
      {
        title: lang.elTerminali,
        icon: <EndUserHandheldDevicesTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 14),
      },
      {
        title: tra('Simcard'),
        icon: <SimcardTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 17),
      },
    ];

    return this.modalRender({
      cardItem,
      headerTitle: lang.sonKullanici,
      desc: lang.donanimModalBilgilendirmeMesaji,
      gridItem: 4,
    });
  }

  securityMenu=(typesID) => {
    const { lang } = this.state;
    const cardItem = [
      {
        title: lang.guvenlikDuvari,
        icon: <FirewallTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 9),
      },
      {
        title: tra('Diger_Guvenlik_Duvari'),
        icon: <OtherSecurityTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 10),
      },
    ];

    return this.modalRender({
      cardItem,
      headerTitle: lang.guvenlikCihazi,
      desc: lang.donanimModalBilgilendirmeMesaji,
      gridItem: [0, 1, 3].includes(cardItem.length % 4) ? 4 : 3,
    });
  }

  otherMenu=(typesID) => {
    const { lang } = this.state;
    const cardItem = [
      {
        title: lang.elTerminali,
        icon: <EndUserHandheldDevicesTypeIcon color={this.props.theme.blueText} />,
        click: () => this.routerPages(typesID, 14),
      },
    ];

    return this.modalRender({
      cardItem,
      headerTitle: lang.diger,
      desc: lang.donanimModalBilgilendirmeMesaji,
      gridItem: [0, 1, 3].includes(cardItem.length % 4) ? 4 : 3,
    });
  }

  /*
   *   ---------------------///  Hardware Modal Page  ---------------------
  */

  routerPages=(typeID, categoryID) => {
    if (typeID || categoryID) {
      this.props.close();
      return this.props.history.push(`/HardwareRegister/${typeID}/${categoryID}`);
    }
    return this.props.history.push('/404');
  }

  modalSwitch=() => {
    switch (this.state.activePage) {
      case hardwareModalPageEnum.MAİN_MENU:
        return this.mainMenu();
      case hardwareModalPageEnum.SERVER_MENU:
        return this.serverMenu(this.state.hardwareTypesId);
      case hardwareModalPageEnum.NETWORK_MENU:
        return this.networkMenu(this.state.hardwareTypesId);
      case hardwareModalPageEnum.ENDUSER_MENU:
        return this.endUserMenu(this.state.hardwareTypesId);
      case hardwareModalPageEnum.SECURITY_MENU:
        return this.securityMenu(this.state.hardwareTypesId);
      case hardwareModalPageEnum.OTHER_MENU:
        return this.otherMenu(this.state.hardwareTypesId);
      default:
        return 'Not Found Page';
    }
  }

  hardwareTypeCardItem=(item) => (
    <HardwareTypeCard onClick={item.click}>
      <p>{item.title}</p>
      <div className="cardIcon"> {item.icon}</div>
    </HardwareTypeCard>
  );

  modalRender = (item) => {
    const {
      close,
    } = this.props;
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;

    return (
      <ContentWrapper>
        <ModalHeader>
          <div className="withBackButton">
            {this.state.activePage !== hardwareModalPageEnum.MAİN_MENU && <ArrowBackIosIcon onClick={() => this.setState({ activePage: hardwareModalPageEnum.MAİN_MENU })} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />}
            <h1>{item.headerTitle}</h1>
          </div>
          <CloseIcon onClick={close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner">
            {item.desc && <p style={{ textAlign: 'center', padding: '15px', color: this.props.theme.text1 }}>{item.desc}</p>}
            <Grid
              container
              style={{ justifyContent: 'center' }}
            >
              {item.cardItem && item.cardItem.map((element) => (
                <Grid
                  item
                  container
                  xs={12}
                  md={(item.gridItem || 4)}
                  style={{ justifyContent: 'center' }}
                >
                  {this.hardwareTypeCardItem(element)}
                </Grid>
              ))}
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} style={{ maxWidth: '790px', margin: '0 auto' }} maxWidth={false} fullWidth="true" scroll="body">{this.modalSwitch()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll isOpen={(open) => open === false && this.props.close()}>{this.modalSwitch()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

NewHardwareInventory.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getHardwareModalTypes: PropTypes.func.isRequired,
  getHardwareModalCategory: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hardwareCategory: selectHardwareModalCategory(),
  hardwareTypes: selectHardwareModalTypes(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getHardwareModalTypes,
      getHardwareModalCategory,
    }, dispatch)
  )
);

const NewHardwareInventoryWithTheme = withTheme(NewHardwareInventory);
const NewHardwareInventoryWithWidth = withWidth()(NewHardwareInventoryWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(NewHardwareInventoryWithWidth);

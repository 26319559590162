import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import {
  CustomButton,
  HardwareAddedAccordion, HardwareAddedAccordionDetails, HardwareAddedAccordionSummary, HardwareAddedGroup, HardwareInputWrapper,
} from '../../assets/styledNew';
import { Plus, TrashIcon } from '../../assets/icons';
import InputRender from './InputRender';
import { selectAppConfig } from '../../store/selectors';
import { tra } from '../../commons/utils';
// import { hardwareAddedArea } from '../../assets/hardwareStatic';
import { HardwareContext } from '../../commons/context/HardwareContext';

class AccordionCard extends Component {
    // eslint-disable-next-line react/static-property-placement
    static contextType= HardwareContext

    constructor(props) {
      super(props);

      this.state = {
        accordionIndex: 0,
        addGroupIndex: 0,
        errValidateGroup: {},
        lang: {
          ekle: tra('Ekle'),
        },
      };
      const { addGroupName, objectGroupName, definedName } = this.props.unitData;
      this.pathDataRemove = [objectGroupName, addGroupName].filter((i) => i).join('.');
      this.definedName = definedName || false;
    }

    componentDidMount() {
      const { accordionIndexData, accordionLengthData } = this.context;
      const { addGroupName } = this.props.unitData;
      const negativeControl = (number) => (number < 0 ? 0 : number);
      this.setState({ accordionIndex: accordionIndexData[addGroupName], addGroupIndex: negativeControl(accordionLengthData[addGroupName] - 1) });
    }

    /*
     *   ---------------------  remove validate field  ---------------------
    */

  accordionValidateError =(unitData, addGroupIndex) => this.context.SubmitData?.[unitData.addGroupName]?.[addGroupIndex] || this.context.ServiceData?.[unitData.addGroupName]?.[addGroupIndex]

  addItem=() => {
    this.setState(
      (state) => ({ addGroupIndex: state.addGroupIndex + 1, accordionIndex: state.addGroupIndex + 1 }),
      () => {
        this.context.setContextAccordionLength({
          name: this.props.unitData.addGroupName,
          index: this.state.addGroupIndex + 1,
        });
        this.context.setContextAccordionIndex({
          name: this.props.unitData.addGroupName,
          index: this.state.addGroupIndex,
        });
      },
    );
  }

  handleChangeAccordion = (index) => this.setState({ accordionIndex: index },
    () => this.context.setContextAccordionIndex({
      name: this.props.unitData.addGroupName,
      index,
    }));

    handleRemove=() => {
      if (this.definedName) { this.context.setContextDeleteSumbitDataDefined({ fieldName: this.pathDataRemove, definedNamePath: this.definedName }); }
    }

  removeItem=(index, objectName) => {
    // const { gridDataState } = this.state;
    // const deleteTempData = [...gridDataState];
    // this.context.setContextAddAreaDeleteSubmitData({
    //   addGroupName: objectName,
    //   addGroupIndex: gridDataState[index].addGroupIndex,
    // });

    // const tempDataIndex = deleteTempData.findIndex((item) => item.addGroupName === objectName);
    // deleteTempData.splice(index, 1);
    // const tempData = [...deleteTempData.filter((item) => item.addGroupName === objectName)];
    // tempData.forEach((item, i, array) => {
    //   const temp = item;
    //   temp.addGroupIndex = i;
    //   temp.addButton = false;
    //   if (array.length === i + 1) {
    //     temp.addButton = true;
    //   }
    // });
    // deleteTempData.splice(tempDataIndex, tempData.length, ...tempData);

    // this.setState({ gridDataState: deleteTempData });

    this.setState(
      (state) => ({ addGroupIndex: state.addGroupIndex - 1, accordionIndex: state.addGroupIndex - 1 }),
      () => {
        this.context.setContextAccordionLength({
          name: objectName,
          index: this.state.addGroupIndex + 1,
        });
        this.context.setContextAccordionIndex({
          name: objectName,
          index: this.state.addGroupIndex,
        });
        this.context.setContextAddAreaDeleteSubmitData({
          addGroupName: objectName,
          addGroupIndex: index,
        });
      },
    );
  }

  accordionGroup(unitData, addGroupIndex) {
    const { pagesIndex } = this.props;
    const { accordionIndex, errValidateGroup } = this.state;
    const focusItem = accordionIndex === addGroupIndex;
    const buttonDisabled = this.context.isHardwareDetail ? !this.props.appConfig.disabled : true;
    return (
      <HardwareAddedAccordion
        key={uuidv4()}
        expanded={focusItem}
        onChange={() => this.handleChangeAccordion(addGroupIndex)}
      >
        <HardwareAddedAccordionSummary key={uuidv4()} focusItem={focusItem} expandIcon={<ExpandMoreIcon />} errorValidate={focusItem ? false : errValidateGroup[addGroupIndex]}>
          <div className="flexGrid">
            <p className="accordionTitle"> {`${unitData.subtitle || unitData.title} ${addGroupIndex + 1}`}</p>
            {buttonDisabled && focusItem && (
            <IconButton key={uuidv4()} className="accordionIconButton" onClick={() => this.removeItem(addGroupIndex, unitData.addGroupName)}>
              <TrashIcon />
            </IconButton>
            )}
          </div>
        </HardwareAddedAccordionSummary>
        <HardwareAddedAccordionDetails key={uuidv4()}>
          <HardwareInputWrapper key={uuidv4()}>
            <div className="inputContainer">
              {unitData.inputArray.map((inputData) => (
                <InputRender
                  key={uuidv4()}
                  inputObject={inputData}
                  objectGroupName={unitData.objectGroupName}
                  addGroupName={unitData.addGroupName}
                  addGroupIndex={addGroupIndex}
                  groupValidateCheckLength={unitData.groupValidateCheck && unitData.inputArray.length}
                  validateErrMessageTitle={inputData.validateRules === 'groupInputControl' ? (unitData.subtitle || unitData.title) : false}
                  validateGroupNameArray={unitData.inputArray.map((input) => [unitData.addGroupName, input.name, addGroupIndex].join('.'))}
                  pagesNumber={pagesIndex}
                  accordionCheckValidate={(check) => this.setState((state) => ({ errValidateGroup: { [addGroupIndex]: check, ...state.errValidateGroup[addGroupIndex] } }))}
                />
              ))}
            </div>
          </HardwareInputWrapper>
        </HardwareAddedAccordionDetails>
      </HardwareAddedAccordion>
    );
  }

  render() {
    const { unitData } = this.props;
    const { addGroupIndex } = this.state;
    const buttonDisabled = this.context.isHardwareDetail ? !this.props.appConfig?.disabled : true;
    const checkValidateButton = this.context.SubmitData?.[unitData.addGroupName]?.[addGroupIndex] || this.context.ServiceData?.[unitData.addGroupName]?.[addGroupIndex];
    return (
      <HardwareAddedGroup
        key={uuidv4()}
      >
        {(unitData.subtitle || unitData.title) && (
        <div className="accordionHeader">
          <h4>{unitData.subtitle || unitData.title}</h4>
          {buttonDisabled && unitData.removeButton && <CustomButton transparent onClick={() => this.handleRemove(unitData)} startIcon={<TrashIcon />}>{tra('Sil')}</CustomButton>}
        </div>
        )}
        {[...Array(this.state.addGroupIndex + 1)].map((value, index) => this.accordionGroup(unitData, index))}
        { buttonDisabled && unitData.addButton && <CustomButton key={uuidv4()} transparent disabled={!checkValidateButton} onClick={() => this.addItem()} startIcon={<Plus width={12} />}>{`${unitData.subtitle} ${this.state.lang.ekle}`}</CustomButton>}
      </HardwareAddedGroup>
    );
  }
}

AccordionCard.propTypes = {
  appConfig: PropTypes.object.isRequired,
  unitData: PropTypes.array.isRequired,
  //   index: PropTypes.number.isRequired,
  pagesIndex: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ...
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(AccordionCard);

import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTheme } from 'styled-components';
import Switch from '@material-ui/core/Switch';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import { parse } from 'date-fns';
import { withRouter } from 'react-router-dom';
import {
  Earth, ProfilChange, Refresh, Info, Help, Moon, Check, Lock, ProfilOutlined,
} from '../../assets/icons';
import { tra, pageShow } from '../../commons/utils';
import { setAppConfig, startRequiresServices } from '../../store/actions';
import { selectAppConfig, selectActiveUser } from '../../store/selectors';
import BottomSheet from '../../components/BottomSheet';
import { PageContent, PageWrapper, CustomDivider } from '../../assets/styledNew';
import ChangePassword from '../../components/modals/ChangePassword';

class ProfilSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        // profilDuzenle: tra('Profil_Duzenle'),
        sirketDegistir: tra('Sirket_Degistir'),
        sifreDegistir: tra('Sifre_Degistir'),
        hakkinda: tra('Hakkinda'),
        yardim: tra('Yardim'),
        temaDegistir: tra('Tema_Degistir'),
        dilDegistir: tra('Dil_Degistir'),
      },
      // languageSheet: false,
      // companySheet: false,
      // changePasswordSheet: false,
      openSheet: false,

    };
    this.openSheetEnum = {
      language: 0,
      company: 1,
      changePassword: 2,
    };
  }

  /*
*   ---------------------  MenuComponent  ---------------------
*/
  menuItemComponent = (item) => (
    <>
      <CustomDivider />
      <ListItem button onClick={item.event} aria-hidden>
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
        <ListItemText style={{ color: `${this.props.theme.text1}` }}>{item.text}</ListItemText>
      </ListItem>
    </>
  )

  menuItemSwitchComponent = (item) => (
    <>
      <CustomDivider />
      <ListItem>
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
        <ListItemText style={{ color: `${this.props.theme.text1}` }}>{item.text}</ListItemText>
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            color="primary"
            checked={item.value}
            onChange={item.event}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )

  /*
*   ------------------///  MenuComponent  ---------------------
*/
  /*
*   ---------------------  bottomSheet  ---------------------
*/

  bottomSheet = (item) => (
    <BottomSheet snapPoint={[400, 0]} initialSnap={0} open={item.open} isOpen={(open) => open === false && this.setState({ openSheet: false })}>
      {item.children}
    </BottomSheet>
  )

  companySheet = () => {
    const { activeUser } = this.props;
    // activeUser.companies.map((item) => <MenuItem onClick={() => this.selectCompany(item.id)}>{item.name}</MenuItem>)
    return (
      <>
        {
          activeUser && activeUser.companies && activeUser.companies.map((item) => (
            <ListItem button aria-hidden onClick={() => this.selectCompany(item.id)}>
              <ListItemAvatar>
                <Avatar
                  src={item.company_image}
                />
              </ListItemAvatar>
              <ListItemText style={{ color: `${this.props.theme.text1}` }}>{item.name}</ListItemText>
              <ListItemIcon style={this.isCompany(item.id) ? {} : { visibility: 'hidden' }}>
                <Check width={16} color={this.props.theme.text1} />
              </ListItemIcon>
            </ListItem>
          ))
        }
      </>
    );
  }

  isCompany = (id) => Boolean(id === Number(localStorage.getItem('company')))

  selectCompany = (id) => {
    localStorage.setItem('company', id);
    // this.setState({ companySheet: false });
    // this.setState({ languageSheet: false });
    this.props.startRequiresServices();
  }

  /*
  *   ---------------------  languageSheet  ---------------------
  */

  languageSheet = () => {
    const languageList = [
      {
        icon: this.isLanguage('tr') && <Check width={16} color={this.props.theme.text1} />,
        text: 'TR',
        event: () => this.selectLanguage('tr'),
      },
      {
        icon: this.isLanguage('en') && <Check width={16} color={this.props.theme.text1} />,
        text: 'EN',
        event: () => this.selectLanguage('en'),
      },
    ];
    return (
      <>
        {languageList.map((item) => this.menuItemComponent(item))}
        <CustomDivider />
      </>
    );
  }

  selectLanguage = (id) => {
    localStorage.setItem('language', id);
    // this.setState({ languageSheet: false });
    // this.setState({ companySheet: false });

    window.location.reload();
  }

  isLanguage = (isLanguage) => Boolean(localStorage.getItem('language') === isLanguage)

  /*
  *   ------------------///  languageSheet  ---------------------
  */

  /*
  *   ------------------///  bottomSheet  ---------------------
  */

  handleDarkmode = () => {
    const theme = localStorage.getItem('theme');
    if (theme !== null) {
      if (theme === 'dark') {
        this.props.setAppConfig('theme', 'light');
        localStorage.setItem('theme', 'light');
      } else {
        localStorage.setItem('theme', 'dark');
        this.props.setAppConfig('theme', 'dark');
      }
    } else {
      localStorage.setItem('theme', 'dark');
      this.props.setAppConfig('theme', 'dark');
    }
  }

  handleEventProps = (arg, callback) => {
    if (callback) callback();
    if (!Array.isArray(arg)) {
      const [key, value] = [Object.keys(arg).toString(), ...Object.values(arg)];
      this.props.setAppConfig(key, value);
    } else {
      this.props.setAppConfig(...arg);
    }
  }

  render() {
    const { lang, openSheet } = this.state;
    const { changePassword, company, language } = this.openSheetEnum;
    const { theme } = this.props;
    const menu = [
      {
        type: 0, icon: <ProfilOutlined width={16} color={theme.text1} />, text: tra('Profil'), event: () => this.props.history.push(pageShow.PROFIL),
      },
      // {
      //   type: 0, icon: <Refresh width={14} color={theme.text1} />, text: lang.sirketDegistir, event: () => this.setState({ openSheet: this.openSheetEnum.company }),
      // },
      {
        type: 0, icon: <Earth width={14} color={theme.text1} />, text: lang.dilDegistir, event: () => this.setState({ openSheet: this.openSheetEnum.language }),
      },
      {
        type: 1, icon: <Moon width={14} color={theme.text1} />, text: lang.temaDegistir, event: () => this.handleDarkmode(), value: Boolean(this.props.appConfig.theme !== 'light'),
      },
      {
        type: 0, icon: <Lock width={14} color={theme.text1} />, text: lang.sifreDegistir, event: () => this.setState({ openSheet: this.openSheetEnum.changePassword }),
      },
      {
        type: 0, icon: <Info width={14} color={theme.text1} />, text: lang.hakkinda, event: '',
      },
      {
        type: 0, icon: <Help width={14} color={theme.text1} />, text: lang.yardim, event: '',
      },
    ];
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <List component="nav" aria-label="main mailbox folders">
              {menu.map((item) => (item.type === 0 ? this.menuItemComponent(item) : this.menuItemSwitchComponent(item)))}
              <CustomDivider />
            </List>
            {this.bottomSheet({ children: this.languageSheet(), open: Boolean(openSheet === language) })}
            {this.bottomSheet({ children: this.companySheet(), open: Boolean(openSheet === company) })}
          </div>
        </PageContent>
        <ChangePassword
          open={Boolean(openSheet === changePassword)}
          close={() => this.setState({ openSheet: false })}
        />
      </PageWrapper>
    );
  }
}

ProfilSetting.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired,
  startRequiresServices: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  appConfig: selectAppConfig(),
  activeUser: selectActiveUser(),

});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setAppConfig,
      startRequiresServices,
    }, dispatch)
  )
);

const ProfilSettingWithTheme = withTheme(ProfilSetting);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilSettingWithTheme));

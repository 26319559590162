import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Tooltip, Zoom } from '@material-ui/core';
import trLocale from 'moment/locale/tr';
import { tr } from 'date-fns/esm/locale';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
// import Input from '../components/Input';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import EventIcon from '@material-ui/icons/Event';
import { tra } from '../commons/utils';
import {
  CalendarWrapper,
  DayBox,
  CalendarHead,
  CalendarTop,
  DayEvents,
  DayEventsItem,
  EventDetails,
  AwasomeSwitch,
  AwasomeSwitchItem,
  AwasomeSwitchIndicator,
  WeekView,
  WeekDay,
  YearView,
  MonthGrid, DayCell, CalendarWrapperMobile,
} from '../assets/styledCalendar';
import { CustomDatePicker } from '../assets/styledNew';
import {
  dayList,
  monthList,
  eventColor,
} from '../assets/statics';
import { getEvents } from '../store/actions';
import { selectEvents } from '../store/selectors';
import Touch from '../commons/touch';

class Calendar extends Component {
  constructor(props) {
    moment.updateLocale('tr', trLocale);
    super(props);
    this.state = {
      showDatePicker: false,
      selectedDate: new Date(),
      monthOrder: 0, // 0 geçerli ay +1 sonraki aylar
      weekOrder: 0, // 0 geçerli ay +1 sonraki aylar
      yearOrder: 0,
      calendarType: 1,
      lang: {
        ay: tra('Ay'),
        yil: tra('Yil'),
        hafta: tra('Hafta'),
      },
    };
    // props.selectDay(moment()._d, this.props.events[moment().add(this.state.monthOrder, 'months').format('YYYY')].filter((e) => moment().isSame(e.date, 'date') && e));
    document.removeEventListener('keydown', this._handleKeyDown.bind(this));
    this.props.getEvents({
      minDate: moment().clone().startOf('year').format('YYYY-MM-DD'),
      maxDate: moment().clone().endOf('year').format('YYYY-MM-DD'),
    });
  }

  changeDate = (value) => {
    const { calendarType } = this.state;
    switch (calendarType) {
      case 0:
        this.setState((prevState) => ({
          weekOrder: prevState.weekOrder + value,
          activeYear: moment().add(prevState.weekOrder + value, 'weeks').format('YYYY'),
        }), () => this.getEventYear());
        break;
      case 1:
        this.setState((prevState) => ({ monthOrder: prevState.monthOrder + value, activeYear: moment().add(prevState.monthOrder + value, 'months').format('YYYY') }), () => this.getEventYear());
        break;
      case 2:
        this.setState((prevState) => ({ yearOrder: prevState.yearOrder + value * 12, activeYear: moment().add(prevState.yearOrder + value * 12, 'months').format('YYYY') }), () => this.getEventYear());
        break;
      default:
        break;
    }
  }

  handleSelectDate = (e) => {
    const diffMonth = moment(new Date(e)).diff(moment(), 'months', true);
    this.setState(({ monthOrder: diffMonth, activeYear: moment([new Date(e).getFullYear]).format('YYYY') }), () => this.getEventYear());
    this.setState({ selectedDate: e });
  }

  getEventYear = () => {
    if (!this.props.events[this.state.activeYear]) {
      this.props.getEvents({
        minDate: `${this.state.activeYear}-01-01`,
        maxDate: `${this.state.activeYear}-12-31`,
      });
    }
  }

  _handleKeyDown = (event) => {
    const { calendarType } = this.state;
    let { weekOrder, monthOrder, yearOrder } = this.state;
    switch (event.keyCode) {
      case 37: // LEFT
        switch (calendarType) {
          case 0:
            weekOrder -= 1;
            break;
          case 1:
            monthOrder -= 1;
            break;
          case 2:
            yearOrder -= 12;
            break;
          default:
            break;
        }
        this.setState({ weekOrder, monthOrder, yearOrder });
        break;
      case 39: // Right
        switch (calendarType) {
          case 0:
            weekOrder += 1;
            break;
          case 1:
            monthOrder += 1;
            break;
          case 2:
            yearOrder += 12;
            break;
          default:
            break;
        }
        this.setState({ weekOrder, monthOrder, yearOrder });
        break;
      default:
        break;
    }
  }

  // Test Events
  // genereteEvent = () => {
  //   const data = [];
  //   for (let i = 0; i < 100; i++) {
  //     data.push(
  //       {
  //         date: new Date(Math.round(Math.random() * (new Date(2021, 11, 30).getTime() - new Date(2020, 0, 1).getTime())) + new Date(2021, 0, 1).getTime()),
  //         app: `Test Event ${i}`,
  //         desc: 'Lisans Başlangıc',
  //         statu: eventColor[parseInt(Math.random() * 3, 10)],
  //       },
  //     );
  //   }
  //   data.push({
  //     date: new Date(),
  //     app: 'Microsoft Office 365',
  //     desc: 'Lisans Başlangıc',
  //     statu: eventColor[parseInt(Math.random() * 3, 10)],
  //   });
  //   return data;
  // }

  getDaysArrayByMonth = (month) => {
    let daysInMonth = moment().add(month, 'months').daysInMonth();
    const arrDays = [];
    while (daysInMonth) {
      const current = moment().add(month, 'months').date(daysInMonth);
      arrDays.push(current);
      daysInMonth--;
    }
    return arrDays.reverse();
  }

  getDaysArrayByPrevMonth = (month) => {
    const arrDays = [];
    let startDayOrder = moment().add(month, 'months').startOf('month').day() - 1;
    if (startDayOrder < 0) startDayOrder += 7;
    while (startDayOrder) {
      const current = moment().add(month, 'months').subtract(1, 'months').endOf('month')
        .subtract(startDayOrder - 1, 'day');
      arrDays.push(current);
      startDayOrder--;
    }
    return arrDays;
  }

  getDaysArrayByNextMonth = (month) => {
    const arrDays = [];
    const prevMonthDayCount = moment().add(month, 'months').startOf('month').day() - 1;
    const currentMonthDayCount = moment().add(month, 'months').daysInMonth();
    let kalan = (prevMonthDayCount + currentMonthDayCount) % 7;
    if (kalan) kalan = 7 - kalan;
    while (kalan) {
      const current = moment().add(month + 1, 'months').startOf('month').add(kalan - 1, 'day');
      arrDays.push(current);
      kalan--;
    }
    return arrDays.reverse();
  }
  // app_name: "subat testi"
  // date: "2021-02-02T08:29:00.000Z"
  // id: 27
  // name: "test 2 subat"
  // status: 2

  renderDayBox = (data, isOtherMonth, hiddenDetail) => {
    const { calendarType, yearOrder } = this.state;
    const { events } = this.props;
    const yearEvents = events[moment().add(calendarType === 0 ? this.state.weekOrder : calendarType === 1 ? this.state.monthOrder : calendarType === 2 && yearOrder, calendarType === 0 ? 'Week' : 'months').format('YYYY')];
    return (
      <DayBox
        otherMonth={isOtherMonth}
        today={moment(data).isSame(moment(), 'day')}
        onClick={() => this.props.selectDay(data._d, yearEvents.filter((e) => data.isSame(e.date, 'date') && e))}
      >
        <span className="dayNumber">{data.date()}</span>
        <DayEvents>
          {
            yearEvents && yearEvents.filter((e) => data.isSame(e.date, 'date') && e).slice(0, 3).map((event, i) => (
              <Tooltip title={`${event.name} - ${event.app_name}`} TransitionComponent={Zoom} placement="top">
                <DayEventsItem color={eventColor[event.status]} index={i} />
              </Tooltip>
            ))
          }
        </DayEvents>
        {
          !hiddenDetail && yearEvents && yearEvents.filter((e) => data.isSame(e.date, 'date') && e).length > 0 && (
            <EventDetails statu={eventColor[yearEvents.filter((e) => data.isSame(e.date, 'date') && e)[0].status]}>
              <p>{yearEvents.filter((e) => data.isSame(e.date, 'date') && e)[0].name}</p>
              <span>{yearEvents.filter((e) => data.isSame(e.date, 'date') && e)[0].app_name}</span>
            </EventDetails>
          )
        }
      </DayBox>
    );
  }

  getWeekDays = (weekOrder) => {
    const weekStart = moment().add(weekOrder, 'weeks').startOf('isoWeek');
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days'));
    }
    return days;
  }

  displayDesktop = () => {
    const {
      monthOrder,
      calendarType,
      weekOrder,
      yearOrder,
      lang,
    } = this.state;
    const { events } = this.props;
    const yearEvents = events[moment().add(calendarType === 0 ? this.state.weekOrder : calendarType === 1 ? this.state.monthOrder : calendarType === 2 && yearOrder, calendarType === 0 ? 'Week' : 'months').format('YYYY')];
    return (
      <CalendarWrapper onKeyDown={this._handleKeyDown} tabIndex="0">
        <CalendarHead>
          <div className="date">
            <ChevronLeft onClick={() => this.changeDate(-1)} />
            {
              calendarType === 0 ? (
                <span>
                  {tra(moment().add(weekOrder, 'weeks').startOf('isoWeek').format('MMMM')) !== tra(moment().add(weekOrder, 'weeks').endOf('isoWeek').format('MMMM')) && tra(moment().add(weekOrder, 'weeks').startOf('isoWeek').format('MMMM'))} {`${moment().add(weekOrder, 'weeks').startOf('isoWeek').date()} - ${moment().add(weekOrder, 'weeks').endOf('isoWeek').date()}`} {tra(moment().add(weekOrder, 'weeks').endOf('isoWeek').format('MMMM'))} {tra(moment().add(weekOrder, 'weeks').format('YYYY'))}
                </span>
              ) : calendarType === 1 ? (
                <span>{tra(moment().add(monthOrder, 'months').format('MMMM'))} {moment().add(monthOrder, 'months').format('YYYY')}</span>
              ) : calendarType === 2 && (
                <span>{moment().add(yearOrder, 'months').format('YYYY')}</span>
              )
            }
            <ChevronRight onClick={() => this.changeDate(1)} />
          </div>
          <AwasomeSwitch>
            <AwasomeSwitchItem onClick={() => this.setState({ calendarType: 0 })}>{lang.hafta}</AwasomeSwitchItem>
            <AwasomeSwitchItem onClick={() => this.setState({ calendarType: 1 })}>{lang.ay}</AwasomeSwitchItem>
            <AwasomeSwitchItem onClick={() => this.setState({ calendarType: 2 })}>{lang.yil}</AwasomeSwitchItem>
            <AwasomeSwitchIndicator total={3} active={calendarType} />
          </AwasomeSwitch>
        </CalendarHead>
        {
          calendarType === 0 ? (
            <>
              <WeekView>
                {
                  this.getWeekDays(weekOrder).map((wDay, i) => (
                    <WeekDay
                      today={moment(wDay).isSame(moment(), 'day')}
                      onClick={() => this.props.selectDay(wDay._d, yearEvents.filter((e) => wDay.isSame(e.date, 'date') && e))}
                    >
                      <span>{dayList[i].text}</span>
                      <div className="weekEvent">
                        <span>{wDay.date()}</span>
                        <div className="weekScroller">
                          {
                            yearEvents && yearEvents.filter((e) => wDay.isSame(e.date, 'date') && e) && yearEvents.filter((e) => wDay.isSame(e.date, 'date') && e).map((event) => (
                              <EventDetails statu={eventColor[event.status]}>
                                <p>{event.app_name}</p>
                                <span>{event.name}</span>
                              </EventDetails>
                            ))
                          }
                        </div>
                      </div>
                    </WeekDay>
                  ))
                }
              </WeekView>
            </>
          ) : calendarType === 1 ? (
            <>
              <CalendarTop>
                {
                  dayList.map((dayStr) => (
                    <DayBox><p>{dayStr.text}</p></DayBox>
                  ))
                }
              </CalendarTop>
              {this.getDaysArrayByPrevMonth(monthOrder).map((box) => this.renderDayBox(box, true))}
              {this.getDaysArrayByMonth(monthOrder).map((box) => this.renderDayBox(box))}
              {this.getDaysArrayByNextMonth(monthOrder).map((box) => this.renderDayBox(box, true))}
            </>
          ) : calendarType === 2 && (
            <YearView>
              {
                monthList.map((m) => (
                  <div className="month">
                    <span>{m.text}</span>
                    <CalendarTop>
                      {
                        dayList.map((dayStr) => (
                          <DayBox><p>{dayStr.sort}</p></DayBox>
                        ))
                      }
                    </CalendarTop>
                    {this.getDaysArrayByPrevMonth(yearOrder + m.id - new Date().getMonth()).map((box) => this.renderDayBox(box, true, true))}
                    {this.getDaysArrayByMonth(yearOrder + m.id - new Date().getMonth()).map((box) => this.renderDayBox(box, false, true))}
                    {this.getDaysArrayByNextMonth(yearOrder + m.id - new Date().getMonth()).map((box) => this.renderDayBox(box, true, true))}
                  </div>
                ))
              }
            </YearView>
          )
        }
      </CalendarWrapper>
    );
  }

  displayMobile = () => {
    const {
      monthOrder,
      calendarType,
      weekOrder,
      yearOrder,
      // lang,
    } = this.state;
    // const { events } = this.props;
    // const yearEvents = events[moment().add(calendarType === 0 ? this.state.weekOrder : calendarType === 1 ? this.state.monthOrder : calendarType === 2 && yearOrder, calendarType === 0 ? 'Week' : 'months').format('YYYY')];
    return (
      <CalendarWrapperMobile onKeyDown={this._handleKeyDown} tabIndex="0">
        <div className="calendarHeader">
          <span className="monthTitle">
            <ChevronLeft onClick={() => this.changeDate(-1)} />
            {
              calendarType === 0 ? (
                <span>
                  {`${moment().add(weekOrder, 'weeks').startOf('isoWeek').date()} - ${moment().add(weekOrder, 'weeks').endOf('isoWeek').date()}`} {tra(moment().add(weekOrder, 'weeks').format('MMMM'))}
                </span>
              ) : calendarType === 1 ? (
                <span>{tra(moment().add(monthOrder, 'months').format('MMMM'))} {moment().add(monthOrder, 'months').format('YYYY')}</span>
              ) : calendarType === 2 && (
                <span>{moment().add(yearOrder, 'months').format('YYYY')}</span>
              )
            }
            <ChevronRight onClick={() => this.changeDate(1)} />
          </span>
          <IconButton className="datePickerButton">
            <EventIcon onClick={() => this.setState({ showDatePicker: true })} />
          </IconButton>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr} style={{ margin: 0, display: 'none' }}>
            <CustomDatePicker
              open={this.state.showDatePicker}
              onOpen={() => this.setState({ showDatePicker: true })}
              onClose={() => this.setState({ showDatePicker: false })}
              showTodayButton
              value={this.state.selectedDate}
              views={['year', 'month']}
              onChange={(e) => this.handleSelectDate(e)}
              animateYearScrolling
              okLabel={tra('Tamam')}
              cancelLabel={tra('Cancel')}
              todayLabel={tra('Bugun')}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Touch
          // swipeTop={() => console.table('top')}
          // swipeBottom={() => console.table('bottom')}
          swipeLeft={() => this.changeDate(-1)}
          swipeRight={() => this.changeDate(1)}
        >
          <MonthGrid>
            {dayList.map((item) => <p className="weeks">{item.sort}</p>)}
            {this.getDaysArrayByPrevMonth(monthOrder).map((box) => this.renderDayBoxMobile(box, true))}
            {this.getDaysArrayByMonth(monthOrder).map((box) => this.renderDayBoxMobile(box))}
            {this.getDaysArrayByNextMonth(monthOrder).map((box) => this.renderDayBoxMobile(box, true))}
          </MonthGrid>
        </Touch>
      </CalendarWrapperMobile>
    );
  }

  renderDayBoxMobile = (data, isOtherMonth, hiddenDetail) => {
    const { calendarType, yearOrder } = this.state;

    const { events } = this.props;
    const yearEvents = events[moment().add(calendarType === 0 ? this.state.weekOrder : calendarType === 1 ? this.state.monthOrder : calendarType === 2 && yearOrder, calendarType === 0 ? 'Week' : 'months').format('YYYY')];
    return (
      <DayCell
        otherMonth={isOtherMonth}
        today={moment(data).isSame(moment(), 'day')}
        onClick={() => this.props.selectDay(data._d, yearEvents.filter((e) => data.isSame(e.date, 'date') && e))}
      >
        <span className="dayNumber">{data.date()}</span>
        {!hiddenDetail && yearEvents && yearEvents.filter((e) => data.isSame(e.date, 'date') && e).length > 0 && (<p className="dayEventDetailDotted">{yearEvents.filter((e) => data.isSame(e.date, 'date') && e).length}</p>)}
      </DayCell>
    );
  }

  render() {
    return isWidthDown('sm', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

Calendar.propTypes = {
  selectDay: PropTypes.func,
  getEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
};

Calendar.defaultProps = {
  selectDay: () => { },
};

const mapStateToProps = createStructuredSelector({
  events: selectEvents(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getEvents,
    }, dispatch)
  )
);

const CalendarWithWidth = withWidth()(Calendar);

export default connect(mapStateToProps, mapDispatchToProps)(CalendarWithWidth);

/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
  createMuiTheme,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  Tooltip,
  // Button,
  // Select,
  // ListItemText,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { tr, enUS } from 'date-fns/esm/locale';
import { withTheme } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import {
  CustomFormControl, CustomFileText, CustomMenuItem, CustomIconTextField,
} from '../assets/styledInput';
import { Colors } from '../assets/statics';
import { CustomButton } from '../assets/styledNew';
import { Plus, Minus } from '../assets/icons';
import { tra } from '../commons/utils';
// import { Search } from '@material-ui/icons';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.blue,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.blue,
      },
      daySelected: {
        backgroundColor: Colors.blue,
      },
      dayDisabled: {
        color: 'rgba(0, 0, 0, 0.5)',
      },
      current: {
        color: '#996e00',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        // color: lightBlue["400"],
      },
    },
  },
});

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        yukle: tra('Yukle'),
        lutfenBirDosyaSeciniz: tra('Lutfen_Bir_Dosya_Seciniz'),
      },
    };
    this.hiddenInput = React.createRef();
  }

  render() {
    const {
      type, value, title, onChange, error, row, disabled, variant, labelPlacement, limitCount, info, format, onlyYear,
      data, labelKey, valueKey, helperText, placeholder, endAdornment, startAdornment, freeSolo, groupByKey, onInputChange,
      maxDate, minDate, suffix, name, onKeyDown, onFocus, acceptedFiles, onValueChange, emptyItem, defaultVal, dataNull, optionKey, secondLabelKey,
      minusClick, plusClick, multiple, defaultChecked, telCode, onChangeTelCode, allowNegative, indicate, maxLength, onBlur, telCodeDisabled, views, decimalScale, closeIcon, getOptionLabel,
    } = this.props;
    const { lang } = this.state;
    switch (type) {
      case 'tel':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <div style={{
              display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center',
            }}
            >
              <Autocomplete
                value={telCode || null}
                autoHighlight
                error={error}
                getOptionSelected={(
                  option,
                  val,
                ) => val.id === option.id}
                style={{ maxWidth: 150, marginRight: 10 }}
                options={data || []}
                freeSolo={freeSolo}
                defaultValue={defaultVal}
                getOptionLabel={(option) => (option !== undefined ? `${option.iso3} (${option.dial_code})` : '')}
                getOptionDisabled={(opt) => opt.isRemoved === 1}
                onChange={onChangeTelCode}
                closeIcon={false}
                disabled={telCodeDisabled || disabled}
                InputProps={{
                  endAdornment,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder}
                    fullWidth
                    error={error}
                    helperText={error && <p style={{ whiteSpace: 'noWrap' }}>{`${helperText}`}</p>}
                    disabled={disabled}
                    variant={variant || 'outlined'}
                  />
                )}
              />
              <NumberFormat
                value={value}
                onChange={onChange}
                onValueChange={onValueChange}
                format="(###) ###-##-##"
                customInput={TextField}
                fullWidth
                placeholder={placeholder}
                mask="_"
                suffix={` ${suffix}`}
                disabled={disabled}
                error={error}
                helperText={error}
                variant={variant || 'outlined'}
                inputProps={{
                  maxLength,
                }}
                InputProps={{
                  endAdornment,
                  startAdornment,
                }}
              />
            </div>
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'text':
        return (
          <CustomFormControl info={info} row={row > 0} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <TextField
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              fullWidth
              onChange={onChange}
              onKeyDown={onKeyDown}
              error={error}
              {...(defaultVal !== false && { defaultVal })}
              helperText={helperText}
              rows={row > 0 && row}
              multiline={row > 0 && true}
              disabled={disabled}
              variant={variant || 'outlined'}
              inputProps={{
                maxLength,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                endAdornment,
                startAdornment,
              }}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'number':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <NumberFormat
              className="numberPlusCons"
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              onValueChange={onValueChange}
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={decimalScale}
              allowNegative={allowNegative}
              customInput={TextField}
              fullWidth
              placeholder={placeholder}
              suffix={`${suffix}`}
              disabled={disabled}
              error={error}
              helperText={helperText}
              variant={variant || 'outlined'}
              inputProps={{
                maxLength,
              }}
              InputProps={{
                endAdornment: endAdornment || (
                  <IconButton disabled={disabled} onMouseDown={plusClick} style={{ padding: 'unset' }}>
                    <Plus color={this.props.theme.blueText} width={14} />
                  </IconButton>
                ),
                startAdornment: startAdornment || (
                  <IconButton disabled={disabled} onMouseDown={minusClick} style={{ padding: 'unset' }}>
                    <Minus color={this.props.theme.blueText} width={14} />
                  </IconButton>
                ),
              }}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'password':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <TextField
              onBlur={onBlur}
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              fullWidth
              onChange={onChange}
              error={error}
              helperText={helperText}
              rows={row > 0 && row}
              multiline={row > 0 && true}
              disabled={disabled}
              variant={variant || 'outlined'}
              inputProps={{
                maxLength,
              }}
              InputProps={{
                endAdornment,
                startAdornment,
              }}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );

      case 'currency':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <NumberFormat
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onValueChange={onValueChange}
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={decimalScale}
              allowNegative={false}
              customInput={TextField}
              fullWidth
              placeholder={placeholder}
              suffix={`${suffix}`}
              disabled={disabled}
              error={error}
              helperText={helperText}
              variant={variant || 'outlined'}
              inputProps={{
                maxLength,
              }}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'select':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <CustomIconTextField
              select
              onBlur={onBlur}
              name={name}
              value={(data.length > 0 && data.find((item) => (valueKey !== '' ? (item[valueKey] === value) : (item.id === value)))) ? (value !== 0 ? (value || '') : 0) : ''}
              onChange={onChange}
              fullWidth
              disabled={disabled}
              variant={variant || 'outlined'}
              defaultValue={0}
              error={error}
              helperText={helperText}
            >
              {placeholder && <MenuItem disabled value={placeholder}>{placeholder}</MenuItem>}
              {emptyItem && <MenuItem value="">{tra('Secilmedi_Mesaji')}</MenuItem>}
              {
                data && data.length > 0 && (
                  data.map((item) => (
                    <CustomMenuItem
                      key={item.id}
                      active={(item.id === value) && item.icon}
                      value={valueKey !== '' ? item[valueKey] : item.id}
                      disabled={item.isRemoved === 1}
                    >
                      {item.icon && item.icon} {labelKey !== '' ? item[labelKey] : tra(item.name)}
                    </CustomMenuItem>
                  ))
                )
              }
            </CustomIconTextField>
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'date':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('language') === 'en' ? enUS : tr} style={{ margin: 0 }}>
                <KeyboardDatePicker
                  autoOk
                  views={views || (onlyYear && ['year'])}
                  okLabel={tra('Tamam')}
                  cancelLabel={tra('İptal')}
                  renderDay={this.renderDay}
                  id="date-picker-dialog"
                  inputVariant={variant || 'outlined'}
                  format={onlyYear ? 'yyyy' : 'dd/MM/yyyy'}
                  fullWidth
                  value={value}
                  error={error}
                  onChange={onChange}
                  disabled={disabled}
                  maxDate={maxDate}
                  minDate={minDate}
                  helperText={helperText}
                  inputProps={{
                    maxLength,
                  }}
                  InputProps={{
                    endAdornment,
                    startAdornment,
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'dateAndTime':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('language') === 'en' ? enUS : tr} style={{ margin: 0 }}>
                <DateTimePicker
                  autoOk
                  okLabel={tra('Tamam')}
                  cancelLabel={tra('İptal')}
                  renderDay={this.renderDay}
                  id="date-picker-dialog"
                  inputVariant={variant || 'outlined'}
                  format={format}
                  fullWidth
                  value={value}
                  error={error}
                  onChange={onChange}
                  disabled={disabled}
                  maxDate={maxDate}
                  minDate={minDate}
                  helperText={helperText}
                  inputProps={{
                    maxLength,
                  }}
                  InputProps={{
                    endAdornment,
                    startAdornment,
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'switch':
        return (
          <CustomFormControl info={info} noSpace fullWidth variant="outlined">
            <InputLabel shrink id="demo-simple-select-label">{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <FormControlLabel
              label={placeholder}
              labelPlacement={labelPlacement || 'start'}
              control={(
                <Switch
                  checked={value}
                  onChange={onChange}
                  name="checkedB"
                  color="primary"
                  disabled={disabled}
                />
              )}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'autoComplete':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink id="demo-simple-select-label">{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <Autocomplete
              value={data.length > 0 ? ((value === null || value === undefined || value === '') ? [] : value) : []}
              getOptionSelected={(
                option,
                val,
              ) => val.id === option.id}
              options={data || []}
              freeSolo={freeSolo}
              defaultValue={defaultVal}
              getOptionLabel={(option) => (getOptionLabel ? getOptionLabel?.(option) : (option?.[labelKey || 'name'] ?? ''))}
              getOptionDisabled={(opt) => opt.isRemoved === 1}
              onChange={onChange}
              closeIcon={closeIcon && <CloseIcon fontSize="small" />}
              multiple={multiple}
              disabled={disabled}
              InputProps={{
                endAdornment,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  fullWidth
                  error={error}
                  helperText={helperText}
                  disabled={disabled}
                  onBlur={onBlur}
                  variant={variant || 'outlined'}
                />
              )}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'autoCompleteSearch':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink id="demo-simple-select-label">{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <Autocomplete
              value={value}
              getOptionSelected={(
                option,
                val,
              ) => val[optionKey || 'id'] === option[optionKey || 'id']}
              options={data || []}
              getOptionLabel={(option) => (option !== undefined ? option[labelKey || 'name'] : '')}
              getOptionDisabled={(opt) => opt.isRemoved === 1}
              onChange={onChange}
              closeIcon={false}
              disabled={disabled}
              InputProps={{
                endAdornment,
              }}
              onInputChange={onInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  fullWidth
                  error={error}
                  disabled={disabled}
                  variant={variant || 'outlined'}
                />
              )}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'autoCompleteFree':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink id="demo-simple-select-label">{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <Autocomplete
              value={dataNull ? value : (data.length > 0 ? ((value === null || value === undefined) ? [] : value) : data)}
              getOptionSelected={(
                option,
                val,
              ) => val === option}
              options={data}
              freeSolo={freeSolo}
              defaultValue={defaultVal}
              getOptionLabel={(option) => (option !== undefined ? option : '')}
              onChange={onChange}
              closeIcon={false}
              multiple={multiple}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  fullWidth
                  error={error}
                  disabled={disabled}
                  variant={variant || 'outlined'}
                />
              )}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'autoCompleteCreatable':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink id="demo-simple-select-label">{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <Autocomplete
              value={value}
              onChange={onChange}
              disabled={disabled}
              filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option[labelKey]);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    [labelKey]: `${tra('Ekle')} "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={data}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option[labelKey];
              }}
              renderOption={(props) => <li {...props}>{props[labelKey]}{secondLabelKey ? `(${props[secondLabelKey]})` : ''}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  fullWidth
                  error={error}
                  disabled={disabled}
                  variant={variant || 'outlined'}
                />
              )}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'autoCompleteGrouped':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink id="demo-simple-select-label">{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <Autocomplete
              filterOptions={createFilterOptions({ matchFrom: 'any', limit: limitCount })}
              value={value}
              options={data || []}
              closeIcon={false}
              onChange={onChange}
              disabled={disabled}
              groupBy={(option) => option[groupByKey]}
              getOptionLabel={(option) => (option ? `${option.title}, ${option[groupByKey]}` : '')}
              renderOption={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  fullWidth
                  error={error}
                  disabled={disabled}
                  variant={variant || 'outlined'}
                />
              )}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'checkbox':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.props.value}
                  defaultChecked={defaultChecked}
                  onChange={(e) => this.props.onChange(e)}
                  name="checkedB"
                  color="primary"
                  disabled={disabled}
                />
              )}
              label={`${this.props.title}${indicate ? <span className="indicate"> *</span> : ''}`}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      case 'radioButtonGroup':
        return (
          <div style={{ width: '100%', display: 'block' }}>
            <RadioGroup value={this.props.value} onChange={(e) => this.props.onChange(e)}>
              {data.map((choise) => (
                <FormControlLabel
                  control={(
                    <Radio />
                  )}
                  value={choise.id}
                  label={choise.text}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          </div>
        );
      case 'upload':
        return (
          <CustomFormControl info={info} fullWidth variant="outlined">
            <InputLabel shrink>{title}{indicate && <span className="indicate"> *</span>}</InputLabel>
            <CustomFileText
              onBlur={onBlur}
              name={name}
              value={value}
              placeholder={lang.lutfenBirDosyaSeciniz}
              type={type}
              fullWidth
              error={error}
              helperText={helperText}
              disabled
              variant={variant || 'outlined'}
              inputProps={{
                maxLength,
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <CustomButton disabled={disabled} onClick={() => this.hiddenInput.current.click()} style={{ color: '#fff' }}>{lang.yukle}</CustomButton>
                    <input onClick={(e) => { e.target.value = ''; }} disabled={disabled} accept={acceptedFiles} onChange={onChange} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
                  </>
                ),
              }}
            />
            <div className="info">
              <Tooltip title={info || ''}>
                <Info />
              </Tooltip>
            </div>
          </CustomFormControl>
        );
      default:
        return (
          <p>Hatalı Tip ({type})</p>
        );
    }
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.string.isRequired,
  defaultVal: PropTypes.any,
  onValueChange: PropTypes.func,
  emptyItem: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  data: PropTypes.array,
  variant: PropTypes.string,
  row: PropTypes.number,
  disabled: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  suffix: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  minusClick: PropTypes.func,
  plusClick: PropTypes.func,
  labelPlacement: PropTypes.string,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  onChangeTelCode: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  telCode: PropTypes.any.isRequired,
  allowNegative: PropTypes.number,
  indicate: PropTypes.bool,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  views: PropTypes.array,
  decimalScale: PropTypes.number,
  closeIcon: PropTypes.any,
  getOptionLabel: PropTypes.func,
  format: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => { },
  onKeyDown: () => { },
  onFocus: () => { },
  onBlur: () => { },
  onValueChange: () => { },
  minusClick: () => { },
  plusClick: () => { },
  emptyItem: false,
  title: '',
  name: '',
  error: false,
  suffix: '',
  variant: 'outlined',
  helperText: '',
  data: [],
  row: 0,
  disabled: false,
  labelKey: '',
  valueKey: '',
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01'),
  placeholder: '',
  startAdornment: null,
  endAdornment: null,
  labelPlacement: '',
  freeSolo: false,
  multiple: true,
  allowNegative: false,
  indicate: false,
  maxLength: false,
  views: ['year', 'month', 'date'],
  decimalScale: 2,
  defaultVal: false,
  closeIcon: false,
  getOptionLabel: false,
  format: 'dd/MM/yyyy',
};

const InputWithTheme = withTheme(Input);
export default InputWithTheme;

/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { calculateTimeValue, tra } from '../utils';

export const InventoryContext = React.createContext({});

const InventoryProvider = (props) => {
  const [InventoryState, setInventoryState] = useState({
    name: '',
    desc: '',
    version: '',
    platform_id: null,
    location_id: null,
    country_id: null,
    budget_owner: null,
    managed_by: null,
    vendor_id: null,
    SysOwners: [],
    TechnicOwners: [],
    BusinessOwners: [],
    Tags: [],
    Processes: [],
    leg_id: null,
    business_value: 0,
    business_critically: 0,
    cost_fitness: 0,
    it_quality: 0,
    time_value: 0,
    scope_id: null,
    func_id: null,
    cat_id: null,
    AppFiles: [],
    AppUrls: [],
    follow: false,
    id: '',
    isRemoved: '',
    remove_desc: null,
    statu: '',
    user_id: '',
    total_cost: 0,
    user_base: '',
    PreApps: [],
    SucApps: [],
    AppPlans: [],
  });

  const [InventoryPageState, setInventoryPageState] = useState({
    businessValueHover: 0,
    itQualityValueHover: 0,
    businessCriticallyHover: 0,
    costFitnessHover: 0,
    openModalInventoryDetail: false,
    sendSysOwners: [],
    sendTechnicOwners: [],
    sendProcesses: [],
    sendBusinessOwners: [],
    sendPreApps: [],
    sendSucApps: [],
    controlModal: false,
    statusModal: false,
    deleteModal: false,
    tabValue: 0,
    costTabValue: 2,
    logDrawer: false,
  });

  const resetState = () => {
    setInventoryState({
      name: '',
      desc: '',
      version: '',
      platform_id: null,
      location_id: null,
      country_id: null,
      budget_owner: null,
      managed_by: null,
      vendor_id: null,
      SysOwners: [],
      TechnicOwners: [],
      BusinessOwners: [],
      Tags: [],
      Processes: [],
      leg_id: null,
      business_value: 0,
      it_quality: 0,
      business_critically: 0,
      cost_fitness: 0,
      time_value: 0,
      scope_id: null,
      func_id: null,
      cat_id: null,
      AppFiles: [],
      AppUrls: [],
      follow: false,
      id: '',
      isRemoved: '',
      remove_desc: null,
      statu: '',
      user_id: '',
      total_cost: 0,
      user_base: '',
      PreApps: [],
      SucApps: [],
      AppPlans: [],
    });
    setInventoryPageState({
      businessValueHover: 0,
      itQualityValueHover: 0,
      businessCriticallyHover: 0,
      costFitnessHover: 0,
      openModalInventoryDetail: false,
      sendSysOwners: [],
      sendTechnicOwners: [],
      sendProcesses: [],
      sendBusinessOwners: [],
      sendPreApps: [],
      sendSucApps: [],
      controlModal: false,
      statusModal: false,
      deleteModal: false,
      tabValue: 0,
      costTabValue: 2,
      logDrawer: false,
    });
  };

  const RatingArr = [
    {
      id: 1,
      title: tra('Technical_Fitness'),
      valueKey: 'it_quality',
      hoverKey: 'itQualityValueHover',
      color: '#2144D4',
    },
    {
      id: 2,
      title: tra('Business_Fitness'),
      valueKey: 'business_value',
      hoverKey: 'businessValueHover',
      color: '#FFA900',
    },
    {
      id: 3,
      title: tra('Cost_Fitness'),
      valueKey: 'cost_fitness',
      hoverKey: 'costFitnessHover',
      color: '#FF5242',
    },
    {
      id: 4,
      title: tra('Business_Criticality'),
      valueKey: 'business_critically',
      hoverKey: 'businessCriticallyHover',
      color: '#2144D4',
    },
  ];

  const onChangeTags = (e, newValue, state) => {
    if (newValue !== undefined && newValue !== '' && newValue !== null) {
      setInventoryState((prevState) => ({
        ...prevState,
        [state]: newValue,
      }));
    }
  };

  const onChangeTab = (e, newValue, text) => {
    setInventoryPageState((prevState) => ({
      ...prevState,
      [text]: newValue,
    }));
  };

  const onChangeAutoComplete = (e, newValue, state, reason) => {
    const sendArr = `send${state}`;
    let tempArr = [];
    if (reason === 'clear') {
      setInventoryState((prevState) => {
        const newData = { ...prevState };
        delete newData[state];
        return newData;
      });
      setInventoryPageState((prevState) => {
        const newData = { ...prevState };
        delete newData[sendArr];
        return newData;
      });
    }

    if (newValue !== undefined && newValue !== '' && newValue !== null) {
      if (newValue.length > 0 && Array.isArray(newValue)) {
        for (let i = 0; i < newValue.length; ++i) {
          const obj = { id: newValue[i].id };
          tempArr.push(obj);
        }
      } else {
        tempArr = { id: newValue?.id };
      }
      setInventoryState((prevState) => ({
        ...prevState,
        [state]: newValue,
      }));
      setInventoryPageState((prevState) => ({
        ...prevState,
        [sendArr]: tempArr,
      }));
    }
  };

  const handleChange = (e, state) => {
    const val = e.target.value;
    setInventoryState((prevState) => ({
      ...prevState,
      [state]: val,
    }));
  };

  const getTimeValue = () => {
    setInventoryState((prevState) => ({
      ...prevState,
      time_value: calculateTimeValue(prevState.it_quality, prevState.business_value).value,
    }));
  };

  const handleStarChange = (newValue, state) => {
    setInventoryState((prevState) => ({
      ...prevState,
      [state]: newValue,
    }));
    getTimeValue();
  };

  const addAppPlan = () => {
    setInventoryState((prevState) => {
      const newObj = {
        data_plan_id: '',
        plan_id: '',
        year: new Date(),
        desc: '',
      };
      const tempAppPlans = prevState.AppPlans;
      tempAppPlans.push(newObj);
      return {
        ...prevState,
        AppPlans: tempAppPlans,
      };
    });
  };

  const deleteAppPlan = (index) => {
    setInventoryState((prevState) => {
      const tempAppPlans = prevState.AppPlans;
      tempAppPlans.splice(index, 1);
      return {
        ...prevState,
        AppPlans: tempAppPlans,
      };
    });
  };

  const handleChangeAppPlanData = (e, state, index) => {
    let val = '';
    if (state === 'year') {
      val = e;
    } else {
      val = e.target.value;
    }
    setInventoryState((prevState) => {
      const tempAppPlans = prevState.AppPlans;
      tempAppPlans[index][state] = val;
      return {
        ...prevState,
        AppPlans: tempAppPlans,
      };
    });
  };

  const value = {
    InventoryState,
    InventoryPageState,
    setInventoryState,
    setInventoryPageState,
    onChangeTab,
    resetState,
    handleChange,
    handleStarChange,
    onChangeAutoComplete,
    onChangeTags,
    addAppPlan,
    deleteAppPlan,
    handleChangeAppPlanData,
    RatingArr,
  };

  return (
    <InventoryContext.Provider value={value}>
      {props.children}
    </InventoryContext.Provider>

  );
};

InventoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InventoryProvider;

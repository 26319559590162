import React from 'react';
import PropTypes from 'prop-types';

export default function Touch(props) {
  const [touchStartVertical, setTouchStartVertical] = React.useState(0);
  const [touchEndVertical, setTouchEndVertical] = React.useState(0);
  const [touchStartHorizontal, setTouchStartHorizontal] = React.useState(0);
  const [touchEndHorizontal, setTouchEndHorizontal] = React.useState(0);
  const [swipe, setSwipe] = React.useState(false);
  const {
    swipeLeft, swipeRight, swipeBottom, swipeTop,
  } = props;
  function handleTouchStart(e) {
    setTouchStartHorizontal(e.targetTouches[0].clientX);
    setTouchStartVertical(e.targetTouches[0].clientY);
  }

  function handleTouchMove(e) {
    setSwipe(true);
    setTouchEndHorizontal(e.targetTouches[0].clientX);
    setTouchEndVertical(e.targetTouches[0].clientY);
  }

  function handleTouchEnd() {
    const horizontal = swipe ? touchStartHorizontal - touchEndHorizontal : 0;
    const vertical = swipe ? touchStartVertical - touchEndVertical : 0;
    setSwipe(false);
    if (horizontal > 100) swipeRight();
    else if (vertical > 100) swipeBottom();
    else if (horizontal < -100) swipeLeft();
    else if (vertical < -100) swipeTop();
  }
  return (
    <div
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={() => handleTouchEnd()}
    >
      {props.children}
    </div>
  );
}

Touch.propTypes = {
  swipeRight: PropTypes.func,
  swipeLeft: PropTypes.func,
  swipeTop: PropTypes.func,
  swipeBottom: PropTypes.func,
  children: PropTypes.node.isRequired,
};
Touch.defaultProps = {
  swipeRight: () => { },
  swipeLeft: () => { },
  swipeBottom: () => { },
  swipeTop: () => { },
};

export function DragToScroll(props) {
  const [dragScroll, setDragScroll] = React.useState(false);
  const [horizontal, setHorizontal] = React.useState(0);
  const [clientHorizontal, setClientHorizontal] = React.useState(0);
  const scrollDiv = React.useRef(null);

  function mouseDownHandler(e) {
    setHorizontal(scrollDiv.current.scrollLeft);
    setClientHorizontal(e.clientX);
    setDragScroll(true);
  }

  function mouseMoveHandler(e) {
    if (dragScroll) {
      scrollDiv.current.scrollLeft = horizontal - (e.clientX - clientHorizontal);
    }
  }

  const style = {
    userSelect: 'none',
    cursor: window.innerWidth < 960 ? (dragScroll ? 'grabbing' : 'grab') : 'auto',
  };

  return React.cloneElement(
    props.children,
    {
      ref: scrollDiv,
      onMouseDown: (e) => mouseDownHandler(e),
      onMouseMove: (e) => mouseMoveHandler(e),
      onMouseUp: () => setDragScroll(false),
      onMouseLeave: () => setDragScroll(false),
      style,
    },
  );
}

DragToScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

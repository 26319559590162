import React from 'react';
import { Link } from 'react-router-dom';
import { CustomButton, NotFoundWrapper } from '../assets/styledNew';
import { tra } from '../commons/utils';
import { NotFoundSvg } from '../assets/icons';

const NotFound = () => (
  <NotFoundWrapper>
    <NotFoundSvg />
    <h1>{tra('Sayfa_Bulunamadi_Mesaji')}</h1>
    <p>{tra('Hizmet_Mesaji')}</p>
    <Link to="/">
      <CustomButton>{tra('Ana_Sayfaya_Don')}</CustomButton>
    </Link>
  </NotFoundWrapper>
);

export default NotFound;

import { createSelector } from 'reselect';

const domain = (state) => state;

export const selectResponseState = (name) => createSelector(
  domain,
  (substate) => substate.responseState?.[name] || 'IDLE',
);

export const selectToast = () => createSelector(
  domain,
  (substate) => substate.toast,
);

export const selectLoginState = () => createSelector(
  domain,
  (substate) => substate.loginState,
);

export const selectInfos = (state) => createSelector(
  domain,
  (substate) => (state === 'All' ? substate.infos : substate.infos[state || 'def']),
);

export const selectUsers = () => createSelector(
  domain,
  (substate) => substate.users,
);

export const selectApplications = () => createSelector(
  domain,
  (substate) => substate.applications,
);

export const selectApplicationLog = () => createSelector(
  domain,
  (substate) => substate.applicationLog,
);

export const selectComments = () => createSelector(
  domain,
  (substate) => substate.comments,
);

export const selectActiveUser = () => createSelector(
  domain,
  (substate) => substate.activeUser,
);

export const selectProfileData = () => createSelector(
  domain,
  (substate) => substate.profileData,
);

export const selectResetState = () => createSelector(
  domain,
  (substate) => substate.resetState,
);

export const selectApplicationDetail = () => createSelector(
  domain,
  (substate) => substate.application,
);

export const selectDashboard = () => createSelector(
  domain,
  (substate) => substate.dashboardData,
);

export const selectCostGraph = () => createSelector(
  domain,
  (substate) => substate.costGraph,
);

export const selectAdditionalCost = () => createSelector(
  domain,
  (substate) => substate.additionalCost,
);

export const selectVendorProcessMatrix = () => createSelector(
  domain,
  (substate) => substate.vendorProcessMatrix,
);

export const selectAppConfig = () => createSelector(
  domain,
  (substate) => substate.appConfig,
);

export const selectCalendar = () => createSelector(
  domain,
  (substate) => substate.calendar,
);

export const selectFilterDrawer = () => createSelector(
  domain,
  (substate) => substate.filterDrawer,
);

export const selectCreatedEvent = () => createSelector(
  domain,
  (substate) => substate.createdEvent,
);

export const selectEvents = () => createSelector(
  domain,
  (substate) => substate.events,
);

export const selectSurveyAssignData = () => createSelector(
  domain,
  (substate) => substate.surveyAssignData,
);

export const selectSurveyUsers = () => createSelector(
  domain,
  (substate) => substate.surveyUsers,
);

export const selectSurveyAnswerBookmark = () => createSelector(
  domain,
  (substate) => substate.surveyAnswerBookmark,
);

export const selectNotifications = () => createSelector(
  domain,
  (substate) => substate.notifications,
);

export const selectSocket = () => createSelector(
  domain,
  (substate) => substate.socket,
);

export const selectSurveyList = () => createSelector(
  domain,
  (substate) => substate.surveyList,
);

export const selectSurveyDetail = () => createSelector(
  domain,
  (substate) => substate.surveyDetail,
);

export const selectSingleSurveyDetail = () => createSelector(
  domain,
  (substate) => substate.singleSurveyDetail,
);

export const selectAssignedSurveys = () => createSelector(
  domain,
  (substate) => substate.assignedSurveys,
);

export const selectSurveyQuestions = () => createSelector(
  domain,
  (substate) => substate.surveyQuestions,
);

export const selectSingleSurvey = () => createSelector(
  domain,
  (substate) => substate.singleSurvey,
);

export const selectLoading = () => createSelector(
  domain,
  (substate) => substate.loading,
);
export const selectInitLoading = () => createSelector(
  domain,
  (substate) => substate.initLoading,
);

export const selectAddCompanyModal = () => createSelector(
  domain,
  (substate) => substate.addCompanyModal,
);

export const selectTimeModel = () => createSelector(
  domain,
  (substate) => substate.timeModel,
);

export const selectAdminCompanies = () => createSelector(
  domain,
  (substate) => substate.adminCompanies,
);

export const selectAdminCompanyUsers = () => createSelector(
  domain,
  (substate) => substate.adminCompanyUsers,
);

export const selectCompanyDepartments = () => createSelector(
  domain,
  (substate) => substate.companyDepartments,
);

export const selectIntegrationComponents = () => createSelector(
  domain,
  (substate) => substate.integrationComponents,
);

export const selectIntegrationComponentsDetail = () => createSelector(
  domain,
  (substate) => substate.integrationComponentsDetail,
);

export const selectIntegration = () => createSelector(
  domain,
  (substate) => substate.integration,
);

export const selectIntegrationComponentsDetailUser = () => createSelector(
  domain,
  (substate) => substate.integrationComponentsDetailUser,
);

export const selectIntegrationMessages = () => createSelector(
  domain,
  (substate) => substate.integrationMessages,
);
export const selectIntegrationChannels = () => createSelector(
  domain,
  (substate) => substate.integrationChannels,
);

export const selectRegAccountTypes = () => createSelector(
  domain,
  (substate) => substate.regAccountTypes,
);

export const selectRegCurrencies = () => createSelector(
  domain,
  (substate) => substate.regCurrencies,
);

export const selectRegLanguages = () => createSelector(
  domain,
  (substate) => substate.regLanguages,
);

export const selectRegComplete = () => createSelector(
  domain,
  (substate) => substate.regComplete,
);

export const selectActivationComplete = () => createSelector(
  domain,
  (substate) => substate.activationComplete,
);

export const selectPassResetComplete = () => createSelector(
  domain,
  (substate) => substate.passResetComplete,
);

export const selectSurveyDetailApps = () => createSelector(
  domain,
  (substate) => substate.surveyDetailApps,
);

export const selectSurveyReportQuestions = () => createSelector(
  domain,
  (substate) => substate.surveyReportQuestions,
);

export const selectAnswerOptionUsers = () => createSelector(
  domain,
  (substate) => substate.answerOptionUsers,
);

export const selectActiveNotFound = () => createSelector(
  domain,
  (substate) => substate.activeNotFound,
);

export const selectInventoryRegisterFiles = () => createSelector(
  domain,
  (substate) => substate.inventoryRegisterFiles,
);

export const selectInventoryRegisterFileUrls = () => createSelector(
  domain,
  (substate) => substate.inventoryRegisterFileUrls,
);

export const selectHardwareModalTypes = () => createSelector(
  domain,
  (substate) => substate.hardwareModalTypes,
);
export const selectHardwareModalCategory = () => createSelector(
  domain,
  (substate) => substate.hardwareModalCategory,
);
export const selectHardwareDevices = () => createSelector(
  domain,
  (substate) => substate.hardwareDevices,
);
export const selectHardwareDeviceDetail = () => createSelector(
  domain,
  (substate) => substate.hardwareDeviceDetail,
);

export const selectHardwareLogs = () => createSelector(
  domain,
  (substate) => substate.hardwareLog,
);

export const selectCostingCategories = () => createSelector(
  domain,
  (substate) => substate.costingCategories,
);

export const selectHWCostingCategories = () => createSelector(
  domain,
  (substate) => substate.hwCostingCategories,
);

export const selectCostingList = () => createSelector(
  domain,
  (substate) => substate.costingList,
);

export const selectCostingDetail = () => createSelector(
  domain,
  (substate) => substate.costingDetail,
);

export const selectCostingFiles = () => createSelector(
  domain,
  (substate) => substate.costingFiles,
);

export const selectApplicationContracts = () => createSelector(
  domain,
  (substate) => substate.applicationContracts,
);

export const selectCostingLogs = () => createSelector(
  domain,
  (substate) => substate.costingLogs,
);

export const selectCostingFileUrls = () => createSelector(
  domain,
  (substate) => substate.costingFileUrls,
);

export const selectCostGroup = () => createSelector(
  domain,
  (substate) => substate.costGroup,
);

export const selectApplicationDrafts = () => createSelector(
  domain,
  (substate) => substate.applicationDrafts,
);

export const selectInventoryConfig = () => createSelector(
  domain,
  (substate) => substate.inventoryConfig,
);

export const selectCostingConfig = () => createSelector(
  domain,
  (substate) => substate.costingConfig,
);

export const selectApplicationDraft = () => createSelector(
  domain,
  (substate) => substate.applicationDraft,
);

export const selectApplicationLifecycle = () => createSelector(
  domain,
  (substate) => substate.applicationLifecycle,
);

export const selectIntegrationRegisterFileUrls = () => createSelector(
  domain,
  (substate) => substate.integrationRegisterFileUrls,
);
export const selectIntegrationRegisterFiles = () => createSelector(
  domain,
  (substate) => substate.integrationRegisterFiles,
);
export const selectExcelErrorList = () => createSelector(
  domain,
  (substate) => substate.excelErrorList,
);
export const selectExcelErrorModal = () => createSelector(
  domain,
  (substate) => substate.excelErrorModal,
);
export const selectLdapUsers = () => createSelector(
  domain,
  (substate) => substate.ldapUsers,
);
export const selectSendToDetailPageID = () => createSelector(
  domain,
  (substate) => substate.sendToDetailPageID,
);
export const selectContractYears = () => createSelector(
  domain,
  (substate) => substate.contractYears,
);
export const selectSendToView = () => createSelector(
  domain,
  (substate) => substate.sendToView,
);
export const selectVendorUsers = () => createSelector(
  domain,
  (substate) => substate.vendorUsers,
);
export const selectGeneralReportType = () => createSelector(
  domain,
  (substate) => substate.generalReportType,
);
export const selectAppCostReport = () => createSelector(
  domain,
  (substate) => substate.appCostReport,
);
export const selectPerBusinessCapability = () => createSelector(
  domain,
  (substate) => substate.perBusinessCapability,
);
export const selectPerITDomain = () => createSelector(
  domain,
  (substate) => substate.perITDomain,
);
export const selectPerBusinessDomain = () => createSelector(
  domain,
  (substate) => substate.perBusinessDomain,
);
export const selectDashboardMapReport = () => createSelector(
  domain,
  (substate) => substate.dashboardMapReports,
);
export const selectDashboardCostingDetail = () => createSelector(
  domain,
  (substate) => substate.dashboardCostingDetail,
);
export const selectHWCostingExpenses = () => createSelector(
  domain,
  (substate) => substate.hwCostingExpenses,
);
export const selectHWCostingDetail = () => createSelector(
  domain,
  (substate) => substate.hwCostingDetail,
);
export const selectHWCostingList = () => createSelector(
  domain,
  (substate) => substate.hwCostingList,
);
export const selectHWCostingLogs = () => createSelector(
  domain,
  (substate) => substate.hwCostingLogs,
);

export const selectLicenseFollow = () => createSelector(
  domain,
  (substate) => substate.licenseFollow.tableList,
);

export const selectLicenseFollowSelectData = () => createSelector(
  domain,
  (substate) => {
    const { tableList, ...data } = substate.licenseFollow;
    return data;
  },
);

export const selectLicenseFollowDetail = () => createSelector(
  domain,
  (substate) => substate.licenseFollowDetail,
);

export const selectLicenseFollowDetailLog = () => createSelector(
  domain,
  (substate) => substate.licenseFollowDetailLog,
);

export const selectUserModule = () => createSelector(
  domain,
  (substate) => substate.activeUser?.modules?.filter((i) => i?.active)?.map((i) => i.id),
);

export const selectIntegrationPiUrlId = () => createSelector(
  domain,
  (substate) => substate.integrationPiUrlId,
);

export const selectIntegrationComponentInitData = () => createSelector(
  domain,
  (substate) => substate.integrationInitComponentData,
);

export const selectIntegrationResponsibleData = () => createSelector(
  domain,
  (substate) => substate.integrationResponsibleData,
);
export const selectIntegrationMessageLogData = () => createSelector(
  domain,
  (substate) => substate.integrationMessageLog,
);
export const selectIntegrationMessageMonitoringSendMailUserData = () => createSelector(
  domain,
  (substate) => substate.integrationMessageMonitoringSendMailUser,
);

import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  ModalHeader,
  ModalContent,
  CustomModal,
  CustomButton,
  BottomSheetWrapper,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import Input from '../Input';
import {
  changePassword, setResetState, createCompany, addCompanyModal,
} from '../../store/actions';
import { selectAddCompanyModal, selectInfos, selectResetState } from '../../store/selectors';
import BottomSheet from '../BottomSheet';

class AddCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      prefix: '',
      userTitle: '',
      language: '',
      currency: '',
    };
    this.validator = new SimpleReactValidator({
      locale: 'tr',
      autoForceUpdate: this,
      element: false,
    });
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.resetState && nextProps.open) {
      nextProps.setResetState(false);
      nextProps.addCompanyModal(false);
      return {
        name: '',
        prefix: '',
        userTitle: '',
        language: '',
        currency: '',
      };
    }
    return null;
  }

  resetState = () => {
    this.setState({
      name: '',
      prefix: '',
      userTitle: '',
      language: '',
      currency: '',
    });
    this.validator.hideMessages();
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  createCompany = () => {
    const {
      name, prefix, userTitle, language, currency,
    } = this.state;
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return false;
    }
    const data = {
      name,
      prefix,
      user_title: userTitle,
      language,
      currency_id: currency,
    };
    this.props.createCompany(data);
    return true;
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;

    const {
      name, prefix, userTitle, language, currency,
    } = this.state;
    const { languages, currencies } = this.props;
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{tra('Şirket_Ekle')}</h1>
          <CloseIcon onClick={() => this.props.addCompanyModal(false)} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Sirket_Adi')}
                  placeholder={`${tra('Örnek_Şirket_Adı')}: MDP Group `}
                  name="name"
                  value={name}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('name', name, 'required')}
                  helperText={this.validator.message('name', name, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  name="prefix"
                  title={tra('Şirket_Ön_Eki')}
                  placeholder={`${tra('Örnek_Şirket_Ön_Eki')}: MDP`}
                  value={prefix}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('prefix', prefix, 'required')}
                  helperText={this.validator.message('prefix', prefix, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  name="userTitle"
                  value={userTitle}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('userTitle', userTitle, 'required')}
                  helperText={this.validator.message('userTitle', userTitle, 'required')}
                  title={tra('Kullanıcı_Ünvanı')}
                  placeholder={`${tra('Örnek_Kullanıcı_Ünvanı')}: ${tra('Yonetici')}`}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  type="select"
                  data={languages}
                  name="language"
                  value={language}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('language', language, 'required')}
                  helperText={this.validator.message('language', language, 'required')}
                  title={tra('Dil_Secimi')}
                  labelKey="language"
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  type="select"
                  name="currency"
                  data={currencies}
                  value={currency}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('currency', currency, 'required')}
                  helperText={this.validator.message('currency', currency, 'required')}
                  title={tra('Para_Birimi')}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={() => this.createCompany()}>{tra('Kaydet')}</CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={() => this.props.addCompanyModal(false)} onExit={() => this.resetState()} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} isOpen={(open) => { if (open === false) this.props.addCompanyModal(false); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AddCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  createCompany: PropTypes.func.isRequired,
  addCompanyModal: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  resetState: selectResetState(),
  open: selectAddCompanyModal(),
  languages: selectInfos('Languages'),
  currencies: selectInfos('Currencies'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      changePassword,
      setResetState,
      createCompany,
      addCompanyModal,
    }, dispatch)
  )
);

const AddCompanyModalWithTheme = withTheme(AddCompanyModal);
const AddCompanyModalWithWidth = withWidth()(AddCompanyModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyModalWithWidth);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import React, { Component, memo } from 'react';

import { isWidthDown, withWidth } from '@material-ui/core';
import GraphTab from './Components/GraphTab';
import ListTab from './Components/ListTab';
import ChannelMonitoringTab from './Components/ChannelMonitoringTab';
import ReportTab from './Components/ReportTab';
import {
  CustomTabHead, CustomTabs, PageContent, PageWrapper, TabWrapper,
} from '../../assets/styledNew';
import { Colors } from '../../assets/statics';
import { tra } from '../../commons/utils';
import MessageMonitoringTab from './Components/MessageMonitoringTab';
import { getInfoMonitoring } from '../../store/actions';

class Integration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.storyAdminTabs = [
      {
        path: '/graph',
        tabValue: 0,
        label: tra('Grafik'),
        id: '0',
        tabPanel: 'full-width-tabpanel-0',
      },
      {
        path: '/list',
        tabValue: 1,
        label: tra('Liste'),
        id: '1',
        tabPanel: 'full-width-tabpanel-1',
      },
      {
        path: '/channel',
        tabValue: 2,
        label: tra('Channel_Monitoring'),
        id: '2',
        tabPanel: 'full-width-tabpanel-1',
      },
      {
        path: '/message',
        tabValue: 3,
        label: tra('Message_Monitoring'),
        id: '3',
        tabPanel: 'full-width-tabpanel-1',
      },
      {
        path: '/report',
        tabValue: 4,
        label: tra('Responsible_Report'),
        id: '4',
        tabPanel: 'full-width-tabpanel-1',
      },
    ];
  }

  componentDidMount() {
    this.props.getInfoMonitoring();
  }

  displayDesktop = (storyAdminTabs) => {
    const { history, location: { pathname } = {}, match: { path } = {} } = this.props;
    return (
      <TabWrapper>
        <CustomTabs
          opacity
          value={pathname}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, newValue) => history.push(`${path}${this.storyAdminTabs[newValue].path}`)}
          style={{ borderBottom: `1px solid ${Colors.lightGrey400}`, paddingBottom: 0 }}
        >
          {storyAdminTabs.map((item) => (
            <CustomTabHead
              opacity
              label={item.label}
              tabValue={`${path}${item.path}`}
              active={pathname.includes(`${path}${item.path}`)}
              id={item.id}
            />
          ))}
        </CustomTabs>
        <Switch>
          <Route path={`${path}/graph`} exact component={GraphTab} />
          <Route path={`${path}/list`} exact component={ListTab} />
          <Route path={`${path}/message`} exact component={MessageMonitoringTab} />
          <Route path={`${path}/channel`} exact component={ChannelMonitoringTab} />
          <Route path={`${path}/report`} exact component={ReportTab} />
          <Redirect to={`${path}/graph`} />
        </Switch>
      </TabWrapper>
    );
  }

  displayMobile = () => this.displayDesktop(this.storyAdminTabs)

  render() {
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            {isWidthDown('sm', this.props.width) ? this.displayMobile(this.storyAdminTabs) : this.displayDesktop(this.storyAdminTabs)}
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

Integration.propTypes = {
  match: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getInfoMonitoring: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getInfoMonitoring,
}, dispatch);

export default compose(
  withWidth(),
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(Integration);

import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const deviceDetail = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'PrinterScanner',
    inputArray: [
      {
        type: 'text',
        title: tra('Isim'),
        name: 'name', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Baglanti_Sekli'),
        name: 'connection_style', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Baski_Yonetimi'),
        name: 'printing_method', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'text',
        title: tra('Urun_Turu'),
        name: 'product_type', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Baski_Hizi'),
        name: 'printing_speed', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
];

export default [[...inventoryPages, deviceDetail]];

import styled from 'styled-components';
import { Accordion, Grid, TextField } from '@material-ui/core';
import { Colors } from './statics';
import { hexToRgbA, scrSize } from '../commons/utils';
import { CustomButton } from './styledNew';

export const SurveyWrapper = styled.div`
  padding: 15px;
  border: 2px solid ${(props) => props.theme.border};
  width: 100%;
  margin-top: 20px;
  background-color: ${(props) => props.theme.surveyFormBackg};
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      font-size: 15px;
      font-weight: 600;
      color: ${(props) => props.theme.text2};
    }
  }
  .surveyItem {
    width: 100%;
    margin-top: 20px;
  }
`;

export const CustomAccordion = styled(Accordion)`
  box-shadow: unset;
  background-color: ${(props) => props.theme.surveyFormBackg};
  border: 1px solid ${(props) => props.theme.border};;
  border-radius: 15px!important;
  .MuiAccordionSummary-expandIcon {
    margin: 0px 20px;
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: ${Colors.blue};
      }
    }
  }
  .Mui-expanded {
    .end {
      .notE {
        svg {
          display: none;
        }
      }
      .exp {
        svg {
          display: inline-flex!important;
        }
      }
    }
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
    border-bottom: 1px solid ${(props) => props.theme.border};
    background: ${(props) => props.theme.boxBackg};
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    border-radius: 15px;
    background-color: ${(props) => props.theme.boxBackg};
  }
  .MuiAccordionSummary-content {
    margin: 0px;
  }
  .contentWrapper {
    width: 100%;
    padding: 40px 20px 20px 20px;
  }
  .summaryWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    .start {
      padding: 0px 15px;
      border-right: 1px solid ${(props) => props.theme.border};
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .MuiSvgIcon-root {
        fill: ${Colors.lightGrey300};
      }
    }
    .question {
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0px 20px;
      > p {
        color: ${(props) => props.theme.text2};
        font-size: 15px;
        span {
          font-weight: 600;
        }
      }
    }
    .end {
      padding: 0px 15px;
      border-left: 1px solid ${(props) => props.theme.border};
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .exp {
        svg {
          width: 15px;
          height: 15px;
          display: none;
          path {
            fill: ${Colors.lightPurple100};
          }
        }
      }
    }
  }
  .bottomActions {
    display: flex;
    align-items: center;
    button {
      margin-right: 10px;
    }
    svg {
      path {
        fill: ${Colors.lightGrey300};
      }
    }
    .MuiSwitch-track {
      background: ${(props) => props.theme.border} !important;
    }
  }
  @media ${scrSize('xs', 'sm')} {
    .contentWrapper {
      padding: 40px 0px 20px 0px;
    }
  }
  .surveyChart {
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h4 {
      color: ${(props) => props.theme.blueText};
    }
    .icon {
    }
  }
  .answerBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.mainBackg};
    .title {
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;
        color: ${(props) => props.theme.text1};
      }
      svg {
        path {
          fill: ${Colors.lightGrey100};
        }
      }
    }
    .select {
      margin-top: 15px;
      .selectField {
        .MuiInput-underline:after {
          display: none;
        }
        .MuiSelect-select.MuiSelect-select {
          color: ${(props) => props.theme.blueText};
        }
        .MuiSelect-icon {
          color: ${(props) => props.theme.blueText};
        }
      }
    }
  }
`;

export const SelectUserItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  .MuiPaper-root {
    background: ${(props) => props.theme.boxBackg};
  }
  .img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    p {
      font-size: 12px;
      color: ${(props) => props.theme.text2};
    }
    h4 {
      color: ${(props) => props.theme.text3};
      font-size: 15px;
      margin: 5px 0px;
    }
  }
`;

export const SurveyCardWrapper = styled.div`
  & + & {
    margin-left: 50px;
  }
  .surveyCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.boxBackg};
    border-radius: 10px;
    padding: 40px 30px;
    width: 200px;
    box-shadow: 0px 26px 36px rgba(18, 33, 96, 0.2);
    .iconContainer {
      > svg {
        width: 50px;
        height: 50px;
        path {
          fill: ${(props) => props.theme.text3};
          ${(props) => props.active && `
            fill: ${props.theme.blueText};
          `}
        }
      }
      background: ${hexToRgbA(Colors.lightBlue100, 0.1)};
      height: 150px;
      width: 150px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .smallIcon {
        background: ${(props) => props.theme.lightGrey300};
        ${(props) => props.active && `
          background: ${props.theme.blueText};
        `}
        position: absolute;
        bottom: -10px;
        right: -10px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 10px solid ${(props) => props.theme.boxBackg};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .title {
      display: flex;
      margin-top: 30px;
      > p {
        text-align: center;
        font-weight: 600;
        color: ${(props) => props.theme.text2};
      }
    }
    .actions {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        margin-top: 10px;
        font-weight: 600;
        :first-child {
          color: ${Colors.blue};
          ${(props) => props.active && `
            color: ${props.theme.blueText};
          `}
        }
        :last-child {
          color: ${(props) => props.theme.purpleText};
          ${(props) => props.active && `
            color: ${props.theme.blueText};
          `}
        }
      }
    }
  }
  .selectWrapper {
    margin-top: 40px;
  }
`;

export const CustomTextField = styled(TextField)`
  .MuiInputBase-root {
    color: ${(props) => props.theme.text2};
  }
  .MuiInput-underline:before , .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.text3};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before , .MuiOutlinedInput-notchedOutline:hover {
    border-color: ${(props) => props.theme.border};
  }
  .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.border};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.border} !important;
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }
  .MuiInputBase-root.Mui-disabled {
    color: ${(props) => props.theme.text1} !important;
  }
`;

export const PublishHeader = styled.div`
  margin-top: 20px;
  width: 100%;
  background: ${Colors.lightPurple200};
  color: ${Colors.blue};
  display: flex;
  align-items: center;
  box-shadow: 0px 26px 36px rgba(18, 33, 96, 0.1);
  justify-content: center;
  border-radius: 10px;
  padding: 15px;
  > p {
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
  }
  svg {
    width: 25px;
    height: 25px;
  }
`;

export const MultipleChoiseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .factor {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 10px;
      border: 1px solid ${(props) => props.theme.border};
      background-color: ${(props) => props.theme.boxBackg};
      margin-left: 15px;
      > svg {
        margin: 10px 15px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        > path {
          fill: ${(props) => props.theme.blueText};
        }
      }
      > p {
        color: ${(props) => props.theme.text2};
        margin-left: 0px!important;
      }
    }
  }
  div:last-child {
    > p {
      margin-left: 10px;
      padding: 10px 0px;
      color: ${(props) => props.theme.text1} !important;
      a {
        color: ${(props) => props.theme.blueText};
      }
    }
  }
`;

export const SelectionHeader = styled.div`
  display: flex;
  width: 100%;
  > div {
    padding: 20px 15px;
    width: 100%;
  }
  align-items: center;
  input {
    padding: 10px;
  }
  h4 {
    color: ${(props) => props.theme.text1};
  }
`;

export const SelectionAccordionWrapper = styled.div`
  max-height: 500px;
  overflow: auto;
  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionSummary-root {
    padding: 0px 30px;
    background-color: ${(props) => props.theme.inputBackg};
    color: ${(props) => props.theme.text1};
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
    max-height: 165px;
    overflow: auto;
    padding: 5px 30px;
    background: rgba(228, 236, 247, 0.3);
  }
  
`;

export const WidgetSmall = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px;
  .svgBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: rgba(22, 70, 255, 0.5);
    transition: all 1s ease;
    border-radius: 100%;
    ${(props) => props.percent > 0 && `
      background-image: 
        linear-gradient(${props.percent <= 50 ? (90 + (360 * (props.percent / 100))) : (90 + (360 * (((100 - props.percent) + 50) / 100)))}deg, transparent 50%, ${props.percent > 50 ? 'rgba(22, 70, 255, 0.5)' : Colors.lightGrey800} 50%),
        linear-gradient(90deg, ${Colors.lightGrey800} 50%, transparent 50%);
        transform: scaleX(${props.percent > 50 ? '-1' : 'unset'}) rotate(${props.percent > 50 ? '180deg' : '0deg'});
        svg {
          transform: rotate(${props.percent > 50 ? '180deg' : '0deg'});
        }
    `}
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${Colors.blue};
      border-radius: 100%;
      width: 90px;
      height: 90px;
      > p {
        font-weight: 600;
        color: white;
        font-size: 25px;
      }
    }
  }
`;

export const SingleSurveyDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    .profileCircle {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: ${Colors.lightGrey400};
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: ${(props) => props.theme.text1};
      margin-left: 20px;
      > h4 {
        font-size: 25px;
      }
      > p {
        font-size: 15px;
      }
    }
  }
`;

export const SurveySectionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  > p {
    position: absolute;
    font-weight: 500;
    background: ${(props) => (props.answer ? props.theme.boxBackg : props.theme.boxBackg)};
    color: ${(props) => props.theme.text1};
    padding: 5px 20px;
  }
  :before {
    content: "";
    width: ${(props) => (props.answer ? '45%' : '50%')};
    height: 1px;
    background-color: ${Colors.lightGrey200};
    left: 0;
  }
  :after {
    content: "";
    width: ${(props) => (props.answer ? '45%' : '50%')};
    height: 1px;
    background-color: ${Colors.lightGrey200};
    right: 0;
  }
`;

export const SurveyQuestionBox = styled.div`
  display: flex;
  min-height: 150px;
  padding: 20px;
  height: 80%;
  background-color: ${(props) => props.theme.boxBackg};
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 10px;
  > h4 {
    font-size: 15px;
    color: ${(props) => props.theme.text1};
    padding: 0px 15px;
  }
  .answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    width: 100%;
    overflow: auto;
  }
`;

export const SurveyOptionBox = styled.div`
  width: 100%;
  .paragraph {
    margin: 10px 0px;
  }
  .radioButton {
    padding: 5px 0px;
    margin: 10px 0px;
    width: 100%;
    border-radius: 5px;
    color: ${(props) => props.theme.text1};
    font-weight: 600;
    > p {
      padding: 0px 15px;
    }
    ${(props) => props.answered && `
      background-color: rgba(76, 111, 255, 0.2);
      > p {
        font-weight: 800;
      } 
    `}
  }
  .Mui-disabled {
    color: ${(props) => props.theme.text3} !important;
  }
`;

export const StatusBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 8px 0px;
  background-color: ${(props) => (props.type === 1 ? props.theme.greenBackg : props.type === 2 ? 'rgba(255, 169, 0, 0.2)' : props.theme.redBackg)};
  color: ${(props) => (props.type === 1 ? props.theme.greenText : props.type === 2 ? '#885A00' : props.theme.redText)};
  font-size: 12px;
  font-weight: 600;
`;

export const SurveyAnswerBox = styled.div`
  display: flex;
  padding: 20px;
  // background-color: ${(props) => props.theme.boxBackg};
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 10px;
  color:${(props) => props.theme.text1};
  > h5 {
    margin-top: 10px;
    font-size: 15px;
    color: ${(props) => props.theme.redText};
  }
  > p {
    font-size: 14px;
    color: ${(props) => props.theme.text2};
  }
  > h4 {
    font-size: 15px;
    color: ${(props) => props.theme.text2};
  }
  .answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    width: 100%;
    color:${(props) => props.theme.text1}
  }
  .paragraph {
    .MuiFormControl-root {
      width: 50%;
    }
  }
  @media ${scrSize('xs', 'sm')} {
    .paragraph {
    .MuiFormControl-root {
      width: 100%;
    }
  }
  }
`;

export const SurveyCard = styled.div`
  cursor: ${(props) => props.add && 'pointer'};
  transition: all 0.8s ease;
  :hover {
    background: ${(props) => props.theme.surveyCardHover};
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 14%);
    .iconButtons {
      ul  li {
        background: ${(props) => props.theme.boxBackg}!important;
      }
    }
  }
  display: flex;
  position: relative;
  height: 100%;
  background: ${(props) => props.theme.surveyCardBackg};
  border-radius: 20px;
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 14%);
  padding: 15px;
  .cardHeader {
    // width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .smallCircle {
      display: flex;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: ${Colors.lightBlue100};
    }
    .menu {
      position: absolute;
      right: 0px;
      button {
        padding: 5px;
      }
    }
  }
  .create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        //align-item: center;
        background: ${hexToRgbA(Colors.lightBlue100, 0.2)};
        min-width: 50px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > h4 {
          font-size: 18px;
          color: ${(props) => props.theme.text2};
          font-weight: 600;
        }
        > p {
          font-size: 14px;
          font-weight: 600;
          color: ${(props) => props.theme.text3};
        }
      }
    }
  }
  .createdCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      justify-content: flex-start;
      overflow: hidden;
      .icon {
        display: flex;
        justify-content: center;
        align-items: right;
        min-width: 50px;
        width: 50px;
        border-radius: 50%;
        > svg {
          path {
            fill: blue;
          } 
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0px;
        width: 100%;
        h4 {
          font-size: 18px;
          color: ${(props) => props.theme.text2};
          font-weight: 600;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > p {
          font-size: 14px;
          font-weight: 600;
          margin: 10px 0px;
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: ${(props) => props.theme.text3};
          margin-top: 5px;
        }
      }
      .menu {
        padding: 0; 
        margin: 0;
        button {
          padding: 0;
        }
      }
      
    }
    .cardBar {
      width: 100%;
      .singleApp {
        text-align: center;
        color: ${(props) => props.theme.text1};
        margin-bottom: 10px;
      }
      .barInfo {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        padding: 5px 0;
        color: ${(props) => props.theme.text3};
      }
      .progressBar {
        background-color: ${Colors.lightPurple300};
        height: 5px;
        width: 100%;
        transition: all 1s ease;
        position: relative;
        border-radius: 15px;
        :before {
          content: "";
          position: absolute;
          height: 8px;
          background-color: ${(props) => (props.color || Colors.lightBlue100)};
          width: ${(props) => ((props.bar / props.volume) > 1 ? '100%' : `${(props.bar / props.volume) * 100}%`)};
          left: 0;
          top: -2px;
          border-radius: 15px;
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .date {
        > p {
          font-size: 13px;
          color: ${(props) => props.theme.text3};
        }
      }
      .iconButtons {
        ul {
          display: flex;
          li + li {
            margin-left: 10px;
          }
          li {
            cursor: pointer;
            background: ${(props) => hexToRgbA(props.theme.boxBackg, 0.7)};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            transition: all 0.8s ease;
            align-items: center;
            a {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            svg {
              padding: 5px;
            }
            :hover {
              > svg path {
                fill: ${(props) => (props.color || Colors.blue)};
              }
              > svg ellipse {
                fill: ${(props) => (props.color || Colors.blue)};
              }
            }
            a:hover {
              > svg path {
                fill: ${(props) => (props.color || Colors.blue)};
              }
              > svg ellipse {
                fill: ${(props) => (props.color || Colors.blue)};
              }
            }
          }
        }
      }
    }
  }
  .MuiInputBase-root {
    height: 30px;
    margin-bottom: 10px;
    > div {
      border-radius: 10px;
      text-align: center;
      padding: 5px 8px 5px 0px;
      }
    }
  @media ${scrSize('xs', 'sm')} {
    height: fit-content;
    .create {
      padding: 25px 0;
      .content {
        .info { 
          h4 {
            font-size: 20px;
          }
          p {
            font-size: 10px
          }
        }
        .icon {
          padding: 5px;
        }
      }
    }
    .createdCard {
      .cardHeader {
        .menu button {
          padding: 5px 0;
        }
      }
      .content {
        .info {
          h4 {
            font-size: 16px;
          }
          p {
            font-size: 10px;
            margin: 5px 0;
          }
        }
        .icon {
          min-width: 35px;
          min-height: 35px;
          width: 35px;
          height: 35px;
          svg {
            width: 20px;
          }
        }
      }
      .cardBar {
        .barInfo {
          > span {
            font-size: 10px;
          }
        }
        .progressBar {
          height: 3px;
          ::before {
            height: 5px;
          }
        }
      }
      .footer {
        .date p{
          font-size: 10px;
        }
      }
    }
  }
`;

export const SurveyStepper = styled.div`
  display: inline-flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '50%')};
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    left: calc((100% - 90%)/2);
    width: 90%;
    height: 2px;
    /* width: 100%; */
    vertical-align: middle;
    background-color: ${Colors.lightGrey200};
    z-index: 0;
  }
  ::before {
    content: "";
    position: absolute;
    height: 2px;
    transition: width 0.5s ease;
    left: calc((100% - 90%)/2);
    width: ${(props) => (props.stepIndex === 1 ? '0px' : props.stepIndex === props.children.length + 1 ? '90%' : `calc(90% * ${(props.stepIndex - 1) / (props.children.length - 1)})`)};
    vertical-align: middle;
    background-color: ${Colors.blue};
    z-index: 1;
  }
  .step {
    display: flex;
    position: relative;
    align-items: center;
    padding: 20px;
    :nth-child(${(props) => `-n+ ${props.stepIndex}`}) {
      .circle {
        background-color: ${Colors.blue};
        border: unset;
        box-shadow: 0 0 0 5px ${(props) => props.theme.mainBackg};
        span {
          color: ${Colors.white};
        }
      }
    }
    /* :nth-child(${(props) => `-n+ ${props.stepIndex - 1}`}) {
      :after {
        background-color: ${Colors.blue}!important;
      }
    } */
    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      z-index: 1;
      background-color: ${(props) => props.theme.mainBackg};
      /* border: 2px solid ${Colors.lightGrey200}; */
      box-shadow: 0 0 0 2px ${Colors.lightGrey200}, 0 0 0 5px ${(props) => props.theme.mainBackg};
      border-radius: 50%;
      position: relative;
      > span {
        font-size: 20px;
        color: ${Colors.lightGrey200};
      }
      > p {
        position: absolute;
        bottom: -100%;
        white-space: nowrap;
        font-weight: 600;
        font-size: 15px;
        color: ${(props) => props.theme.text2};

      }
    }
    /* :not(:last-child):after {
      content: "";
      position: absolute;
      height: 2px;
      left: 100%;
      width: 300px;
      vertical-align: middle;
      background-color: ${Colors.lightGrey200};
    } */
  }

  @media ${scrSize('xs', 'sm')} {
    width: 70%;
    .step {
      padding: 5px;
      .circle {
        width: 30px;
        height: 30px;
        p {
          bottom: -70%;
          font-size: 10px;
        }
      }
      :not(:last-child):after {
        left: 100%;
        width: 55px;
      }
    }
  }
`;

export const SavedSurveyBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 10px;
  padding: 25px;
  height: 150px;
  position: relative;
  svg {
    width: 25px;
    height: 25px;
    path {
      fill: ${Colors.lightPurple100};
    }
  }
  h4 {
    color: ${Colors.lightPurple100};
  }
  :hover {
    box-shadow: 0px 26px 36px rgba(18, 33, 96, 0.1);
    svg {
      path {
        fill: ${Colors.blue};
      }
    }
    h4 {
      color: ${Colors.blue};
    }
    p {
      color: ${Colors.lightPurple100};
    }
  }
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${(props) => props.theme.text2}
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
      color: ${(props) => props.theme.text2};
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .content {
    margin-top: 15px;
    font-size: 15px;
    color: ${Colors.lightPurple100};
  }
`;

export const SurveyDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // margin: 20px 0px;
  padding-top: 20px;
  .info {
    display: flex;
    flex-direction: row;
    .circle {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: ${Colors.lightBlue100};
    }
    .MuiSelect-select {
      padding-right: 35px;
    }
    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
      color: ${(props) => props.theme.text2};
      > h4 {
        font-size: 24px;
        color: ${(props) => props.theme.text2};
      }
      > div {
        margin-top: 10px;
      }
    }
  }
  .progressBar {
    padding: 0px 10px;
    margin-top: 20px;
    background-color: ${(props) => props.theme.surveyProgressBackg};
    height: 20px;
    width: 100%;
    transition: all 1s ease;
    display: flex;
    align-items: center;
    border-radius: 15px;
    .answered {
      background-color: ${Colors.lightBlue100};
      width: ${(props) => props.answered}%;
    }
    .notAnswered {
      background-color: ${Colors.lightRed};
      width: ${(props) => props.notAnswered}%;
    }
    .halfAnswered {
      background-color: ${Colors.lightPurple};
      width: ${(props) => props.halfAnswered}%;
    }
    > div {
      height: 10px;
      :first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      :last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
`;

export const SurveyDetailHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px -1px rgb(53 63 102 / 5%);
  background-color:${(props) => props.theme.boxBackg};
  ${(props) => props.active && `
    background-color: ${props.backg};
  `};
`;

export const SurveyHeaderContainer = styled(Grid)`
  @media ${scrSize('xs', 'sm')} {
    overflow-y: hidden;
    flex-flow: nowrap;
    height: fit-content;
    padding: 0;
    .dashboardInfoCard {
      z-index: 99;
      height: fit-content;
    }
    ${SurveyDetailHeaderBox} {
        width: 45vw;
      }
    ::-webkit-scrollbar {
        display: none;
    }
  }
`;

export const SurveyHeaderApp = styled.div`
  display: flex;
  align-items: center;
  > h4 {
    font-size: 22px;
    color: ${(props) => props.theme.text2};
  }
  > button {
    margin: 0px 10px;
    svg {
      path {
        fill: ${(props) => props.theme.blueText};
      }
    }
  }
`;

export const SurveyListMobile = styled.div`
  width: -webkit-fill-available;
  /* margin: 0 20px; */
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    h4 {
      font-size: 14px;
      color:${(props) => props.theme.text1}
    }
    ${CustomButton} {
      padding: 0 2px;
    }
  }
  ${(props) => (props.allPage
    ? `> .content {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    > div {
      margin: 15px;
    }
  }`
    : `> .content {
    display: flex;
    flex-flow: nowrap;
    overflow-y: hidden;
    padding: 20px 0;
    transition: all 0.5s ease-in-out;
    ::-webkit-scrollbar {
    display: none;
    }
    > div {
      margin-right: 10px;
      :first-child {
        margin: 0 10px 0 15px;
      }
    }
  }`)}

`;

export const SurveyAnswerBottomBar = styled.div`
  background-color: ${(props) => props.theme.boxBackg};
  position: fixed;
  bottom: 0;
  display: flex;
  left: 80px;
  right: 0;
  padding: 20px 120px !important;
  box-shadow: 0px 4px 33px rgba(53, 63, 102, 0.1);
  @media ${scrSize('md', 'xs')} {
    left: 0;
    padding: 10px 20px!important;
    justify-content: flex-end;
  } 
  @media screen and (max-height: 720px) {
    left:80px;
  }
  `;
export const SurveyChartBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
  width: 100%;
  background-color: ${(props) => props.theme.boxBackg};
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 15px;
  min-height: 150px;
  margin: 20px 0px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h4{
      color: ${(props) => props.theme.text1};
    }
    .totalAnswered {
      display: flex;
      flex-direction: row;
      align-items: center;
      right: 0;
      svg {
        > path {
          fill: ${Colors.lightBlue100};
        }
      }
      .count {
        color: ${Colors.lightBlue100};
        font-size: 30px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    .detail {
      position: absolute;
      bottom: 0px;
      left: 0;
      color: ${(props) => props.theme.blueText};
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
`;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';

import { HardwareContext } from '../../commons/context/HardwareContext';
import InputGroupRender from './InputGroupRender';
import AccordionCard from './AccordionCard';

class GridRender extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType=HardwareContext

  constructor(props) {
    super(props);
    this.state = {
      gridDataState: [],
    };
  }

  componentDidMount() {
    this.setState({ gridDataState: JSON.parse(JSON.stringify(this.props.gridData)) });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.gridData) !== JSON.stringify(this.props.gridData)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ gridDataState: JSON.parse(JSON.stringify(this.props.gridData)) });
    }
  }

  componentWillUnmount() {
    this.setState({ gridDataState: [] });
  }

  render() {
    const { gridData } = this.props;
    const { gridIndex, pagesIndex } = this.props;
    const { gridDataState } = this.state;
    return (
      <Grid item xs={12} md={6} style={isWidthDown('sm', this.props.width) ? {} : gridIndex % 2 === 1 ? { paddingLeft: '65px' } : { paddingRight: '65px' }}>
        {gridData && gridDataState && gridDataState.map((unitData, index) => (unitData.addGroupName
          ? (
            <AccordionCard
              unitData={unitData}
              index={index}
              pagesIndex={pagesIndex}
            />
          )
          : (
            <InputGroupRender
              data={unitData}
              pagesIndex={pagesIndex}
              gridIndex={gridIndex}
              propsChildren={this.props.propsChildren}
            />
          )))}
      </Grid>
    );
  }
}

GridRender.propTypes = {
  pagesIndex: PropTypes.number.isRequired,
  gridData: PropTypes.array.isRequired,
  gridIndex: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  propsChildren: PropTypes.node,

};
GridRender.defaultProps = {
  propsChildren: false,
};

const mapStateToProps = createStructuredSelector({
  // ...
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ...
    }, dispatch)
  )
);

const GridRenderWithTheme = withTheme(GridRender);

const GridRenderWithWidth = withWidth()(GridRenderWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(GridRenderWithWidth);

/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  SwipeableDrawer, IconButton, MenuItem, Fade,
} from '@material-ui/core';
import { ChevronLeft, PostAddOutlined } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { withTheme } from 'styled-components';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  CustomButton,
  MobileHeader,
  CustomMenu,
  HeaderHardwareSelectButton,
} from '../assets/styledNew';
import {
  CloudExport,
  CloudImport,
  Plus,
  File,
  Printer,
  Minus,
  EditNew,
  Pen,
  Save,
} from '../assets/icons';
import { Colors, SheetWithModalEnum, hardwareUnitSelect } from '../assets/statics';
import { tra, pageShow } from '../commons/utils';
import {
  setAppConfig,
  excelUpload,
  excelDownload,
  answerSurvey,
  followApplication,
  getIntegrationExcel,
  uploadIntegrationExcel,
} from '../store/actions';
import {
  selectSurveyQuestions,
  selectAssignedSurveys,
  selectSurveyAnswerBookmark,
  selectApplicationDetail,
  selectAppConfig,
  selectHardwareDeviceDetail,
} from '../store/selectors';
import MobileDrawer from './MobileDrawerMenu';
import { ModuleSwitch } from '../commons/ModuleSwitch';

class DisplayMobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        kaydet: tra('Kaydet'),
        bildirimEkrani: tra('Bildirim_Ekrani'),
        Yonetici_Ekrani: tra('Yonetici_Ekrani'),
        genelBakis: tra('Genel_Bakis'),
        envanter: tra('Envanter'),
        yeniEnvanterGirisi: tra('Yeni_Envanter_Girisi'),
        yeniEnvanter: tra('Yeni_Envanter'),
        yeniDonanim: tra('Yeni_Donanim'),
        takvim: tra('Takvim'),
        envanterDetay: tra('Envanter_Detay'),
        Anket_Olustur: tra('Anket_Olustur'),
        Anketler: tra('Anketler'),
        iceAktar: tra('Ice_Aktar'),
        disaAktar: tra('Disa_Aktar'),
        ornekIndir: tra('Ornek_Indir'),
        dilDegistir: tra('Dil_Degistir'),
        entegrasyonHaritasi: tra('Entegrasyon_Haritasi'),
        duzenle: tra('Duzenle'),
        arsiv: tra('Arsiv'),
        ciktiAl: tra('Cikti_Al'),
        lisansIslemleri: tra('Lisans_Islemleri'),
        yeniEntegrasyon: tra('Yeni_Entegrasyon'),
        dashboardDuzeni: tra('Dashboard_duzeni'),
        kullaniciAyarlari: tra('Kullanici_Ayarlari'),
        profil: tra('Profil'),
        vazgec: tra('Vazgec'),
        donanim_envanteri: tra('Donanim_Envanteri'),
        donanim_detay: tra('Donanim_Detay'),
        dokuman: tra('Dokuman'),
        masraf: tra('Masraf'),
        yeniMasraf: tra('Yeni_Masraf'),
        masrafDetay: tra('Masraf_Detay'),
        raporlar: tra('Raporlar'),
      },
      drawerMenu: false,
      excelMenu: null,
      integrationMenu: null,
      hardwareUnitMenu: null,
      // disabled: true
    };

    this.hiddenInput = React.createRef();
    this.integrationHiddenInput = React.createRef();
  }

  /*
  *   ---------------------  SURVEY_ANSWER  ---------------------
  */

  handleReturn = () => {
    const { history } = this.props;
    const match = {
      id: history.location.pathname.split('/')[3],
      appId: history.location.pathname.split('/')[4],
      isSelectedApp: history.location.pathname.split('/')[5],
    };
    if (match.isSelectedApp === '1') {
      history.replace(`/Surveys/${match.appId}`);
    } else {
      window.location.href = '#/Surveys';
    }
  }

  /* type === 1 gönder // type === 2 taslak */
  handleSaveSurvey = (type) => {
    const { surveyQuestions, history, surveyBookmark } = this.props;
    const match = {
      id: history.location.pathname.split('/')[3],
      appId: history.location.pathname.split('/')[4],
      isSelectedApp: history.location.pathname.split('/')[5],
    };
    const data = {
      app_id: match.appId,
      Answers: surveyBookmark.filter((item) => (item.option_choice_id !== '' && (item.answer_text !== ''))),
    };
    this.props.answerSurvey(data, match.id, type, surveyQuestions.update);
  }

  /*
  *   ------------------///  SURVEY_ANSWER  ---------------------
  */

  /*
  *   ---------------------  INTEGRATION PAGE  ---------------------
  */
  IntegrationButton = () => {
    const { lang } = this.state;
    // const { history } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ integrationMenu: e.currentTarget })}
          startIcon={<Plus width={12} />}
        >
          {tra('Yeni')}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.integrationMenu}
          open={Boolean(this.state.integrationMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ integrationMenu: null })}
        >
          <>
            <MenuItem onClick={() => this.integrationHiddenInput.current.click()}><CloudImport color={this.props.theme.blueText} />{lang.iceAktar}</MenuItem>
            <input onClick={(e) => { e.target.value = ''; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.integrationExcelUpload(e)} ref={this.integrationHiddenInput} type="file" style={{ display: 'none' }} />
          </>
          <MenuItem onClick={() => this.props.getIntegrationExcel()}><CloudExport color={this.props.theme.blueText} width={14} />{lang.disaAktar}</MenuItem>
          <MenuItem onClick={() => this.handleEventProps({ integrationModal: true }, () => this.setState({ integrationMenu: null }))}><PostAddOutlined color={this.props.theme.blueText} width={12} />{lang.yeniEntegrasyon}</MenuItem>
        </CustomMenu>
      </div>
    );
  }

  integrationExcelUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.props.uploadIntegrationExcel(e.target.files);
    }
  }
  /*
 *   ------------------///  INTEGRATION PAGE  ---------------------
 */

  /*
  *   ---------------------  INVENTORY PAGE  ---------------------
  */

  InventoriesButton = () => {
    const { lang } = this.state;
    const { history } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ excelMenu: e.currentTarget })}
          startIcon={isWidthDown('md', this.props.width) ? <Plus width={12} /> : <CloudExport color={this.props.theme.blueText} width={14} />}
        >
          {isWidthDown('md', this.props.width)
            ? 'Yeni'
            : 'Excel'}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.excelMenu}
          open={Boolean(this.state.excelMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ excelMenu: null })}
        >
          <>
            <MenuItem onClick={() => this.hiddenInput.current.click()}><CloudImport color={this.props.theme.blueText} />{lang.iceAktar}</MenuItem>
            <input onClick={(e) => { e.target.value = ''; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.excelUpload(e)} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
          </>
          <MenuItem onClick={() => this.excelDownlaod('', 'Envanter Listesi')}><CloudExport color={this.props.theme.blueText} width={14} />{lang.disaAktar}</MenuItem>
          <MenuItem onClick={() => this.excelDownlaod('/sample', 'Sample')}><CloudExport color={this.props.theme.blueText} width={14} />{lang.ornekIndir}</MenuItem>
          {isWidthDown('md', this.props.width) && <MenuItem onClick={() => { history.push('/InventoryRegister/register'); this.setState({ excelMenu: null }); }}><Plus width={14} color={Colors.purple} />{lang.yeniEnvanter}</MenuItem>}
        </CustomMenu>
      </div>
    );
  }

  HardwareInventoriesButton = () => {
    const { hardwareInventoryModalEnum } = SheetWithModalEnum;
    const { lang } = this.state;
    // const { history } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ excelMenu: e.currentTarget })}
          startIcon={isWidthDown('md', this.props.width) ? <Plus width={12} /> : <CloudExport color={this.props.theme.blueText} width={14} />}
        >
          {isWidthDown('md', this.props.width)
            ? 'Yeni'
            : 'Excel'}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.excelMenu}
          open={Boolean(this.state.excelMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ excelMenu: null })}
        >
          <>
            <MenuItem onClick={() => this.hiddenInput.current.click()}><CloudImport color={this.props.theme.blueText} />{lang.iceAktar}</MenuItem>
            <input onClick={(e) => { e.target.value = ''; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.excelUpload(e)} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
          </>
          <MenuItem onClick={() => this.excelDownlaod('', 'Envanter Listesi')}><CloudExport color={this.props.theme.blueText} width={14} />{lang.disaAktar}</MenuItem>
          <MenuItem onClick={() => this.excelDownlaod('/sample', 'Sample')}><CloudExport color={this.props.theme.blueText} width={14} />{lang.ornekIndir}</MenuItem>
          {isWidthDown('md', this.props.width) && <MenuItem onClick={() => { this.props.setAppConfig(hardwareInventoryModalEnum.NEW_HARDWARE, true); this.setState({ excelMenu: null }); }}><Plus width={14} color={this.props.theme.blueText} />{lang.yeniDonanim}</MenuItem>}
        </CustomMenu>
        {!isWidthDown('md', this.props.width) && <CustomButton onClick={() => { this.props.setAppConfig(hardwareInventoryModalEnum.NEW_HARDWARE, true); this.setState({ excelMenu: null }); }}><Plus width={14} color={this.props.theme.blueText} />{lang.yeniDonanim}</CustomButton>}
      </div>
    );
  }

  excelDownlaod = (type, fileName) => {
    this.props.excelDownload(type, fileName);
    this.setState({ excelMenu: null });
  }

  excelUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.props.excelUpload(e.target.files);
    }
    this.setState({ excelMenu: null });
  }

  /*
  *   ------------------///  INVENTORY PAGE  ---------------------
  */

  /*
  *   ---------------------  INVENTORY_DETAİL  ---------------------
  */

  inventoryDetailHeaderButton = () => {
    const { lang } = this.state;
    const { application } = this.props;
    return (
      <>
        <IconButton
          onClick={(e) => this.setState({ inventoryDetailMenu: e.currentTarget })}
        >
          <MoreVertIcon />
        </IconButton>
        <CustomMenu
          notArrow
          anchorEl={this.state.inventoryDetailMenu}
          open={Boolean(this.state.inventoryDetailMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ inventoryDetailMenu: null })}
        >
          {this.props.appConfig.disabled ? (
            <MenuItem
              onClick={() => this.handleEventProps({ disabled: false }, () => this.setState({ inventoryDetailMenu: null }))}
            >
              <EditNew color={Colors.grey} width="16" />
              {lang.duzenle}
            </MenuItem>
          )
            : (
              <>
                <MenuItem
                  onClick={() => this.handleEventProps({ controlModal: true }, () => this.setState({ inventoryDetailMenu: null }))}
                >
                  <EditNew color={Colors.grey} width="16" />
                  {lang.kaydet}
                </MenuItem>
                <MenuItem
                  onClick={() => this.handleEventProps({ disabled: true }, () => this.setState({ inventoryDetailMenu: null }))}
                >
                  {/* <EditNew color={Colors.grey} width="16" /> */}
                  {lang.vazgec}
                </MenuItem>
              </>
            )}
          {
            application?.follow
              ? (
                <MenuItem onClick={() => this.handleFollow(1)}>
                  <Minus color={Colors.grey} width={16} /> Takipten Çık
                </MenuItem>
              )
              : (
                <MenuItem onClick={() => this.handleFollow(0)}>
                  <Plus color={Colors.grey} width={16} /> Takip Et
                </MenuItem>
              )
          }
          <MenuItem
            onClick={() => window.print()}
          >
            <Printer width={16} />
            {lang.ciktiAl}
          </MenuItem>
        </CustomMenu>
      </>
    );
  }

  handleFollow = (type) => {
    const appId = this.props.history.location.pathname.split('/')[2];
    this.props.followApplication(appId, type);
  }

  /*
  *   ------------------///  INVENTORY_DETAİL  ---------------------
  */

  /*
  *   ---------------------  DASHBOARD_PAGE  ---------------------
  */
  dashboardEditButton = () => <CustomButton onClick={() => this.handleEventProps({ editDashboard: !this.props.appConfig.editDashboard })}>{this.props.appConfig.editDashboard ? <><Save width={14} />{this.state.lang.kaydet} </> : isWidthDown('xs', this.props.width) ? <><Pen width={14} /> {this.state.lang.duzenle} </> : <><Pen width={14} /> {this.state.lang.dashboardDuzeni} </>}</CustomButton>
  /*
  *   ------------------///  DASHBOARD_PAGE  ---------------------
  */

  /*
   *   ---------------------  HARDWARE REGİSTER  ---------------------
  */
  HardwareRegisterButtonMenu = () => {
    const { lang } = this.state;
    return (
      <>
        <IconButton
          onClick={(e) => this.setState({ inventoryDetailMenu: e.currentTarget })}
        >
          <MoreVertIcon />
        </IconButton>
        <CustomMenu
          notArrow
          anchorEl={this.state.inventoryDetailMenu}
          open={Boolean(this.state.inventoryDetailMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ inventoryDetailMenu: null })}
        >
          <MenuItem>
            <DescriptionIcon style={{ width: '16px' }} />
            {lang.dokuman}
          </MenuItem>
        </CustomMenu>
      </>
    );
  }

  HardwareRegisterSwitchHeader = () => {
    const { hardwareUnitMenu } = this.state;
    const { history } = this.props;
    const typeIndex = history.location.pathname.split('/')[2];
    const unitIndex = history.location.pathname.split('/')[3];
    const unitArray = hardwareUnitSelect.filter((typeSelect) => typeSelect.typeID.toString() === typeIndex && typeSelect);

    return (
      <>
        <HeaderHardwareSelectButton onClick={(event) => this.setState({ hardwareUnitMenu: event.currentTarget })}>
          {unitArray && unitArray.find((c) => c.id.toString() === unitIndex).name}
          <ExpandMoreOutlinedIcon style={{ color: this.props.theme.blueText }} />
        </HeaderHardwareSelectButton>
        <CustomMenu
          id="hardwareUnitMenu"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={hardwareUnitMenu}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(hardwareUnitMenu)}
          onClose={() => this.setState({ hardwareUnitMenu: null })}
          TransitionComponent={Fade}
        >
          {unitArray.map((item) => <MenuItem onClick={() => this.setState({ hardwareUnitMenu: null }, () => history.push(`/HardwareRegister/${typeIndex}/${item.id}`))}>{item.name}</MenuItem>)}
        </CustomMenu>
      </>
    );
  }
  /*
   *   ---------------------///  HARDWARE REGİSTER  ---------------------
  */

  /*
   *   ---------------------  HARDWARE DETAİL  ---------------------
  */
  hardwareDetailHeaderButton = () => {
    // const { inventoryDetailModalEnum } = SheetWithModalEnum;
    const { lang } = this.state;
    const { application } = this.props;
    return (
      <>
        <IconButton
          onClick={(e) => this.setState({ inventoryDetailMenu: e.currentTarget })}
        >
          <MoreVertIcon />
        </IconButton>
        <CustomMenu
          notArrow
          anchorEl={this.state.inventoryDetailMenu}
          open={Boolean(this.state.inventoryDetailMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ inventoryDetailMenu: null })}
        >
          {this.props.appConfig.disabled ? (
            <MenuItem>
              <EditNew color={Colors.grey} width="16" />
              {lang.duzenle}
            </MenuItem>
          )
            : (
              <>
                <MenuItem>
                  <EditNew color={Colors.grey} width="16" />
                  {lang.kaydet}
                </MenuItem>
                <MenuItem
                  onClick={() => this.handleEventProps({ disabled: true }, () => this.setState({ inventoryDetailMenu: null }))}
                >
                  {/* <EditNew color={Colors.grey} width="16" /> */}
                  {lang.vazgec}
                </MenuItem>
              </>
            )}
          {
            application?.follow
              ? (
                <MenuItem onClick={() => this.handleFollow(1)}>
                  <Minus color={Colors.grey} width={16} /> Takipten Çık
                </MenuItem>
              )
              : (
                <MenuItem onClick={() => this.handleFollow(0)}>
                  <Plus color={Colors.grey} width={16} /> Takip Et
                </MenuItem>
              )
          }
          <MenuItem
            onClick={() => window.print()}
          >
            <Printer width={16} />
            {lang.ciktiAl}
          </MenuItem>
          <MenuItem>
            <File width={16} />
            {lang.lisansIslemleri}
          </MenuItem>
        </CustomMenu>
      </>
    );
  }

  handleFollow = (type) => {
    const appId = this.props.history.location.pathname.split('/')[2];
    this.props.followApplication(appId, type);
  }
  /*
   *   ---------------------///  HARDWARE DETAİL  ---------------------
  */

  /*
  *   ---------------------  CustomHeader  ---------------------
  */

  //  ---------------------///  COST  ---------------------

  costButtons = () => {
    const { lang } = this.state;
    const { history } = this.props;
    return (
      <div className="headerButton">
        <CustomButton
          onClick={(e) => this.setState({ excelMenu: e.currentTarget })}
          startIcon={isWidthDown('md', this.props.width) ? <Plus width={12} /> : <CloudExport color={this.props.theme.blueText} width={14} />}
        >
          {isWidthDown('md', this.props.width)
            ? 'Yeni'
            : 'Excel'}
        </CustomButton>
        <CustomMenu
          anchorEl={this.state.excelMenu}
          open={Boolean(this.state.excelMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ excelMenu: null })}
        >
          {isWidthDown('md', this.props.width)
            && (
              <>
                <ModuleSwitch software>
                  <MenuItem onClick={() => { history.push('/CostingRegister/software'); this.setState({ excelMenu: null }); }}><Plus width={14} color={Colors.purple} />{lang.yeniMasraf} / {tra('Yazilim')}</MenuItem>
                </ModuleSwitch>
                <ModuleSwitch hardware>
                  <MenuItem onClick={() => { history.push('/CostingRegister/hardware'); this.setState({ excelMenu: null }); }}><Plus width={14} color={Colors.purple} />{lang.yeniMasraf} / {tra('Donanim')}</MenuItem>
                </ModuleSwitch>
              </>
            )}
        </CustomMenu>
      </div>
    );
  }

  //   ---------------------///  COST  ---------------------

  homePage = () => this.headerSwitchRender({
    title: this.state.lang.genelBakis,
    right: this.dashboardEditButton(),
  })

  inventoryPage = () => this.headerSwitchRender({
    title: this.state.lang.envanter,
    right: this.InventoriesButton(),
  })

  hardwareInventoryPage = () => this.headerSwitchRender({
    title: this.state.lang.donanim_envanteri,
    right: this.HardwareInventoriesButton(),
  })

  inventoryDetailPage = () => this.headerSwitchRender({
    title: this.state.lang.envanterDetay,
    right: this.inventoryDetailHeaderButton(),
    notification: false,
  })

  inventoryRegisterPage = () => this.headerSwitchRender({
    title: this.state.lang.yeniEnvanterGirisi,
  })

  adminPage = () => this.headerSwitchRender({
    title: this.state.lang.Yonetici_Ekrani,
  })

  schedulePage = () => this.headerSwitchRender({
    title: this.state.lang.takvim,
  })

  notificationPage = () => this.headerSwitchRender({
    title: this.state.lang.bildirimEkrani,
  })

  adminSurveyPage = () => this.headerSwitchRender({
    title: this.state.lang.Anket_Olustur,
  })

  surveyPage = () => this.headerSwitchRender({
    title: this.state.lang.Anketler,
  })

  reportPage = () => this.headerSwitchRender({
    title: this.state.lang.raporlar,
  })

  surveyAnswerPage = () => {
    const { assignedSurveys } = this.props;
    const match = {
      id: this.props.history.location.pathname.split('/')[3],
      appId: this.props.history.location.pathname.split('/')[4],
      isSelectedApp: this.props.history.location.pathname.split('/')[5],
    };
    const selectedSurvey = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.id, 10));
    const selectedApp = assignedSurveys.length > 0 && assignedSurveys.find((item) => item.id === parseInt(match.id, 10)).SurveyCounts.find((app) => app.id === parseInt(match.appId, 10));
    return this.headerSwitchRender({
      left: <IconButton onClick={() => this.handleReturn()}><ChevronLeft /></IconButton>,
      right: selectedSurvey.active !== 0 && selectedApp.situation !== 1 && <IconButton onClick={() => { this.handleSaveSurvey(2); }}><BookmarkBorderIcon /></IconButton>,
      notification: false,
    });
  }

  integrationPage = () => this.headerSwitchRender({
    title: this.state.lang.entegrasyonHaritasi,
    right: this.IntegrationButton(),
  })

  profilSettingPage = () => this.headerSwitchRender({
    title: this.state.lang.kullaniciAyarlari,
  })

  profilPage = () => this.headerSwitchRender({
    title: this.state.lang.profil,
  })

  surveyDetailAppsPage = () => this.headerSwitchRender({
    left: <IconButton onClick={() => this.props.history.goBack()}><ChevronLeft /></IconButton>,
  })

  surveyChartDetailPage = () => this.headerSwitchRender({
    left: <IconButton onClick={() => this.props.history.goBack()}><ChevronLeft /></IconButton>,
  })

  surveyDetailPage = () => this.headerSwitchRender({
    left: <IconButton onClick={() => this.props.history.goBack()}><ChevronLeft /></IconButton>,
  })

  hardwareRegisterPage = () => this.headerSwitchRender({
    left: <IconButton onClick={() => this.props.history.push('/HardwareInventories')}><ChevronLeft /></IconButton>,
    title: this.HardwareRegisterSwitchHeader(),
    right: this.HardwareRegisterButtonMenu(),
    notification: false,
  })

  hardwareDetailPage = () => {
    const { devicesDetail } = this.props;
    const deviceName = devicesDetail && hardwareUnitSelect.find((item) => item.id === devicesDetail.hardware_category_id) && hardwareUnitSelect.find((item) => item.id === devicesDetail.hardware_category_id).name;
    return this.headerSwitchRender({
      left: <IconButton onClick={() => this.props.history.goBack()}><ChevronLeft /></IconButton>,
      title: `${this.state.lang.donanim_detay} > ${deviceName}`,
      right: this.hardwareDetailHeaderButton(),
      notification: false,
    });
  }

  costingPage = () => this.headerSwitchRender({
    title: this.state.lang.masraf,
    right: this.costButtons(),
  })

  costingDetailPage = () => this.headerSwitchRender({
    title: this.state.lang.masrafDetay,
    notification: false,
  })

  costingRegisterPage = () => this.headerSwitchRender({
    title: this.state.lang.yeniMasraf,
  })

  licenseFollowPage = () => this.headerSwitchRender({
    title: tra('Lisans_Takibi'),
    right: <CustomButton onClick={() => this.props.history.push('/LicenseFollowRegister')} startIcon={<Plus width={12} />}>{tra('Ekle')}</CustomButton>,
    notification: false,
  })

  licenseFollowRegisterPage = () => this.headerSwitchRender({
    title: tra('Lisans_Takibi_Kayit'),
    left: <IconButton onClick={() => this.props.history.goBack()}><ChevronLeft /></IconButton>,
    notification: false,
  })

  licenseFollowDetailPage = () => this.headerSwitchRender({
    title: tra('Lisans_Takibi_Detay'),
    left: <IconButton onClick={() => this.props.history.goBack()}><ChevronLeft /></IconButton>,
    notification: false,
  })

  /*
  *   ------------------///  Custom Header  ---------------------
  */

  headerSwitch = (route) => {
    switch (route) {
      case pageShow.HOME: return this.homePage();
      case pageShow.ADMİN: return this.adminPage();
      case pageShow.SCHEDULE: return this.schedulePage();
      case pageShow.INVENTORY: return this.inventoryPage();
      case pageShow.COSTING: return this.costingPage();
      case pageShow.REPORTS: return this.reportPage();
      // case pageShow.HARDWARE_INVENTORY: return this.hardwareInventoryPage();
      case pageShow.INVENTORY_REGISTER: return this.inventoryRegisterPage();
      case pageShow.NOTIFICATION: return this.notificationPage();
      case String(route.match(pageShow.INVENTORY_DETAIL_REGEX)): return this.inventoryDetailPage();
      case String(route.match(pageShow.SURVEY_ANSWER_REGEX)): return this.surveyAnswerPage();
      case pageShow.ADMIN_SURVEY: return this.adminSurveyPage();
      case pageShow.SURVEY: // (/Surveys || /Surveys/[number])
      case String(route.match(pageShow.SURVEY_REGEX)): return this.surveyPage();
      case pageShow.INTEGRATION: return this.integrationPage();
      case pageShow.PROFIL_SETTİNG: return this.profilSettingPage();
      case pageShow.PROFIL: return this.profilPage();
      case String(route.match(pageShow.SURVEY_DETAIL_APPS_REGEX)): return this.surveyDetailAppsPage();
      case String(route.match(pageShow.SURVEY_CHART_DETAIL_REGEX)): return this.surveyChartDetailPage();
      case String(route.match(pageShow.SURVEY_DETAIL_REGEX)): return this.surveyDetailPage();
      case String(route.match(pageShow.HARDWARE_DETAIL_REGEX)): return this.hardwareDetailPage();
      case String(route.match(pageShow.HARDWARE_REGISTER_REGEX)): return this.hardwareRegisterPage();
      case String(route.match(pageShow.COSTING_REGISTER_REGEX)): return this.costingRegisterPage();
      case String(route.match(pageShow.COSTING_DETAIL_REGEX)): return this.costingDetailPage();
      case pageShow.LICENSE_FOLLOW: return this.licenseFollowPage();
      case pageShow.LICENSE_FOLLOW_REGISTER: return this.licenseFollowRegisterPage();
      case String(route.match(pageShow.LICENSE_FOLLOW_DETAIL_REGEX)): return this.licenseFollowDetailPage();
      default: return '';
    }
  }

  headerSwitchRender = (props) => {
    const {
      title, left, right, notification,
    } = props;
    return (
      <>
        <MobileHeader>
          <div className="left">
            {left || this.hamburgerButton()}
            {title && <h1>{title}</h1>}
          </div>
          <div className="right">
            {right}
            {notification !== false && this.props.notificationsComponent}
          </div>
        </MobileHeader>
        {this.props.addCompanyModalComponent}
      </>
    );
  }

  /*
  *   ---------------------  base component  ---------------------
  */

  hamburgerButton = () => (
    <IconButton onMouseDown={() => this.setState({ drawerMenu: true })}>
      <MenuIcon />
    </IconButton>
  )

  handleEventProps = (arg, callback) => {
    const [key, value] = [Object.keys(arg).toString(), ...Object.values(arg)];
    if (callback) callback();
    return this.props.setAppConfig(key, value);
  }

  /*
*   ------------------///  base component  ---------------------
*/

  render() {
    const { drawerMenu } = this.state;
    const { history } = this.props;
    return (
      <>
        {this.headerSwitch(history.location.pathname)}
        <SwipeableDrawer
          anchor="left"
          open={drawerMenu}
          onClose={() => this.setState({ drawerMenu: false })}
          onOpen={() => this.setState({ drawerMenu: true })}
        >
          <MobileDrawer setTheme={() => this.props.setTheme()} drawerClose={() => this.setState({ drawerMenu: false })} />
        </SwipeableDrawer>
        <div className="placeholder" style={{ height: '70px' }} />
      </>
    );
  }
}

DisplayMobileHeader.propTypes = {
  setAppConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  setTheme: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  excelUpload: PropTypes.func.isRequired,
  excelDownload: PropTypes.func.isRequired,
  getIntegrationExcel: PropTypes.func.isRequired,
  uploadIntegrationExcel: PropTypes.func.isRequired,
  surveyQuestions: PropTypes.array.isRequired,
  answerSurvey: PropTypes.func.isRequired,
  assignedSurveys: PropTypes.array.isRequired,
  surveyBookmark: PropTypes.array.isRequired,
  application: PropTypes.object.isRequired,
  devicesDetail: PropTypes.object.isRequired,
  notificationsComponent: PropTypes.node.isRequired,
  addCompanyModalComponent: PropTypes.node.isRequired,
  followApplication: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  surveyQuestions: selectSurveyQuestions(),
  assignedSurveys: selectAssignedSurveys(),
  application: selectApplicationDetail(),
  surveyBookmark: selectSurveyAnswerBookmark(),
  appConfig: selectAppConfig(),
  devicesDetail: selectHardwareDeviceDetail(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setAppConfig,
      excelUpload,
      excelDownload,
      answerSurvey,
      followApplication,
      getIntegrationExcel,
      uploadIntegrationExcel,
    }, dispatch)
  )
);

const DisplayMobileHeaderWithTheme = withTheme(DisplayMobileHeader);
const DisplayMobileHeaderWithWidth = withWidth()(DisplayMobileHeaderWithTheme);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DisplayMobileHeaderWithWidth));

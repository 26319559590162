import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import ReactCrop from 'react-image-crop';
import {
  DialogContent, Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withTheme } from 'styled-components';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  Colors,
} from '../../assets/statics';
import { uploadProfile } from '../../store/actions';
import 'react-image-crop/dist/ReactCrop.css';
import { tra } from '../../commons/utils';
import {
  BottomSheetWrapper, CustomButton, CustomModal, ModalContent, ModalHeader, PhotoBoxWrapper,
} from '../../assets/styledNew';
import BottomSheet from '../BottomSheet';

class ProfilePhotoChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1,
      },
      tempBlob: null,
      lang: {
        profilFotoDegistir: tra('Profil_Foto_Degistir'),
        fotografOnizleme: tra('Fotograf_Onizleme'),
        yukle: tra('Yukle'),
        kaydet: tra('Kaydet'),
      },
    };
    this.hiddenInput = React.createRef();
  }

  onExit = () => {
    this.setState({
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1,
      },
      tempBlob: null,
    });
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    // percentCrop diye ikinci bir propu var
    this.setState({ crop });
  };

  handleSaveImage = () => {
    const { tempBlob } = this.state;
    const file = new File([tempBlob], 'filename.jpg', { type: 'image/jpeg' });
    // aspect 1/1
    this.props.uploadProfile(this.props.user.id, file);
    this.setState({ src: null, tempBlob: null });
    this.props.close();
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        const tempBlob = blob;
        tempBlob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(tempBlob);
        resolve(this.fileUrl);
        this.setState({ tempBlob });
      }, 'image/jpeg');
    });
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { crop, src, lang } = this.state;
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1 style={{ padding: '10px 0px' }}>{tra('Sirket_Logo_Degistir')}</h1>
          <Close onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={4}>
              <PhotoBoxWrapper>
                {
                  src
                    ? (
                      <div className="cropWrapper">
                        <ReactCrop
                          imageStyle={{ maxHeight: '100%' }}
                          src={src}
                          crop={crop}
                          ruleOfThirds
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                        />
                      </div>
                    ) : (
                      <div className="emptyBox">
                        <p>{lang.fotografOnizleme}</p>
                      </div>
                    )
                }
              </PhotoBoxWrapper>
            </Grid>
            <Grid container justify="space-between" item xs={12}>
              <CustomButton opacity onClick={() => this.onExit()} color={Colors.blue}>{tra('Vazgec')}</CustomButton>
              <div>
                <>
                  <CustomButton style={{ marginRight: 10 }} onClick={() => this.hiddenInput.current.click()} color={Colors.blue}>{lang.yukle}</CustomButton>
                  <input onClick={(e) => { e.target.value = ''; }} ref={this.hiddenInput} onChange={this.onSelectFile} style={{ display: 'none' }} type="file" accept="image/*" />
                </>
                <CustomButton onClick={() => this.handleSaveImage()} color={Colors.blue}>{lang.kaydet}</CustomButton>
              </div>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} onExit={() => this.onExit()} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.onExit(); }}>{this.contentPage()}</BottomSheet>

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );
    }
  }

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

ProfilePhotoChange.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  uploadProfile: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      uploadProfile,
    }, dispatch)
  )
);

const ProfilePhotoChangeWithTheme = withTheme(ProfilePhotoChange);
const ProfilePhotoChangeWithWidth = withWidth()(ProfilePhotoChangeWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePhotoChangeWithWidth);

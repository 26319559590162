import inventoryPages from '../public/publicInventory';
import { ServerAccessory } from '../../statics';
import { tra } from '../../../commons/utils';

const secondPagesRow_2_column_1 = [{
  subtitle: tra('Ram'),
  addGroupName: 'Memories',
  groupValidateCheck: true,
  addButton: true,
  addGroupIndex: 0,
  inputArray: [
    // {
    //   type: 'text',
    //   title: tra('Adi'),
    //   name: 'name',
    //   value: 'example name',
    // },
    {
      type: 'text',
      title: tra('Boyutu'),
      name: 'capacity',
      value: '5 GB',
      validateRules: 'groupInputControl',
    },
    {
      type: 'select',
      title: tra('Tipi'),
      name: 'memory_type_id',
      value: 1,
      data: 'memories',
      inputProps: {
        emptyItem: true,
      },
      validateRules: 'groupInputControl',

    },
    {
      type: 'text',
      title: tra('Modeli'),
      name: 'model',
      value: 'deneme model',
      validateRules: 'groupInputControl',

    },
    {
      type: 'text',
      title: tra('Seri_No'),
      name: 'serial_no',
      value: 1,
      validateRules: 'groupInputControl',

    },
    {
      type: 'select',
      title: tra('Ureticisi_'),
      name: 'producer_id',
      value: 1,
      data: 'producers',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
  ],
}];
const secondPagesRow_2_column_2 = [{
  subtitle: tra('Hard_Diskler'),
  addGroupName: 'HardDisks',
  groupValidateCheck: true,
  addButton: true,
  addGroupIndex: 0,
  inputArray: [
    // {
    //   type: 'text',
    //   title: tra('Ismi'),
    //   name: 'name',
    //   value: 'deneme harddisk',
    // },
    {
      type: 'text',
      title: tra('Model'),
      name: 'model',
      value: '',
      validateRules: 'groupInputControl',
    },
    {
      type: 'text',
      title: tra('Seri_No'),
      name: 'serial_no',
      value: '111-AF-222',
    //   validateRules: 'groupInputControl',
    },
    {
      type: 'select',
      title: tra('Uretici'),
      name: 'producer_id',
      value: 1,
      data: 'producers',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
    {
      type: 'text',
      title: tra('Kapasite'),
      name: 'capacity',
      value: '8 GB',
      validateRules: 'groupInputControl',
    },
    {
      type: 'text',
      title: tra('Okuma_Yazma_Hizi'),
      name: 'read_speed',
      value: '8 GB',
      validateRules: 'groupInputControl',
    },
    {
      type: 'select',
      title: tra('Tur'),
      name: 'disk_type',
      value: '8 GB',
      data: 'DiskTypes',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
    {
      type: 'select',
      title: tra('Soket_Tipi'),
      name: 'socket_type',
      value: '8 GB',
      data: 'SocketTypes',
      validateRules: 'groupInputControl',
      inputProps: {
        emptyItem: true,
      },
    },
  ],
},
];

const secondPagesRow_3_column_1 = [
  {
    subtitle: tra('Ekran_Karti'),
    addGroupName: 'GraphicCard',
    groupValidateCheck: true,
    addButton: true,
    addGroupIndex: 0,
    inputArray: [
      {
        type: 'text',
        title: tra('Kapasite'),
        name: 'capacity',
        value: '',
      },
      {
        type: 'text',
        title: tra('Marka'),
        name: 'brand',
        value: '111-AF-222',
      },
      {
        type: 'text',
        title: tra('Model'),
        name: 'model',
        value: '111-AF-222',
      },
    ],
  },
];

const secondPages = [
  [
    {
      subtitle: tra('Cihaz_Detaylari'),
      // spacerTitle: true,
      objectGroupName: 'LastUserComputer',
      inputArray: [
        {
          type: 'text',
          title: tra('Bilgisayar_Ismi'),
          name: 'name',
          value: 1,
        //   validateRules: 'required',
        },
        // {
        //   type: 'text',
        //   title: tra('Servis_Etiketi'),
        //   name: 'service_brand',
        //   value: 'test makine',
        // validateRules: 'required',
        // },
        {
          type: 'text',
          title: tra('Islemci_Modeli'),
          name: 'model',
          value: 1,
        //   validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Islemci_Bilgisi'),
          name: 'processor_info',
          value: 1,
        //   validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Islemci_Cekirdek_Bilgisi'),
          name: 'processor_core_info',
          value: 1,
        //   validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Islemci_Hizi'),
          name: 'processor_processing_speed',
          value: 1,
        //   validateRules: 'required',
        },
      ],
    }],
  [
    {
      spacerTitle: true,
      objectGroupName: 'LastUserComputer',
      inputArray: [
        {
          type: 'number',
          title: tra('Cekirdek_Sayisi'),
          name: 'process_core_number',
          value: 1,
        //   validateRules: 'required',
        },
      ],
    },
    {
      // subtitle: '',
    //   spacerTitle: true,
      objectGroupName: 'OperatingSystem',
      inputArray: [
        {
          type: 'select',
          title: tra('Isletim_Sistemi'),
          name: 'os_producer_id',
          value: 1,
          data: 'OSPlatforms',
          inputProps: {
            emptyItem: true,
          },
        //   validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Isletim_Sistemi_Surumu'),
          name: 'os_version',
          value: 'OS X',
        //   validateRules: 'required',
        },
        {
          type: 'text',
          title: tra('Paket_Surumu'),
          name: 'service_pack',
          value: 'BIG-SUR',
        //   validateRules: 'required',
        },

      ],
    },
    {
      inputArray: [
        {
          setDefinedName: 'ServerAccessories_defined',
          noService: true,
          type: 'autoComplete',
          title: tra('Aksesuarlar'),
          name: 'ServerAccessories',
          data: ServerAccessory,
          // value: 'BIG-SUR',

        },
      ],
    },
  ],
  secondPagesRow_2_column_1,
  secondPagesRow_2_column_2,
  secondPagesRow_3_column_1,

];

const thirdPAgesRow_1_column_1 = [
  {
    title: tra('Girdi_Cikti_Cihazlar'),
    inputArray: [],
  },
];
const thirdPAgesRow_1_column_2 = [
  {
    spacerTitle: true,
    inputArray: [],
  },
];
const thirdPAgesRow_2_column_1 = [
  {
    definedName: 'ServerAccessories_defined.2',
    subtitle: tra('Klavye'),
    objectGroupName: 'Keyboard',
    removeButton: true,
    // validateRules: 'required',
    inputArray: [
      {
        type: 'text',
        title: tra('Klavye_Type'),
        name: 'device_type',
        value: 'Keyboard',
        validateRules: 'required',
        data: 'DeviceTypes',
        inputProps: {
          emptyItem: true,
        },
      },
      // {
      //   type: 'text',
      //   title: tra('Klavye_Turu'),
      //   name: 'device_type',
      //   value: 2, // klavye olduğu anlamına geliyor 2 kullanıcının bu alanı doldurmasına gerek arka tarafta servise gönderilmesi gerekiyor
      // },
      {
        type: 'select',
        title: tra('Klavye_Uretici'),
        name: 'producer_id',
        value: 1,
        data: 'producers',
        // validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  },
];

const thirdPAgesRow_2_column_2 = [
  {
    // defined: true,
    definedName: 'ServerAccessories_defined.1',
    subtitle: tra('Mouse'),
    objectGroupName: 'Mouse',
    removeButton: true,
    // validateRules: 'required',
    inputArray: [
      {
        type: 'text',
        title: tra('Mouse_Type'),
        name: 'device_type',
        value: 'Mouse',
        validateRules: 'required',
        // data: 'DeviceTypes',
        // inputProps: {
        //   emptyItem: true,
        // },
      },
      // {
      //   type: 'text',
      //   title: tra('Mouse_Turu'),
      //   name: 'device_type', // id mouse anlamına geliyor. gizlenmesi lazım
      //   value: 1,
      // },
      {
        type: 'select',
        title: tra('Mouse_Uretici'),
        name: 'producer_id',
        value: 1,
        data: 'producers',
        // validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  },
];

const thirdPAgesRow_3_column_1 = [
  {
    // defined: true,
    definedName: 'ServerAccessories_defined.3',
    subtitle: tra('Monitor'),
    addGroupName: 'Monitor',
    addButton: true,
    addGroupIndex: 0,
    removeButton: true,
    inputArray: [
      {
        type: 'text',
        title: tra('Seri_No'),
        name: 'serial_no',
        value: '4354-DERF-4343',
        // validateRules: 'required',

      },
      {
        type: 'select',
        title: tra('Uretici'),
        name: 'producer_id',
        value: 1,
        data: 'producers',
        // validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
      {
        type: 'select',
        title: tra('Cozunurluk'),
        name: 'resolution',
        value: '1920 X 1080',
        data: 'Resolutions',
        // validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
      {
        type: 'select',
        title: tra('Monitor_Turu'),
        name: 'monitor_type', // değişecek
        value: '1920 X 1080',
        data: 'MonitorTypes',
        // validateRules: 'required',
        inputProps: {
          emptyItem: true,
        },
      },
    ],
  },
];

const thirdPages = [
  thirdPAgesRow_1_column_1,
  thirdPAgesRow_1_column_2,
  thirdPAgesRow_2_column_1,
  thirdPAgesRow_2_column_2,
  thirdPAgesRow_3_column_1,
];

export default [inventoryPages, secondPages, thirdPages];

/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  CustomModal,
  CustomButton,
  CustomUpdateWarnModal,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import Input from '../Input';
import BottomSheet from '../BottomSheet';
import { WarningIcon } from '../../assets/icons';
import { changeAppStatus } from '../../store/actions';

class InventoryStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statuTime: new Date(),
      comment: '',
    };
  }

  changeStatus = () => {
    const { appId, statu } = this.props;
    const { comment, statuTime } = this.state;
    const data = {
      statu,
      statu_time: statuTime,
      comment,
    };
    this.props.changeAppStatus(appId, data);
    this.props.close();
  }

  contentPage = () => {
    const { statuTime } = this.state;
    const {
      close, statu,
    } = this.props;
    return (
      <CustomUpdateWarnModal className="content">
        <div className="header">
          <CloseIcon onClick={close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </div>
        <div className="content">
          <div className="iconWrapper">
            <WarningIcon />
            <div className="messageContent">
              <h3>{tra('Information')}</h3>
              <p>{tra('Statu')} {statu === 2 ? tra('Aktif') : (statu === 1 ? tra('Pasif') : tra('Stopped'))}. {tra('Select_Date_To_Continue_Message')}</p>
            </div>
          </div>
          <div className="inputList">
            <Input
              type="date"
              title={tra('Yil_Secimi')}
              value={statuTime}
              onChange={(e) => this.setState({ statuTime: new Date(e) })}
            />
          </div>
        </div>
        <div className="actions">
          <CustomButton opacity onClick={close}>{tra('Vazgec')}</CustomButton>
          <CustomButton onClick={this.changeStatus}>{tra('Tamamla')}</CustomButton>
        </div>
      </CustomUpdateWarnModal>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

InventoryStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  changeAppStatus: PropTypes.func.isRequired,
  appId: PropTypes.any.isRequired,
  statu: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      changeAppStatus,
    }, dispatch)
  )
);

const InventoryStatusModalWithTheme = withTheme(InventoryStatusModal);
const InventoryStatusModalWithWidth = withWidth()(InventoryStatusModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryStatusModalWithWidth);

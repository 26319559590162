/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Check,
  Close, Delete, DeleteOutline, ErrorOutline, MoreVert, Public, UnfoldMore, // Remove,
} from '@material-ui/icons';
import {
  Drawer, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Colors } from '../../assets/statics';
import {
  DocumentPDF, EditNew, Plus, SaveNew, HistoryIcon,
} from '../../assets/icons';
import {
  getHWCostingDetail, setHWCostingDetail, updateHWCosting, setToast,
  setCostingFileUrls, deleteHWCosting, getHWCostingCategories,
  getHardwareDevices, getHWCostingExpenses, getHWCostingList, deleteHWCostFile,
} from '../../store/actions';
import {
  selectHWCostingExpenses, selectInfos, selectActiveUser, selectCostingFileUrls,
  selectVendorUsers, selectHWCostingDetail, selectHWCostingCategories, selectHWCostingList, selectHardwareDevices, selectHWCostingLogs,
} from '../../store/selectors';
import {
  CommaStringToNumber, encryptTokenLink, FormatCurrency,
  tra,
} from '../../commons/utils';
import Logs from '../../components/Logs';
import {
  InputWrapper, PageWrapper, PageContent, CustomButton, GridPageWrapper, ActionFooter, CustomMenu,
} from '../../assets/styledNew';
import AllocationPie from '../../components/charts/AllocationPie';
import { HWCostingContext } from '../../commons/context/HWCostingContext';
import CostingDocModal from '../../components/modals/CostingDocModal';
import AddHWToContract from '../../components/modals/AddHWToContract';
import FastInput from '../../components/costing/HWCostingFastInput';
import Validator from '../../commons/validator';
import UpdateWarnModal from '../../components/modals/UpdateWarnModal';
import DeleteModal from '../../components/modals/DeleteModal';

class HWCostingDetail extends Component {
  static contextType = HWCostingContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      costId: '',
      logDrawer: false,
      disabled: true,
      controlModal: false,
      compCurrency: '',
      contractsArr: [],
      fileUrlArr: [],
      removeCheckClick: false,
    };
    props.getHWCostingCategories();
    props.getHardwareDevices();
    props.getHWCostingExpenses();
    props.getHWCostingList();
  }

  componentDidUpdate(prevProps, prevState) {
    const costId = this.props.match.params.id;
    const { setHWCostingState, setHWCostingPageVariables, HWCostingPageVariables } = this.context;
    const {
      hwCostingDetail, hardwares,
    } = this.props;
    if (hardwares.length > 0 && hwCostingDetail && hwCostingDetail.id && (hwCostingDetail.id.toString() !== costId || hwCostingDetail.id.toString() !== prevState.costId.toString())) {
      const costingHardwares = hardwares.filter((item) => hwCostingDetail.HardwareInventories.find((hw) => item.id === hw.id));
      const firstDataCostUrls = hwCostingDetail.HwContractUrls.map((item) => item.url);
      const tempGenericContItems = [];
      for (let i = 0; i < hwCostingDetail.HwGenericContItems.length; ++i) {
        const obj = {
          monthly_inv: hwCostingDetail.HwGenericContItems[i].monthly_inv,
          total_inv: hwCostingDetail.HwGenericContItems[i].total_inv,
          total_cost: hwCostingDetail.HwGenericContItems[i].total_cost,
          monthly_cost: hwCostingDetail.HwGenericContItems[i].monthly_cost,
          invCurr_type: hwCostingDetail.HwGenericContItems[i].invCurr_type,
          period: hwCostingDetail.HwGenericContItems[i].period,
          contract_start: hwCostingDetail.HwGenericContItems[i].contract_start,
          contract_end: hwCostingDetail.HwGenericContItems[i].contract_end,
          calculated_end: hwCostingDetail.HwGenericContItems[i].calculated_end,
          purchase_date: hwCostingDetail.HwGenericContItems[i].purchase_date,
          fx_rate: hwCostingDetail.HwGenericContItems[i].fx_rate,
          tco_agg: hwCostingDetail.HwGenericContItems[i].tco_agg,
        };
        tempGenericContItems.push(obj);
      }
      const newData = {
        ...hwCostingDetail,
        HardwareInventoryContract: costingHardwares,
        HwContractUrls: firstDataCostUrls,
        HwGenericContItems: tempGenericContItems,
      };
      this.props.setCostingFileUrls(firstDataCostUrls);
      const newSendHardwares = [];
      let allocTotal = 0;
      for (let i = 0; i < hwCostingDetail.HardwareInventories.length; ++i) {
        const newObj = {
          inventory_id: hwCostingDetail.HardwareInventories[i].id,
          allocation: hwCostingDetail.HardwareInventories[i].allocation,
          value: hwCostingDetail.HardwareInventories[i].value,
          value_inv: 1,
        };
        allocTotal += HWCostingPageVariables.hardwareValueStatus === 2 ? hwCostingDetail.HardwareInventories[i].allocation : hwCostingDetail.HardwareInventories[i].value;
        newSendHardwares.push(newObj);
      }
      setHWCostingState(newData);
      setHWCostingPageVariables((prvState) => ({
        ...prvState,
        allocationTotal: allocTotal,
        sendHardwares: newSendHardwares,
        addHWModal: false,
        costingDocModal: false,
      }));
      this.setState({
        costId: hwCostingDetail.id,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const paramCostId = props.match.params.id;
    const { hwCostingDetail, hwCostingCategories, hwCostingList } = props;
    if (hwCostingList.length > 0 && hwCostingList.filter((item) => item.id.toString() === paramCostId.toString()).length === 0) {
      props.setHWCostingDetail(null);
      window.location.href = '#/404';
      return null;
    }
    if (paramCostId !== undefined && Object.keys(props.hwCostingDetail)?.length && props.hwCostingDetail.id !== parseInt(paramCostId, 10)) {
      props.getHWCostingDetail(paramCostId);
    }
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.name;
      return {
        compCurrency: currency,
      };
    }
    if (hwCostingDetail && hwCostingDetail.category_id && hwCostingCategories.length > 0 && state.contractsArr.length === 0) {
      return {
        contractsArr: hwCostingCategories.find((item) => item.id === hwCostingDetail.category_id).HwCostingTypes,
        fileUrlArr: hwCostingDetail.HwContractUrls.map((item) => item.url),
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({
      costId: '',
      logDrawer: false,
      disabled: true,
      controlModal: false,
    });
    this.props.setHWCostingDetail({});
    this.context.setReset();
  }

  handleBackFirstData = () => {
    const { hwCostingDetail, hardwares } = this.props;
    const costingApps = hardwares.filter((item) => hwCostingDetail.HardwareInventories.find((hw) => item.id === hw.id));
    const firstDataCostUrls = hwCostingDetail.HwContractUrls.map((item) => item.url);
    const tempGenericContItems = [];
    for (let i = 0; i < hwCostingDetail.HwGenericContItems.length; ++i) {
      const obj = {
        monthly_inv: hwCostingDetail.HwGenericContItems[i].monthly_inv,
        total_inv: hwCostingDetail.HwGenericContItems[i].total_inv,
        total_cost: hwCostingDetail.HwGenericContItems[i].total_cost,
        monthly_cost: hwCostingDetail.HwGenericContItems[i].monthly_cost,
        invCurr_type: hwCostingDetail.HwGenericContItems[i].invCurr_type,
        period: hwCostingDetail.HwGenericContItems[i].period,
        contract_start: hwCostingDetail.HwGenericContItems[i].contract_start,
        contract_end: hwCostingDetail.HwGenericContItems[i].contract_end,
        calculated_end: hwCostingDetail.HwGenericContItems[i].calculated_end,
        purchase_date: hwCostingDetail.HwGenericContItems[i].purchase_date,
        fx_rate: hwCostingDetail.HwGenericContItems[i].fx_rate,
        tco_agg: hwCostingDetail.HwGenericContItems[i].tco_agg,
      };
      tempGenericContItems.push(obj);
    }
    const newData = {
      ...hwCostingDetail,
      HardwareInventoryContract: costingApps,
      HwContractUrls: firstDataCostUrls,
      HwGenericContItems: tempGenericContItems,
    };
    let allocTotal = 0;
    const newSendHardwares = [];
    for (let i = 0; i < hwCostingDetail.HardwareInventories.length; ++i) {
      const newObj = {
        id: hwCostingDetail.HardwareInventories[i].id,
        allocation: hwCostingDetail.HardwareInventories[i].allocation,
        value: hwCostingDetail.HardwareInventories[i].value,
        value_inv: 1,
      };
      allocTotal += hwCostingDetail.HardwareInventories[i].value;
      newSendHardwares.push(newObj);
    }
    const pageData = {
      sendHardwares: newSendHardwares,
      allocationTotal: allocTotal,
      addHWModal: false,
      costingDocModal: false,
    };
    this.context.setContextHWCostingState(newData);
    this.props.setCostingFileUrls(firstDataCostUrls);
    this.context.setHWCostingPageVariables(pageData);
    Validator.hideMessages();
    this.setState({ disabled: true, fileUrlArr: firstDataCostUrls });
  }

  handleSaveContract = () => {
    const { HWCostingState, HWCostingPageVariables } = this.context;
    const { costingFileUrls } = this.props;
    const updatedData = { ...HWCostingState };
    updatedData.HardwareInventoryContract = HWCostingPageVariables.sendHardwares;
    updatedData.HwContractUrls = costingFileUrls;
    for (let i = 0; i < updatedData.HwGenericContItems.length; ++i) {
      updatedData.HwGenericContItems[i].fx_rate = CommaStringToNumber(updatedData.HwGenericContItems[i].fx_rate);
      const contractStart = new Date(updatedData.HwGenericContItems[i].contract_start).setHours(13, 0, 0, 0);
      const contractEnd = new Date(updatedData.HwGenericContItems[i].contract_end).setHours(13, 0, 0, 0);
      const calculatedEnd = new Date(updatedData.HwGenericContItems[i].calculated_end).setHours(13, 0, 0, 0);
      if (updatedData.HwGenericContItems[i].purchase_date !== null) {
        const purchaseDate = new Date(updatedData.HwGenericContItems[i].purchase_date).setHours(13, 0, 0, 0);
        updatedData.HwGenericContItems[i].purchase_date = new Date(purchaseDate);
      }
      updatedData.HwGenericContItems[i].contract_start = new Date(contractStart);
      updatedData.HwGenericContItems[i].contract_end = new Date(contractEnd);
      updatedData.HwGenericContItems[i].calculated_end = new Date(calculatedEnd);
    }
    updatedData.total = CommaStringToNumber(updatedData.total);
    updatedData.total_inv = CommaStringToNumber(updatedData.total_inv);
    delete updatedData.id;
    delete updatedData.HardwareInventories;
    delete updatedData.createdAt;
    delete updatedData.company_id;
    delete updatedData.isRemoved;
    delete updatedData.updatedAt;
    delete updatedData.statu;
    delete updatedData.HwContractFiles;
    if (HWCostingPageVariables.sendHardwares.length > 0) {
      if (!Validator.allValid()) {
        Validator.showMessages();
        this.setState({ controlModal: false });
        this.forceUpdate();
        this.props.setToast(true, tra('Lutfen_Zorunlu_Alanlari_Doldurun'), 'warning');
      } else if (HWCostingPageVariables.allocationTotal - (HWCostingPageVariables.hardwareValueStatus === 2 ? 100 : HWCostingState.total) !== 0) {
        this.props.setToast(true, tra('Variance_Control_Message'), 'warning');
        this.setState({ controlModal: false });
      } else {
        this.props.updateHWCosting(updatedData, HWCostingState.id);
        this.setState({ disabled: true, controlModal: false });
      }
    } else {
      this.props.setToast(true, tra('Donanim_Ekle_Mesaji'), 'warning');
    }
  }

  fileGenerateLink = async (fileObj) => {
    const { hwCostingDetail } = this.props;
    const data = JSON.stringify({
      contract_id: hwCostingDetail.id,
      extension: fileObj.extension,
      path: fileObj.filename,
      timeout: 60,
      date: new Date(),
    });
    const token = await encryptTokenLink(data);
    window.open(`${fileObj.base_url}?token=${token.toString()}`, '_blank').focus();
  }

  renderCosting = () => {
    const {
      HWCostingState, HWCostingPageVariables, changeFxRate, setHWCostingPageVariables, handleChangeHWValue,
      handleChangeHWValueAllocation, handleDateChange, handleChangeCurrency, handleChange, removeHW,
      changeAgrAutoComplete, addNewContract, deleteContract, changeHWValueStatus, changeHWValueStatusMenu,
    } = this.context;
    const {
      disabled, compCurrency, contractsArr, fileUrlArr, removeCheckClick,
    } = this.state;
    const {
      hwCostingCategories, hardwares, currencies, tcoAggregations, hwCostingList, hwCostingDetail, hwCostingExpenses,
    } = this.props;
    const costId = this.props.match.params.id;
    Validator.purgeFields();
    return (
      <Grid container spacing={2}>
        <Grid className="gridRow" container item xs={12} spacing={3}>
          <Grid className="rowLeft" item xs={6}>
            <div className="box">
              <div className="headerGrid">
                <div className="left">
                  <h4>{tra('Masraf_Ekle')}</h4>
                  <p>{tra('Add_HW_Costing_Detail')}</p>
                </div>
              </div>
              <div className="inputList">
                <FastInput
                  type="select"
                  disabled
                  data={hwCostingCategories}
                  title={tra('Masraf_Kategorisi')}
                  name="category_id"
                />
                <FastInput
                  type="select"
                  disabled
                  data={contractsArr}
                  title={tra('Masraf_Tipi')}
                  name="type_id"
                />
                <FastInput
                  type="autoCompleteCreatable"
                  title={tra('Tanim')}
                  disabled={disabled}
                  data={hwCostingList}
                  labelKey="agreement"
                  onChange={(e, newValue) => changeAgrAutoComplete(e, newValue)}
                  freeSolo
                  name="agreement"
                  indicate
                  error={Validator.message('agreement', HWCostingState.agreement, 'required')}
                  helperText={Validator.message('agreement', HWCostingState.agreement, 'required')}
                />
              </div>
            </div>
          </Grid>
          <Grid className="rowRight" item xs={6}>
            <div className="box">
              <div className="headerGrid" />
              <div className="inputList">
                <FastInput
                  disabled={disabled}
                  type="text"
                  title={tra('Notlar')}
                  row="4"
                  name="comments"
                  onChange={(e) => handleChange(e, 'comments')}
                />
                <FastInput
                  disabled={disabled}
                  type="select"
                  emptyItem
                  name="expense_type_id"
                  title={tra('Expense_Type')}
                  onChange={(e) => handleChange(e, 'expense_type_id')}
                  data={hwCostingExpenses}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        {HWCostingState.HwGenericContItems && HWCostingState.HwGenericContItems.map((rowItem, index) => (
          <Grid className={index !== HWCostingState.HwGenericContItems.length - 1 ? 'gridRowDashed' : 'gridRow'} container item xs={12} spacing={3}>
            {((index !== 0) || HWCostingState.HwGenericContItems.length > 1) && (
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton
                  style={{ padding: 0, marginRight: 40 }}
                  opacity
                  disabled={disabled}
                  onClick={() => deleteContract(index)}
                >
                  <DeleteOutline />
                </CustomButton>
              </Grid>
            )}
            <Grid className="rowLeft" item xs={6}>
              <div className="box">
                {index === 0 && (
                  <div className="headerGrid">
                    <div className="left">
                      <h4>{tra('Date_Information')}</h4>
                      <p>{tra('Date_Information_Detail_HW')}</p>
                    </div>
                  </div>
                )}
                <div className="inputList">
                  <FastInput
                    type="date"
                    disabled={disabled}
                    title={tra('Sozlesme_Baslangic')}
                    value={rowItem.contract_start}
                    onChange={handleDateChange('contract_start', index)}
                  />
                  <FastInput
                    type="date"
                    disabled={disabled}
                    title={tra('Sozlesme_Bitis')}
                    value={rowItem.contract_end}
                    onChange={handleDateChange('contract_end', index)}
                    minDate={HWCostingState.contract_start}
                  />
                  {HWCostingState.costing_type !== 3 && (
                    <FastInput
                      type="date"
                      disabled={disabled}
                      title={tra('Satin_Alma_Tarihi')}
                      value={rowItem.purchase_date}
                      onChange={handleDateChange('purchase_date', index)}
                    />
                  )}
                  <FastInput
                    type="currency"
                    title={tra('Aylik_Periyot')}
                    disabled
                    value={rowItem.period}
                  />
                </div>
              </div>
            </Grid>
            <Grid className="rowRight" item xs={6}>
              <div className="box">
                {index === 0 && (
                  <div className="headerGrid">
                    <div className="left">
                      <h4>{tra('Cost_Information')}</h4>
                      <p>{tra('Cost_Information_Detail_HW')}.</p>
                    </div>
                  </div>
                )}
                <div className="inputList">
                  <div className="multipleInputWrapper">
                    <FastInput
                      type="select"
                      title={tra('Invoice_Currency')}
                      data={currencies}
                      value={rowItem.invCurr_type}
                      onChange={(e) => handleChange(e, 'invCurr_type', index)}
                      disabled={disabled}
                    />
                    <FastInput
                      disabled={disabled ? true : currencies.find((item) => item.id === rowItem.invCurr_type)?.name === compCurrency}
                      type="number"
                      title={tra('Fx_Rate')}
                      value={rowItem.fx_rate}
                      indicate
                      error={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                      helperText={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                      onChange={(e) => changeFxRate(e, 'change', '', index)}
                      plusClick={() => changeFxRate('', 'buttonClick', 'plus', index)}
                      minusClick={() => changeFxRate('', 'buttonClick', 'minus', index)}
                    />
                  </div>
                  <div className="multipleInputWrapper">
                    <FastInput
                      disabled={disabled}
                      type="currency"
                      title={`${tra('Total_Amount_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                      value={rowItem.total_cost}
                      onChange={(e) => {
                        Validator.showMessageFor('allocationTotal');
                        handleChangeCurrency(e, index);
                      }}
                      indicate
                      error={Validator.message('total_cost', rowItem.total_cost, 'required')}
                      helperText={Validator.message('total_cost', rowItem.total_cost, 'required')}
                    />
                    <FastInput
                      type="currency"
                      title={`${HWCostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                      value={rowItem.monthly_cost}
                      disabled
                    />
                  </div>
                  {compCurrency !== currencies.find((item) => item.id === rowItem.invCurr_type)?.name && (
                    <div className="multipleInputWrapper">
                      <FastInput
                        type="currency"
                        title={`${tra('Total_Amount_In')} ${compCurrency}`}
                        disabled
                        value={rowItem.total_inv}
                      />
                      <FastInput
                        type="currency"
                        title={`${HWCostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${compCurrency}`}
                        value={rowItem.monthly_inv}
                        disabled
                      />
                    </div>
                  )}
                  <div className="multipleInputWrapper">
                    <FastInput
                      type="select"
                      title={tra('Run_Cost_Aggrigation')}
                      value={rowItem.tco_agg}
                      data={tcoAggregations}
                      disabled={disabled}
                      onChange={(e) => handleChange(e, 'tco_agg', index)}
                      indicate
                      error={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                      helperText={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            {index === HWCostingState.HwGenericContItems.length - 1 && (
              <Grid item xs={12}>
                <CustomButton
                  disabled={disabled}
                  transparent
                  startIcon={<Plus width={12} />}
                  onClick={addNewContract}
                >{tra('Split_Existing_Contract')}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        ))}
        <Grid className="gridRow" container item xs={12} spacing={3}>
          <Grid className="rowLeft" item xs={8}>
            <div className="box">
              <div className="headerGrid">
                <div className="left">
                  <h4>{tra('Allocation_Of_Cost_To_Hardwares')}</h4>
                  <p>{tra('Allocation_Of_Cost_To_Hardwares_Detail')}</p>
                </div>
              </div>
              {HWCostingPageVariables.sendHardwares.length > 0 && (
                <div className="allocationTable">
                  <Table>
                    <TableHead>
                      <TableCell />
                      <TableCell>{tra('Allocation')} %</TableCell>
                      <TableCell>{tra('Allocation_In')} {currencies.find((cur) => cur.id === HWCostingState.HwGenericContItems[0].invCurr_type)?.name}</TableCell>
                      <TableCell />
                    </TableHead>
                    <TableBody>
                      {HWCostingPageVariables.sendHardwares.map((item) => (
                        <TableRow>
                          <TableCell><p>{hardwares.length > 0 && hardwares.find((hw) => hw.id === item.inventory_id)?.model}</p></TableCell>
                          <TableCell>
                            <FastInput
                              type="currency"
                              suffix="%"
                              decimalScale="none"
                              allowDecimal={false}
                              value={item.allocation}
                              onChange={(e) => {
                                handleChangeHWValueAllocation(e, item.inventory_id);
                                Validator.showMessageFor('allocationTotal');
                              }}
                              disabled={disabled || (HWCostingPageVariables.hardwareValueStatus === 1)}
                            />
                          </TableCell>
                          <TableCell>
                            <FastInput
                              type="currency"
                              value={FormatCurrency(item.value)}
                              onChange={(e) => {
                                handleChangeHWValue(e, item.inventory_id);
                                Validator.showMessageFor('allocationTotal');
                              }}
                              disabled={disabled || (HWCostingPageVariables.hardwareValueStatus === 2)}
                            />
                          </TableCell>
                          <TableCell><IconButton disabled={disabled} onClick={() => removeHW(item.inventory_id)}><Close /></IconButton></TableCell>
                        </TableRow>
                      ))}
                      <TableRow className="totalRow">
                        <TableCell><p>{tra('Total_Allocated')} {HWCostingPageVariables.hardwareValueStatus === 1 ? tra('Amount') : tra('Percentage')} {HWCostingPageVariables.hardwareValueStatus === 1 && `in ${currencies.find((cur) => cur.id === HWCostingState.HwGenericContItems[0].invCurr_type)?.name}`}</p></TableCell>
                        <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(HWCostingPageVariables.allocationTotal)}{HWCostingPageVariables.hardwareValueStatus === 2 && '%'}</p></TableCell>
                      </TableRow>
                      {HWCostingPageVariables.allocationTotal - HWCostingPageVariables.hardwareValueStatus === 2 ? 100 : HWCostingState.total !== 0 && (
                        <TableRow className="varianceRow">
                          <TableCell><div style={{ display: 'flex', alignItems: 'center' }}><ErrorOutline /><p style={{ marginLeft: 15 }}>{tra('Fark')}</p></div></TableCell>
                          <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(HWCostingPageVariables.allocationTotal - (HWCostingPageVariables.hardwareValueStatus === 2 ? 100 : HWCostingState.total))}{HWCostingPageVariables.hardwareValueStatus === 2 && '%'}</p></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          </Grid>
          <Grid style={{ padding: 0 }} className="rowWithoutInfo" item xs={4}>
            <div className="box">
              <div className="headerGrid">
                <div className="right">
                  <>
                    <CustomButton
                      disabled={disabled}
                      opacity
                      endIcon={<UnfoldMore width={12} />}
                      onClick={(e) => changeHWValueStatusMenu(e.currentTarget)}
                    >{HWCostingPageVariables.hardwareValueStatus === 1 ? tra('Absolute_Value') : tra('Percentage')}
                    </CustomButton>
                    <CustomMenu
                      anchorEl={HWCostingPageVariables.hardwareValueStatusMenu}
                      open={Boolean(HWCostingPageVariables.hardwareValueStatusMenu)}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      keepMounted
                      onClose={() => changeHWValueStatusMenu(null)}
                    >
                      <MenuItem onClick={() => changeHWValueStatus(1)}>{tra('Absolute_Value')}</MenuItem>
                      <MenuItem onClick={() => changeHWValueStatus(2)}>{tra('Percentage')}</MenuItem>
                    </CustomMenu>
                  </>
                  <CustomButton
                    disabled={disabled}
                    onClick={() => setHWCostingPageVariables({
                      ...HWCostingPageVariables,
                      addHWModal: true,
                    })}
                    startIcon={<Plus width={12} />}
                  >
                    {tra('Donanim_Ekle')}
                  </CustomButton>
                </div>
              </div>
              {HWCostingPageVariables.sendHardwares.length > 0 && (
                <div className="chartWrapper">
                  <AllocationPie type="hw" applications={hardwares} data={HWCostingPageVariables.sendHardwares} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid className="gridRow" container item xs={12} spacing={3}>
          <Grid className="rowLeft" item xs={12}>
            <div className="box">
              <div className="headerGrid">
                <div className="left">
                  <h4>{tra('Add_Document_And_Link')}</h4>
                  <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>
                </div>
                <div>
                  <CustomButton
                    onClick={() => setHWCostingPageVariables({
                      ...HWCostingPageVariables,
                      costingDocModal: true,
                    })}
                    startIcon={<Plus width={12} />}
                    active
                    disabled={disabled}
                  >
                    {tra('Ekle')}
                  </CustomButton>
                </div>
              </div>
              <div className="documentList">
                {(hwCostingDetail?.HwContractFiles?.length > 0 || fileUrlArr.length > 0) && (
                  <Table>
                    <TableHead>
                      <TableCell colSpan={4}>{hwCostingDetail?.HwContractFiles?.length} Document / {fileUrlArr.length} Link</TableCell>
                    </TableHead>
                    <TableBody>
                      {hwCostingDetail?.HwContractFiles?.map((file, index) => (
                        <TableRow>
                          <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                          {/* <TableCell style={{ width: 'auto' }}><a onClick={() => this.fileGenerateLink(file)} target="_blank">{file.filename}</a></TableCell> */}
                          <TableCell style={{ width: 'auto' }}><a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a></TableCell>
                          <TableCell style={{ width: 150, textAlign: 'center' }}>{(parseInt(file.size / 1024, 10))} Kb</TableCell>
                          <TableCell style={{ width: 50, textAlign: 'center' }}>
                            {removeCheckClick === index
                              ? (
                                <div style={{ display: 'flex' }}>
                                  <IconButton
                                    onClick={() => this.props.deleteHWCostFile(file.id, costId, 'contract')}
                                    style={{ padding: 5 }}
                                  >
                                    <Check style={{ color: Colors.green }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => this.setState({ removeCheckClick: false })}
                                    style={{ padding: 5 }}
                                  >
                                    <Close style={{ color: Colors.red }} />
                                  </IconButton>
                                </div>
                              )
                              : (
                                <IconButton
                                  onClick={() => this.setState({ removeCheckClick: index })}
                                  style={{ padding: 5 }}
                                ><Delete />
                                </IconButton>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {fileUrlArr.map((url, index) => (
                        <TableRow>
                          <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                          <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                          <TableCell style={{ width: 50, textAlign: 'center' }}>
                            <IconButton
                              onClick={() => {
                                fileUrlArr.splice(index, 1);
                                this.props.setCostingFileUrls(fileUrlArr);
                                this.setState({ fileUrlArr });
                              }}
                              style={{ padding: 5 }}
                              disabled={disabled}
                            ><Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      HWCostingPageVariables, setHWCostingPageVariables, addHWToContract,
    } = this.context;
    const {
      logDrawer, disabled, controlModal, compCurrency, deleteModal,
    } = this.state;
    const {
      hwCostingDetail, hwCostingLogs,
    } = this.props;
    const costId = this.props.match.params.id;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Drawer open={logDrawer} onClose={() => this.setState({ logDrawer: false })} anchor="right">
              <Logs data={hwCostingLogs} />
            </Drawer>
            {
              hwCostingDetail && (
                <Grid item xs={12}>
                  <GridPageWrapper>
                    <InputWrapper>
                      <Grid container spacing={isWidthDown('sm', this.props.width) ? 0 : 2}>
                        <Grid item xs={12}>
                          <div className="header" style={isWidthDown('sm', this.props.width) ? { display: 'none' } : { padding: '0px' }}>
                            <div className="buttonWrapperLeft" />
                            <div className="buttonWrapperRight" style={{ marginTop: 0 }}>
                              {
                                disabled && (
                                  <CustomButton onClick={() => this.setState({ disabled: false })}>
                                    <EditNew color={Colors.blue} width="16" />
                                    {tra('Duzenle')}
                                  </CustomButton>
                                )
                              }
                              <CustomButton
                                opacity
                                onClick={() => this.setState({ logDrawer: true })}
                                startIcon={<HistoryIcon />}
                              >
                                {tra('Tarihce')}
                              </CustomButton>
                              <CustomButton
                                style={{ padding: 0 }}
                                opacity
                                onClick={(e) => this.setState({ otherMenu: e.currentTarget })}
                              >
                                <MoreVert />
                              </CustomButton>
                              <CustomMenu
                                anchorEl={this.state.otherMenu}
                                open={Boolean(this.state.otherMenu)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                keepMounted
                                onClose={() => this.setState({ otherMenu: null })}
                              >
                                <MenuItem
                                  onClick={() => {
                                    this.setState({ otherMenu: false, deleteModal: true });
                                  }}
                                >
                                  <Delete />{tra('Delete_Cost')}
                                </MenuItem>
                              </CustomMenu>
                            </div>
                          </div>
                        </Grid>
                        {this.renderCosting()}
                      </Grid>
                    </InputWrapper>
                  </GridPageWrapper>
                </Grid>
              )
            }
          </div>
        </PageContent>
        {!disabled && (
          <ActionFooter>
            <div>
              <CustomButton
                onClick={() => this.handleBackFirstData()}
              >
                {tra('Vazgec')}
              </CustomButton>
              <CustomButton
                onClick={() => this.setState({ controlModal: true })}
              >
                <SaveNew color={Colors.blue} width="16" />
                {tra('Kaydet')}
              </CustomButton>
            </div>
          </ActionFooter>
        )}
        <AddHWToContract
          addHardware={addHWToContract}
          open={HWCostingPageVariables.addHWModal}
          close={() => setHWCostingPageVariables({
            ...HWCostingPageVariables,
            addHWModal: false,
          })}
          type={HWCostingPageVariables.hardwareValueStatus}
          sendHardwares={HWCostingPageVariables.sendHardwares}
          contractType={hwCostingDetail?.category_id}
          compCurrency={compCurrency}
        />
        <CostingDocModal
          open={HWCostingPageVariables.costingDocModal}
          close={() => setHWCostingPageVariables({
            ...HWCostingPageVariables,
            costingDocModal: false,
          })}
          id={costId}
          fileType="hardware"
          disabled={disabled}
          setFileUrlArr={(fileUrl) => this.setState({ fileUrlArr: fileUrl })}
        />
        <UpdateWarnModal
          open={controlModal}
          close={() => this.setState({ controlModal: false })}
          message={tra('Sozlesme_Guncelleme_Mesaji')}
          update={() => this.handleSaveContract()}
        />
        <DeleteModal
          open={deleteModal}
          close={() => this.setState({ deleteModal: false })}
          message={tra('Sozlesme_Silme_Mesaji')}
          deleteFunc={() => {
            this.props.deleteHWCosting(costId);
            this.setState({ deleteModal: false });
          }}
        />
      </PageWrapper>
    );
  }
}

HWCostingDetail.propTypes = {
  match: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  hwCostingLogs: PropTypes.array.isRequired,
  tcoAggregations: PropTypes.array.isRequired,
  hardwares: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  costingFileUrls: PropTypes.array.isRequired,
  setCostingFileUrls: PropTypes.func.isRequired,
  deleteHWCostFile: PropTypes.func.isRequired,
  getHWCostingCategories: PropTypes.func.isRequired,
  getHardwareDevices: PropTypes.func.isRequired,
  getHWCostingExpenses: PropTypes.func.isRequired,
  getHWCostingList: PropTypes.func.isRequired,
  setHWCostingDetail: PropTypes.func.isRequired,
  getHWCostingDetail: PropTypes.func.isRequired,
  hwCostingCategories: PropTypes.array.isRequired,
  hwCostingList: PropTypes.array.isRequired,
  hwCostingExpenses: PropTypes.array.isRequired,
  hwCostingDetail: PropTypes.object.isRequired,
  updateHWCosting: PropTypes.func.isRequired,
  deleteHWCosting: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currencies: selectInfos('Currencies'),
  tcoAggregations: selectInfos('TcoAggregations'),
  hwCostingLogs: selectHWCostingLogs(),
  user: selectActiveUser(),
  costingFileUrls: selectCostingFileUrls(),
  vendorUsers: selectVendorUsers(),
  hwCostingDetail: selectHWCostingDetail(),
  hwCostingCategories: selectHWCostingCategories(),
  hwCostingList: selectHWCostingList(),
  hwCostingExpenses: selectHWCostingExpenses(),
  hardwares: selectHardwareDevices(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getHWCostingCategories,
      setToast,
      setCostingFileUrls,
      deleteHWCostFile,
      getHardwareDevices,
      getHWCostingExpenses,
      getHWCostingList,
      setHWCostingDetail,
      getHWCostingDetail,
      updateHWCosting,
      deleteHWCosting,
    }, dispatch)
  )
);

const HWCostingDetailWithWidth = withWidth()(HWCostingDetail);

export default connect(mapStateToProps, mapDispatchToProps)(HWCostingDetailWithWidth);

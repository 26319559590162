import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { ThemeContext } from 'styled-components';
import { Colors } from '../../assets/statics';
import { ChartCustomTooltip } from '../../assets/styledNew';
import { tra } from '../../commons/utils';

const prepareData = (data) => {
  const temp = [];
  const tempKeys = [];
  let maxVal = 0;
  for (let i = 0; i < data.length; ++i) {
    tempKeys.push(data[i].text.trim());
    if (parseInt(data[i].total_count, 10) > maxVal) {
      maxVal = parseInt(data[i].total_count, 10);
    }
    const obj = {
      Application: data[i].text,
      TotalAnswer: parseInt(data[i].total_count, 10),
      TotalAnswerColor: Colors.lightBlue100,
      MaxAnsweredColor: Colors.lightGrey700,
    };
    temp.push(obj);
  }
  for (let i = 0; i < temp.length; ++i) {
    temp[i].MaxAnswered = maxVal - temp[i].TotalAnswer;
  }
  return temp;
};

const SurveyBarChart = ({ data, layout }) => {
  const themeContext = useContext(ThemeContext);

  return (
    data.length > 0
      ? (
        <ResponsiveBar
          data={prepareData(data)}
          keys={['TotalAnswer', 'MaxAnswered']}
          indexBy="Application"
          margin={{
            top: 50, right: 50, bottom: 80, left: 50,
          }}
          padding={0.5}
          innerPadding={0}
          groupMode="stacked"
          layout={layout}
          tooltip={(p) => {
            if (p.id !== 'MaxAnswered') {
              return (
                <ChartCustomTooltip bar>
                  <p>{p.indexValue}</p>
                  <h4>{p.value}</h4>
                  <p>{tra('Kullanici')}</p>
                </ChartCustomTooltip>
              );
            }
            return false;
          }}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={(d) => d.data[`${d.id}Color`]}
          borderRadius={3}
          borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
          axisTop={null}
          enableGridX={layout === 'horizontal'}
          enableGridY={layout === 'vertical'}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (e) => Math.floor(e) === e && e,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          enableLabel={false}
          labelSkipHeight={19}
          labelTextColor={{ from: 'color', modifiers: [['darker', '1.2']] }}
          legends={[]}
          animate
          motionStiffness={90}
          motionDamping={15}
          theme={{
            textColor: themeContext.text1,
          }}
        />
      ) : (
        <h4 style={{ color: themeContext.text1 }}>{tra('Henuz_Bir_Cevap_Bulunmamaktadir')}</h4>
      )
  );
};

SurveyBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
};

export default SurveyBarChart;

import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, Grid, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  ModalHeader,
  ModalContent,
  CustomModal,
  CustomButton,
  BottomSheetWrapper,
} from '../../assets/styledNew';
import Input from '../Input';
import { tra } from '../../commons/utils';
import { changePassword, setResetState } from '../../store/actions';
import { selectResetState } from '../../store/selectors';
import BottomSheet from '../BottomSheet';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      oldPassword: '',
      password: '',
      newPassword: '',
      lang: {
        sifreDegistir: tra('Sifre_Degistir'),
        yeniSifre: tra('Yeni_Sifreniz'),
        yeniSifreTekrar: tra('Yeni_Sifrenizi_Tekrar'),
        parolanizUyusmuyor: tra('Parolaniz_Uyusmuyor'),
        kaydet: tra('Kaydet'),
        eskiSifre: tra('Eski_Sifre'),
      },
    };
    this.validator = new SimpleReactValidator({
      locale: 'tr',
      autoForceUpdate: this,
      element: false,
      validators: {
        passwordControl: {
          message: `${this.state.lang.parolanizUyusmuyor}.`,
          rule: (val, params) => (val === params[0]),
        },
      },
    });
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.resetState && nextProps.open) {
      nextProps.setResetState(false);
      nextProps.close();
      return {
        oldPassword: '',
        password: '',
        newPassword: '',
      };
    }
    return null;
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  handeChangePassword = () => {
    const { oldPassword, newPassword } = this.state;
    const data = {
      newPassword,
      password: oldPassword,
    };
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return false;
    }
    this.props.changePassword(data);
    return true;
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;

    const { close } = this.props;
    const {
      newPassword, password, lang, showPassword, showPassword2, showPassword3, oldPassword,
    } = this.state;

    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{lang.sifreDegistir}</h1>
          <CloseIcon onClick={close} style={{ cursor: 'pointer' }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder={lang.eskiSifre}
                  name="oldPassword"
                  value={oldPassword}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('oldPassword', oldPassword, 'required|min:6')}
                  helperText={this.validator.message('oldPassword', oldPassword, 'required|min:6')}
                  endAdornment={(
                    <IconButton
                      value={false}
                      onMouseDown={() => this.setState({ showPassword: true })}
                      onMouseUp={() => this.setState({ showPassword: false })}
                      onTouchStart={() => this.setState({ showPassword: true })}
                      onTouchEnd={() => this.setState({ showPassword: false })}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    )}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type={showPassword2 ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('password', password, `required|min:8|passwordControl:${newPassword}`)}
                  helperText={this.validator.message('password', password, `required|min:8|passwordControl:${newPassword}`)}
                  endAdornment={(
                    <IconButton
                      value={false}
                      onMouseDown={() => this.setState({ showPassword2: true })}
                      onMouseUp={() => this.setState({ showPassword2: false })}
                      onTouchStart={() => this.setState({ showPassword2: true })}
                      onTouchEnd={() => this.setState({ showPassword2: false })}
                      edge="end"
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    )}
                  placeholder={lang.yeniSifre}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type={showPassword3 ? 'text' : 'password'}
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message('newPassword', newPassword, `required|min:8|passwordControl:${newPassword}`)}
                  helperText={this.validator.message('newPassword', newPassword, `required|min:8|passwordControl:${newPassword}`)}
                  endAdornment={(
                    <IconButton
                      value={false}
                      onMouseDown={() => this.setState({ showPassword3: true })}
                      onMouseUp={() => this.setState({ showPassword3: false })}
                      onTouchStart={() => this.setState({ showPassword3: true })}
                      onTouchEnd={() => this.setState({ showPassword3: false })}
                      edge="end"
                    >
                      {showPassword3 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    )}
                  placeholder={lang.yeniSifreTekrar}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={() => this.handeChangePassword()}>{lang.kaydet}</CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>

    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('md', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

ChangePassword.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  setResetState: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  resetState: selectResetState(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      changePassword,
      setResetState,
    }, dispatch)
  )
);

const ChangePasswordWithWidth = withWidth()(ChangePassword);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordWithWidth);

import React, { Component } from 'react';
// import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { ColorBox, LoginBox, CustomButton } from '../assets/styledNew';
import Input from '../components/Input';
import { Colors } from '../assets/statics';
import {
  LogoColor,
} from '../assets/icons';
// import { selectLoginState } from '../store/selectors';
import { resetPassword, setPasswordResetComplete } from '../store/actions';
import { tra } from '../commons/utils';
import validator from '../commons/validator';
import { selectPassResetComplete } from '../store/selectors';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      password: '',
      newPassword: '',
      stepIndex: 1,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.passResetComplete) {
      nextProps.setPasswordResetComplete(false);
      return {
        stepIndex: 2,
      };
    }
    return null;
  }

  componentDidMount() {
    localStorage.setItem('token', this.props.match.params.token);
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  isCheck = (...arr) => {
    const arrayValidate = [];
    arr.forEach((item) => {
      arrayValidate.push(validator.fieldValid(item));
      validator.showMessageFor(item);
    });
    this.setState({ asd: '' }); // bu olmassa validator geç setleniyor.
    return arrayValidate.every((i) => i === true);
  }

  handlePasswordSend = () => {
    const { password } = this.state;
    if (this.isCheck('password', 'newPassword')) {
      this.props.resetPassword(password);
    }
  }

  render() {
    const {
      newPassword, password, showPassword, stepIndex,
    } = this.state;
    return (
      <ColorBox state>
        <LoginBox stepIndex={stepIndex} reverse={false}>
          <div className="headerArea">
            <LogoColor />
          </div>
          <div className="formGroup">

            <div className="formArea">
              <div>
                <h1>{tra('Sifre_Sifirlama')}</h1>
                {tra('Sifirlama_Mesaji')}
              </div>
              <div className="inputArea">
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  variant="standard"
                  value={password}
                  onChange={(e) => this.handleChange(e)}
                  error={validator.message('password', password, `required|min:8|passwordControl:${newPassword}`)}
                  helperText={validator.message('password', password, `required|min:8|passwordControl:${newPassword}`)}
                  endAdornment={(
                    <IconButton
                      aria-label="toggle password visibility"
                      value={false}
                      onMouseDown={() => this.setState({ showPassword: true })}
                      onMouseUp={() => this.setState({ showPassword: false })}
                      edge="end"
                    >
                      {showPassword ? <Visibility style={{ color: Colors.purple }} /> : <VisibilityOff style={{ color: Colors.purple }} />}
                    </IconButton>
                  )}
                  placeholder={tra('Sifre_giriniz')}
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  variant="standard"
                  value={newPassword}
                  onChange={(e) => this.handleChange(e)}
                  error={validator.message('newPassword', newPassword, `required|min:8|passwordControl:${newPassword}`)}
                  helperText={validator.message('newPassword', newPassword, `required|min:8|passwordControl:${newPassword}`)}
                  endAdornment={(
                    <IconButton
                      aria-label="toggle password visibility"
                      value={false}
                      onMouseDown={() => this.setState({ showPassword: true })}
                      onMouseUp={() => this.setState({ showPassword: false })}
                      edge="end"
                    >
                      {showPassword ? <Visibility style={{ color: Colors.purple }} /> : <VisibilityOff style={{ color: Colors.purple }} />}
                    </IconButton>
                  )}
                  placeholder={tra('Sifrenizi_Tekrar_Giriniz')}
                />
              </div>
              <CustomButton borderRadius="5px" color={Colors.blue} padding="10px 10px" onClick={this.handlePasswordSend}>{tra('Gonder')}</CustomButton>
            </div>
            <div className="formArea">
              <div>
                <h1>{tra('Tebrikler')}</h1>
                {tra('Sifre_Sifirlama_Tamamlandi_Mesaji')}
              </div>
              <div
                style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 150,
                }}
              >
                <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => { window.location = '#/'; }}>{tra('Giris_Yap')}</CustomButton>
              </div>
            </div>
            <div className="formArea" />
            <div className="formArea" />
          </div>
        </LoginBox>
      </ColorBox>
    );
  }
}

PasswordReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
  passResetComplete: PropTypes.bool.isRequired,
  setPasswordResetComplete: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  passResetComplete: selectPassResetComplete(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      resetPassword,
      setPasswordResetComplete,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  AccordionDetails, AccordionSummary, Grid, IconButton, MenuItem, TextField, Tooltip, withWidth,
} from '@material-ui/core';
import { CheckBox, ExpandMore } from '@material-ui/icons';
import { withTheme } from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  PageContent, PageWrapper, SurveyParagraphRep, SurveyParagraphTooltip,
} from '../../assets/styledNew';
import {
  SurveyChartBox, SurveyDetailHeader, SurveyDetailHeaderBox, SurveySectionHeader, SurveyHeaderApp,
  CustomAccordion, SelectUserItem, SurveyHeaderContainer,
} from '../../assets/styledSurveyQuestions';
import { tra } from '../../commons/utils';
import {
  Clock, HalfCircle, Left, RadioButton, Right, Users,
} from '../../assets/icons';
import SurveyBarChart from '../../components/charts/SurveyBarChart';
import SurveyPieChart from '../../components/charts/SurveyPieChart';
import { selectAnswerOptionUsers, selectSurveyDetailApps, selectSurveyReportQuestions } from '../../store/selectors';
import {
  getSurveyReportQuestions, getSurveyDetailApps, getSingleSurveyDetail, getAnswerOptionUsers,
} from '../../store/actions';
import { Colors } from '../../assets/statics';
import { DragToScroll } from '../../commons/touch';

const headerUsers = [
  {
    id: 1,
    text: tra('TOPLAM_KATILIMCI'),
    icon: 'users',
    key: 'total_user_count',
    color: '#FFA900',
    backg: 'rgba(255, 169, 0, 0.1)',
  },
  {
    id: 2,
    text: tra('TOPLAM_YANITLAYAN'),
    key: 'total_finish_count',
    icon: 'users',
    color: '#4C6FFF',
    backg: 'rgba(76, 111, 255, 0.1)',
  },
  {
    id: 3,
    text: tra('TOPLAM_YANITLAMAYANLAR'),
    key: 'total_unstart_count',
    icon: 'users',
    color: '#FF5242',
    backg: 'rgba(255, 82, 66, 0.1)',
  },
  {
    id: 4,
    text: tra('YARIM_KALAN_ANKETLER'),
    key: 'total_unfinish_count',
    icon: 'half',
    color: '#6C6C8F',
    backg: 'rgba(108, 108, 143, 0.1)',
  },
];

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 19px ${Colors.shadow};`,
    fontSize: 11,
  },
  arrow: {
    color: Colors.white,
  },
}))(Tooltip);

class SurveyChartDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        toplamYanitlayan: tra('TOPLAM_YANITLAYAN'),
        gerekli: tra('Gerekli'),
        detayliGor: tra('Detayli_Gor'),
        enCokVerimAlinanUygulama: tra('En_Cok_Verim_Alinan_Uygulama_Sorusu'),
        detayiGizle: tra('Detayi_Gizle'),
        soruDetayi: tra('Soru_Detayi'),
      },
      optId: '',
      selectedApp: props.match.params.appId,
      detailChartArr: [],
    };
    props.getSurveyDetailApps(props.match.params.id);
    props.getSurveyReportQuestions(props.match.params.id, props.match.params.appId);
  }

  headerIconDecider = (icon, color) => {
    switch (icon) {
      case 'users':
        return <Users width={25} color={color} />;
      case 'half':
        return <HalfCircle width={25} color={color} />;
      case 'time':
        return <Clock width={25} color={color} />;
      default:
        return <Users width={25} color={color} />;
    }
  }

  handlePercantage = (value) => {
    const { chartDetail } = this.props;
    if (value > 0) {
      return (value / chartDetail.total_user_count) * 100;
    }
    return 0;
  }

  handleDetail = (type, id) => {
    const { detailChartArr } = this.state;
    if (type === 'open') {
      detailChartArr.push(id);
    } else {
      const index = detailChartArr.findIndex((item) => item === id);
      detailChartArr.splice(index, 1);
    }
    this.setState({ detailChartArr });
  }

  renderGraphDetailBox = (item, index) => {
    const { lang, detailChartArr, optId } = this.state;
    const { match, answerOptionUsers } = this.props;
    return (
      <>
        <SurveyChartBox>
          <div className="header">
            <h4>{index}. {item.question_text}</h4>
            <div className="totalAnswered">
              <Users width={25} />
              <p className="count">{item.answer_count}</p>
            </div>
          </div>
          <div className="content">
            <Grid container>
              {item.input_type_id !== 1 ? (
                <Grid container justify="center" item xs={12}>
                  <Grid item sm={8} xs={12}>
                    <div
                      style={{
                        width: '100%',
                        height: item.OptionChoices.filter((opt) => parseInt(opt.total_count, 10) > 0).length > 0 ? 350 : 'unset',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {item.input_type_id === 2 && <SurveyPieChart data={item.OptionChoices} />}
                      {item.input_type_id === 3 && <SurveyBarChart data={item.OptionChoices} layout="vertical" />}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container style={{ marginTop: 20, maxHeight: 500, overflow: 'auto' }} item xs={12}>
                  {item.answers && item.answers.length > 0 ? item.answers.map((ans) => (
                    <Grid item xs={12}>
                      <Link onClick={() => this.props.getSingleSurveyDetail(ans.user_id, match.params.id, match.params.appId)} to={`/Admin/SurveyDetail/${match.params.id}/${match.params.appId}/SingleSurveyDetail/${ans.user_id}`}>
                        <LightTooltip
                          placement="top"
                          arrow
                          title={(
                            <SurveyParagraphTooltip>
                              <div className="content">
                                <img className="icon" src={ans.profileImage} alt="prfImage" />
                                <div className="info">
                                  <p>{ans.permission}</p>
                                  <h4>{ans.full_name}</h4>
                                  <p>{ans.department}</p>
                                </div>
                              </div>
                            </SurveyParagraphTooltip>
                          )}
                        >
                          <SurveyParagraphRep>
                            <p>{ans.answer_text}</p>
                          </SurveyParagraphRep>
                        </LightTooltip>
                      </Link>
                    </Grid>
                  )) : (
                    <Grid item xs={12}><h4 style={{ textAlign: 'center', fontWeight: 'normal', color: this.props.theme.text3 }}>{tra('Henuz_Bir_Cevap_Bulunmamaktadir')}</h4></Grid>
                  )}
                </Grid>
              )}
            </Grid>
            {item.input_type_id !== 1 && (
              detailChartArr.filter((dt) => dt === item.id).length === 0 ? (
                <a onClick={() => this.handleDetail('open', item.id)} className="detail">{lang.detayliGor} <ExpandMoreIcon style={{ color: this.props.theme.blueText }} /></a>
              ) : (
                <a onClick={() => this.handleDetail('close', item.id)} className="detail">{lang.detayiGizle}<ExpandLessIcon style={{ color: this.props.theme.blueText }} /></a>
              )
            )}
          </div>
        </SurveyChartBox>
        {item.input_type_id !== 1 && detailChartArr.filter((dt) => dt === item.id).length > 0 && (
          <div style={{ width: '100%' }}>
            <CustomAccordion expanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <div className="surveyChart">
                  <h4>{index}. {lang.soruDetayi}</h4>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {item.OptionChoices && item.OptionChoices.length > 0 && item.OptionChoices.map((opt) => (
                    <Grid item sm={3} xs={6}>
                      <div className="answerBox">
                        <div className="title">{item.input_type_id === 2 ? <RadioButton /> : <CheckBox />} <p>{opt.text}</p></div>
                        <div className="select">
                          <TextField
                            onFocus={() => {
                              this.setState({ optId: opt.id });
                              if (optId !== opt.id) {
                                this.props.getAnswerOptionUsers(opt.id, match.params.appId);
                              }
                            }}
                            disabled={opt.total_count === '0'}
                            className="selectField"
                            defaultValue={opt.total_count}
                            select
                            InputProps={{ disableUnderline: true }}
                          >
                            <MenuItem value={opt.total_count} style={{ display: 'none' }}>{opt.total_count} Yanıt</MenuItem>
                            {answerOptionUsers && answerOptionUsers.length > 0 && answerOptionUsers.map((user) => (
                              <Link onClick={() => this.props.getSingleSurveyDetail(user.id, match.params.id, match.params.appId)} to={`/Admin/SurveyDetail/${match.params.id}/${match.params.appId}/SingleSurveyDetail/${user.id}`}>
                                <SelectUserItem>
                                  <img className="img" src={user.profile_image} alt="profImg" />
                                  <div className="info">
                                    <p>{user.permission}</p>
                                    <h4>{user.name}</h4>
                                    <p>{user.department}</p>
                                  </div>
                                </SelectUserItem>
                              </Link>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </CustomAccordion>
          </div>
        )}
      </>
    );
  }

  handleChangeApp = (type, appId) => {
    const { match, surveyDetailApps } = this.props;
    const selectedIndex = surveyDetailApps.Applications.findIndex((item) => parseInt(item.id, 10) === appId);
    let changedAppId = appId;
    if (type === 'next') {
      if (surveyDetailApps.Applications[selectedIndex + 1]) {
        this.props.getSurveyReportQuestions(match.params.id, surveyDetailApps.Applications[selectedIndex + 1].id);
        changedAppId = surveyDetailApps.Applications[selectedIndex + 1].id;
        window.location.href = `#/Admin/SurveyChartDetail/${match.params.id}/${changedAppId}`;
      } else {
        this.props.getSurveyReportQuestions(match.params.id, surveyDetailApps.Applications[0].id);
        changedAppId = surveyDetailApps.Applications[0].id;
        window.location.href = `#/Admin/SurveyChartDetail/${match.params.id}/${changedAppId}`;
      }
    } if (type === 'prev') {
      if (surveyDetailApps.Applications[selectedIndex - 1]) {
        this.props.getSurveyReportQuestions(match.params.id, surveyDetailApps.Applications[selectedIndex - 1].id);
        changedAppId = surveyDetailApps.Applications[selectedIndex - 1].id;
        window.location.href = `#/Admin/SurveyChartDetail/${match.params.id}/${changedAppId}`;
      } else {
        this.props.getSurveyReportQuestions(match.params.id, surveyDetailApps.Applications[surveyDetailApps.Applications.length - 1].id);
        changedAppId = surveyDetailApps.Applications[surveyDetailApps.Applications.length - 1].id;
        window.location.href = `#/Admin/SurveyChartDetail/${match.params.id}/${changedAppId}`;
      }
    }
    this.setState({ selectedApp: changedAppId, optId: '', detailChartArr: [] });
  }

  render() {
    const { selectedApp } = this.state;
    const { chartDetail, surveyDetailApps } = this.props;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container>
              <Grid item xs={12}>
                <h3 style={{ fontWeight: 600, fontSize: 24, color: this.props.theme.text2 }}>{chartDetail.name}</h3>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {surveyDetailApps && surveyDetailApps.Applications && surveyDetailApps.Applications.length > 0 && (
                <Grid container justify="center" item xs={12}>
                  <SurveyHeaderApp>
                    <IconButton disabled={surveyDetailApps.Applications.length < 2} onClick={() => this.handleChangeApp('prev', selectedApp)}><Left /></IconButton>
                    <h4>{surveyDetailApps.Applications.find((item) => item.id === parseInt(selectedApp, 10)).name}</h4>
                    <IconButton disabled={surveyDetailApps.Applications.length < 2} onClick={() => this.handleChangeApp('next', selectedApp)}><Right /></IconButton>
                  </SurveyHeaderApp>
                </Grid>
              )}
              <Grid item xs={12}>
                <SurveyDetailHeader
                  answered={this.handlePercantage(chartDetail.total_finish_count)}
                  notAnswered={this.handlePercantage(chartDetail.total_unstart_count)}
                  halfAnswered={this.handlePercantage(chartDetail.total_unfinish_count)}
                >
                  <div className="progressBar">
                    {chartDetail.total_finish_count > 0 && <div className="answered" />}
                    {chartDetail.total_unstart_count > 0 && <div className="notAnswered" />}
                    {chartDetail.total_unfinish_count > 0 && <div className="halfAnswered" />}
                  </div>
                </SurveyDetailHeader>
              </Grid>
              <DragToScroll>
                <SurveyHeaderContainer container item xs={12} spacing={3}>
                  {headerUsers.map((header) => (
                    <Grid item xs>
                      <SurveyDetailHeaderBox>
                        {this.headerIconDecider(header.icon, header.color)}
                        <p
                          style={{
                            color: header.color,
                            fontSize: 20,
                            fontWeight: 600,
                            marginTop: 10,
                          }}
                        >{chartDetail[header.key]}
                        </p>
                        <p
                          style={{
                            color: header.color,
                            fontSize: 15,
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >{header.text}
                        </p>
                      </SurveyDetailHeaderBox>
                    </Grid>
                  ))}
                </SurveyHeaderContainer>
              </DragToScroll>
              {chartDetail && chartDetail.SurveySections && chartDetail.SurveySections.map((section) => (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <SurveySectionHeader style={{ margin: '10px 0px' }}><p style={{ background: this.props.theme.mainBackg }}>{section.name}</p></SurveySectionHeader>
                  </Grid>
                  {section.Questions.length > 0 && section.Questions.map((question, i) => (
                    this.renderGraphDetailBox(question, i + 1)
                  ))}
                </Grid>
              ))}
            </Grid>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

SurveyChartDetail.propTypes = {
  match: PropTypes.object.isRequired,
  getSurveyReportQuestions: PropTypes.func.isRequired,
  chartDetail: PropTypes.object.isRequired,
  surveyDetailApps: PropTypes.object.isRequired,
  getSurveyDetailApps: PropTypes.func.isRequired,
  getSingleSurveyDetail: PropTypes.func.isRequired,
  answerOptionUsers: PropTypes.array.isRequired,
  getAnswerOptionUsers: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  chartDetail: selectSurveyReportQuestions(),
  surveyDetailApps: selectSurveyDetailApps(),
  answerOptionUsers: selectAnswerOptionUsers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getSurveyDetailApps,
      getSurveyReportQuestions,
      getSingleSurveyDetail,
      getAnswerOptionUsers,
    }, dispatch)
  )
);

const SurveyChartDetailWithTheme = withTheme(SurveyChartDetail);
const SurveyChartDetailWithWidth = withWidth()(SurveyChartDetailWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyChartDetailWithWidth);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from 'styled-components';
import {
  CustomButton, CustomModal, ModalHeader, ModalContent, BottomSheetWrapper,
} from '../../assets/styledNew';
import { Colors } from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';
import {
  setResetState, getLdapUsers, createLdapUser,
} from '../../store/actions';
import {
  selectInfos, selectLdapUsers, selectResetState,
} from '../../store/selectors';
import BottomSheet from '../BottomSheet';
import Validator from '../../commons/validator';

class AddUserLdap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDepartment: '',
      userPermission: '',
      userMail: '',
      userPhone: '',
      selectedUser: null,
    };
    this.throttleSearch = false;
  }

  componentDidUpdate() {
    if (this.props.resetState) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({
      userDepartment: '',
      userPermission: '',
      userMail: '',
      userPhone: '',
      selectedUser: null,
    });
    this.props.setResetState(false);
    Validator.hideMessages();
    this.forceUpdate();
  }

  saveLdapUser = () => {
    const {
      selectedUser, userDepartment, userPermission, userMail, userPhone,
    } = this.state;
    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      return false;
    }
    const data = {
      department_id: userDepartment?.id,
      permission_id: userPermission,
      cn: selectedUser.cn,
      sn: selectedUser.sn,
      title: selectedUser.title,
      mail: userMail,
      sAMAccountName: selectedUser.sAMAccountName,
      telephoneNumber: userPhone,
      registry_no: selectedUser.employeeID,
    };
    this.props.createLdapUser(data);
    return true;
  }

  handleChange = (e, state) => {
    this.setState({ [state]: e?.target?.value || e });
  }

  searchUser = (e, str) => {
    clearTimeout(this.throttleSearch);
    this.throttleSearch = setTimeout(() => {
      if (str.length > 2) {
        this.props.getLdapUsers(str);
      }
    }, 700);
  };

  onChangeAutoComplete = (e, newValue) => {
    if (newValue !== undefined && newValue !== '' && newValue !== null) {
      this.setState({
        selectedUser: newValue,
        userMail: newValue.mail,
      });
      if (newValue.telephoneNumber !== '.') {
        this.setState({ userPhone: newValue.telephoneNumber });
      }
    }
  };

  handlePhoneChange = (values) => {
    this.setState({ userPhone: values.value });
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { permissions, ldapUsers, locations } = this.props;
    const {
      userDepartment, userPermission, selectedUser, userMail, userPhone,
    } = this.state;
    Validator.purgeFields();
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>Ldap {tra('Yeni_Kullanici_Ekle')}</h1>
          <CloseIcon onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  type="autoCompleteSearch"
                  value={selectedUser}
                  onChange={(e, val) => this.onChangeAutoComplete(e, val)}
                  title={tra('Kullanıcı_Arayın')}
                  onInputChange={this.searchUser}
                  data={ldapUsers}
                  labelKey="cn"
                  optionKey="cn"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={`${tra('Adi')} ${tra('Soyadi')}`}
                  value={selectedUser?.cn || ''}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={`Ldap ${tra('Kullanici_Adi')}`}
                  value={selectedUser?.sAMAccountName || ''}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Email')}
                  onChange={(e) => this.setState({ userMail: e.target.value })}
                  value={userMail}
                  error={Validator.message('userMail', userMail, 'required')}
                  helperText={Validator.message('userMail', userMail, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="tel"
                  valueKey="dial_code"
                  data={locations}
                  telCode={locations.find((item) => item.code === 'TR')}
                  telCodeDisabled
                  onChangeTelCode={(e, newValue) => this.handlePhoneCodeChange(e, newValue)}
                  title={tra('Telefon')}
                  value={userPhone}
                  onValueChange={(values) => this.handlePhoneChange(values)}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="autoComplete"
                  title={tra('Departman')}
                  value={userDepartment}
                  data={this.props.departments}
                  multiple={false}
                  onChange={(e, val) => this.handleChange(val, 'userDepartment')}
                  error={Validator.message('userDepartment', userDepartment, 'required')}
                  helperText={Validator.message('userDepartment', userDepartment, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="select"
                  title={tra('Yetki')}
                  data={permissions}
                  value={userPermission}
                  onChange={(e) => this.handleChange(e, 'userPermission')}
                  error={Validator.message('userPermission', userPermission, 'required')}
                  helperText={Validator.message('userPermission', userPermission, 'required')}
                />
              </Grid>
              <Grid container justify="space-between" item xs={12}>
                <CustomButton onClick={this.resetState} opacity color={Colors.blue}> {tra('Vazgeç')}  </CustomButton>
                <CustomButton onClick={this.saveLdapUser} color={Colors.blue}> {tra('Kaydet')}  </CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} onExit={() => this.resetState()} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AddUserLdap.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  ldapUsers: PropTypes.array.isRequired,
  getLdapUsers: PropTypes.func.isRequired,
  createLdapUser: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  departments: selectInfos('Departments'),
  permissions: selectInfos('Permissions'),
  locations: selectInfos('Locations'),
  resetState: selectResetState(),
  ldapUsers: selectLdapUsers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setResetState,
      getLdapUsers,
      createLdapUser,
    }, dispatch)
  )
);

const AddUserLdapWithTheme = withTheme(AddUserLdap);
const AddUserLdapWithWidth = withWidth()(AddUserLdapWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AddUserLdapWithWidth);

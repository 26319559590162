import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from 'styled-components';
import {
  CustomButton,
  CustomModal,
  ModalHeader,
  ModalContent,
  BottomSheetWrapper,
} from '../../assets/styledNew';

import {
  Colors,
} from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';
import BottomSheet from '../BottomSheet';
import Validator from '../../commons/validator';
import { updateInfo } from '../../store/actions';
import { selectInfos } from '../../store/selectors';

class EditManageDataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  handleEntering = () => {
    const { item } = this.props;
    this.setState({
      value: item?.name,
    });
  }

  handleSaveUser = () => {
    const { item, infos, dataType } = this.props;
    const { value } = this.state;
    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      return false;
    }
    const header = infos.find((info) => info.id === dataType);
    const data = {
      name: value,
    };
    this.props.updateInfo(item.id, header.name, data);
    this.props.close();
    return true;
  }

  handleChange = (e, state) => {
    this.setState({ [state]: e.target.value });
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { value } = this.state;
    const { item } = this.props;
    Validator.purgeFields();
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{tra('Veri_Duzenle')}</h1>
          <CloseIcon onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('ID')}
                  value={item?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Deger')}
                  value={value}
                  onChange={(e) => this.handleChange(e, 'value')}
                  error={Validator.message('value', value, 'required')}
                  helperText={Validator.message('value', value, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="date"
                  title={tra('Yaratilma_Tarihi')}
                  value={item?.date}
                  disabled
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={this.handleSaveUser} color={Colors.blue}>{tra('Kaydet')}</CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal onEntering={() => this.handleEntering()} open={this.props.open} onClose={this.props.close} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

EditManageDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  updateInfo: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  infos: PropTypes.array.isRequired,
  dataType: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  infos: selectInfos('Headers'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      updateInfo,
    }, dispatch)
  )
);

const EditManageDataModalWithTheme = withTheme(EditManageDataModal);
const EditManageDataModalWithWidth = withWidth()(EditManageDataModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(EditManageDataModalWithWidth);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from 'styled-components';
import {
  CustomButton, CustomModal, ModalHeader, ModalContent, BottomSheetWrapper,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import Input from '../Input';
import BottomSheet from '../BottomSheet';
import Validator from '../../commons/validator';
import Table from '../Table';
import {
  selectAdminCompanies, selectAdminCompanyUsers, selectCompanyDepartments, selectInfos,
} from '../../store/selectors';
import { addUserToCompany } from '../../store/actions';

class AddUserToCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        departman: tra('Departman'),
        yetki: tra('Yetki'),
        unvan: tra('Unvan'),
        kullanici: tra('Kullanici'),
      },
      selectedPermission: '',
      selectedUser: null,
      selectedDepartment: '',
      selectedTitle: '',
      filterCompanyTable: [],
    };
  }

  onExit = () => {
    Validator.hideMessages();
    this.setState({
      selectedDepartment: '',
      selectedPermission: '',
      selectedUser: null,
      selectedTitle: '',
    });
  }

  addUserToCompany = () => {
    const {
      selectedDepartment, selectedPermission, selectedUser, selectedTitle,
    } = this.state;
    const { compId } = this.props;
    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      return false;
    }
    const data = {
      user_id: selectedUser.id,
      permission_id: selectedPermission,
      department_id: selectedDepartment,
      title: selectedTitle,
    };
    this.props.addUserToCompany(data, compId);
    this.setState({
      selectedTitle: '',
      selectedUser: null,
      selectedDepartment: '',
      selectedPermission: '',
    });
    return true;
  }

  handleChange = (e, state) => {
    this.setState({ [state]: e.target.value });
  }

  onChangeAutoComplete = (e, newValue) => {
    if (newValue !== undefined && newValue !== '' && newValue !== null) {
      this.setState({ selectedUser: newValue });
    }
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const {
      adminCompUsers, companyDepartments, permissions, adminCompanies, compId,
    } = this.props;
    const {
      lang, selectedUser, selectedDepartment, selectedPermission, selectedTitle,
    } = this.state;
    const userColumns = [
      {
        name: 'profile_img',
        text: tra('Profil'),
        editable: false,
        align: 'center',
        customBody: (value) => (
          <img src={value} alt="profImg" style={{ width: 40, borderRadius: '50%' }} />
        ),
      },
      {
        name: 'email',
        text: lang.email,
        editable: false,
        align: 'center',
        searchable: true,
      },
    ];
    Validator.purgeFields();
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{adminCompanies.find((item) => item.id === compId) ? adminCompanies.find((item) => item.id === compId).name : ''} {tra('Şirketine_Kullanıcı_Ekle')}</h1>
          <CloseIcon onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={4}>
              {Object.keys(adminCompUsers).length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Input
                      title={lang.kullanici}
                      value={selectedUser || null}
                      disabled={adminCompUsers.notContainUsers.length === 0}
                      placeholder={adminCompUsers.notContainUsers.length === 0 ? tra('Ekleyebileceğiniz_Kullanıcı_Bulunmamaktadır') : tra('Kullanıcı_Arayın')}
                      onChange={(e, newValue) => this.onChangeAutoComplete(e, newValue)}
                      type="autoComplete"
                      multiple={false}
                      data={adminCompUsers.notContainUsers || []}
                      error={Validator.message('selectedUser', selectedUser, 'required')}
                      helperText={Validator.message('selectedUser', selectedUser, 'required')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      title={lang.departman}
                      disabled={adminCompUsers.notContainUsers.length === 0}
                      value={selectedDepartment}
                      onChange={(e) => this.setState({ selectedDepartment: e.target.value })}
                      type="select"
                      data={companyDepartments.filter((item) => item.isRemoved === 0)}
                      error={Validator.message('selectedDepartment', selectedDepartment, 'required')}
                      helperText={Validator.message('selectedDepartment', selectedDepartment, 'required')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      title={lang.yetki}
                      disabled={adminCompUsers.notContainUsers.length === 0}
                      value={selectedPermission}
                      onChange={(e) => this.setState({ selectedPermission: e.target.value })}
                      type="select"
                      data={permissions}
                      error={Validator.message('selectedPermission', selectedPermission, 'required')}
                      helperText={Validator.message('selectedPermission', selectedPermission, 'required')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      type="text"
                      onChange={(e) => this.setState({ selectedTitle: e.target.value })}
                      value={selectedTitle}
                      title={lang.unvan}
                      disabled={adminCompUsers.notContainUsers.length === 0}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Table
                  data={Object.keys(adminCompUsers).length > 0 && adminCompUsers.users}
                  columns={userColumns}
                  options={{
                    header: {
                      visible: false,
                      search: false,
                    },
                    pagination: {
                      active: true,
                      perPage: 4,
                      position: 'right',
                    },
                    filter: this.state.filterCompanyTable,
                    editable: false,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12}>
              <CustomButton onClick={this.props.close} style={{ margin: '0 15px' }} outlined>{tra('Vazgec')}</CustomButton>
              <CustomButton
                disabled={Object.keys(adminCompUsers).length > 0 && adminCompUsers.notContainUsers.length === 0}
                onClick={() => this.addUserToCompany()}
              >
                {tra('Kaydet')}
              </CustomButton>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} onExit={() => this.onExit()} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.onExit(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AddUserToCompany.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  adminCompUsers: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  companyDepartments: PropTypes.array.isRequired,
  addUserToCompany: PropTypes.func.isRequired,
  adminCompanies: PropTypes.array.isRequired,
  compId: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  adminCompUsers: selectAdminCompanyUsers(),
  companyDepartments: selectCompanyDepartments(),
  permissions: selectInfos('Permissions'),
  adminCompanies: selectAdminCompanies(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      addUserToCompany,
    }, dispatch)
  )
);

const AddUserToCompanyWithTheme = withTheme(AddUserToCompany);
const AddUserToCompanyWithWidth = withWidth()(AddUserToCompanyWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToCompanyWithWidth);

import { Grid, isWidthDown, withWidth } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { HardwareContext } from '../../commons/context/HardwareContext';
import InputRender from './InputRender';
import { CustomButton, HardwareAddRowGridComp } from '../../assets/styledNew';
import { Plus, TrashIcon } from '../../assets/icons';
import { tra } from '../../commons/utils';
import { selectAppConfig } from '../../store/selectors';
import Validator from '../../commons/validator';

class RowGridRender extends Component {
    // eslint-disable-next-line react/static-property-placement
    static contextType = HardwareContext

    constructor(props) {
      super(props);

      this.state = {
        addGroupName: false,
        objectGroupName: false,
        addGroupIndex: 0,
        gridDataState: [],
      };
    }

    componentDidMount() {
      const { gridData } = this.props;
      const { addGroupName, subtitle, objectGroupName } = gridData.flat(Infinity).find((d) => d.addGroupName || d.objectGroupName);
      if (addGroupName) {
        this.setState({ addGroupName, subtitle }, () => this.initalAddGroup());
      } else {
        this.setState({ objectGroupName, subtitle });
      }
      this.setState({ gridDataState: [this.props.gridData.flat(Infinity)] });
    }

    initalAddGroup=() => {
      const { accordionLengthData } = this.context;
      const negativeControl = (number) => (number < 0 ? 0 : number);
      this.setState((state) => ({ addGroupIndex: negativeControl(accordionLengthData[state.addGroupName] - 1) }));
    }

    hwaInput=(inputData, index) => {
      const { addGroupName, objectGroupName } = this.state;
      const { pagesIndex } = this.props;

      return (addGroupName || objectGroupName) && inputData.childrenInput ? (
        <div className="inputFlexRow">
          <InputRender
            key={uuidv4()}
            inputObject={inputData}
            objectGroupName={objectGroupName}
            addGroupName={addGroupName}
            addGroupIndex={index}
            pagesNumber={pagesIndex}
          />
          {
          inputData.childrenInput.map((element) => (
            <InputRender
              key={uuidv4()}
              inputObject={element}
              objectGroupName={objectGroupName}
              addGroupName={addGroupName}
              addGroupIndex={index}
              pagesNumber={pagesIndex}
            />
          ))
            }
        </div>
      ) : (
        <InputRender
          key={uuidv4()}
          inputObject={inputData}
          objectGroupName={objectGroupName}
          addGroupName={addGroupName}
          addGroupIndex={index}
          pagesNumber={pagesIndex}
        />
      );
    }

    addGroup=() => {
      const { addGroupIndex, addGroupName, gridDataState } = this.state;
      gridDataState.flat().map((e) => e.inputArray).flat().forEach((input) => {
        Validator.hideMessageFor(Validator.hideMessageFor([addGroupName, input.name, addGroupIndex + 1].filter((n) => n != null).join('.')));
      });
      this.setState((state) => ({ addGroupIndex: state.addGroupIndex + 1 }), () => {
        this.context.setContextAccordionLength({
          name: this.state.addGroupName,
          index: this.state.addGroupIndex + 1,
        });
      });
    }

    removeItem=(index) => {
      const { addGroupName } = this.state;
      this.context.setContextAddAreaDeleteSubmitData({
        addGroupName,
        addGroupIndex: index,
      });
      this.context.setContextAccordionLength({
        name: this.state.addGroupName,
        index: this.state.addGroupIndex,
      });
      this.setState((state) => ({ addGroupIndex: state.addGroupIndex - 1 < 0 ? 0 : state.addGroupIndex - 1 }));
    }

    render() {
      const { subtitle, addGroupName } = this.state;
      const buttonDisabled = this.context.isHardwareDetail ? !this.props.appConfig.disabled : true;

      return (
        [...Array(this.state.addGroupIndex + 1)].map((e, addGroupIndex, array) => (
          <HardwareAddRowGridComp container key={uuidv4()}>
            <div key={uuidv4()} className="header">
              { subtitle && <h4> {addGroupName ? `${subtitle} ${addGroupIndex + 1}` : `${subtitle}`} </h4> }
              {buttonDisabled && addGroupName && <CustomButton key={uuidv4()} transparent onClick={() => this.removeItem(addGroupIndex)} startIcon={<TrashIcon />}>{`${subtitle} ${addGroupIndex + 1} ${tra('Sil').toLowerCase()}`}</CustomButton>}
            </div>
            { this.state.gridDataState.map((element) =>
              element.map((item, index) => (
                <Grid
                  key={uuidv4()}
                  item
                  xs={12}
                  md={6}
                  style={isWidthDown('sm', this.props.width) ? {} : index ? { paddingLeft: '65px' }
                    : { paddingRight: '65px' }}
                >
                  {item.inputArray.map((input) => this.hwaInput(input, addGroupIndex))}
                </Grid>
              )))}
            <Grid
              key={uuidv4()}
              className="footer"
            >
              {buttonDisabled && addGroupName && addGroupIndex === array.length - 1 && <CustomButton key={uuidv4()} transparent onClick={() => this.addGroup()} startIcon={<Plus width={12} />}>{`${subtitle} ${tra('Ekle').toLowerCase()}`}</CustomButton>}
            </Grid>
          </HardwareAddRowGridComp>
        ))
      );
    }
}

RowGridRender.propTypes = {
  width: PropTypes.string.isRequired,
  //   reRender: PropTypes.string.isRequired,
  //   match: PropTypes.object.isRequired,
  gridData: PropTypes.array.isRequired,
  pagesIndex: PropTypes.number.isRequired,
  appConfig: PropTypes.object.isRequired,

};

const mapStateToProps = createStructuredSelector({
  appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ..
    }, dispatch)
  )
);

const RowGridRenderWithTheme = withTheme(RowGridRender);

const RowGridRenderWithWidth = withWidth()(RowGridRenderWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(RowGridRenderWithWidth);

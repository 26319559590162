import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AccordionDetails, AccordionSummary, Grid, IconButton, MenuItem, Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { tr } from 'date-fns/locale';
import {
  Close,
  Delete,
  Menu,
} from '@material-ui/icons';
import { withTheme } from 'styled-components';
import { tra } from '../commons/utils';
import { CustomAccordion, CustomTextField, MultipleChoiseWrapper } from '../assets/styledSurveyQuestions';
import {
  Check, Circle, Copy, EditNew,
  Minus,
  Plus,
  Square,
} from '../assets/icons';
import { CustomFormControl } from '../assets/styledInput';
import Input from './Input';

class SurveyQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderFirst: 1,
      sliderLast: 5,
      lang: {
        kisaYanitMetni: tra('Kisa_Yanit_Metni'),
        uzunYanitMetni: tra('Uzun_Yanit_Metni'),
        secenekEkle: tra('Secenek_Ekle'),
        veya: tra('Veya'),
        secenegiEkle: tra('Secenegi_Ekle'), // lowerCase
        etiketIstegeBagli: tra('Etiket_Istege_Bagli'), // Etiket (İsteğe Bağlı)
        soru: tra('Soru'),
        tip: tra('Tip'),
        diger: tra('Diger'), // lowercase
        secenek: tra('Secenek'),
        ekle: tra('Ekle'), // upperCase
        ekBilgi: tra('Ek_Bilgi'),
        gerekli: tra('Gerekli'), //+
        digerSecenegiEkle: tra('Diger_Secenegi_Ekle'),
      },
    };
  }

  answerDecider = (id, options) => {
    const { sectionIndex, questionIndex } = this.props;
    const { sliderFirst, sliderLast, lang } = this.state;
    const { inputTypes } = this.props;
    const inputType = inputTypes.find((item) => item.id === id).type;

    switch (inputType) {
      case 0:
        return (
          <CustomTextField
            fullWidth
            placeholder={lang.uzunYanitMetni}
            disabled
          />
        );
      case 1:
        return (
          <MultipleChoiseWrapper>
            {options.map((opt, index) => (
              <div>
                <Circle />
                <CustomTextField
                  style={{ marginLeft: 10 }}
                  onChange={(e) => this.props.onChangeOption(e, sectionIndex, questionIndex, index)}
                  value={opt.text}
                  disabled={opt.text === lang.diger}
                  fullWidth
                  placeholder={`${index + 1}. ${lang.secenek}`}
                  InputProps={{
                    endAdornment: <IconButton onClick={() => this.props.deleteOption(sectionIndex, questionIndex, index)} style={{ padding: 0 }}><Close style={{ color: this.props.theme.text1 }} /></IconButton>,
                  }}
                />
                <div className="factor">
                  <Minus color={this.props.theme.text2} onClick={() => this.props.onChangeFactor(sectionIndex, questionIndex, index, 'dec')} />
                  <input
                    disabled
                    value={opt.factor}
                    style={{
                      width: 25,
                      border: 'none',
                      background: this.props.theme.boxBackg,
                      textAlign: 'center',
                      fontSize: 15,
                      color: this.props.theme.text1,
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                  <Plus color={this.props.theme.text2} onClick={() => this.props.onChangeFactor(sectionIndex, questionIndex, index, 'inc')} />
                </div>
              </div>
            ))}
            <div>
              <Circle />
              <p>
                <Link to="#" onClick={() => this.props.addOption(sectionIndex, questionIndex, false)}>{lang.secenekEkle}</Link> {lang.veya} <Link to="#" onClick={() => this.props.addOption(sectionIndex, questionIndex, true)}>{lang.digerSecenegiEkle}</Link>
              </p>
            </div>
          </MultipleChoiseWrapper>
        );
      case 2:
        return (
          <MultipleChoiseWrapper>
            {options.map((opt, index) => (
              <div>
                <Square />
                <CustomTextField
                  style={{ marginLeft: 10 }}
                  value={opt.text}
                  disabled={opt.text === lang.diger}
                  onChange={(e) => this.props.onChangeOption(e, sectionIndex, questionIndex, index)}
                  fullWidth
                  placeholder={`${index + 1}. ${lang.secenek}`}
                  InputProps={{
                    endAdornment: <IconButton onClick={() => this.props.deleteOption(sectionIndex, questionIndex, index)} style={{ padding: 0 }}><Close style={{ color: this.props.theme.text1 }} /></IconButton>,
                  }}
                />
                <div className="factor">
                  <Minus color={this.props.theme.text2} onClick={() => this.props.onChangeFactor(sectionIndex, questionIndex, index, 'dec')} />
                  <p>{opt.factor}</p>
                  <Plus color={this.props.theme.text2} onClick={() => this.props.onChangeFactor(sectionIndex, questionIndex, index, 'inc')} />
                </div>
              </div>
            ))}
            <div>
              <Square />
              <p>
                <Link to="#" onClick={() => this.props.addOption(sectionIndex, questionIndex, false)}>{lang.secenekEkle}</Link> {lang.veya} <Link to="#" onClick={() => this.props.addOption(sectionIndex, questionIndex, true)}>{lang.digerSecenegiEkle}</Link>
              </p>
            </div>
          </MultipleChoiseWrapper>
        );
      case 4:
        return (
          <CustomTextField
            disabled
            fullWidth
            placeholder={lang.kisaYanitMetni}
          />
        );
      case 5:
        return (
          <MultipleChoiseWrapper>
            {options.map((opt, index) => (
              <div>
                <p>{index + 1}.</p>
                <CustomTextField
                  style={{ marginLeft: 10 }}
                  fullWidth
                  disabled={opt.text === lang.diger}
                  value={opt.text}
                  onChange={(e) => this.props.onChangeOption(e, sectionIndex, questionIndex, index)}
                  placeholder={`${index + 1}. ${lang.secenek}`}
                  InputProps={{
                    endAdornment: <IconButton onClick={() => this.props.deleteOption(sectionIndex, questionIndex, index)} style={{ padding: 0 }}><Close style={{ color: this.props.theme.text1 }} /></IconButton>,
                  }}
                />
                <div className="factor">
                  <Minus color={this.props.theme.text2} onClick={() => this.props.onChangeFactor(sectionIndex, questionIndex, index, 'dec')} />
                  <p>{opt.factor}</p>
                  <Plus color={this.props.theme.text2} onClick={() => this.props.onChangeFactor(sectionIndex, questionIndex, index, 'inc')} />
                </div>
              </div>
            ))}
            <div>
              {options.length + 1}
              <p>
                <Link to="#" onClick={() => this.props.addOption(sectionIndex, questionIndex, false)}>${lang.secenek} Ekle</Link> veya <Link to="#" onClick={() => this.props.addOption(sectionIndex, questionIndex, true)}>&quot;{lang.diger}&quot; {lang.secenegiEkle}</Link>
              </p>
            </div>
          </MultipleChoiseWrapper>
        );
      case 7:
        return (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomTextField onChange={(e) => this.setState({ sliderFirst: e.target.value })} select InputProps={{ disableUnderline: true }} value={sliderFirst === null ? '' : sliderFirst}>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </CustomTextField>
              <p style={{ marginLeft: 10 }}>-</p>
              <CustomTextField onChange={(e) => this.setState({ sliderLast: e.target.value })} value={sliderLast === null ? '' : sliderLast} style={{ marginLeft: 10 }} select InputProps={{ disableUnderline: true }}>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </CustomTextField>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
              <p>{sliderFirst}</p>
              <CustomTextField
                style={{ marginLeft: 10 }}
                fullWidth
                placeholder={lang.etiketIstegeBagli}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
              <p>{sliderLast}</p>
              <CustomTextField
                style={{ marginLeft: 10 }}
                fullWidth
                placeholder={lang.etiketIstegeBagli}
              />
            </div>
          </div>
        );
      case 9:
        return (
          <CustomFormControl fullWidth variant="outlined">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr} style={{ margin: 0 }}>
              <KeyboardDatePicker
                margin="normal"
                format="MM/dd/yyyy"
              />
            </MuiPickersUtilsProvider>

          </CustomFormControl>
        );
      case 10:
        return (
          <CustomTextField
            placeholder="Time"
            type="time"
            defaultValue="07:30"
          />
        );
      default:
        return (
          <p>Hatalı Tip ({inputType})</p>
        );
    }
  }

  render() {
    const {
      type, index, question, onChangeQuestion, options, deleteQuestion, expandChange, onChangeType,
      inputTypes, onChangeDetail, onChangeNecessary, detail, copyQuestion,
    } = this.props;
    const { lang } = this.state;
    return (
      <CustomAccordion onChange={expandChange}>
        <AccordionSummary>
          <div className="summaryWrapper">
            <div className="start">
              <Menu style={{ fill: this.props.theme.text3 }} />
            </div>
            <div className="question">
              <p><span>Q{index}:</span> {question}</p>
            </div>
            <div className="end">
              <div className="notE">
                <EditNew color={this.props.theme.text3} />
              </div>
              <div className="exp">
                <Check />
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="contentWrapper">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Input
                  onChange={onChangeQuestion}
                  type="text"
                  title={lang.soru}
                  defaultVal={question}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Input
                  onChange={onChangeType}
                  type="select"
                  data={inputTypes}
                  title={lang.tip}
                  value={type}
                  // valueKey="type"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Input
                  type="text"
                  title={lang.ekBilgi}
                  onChange={onChangeDetail}
                  defaultVal={detail}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {this.answerDecider(type, options)}
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <div className="bottomActions">
                  <Tooltip title={tra('Kopyala')}>
                    <IconButton onClick={copyQuestion}>
                      <Copy width={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={tra('Sil')}>
                    <IconButton onClick={deleteQuestion}>
                      <Delete width={20} />
                    </IconButton>
                  </Tooltip>
                  <Input
                    type="switch"
                    placeholder={lang.gerekli}
                    labelPlacement="end"
                    onChange={onChangeNecessary}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </CustomAccordion>
    );
  }
}

SurveyQuestions.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  onChangeQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  onChangeOption: PropTypes.func.isRequired,
  addOption: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
  onChangeType: PropTypes.func.isRequired,
  expandChange: PropTypes.func.isRequired,
  onChangeNecessary: PropTypes.func.isRequired,
  onChangeFactor: PropTypes.func.isRequired,
  onChangeDetail: PropTypes.func.isRequired,
  inputTypes: PropTypes.array.isRequired,
  detail: PropTypes.string.isRequired,
  copyQuestion: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

SurveyQuestions.defaultProps = {

};

const SurveyQuestionstWithTheme = withTheme(SurveyQuestions);

export default SurveyQuestionstWithTheme;

// RESPONSE_STATE
export const RESPONSE_STATE = 'RESPONSE_STATE';
// SET TOAST
export const SET_TOAST = 'SET_TOAST';
// SET LOGIN STATE
export const DO_LOGIN = 'DO_LOGIN';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
// SET LOGIN STATE
export const SET_LOGIN_STATE = 'SET_LOGIN_STATE';
// GET_INFO
export const GET_INFO = 'GET_INFO';
// GET_INFO_HARDWARE
export const GET_INFO_HARDWARE = 'GET_INFO_HARDWARE';
// GET_INFO_MONITORING
export const GET_INFO_MONITORING = 'GET_INFO_MONITORING';
// SET_INFO
export const SET_INFO = 'SET_INFO';
// CREATE_INFO
export const CREATE_INFO = 'CREATE_INFO';
// CHANGE_INFO_STATU
export const CHANGE_INFO_STATU = 'CHANGE_INFO_STATU';

// USERS CONSTANTS
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const USERS_OPERATION = 'USERS_OPERATION';
export const USER_INIT = 'USER_INIT';
export const CREATE_USER = 'CREATE_USER';
export const USER_STATUS_CHANGE = 'USER_STATUS_CHANGE';

export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';

// PROFILES CONSTANTS
export const PROFIL_INIT = 'PROFIL_INIT';

export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO';
export const GET_PROFILE_HARDWARE = 'GET_PROFILE_HARDWARE';
export const SET_PROFILE_HARDWARE = 'SET_PROFILE_HARDWARE';
export const GET_PROFILE_APPLICATION = 'GET_PROFILE_APPLICATION';
export const SET_PROFILE_APPLICATION = 'SET_PROFILE_APPLICATION';
export const GET_PROFILE_SURVEY = 'GET_PROFILE_SURVEY';
export const SET_PROFILE_SURVEY = 'SET_PROFILE_SURVEY';
export const GET_PROFILE_LOG = 'GET_PROFILE_LOG';
export const SET_PROFILE_LOG = 'SET_PROFILE_LOG';

// APPLICATIONS CONSTANTS
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_APP_WITH_FILE = 'CREATE_APP_WITH_FILE';
export const GET_APPLICATION_DETAIL = 'GET_APPLICATION_DETAIL';
export const SET_APPLICATION_DETAIL = 'SET_APPLICATION_DETAIL';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const ARCHIVE_APPLICATION = 'ARCHIVE_APPLICATION';
export const UNARCHIVE_APPLICATION = 'UNARCHIVE_APPLICATION';
export const FOLLOW_APPLICATION = 'FOLLOW_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';

// APPLICATIONS CONSTANTS_LOG
export const GET_APPLICATIONS_LOG = 'GET_APPLICATIONS_LOG';
export const SET_APPLICATIONS_LOG = 'SET_APPLICATIONS_LOG';

// COMMENTS CONSTANTS
export const GET_COMMENTS = 'GET_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const DO_COMMENT = 'DO_COMMENT';
// UPLOAD_PROFILE
export const UPLOAD_PROFILE = 'UPLOAD_PROFILE';
// UPLOAD_APP_FILE
export const UPLOAD_APP_FILE = 'UPLOAD_APP_FILE';

// SET_RESET_STATE
export const SET_RESET_STATE = 'SET_RESET_STATE';

// COMPANY_REGISTER
export const COMPANY_REGISTER = 'COMPANY_REGISTER';

// DASHBOARD CONSTANTS
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const SET_DASHBOARD = 'SET_DASHBOARD';

// COSTGRAPH CONSTANTS
export const GET_COSTGRAPH = 'GET_COSTGRAPH';
export const SET_COSTGRAPH = 'SET_COSTGRAPH';

// ADDITIONALCOST CONSTANTS
export const GET_ADDITIONALCOST = 'GET_ADDITIONALCOST';
export const SET_ADDITIONALCOST = 'SET_ADDITIONALCOST';

// VENDORPROCESSMATRİX CONSTANTS
export const GET_VENDORPROCESSMATRIX = 'GET_VENDORPROCESSMATRİX';
export const SET_VENDORPROCESSMATRIX = 'SET_VENDORPROCESSMATRİX';

// APPCONFIG CONSTANTS
export const GET_APPCONFIG = 'GET_APPCONFIG';
export const SET_APPCONFIG = 'SET_APPCONFIG';

// CALENDAR CONSTANTS
export const GET_CALENDAR = 'GET_CALENDAR';
export const SET_CALENDAR = 'SET_CALENDAR';

// TIME MODEL
export const GET_TIME_MODEL = 'GET_TIME_MODEL';
export const SET_TIME_MODEL = 'SET_TIME_MODEL';

// FILTER DRAWER
export const SET_FILTER_DRAWER = 'SET_FILTER_DRAWER';

// EXCEL
export const EXCEL_DOWNLOAD = 'EXCEL_DOWNLOAD';
export const EXCEL_UPLOAD = 'EXCEL_UPLOAD';
export const ADMIN_EXCEL_DOWNLOAD = 'ADMIN_EXCEL_DOWNLOAD';
export const ADMIN_EXCEL_UPLOAD = 'ADMIN_EXCEL_UPLOAD';
export const SET_EXCEL_ERROR_LIST = 'SET_EXCEL_ERROR_LIST';
export const SET_EXCEL_ERROR_MODAL = 'SET_EXCEL_ERROR_MODAL';

// EVENT_CALENDAR
export const CREATE_EVENT = 'CREATE_EVENT';
export const SET_EVENT_CREATE = 'SET_EVENT_CREATE';
export const GET_EVENTS = 'GET_EVENTS';
export const SET_EVENTS = 'SET_EVENTS';

// START_REQUIRED_SERVICES
export const START_REQUIRED_SERVICES = 'START_REQUIRED_SERVICES';

// ADMIN SURVEY
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const GET_SINGLE_SURVEY = 'GET_SINGLE_SURVEY';
export const SET_SINGLE_SURVEY = 'SET_SINGLE_SURVEY';
export const GET_SURVEY_LIST = 'GET_SURVEY_LIST';
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST';
export const GET_SURVEY_DETAIL = 'GET_SURVEY_DETAIL';
export const SET_SURVEY_DETAIL = 'SET_SURVEY_DETAIL';
export const GET_SINGLE_SURVEY_DETAIL = 'GET_SINGLE_SURVEY_DETAIL';
export const SET_SINGLE_SURVEY_DETAIL = 'SET_SINGLE_SURVEY_DETAIL';
export const SET_SURVEY_ASSIGN_DATA = 'SET_SURVEY_ASSIGN_DATA';
export const GET_SURVEY_USERS = 'GET_SURVEY_USERS';
export const SET_SURVEY_USERS = 'SET_SURVEY_USERS';
export const END_SURVEY = 'END_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const GET_SURVEY_DETAIL_APPS = 'GET_SURVEY_DETAIL_APPS';
export const SET_SURVEY_DETAIL_APPS = 'SET_SURVEY_DETAIL_APPS';
export const GET_SURVEY_REPORT_QUESTIONS = 'GET_SURVEY_REPORT_QUESTIONS';
export const SET_SURVEY_REPORT_QUESTIONS = 'SET_SURVEY_REPORT_QUESTIONS';
export const GET_ANSWER_OPTION_USERS = 'GET_ANSWER_OPTION_USERS';
export const SET_ANSWER_OPTION_USERS = 'SET_ANSWER_OPTION_USERS';

// SOCKETS
export const SET_SOCKET = 'SET_SOCKET';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';

// PRIVATE SURVEY
export const GET_ASSIGNED_SURVEYS = 'GET_ASSIGNED_SURVEYS';
export const SET_ASSIGNED_SURVEYS = 'SET_ASSIGNED_SURVEYS';
export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS';
export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS';
export const ANSWER_SURVEY = 'ANSWER_SURVEY';

// LOADING
export const SET_LOADING = 'SET_LOADING';

// LOADING
export const SET_INIT_LOADING = 'SET_INIT_LOADING';

// DELETE FILE
export const DELETE_FILE = 'DELETE_FILE';
// PASSWORD
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// ADMIN COMPANY
export const COMPANY_LOGO_UPLOAD = 'COMPANY_LOGO_UPLOAD';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const GET_ADMIN_COMPANIES = 'GET_ADMIN_COMPANIES';
export const SET_ADMIN_COMPANIES = 'SET_ADMIN_COMPANIES';
export const GET_ADMIN_COMPANY_USERS = 'GET_ADMIN_COMPANY_USERS';
export const SET_ADMIN_COMPANY_USERS = 'SET_ADMIN_COMPANY_USERS';
export const ADD_USER_TO_COMPANY = 'ADD_USER_TO_COMPANY';
export const GET_COMPANY_DEPARTMENTS = 'GET_COMPANY_DEPARTMENTS';
export const SET_COMPANY_DEPARTMENTS = 'SET_COMPANY_DEPARTMENTS';

export const ADD_COMPANY_MODAL = 'ADD_COMPANY_MODAL';

// INTEGRATION
export const GET_INTEGRATION_COMPONENTS = 'GET_INTEGRATION_COMPONENTS';
export const SET_INTEGRATION_COMPONENTS = 'SET_INTEGRATION_COMPONENTS';
export const GET_INTEGRATION_COMPONENTS_DETAIL = 'GET_INTEGRATION_COMPONENTS_DETAIL';
export const SET_INTEGRATION_COMPONENTS_DETAIL = 'SET_INTEGRATION_COMPONENTS_DETAIL';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const GET_INTEGRATION = 'GET_INTEGRATION';
export const SET_INTEGRATION = 'SET_INTEGRATION';
export const GET_INTEGRATION_EXCEL = 'GET_INTEGRATION_EXCEL';
export const GET_INTEGRATION_EXCEL_SAMPLE = 'GET_INTEGRATION_EXCEL_SAMPLE';
export const UPLOAD_INTEGRATION_EXCEL = 'UPLOAD_INTEGRATION_EXCEL';
export const GET_INTEGRATION_COMPONENTS_DETAIL_USER = 'GET_INTEGRATION_COMPONENTS_DETAIL_USER';
export const SET_INTEGRATION_COMPONENTS_DETAIL_USER = 'SET_INTEGRATION_COMPONENTS_DETAIL_USER';
export const SAVE_INTEGRATION_COMPONENTS_DETAIL_USER = 'SAVE_INTEGRATION_COMPONENTS_DETAIL_USER';
export const UPLOAD_INTEGRATION_FILE = 'UPLOAD_INTEGRATION_FILE';
export const GET_INTEGRATION_MESSAGES = 'GET_INTEGRATION_MESSAGES';
export const SET_INTEGRATION_MESSAGES = 'SET_INTEGRATION_MESSAGES';
export const GET_INTEGRATION_CHANNEL = 'GET_INTEGRATION_CHANNEL';
export const SET_INTEGRATION_CHANNEL = 'SET_INTEGRATION_CHANNEL';

// SURVEY ANSWER BOOKMARK
export const SET_SURVEY_ANSWER_BOOKMARK = 'SET_SURVEY_ANSWER_BOOKMARK';
// COMPANY REGISTER INIT
export const GET_REG_COMP_INIT = 'GET_REG_COMP_INIT';
export const SET_REG_ACCOUNT_TYPES = 'SET_REG_ACCOUNT_TYPES';
export const SET_REG_LANGUAGES = 'SET_REG_LANGUAGES';
export const SET_REG_CURRENCIES = 'SET_REG_CURRENCIES';
export const SET_REGISTER_COMPLETE = 'SET_REGISTER_COMPLETE';

// COMPANY ACTIVATION
export const COMPANY_ACTIVATION = 'COMPANY_ACTIVATION';
// RESEND_ACTIVATION_CODE
export const RESEND_ACTIVATION_CODE = 'RESEND_ACTIVATION_CODE';
// SET_ACTIVATION_COMPLETE
export const SET_ACTIVATION_COMPLETE = 'SET_ACTIVATION_COMPLETE';
// SET_PASSWORD_RESET_COMPLETE
export const SET_PASSWORD_RESET_COMPLETE = 'SET_PASSWORD_RESET_COMPLETE';

// DOWNLOAD_INVENTORY_DATA
export const DOWNLOAD_INVENTORY_DATA = 'DOWNLOAD_INVENTORY_DATA';

// SET_INVENTORY_REGISTER_FILES
export const SET_INVENTORY_REGISTER_FILES = 'SET_INVENTORY_REGISTER_FILES';
// SET_INVENTORY_REGISTER_FILE_URLS
export const SET_INVENTORY_REGISTER_FILE_URLS = 'SET_INVENTORY_REGISTER_FILE_URLS';

// SET_ACTIVE_NOT_FOUND
export const SET_ACTIVE_NOT_FOUND = 'SET_ACTIVE_NOT_FOUND';

// HARDWARE CONSTANTS
export const CREATE_HARDWARE_SERVER = 'CREATE_HARDWARE_SERVER';

// HARDWARE MODAL TYPES
export const GET_HARDWARE_MODAL_TYPES = 'GET_HARDWARE_MODAL_TYPES';
export const SET_HARDWARE_MODAL_TYPES = 'SET_HARDWARE_MODAL_TYPES';
// HARDWARE MODAL TYPES CATEGORY
export const GET_HARDWARE_MODAL_CATEGORY = 'GET_HARDWARE_MODAL_CATEGORY';
export const SET_HARDWARE_MODAL_CATEGORY = 'SET_HARDWARE_MODAL_CATEGORY';

// HARDWARE DEVICES
export const SET_HARDWARE_DEVICES = 'SET_HARDWARE_DEVICES';
export const GET_HARDWARE_DEVICES = 'GET_HARDWARE_DEVICES';

// HARDWARE DEVICES DETAIL
export const SET_HARDWARE_DEVICE_DETAIL = 'SET_HARDWARE_DEVICE_DETAIL';
export const GET_HARDWARE_DEVICE_DETAIL = 'GET_HARDWARE_DEVICE_DETAIL';

// UPDATE HARDWARE DEVICES DETAIL
export const UPDATE_HARDWARE_DEVICE = 'UPDATE_HARDWARE_DEVICE';
// CREATE_COSTING
export const CREATE_COSTING = 'CREATE_COSTING';
// GET_COSTING_CATEGORIES
export const GET_COSTING_CATEGORIES = 'GET_COSTING_CATEGORIES';
// SET_COSTING_CATEGORIES
export const SET_COSTING_CATEGORIES = 'SET_COSTING_CATEGORIES';
// GET_COSTING
export const GET_COSTING = 'GET_COSTING';
// SET_COSTING
export const SET_COSTING = 'SET_COSTING';
// GET_COSTING_DETAIL
export const GET_COSTING_DETAIL = 'GET_COSTING_DETAIL';
// SET_COSTING_DETAIL
export const SET_COSTING_DETAIL = 'SET_COSTING_DETAIL';
// SET_COSTING_FILES
export const SET_COSTING_FILES = 'SET_COSTING_FILES';
// SET_COSTING_FILE_URLS
export const SET_COSTING_FILE_URLS = 'SET_COSTING_FILE_URLS';
// GET_APPLICATION_CONTRACTS
export const GET_APPLICATION_CONTRACTS = 'GET_APPLICATION_CONTRACTS';
// SET_APPLICATION_CONTRACTS
export const SET_APPLICATION_CONTRACTS = 'SET_APPLICATION_CONTRACTS';
// CHANGE_APP_STATUS
export const CHANGE_APP_STATUS = 'CHANGE_APP_STATUS';
// UPDATE_COSTING
export const UPDATE_COSTING = 'UPDATE_COSTING';
// DELETE_COSTING
export const DELETE_COSTING = 'DELETE_COSTING';
// UPLOAD_COST_FILE
export const UPLOAD_COST_FILE = 'UPLOAD_COST_FILE';
// DELETE_COST_FILE
export const DELETE_COST_FILE = 'DELETE_COST_FILE';
// GET_COSTING_LOGS
export const GET_COSTING_LOGS = 'GET_COSTING_LOGS';
// SET_COSTING_LOGS
export const SET_COSTING_LOGS = 'SET_COSTING_LOGS';
// HARDWARE CONSTANTS_LOG
export const GET_HARDWARE_LOG = 'GET_HARDWARE_LOG';
export const SET_HARDWARE_LOG = 'SET_HARDWARE_LOG';
// GET_COST_GROUP
export const GET_COST_GROUP = 'GET_COST_GROUP';
// SET_COST_GROUP
export const SET_COST_GROUP = 'SET_COST_GROUP';
// GET_APPLICATION_DRAFTS
export const GET_APPLICATION_DRAFTS = 'GET_APPLICATION_DRAFTS';
// SET_APPLICATION_DRAFTS
export const SET_APPLICATION_DRAFTS = 'SET_APPLICATION_DRAFTS';
// SET_INVENTORY_CONFIG
export const SET_INVENTORY_CONFIG = 'SET_INVENTORY_CONFIG';
// SET_COSTING_CONFIG
export const SET_COSTING_CONFIG = 'SET_COSTING_CONFIG';
// GET_APPLICATION_DRAFT
export const GET_APPLICATION_DRAFT = 'GET_APPLICATION_DRAFT';
// SET_APPLICATION_DRAFT
export const SET_APPLICATION_DRAFT = 'SET_APPLICATION_DRAFT';
// UPDATE_APPLICATION_DRAFT
export const UPDATE_APPLICATION_DRAFT = 'UPDATE_APPLICATION_DRAFT';
// UPLOAD_APP_DRAFT_FILE
export const UPLOAD_APP_DRAFT_FILE = 'UPLOAD_APP_DRAFT_FILE';
// GET_APPLICATION_LIFECYCLE
export const GET_APPLICATION_LIFECYCLE = 'GET_APPLICATION_LIFECYCLE';
// SET_APPLICATION_LIFECYCLE
export const SET_APPLICATION_LIFECYCLE = 'SET_APPLICATION_LIFECYCLE';
// SET_INTEGRATION_REGISTER_FILE_URLS
export const SET_INTEGRATION_REGISTER_FILE_URLS = 'SET_INTEGRATION_REGISTER_FILE_URLS';
// GET_INTEGRATION_REGISTER_FILE_URLS
export const GET_INTEGRATION_REGISTER_FILE_URLS = 'GET_INTEGRATION_REGISTER_FILE_URLS';
// GET_INTEGRATION_REGISTER_FILES
export const GET_INTEGRATION_REGISTER_FILES = 'GET_INTEGRATION_REGISTER_FILES';
// SET_INTEGRATION_REGISTER_FILES
export const SET_INTEGRATION_REGISTER_FILES = 'SET_INTEGRATION_REGISTER_FILES';

// GET_HWCOSTING_CATEGORIES
export const GET_HWCOSTING_CATEGORIES = 'GET_HWCOSTING_CATEGORIES';
// SET_HWCOSTING_CATEGORIES
export const SET_HWCOSTING_CATEGORIES = 'SET_HWCOSTING_CATEGORIES';
// GET_HWCOSTING_EXPENSES
export const GET_HWCOSTING_EXPENSES = 'GET_HWCOSTING_EXPENSES';
// SET_HWCOSTING_EXPENSES
export const SET_HWCOSTING_EXPENSES = 'SET_HWCOSTING_EXPENSES';
// CREATE_HWCOSTING
export const CREATE_HWCOSTING = 'CREATE_HWCOSTING';
// GET_HWCOSTING_LIST
export const GET_HWCOSTING_LIST = 'GET_HWCOSTING_LIST';
// SET_HWCOSTING_LIST
export const SET_HWCOSTING_LIST = 'SET_HWCOSTING_LIST';
// GET_HWCOSTING_DETAIL
export const GET_HWCOSTING_DETAIL = 'GET_HWCOSTING_DETAIL';
// SET_HWCOSTING_DETAIL
export const SET_HWCOSTING_DETAIL = 'SET_HWCOSTING_DETAIL';
// UPDATE_HWCOSTING
export const UPDATE_HWCOSTING = 'UPDATE_HWCOSTING';
// DELETE_HWCOSTING
export const DELETE_HWCOSTING = 'DELETE_HWCOSTING';
// GET_HWCOSTING_LOGS
export const GET_HWCOSTING_LOGS = 'GET_HWCOSTING_LOGS';
// SET_HWCOSTING_LOGS
export const SET_HWCOSTING_LOGS = 'SET_HWCOSTING_LOGS';
// UPLOAD_HWCOST_FILE
export const UPLOAD_HWCOST_FILE = 'UPLOAD_HWCOST_FILE';
// DELETE_HWCOST_FILE
export const DELETE_HWCOST_FILE = 'DELETE_HWCOST_FILE';

// GET_LDAP_USERS
export const GET_LDAP_USERS = 'GET_LDAP_USERS';
// SET_LDAP_USERS
export const SET_LDAP_USERS = 'SET_LDAP_USERS';
// CREATE_LDAP_USER
export const CREATE_LDAP_USER = 'CREATE_LDAP_USER';

// SET_SEND_TO_DETAIL_PAGE_ID
export const SET_SEND_TO_DETAIL_PAGE_ID = 'SET_SEND_TO_DETAIL_PAGE_ID';

// GET_CONTRACT_YEARS
export const GET_CONTRACT_YEARS = 'GET_CONTRACT_YEARS';
// SET_CONTRACT_YEARS
export const SET_CONTRACT_YEARS = 'SET_CONTRACT_YEARS';
// SET_SEND_TO_VIEW
export const SET_SEND_TO_VIEW = 'SET_SEND_TO_VIEW';

// GET_VENDOR_USERS
export const GET_VENDOR_USERS = 'GET_VENDOR_USERS';
// SET_VENDOR_USERS
export const SET_VENDOR_USERS = 'SET_VENDOR_USERS';
// CREATE_VENDOR_USER
export const CREATE_VENDOR_USER = 'CREATE_VENDOR_USER';
// UPDATE_VENDOR_USER
export const UPDATE_VENDOR_USER = 'UPDATE_VENDOR_USER';
// VENDOR_USER_STATUS_CHANGE
export const VENDOR_USER_STATUS_CHANGE = 'VENDOR_USER_STATUS_CHANGE';
// DELETE_VENDOR_USER
export const DELETE_VENDOR_USER = 'DELETE_VENDOR_USER';

// GET_APPLICATION_REPORTS
export const GET_APPLICATION_REPORTS = 'GET_APPLICATION_REPORTS';
// SET_APPLICATION_REPORTS
export const SET_APPLICATION_REPORTS = 'SET_APPLICATION_REPORTS';
// GET_AGGREGATION_REPORTS
export const GET_AGGREGATION_REPORTS = 'GET_AGGREGATION_REPORTS';
// SET_AGGREGATION_REPORTS
export const SET_AGGREGATION_REPORTS = 'SET_AGGREGATION_REPORTS';
// GET_HW_AGGREGATION_REPORTS
export const GET_HW_AGGREGATION_REPORTS = 'GET_HW_AGGREGATION_REPORTS';
// SET_HW_AGGREGATION_REPORTS
export const SET_HW_AGGREGATION_REPORTS = 'SET_HW_AGGREGATION_REPORTS';
// GET_USER_REPORTS
export const GET_USER_REPORTS = 'GET_USER_REPORTS';
// SET_USER_REPORTS
export const GET_USERS_REPORTS = 'GET_USERS_REPORTS';
// SET_USER_REPORTS
export const SET_USER_REPORTS = 'SET_USER_REPORTS';
// SET_USERS_REPORTS
export const SET_USERS_REPORTS = 'SET_USERS_REPORTS';
// GET_DEPARTMENT_REPORT
export const GET_DEPARTMENT_REPORT = 'GET_DEPARTMENT_REPORT';
// SET_DEPARTMENT_REPORT
export const SET_DEPARTMENT_REPORT = 'SET_DEPARTMENT_REPORT';
// SET_GENERAL_REPORT_TYPE
export const SET_GENERAL_REPORT_TYPE = 'SET_GENERAL_REPORT_TYPE';

// UPDATE_INFO
export const UPDATE_INFO = 'UPDATE_INFO';

// GET_APP_COST_REPORT
export const GET_APP_COST_REPORT = 'GET_APP_COST_REPORT';
// SET_APP_COST_REPORT
export const SET_APP_COST_REPORT = 'SET_APP_COST_REPORT';
// GET_PER_BUSINESS_CAPABILITY
export const GET_PER_BUSINESS_CAPABILITY = 'GET_PER_BUSINESS_CAPABILITY';
// SET_PER_BUSINESS_CAPABILITY
export const SET_PER_BUSINESS_CAPABILITY = 'SET_PER_BUSINESS_CAPABILITY';
// GET_PER_IT_DOMAIN
export const GET_PER_IT_DOMAIN = 'GET_PER_IT_DOMAIN';
// SET_PER_IT_DOMAIN
export const SET_PER_IT_DOMAIN = 'SET_PER_IT_DOMAIN';
// GET_PER_BUSINESS_DOMAIN
export const GET_PER_BUSINESS_DOMAIN = 'GET_PER_BUSINESS_DOMAIN';
// SET_PER_BUSINESS_DOMAIN
export const SET_PER_BUSINESS_DOMAIN = 'SET_PER_BUSINESS_DOMAIN';

// GET_DASHBOARD_MAP_REPORT
export const GET_DASHBOARD_MAP_REPORT = 'GET_DASHBOARD_MAP_REPORT';
// SET_DASHBOARD_MAP_REPORT
export const SET_DASHBOARD_MAP_REPORT = 'SET_DASHBOARD_MAP_REPORT';

// GET_GET_DASHBOARD_COSTING_DETAIL
export const GET_DASHBOARD_COSTING_DETAIL = 'GET_DASHBOARD_COSTING_DETAIL';
// SET_GET_DASHBOARD_COSTING_DETAIL
export const SET_DASHBOARD_COSTING_DETAIL = 'SET_DASHBOARD_COSTING_DETAIL';

// GET_LICENSE_FOLLOW
export const GET_LICENSE_FOLLOW = 'GET_LICENSE_FOLLOW';

// DELETE_LICENSE_FOLLOW
export const DELETE_LICENSE_FOLLOW = 'DELETE_LICENSE_FOLLOW';

// SET_LICENSE_FOLLOW
export const SET_LICENSE_FOLLOW = 'SET_LICENSE_FOLLOW';

// GET_LICENSE_FOLLOW_AGREEMENT
export const GET_LICENSE_FOLLOW_AGREEMENT = 'GET_LICENSE_FOLLOW_AGREEMENT';
// SET_LICENSE_FOLLOW_AGREEMENT
export const SET_LICENSE_FOLLOW_AGREEMENT = 'SET_LICENSE_FOLLOW_AGREEMENT';

// GET_LICENSE_FOLLOW_USER
export const GET_LICENSE_FOLLOW_USER = 'GET_LICENSE_FOLLOW_USER';
// SET_LICENSE_FOLLOW_USER
export const SET_LICENSE_FOLLOW_USER = 'SET_LICENSE_FOLLOW_USER';

// GET_LICENSE_FOLLOW_DETAIL
export const GET_LICENSE_FOLLOW_DETAIL = 'GET_LICENSE_FOLLOW_DETAIL';
// SET_LICENSE_FOLLOW_DETAIL
export const SET_LICENSE_FOLLOW_DETAIL = 'SET_LICENSE_FOLLOW_DETAIL';

// CREATE_LICENSE_FOLLOW
export const CREATE_LICENSE_FOLLOW = 'CREATE_LICENSE_FOLLOW';

// UPDATE_LICENSE_FOLLOW_KEY
export const UPDATE_LICENSE_FOLLOW_KEY = 'UPDATE_LICENSE_FOLLOW_KEY';
// UPDATE_LICENSE_FOLLOW_USER
export const UPDATE_LICENSE_FOLLOW_USER = 'UPDATE_LICENSE_FOLLOW_USER';

export const SET_LICENSE_FOLLOW_LOG = 'SET_LICENSE_FOLLOW_LOG';

// CREATE_ADMIN_INTEGRATION
export const CREATE_ADMIN_INTEGRATION = 'CREATE_ADMIN_INTEGRATION';

// UPDATE_ADMIN_INTEGRATION
export const UPDATE_ADMIN_INTEGRATION = 'UPDATE_ADMIN_INTEGRATION';

// DELETE_ADMIN_INTEGRATION
export const DELETE_ADMIN_INTEGRATION = 'DELETE_ADMIN_INTEGRATION';

// SET_INTEGRATION_PI_URL
export const SET_INTEGRATION_PI_URL = 'SET_INTEGRATION_PI_URL';

// GET_INTEGRATION_PI_URL
export const GET_INTEGRATION_PI_URL = 'GET_INTEGRATION_PI_URL';

// UPDATE_INTEGRATION_PI_DATA
export const UPDATE_INTEGRATION_PI_DATA = 'UPDATE_INTEGRATION_PI_DATA';

// GET_INTEGRATION_MESSAGE_EXCEL
export const GET_INTEGRATION_MESSAGE_EXCEL = 'GET_INTEGRATION_MESSAGE_EXCEL';

// SET_INTEGRATION_COMPONENT_INIT_DATA
export const SET_INTEGRATION_COMPONENT_INIT_DATA = 'SET_INTEGRATION_COMPONENT_INIT_DATA';

// GET_INTEGRATION_RESPONSIBLE
export const GET_INTEGRATION_RESPONSIBLE = 'GET_INTEGRATION_RESPONSIBLE';

// SET_INTEGRATION_RESPONSIBLE
export const SET_INTEGRATION_RESPONSIBLE = 'SET_INTEGRATION_RESPONSIBLE';

// UPDATE_INTEGRATION_MESSAGES
export const UPDATE_INTEGRATION_MESSAGES = 'UPDATE_INTEGRATION_MESSAGES';

// GET_INTEGRATION_MESSAGE_LOG
export const GET_INTEGRATION_MESSAGE_LOG = 'GET_INTEGRATION_MESSAGE_LOG';

// SET_INTEGRATION_MESSAGE_LOG
export const SET_INTEGRATION_MESSAGE_LOG = 'SET_INTEGRATION_MESSAGE_LOG';

// CREATE_INTEGRATION_MESSAGE_LOG
export const CREATE_INTEGRATION_MESSAGE_LOG = 'CREATE_INTEGRATION_MESSAGE_LOG';

// DELETE_INTEGRATION_MESSAGE_LOG
export const DELETE_INTEGRATION_MESSAGE_LOG = 'DELETE_INTEGRATION_MESSAGE_LOG';

// GET_MESSAGE_MONITORING_SEND_MAIL_USERS
export const GET_MESSAGE_MONITORING_SEND_MAIL_USERS = 'GET_MESSAGE_MONITORING_SEND_MAIL_USERS';

// SET_MESSAGE_MONITORING_SEND_MAIL_USERS
export const SET_MESSAGE_MONITORING_SEND_MAIL_USERS = 'SET_MESSAGE_MONITORING_SEND_MAIL_USERS';

// DELETE_MESSAGE_MONITORING_SEND_MAIL_USERS
export const DELETE_MESSAGE_MONITORING_SEND_MAIL_USERS = 'DELETE_MESSAGE_MONITORING_SEND_MAIL_USERS';

// CREATE_MESSAGE_MONITORING_SEND_MAIL_USERS
export const CREATE_MESSAGE_MONITORING_SEND_MAIL_USERS = 'CREATE_MESSAGE_MONITORING_SEND_MAIL_USERS';

// GET_MESSAGE_MONITORING_SEND_MAIL_LOG
export const GET_MESSAGE_MONITORING_SEND_MAIL_LOG = 'GET_MESSAGE_MONITORING_SEND_MAIL_LOG';

import PropTypes from "prop-types"
import { Accordion, AccordionDetails, AccordionSummary, isWidthDown, withWidth } from '@material-ui/core';
import { compose } from 'redux';
import React, { useState } from 'react';

import { CustomButton, CustomDrawer, FilteredSidebar } from '../../../assets/styledNew';
import { tra } from '../../../commons/utils';
import Input from '../../../components/Input'
import { LeftArrow } from '../../../assets/icons';
import { ExpandMore } from '@material-ui/icons';
import { useSelector } from 'react-redux';

function ChartFilterDrawerMenu(props) {

  //STORE
  const integrationInitComponentData = useSelector(s => s?.integrationInitComponentData)

  const initFilter = {
    senderFilterArr: [],
    recevierFilterArr: [],
    recevierFilterVal: '',
    senderFilterVal: ''
  }

  //STATE
  const [filterData, setFilterData] = useState(initFilter)



  const { senderFilterArr, recevierFilterArr, recevierFilterVal, senderFilterVal } = filterData ?? {}

  const data = JSON.parse(JSON.stringify(integrationInitComponentData))
  
  const limit = 50
  const selectedTopPinData = (selectedArray) => [data.filter(i => selectedArray?.some(p => p === i?.id)), data.filter(i => !selectedArray?.some(p => p === i?.id))].flat()
  const filtered = (val, selectedArray) => val ? selectedTopPinData(selectedArray).filter(i => i?.name?.toLowerCase()?.includes(val?.toLowerCase())) : selectedTopPinData(selectedArray).slice(0, limit)

  const handleCheckboxChange = (item, stateName) => () => {
    const state = filterData?.[stateName] || []
    if (state?.some(p => p === item.id) ?? false)
      return setFilterData((s) => ({ ...s, [stateName]: state.filter(deleteItem => deleteItem !== item.id) }))
    return setFilterData((s) => ({ ...s, [stateName]: [...new Set([...s[stateName], item.id])] }))
  }

  const resetChartFiltered = () => {
    setFilterData(initFilter)
    props.onSubmit()
  }

  const updateChartFiltered = () => props.onSubmit({
    senders: senderFilterArr,
    receivers: recevierFilterArr
  })


  return (
    <CustomDrawer
      anchor="left"
      open={props.open}
      onClose={() => props.onClose()}
    >
      <FilteredSidebar>
        <div className="title">
          {tra('Filtre')}
          <CustomButton onClick={() => props.onClose()} startIcon={<LeftArrow width={12} />}>{tra('Geri')}</CustomButton>
        </div>
        <div className="filterContent">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <p>{tra('Sender')}</p>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 200, overflow: 'auto' }}>
              <Input
                type="text"
                placeholder={tra('Veri_Ara')}
                fullWidth
                value={senderFilterVal}
                onChange={(e) => {
                  e.persist();
                  setFilterData(s => ({ ...s, senderFilterVal: e?.target?.value }))
                }}
              />
              <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                {
                  filtered(senderFilterVal, senderFilterArr)?.map((i, index) => (
                    <Input
                      key={`sender${index}${i?.id}`}
                      type="checkbox"
                      title={i.name}
                      value={senderFilterArr?.some(p => p === i?.id) ?? false}
                      onChange={handleCheckboxChange(i, 'senderFilterArr')}
                    />
                  ))
                }
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <p>{tra('Recevier')}</p>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 200, overflow: 'auto' }}>
              <Input
                type="text"
                placeholder={tra('Veri_Ara')}
                fullWidth
                value={recevierFilterVal}
                onChange={(e) => {
                  e.persist();
                  setFilterData(s => ({ ...s, recevierFilterVal: e?.target?.value }))
                }}
              />
              <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                {
                  filtered(recevierFilterVal, recevierFilterArr)?.map((i, index) => (
                    <Input
                      key={`receiver${index}${i?.id}`}
                      type="checkbox"
                      title={i.name}
                      value={recevierFilterArr?.some(p => p === i?.id) ?? false}
                      onChange={handleCheckboxChange(i, 'recevierFilterArr')}
                    />
                  ))
                }
              </div>
            </AccordionDetails>
          </Accordion>

        </div>
        <div className="filterDrawefooter" style={{ display: 'block' }}>
          <CustomButton onClick={updateChartFiltered}>{tra('Uygula')}</CustomButton>
          <CustomButton transparent onClick={resetChartFiltered}>{tra('Sifirla')}</CustomButton>
        </div>
      </FilteredSidebar>
    </CustomDrawer>
  );
}

ChartFilterDrawerMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default compose(withWidth())(ChartFilterDrawerMenu)

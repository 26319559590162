import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const simcard = [[
  [{
    subtitle: tra('Simcard_Detaylari'),
    objectGroupName: 'SIMCard',
    inputArray: [
      {
        type: 'tel',
        title: tra('Gsm_No'),
        name: 'gsm_no',
        value: '1920 X 1080',
        data: 'locations',
        validateRules: 'required|min:10',
      },
      {
        type: 'text',
        title: tra('Servis_Saglayici'),
        name: 'service_provider',
        value: '1920 X 1080',
        validateRules: 'required',
      },
      {
        type: 'text',
        title: tra('Statu'),
        name: 'statu',
        value: '1920 X 1080',

      },
      {
        type: 'text',
        title: tra('Hat_Turu'),
        name: 'line_type',
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Kullanan_Kisi'),
        name: 'gsm_no',
        value: '1920 X 1080',
      },
    ],
  }],
  [
    {
      subtitle: tra('Kategori_Icerik_Detayi'),
      // objectGroupName: 'SIMCard',

      inputArray: [
        {
          type: 'text',
          title: tra('Kategori'),
          name: 'category',
          value: '1920 X 1080',
          validateRules: 'required',

        },
        {
          type: 'text',
          title: tra('Ses'),
          name: 'sound',
          value: '1920 X 1080',
          validateRules: 'required',

        },
        {
          type: 'text',
          title: tra('Data'),
          name: 'data',
          value: '1920 X 1080',
          validateRules: 'required',

        },
        {
          type: 'text',
          title: tra('Sms'),
          name: 'sms',
          value: '1920 X 1080',
          validateRules: 'required',

        },
        {
          type: 'text',
          title: tra('Pin_Kodu'),
          name: 'pin_code',
          value: '1920 X 1080',
          validateRules: 'integer|min:4',
          inputProps: {
            maxLength: 4,
          },
          childrenInput: [
            {
              type: 'text',
              title: tra('Puk_Kodu'),
              name: 'puk_code',
              value: '1920 X 1080',
              validateRules: 'integer|min:8',
              inputProps: {
                maxLength: 8,
              },
            },
          ],
        },
      ],
    }],
]];

export default [inventoryPages, simcard];

import SimpleReactValidator from 'simple-react-validator';
import { FormatCurrency, tra } from './utils';
import { templateTr } from '../assets/statics';

SimpleReactValidator.addLocale('tr', templateTr);

const lang = {
  parolanizUyusmuyor: tra('Parolaniz_Uyusmuyor'),
  adSoyadEksik: tra('AdSoyad_Eksik'),
};
export default new SimpleReactValidator({
  locale: 'tr',
  autoForceUpdate: this,
  element: false,
  validators: {
    limitControl: {
      message: `${tra('Limit_Mesaji')} - ${tra('Fark')} :values`,
      rule: (val, params) => val === parseFloat(params[0]),
      messageReplace: (message, params) => message.replace(':values', FormatCurrency(params[1])),
    },
    fullnameControl: {
      message: `${lang.adSoyadEksik}.`,
      rule: (val) => (val.split(' ').length > 1 && val.split(' ').every((i) => i.length > 1)),
    },
    passwordControl: {
      message: `${lang.parolanizUyusmuyor}.`,
      rule: (val, params) => (val === params[0]),
    },
    alpha_space_tr: {
      message: `${templateTr.alpha_space}.`,
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[A-Za-zwığüşöçĞÜŞÖÇİ\s]*$/i),
    },
    alpha_tr: {
      message: `${templateTr.alpha}.`,
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[A-Za-zwığüşöçĞÜŞÖÇİ]*$/i),
    },
    alpha_num_tr: {
      message: `${templateTr.alpha_num}.`,
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[A-Za-z0-9wığüşöçĞÜŞÖÇİ]*$/i),
    },
    alpha_num_space_tr: {
      message: `${templateTr.alpha_num_space}.`,
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[A-Za-z0-9\swığüşöçĞÜŞÖÇİ]*$/i),
    },
    alpha_num_dash_tr: {
      message: `${templateTr.alpha_num_dash}.`,
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[A-Za-z0-9-_wığüşöçĞÜŞÖÇİ]*$/i),
    },
    alpha_num_dash_space_tr: {
      message: `${templateTr.alpha_num_dash_space}.`,
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[A-Za-z0-9\s-_wığüşöçĞÜŞÖÇİ]*$/i),
    },
    ip: { // name the rule
      // message: tra('IP_adress'),
      message: tra('IP_adress'),
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i) && params.indexOf(val) === -1,
      messageReplace: (message, params) => message.replace(':name', params.join()), // optional
      // required: true, // optional
    },
    macAdress: { // name the rule
      message: tra('IP_adress'),
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/) && params.indexOf(val) === -1,
      messageReplace: (message, params) => message.replace(':name', params.join()), // optional
      required: true, // optional
    },
    customCurrency: { // name the rule
      message: `${templateTr.currency}`,
      // rule: (val, params, validator) => validator.helpers.testRegex(val, /^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.\d{3})?)*((?=.*\d)(?:\,\d\d){1}?){0,1}$/i),
      rule: (val, params, validator) => validator.helpers.testRegex(val, /^[+-]?[0-9]{1,3}(?:[0-9]*(?:[.,][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?|(?:\.[0-9]{3})*(?:,[0-9]{1,2})?)$/),
      messageReplace: (message, params) => message.replace(':name', params.join()), // optional
      // required: true, // optional
    },
    groupInputControl: {
      message: ':name alanların doldurulması zorunludur',
      // rule: (val, params) => false,
      rule: (val, params) => params[1] === 'true',
      messageReplace: (message, params) => message.replace(':name', params[0]), // optional
      required: true,
    },
    lessThanNumber: {
      /**
       * less:küçüktür
       * greater:büyüktür
       * lessEqual:küçük eşittir
       * greaterEquel: büyük eşittir
       *
       * params[0]= değer(değişken adı)
       * params[1]= name
       */
      message: tra('Sayisini_Gecemez'),
      rule: (val, params) => (val < (params[0] || 0)),
      messageReplace: (message, params) => message.replace(':name', params[1]), // optional
    },
    greaterThanNumber: {
      message: tra('Sayisindan_Az_Olamaz'),
      rule: (val, params) => (val > (params[0] || 0)),
      messageReplace: (message, params) => message.replace(':name', params[1]), // optional
    },
    lessEqualThanNumber: {
      message: tra('Sayisini_Gecemez'),
      rule: (val, params) => (val <= (params[0] || 0)),
      messageReplace: (message, params) => (!params[0].includes('undefined') ? message.replace(':name', params[1]) : message.replace(/\D*/, `${params[1]} ${tra('Girilmeli')}`)),
    }, // optional
    greaterEqualThanNumber: {
      message: tra('Sayisindan_Az_Olamaz'),
      rule: (val, params) => (val >= (params[0] || 0)),
      messageReplace: (message, params) => message.replace(':name', params[1]), // optional
    },
    urlControl: {
      message: tra('Url_Control'),
      rule: (val) => {
        const urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
        const url = new RegExp(urlRegex, 'i');
        return val.length < 2083 && url.test(val);
      },
      // messageReplace: (message, params) => message.replace(':name', params[1]), // optional
    },
    closeValid: {
      message: '',
      rule: () => false,
    },
  },
});

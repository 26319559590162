import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  DialogContent, Grid,
} from '@material-ui/core';
import { withTheme } from 'styled-components';
import Input from '../Input';
import { CustomButton, CustomModal, PageContent } from '../../assets/styledNew';
import {
  selectApplications, selectInfos, selectSurveyAssignData, selectSurveyUsers,
} from '../../store/selectors';
import { setSurveyAssignData } from '../../store/actions';
import { SelectionAccordionWrapper, SelectionHeader } from '../../assets/styledSurveyQuestions';
import { Search } from '../../assets/icons';
import { tra } from '../../commons/utils';

class SurveySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDepartments: Object.keys(props.surveyAssignData).length > 0 ? props.surveyAssignData.selectedDepartments : [],
      selectedApps: Object.keys(props.surveyAssignData).length > 0 ? props.surveyAssignData.selectedApps : [],
      appSearchText: '',
      userGroupSearch: '',
      lang: {
        kaydet: tra('Kaydet'),
      },
    };
  }

  handleClearUser = (item) => {
    const { selectedDepartments } = this.state;
    const index = selectedDepartments.findIndex((slt) => slt.id === item.id);
    selectedDepartments.splice(index, 1);
    this.setState({ selectedDepartments });
  }

  addClearAllUsers = (e, dep) => {
    const { selectedDepartments } = this.state;
    if (e.target.checked) {
      selectedDepartments.push(dep);
    } else {
      const depIndex = selectedDepartments.findIndex((item) => item.id === dep.id);
      selectedDepartments.splice(depIndex, 1);
    }
    this.setState({ selectedDepartments });
  }

  handleChangeApp = (e, item) => {
    const { selectedApps } = this.state;
    if (e.target.checked) {
      selectedApps.push(item);
    } else {
      const appIndex = selectedApps.findIndex((sltApp) => sltApp.id === item.id);
      selectedApps.splice(appIndex, 1);
    }
    this.setState({ selectedApps });
  }

  handleSave = (type) => {
    const { selectedApps, selectedDepartments } = this.state;
    const obj = {
      selectedApps,
      selectedDepartments,
    };
    this.props.setSurveyAssignData(obj);
    if (type === 'button') {
      this.props.close();
    }
  }

  searchApp = (activeApps) => {
    const { appSearchText } = this.state;
    const temp = [];
    if (appSearchText !== '') {
      for (let i = 0; i < activeApps.length; ++i) {
        if (activeApps[i].name !== null && activeApps[i].name.toUpperCase().indexOf(appSearchText.toUpperCase()) > -1) {
          temp.push(activeApps[i]);
        }
      }
      return temp;
    }
    return activeApps;
  }

  searchUser = () => {
    const { appUserTypes } = this.props;
    const { userGroupSearch } = this.state;
    const temp = [];
    if (userGroupSearch !== '') {
      for (let i = 0; i < appUserTypes.length; ++i) {
        if (appUserTypes[i].name.toUpperCase().indexOf(userGroupSearch.toUpperCase()) > -1) {
          temp.push(appUserTypes[i]);
        }
      }
      return temp;
    }
    return appUserTypes;
  }

  render() {
    const {
      close, open, type, apps, appUserTypes,
    } = this.props;
    const { selectedDepartments, selectedApps } = this.state;
    const activeApps = apps.length > 0 ? apps.filter((item) => item.isRemoved !== 1) : [];
    return (
      <CustomModal onExit={() => this.handleSave()} open={open} onClose={close} maxWidth="sm" fullWidth="true" scroll="body">
        <DialogContent style={{ padding: 0 }} className="content">
          <SelectionHeader>
            <div>
              <Grid container>
                <Grid container alignItems="center" item xs={5}>
                  <h4>{type === 'user' ? tra('Kullanici_Grubu_Sec') : tra('Uygulama_Seç')}</h4>
                </Grid>
                <Grid item xs={7}>
                  {
                    type === 'user'
                      ? (
                        <Input onChange={(e) => this.setState({ userGroupSearch: e.target.value })} placeholder={tra('Kullanici_Grubu_Ara')} type="text" endAdornment={<Search />} />
                      ) : (
                        <Input onChange={(e) => this.setState({ appSearchText: e.target.value })} placeholder={tra('Uygulama_Ara')} type="text" endAdornment={<Search />} />
                      )
                  }
                </Grid>
              </Grid>
            </div>
          </SelectionHeader>
          <PageContent>
            <Grid container>
              <Grid item xs={6}>
                <SelectionAccordionWrapper style={{ borderRight: '1px solid #C1D1E2' }}>
                  <div style={{ background: 'rgba(228, 236, 247, 0.3)', padding: '0px 10px' }}>

                    {type === 'user' && appUserTypes?.length > 0 && this.searchUser().map((dep) => (
                      <Input
                        type="checkbox"
                        title={dep.name}
                        value={selectedDepartments.find((selectedDep) => selectedDep.id === dep.id) !== undefined}
                        onChange={(e) => this.addClearAllUsers(e, dep)}
                      />
                    ))}
                    {
                      type === 'app' && apps.length > 0 && this.searchApp(activeApps).map((app) => (
                        <Input
                          type="checkbox"
                          title={app.name}
                          value={selectedApps.length > 0 && selectedApps.find((sltApp) => sltApp.id === app.id) !== undefined}
                          onChange={(e) => this.handleChangeApp(e, app)}
                        />
                      ))
                    }
                  </div>
                </SelectionAccordionWrapper>
              </Grid>
              <Grid item xs={6}>
                <SelectionAccordionWrapper>
                  {
                    <>
                      {type === 'user' && selectedDepartments.map((item) => (
                        <div style={{ background: 'rgba(228, 236, 247, 0.3)', padding: '0px 10px' }}>
                          <Input
                            type="checkbox"
                            title={item.name}
                            value={item.id}
                            onChange={() => this.handleClearUser(item)}
                          />
                        </div>
                      ))}
                      {type === 'user' && selectedDepartments.length > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <CustomButton onClick={() => this.handleSave('button')} style={{ margin: 10 }}>{this.state.lang.kaydet}</CustomButton>
                        </div>
                      )}
                    </>
                  }
                  {
                    <>
                      {type === 'app' && selectedApps.map((item) => (
                        <div style={{ background: 'rgba(228, 236, 247, 0.3)', padding: '0px 10px' }}>
                          <Input
                            type="checkbox"
                            title={item.name}
                            value
                            onChange={(e) => this.handleChangeApp(e, item)}
                          />
                        </div>
                      ))}
                      {type === 'app' && selectedApps.length > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <CustomButton onClick={() => this.handleSave('button')} style={{ margin: 10 }}>{this.state.lang.kaydet}</CustomButton>
                        </div>
                      )}
                    </>
                  }
                </SelectionAccordionWrapper>
              </Grid>
            </Grid>
          </PageContent>
        </DialogContent>
      </CustomModal>
    );
  }
}

SurveySelection.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  apps: PropTypes.array.isRequired,
  appUserTypes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  setSurveyAssignData: PropTypes.func.isRequired,
  surveyAssignData: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  apps: selectApplications(),
  surveyUsers: selectSurveyUsers(),
  surveyAssignData: selectSurveyAssignData(),
  appUserTypes: selectInfos('AppUserTypes'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setSurveyAssignData,
    }, dispatch)
  )
);
const SurveySelectionWithTheme = withTheme(SurveySelection);
export default connect(mapStateToProps, mapDispatchToProps)(SurveySelectionWithTheme);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Collapse, TableBody, TableHead, Tooltip,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Search } from '@material-ui/icons';
import ExpandTable, {
  ExpandableRow, ExpandTableTh, ExpandTableTr, TableTh, TableTr, ExpandTableTd, TableTd,
} from '../../assets/styledExpandTable';
import { FormatCurrency, FormatUTCLocaleDateStr, tra } from '../../commons/utils';
import Input from '../../components/Input';

const ExpandingRow = ({ item, currency }) => {
  const [open, setOpen] = useState(false);

  return useMemo(() => (
    <>
      <ExpandTableTr hover onClick={() => setOpen(!open)}>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.name}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.version}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.publisher_name}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.scope_name}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'end' }}>{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTd>
      </ExpandTableTr>
      <ExpandableRow>
        <ExpandTableTh colSpan={5}>
          <Collapse in={open}>
            <div
              style={{
                background: 'white',
                padding: 20,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <ExpandTable>
                <TableHead>
                  <TableTh style={{ textAlign: 'start' }}>{tra('Masraf_Tipi')}</TableTh>
                  <TableTh style={{ textAlign: 'start' }}>{tra('Kategori')}</TableTh>
                  <TableTh style={{ textAlign: 'start' }}>{tra('Sozlesme')}</TableTh>
                  <TableTh style={{ textAlign: 'start' }}>{tra('Servis')}</TableTh>
                  <TableTh style={{ textAlign: 'center' }}>{tra('Percentage')}</TableTh>
                  <TableTh style={{ textAlign: 'center' }}>{tra('Vendor')}</TableTh>
                  <TableTh style={{ textAlign: 'center' }}>{tra('Baslangic_Tarihi')}</TableTh>
                  <TableTh style={{ textAlign: 'center' }}>{tra('Bitis_Tarihi')}</TableTh>
                  <TableTh style={{ textAlign: 'center' }}>{tra('Full_Period')}</TableTh>
                  <TableTh style={{ textAlign: 'center' }}>{tra('Cost')} {currency}</TableTh>
                </TableHead>
                <br />
                <TableBody>
                  {item.Contracts.map((costItem) => (
                    <>
                      <TableTr fc={costItem.fc === 1}>
                        <TableTd style={{ textAlign: 'start' }}>{costItem.costing_type}</TableTd>
                        <TableTd style={{ textAlign: 'start' }}>{costItem.costing_category}</TableTd>
                        <Tooltip title={costItem.aggrement}>
                          <TableTd style={{ textAlign: 'start' }}>{costItem.aggrement}</TableTd>
                        </Tooltip>
                        <Tooltip title={costItem.service}>
                          <TableTd style={{ textAlign: 'start' }}>{costItem.service}</TableTd>
                        </Tooltip>
                        <TableTd style={{ textAlign: 'center' }}>{costItem.allocation}%</TableTd>
                        <TableTd style={{ textAlign: 'center' }}>{costItem.vendor}</TableTd>
                        <TableTd style={{ textAlign: 'center' }}>{FormatUTCLocaleDateStr(costItem.contract_start)}</TableTd>
                        <TableTd style={{ textAlign: 'center' }}>{FormatUTCLocaleDateStr(costItem.contract_end)}</TableTd>
                        <TableTd style={{ textAlign: 'center' }}>{FormatCurrency(costItem.full_period)}</TableTd>
                        <TableTd style={{ textAlign: 'center' }}>{FormatCurrency(costItem.total_inv)}</TableTd>
                      </TableTr>
                      <br />
                    </>
                  ))}
                </TableBody>
              </ExpandTable>
            </div>
          </Collapse>
        </ExpandTableTh>
      </ExpandableRow>
    </>
  ), [open]);
};

const ApplicationReport = ({ currency }) => {
  const appReports = useSelector((state) => state.applicationReports);
  const [searchText, setSearchText] = useState('');
  const [searchableList, setSearchableList] = useState([]);

  useEffect(() => {
    if (appReports.length > 0) {
      const keys = Object.keys(appReports[0]);
      setSearchableList(keys);
    }
  }, [appReports]);

  const isTableData = (tableItem, searchVal) => {
    const allReturn = [];
    for (let i = 0; i < searchableList.length; ++i) {
      if (Array.isArray(tableItem[searchableList[i]])) {
        const subArrKeys = Object.keys(tableItem[searchableList[i]][0]);
        for (let k = 0; k < subArrKeys.length; ++k) {
          const arrayTempData = tableItem[searchableList[i]].map((item) => item[subArrKeys[k]]).toString().toUpperCase().includes(searchVal.toUpperCase());
          allReturn.push(arrayTempData);
        }
      } else { allReturn.push([tableItem[searchableList[i]]].toString().toUpperCase().includes(searchVal.toUpperCase())); }
    }

    return allReturn.some((e) => e);
  };

  const searchData = (data) => {
    if (searchText.length > 1 && data && searchableList) {
      const filteredData = data.filter((tableItem) => isTableData(tableItem, searchText));
      return filteredData;
    }
    return data;
  };

  return (
    <>
      <div style={{ width: 'fit-content' }}>
        <Input startAdornment={<Search />} placeholder={tra('Arama_Yap')} type="text" onChange={(e) => setSearchText(e.target.value)} />
      </div>
      <ExpandTable>
        <TableHead>
          <TableTh style={{ textAlign: 'start' }}>{tra('Uygulama_Adı')}</TableTh>
          <TableTh style={{ textAlign: 'start' }}>{tra('Surumu')}</TableTh>
          <TableTh style={{ textAlign: 'start' }}>{tra('Publisher')}</TableTh>
          <TableTh style={{ textAlign: 'start' }}>{tra('Kapsam')}</TableTh>
          <TableTh />
        </TableHead>
        <br />
        <TableBody>
          {searchData(appReports).map((itemRow) => (
            <>
              <ExpandingRow item={itemRow} currency={currency} />
              <br />
            </>
          ))}
        </TableBody>
      </ExpandTable>
    </>
  );
};

ExpandingRow.propTypes = {
  item: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

ApplicationReport.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default ApplicationReport;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  DialogContent, // DialogTitle,
  Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  Check, Delete, Public, // CheckBoxRounded,
} from '@material-ui/icons';
import {
  CustomButton,
  CustomModal,
  ModalHeader,
  ModalContent,
  BottomSheetWrapper,
  StarRating,
  AddFileComponent,
  CustomDivider,
} from '../../assets/styledNew';

import {
  Colors,
} from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';
import {
  getIntegrationComponents,
  getIntegrationComponentsDetail,
  setToast,
  createIntegration,
  getIntegration,
  getIntegrationExcel,
  uploadIntegrationExcel,
  saveIntegrationComponentsDetailUser,
  getIntegrationComponentsDetailUser,
  setAppConfig,
  getIntegrationExcelSample,
  uploadIntegrationFile,
  setIntegrationComponentsDetail,
  setFilterDrawer,
  getIntegrationMessages,
  getIntegrationChannels,
  deleteFile,
  setIntegrationRegisterFileUrls,
} from '../../store/actions';
import {
  selectIntegration,
  selectIntegrationComponents,
  selectIntegrationComponentsDetail,
  selectIntegrationComponentsDetailUser,
  selectAppConfig,
  selectFilterDrawer,
  selectIntegrationMessages,
  selectIntegrationChannels,
  selectIntegrationRegisterFileUrls,
  selectIntegrationRegisterFiles,
  selectInfos,
  selectUsers,
} from '../../store/selectors';
import BottomSheet from '../BottomSheet';
import { DocumentPDF } from '../../assets/icons';

class IntegrationGraphicFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlText: '',
      complexityHover: 0,
      criticalityHover: 0,
      description: '',
      complexity: 0,
      criticality: 0,
      IntegrationSectors: [],
      developer: '',
      documentLinks: [],
      id: false,
      removeFile: [],
      integrationFiles: [],
      removeCheckClick: false,
      IntegrationEmails: [],
      isRemoved: 0,
    };
    this.hiddenInput = React.createRef();
  }

  componentDidUpdate() {
    if (this.props?.itegrationComponentsDetailUser && this.props?.itegrationComponentsDetailUser?.id !== this.state.id && this.props.open) {
      const setForm = {
        id: this.props.itegrationComponentsDetailUser.id,
        description: this.props.itegrationComponentsDetailUser.description,
        complexity: this.props.itegrationComponentsDetailUser.complexity,
        criticality: this.props.itegrationComponentsDetailUser.criticality,
        IntegrationEmails: this.props.itegrationComponentsDetailUser.IntegrationEmails,
        IntegrationSectors: this.props.itegrationComponentsDetailUser.Departments,
        developer: this.props.itegrationComponentsDetailUser.developer,
        documentLinks: this.props.itegrationComponentsDetailUser?.IntegrationUrls?.map((item) => item.url),
        integrationFiles: this.props.itegrationComponentsDetailUser.IntegrationFiles,
        isRemoved: this.props.itegrationComponentsDetailUser.isRemoved,
      };
      if (Object.values(setForm).some((i) => i)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ ...setForm });
      }
    }
    if (this.props?.itegrationComponentsDetailUser && this.props.itegrationComponentsDetailUser.IntegrationFiles !== this.state.integrationFiles && this.props.open) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ integrationFiles: this.props.itegrationComponentsDetailUser.IntegrationFiles });
    }
  }

  handleStarHoverChange = (num, numHover) => {
    if (num === 0 || num === null || num === undefined) {
      return numHover === -1 ? 0 : numHover;
    }
    return num;
  }

  uploadFileState = (e) => {
    const { integrationFiles } = this.props;
    const tempArr = [...integrationFiles];
    if ((e.target.files.length + integrationFiles.length) < 4) {
      for (let i = 0; i < e.target.files.length; ++i) {
        tempArr.push(e.target.files[i]);
      }
      this.props.uploadIntegrationFile(e.target.files, this.state.id);
    } else {
      this.props.setToast(true, 'You can add max 3 files', 'warning');
    }
  }

  resetState = () => {
    const reset = {
      id: false,
      urlText: '',
      complexityHover: 0,
      criticalityHover: 0,
      description: '',
      complexity: 0,
      criticality: 0,
      IntegrationEmails: [],
      IntegrationSectors: '',
      developer: '',
      documentLinks: [],
      removeFile: [],
      integrationFiles: [],
    };
    this.setState({ ...reset });
  }

  saveDetail = () => {
    const { selectedRowId } = this.props;
    const {
      description,
      complexity,
      criticality,
      IntegrationEmails,
      IntegrationSectors,
      developer,
      documentLinks,
      isRemoved,
    } = this.state;
    const data = {
      description,
      complexity,
      criticality,
      IntegrationEmails,
      IntegrationSectors,
      developer,
      IntegrationUrls: documentLinks,
      isRemoved,
    };

    // const delay = new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 1000);
    // });
    // eslint-disable-next-line no-restricted-syntax
    // for (const file of this.state.removeFile) {
    //   delay.then(() => {
    //     this.props.deleteFile(file.id, file.appId, file.type);
    //   });
    // }
    // this.props.setIntegrationRegisterFileUrls(documentLinks);
    this.props.saveIntegrationComponentsDetailUser(selectedRowId, data);
  }

  isURL = (str) => {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return str.match(regex);
  }

  saveAppUrls = () => {
    // const { integrationFileUrls } = this.props;
    const { urlText, documentLinks } = this.state;

    if (this.isURL(urlText)) {
      // const tempFileUrls = integrationFileUrls;
      documentLinks.push(urlText);
      this.setState({ urlText: '' });
      // this.props.setFileUrlArr(tempFileUrls);
      this.setState({ documentLinks });
      this.props.setIntegrationRegisterFileUrls(documentLinks);
    } else {
      this.props.setToast(true, tra('Url_Control'), 'warning');
    }
  }

  handleRemoveFile = (id, appId, fileIndex) => {
    const { removeFile, integrationFiles } = this.state;
    removeFile.push({ id, appId, type: 'integration' });
    integrationFiles.splice(fileIndex, 1);
    this.setState({ removeFile, integrationFiles });
  }

  handleDocumentLinkRemove = (index) => {
    const { documentLinks } = this.state;
    documentLinks.splice(index, 1);
    this.setState({ documentLinks });
  }

  /**
   *
   * @param {number} type 1:Sorumlu Email || 2: Bildirim Email
   * @returns
   */
  handleIntegraionEmail = (type) => (e, email) => {
    const { IntegrationEmails } = this.state;
    const emails = email.map((i) => ({ type, user_id: i.id }));
    const otherEmail = IntegrationEmails.filter((i) => i.type !== type);
    this.setState({ IntegrationEmails: [...otherEmail, ...emails] });
  }

  handleIntegrationEmailValue=(type) => {
    const userIdList = this.state.IntegrationEmails.filter((i) => i?.type === type) ?? [];

    return this.props.users?.filter((i) => userIdList?.some((v) => (v?.user_id === i?.id) ?? false)) ?? [];
  }

    contentPage = () => {
      const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
      const {
      // tabValue,
      // selectedRowId,
        criticalityHover, complexityHover, complexity, criticality, description, IntegrationEmails, IntegrationSectors,
        developer, documentLinks, integrationFiles, removeCheckClick, isRemoved,
      } = this.state;
      const { users } = this.props;
      return (
        <ContentWrapper>
          <ModalHeader>
            <h1>{tra('Integration_Credentials')}</h1>
            <Close onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
          </ModalHeader>
          <ModalContent
            style={{
              maxHeight: '80vh',
              overflowY: 'auto',
              paddingRight: '3px',
            }}
          >
            <div className="pageInner" style={{ paddingTop: 30 }}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <p style={{ fontSize: '14px' }}>{tra('Complexity')}</p>
                  <StarRating>
                    <p className="ratingNumber">{this.handleStarHoverChange(complexity, complexityHover)}</p>
                    <Rating
                      onChange={(event, newValue) => this.setState({ complexity: newValue })}
                      onChangeActive={(event, newHover) => this.setState({ complexityHover: newHover })}
                      value={complexity}
                      size="small"
                      precision={0.5}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                  </StarRating>
                </Grid>
                <Grid item xs={6}>
                  <p style={{ fontSize: '14px' }}>{tra('Criticality')}</p>
                  <StarRating>
                    <p className="ratingNumber">{this.handleStarHoverChange(criticality, criticalityHover)}</p>
                    <Rating
                      onChange={(event, newValue) => this.setState({ criticality: newValue })}
                      onChangeActive={(event, newHover) => this.setState({ criticalityHover: newHover })}
                      value={criticality}
                      size="small"
                      precision={0.5}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    />
                  </StarRating>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="select"
                    fullWidth
                    title={tra('Durum')}
                    value={isRemoved}
                    data={[
                      { id: 1, name: 'Pasif' },
                      { id: 0, name: 'Aktif' },
                    ]}
                    onChange={(e) => this.setState({ isRemoved: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    fullWidth
                    multiple
                    data={users}
                    title={tra('Responsible_email')}
                    value={this.handleIntegrationEmailValue(1)}
                    onChange={this.handleIntegraionEmail(1)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    fullWidth
                    title={tra('Sektor')}
                    value={IntegrationSectors}
                    data={this.props.departments}
                    onChange={(e, data) => this.setState({ IntegrationSectors: data })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    fullWidth
                    title={tra('Developer')}
                    value={developer}
                    onChange={(e) => this.setState({ developer: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    fullWidth
                    multiple
                    title={tra('Alert_email')}
                    data={users}
                    value={this.handleIntegrationEmailValue(2)}
                    onChange={this.handleIntegraionEmail(2)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    row={4}
                    fullWidth
                    title={tra('Aciklama')}
                    value={description}
                    onChange={(e) => this.setState({ description: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h4>{tra('Add_Document_And_Link')}</h4>
                  <CustomDivider style={{ margin: '25px 0' }} />
                  <Grid item container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <AddFileComponent onClick={() => this.hiddenInput.current.click()}>
                        <DocumentPDF width={30} />
                        <p>{tra('Dosya_Ekle')}</p>
                      </AddFileComponent>
                      <input
                        multiple
                        onClick={(e) => { e.target.value = ''; }}
                        onChange={this.uploadFileState}
                        ref={this.hiddenInput}
                        type="file"
                        style={{ display: 'none' }}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <p>
                        {tra('You_Can_Upload_File_From_Here')}. <br />
                        {tra('File_Type_Detail')}
                      </p>
                    </Grid>
                  </Grid>
                  <CustomDivider style={{ margin: '25px 0' }} />
                  <Grid item xs={12}>
                    <div style={{
                      display: 'flex', alignItems: 'center', marginTop: 40,
                    }}
                    >
                      <Input
                        type="text"
                        name="url"
                        value={this.state.urlText}
                        onChange={(e) => this.setState({ urlText: e.target.value })}
                        title="Url"
                      />
                      <CustomButton onClick={() => this.saveAppUrls()} style={{ marginLeft: 15 }}>{tra('Add_Url')}</CustomButton>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {(documentLinks || integrationFiles) && [...documentLinks || [], ...integrationFiles || []].length !== 0 ? (
                    <Table>
                      <TableHead>
                        <TableCell colSpan={4}>{integrationFiles.length} {tra('Dokuman')} / {documentLinks.length} Link</TableCell>
                      </TableHead>
                      <TableBody>
                        {integrationFiles && integrationFiles.map((file, fileIndex) => (
                          <TableRow>
                            <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                            <TableCell style={{ width: 'auto' }}><a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a></TableCell>
                            <TableCell style={{ width: 150, textAlign: 'center' }}>{(parseInt(file.size / 1024, 10))} Kb</TableCell>
                            <TableCell style={{ width: 50, textAlign: 'center' }}>
                              {removeCheckClick === fileIndex
                                ? (
                                  <div style={{ display: 'flex' }}>
                                    <IconButton
                                      onClick={() => this.props.deleteFile(file.id, this.state.id, 'integration')}
                                      style={{ padding: 5 }}
                                    >
                                      <Check style={{ color: Colors.green }} />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => this.setState({ removeCheckClick: false })}
                                      style={{ padding: 5 }}
                                    >
                                      <Close style={{ color: Colors.red }} />
                                    </IconButton>
                                  </div>
                                )
                                : (
                                  <IconButton
                                    onClick={() => this.setState({ removeCheckClick: fileIndex })}
                                    style={{ padding: 5 }}
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                            </TableCell>
                          </TableRow>
                        ))}
                        {this.state.documentLinks && this.state.documentLinks.map((url, index) => (
                          <TableRow>
                            <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                            <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                            <TableCell style={{ width: 50, textAlign: 'center' }}>
                              <IconButton
                                onClick={() => this.handleDocumentLinkRemove(index)}
                                style={{ padding: 5 }}
                              ><Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>}
                </Grid>

              </Grid>
            </div>
          </ModalContent>
          <Grid container justify="flex-end" item xs={12} style={{ padding: '5px' }}>
            <CustomButton onClick={() => this.saveDetail()} color={Colors.blue}> {tra('Kaydet')}  </CustomButton>
          </Grid>
        </ContentWrapper>
      );
    }

  displayDesktop = () => (
    <CustomModal
      onEntering={() => { }}
      open={this.props.open}
      onClose={this.props.close}
      onExit={() => this.resetState()}
      maxWidth="sm"
      fullWidth="true"
      scroll="body"
    >{this.contentPage()}
    </CustomModal>
  );

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

IntegrationGraphicFormModal.propTypes = {
  itegrationComponentsDetailUser: PropTypes.array.isRequired,
  saveIntegrationComponentsDetailUser: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setIntegrationRegisterFileUrls: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  selectedRowId: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  uploadIntegrationFile: PropTypes.func.isRequired,
  integrationFiles: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  integrationComponents: selectIntegrationComponents(),
  integrationComponentsDetail: selectIntegrationComponentsDetail(),
  itegrationComponentsDetailUser: selectIntegrationComponentsDetailUser(),
  integrations: selectIntegration(),
  appConfig: selectAppConfig(),
  filterDrawer: selectFilterDrawer(),
  integrationMessages: selectIntegrationMessages(),
  integrationChannels: selectIntegrationChannels(),
  integrationFileUrls: selectIntegrationRegisterFileUrls(),
  integrationFiles: selectIntegrationRegisterFiles(),
  departments: selectInfos('Departments'),
  users: selectUsers(),
});
const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getIntegrationComponents,
      getIntegrationComponentsDetail,
      setToast,
      createIntegration,
      getIntegration,
      getIntegrationExcel,
      uploadIntegrationExcel,
      saveIntegrationComponentsDetailUser,
      getIntegrationComponentsDetailUser,
      setAppConfig,
      getIntegrationExcelSample,
      uploadIntegrationFile,
      setIntegrationComponentsDetail,
      setFilterDrawer,
      getIntegrationMessages,
      getIntegrationChannels,
      deleteFile,
      setIntegrationRegisterFileUrls,
    }, dispatch)
  )
);
const IntegrationGraphicFormModalWithTheme = withTheme(IntegrationGraphicFormModal);
const IntegrationGraphicFormModalWithWidth = withWidth()(IntegrationGraphicFormModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGraphicFormModalWithWidth);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import { Grid } from '@material-ui/core';
// import { isThisSecond } from 'date-fns';
import Validator from '../../commons/validator';
import {
  PageWrapper, PageContent, CustomButton, HardwareDetailBottomSheet, HardwareRegisterContainerSlider, ComponentLoader,
} from '../../assets/styledNew';
import {
  createHardware, setResetState, setSendToDetailPageID, setSendToView,
} from '../../store/actions';
import HardwareStaticUIData, { hwaPathData } from '../../assets/hardwareStatic';
import { tra } from '../../commons/utils';
import PagesRender from '../../components/hardware/PagesRender';
import { HardwareContext } from '../../commons/context/HardwareContext';
// import { PlaylistAddOutlined } from '@material-ui/icons';
import {
  selectResetState, selectSendToDetailPageID, selectSendToView,
} from '../../store/selectors';
// import InputOnChange from '../../components/hardware/helpers/InputOnChange';

class HardwareRegister extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType=HardwareContext

  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
      notAnimatedSlider: true,
      unitId: false,
      sendToNewSave: false,
      // lang: {
      //   dokuman: tra('Dokuman'),
      // },
    };
    // this.inputOnChange = new InputOnChange();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pagesNumber !== state.stepIndex) return { stepIndex: props.pagesNumber };
    return null;
  }

  componentDidMount() {
    this.setState({ unitId: this.props.match.params.unit });
  }

  componentDidUpdate(prevProps, prevState) {
    const hwaUrl = new URL(window.location.href).hash.split('/') || false;
    if (prevState.stepIndex !== this.state.stepIndex) {
      // setTimeout(() => document.querySelector('.mainContent').scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth',
      // }), 900);
    }
    // const UIPagesData = HardwareStaticUIData(Number(this.props.match.params.unit)).filter((item) => item.flat(Infinity).some((e) => (e.inputArray.length !== 0 && !e.definedName) || item.flat(Infinity).some((element) => this.context.setContextDefinedControl(element.definedName))));
    // if (UIPagesData.length !== this.state.pagesLength) this.setState({ pagesLength: UIPagesData.length });
    if (prevProps.reRender !== this.props.reRender) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ notAnimatedSlider: false });
    }
    if (this.props.resetState === true && this.state.sendToNewSave) {
      this.context.setReset();
      Validator.visibleFields = [];
      this.props.setResetState(false);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sendToNewSave: false });
    }
    if (Number.isInteger(this.props.sendToDetailID) !== false && this.props.sendToView) {
      // this.props.setSendToView(false);
      this.props.history.push(`/HardwareDetail/${this.props.sendToDetailID}`);
    }
    if (this.props.match.params.unit && hwaUrl.length !== 0 && Number(hwaUrl[hwaUrl.length - 1]) !== Number(this.state.unitId)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ notAnimatedSlider: false, unitId: Number(hwaUrl[hwaUrl.length - 1]) }, () => {
        this.context.setContextPagesNumber(0);
        Validator.visibleFields = [];
      });
    }
  }

  componentWillUnmount() {
    this.context.setReset();
    Validator.visibleFields = [];
    setSendToDetailPageID(false);
  }

  handleSubmitButton = (sendToView) => {
    const { stepIndex } = this.state;
    const { type, unit } = this.props.match.params;
    const { PagesValidate, SubmitData } = this.context;

    // const postTypePath = ['server', 'network', 'lastUserProduct'];
    // if (!Object.entries(PagesValidate).map((i) => i[1]).flat(1) || this.isCheck(...Object.entries(PagesValidate).map((i) => i[1]).flat(1))) {
    if ((PagesValidate?.[stepIndex] === undefined) || (PagesValidate?.[stepIndex] && this.isCheck(...PagesValidate?.[stepIndex]))) {
      return this.props.createHardware({
        ...SubmitData,
        hardware_category_id: Number(unit),
      }, hwaPathData.find((i) => i.id === Number(type)).path,
      sendToView);
    }
    // Validator.showMessages();

    this.context.setContextParentReRender();
    return this.scrollToErrorField();
  }

  handleSubmitToFromViewDetail = () => {
    this.handleSubmitButton(true);
  }

  handleSubmitToNewSave = () => {
    this.handleSubmitButton(false);
  }

  isCheck = (...arr) => {
    const arrayValidate = [];
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      arrayValidate.push(Validator.fieldValid(element));
      Validator.showMessageFor(element);
    }
    this.setState({ asd: '' }); // bu olmassa validator geç setlenmiyor.
    return arr.length !== 0 ? arrayValidate.every((i) => i === true) : false;
  }

  /*
  *   ---------------------  slider prev next button  ---------------------
 */

  scrollToErrorField=() => {
    setTimeout(() => document.querySelector('.MuiFormHelperText-root')?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 900);
  }

  handleIncrementPages = (length) => {
    const { stepIndex } = this.state;
    const { PagesValidate, pagesNumber } = this.context;
    this.setState({ notAnimatedSlider: true });
    // boş olduğunda ya da tüm validasyonlar koşulu sağlarsa geçer
    if ((PagesValidate?.[stepIndex] === undefined) || (PagesValidate?.[stepIndex] && this.isCheck(...PagesValidate?.[stepIndex]))) {
    // return this.setState((prevState) => ({ stepIndex: (prevState.stepIndex >= length ? length : prevState.stepIndex + 1) }));
      return pagesNumber < length && this.context.setContextPagesNumber(pagesNumber + 1);
    }
    this.context.setContextParentReRender();
    // return this.scrollToErrorField();
  }

  handleDeincrementPages = () => {
    this.setState({ notAnimatedSlider: true });
    const { pagesNumber } = this.context;
    // this.setState((prevState) => ({ stepIndex: (prevState.stepIndex <= 0 ? 0 : prevState.stepIndex - 1) }));
    return pagesNumber > 0 && this.context.setContextPagesNumber(pagesNumber - 1);
  }

  /*
   *   ---------------------///  slider prev next button  ---------------------
  */

  // definedAreaFilterPages=(payload)=>payload.filter(i)

  render() {
    const { stepIndex, unitId } = this.state;
    const UIPagesData = HardwareStaticUIData(Number(unitId)).filter((item) => item.flat(Infinity).some((e) => (e.inputArray.length !== 0 && !e.definedName) || item.flat(Infinity).some((element) => this.context.setContextDefinedControl(element.definedName))));
    Validator.purgeFields();
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <HardwareRegisterContainerSlider
              sliderStepIndex={this.state.stepIndex}
              notAnimatedSlider={this.state.notAnimatedSlider}
            >
              <div className="sliderContent">
                {
                 UIPagesData
                   ? UIPagesData.map((pagesData, pagesIndex) => (
                     <PagesRender
                       pagesData={pagesData}
                       pagesIndex={pagesIndex}
                     />
                   ))
                   : <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                }

              </div>
            </HardwareRegisterContainerSlider>

            <HardwareDetailBottomSheet show>
              <Grid container>
                <Grid container justify="space-between" item xs={12}>
                  <div>
                    {stepIndex !== 0 ? <CustomButton style={{ marginRight: '5px' }} outlined onClick={() => this.handleDeincrementPages()}>{tra('Geri')}</CustomButton> : <span />}
                    <CustomButton outlined onClick={() => this.props.history.push('/HardwareInventories')}>{tra('Iptal_Et')}</CustomButton>
                  </div>
                  <div>
                    {/* {!isWidthDown('sm', this.props.width) && <CustomButton transparent style={{ marginRight: 10 }} onClick={() => this.handleSaveSurvey(0)}>{this.state.lang.dokuman}</CustomButton>} */}
                    {UIPagesData.length - 1 <= stepIndex
                      ? (
                        <div>
                          <CustomButton style={{ marginRight: '5px' }} onClick={() => this.handleSubmitToFromViewDetail()}>{tra('Save_View')}</CustomButton>
                          <CustomButton onClick={() => this.handleSubmitToNewSave()}>{tra('Save_Close')}</CustomButton>
                        </div>
                      )
                      : (
                        <CustomButton onClick={() => this.handleIncrementPages(UIPagesData.length - 1)}>{tra('Devam')}</CustomButton>
                      ) }
                  </div>
                </Grid>
              </Grid>
            </HardwareDetailBottomSheet>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

HardwareRegister.propTypes = {
  width: PropTypes.string.isRequired,
  reRender: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  createHardware: PropTypes.func.isRequired,
  pagesNumber: PropTypes.number.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  sendToDetailID: PropTypes.number.isRequired,
  setSendToView: PropTypes.func.isRequired,
  sendToView: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  resetState: selectResetState(),
  sendToDetailID: selectSendToDetailPageID(),
  sendToView: selectSendToView(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createHardware,
      setResetState,
      setSendToDetailPageID,
      setSendToView,
    }, dispatch)
  )
);

const HardwareRegisterWithTheme = withTheme(HardwareRegister);

const HardwareRegisterWithWidth = withWidth()(HardwareRegisterWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(HardwareRegisterWithWidth);

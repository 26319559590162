import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  ComponentLoader,
  PageContent, PageWrapper,
} from '../../assets/styledNew';
import {
  Clock, HalfCircle, Users,
} from '../../assets/icons';
import { getSurveyDetailApps, getSurveyReportQuestions, setSurveyDetail } from '../../store/actions';
import { selectSurveyDetailApps } from '../../store/selectors';
import {
  SurveyCard, SurveyDetailHeader, SurveyDetailHeaderBox, SurveyHeaderContainer,
} from '../../assets/styledSurveyQuestions';
import Input from '../../components/Input';
import { tra } from '../../commons/utils';
import { DragToScroll } from '../../commons/touch';

const headerApps = [
  {
    id: 1,
    text: tra('Toplam_Uygulama'),
    icon: 'users',
    key: 'application_count',
    color: '#2144D4',
    backg: 'rgba(33, 68, 212, .1)',
  },
  {
    id: 2,
    text: tra('Yanıtlanacak_Anket'),
    key: 'total_survey_count',
    icon: 'users',
    color: '#FFA900',
    backg: 'rgba(255, 169, 0, .1)',
  },
  {
    id: 3,
    text: tra('Yanıtlanan_Anket'),
    key: 'total_finish_count',
    icon: 'users',
    color: '#4C6FFF',
    backg: 'rgba(76, 111, 255, .1)',
  },
  {
    id: 4,
    text: tra('Yanıtlanmayan_Anket'),
    key: 'total_unfinish_count',
    icon: 'half',
    color: '#FF5242',
    backg: 'rgba(255, 82, 66, .1)',
  },
  {
    id: 5,
    text: tra('Yarım_Kalan_Anketler'),
    key: 'total_unfinish_count',
    icon: 'users',
    color: '#6C6C8F',
    backg: 'rgba(108, 108, 143, .1)',
  },
  {
    id: 6,
    text: tra('Soru_Sayısı'),
    key: 'total_question_count',
    icon: 'half',
    color: '#187D7D',
    backg: 'rgba(24, 125, 125, .1)',
  },
];

class SurveyDetailApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        tamamlandi: tra('Tamamlandi'),
        uygulamaAra: tra('Uygulama_Ara'),
      },
      searchText: '',
    };
    props.getSurveyDetailApps(props.match.params.id);
  }

  componentWillUnmount() {
    this.props.setSurveyDetail(false);
  }

  headerIconDecider = (icon, color) => {
    switch (icon) {
      case 'users':
        return <Users width={25} color={color} />;
      case 'half':
        return <HalfCircle width={25} color={color} />;
      case 'time':
        return <Clock width={25} color={color} />;
      default:
        return <Users width={25} color={color} />;
    }
  }

  handlePercantage = (value) => {
    const { surveyDetail } = this.props;
    if (value > 0) {
      return (value / surveyDetail.total_survey_count) * 100;
    }
    return 0;
  }

  surveyCard = (item) => {
    const { lang } = this.state;
    const { match } = this.props;
    return (
      <Link onClick={() => this.props.getSurveyReportQuestions(match.params.id, item.id)} to={`/Admin/SurveyChartDetail/${match.params.id}/${item.id}`}>
        <SurveyCard
          style={{ height: 100, margin: 10 }}
          key={item.id}
          started={item.isDraft === 0}
          bar={item.complete_count}
          volume={item.total_count}
          color={item.color}
        >
          <div className="createdCard">
            <div className="content">
              <div style={{ marginLeft: 0 }} className="info">
                <Tooltip title={item.name}>
                  <h4>{item.name}</h4>
                </Tooltip>
              </div>
            </div>
            <div className="cardBar">
              <div className="barInfo">
                <span>{lang.tamamlandi}</span>
                <span>{item.complete_count}/{item.total_count}</span>
              </div>
              <div className="progressBar" />
            </div>
          </div>
        </SurveyCard>
      </Link>
    );
  }

  handleSearch = (apps) => {
    const { searchText } = this.state;
    const temp = [];
    if (searchText !== '') {
      for (let i = 0; i < apps.length; ++i) {
        if (apps[i].name.toUpperCase().indexOf(searchText.toUpperCase()) > -1) {
          temp.push(apps[i]);
        }
      }
      return temp;
    }
    return apps;
  }

  render() {
    const { surveyDetail } = this.props;
    const { lang } = this.state;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container spacing={3}>
              <Grid container item xs={12} spacing={4}>
                <Grid item xs={9}>
                  <SurveyDetailHeader
                    answered={this.handlePercantage(surveyDetail.total_finish_count)}
                    notAnswered={this.handlePercantage(surveyDetail.total_unstart_count)}
                    halfAnswered={this.handlePercantage(surveyDetail.total_unfinish_count)}
                  >
                    <div className="info">
                      <div className="circle" />
                      <div className="title">
                        <h4>{surveyDetail.name}</h4>
                        <p>{surveyDetail.description}</p>
                      </div>
                    </div>
                    <div className="progressBar">
                      {surveyDetail.total_finish_count > 0 && <div className="answered" />}
                      {surveyDetail.total_unstart_count > 0 && <div className="notAnswered" />}
                      {surveyDetail.total_unfinish_count > 0 && <div className="halfAnswered" />}
                    </div>
                  </SurveyDetailHeader>
                </Grid>
              </Grid>
              <DragToScroll>
                <SurveyHeaderContainer container item xs={12} spacing={3}>
                  {headerApps.map(((header) => (
                    <Grid item sm xs={12}>
                      <SurveyDetailHeaderBox backg={header.backg}>
                        {this.headerIconDecider(header.icon, header.color)}
                        <p
                          style={{
                            color: header.color,
                            fontSize: 20,
                            fontWeight: 600,
                            marginTop: 10,
                          }}
                        >{surveyDetail[header.key]}
                        </p>
                        <p
                          style={{
                            color: header.color,
                            fontSize: 15,
                            fontWeight: 400,
                            marginTop: 5,
                            textTransform: 'capitalize',
                          }}
                        >{header.text}
                        </p>
                      </SurveyDetailHeaderBox>
                    </Grid>
                  )))}
                </SurveyHeaderContainer>
              </DragToScroll>
            </Grid>
            <Grid container spacing={3}>
              <Grid container justify="space-between" item xs={12}>
                <div className="search">
                  <Input onChange={(e) => this.setState({ searchText: e.target.value })} endAdornment={<Search />} placeholder={lang.uygulamaAra} type="text" />
                </div>
              </Grid>
              <DragToScroll>
                <SurveyHeaderContainer container item xs={12} spacing={3}>
                  {
                    surveyDetail ? (
                      surveyDetail.Applications && surveyDetail.Applications.length > 0 && this.handleSearch(surveyDetail.Applications).map((app) => (
                        <Grid item sm={3} xs={12}>
                          {this.surveyCard(app)}
                        </Grid>
                      ))
                    ) : (
                      <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                    )
                  }
                </SurveyHeaderContainer>
              </DragToScroll>
            </Grid>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

SurveyDetailApps.propTypes = {
  match: PropTypes.object.isRequired,
  getSurveyDetailApps: PropTypes.func.isRequired,
  surveyDetail: PropTypes.object.isRequired,
  getSurveyReportQuestions: PropTypes.func.isRequired,
  setSurveyDetail: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  surveyDetail: selectSurveyDetailApps(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getSurveyDetailApps,
      getSurveyReportQuestions,
      setSurveyDetail,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailApps);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem, TableBody, TableHead,
} from '@material-ui/core';
import {
  Search, UnfoldMore,
} from '@material-ui/icons';
import ExpandTable, {
  TableTh,
} from '../../../assets/styledExpandTable';
import { tra } from '../../../commons/utils';
import Input from '../../../components/Input';
import { CustomButton, CustomMenu } from '../../../assets/styledNew';
import { getUsersReports } from '../../../store/actions';
import ExpandingRow from './components/ExpandingRow';

const Users = ({ currency }) => {
  const usersReports = useSelector((state) => state.usersReports);
  const [searchText, setSearchText] = useState('');
  const [yearMenu, setYearMenu] = useState(null);
  const [tableYear, setTableYear] = useState(new Date().getFullYear());
  const [searchableList, setSearchableList] = useState([]);
  const dispatch = useDispatch();
  const contractYears = useSelector((state) => state.contractYears);

  useEffect(() => {
    if (usersReports.length > 0) {
      const keys = Object.keys(usersReports[0]);
      setSearchableList(keys);
    }
  }, [usersReports]);

  const isTableData = (tableItem, searchVal) => {
    const allReturn = [];
    for (let i = 0; i < searchableList.length; ++i) {
      if (Array.isArray(tableItem[searchableList[i]])) {
        if (tableItem[searchableList[i]][0]) {
          const subArrKeys = Object.keys(tableItem[searchableList[i]][0]);
          for (let k = 0; k < subArrKeys.length; ++k) {
            const arrayTempData = tableItem[searchableList[i]].map((item) => item[subArrKeys[k]]).toString().toUpperCase().includes(searchVal.toUpperCase());
            allReturn.push(arrayTempData);
          }
        }
      } else { allReturn.push([tableItem[searchableList[i]]].toString().toUpperCase().includes(searchVal.toUpperCase())); }
    }

    return allReturn.some((e) => e);
  };

  const searchData = (data) => {
    if (searchText.length > 1 && data && searchableList) {
      const filteredData = data.filter((tableItem) => isTableData(tableItem, searchText));
      return filteredData;
    }
    return data;
  };

  const changeTableYear = (val) => {
    dispatch(getUsersReports(val));
    setTableYear(val);
    setYearMenu(null);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
        <Input startAdornment={<Search />} placeholder={tra('Arama_Yap')} type="text" onChange={(e) => setSearchText(e.target.value)} />
        <CustomButton
          opacity
          style={{ marginLeft: 20, height: 40 }}
          onClick={(e) => setYearMenu(e.currentTarget)}
          endIcon={<UnfoldMore />}
        >
          {tableYear}
        </CustomButton>
        <CustomMenu
          anchorEl={yearMenu}
          open={Boolean(yearMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => setYearMenu(null)}
        >
          {contractYears.map((year) => (
            <MenuItem onClick={() => changeTableYear(year)}>{year}</MenuItem>
          ))}
        </CustomMenu>
      </div>
      <ExpandTable>
        <TableHead>
          <TableTh style={{ textAlign: 'start' }}>{tra('Departman')}</TableTh>
          <TableTh style={{ textAlign: 'start' }}>{tra('Toplam')} {currency}</TableTh>
          <TableTh />
        </TableHead>
        <br />
        <TableBody>
          {
            searchData(usersReports).map((item) => (
              <>
                <ExpandingRow item={item} currency={currency} />
                <br />
              </>
            ))
         }
        </TableBody>
      </ExpandTable>
    </>

  );
};

Users.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default Users;

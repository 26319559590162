/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  PageWrapper,
  // PageHeader,
  PageContent,
  NotificationContainer,
  CustomButton,
  NoData,
  // Widget,
} from '../assets/styledNew';
import { Star } from '../assets/icons';
import { Colors, notificationsTypes } from '../assets/statics';
import { getPostDateStr, tra, parseByMentions } from '../commons/utils';

import { readNotification } from '../store/actions';
import { selectNotifications, selectUsers, selectDashboard } from '../store/selectors';
import NoDataImg from '../assets/img/noData.png';

// createdAt: "2021-03-08T08:33:38.103Z"
// id: 30
// read: 0
// text: "demo user test mentioned you in a comment"
// type: 1
// updatedAt: "2021-03-08T08:33:38.103Z"
// source_user_id: 1

// types
// 1 -> comment
// 2 -> system owner
// 3 -> technic owner
// 4 -> business owner
// 5 -> survey

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: this.props.notifications,
      dateArray: false,
      // miniBoxOrder: localStorage.getItem('miniBoxOrder') ? JSON.parse(localStorage.getItem('miniBoxOrder')) : ['box0', 'box1', 'box2', 'box3'],
      dashboardMiniBoxdata: {},
      currentInstance: this,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (prevState.notifications.length !== nextProps.notifications.length) {
    const dateResult = nextProps.notifications && nextProps.notifications.map((item) => prevState.currentInstance.timeSeperator(item.createdAt))
      .filter((v, i, a) => a.findIndex((t) => (t.day === v.day)) === i)
      .sort((a, b) => a.day - b.day);
    const notificationsResult = nextProps.notifications && prevState.currentInstance.timeSort(nextProps.notifications);
    if (Object.keys(prevState.dashboardMiniBoxdata).length === 0) {
      const dashboardMiniBoxdata = {};
      if (nextProps.dashboardData.infoCard) {
        for (let i = 0; i < nextProps.dashboardData.infoCard.length; i++) {
          dashboardMiniBoxdata[`box${i}`] = {
            text: tra(nextProps.dashboardData.infoCard[i].name),
            count: nextProps.dashboardData.infoCard[i].count,
            // icon: <WindCloud color={Colors.white} />,
            // color:Colors.blue,
          };
        }
        return { dashboardMiniBoxdata };
      }
    }
    return {
      dateArray: dateResult,
      notifications: notificationsResult,
    };
    // }
    // return null;
  }

  componentDidMount() {
    const dateResult = this.props.notifications && this.props.notifications.map((item) => this.timeSeperator(item.createdAt))
      .filter((v, i, a) => a.findIndex((t) => (t.day === v.day)) === i)
      .sort((a, b) => a.day - b.day);

    const notificationsResult = this.props.notifications && this.timeSort(this.props.notifications);
    this.setState({ dateArray: dateResult, notifications: notificationsResult });
  }

  timeSeperator = (date) => {
    const postTime = Math.abs(new Date().getTime() - new Date(date).getTime());
    const postDateDay = Math.floor(postTime / (1000 * 60 * 60 * 24));
    const year = new Date(date).getFullYear();
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0');// Ay degeri 0 üzerinden başlıyor
    const day = new Date(date).getDate().toString().padStart(2, '0');
    if (postDateDay >= 2) {
      return {
        result: `${day}.${month}.${year}`,
        day: postDateDay,
      };
    }
    return {
      result: postDateDay === 0 ? tra('Bugun') : tra('Dun'),
      day: postDateDay,
    };
  }

  handleNotification = (id) => {
    const { notifications } = this.state;
    const ntfIndex = notifications.findIndex((element) => id === element.id);
    notifications[ntfIndex].read = false;
    this.setState({ notifications });
    this.props.readNotification(id);
  }

  timeSort = (items) => items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  upcoming = (date, items) => (
    <div className="column">
      <div className="date">
        <p><strong>{date.month}</strong></p>
        <p>{date.year}</p>
      </div>
      <div className="dateDetail">
        <div className="day">{date.day}</div>
        {
          items.map((item) => (
            <div className="item">
              <span style={{ background: Colors.lightGreen }}> <Star width="10" /></span>
              <p>{item}</p>
            </div>
          ))
        }
      </div>
    </div>
  )

  generateLink = (type, text) => {
    const { link } = notificationsTypes[type];
    let id;
    let idLink;
    if (type === 5) {
      id = text.match(/@survey\[\d+\/\d+]/g);
      idLink = id && id[0].substring(6, id[0].length - 1).substring(2);
    } else if (type === 0) {
      id = text.match(/@event\[\d+\]/g);
      idLink = id && id[0].substring(5, id[0].length - 1).substring(2);
    } else {
      id = text.match(/@app\[\d+\]/g);
      idLink = id && id[0].substring(3, id[0].length - 1).substring(2);
    }
    return `${link}/${idLink}`;
  }

  notificationItem = (item) => {
    // types
    // 1 -> comment
    // 2 -> system owner
    // 3 -> technic owner
    // 4 -> business owner
    // 5 -> survey
    const user = this.props.users.find((u) => u.id === item.source_user_id) && this.props.users.find((u) => u.id === item.source_user_id);
    return (
      <>
        <div className="item">
          {item.read === 0 && <div className="active" style={{ background: Colors.lightGrey200 }} />}
          <div className="itemWrapper">
            <div className="type" style={{ background: notificationsTypes[item.type].color }}>{notificationsTypes[item.type].icon}</div>
            <div className="profile" style={{ border: `3px solid ${notificationsTypes[item.type].color}` }}>
              {item.source_user_id && user && <img src={user.profile_img} alt={user.name} />}
            </div>
            <div className="content">
              <p onClick={() => this.handleNotification(item.id)} dangerouslySetInnerHTML={{ __html: parseByMentions(item.text, item.type, true) }} />
              <p>{getPostDateStr(item.createdAt)}</p>
            </div>
          </div>
          <Link to={this.generateLink(item.type, item.text)}>
            <CustomButton small transparent onClick={() => this.handleNotification(item.id)} color={notificationsTypes[item.type].color}>{notificationsTypes[item.type].button}</CustomButton>
          </Link>
        </div>
      </>
    );
  }

  render() {
    const {
      notifications,
      dateArray,
      // miniBoxOrder,
      // dashboardMiniBoxdata,
    } = this.state;
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} md={10}>
                <NotificationContainer>
                  <div className="timeSeperatorContainer">
                    {
                      this.props.notifications ? (
                        dateArray.length > 0 ? (
                          dateArray.map((date) => (
                            <>
                              <div className="timeSeperator">
                                <span>{date.result}</span>
                              </div>
                              {notifications.filter((item) => this.timeSeperator(item.createdAt).day === date.day)
                                .map((item) => this.notificationItem(item))}
                            </>
                          ))
                        ) : (
                          <NoData style={{ magin: '100px 0' }}>
                            <img src={NoDataImg} alt="No Data" />
                            <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                          </NoData>
                        )
                      ) : (
                        [1, 2, 3, 4, 5].map(() => (
                          <>
                            <div className="item">
                              <div className="itemWrapper">
                                <div className="type"><Skeleton variant="circle" width={35} height={35} style={{ borderRadius: 10 }} /></div>
                                <div className="profile">
                                  <Skeleton variant="circle" width={35} height={35} />
                                </div>
                                <div className="content">
                                  <p><Skeleton variant="text" width={300} height={20} /></p>
                                  <p><Skeleton variant="text" width={20} height={20} /></p>
                                </div>
                              </div>
                              <Skeleton variant="text" width={100} height={35}>
                                <CustomButton small transparent>.</CustomButton>
                              </Skeleton>
                            </div>
                          </>
                        ))
                      )
                    }
                  </div>
                </NotificationContainer>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  {
                    Object.keys(dashboardMiniBoxdata).length > 0 && miniBoxOrder.map((item) => (
                      <Grid item xs={12}>
                        <Widget style={{ overflow: !item && 'inherit', height: 'auto' }}>
                          {
                            item && (
                              <>
                                <div className="content">
                                  <p>{dashboardMiniBoxdata[item].text}</p>
                                  <h3>{dashboardMiniBoxdata[item].count}</h3>
                                   <div className="info">
                                    <span>+100%</span> {lang.herSeyiArttir}
                                  </div>
                                </div>
                                <div className="icon"><Window /></div>
                              </>
                            )
                          }
                        </Widget>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid> */}
            </Grid>
          </div>
        </PageContent>
      </PageWrapper>
    );
  }
}

Notification.propTypes = {
  notifications: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  readNotification: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  notifications: selectNotifications(),
  users: selectUsers(),
  dashboardData: selectDashboard(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      readNotification,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  CalculateOldNew, CalculatePercentage, CommaStringToNumber,
} from '../utils';

export const HWCostingContext = React.createContext({});

const HWCostingProvider = (props) => {
  const categories = useSelector((state) => state.hwCostingCategories);
  const currencies = useSelector((state) => state.infos.Currencies);
  const user = useSelector((state) => state.activeUser);
  const activeComp = localStorage.getItem('company');
  const selectedComp = user.companies && user.companies.find((item) => item.id.toString() === activeComp);
  const selectedCompCurrency = currencies.find((item) => item.id === selectedComp?.currency_id);

  const [HWCostingState, setHWCostingState] = useState({
    category_id: '',
    type_id: '',
    expense_type_id: '',
    total: 0,
    total_inv: 0,
    is_draft: 0,
    agreement: '',
    comments: '',
    HwGenericContItems: [
      {
        monthly_inv: 0,
        total_inv: 0,
        total_cost: 0,
        monthly_cost: 0,
        invCurr_type: '',
        period: 0,
        contract_start: new Date(),
        contract_end: new Date(),
        calculated_end: new Date(),
        purchase_date: null,
        fx_rate: 1,
        tco_agg: '',
      },
    ],
    HardwareInventoryContract: [],
    HwContractUrls: [],
  });

  const [HWCostingPageVariables, setHWCostingPageVariables] = useState({
    addHWModal: false,
    sendHardwares: [],
    allocationTotal: 0,
    costingDocModal: false,
    hardwareValueStatus: 1,
    hardwareValueStatusMenu: null,
  });

  const setReset = () => {
    setHWCostingState({
      category_id: '',
      type_id: '',
      expense_type_id: '',
      total: 0,
      total_inv: 0,
      is_draft: 0,
      agreement: '',
      comments: '',
      HwGenericContItems: [
        {
          monthly_inv: 0,
          total_inv: 0,
          total_cost: 0,
          monthly_cost: 0,
          invCurr_type: '',
          period: 0,
          contract_start: new Date(),
          contract_end: new Date(),
          calculated_end: new Date(),
          purchase_date: null,
          fx_rate: 1,
          tco_agg: '',
        },
      ],
      HardwareInventoryContract: [],
      HwContractUrls: [],
    });
    setHWCostingPageVariables({
      addHWModal: false,
      sendHardwares: [],
      allocationTotal: 0,
      costingDocModal: false,
      hardwareValueStatus: 1,
      hardwareValueStatusMenu: null,
    });
  };

  // change appvaluestatus tü
  const changeHWValueStatus = (val) => {
    setHWCostingState((prvState) => {
      setHWCostingPageVariables((prevState) => {
        let allocTotal = 0;
        if (val === 2) {
          for (let i = 0; i < prevState.sendHardwares.length; ++i) {
            allocTotal += prevState.sendHardwares[i].allocation;
          }
        } else {
          for (let i = 0; i < prevState.sendHardwares.length; ++i) {
            allocTotal += prevState.sendHardwares[i].value;
          }
        }
        return {
          ...prevState,
          allocationTotal: allocTotal,
          hardwareValueStatus: val,
          hardwareValueStatusMenu: null,
        };
      });
      return {
        ...prvState,
      };
    });
  };

  const changeHWValueStatusMenu = (val) => {
    setHWCostingPageVariables((prevState) => ({
      ...prevState,
      hardwareValueStatusMenu: val,
    }));
  };

  const changeAgrAutoComplete = (e, newValue) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        setHWCostingState((prevState) => ({
          ...prevState,
          agreement: newValue,
        }));
      } else if (newValue && newValue.inputValue) {
        setHWCostingState((prevState) => ({
          ...prevState,
          agreement: newValue.inputValue,
        }));
      } else {
        setHWCostingState((prevState) => ({
          ...prevState,
          agreement: newValue.agreement,
        }));
      }
    } else {
      setHWCostingState((prevState) => ({
        ...prevState,
        agreement: '',
      }));
    }
  };

  const addNewContract = () => {
    setHWCostingState((prevState) => {
      const prevContract = prevState.HwGenericContItems[prevState.HwGenericContItems.length - 1];
      const newdate = new Date(prevContract.contract_end);
      const newObj = {
        monthly_inv: 0,
        total_inv: 0,
        total_cost: 0,
        monthly_cost: 0,
        invCurr_type: selectedCompCurrency ? selectedCompCurrency.id : '',
        period: 0,
        contract_start: newdate,
        contract_end: new Date(newdate.setDate(newdate.getDate() + 1)),
        calculated_end: new Date(),
        purchase_date: null,
        fx_rate: 1,
        tco_agg: '',
      };
      const tempContracts = prevState.HwGenericContItems;
      tempContracts.push(newObj);
      return {
        ...prevState,
        HwGenericContItems: tempContracts,
      };
    });
  };

  const deleteContract = (index) => {
    setHWCostingState((prevState) => {
      const tempContracts = prevState.HwGenericContItems;
      const newTotalCost = prevState.total - tempContracts[index].total_cost;
      const newTotalInv = prevState.total_inv - tempContracts[index].total_inv;
      setHWCostingPageVariables((prvState) => {
        const sendApps = prvState.sendHardwares;
        if (prvState.hardwareValueStatus === 2) {
          for (let i = 0; i < sendApps.length; ++i) {
            sendApps[i].value = CalculatePercentage(sendApps[i].allocation, newTotalCost);
          }
        } else {
          for (let i = 0; i < sendApps.length; ++i) {
            sendApps[i].allocation = (sendApps[i].value * 100) / newTotalCost;
          }
        }
        return {
          ...prvState,
          sendHardwares: sendApps,
        };
      });
      tempContracts.splice(index, 1);
      return {
        ...prevState,
        total: newTotalCost,
        total_inv: newTotalInv,
        HwGenericContItems: tempContracts,
      };
    });
  };

  const handleChange = (event, name, index) => {
    const value = event.target?.value;
    const checkVal = event.target?.checked;
    switch (name) {
      case 'category_id':
        {
          const selectedCostType = categories.find((item) => item.id === value);
          HWCostingState.type_id = selectedCostType.HwCostingTypes[0].id;
          setHWCostingState((prevState) => ({
            ...prevState,
            category_id: value,
            type_id: selectedCostType.HwCostingTypes[0]?.id,
          }));
        }
        break;
      case 'type_id':
        setReset();
        setHWCostingState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
      case 'fc':
        setHWCostingState((prevState) => ({
          ...prevState,
          fc: checkVal ? 1 : 0,
        }));
        break;
      case 'tco_agg':
        setHWCostingState((prevState) => {
          const tempContracts = prevState.HwGenericContItems;
          tempContracts[index].tco_agg = value;
          return {
            ...prevState,
            HwGenericContItems: tempContracts,
          };
        });
        break;
      case 'invCurr_type':
        {
          const selectedCur = currencies.find((item) => item.id === value);
          if (selectedCompCurrency.id === selectedCur.id) {
            setHWCostingState((prevState) => {
              const tempContracts = prevState.HwGenericContItems;
              tempContracts[index].invCurr_type = value;
              tempContracts[index].fx_rate = 1;
              return {
                ...prevState,
                HwGenericContItems: tempContracts,
              };
            });
          } else {
            setHWCostingState((prevState) => {
              const tempContracts = prevState.HwGenericContItems;
              tempContracts[index].invCurr_type = value;
              return {
                ...prevState,
                HwGenericContItems: tempContracts,
              };
            });
          }
        }
        break;
      default:
        setHWCostingState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
    }
  };

  // REMOVE APP Tİ
  const removeHW = (id) => {
    setHWCostingPageVariables((prvState) => {
      const tempSendHws = prvState.sendHardwares;
      const selectedIndex = tempSendHws.findIndex((item) => item.inventory_id === id);
      let newTotalAllocation = 0;
      setHWCostingState((prevState) => {
        const selectedIndex2 = prevState.HardwareInventoryContract.findIndex((item) => item.id === id);
        newTotalAllocation = CommaStringToNumber(prvState.allocationTotal) - CommaStringToNumber(tempSendHws[selectedIndex][prvState.hardwareValueStatus === 2 ? 'allocation' : 'value']);
        return {
          ...prevState,
          HardwareInventoryContract: prevState.HardwareInventoryContract.splice(selectedIndex2, 1),
        };
      });
      tempSendHws.splice(selectedIndex, 1);
      return {
        ...prvState,
        allocationTotal: newTotalAllocation,
        sendHardwares: tempSendHws,
      };
    });
  };

  // addaptocontarct
  const addHWToContract = (hw, allocation, value) => {
    setHWCostingState((prevState) => {
      const tempHWs = prevState.HardwareInventoryContract;
      tempHWs.push(hw);
      return {
        ...prevState,
        HardwareInventoryContract: tempHWs,
      };
    });
    setHWCostingPageVariables((prevState) => {
      const tempSendHWs = prevState.sendHardwares;
      let tempAllocTotal = prevState.allocationTotal;
      if (tempSendHWs.findIndex((item) => item.id === hw.id) < 0) {
        const obj = {
          inventory_id: hw.id, value, value_inv: 1, allocation: isNaN(allocation) ? CommaStringToNumber(allocation.replace('%', '')) : allocation,
        };
        if (prevState.hardwareValueStatus === 2) {
          tempAllocTotal = (isNaN(tempAllocTotal) ? CommaStringToNumber(tempAllocTotal) : tempAllocTotal) + CommaStringToNumber(isNaN(allocation) ? CommaStringToNumber(allocation.replace('%', '')) : allocation);
        } else {
          tempAllocTotal = (isNaN(tempAllocTotal) ? CommaStringToNumber(tempAllocTotal) : tempAllocTotal) + value;
        }
        tempSendHWs.push(obj);
      }
      return {
        ...prevState,
        sendHardwares: tempSendHWs,
        addHWModal: false,
        allocationTotal: tempAllocTotal,
      };
    });
  };

  // allocation da absolute value değişirken kullanılan metod handlechangeappvalue
  const handleChangeHWValue = (e, id) => {
    const val = e.target.value;
    let total = 0;
    setHWCostingState((prevState) => {
      total = prevState.total;
      return {
        ...prevState,
      };
    });
    setHWCostingPageVariables((prevState) => {
      const tempSendHws = prevState.sendHardwares;
      const selectedIndex = tempSendHws.findIndex((item) => item.inventory_id === id);
      const newTotal = CalculateOldNew(tempSendHws[selectedIndex].value, val, prevState.allocationTotal);
      tempSendHws[selectedIndex].value = CommaStringToNumber(val.trim());
      tempSendHws[selectedIndex].allocation = CalculatePercentage(tempSendHws[selectedIndex].value, total);
      return {
        ...prevState,
        allocationTotal: newTotal,
        sendHardwares: tempSendHws,
      };
    });
  };

  // allocation da yüzdeli kısım değişirken kullanılan metod handlechangeappvalueallocation
  const handleChangeHWValueAllocation = (e, id) => {
    const val = CommaStringToNumber(e.target.value.replace('%', ''));
    let totalCost = 0;
    setHWCostingState((prevState) => {
      totalCost = prevState.total;
      return {
        ...prevState,
      };
    });
    setHWCostingPageVariables((prevState) => {
      const tempSendHWs = prevState.sendHardwares;
      const selectedIndex = tempSendHWs.findIndex((item) => item.inventory_id === id);
      const calculatedVal = (CommaStringToNumber(totalCost) * val) / 100;
      tempSendHWs[selectedIndex].value = calculatedVal;
      const newTotal = CalculateOldNew(CommaStringToNumber(tempSendHWs[selectedIndex].allocation), val, CommaStringToNumber(prevState.allocationTotal));
      tempSendHWs[selectedIndex].allocation = val;
      return {
        ...prevState,
        allocationTotal: newTotal,
        sendHardwares: tempSendHWs,
      };
    });
  };

  // calculateapplicationvalues tü
  const calculateHardwareValues = (val) => {
    setHWCostingPageVariables((prvState) => {
      const tempSendHWs = prvState.sendHardwares;
      if (tempSendHWs.length > 0) {
        if (prvState.hardwareValueStatus === 2) {
          for (let i = 0; i < tempSendHWs.length; ++i) {
            const value = (CommaStringToNumber(val) * tempSendHWs[i].allocation) / 100;
            tempSendHWs[i].value = value;
          }
        } else {
          for (let i = 0; i < tempSendHWs.length; ++i) {
            const newPercent = CalculatePercentage(tempSendHWs[i].value, CommaStringToNumber(val));
            tempSendHWs[i].allocation = newPercent;
          }
        }
        return {
          ...prvState,
          sendHardwares: tempSendHWs,
        };
      }
      return {
        ...prvState,
      };
    });
  };

  // perpetual de period, fxrate ve totalInv değiştiğinde yapılacak hesaplamalar
  const calcDepreciationPeriod = (selectedDate, totalCost, period, fxrate, index) => {
    setHWCostingState((prevState) => {
      const tempContracts = prevState.HwGenericContItems;
      let tempDays = [];
      const val = CommaStringToNumber(period);
      let calculatedAddedDays = 0;
      let date = moment(new Date(tempContracts[index].contract_start)).add(val, 'M').subtract(1, 'd');
      if (selectedDate) {
        date = moment(new Date(selectedDate)).add(val, 'M').subtract(1, 'd');
        if (period.toString().includes('.')) {
          tempDays = period.toString().split('.');
          if (tempDays[1] !== '') {
            const futureDays = moment(new Date(tempContracts[index].contract_start).setMonth(new Date(tempContracts[index].contract_start).getMonth() + parseInt(val, 10))).daysInMonth();
            calculatedAddedDays = (futureDays * parseInt(tempDays[1], 10)) / (10 ** tempDays[1].length);
            date = date.add(calculatedAddedDays, 'd');
          }
        }
      } else if (period.includes(',')) {
        tempDays = period.split(',');
        if (tempDays[1] !== '') {
          const futureDays = moment(new Date(tempContracts[index].contract_start).setMonth(new Date(tempContracts[index].contract_start).getMonth() + parseInt(val, 10))).daysInMonth();
          calculatedAddedDays = (futureDays * parseInt(tempDays[1], 10)) / (10 ** tempDays[1].length);
          date = date.add(calculatedAddedDays, 'd');
        }
      }

      const newTotal = CalculateOldNew(tempContracts[index].total_cost, CommaStringToNumber(totalCost), prevState.total);
      const newTotalInv = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(totalCost) * CommaStringToNumber(fxrate), prevState.total_inv);
      tempContracts[index].fx_rate = CommaStringToNumber(fxrate);
      tempContracts[index].period = val;
      tempContracts[index].monthly_inv = CommaStringToNumber(totalCost) / val;
      tempContracts[index].monthly_cost = (CommaStringToNumber(totalCost) / val) * CommaStringToNumber(fxrate);
      tempContracts[index].total_inv = CommaStringToNumber(totalCost) * CommaStringToNumber(fxrate);
      tempContracts[index].calculated_end = new Date(date);
      tempContracts[index].contract_end = new Date(date);

      return {
        ...prevState,
        total: newTotal,
        total_inv: newTotalInv,
        HwGenericContItems: tempContracts,
      };
    });
  };

  const handleChangeCurrency = (e, index) => {
    const val = e.target.value;
    setHWCostingState((prevState) => {
      const tempContracts = prevState.HwGenericContItems;
      const totalTemp = CommaStringToNumber(val) * CommaStringToNumber(tempContracts[index].fx_rate);
      const newTotal = CalculateOldNew(prevState.HwGenericContItems[index].total_cost, val, prevState.total);
      const newTotalInv = CalculateOldNew(prevState.HwGenericContItems[index].total_inv, totalTemp, prevState.total_inv);
      if (prevState.category_id === 2) {
        calcDepreciationPeriod(false, val, tempContracts[index].period, tempContracts[index].fx_rate, index);
      } else {
        if (tempContracts[index].period !== 0 && tempContracts[index].fx_rate !== 0) {
          /** Düzenleme lazım buraya newtotal hesabına */
          calculateHardwareValues(newTotal);
          tempContracts[index].total_cost = CommaStringToNumber(val);
          tempContracts[index].total_inv = totalTemp;
          tempContracts[index].monthly_inv = CommaStringToNumber(totalTemp) / CommaStringToNumber(tempContracts[index].period);
          tempContracts[index].monthly_cost = CommaStringToNumber(val) / CommaStringToNumber(tempContracts[index].period);
          return {
            ...prevState,
            total: newTotal,
            total_inv: newTotalInv,
            HwGenericContItems: tempContracts,
          };
        }
        if (tempContracts[index].period !== 0) {
          tempContracts[index].total_cost = CommaStringToNumber(val);
          tempContracts[index].monthly_cost = CommaStringToNumber(val) / CommaStringToNumber(tempContracts[index].period);
          return {
            ...prevState,
            total: newTotal,
            total_inv: newTotalInv,
            HwGenericContItems: tempContracts,
          };
        }
        if (tempContracts[index].fx_rate !== 0) {
          calculateHardwareValues(newTotal);
          tempContracts[index].total_inv = totalTemp;
          tempContracts[index].total_cost = CommaStringToNumber(val);
          tempContracts[index].monthly_cost = tempContracts[index].period !== 0 && CommaStringToNumber(val) / CommaStringToNumber(tempContracts[index].period);
          return {
            ...prevState,
            total: newTotal,
            total_inv: newTotalInv,
            HwGenericContItems: tempContracts,
          };
        }
      }

      tempContracts[index].total_cost = CommaStringToNumber(val);
      return {
        ...prevState,
        total: newTotal,
        total_inv: newTotalInv,
        HwGenericContItems: tempContracts,
      };
    });
  };

  const handleDateChange = (stateText, index) => (e) => {
    if (stateText === 'contract_start' || stateText === 'contract_end') {
      setHWCostingState((prevState) => {
        const tempContracts = prevState.HwGenericContItems;
        const selectedDatePlusOne = moment(new Date(e).setDate(new Date(e).getDate() + 1));
        const contractEndPlusOne = moment(new Date(tempContracts[index].contract_end).setDate(new Date(tempContracts[index].contract_end).getDate() + 1));
        const contractStartPlusOne = moment(new Date(tempContracts[index].contract_start));
        const calculatedPeriod = moment(stateText === 'contract_end'
          ? selectedDatePlusOne.set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          : contractEndPlusOne.set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })).diff(
          moment(stateText === 'contract_start'
            ? moment(new Date(e)).set({
              hour: 13,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            : contractStartPlusOne.set({
              hour: 13,
              minute: 0,
              second: 0,
              millisecond: 0,
            })), 'months', true,
        );
        tempContracts[index].period = calculatedPeriod;
        if (CommaStringToNumber(tempContracts[index].total_inv) !== 0) {
          tempContracts[index].monthly_inv = CommaStringToNumber(tempContracts[index].total_inv) / calculatedPeriod;
          tempContracts[index].monthly_cost = CommaStringToNumber(tempContracts[index].total_cost) / calculatedPeriod;
          return {
            ...prevState,
            HwGenericContItems: tempContracts,
          };
        }
        if (stateText === 'contract_start') {
          calcDepreciationPeriod(e, tempContracts[index].total_cost, tempContracts[index].period, tempContracts[index].fx_rate, index);
        }
        return {
          ...prevState,
          HwGenericContItems: tempContracts,
        };
      });
      if (stateText === 'contract_end') {
        setHWCostingState((prevState) => {
          const tempContracts = prevState.HwGenericContItems;
          tempContracts[index].contract_end = e;
          return {
            ...prevState,
            HwGenericContItems: tempContracts,
          };
        });
      }
    }
    setHWCostingState((prevState) => {
      const tempContracts = prevState.HwGenericContItems;
      tempContracts[index][stateText] = e;
      return {
        ...prevState,
        HwGenericContItems: tempContracts,
      };
    });
  };

  const changeFxRate = (e, type, buttonType, index) => {
    let val = e.target?.value || 0;
    switch (type) {
      case 'change':
        setHWCostingState((prevState) => {
          const tempContracts = prevState.HwGenericContItems;
          tempContracts[index].fx_rate = val;
          const newTotal = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val), prevState.total_inv);
          tempContracts[index].total_inv = CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val);
          if (tempContracts[index].period !== 0) {
            tempContracts[index].monthly_inv = (CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val)) / tempContracts[index].period;
            tempContracts[index].monthly_cost = CommaStringToNumber(tempContracts[index].total_cost) / tempContracts[index].period;
          }
          return {
            ...prevState,
            total_inv: newTotal,
            HwGenericContItems: tempContracts,
          };
        });
        break;
      case 'buttonClick':
        if (parseFloat(HWCostingState.HwGenericContItems[index].fx_rate) !== 0 || buttonType !== 'minus') {
          setHWCostingState((prevState) => {
            const tempContracts = prevState.HwGenericContItems;
            val = CommaStringToNumber(prevState.fx_rate) + (buttonType === 'plus' ? +1 : -1);
            tempContracts[index].fx_rate = val;
            const newTotal = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(tempContracts[index].total_cost) * val, prevState.total_inv);
            tempContracts[index].total_inv = CommaStringToNumber(tempContracts[index].total_cost) * val;
            tempContracts[index].monthly_inv = (CommaStringToNumber(tempContracts[index].total_cost) * val) / prevState.period;
            tempContracts[index].monthly_cost = CommaStringToNumber(tempContracts[index].total_cost) / prevState.period;
            return ({
              ...prevState,
              total_inv: newTotal,
              HwGenericContItems: tempContracts,
            });
          });
        }
        break;
      default:
        break;
    }
    // fx rate değiştikçe allocationdaki ve allocation overview tekrar hesaplanması gerekiyor
    setHWCostingState((prvState) => {
      setHWCostingPageVariables((prevState) => {
        let newAllocationTotal = 0;
        const tempSendHWs = prevState.sendHardwares;
        if (prevState.hardwareValueStatus === 2) {
          for (let i = 0; i < tempSendHWs.length; ++i) {
            const value = (prvState.total * tempSendHWs[i].allocation) / 100;
            tempSendHWs[i].value = value;
            newAllocationTotal += tempSendHWs[i].allocation;
          }
          return {
            ...prevState,
            allocationTotal: newAllocationTotal,
            sendHardwares: tempSendHWs,
          };
        }
        for (let i = 0; i < tempSendHWs.length; ++i) {
          tempSendHWs[i].allocation = CalculatePercentage(tempSendHWs[i].value, prvState.total);
          newAllocationTotal += tempSendHWs[i].value;
        }
        return {
          ...prevState,
          allocationTotal: newAllocationTotal,
          sendHardwares: tempSendHWs,
        };
      });
      return {
        ...prvState,
      };
    });
  };

  const handleChangeDepreciationPeriod = (index) => (e) => {
    const val = e.target.value;
    setHWCostingState((prevState) => {
      const tempContracts = prevState.HwGenericContItems;
      calcDepreciationPeriod(false, tempContracts[index].total_cost, val, tempContracts[index].fx_rate, index);
      tempContracts[index].period = val;
      return {
        ...prevState,
        HwGenericContItems: tempContracts,
      };
    });
  };

  const value = {
    HWCostingState,
    HWCostingPageVariables,
    calcDepreciationPeriod,
    setHWCostingPageVariables,
    setHWCostingState,
    removeHW,
    addHWToContract,
    handleChangeHWValue,
    handleChangeHWValueAllocation,
    handleChangeCurrency,
    handleDateChange,
    changeFxRate,
    setContextHWCostingState: (object) => setHWCostingState({ ...HWCostingState, ...object }),
    setReset,
    handleChange,
    changeAgrAutoComplete,
    handleChangeDepreciationPeriod,
    addNewContract,
    deleteContract,
    changeHWValueStatus,
    changeHWValueStatusMenu,
  };

  return (
    <HWCostingContext.Provider value={value}>
      {props.children}
    </HWCostingContext.Provider>

  );
};

HWCostingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HWCostingProvider;

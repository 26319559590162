import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';
import { AllocationPieTooltip } from '../../assets/styledNew';
import { tra, FormatCurrency } from '../../commons/utils';

const AllocationPie = ({ data, applications, type }) => {
  const prepareData = () => {
    const preparedData = [];
    let totalPercent = 100;
    for (let i = 0; i < data.length; ++i) {
      const selectedApp = applications && applications.find((item) => item.id === (type === 'hw' ? data[i].inventory_id : data[i].id));
      const newData = {
        id: data[i].id,
        allocation: data[i].allocation,
        appName: selectedApp ? (type === 'hw' ? selectedApp.model : selectedApp.name) : tra('Uygulama_Ismi_Bulunamadi'),
        value: FormatCurrency(data[i].value),
      };
      totalPercent -= data[i].allocation;
      preparedData.push(newData);
    }
    if (totalPercent > 0) {
      const newData = {
        id: -1,
        allocation: totalPercent,
        appName: tra('Bos_Alan'),
      };
      preparedData.push(newData);
    }
    return preparedData;
  };

  return (
    <ResponsivePie
      data={prepareData()}
      margin={{
        top: 20, right: 0, bottom: 20, left: 0,
      }}
      activeOuterRadiusOffset={9}
      value="allocation"
      colors={{ scheme: 'blues', size: 7 }}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      // radialLabel={(e) => `${e.data.appName} - ${FormatCurrency(e.data.allocation)}%`}
      // radialLabelsLinkDiagonalLength={10}
      // radialLabelsLinkHorizontalLength={15}
      // radialLabelsThickness={2}
      // radialLabelsColor={{ from: 'color', modifiers: [] }}
      enableArcLinkLabels={false}
      enableRadialLabels={false}
      enableSliceLabels={false}
      animate
      legends={[]}
      borderWidth={0}
      innerRadius={0}
      padAngle={0.7}
      tooltip={(p) => (
        <AllocationPieTooltip>
          <h4>{p.datum.data.appName}</h4>
          <p>{p.datum.data.value}</p>
        </AllocationPieTooltip>
      )}
      transitionMode="middleAngle"
    />
  );
};

AllocationPie.propTypes = {
  data: PropTypes.object.isRequired,
  applications: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default AllocationPie;

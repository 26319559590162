import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { MenuItem } from '@material-ui/core';
import { withTheme } from 'styled-components';
import Validator from '../commons/validator';
import {
  CustomButton, ColorBox, LoginBox, StepperBar, CustomMenu,
} from '../assets/styledNew';

import {
  Unlock, Check, LeftArrow, LogoColor, Earth,
} from '../assets/icons';
import Input from '../components/Input';
import {
  selectLoginState, selectRegAccountTypes, selectRegComplete, selectRegCurrencies, selectRegLanguages,
} from '../store/selectors';
import {
  forgotPassword, doLogin, companyRegister, getRegCompInit, setRegisterComplete,
} from '../store/actions';
import { Colors } from '../assets/statics';
import { tra } from '../commons/utils';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      stepIndex: 1,
      reverse: false,
      r_email: '',
      r_fullname: '',
      r_phone: '',
      r_companyName: '',
      r_prefix: '',
      r_currency: 1, // usd currency
      r_account_type: 1, // free account type
      r_language: 2, // turkish language
      r_title: '',
      l_email: '',
      l_password: '',
      forgot_email: '',
      showPassword: false,
      languageAnchorEl: null,
    };
    this.props.getRegCompInit();
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.regComplete) {
      nextProps.setRegisterComplete(false);
      return {
        stepIndex: 1,
      };
    }
    return null;
  }

  handlePhoneChange = (values) => {
    this.setState({ r_phone: values.value });
  }

  handleLogin = () => {
    if (this.isCheck('l_password', 'l_email')) this.props.doLogin(this.state.l_email.toLowerCase().trim(), this.state.l_password);
  }

  handleRegister = () => {
    const {
      r_email, r_fullname, r_phone, r_companyName, r_prefix, r_title, r_account_type, r_currency, r_language,
    } = this.state;
    const nameArr = r_fullname.trim().split(' ');
    let name = '';
    for (let i = 0; i < nameArr.length - 1; ++i) {
      if (i > 0) {
        name += ` ${nameArr[i]}`;
      } else {
        name += nameArr[i];
      }
    }
    const r_name = name;
    const r_surname = nameArr[nameArr.length - 1];
    this.props.companyRegister({
      user_email: r_email,
      user_name: r_name,
      user_surname: r_surname,
      user_title: r_title,
      user_phone: r_phone,
      name: r_companyName,
      prefix: r_prefix,
      language: r_language,
      account_type: r_account_type,
      currency: r_currency,
    });
  }

  handleForgotPassword = () => {
    const { forgot_email } = this.state;
    if (this.isCheck('forgot_email')) this.props.forgotPassword(forgot_email);
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  backToLogin = () => {
    this.setState({
      type: 0,
      reverse: false,
      r_email: '',
      r_fullname: '',
      r_phone: '',
      r_companyName: '',
      r_prefix: '',
      r_title: '',
    });
    Validator.hideMessages();
    this.forceUpdate();
  }

  stepperClick = (index) => this.setState({ stepIndex: index, reverse: true })

  isCheck = (...arr) => {
    const arrayValidate = [];
    Validator.showMessageFor('r_email');
    arr.forEach((item) => {
      arrayValidate.push(Validator.fieldValid(item));
      Validator.showMessageFor(item);
    });
    this.forceUpdate();
    return arrayValidate.every((i) => i === true);
  }

  isCheckIcon = (title, value, rules) => (!Validator.message(title, value, rules) && value !== '') && <CheckCircleOutlineIcon style={{ color: Colors.green }} />

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  submitForm = (stepIndex) => {
    switch (stepIndex) {
      case 2:
        if (this.isCheck('r_email')) {
          this.stepperClick(stepIndex);
        }
        break;
      case 3:
        if (this.isCheck('r_fullname', 'r_phone', 'r_title')) this.stepperClick(stepIndex);
        break;
      case 4:
        if (this.isCheck('r_prefix', 'r_companyName')) {
          if (this.props.regComplete) {
            this.stepperClick(stepIndex);
          }
          this.handleRegister();
        }
        break;
      default:
        Validator.showMessages();
        this.forceUpdate();
        break;
    }
  }

  _handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleLogin();
    }
  }

  selectLanguage = (id) => {
    localStorage.setItem('language', id);
    this.setState({ languageAnchorEl: null });
    window.location.reload();
  }

  languageButton = () => {
    const { languageAnchorEl } = this.state;
    return (
      <>
        <div className="language">
          <Earth color={this.props.theme.menuGrey} onClick={(e) => this.setState({ languageAnchorEl: e.currentTarget })} />
        </div>
        <CustomMenu
          anchorEl={languageAnchorEl}
          open={Boolean(languageAnchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => this.setState({ languageAnchorEl: null })}
        >
          <MenuItem onClick={() => this.selectLanguage('tr')}>TR</MenuItem>
          <MenuItem onClick={() => this.selectLanguage('en')}>EN</MenuItem>
        </CustomMenu>
      </>
    );
  }

  loginRender = () => {
    const {
      l_email, l_password, showPassword,
    } = this.state;
    return (
      <LoginBox onKeyDown={this._handleKeyDown}>
        <div className="headerArea">
          <LogoColor />
          {this.languageButton()}
        </div>
        <div className="formArea">
          <div>
            <h1>{tra('Giris_Yap')}</h1>
            {tra('Hosgeldiniz_Mesaji')}
          </div>
          <div className="inputArea">
            <Input
              type="text"
              name="l_email"
              variant="standard"
              value={l_email}
              onChange={(e) => this.handleChange(e)}
              error={Validator.message('l_email', l_email, 'required|min:3')}
              helperText={Validator.message('l_email', l_email, 'required|min:3')}
              placeholder={tra('Email')}
              endAdornment={this.isCheckIcon('l_email', l_email, 'required|min:3')}
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              name="l_password"
              variant="standard"
              value={l_password}
              onChange={(e) => this.handleChange(e)}
              error={Validator.message('l_password', l_password, 'required|min:6')}
              helperText={Validator.message('l_password', l_password, 'required|min:6')}
              endAdornment={(
                <IconButton
                  aria-label="toggle password visibility"
                  value={false}
                  onMouseDown={() => this.setState({ showPassword: true })}
                  onMouseUp={() => this.setState({ showPassword: false })}
                  onTouchStart={() => this.setState({ showPassword: true })}
                  onTouchEnd={() => this.setState({ showPassword: false })}
                  edge="end"
                >
                  {showPassword ? <Visibility style={{ color: Colors.purple }} /> : <VisibilityOff style={{ color: Colors.purple }} />}
                </IconButton>
              )}
              placeholder={tra('Sifre')}
            />
            <a className="forgotPassword" href="#" onClick={() => this.setState({ type: 2, stepIndex: 1 })}>{tra('Parolami_Unuttum')}!<Unlock color={Colors.darkBlue300} width="15" /></a>

          </div>
          <CustomButton borderRadius="5px" color={Colors.blue} padding="10px 10px" onClick={() => this.handleLogin()}>{tra('Giris_Yap')}</CustomButton>
          {/* <a href="#" className="signInButton" onClick={() => this.setState({ type: 1, stepIndex: 1 })}>{tra('Hesap_Yok_Mesaji')}<RightButton color={Colors.darkBlue300} width="15" /></a> */}
        </div>
      </LoginBox>
    );
  }

  registerRender = () => {
    const {
      stepIndex, reverse, r_email,
      r_fullname, r_phone, r_companyName, r_prefix, r_title, r_language, r_account_type, r_currency,
    } = this.state;
    const { regAccountTypes, regLanguages, regCurrencies } = this.props;
    return (
      <LoginBox stepIndex={stepIndex} reverse={reverse}>
        <div className="headerArea">
          <LogoColor />
          {stepIndex === 1
            ? <a href="#" className="prevButton" onClick={() => this.setState({ type: 0, reverse: false })}> <LeftArrow color={Colors.darkBlackBlue} width={12} /> {tra('Geri')}</a>
            : stepIndex !== 4 && <a href="#" className="prevButton" onClick={() => this.stepperClick(stepIndex - 1)}> <LeftArrow color={Colors.darkBlackBlue} width={12} /> {tra('Geri')}</a>}
        </div>
        <div className="formGroup">
          <div className="formArea">
            <div>
              <h1>{tra('Hesap_Yarat')}</h1>
              {tra('Hesap_Yarat_Mesaji')}
            </div>
            <div className="inputArea">
              <Input
                type="text"
                name="r_email"
                variant="standard"
                value={r_email}
                onChange={(e) => this.handleChange(e)}
                error={Validator.message('r_email', r_email, 'required|email')}
                helperText={Validator.message('r_email', r_email, 'required|email')}
                placeholder={tra('mail_giriniz')}
                endAdornment={this.isCheckIcon('r_email', r_email, 'required|email')}
              />
            </div>
            <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => this.submitForm(2)}>{tra('Devam')}</CustomButton>
          </div>
          <div className="formArea">
            <div>
              <h1>{tra('Hesap_Yarat')}</h1>
              {tra('Hesap_Yarat_Mesaji')}
            </div>
            <div className="inputArea">
              <Input
                type="text"
                name="r_fullname"
                variant="standard"
                value={r_fullname}
                onChange={(e) => this.handleChange(e)}
                error={Validator.message('r_fullname', r_fullname, 'required|min:3|fullnameControl|alpha_space_tr')}
                helperText={Validator.message('r_fullname', r_fullname, 'required|min:3|fullnameControl|alpha_space_tr')}
                placeholder={tra('Adiniz_Soyadiniz')}
                endAdornment={this.isCheckIcon('r_fullname', r_fullname, 'required|min:3|fullnameControl|alpha_space_tr')}
              />
              <Input
                type="text"
                name="r_title"
                variant="standard"
                value={r_title}
                onChange={(e) => this.handleChange(e)}
                error={Validator.message('r_title', r_title, 'required|min:3|alpha_space_tr')}
                helperText={Validator.message('r_title', r_title, 'required|min:3|alpha_space_tr')}
                placeholder={tra('User_Title')}
                endAdornment={this.isCheckIcon('r_title', r_title, 'required|min:3|alpha_space_tr')}
              />
              <Input
                type="tel"
                data={[{ id: 1, name: 'CH (+41)' }]}
                telCode={1}
                onChangeTelCode={() => { }}
                name="r_phone"
                variant="standard"
                value={r_phone}
                onValueChange={(values) => this.handlePhoneChange(values)}
                error={Validator.message('r_phone', r_phone, 'required|phone')}
                helperText={Validator.message('r_phone', r_phone, 'required|phone')}
                placeholder={tra('Tel_No')}
                endAdornment={this.isCheckIcon('r_phone', r_phone, 'required|phone')}
              />
            </div>
            <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => this.submitForm(3)}>{tra('Devam')}</CustomButton>
          </div>
          <div className="formArea">
            <div>
              <h1>{tra('Hesap_Yarat')}</h1>
              {tra('Hesap_Yarat_Mesaji')}
            </div>
            <div className="inputArea">
              <Input
                type="text"
                name="r_companyName"
                variant="standard"
                value={r_companyName}
                onChange={(e) => this.handleChange(e)}
                error={Validator.message('r_companyName', r_companyName, 'required|min:2|alpha_space_tr')}
                helperText={Validator.message('r_companyName', r_companyName, 'required|min:2|alpha_space_tr')}
                placeholder={tra('Sirket_Adi')}
                endAdornment={this.isCheckIcon('r_companyName', r_companyName, 'required|min:2|alpha_space_tr')}
              />
              <div className="multipleInputWrapper">
                <Input
                  type="text"
                  name="r_prefix"
                  variant="standard"
                  value={r_prefix}
                  onChange={(e) => this.handleChange(e)}
                  error={Validator.message('r_prefix', r_prefix, 'required|max:8|alpha_num')}
                  helperText={Validator.message('r_prefix', r_prefix, 'required|max:8|alpha_num')}
                  placeholder={tra('Sirket_Prefix')}
                  endAdornment={this.isCheckIcon('r_prefix', r_prefix, 'required|max:8|alpha_num')}
                />
                <Input
                  type="select"
                  disabled
                  data={regAccountTypes}
                  name="r_account_type"
                  variant="standard"
                  value={r_account_type}
                  // onChange={(e) => this.handleChange(e)}
                  // error={Validator.message('r_account_type', r_account_type, 'required')}
                  // helperText={Validator.message('r_account_type', r_account_type, 'required')}
                  title="Hesap Tipi"
                  valueKey="statu"
                />
              </div>
              <div className="multipleInputWrapper">
                <Input
                  type="select"
                  data={regLanguages}
                  name="r_language"
                  variant="standard"
                  value={r_language}
                  onChange={(e) => this.handleChange(e)}
                  // error={Validator.message('r_language', r_language, 'required')}
                  // helperText={Validator.message('r_language', r_language, 'required')}
                  title={tra('Dil_Secimi')}
                  labelKey="language"
                />
                <Input
                  type="select"
                  data={regCurrencies}
                  name="r_currency"
                  variant="standard"
                  value={r_currency}
                  onChange={(e) => this.handleChange(e)}
                  // error={Validator.message('r_currency', r_currency, 'required')}
                  // helperText={Validator.message('r_currency', r_currency, 'required')}
                  title={tra('Para_Birimi')}
                  labelKey="currency"
                />
              </div>
            </div>
            <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => this.submitForm(4)}>{tra('Tamamla')}</CustomButton>
          </div>

          <div className="formArea">
            <div className="registerEndPage">
              <h1>{tra('Tebrikler')}</h1>
              {tra('Hesap_Yaratildi_Mesaji')}
            </div>
            <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => this.backToLogin()}>{tra('Giris_Yap')}</CustomButton>
          </div>
        </div>
        <StepperBar stepIndex={stepIndex}>
          <div className="circle" onClick={() => this.stepperClick(1)} aria-hidden="true"><Check width="8" color="#fff" /></div>
          <div className="circle" onClick={() => this.stepperClick(2)} aria-hidden="true"><Check width="8" color="#fff" /></div>
          <div className="circle" onClick={() => this.stepperClick(3)} aria-hidden="true"><Check width="8" color="#fff" /></div>
        </StepperBar>
      </LoginBox>
    );
  }

  forgetPasswordRender = () => {
    const { forgot_email } = this.state;
    return (
      <LoginBox>
        <div className="headerArea">
          <LogoColor />
          <a href="#" className="prevButton" onClick={() => { Validator.hideMessageFor('forgot_email'); this.setState({ type: 0, forgot_email: '' }); }}> <LeftArrow color={Colors.darkBlackBlue} width={12} /> {tra('Geri')}</a>
        </div>

        <div className="formArea">
          <div>
            <h1>{tra('Sifre_Sifirlama')}</h1>
            {tra('Sifre_Sifirlama_Mesaji')}
          </div>
          <div className="inputArea">
            <Input
              type="text"
              name="forgot_email"
              variant="standard"
              value={forgot_email}
              onChange={(e) => this.handleChange(e)}
              error={Validator.message('forgot_email', forgot_email, 'required|email')}
              helperText={Validator.message('forgot_email', forgot_email, 'required|email')}
              placeholder={tra('mail_giriniz')}
              endAdornment={this.isCheckIcon('forgot_email', forgot_email, 'required|email')}
            />
          </div>
          <CustomButton borderRadius="5px" color={Colors.blue} onClick={() => this.handleForgotPassword()}>{tra('Sifirlama_Postasi_Gonder')}</CustomButton>
        </div>
      </LoginBox>
    );
  }

  switchComponents = (type) => {
    switch (type) {
      case 0:
        return this.loginRender();
      // case 1:
      //   return this.registerRender();
      case 2:
        return this.forgetPasswordRender();
      default:
        return this.loginRender();
    }
  }

  render() {
    const { loginState } = this.props;
    const { type } = this.state;
    return (
      <ColorBox state={loginState}>
        {this.switchComponents(type)}
      </ColorBox>
    );
  }
}

Login.propTypes = {
  loginState: PropTypes.bool.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  doLogin: PropTypes.func.isRequired,
  companyRegister: PropTypes.func.isRequired,
  getRegCompInit: PropTypes.func.isRequired,
  regAccountTypes: PropTypes.array.isRequired,
  regLanguages: PropTypes.array.isRequired,
  regCurrencies: PropTypes.array.isRequired,
  regComplete: PropTypes.bool.isRequired,
  setRegisterComplete: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loginState: selectLoginState(),
  regAccountTypes: selectRegAccountTypes(),
  regLanguages: selectRegLanguages(),
  regCurrencies: selectRegCurrencies(),
  regComplete: selectRegComplete(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      forgotPassword,
      doLogin,
      companyRegister,
      getRegCompInit,
      setRegisterComplete,
    }, dispatch)
  )
);

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(Login);

/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  Close, DeleteOutline, ErrorOutline, UnfoldMore, Delete, Public, Check, // Remove,
} from '@material-ui/icons';
import withWidth from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import {
  Colors,
} from '../../assets/statics';
import {
  ActionFooter, CustomButton, GridPageWrapper, InputWrapper, PageContent, PageWrapper,
  CustomMenu,
} from '../../assets/styledNew';
import {
  createHWCosting, setResetState, setCostingFiles, setToast,
  getCosting, setCostingFileUrls, setSendToDetailPageID, setSendToView, getHWCostingCategories,
  getHWCostingExpenses,getHardwareDevices, getHWCostingList,
} from '../../store/actions';
import {
  selectCostingFiles, selectInfos, selectResetState,
  selectActiveUser, selectCostingFileUrls, selectSendToDetailPageID, selectSendToView, selectHardwareDevices,
  selectHWCostingCategories, selectHWCostingExpenses, selectHWCostingList,
} from '../../store/selectors';
import {
  CommaStringToNumber, FormatCurrency, tra,
} from '../../commons/utils';
import { Plus, DocumentPDF } from '../../assets/icons';
import AllocationPie from '../../components/charts/AllocationPie';
import { HWCostingContext } from '../../commons/context/HWCostingContext';
import CostingDocModal from '../../components/modals/CostingDocModal';
import FastInput from '../../components/costing/HWCostingFastInput';
import Validator from '../../commons/validator';
import AddHWToContract from '../../components/modals/AddHWToContract';

class HWCostingRegister extends Component {
  static contextType = HWCostingContext;

  constructor(props) {
    super(props);
    this.state = {
      compCurrency: '',
      fileArr: [],
      fileUrlArr: [],
      removeCheckClick: false,
    };
    props.getHardwareDevices();
    props.getHWCostingExpenses();
    props.getHWCostingCategories();
    props.getHWCostingList();
  }

  componentDidUpdate() {
    const {
      HWCostingState, setHWCostingPageVariables, setHWCostingState,
    } = this.context;
    const { hwCategories, currencies, user } = this.props;
    if (hwCategories.length > 0 && HWCostingState.category_id === '') {
      setHWCostingState((prevState) => ({
        ...prevState,
        category_id: hwCategories[0].id,
      }));
    }
    if(hwCategories.length > 0 && hwCategories[0].HwCostingTypes.length > 0 && HWCostingState.type_id === '') {
      setHWCostingState((prevState) => ({
        ...prevState,
        type_id: hwCategories[0].HwCostingTypes[0].id,
      }));
    }
    if (this.props.resetState) {
      this.context.setReset();
      this.resetState();
    }
    if (Number.isInteger(this.props.sendToDetailID) && this.props.sendToView) {
      /**
       * send to detail page if registration is successful by server
       */
      const obj = {
        id: null,
        tabOpen: false,
      };
      this.props.setSendToView(false);
      localStorage.setItem('costfrominventory', JSON.stringify(obj));
      this.props.history.push(`/CostingDetail/Hardware/${this.props.sendToDetailID}`);
    }
    if (HWCostingState.HwGenericContItems[0]?.invCurr_type === '' && this.state.compCurrency !== '' && currencies.length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = user.companies && user.companies.find((item) => item.id.toString() === activeComp);
      const currency = currencies.find((item) => item.id === selectedComp.currency_id);
      setHWCostingState((prvState) => {
        const tempContracts = HWCostingState.HwGenericContItems;
        tempContracts[0].invCurr_type = currency.id;
        return {
          ...prvState,
          HwGenericContItems: tempContracts,
        };
      });
      setHWCostingPageVariables((prvState) => ({
        ...prvState,
        currency: currency.name,
      }));
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const pathArr = props.history.location.pathname.split('/');
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.name;
      return {
        compCurrency: currency,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.resetState();
    this.context.setReset();
    this.props.setSendToDetailPageID(false);
  }

  resetState = () => {
    this.setState({ fileArr: [], fileUrlArr: [] });
    this.props.setResetState(false);
    this.props.setCostingFiles([]);
    this.props.setCostingFileUrls([]);
    Validator.hideMessages();
  }

  changeCostType = (costId, categoryId, type) => {
    const { compCurrency } = this.state;
    const { hwCategories } = this.props;
    const contractTypes = hwCategories.find((item) => item.id === costId).HWCostingTypes;
    const { currencies } = this.props;
    this.props.setCostingFiles([]);
    this.props.setCostingFileUrls([]);
    const tempCost = {
      category_id: costId,
      type_id: type === 'costType' ? contractTypes[0].id : categoryId,
      expense_type_id: '',
      total: 0,
      total_inv: 0,
      is_draft: 0,
      agreement: '',
      comments: '',
      HwGenericContItems: [
        {
          monthly_inv: 0,
          total_inv: 0,
          total_cost: 0,
          monthly_cost: 0,
          invCurr_type: currencies.find((item) => item.name === compCurrency).id,
          period: 0,
          contract_start: new Date(),
          contract_end: new Date(),
          calculated_end: new Date(),
          purchase_date: null,
          fx_rate: 1,
          tco_agg: '',
        },
      ],
      HardwareInventoryContract: [],
      HwContractUrls: [],
    };
    this.context.setHWCostingState(tempCost);
    this.context.setHWCostingPageVariables({
      addHWModal: false,
      allocationTotal: 0,
      sendHardwares: [],
      costingDocModal: false,
      currency: compCurrency,
      hardwareValueStatus: 1,
      hardwareValueStatusMenu: null,
    });
    Validator.hideMessages();
    this.forceUpdate();
  }

  createCost = (sendToView) => {
    const { costingFiles, costingFileUrls } = this.props;
    const { HWCostingState, HWCostingPageVariables } = this.context;
    const sendData = { ...HWCostingState };
    if (HWCostingPageVariables.sendHardwares.length > 0) {
      if (!Validator.allValid()) {
        Validator.showMessages();
        this.forceUpdate();
        this.props.setToast(true, tra('Lutfen_Zorunlu_Alanlari_Doldurun'), 'warning');
      } else {
        sendData.HardwareInventoryContract = HWCostingPageVariables.sendHardwares;
        sendData.HwContractUrls = costingFileUrls;
        for (let i = 0; i < sendData.HwGenericContItems.length; ++i) {
          sendData.HwGenericContItems[i].fx_rate = CommaStringToNumber(sendData.HwGenericContItems[i].fx_rate);
          const contractStart = new Date(sendData.HwGenericContItems[i].contract_start).setHours(13, 0, 0, 0);
          const contractEnd = new Date(sendData.HwGenericContItems[i].contract_end).setHours(13, 0, 0, 0);
          const calculatedEnd = new Date(sendData.HwGenericContItems[i].calculated_end).setHours(13, 0, 0, 0);
          if (sendData.HwGenericContItems[i].purchase_date !== null) {
            const purchaseDate = new Date(sendData.HwGenericContItems[i].purchase_date).setHours(13, 0, 0, 0);
            sendData.HwGenericContItems[i].purchase_date = new Date(purchaseDate);
          }
          sendData.HwGenericContItems[i].contract_start = new Date(contractStart);
          sendData.HwGenericContItems[i].contract_end = new Date(contractEnd);
          sendData.HwGenericContItems[i].calculated_end = new Date(calculatedEnd);
        }
        sendData.total_inv = CommaStringToNumber(sendData.total_inv);
        sendData.total = CommaStringToNumber(sendData.total);
        this.props.createHWCosting(sendData, costingFiles, sendToView);
      }
    } else {
      this.props.setToast(true, tra('Donanim_Ekle_Mesaji'), 'warning');
    }
  }

  handleSubmitToFromViewDetail = () => {
    this.createCost(true);
  }

  render() {
    const {
      compCurrency, fileArr, fileUrlArr, removeCheckClick,
    } = this.state;
    const {
      HWCostingState, HWCostingPageVariables, removeHW, setHWCostingPageVariables, handleChangeHWValue, changeHardwareValueStatusMenu,
      handleChangeHWValueAllocation, handleDateChange, handleChangeCurrency, changeFxRate, addHWToContract, handleChange,
      changeAgrAutoComplete, handleChangeDepreciationPeriod, addNewContract, deleteContract, changehardwareValueStatus,
    } = this.context;
    const {
      hwCategories, currencies, tcoAggregations, history, hwCostingExpenses, hardwares, hwCostingList,
    } = this.props;
    Validator.purgeFields();
    return (
      <PageWrapper>
        <PageContent>
          <div className="pageInner">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <GridPageWrapper style={{ width: '100%', paddingBottom: 40 }}>
                <InputWrapper>
                  <Grid container spacing={2}>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={6}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Masraf_Ekle')}</h4>
                              <p>{tra('Add_HW_Costing_Detail')}.</p>
                            </div>
                          </div>
                          <div className="inputList">
                            <FastInput
                              type="select"
                              title={tra('Masraf_Kategorisi')}
                              data={hwCategories}
                              name="category_id"
                              onChange={(e) => this.changeCostType(e.target.value, '', 'category_id')}
                            />
                            <FastInput
                              type="select"
                              title={tra('Masraf_Tipi')}
                              data={(HWCostingState.type_id !== '') ? hwCategories.find((item) => item.id === HWCostingState.category_id) && hwCategories.find((item) => item.id === HWCostingState.category_id).HwCostingTypes : []}
                              name="type_id"
                              onChange={(e) => this.changeCostType(HWCostingPageVariables.costType, e.target.value)}
                            />
                            <FastInput
                              type="autoCompleteCreatable"
                              title={tra('Tanim')}
                              data={hwCostingList}
                              labelKey="agreement"
                              onChange={(e, newValue) => changeAgrAutoComplete(e, newValue)}
                              freeSolo
                              name="agreement"
                              indicate
                              error={Validator.message('agreement', HWCostingState.agreement, 'required')}
                              helperText={Validator.message('agreement', HWCostingState.agreement, 'required')}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid className="rowRight" item xs={6}>
                        <div className="box">
                          <div className="headerGrid" />
                          <div className="inputList">
                            <FastInput
                              type="text"
                              title={tra('Notlar')}
                              row="4"
                              name="comments"
                              onChange={(e) => handleChange(e, 'comments')}
                            />
                            <FastInput
                              type="select"
                              emptyItem
                              name="expense_type_id"
                              title={tra('Expense_Type')}
                              onChange={(e) => handleChange(e, 'expense_type_id')}
                              data={hwCostingExpenses}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {HWCostingState.HwGenericContItems?.map((rowItem, index) => (
                      <Grid className={index !== HWCostingState.HwGenericContItems.length - 1 ? 'gridRowDashed' : 'gridRow'} container item xs={12} spacing={3}>
                        {((index !== 0) || HWCostingState.HwGenericContItems.length > 1) && (
                          <Grid container justify="flex-end" item xs={12}>
                            <CustomButton
                              style={{ padding: 0, marginRight: 40 }}
                              opacity
                              onClick={() => deleteContract(index)}
                            >
                              <DeleteOutline />
                            </CustomButton>
                          </Grid>
                        )}
                        <Grid className="rowLeft" item xs={6}>
                          <div className="box">
                            {index === 0 && (
                              <div className="headerGrid">
                                <div className="left">
                                  <h4>{tra('Date_Information')}</h4>
                                  <p>{tra('Date_Information_Detail_HW')}.</p>
                                </div>
                              </div>
                            )}
                            <div className="inputList">
                              {HWCostingState.category_id === 2 ? (
                                <>
                                  <FastInput
                                    type="date"
                                    title={tra('Satin_Alma_Tarihi')}
                                    value={rowItem.purchase_date}
                                    onChange={handleDateChange('purchase_date', index)}
                                  />
                                  <FastInput
                                    type="date"
                                    title={tra('Begining_Of_Depreciation')}
                                    value={rowItem.contract_start}
                                    onChange={handleDateChange('contract_start', index)}
                                  />
                                  <FastInput
                                    type="currency"
                                    title={tra('Depreciation_Period')}
                                    value={rowItem.period}
                                    onChange={handleChangeDepreciationPeriod(index)}
                                    indicate
                                    error={Validator.message('period', rowItem.period, 'required')}
                                    helperText={Validator.message('period', rowItem.period, 'required')}
                                  />
                                  <FastInput
                                    type="date"
                                    title={tra('Calculated_End_Date')}
                                    value={rowItem.calculated_end}
                                    disabled
                                  />
                                </>
                              ) : (
                                <>
                                  <FastInput
                                    type="date"
                                    title={tra('Fatura_Baslangic')}
                                    value={rowItem.contract_start}
                                    onChange={handleDateChange('contract_start', index)}
                                  />
                                  <FastInput
                                    type="date"
                                    title={tra('Fatura_Bitis')}
                                    value={rowItem.contract_end}
                                    minDate={HWCostingState.contract_start}
                                    onChange={handleDateChange('contract_end', index)}
                                  />
                                  {HWCostingPageVariables.costType !== 3 && (
                                    <FastInput
                                      type="date"
                                      title={tra('Satin_Alma_Tarihi')}
                                      value={rowItem.purchase_date}
                                      onChange={handleDateChange('purchase_date', index)}
                                    />
                                  )}
                                  <FastInput
                                    type="currency"
                                    title={tra('Aylik_Periyot')}
                                    disabled
                                    value={rowItem.period}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid className="rowRight" item xs={6}>
                          <div className="box">
                            {index === 0 && (
                              <div className="headerGrid">
                                <div className="left">
                                  <h4>{tra('Cost_Information')}</h4>
                                  <p>{tra('Cost_Information_Detail_HW')}.</p>
                                </div>
                              </div>
                            )}
                            <div className="inputList">
                              <div className="multipleInputWrapper">
                                <FastInput
                                  type="select"
                                  title={tra('Invoice_Currency')}
                                  data={currencies}
                                  value={rowItem.invCurr_type}
                                  onChange={(e) => handleChange(e, 'invCurr_type', index)}
                                />
                                <FastInput
                                  disabled={currencies.find((item) => item.id === rowItem.invCurr_type)?.name === compCurrency}
                                  type="number"
                                  title={tra('Fx_Rate')}
                                  value={rowItem.fx_rate}
                                  indicate
                                  error={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                                  helperText={Validator.message('fx_rate', rowItem.fx_rate, 'required')}
                                  onChange={(e) => changeFxRate(e, 'change', '', index)}
                                  plusClick={() => changeFxRate('', 'buttonClick', 'plus', index)}
                                  minusClick={() => changeFxRate('', 'buttonClick', 'minus', index)}
                                />
                              </div>
                              <div className="multipleInputWrapper">
                                <FastInput
                                  type="currency"
                                  title={`${tra('Total_Amount_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                                  value={rowItem.total_cost}
                                  onChange={(e) => {
                                    Validator.showMessageFor('allocationTotal');
                                    handleChangeCurrency(e, index);
                                  }}
                                  indicate
                                  error={Validator.message('total_cost', rowItem.total_cost, 'required')}
                                  helperText={Validator.message('total_cost', rowItem.total_cost, 'required')}
                                />
                                <FastInput
                                  type="currency"
                                  title={`${HWCostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${currencies.find((item) => item.id === rowItem.invCurr_type)?.name}`}
                                  value={rowItem.monthly_cost}
                                  disabled
                                />
                              </div>
                              {currencies.find((item) => item.id === rowItem.invCurr_type)?.name !== compCurrency && (
                                <div className="multipleInputWrapper">
                                  <FastInput
                                    type="currency"
                                    title={`${tra('Total_Amount_In')} ${compCurrency}`}
                                    disabled
                                    value={rowItem.total_inv}
                                  />
                                  <FastInput
                                    type="currency"
                                    title={`${HWCostingState.category_id === 2 ? tra('Monthly_Depreciation_In') : tra('Monthly_Cost_In')} ${compCurrency}`}
                                    value={rowItem.monthly_inv}
                                    disabled
                                  />
                                </div>
                              )}
                              <div className="multipleInputWrapper">
                                <FastInput
                                  type="select"
                                  title={tra('Run_Cost_Aggrigation')}
                                  value={rowItem.tco_agg}
                                  data={tcoAggregations}
                                  onChange={(e) => handleChange(e, 'tco_agg', index)}
                                  indicate
                                  error={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                                  helperText={Validator.message('tco_agg', rowItem.tco_agg, 'required')}
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        {index === HWCostingState.HwGenericContItems.length - 1 && (
                          <Grid item xs={12}>
                            <CustomButton
                              transparent
                              startIcon={<Plus width={12} />}
                              onClick={addNewContract}
                            >{tra('Split_Existing_Contract')}
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={8}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Allocation_Of_Cost_To_Hardwares')}</h4>
                              <p>{tra('Allocation_Of_Cost_To_Hardwares_Detail')}.</p>
                            </div>
                          </div>
                          {HWCostingPageVariables.sendHardwares.length > 0 && (
                            <div className="allocationTable">
                              <Table>
                                <TableHead>
                                  <TableCell />
                                  <TableCell>{tra('Allocation')} %</TableCell>
                                  <TableCell>{tra('Allocation_In')} {currencies.find((cur) => cur.id === HWCostingState.HwGenericContItems[0]?.invCurr_type)?.name}</TableCell>
                                  <TableCell />
                                </TableHead>
                                <TableBody>
                                  {HWCostingPageVariables.sendHardwares?.map((item) => (
                                    <TableRow>
                                      <TableCell><p>{hardwares.find((hw) => hw.id === item.inventory_id)?.model}</p></TableCell>
                                      <TableCell>
                                        <FastInput
                                          type="currency"
                                          suffix="%"
                                          decimalScale="none"
                                          allowDecimal={false}
                                          value={item.allocation}
                                          onChange={(e) => {
                                            handleChangeHWValueAllocation(e, item.inventory_id);
                                            Validator.showMessageFor('allocationTotal');
                                          }}
                                          disabled={HWCostingPageVariables.hardwareValueStatus === 1}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FastInput
                                          type="currency"
                                          value={FormatCurrency(item.value)}
                                          onChange={(e) => {
                                            handleChangeHWValue(e, item.inventory_id);
                                            Validator.showMessageFor('allocationTotal');
                                          }}
                                          disabled={HWCostingPageVariables.hardwareValueStatus === 2}
                                        />
                                      </TableCell>
                                      <TableCell><IconButton onClick={() => removeHW(item.inventory_id)}><Close /></IconButton></TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow className="totalRow">
                                    <TableCell><p>{tra('Total_Allocated')} {HWCostingPageVariables.hardwareValueStatus === 1 ? tra('Amount') : tra('Percentage')} {HWCostingPageVariables.hardwareValueStatus === 1 && `in ${currencies.find((cur) => cur.id === HWCostingState.HwGenericContItems[0]?.invCurr_type)?.name}`}</p></TableCell>
                                    <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(HWCostingPageVariables.allocationTotal)}{HWCostingPageVariables.hardwareValueStatus === 2 && '%'}</p></TableCell>
                                  </TableRow>
                                  {HWCostingPageVariables.allocationTotal - (HWCostingPageVariables.hardwareValueStatus === 2 ? 100 : HWCostingState.total) !== 0 && (
                                    <TableRow className="varianceRow">
                                      <TableCell><div style={{ display: 'flex', alignItems: 'center' }}><ErrorOutline /><p style={{ marginLeft: 15 }}>{tra('Fark')}</p></div></TableCell>
                                      <TableCell colSpan={4} style={{ textAlign: 'end' }}><p>{FormatCurrency(HWCostingPageVariables.allocationTotal - (HWCostingPageVariables.hardwareValueStatus === 2 ? 100 : HWCostingState.total))}{HWCostingPageVariables.hardwareValueStatus === 2 && '%'}</p></TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid style={{ padding: 0 }} className="rowWithoutInfo" item xs={4}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="right">
                              <>
                                <CustomButton
                                  opacity
                                  endIcon={<UnfoldMore width={12} />}
                                  onClick={(e) => changeHardwareValueStatusMenu(e.currentTarget)}
                                >{HWCostingPageVariables.hardwareValueStatus === 1 ? tra('Absolute_Value') : tra('Percentage')}
                                </CustomButton>
                                <CustomMenu
                                  anchorEl={HWCostingPageVariables.hardwareValueStatusMenu}
                                  open={Boolean(HWCostingPageVariables.hardwareValueStatusMenu)}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  keepMounted
                                  onClose={() => changeHardwareValueStatusMenu(null)}
                                >
                                  <MenuItem onClick={() => changehardwareValueStatus(1)}>{tra('Absolute_Value')}</MenuItem>
                                  <MenuItem onClick={() => changehardwareValueStatus(2)}>{tra('Percentage')}</MenuItem>
                                </CustomMenu>
                              </>
                              <CustomButton
                                onClick={() => setHWCostingPageVariables({
                                  ...HWCostingPageVariables,
                                  addHWModal: true,
                                })}
                                startIcon={<Plus width={12} />}
                              >{tra('Donanim_Ekle')}
                              </CustomButton>
                            </div>
                          </div>
                          {HWCostingPageVariables.sendHardwares.length > 0 && (
                            <div className="chartWrapper">
                              <AllocationPie type="hw" applications={hardwares} data={HWCostingPageVariables.sendHardwares} />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="gridRow" container item xs={12} spacing={3}>
                      <Grid className="rowLeft" item xs={12}>
                        <div className="box">
                          <div className="headerGrid">
                            <div className="left">
                              <h4>{tra('Add_Document_And_Link')}</h4>
                              <p>{tra('You_Can_Add_Your_Documents_And_Save_Your_Data')}.</p>
                            </div>
                            <div>
                              <CustomButton
                                onClick={() => setHWCostingPageVariables({
                                  ...HWCostingPageVariables,
                                  costingDocModal: true,
                                })}
                                startIcon={<Plus width={12} />}
                                active
                              >
                                {tra('Ekle')}
                              </CustomButton>
                            </div>
                          </div>
                          <div className="documentList">
                            {(fileArr.length > 0 || fileUrlArr.length > 0) && (
                              <Table>
                                <TableHead>
                                  <TableCell colSpan={4}>{fileArr.length} {tra('Dokuman')} / {fileUrlArr.length} Link</TableCell>
                                </TableHead>
                                <TableBody>
                                  {fileArr?.map((file, index) => (
                                    <TableRow>
                                      <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><DocumentPDF /></TableCell>
                                      {/* <TableCell style={{ width: 'auto' }}>{match.params.type === 'register' ? file.name : <a href={file.file_url} target="_blank" rel="noreferrer">{file.filename}</a>}</TableCell> */}
                                      <TableCell style={{ width: 'auto' }}>{file.name}</TableCell>
                                      <TableCell style={{ width: 150, textAlign: 'center' }}>{parseInt(file.size / 1024, 10)} Kb</TableCell>
                                      <TableCell style={{ width: 50, textAlign: 'center' }}>
                                        {removeCheckClick === index
                                          ? (
                                            <div style={{ display: 'flex' }}>
                                              <IconButton
                                                onClick={() => {
                                                  // if (match.params.type !== 'register') {
                                                  //   this.props.deleteFile(file.id, match.params.type, 'draft');
                                                  // } else {
                                                  fileArr.splice(index, 1);
                                                  this.props.setCostingFiles(fileArr);
                                                  this.setState({ fileArr });
                                                  // }
                                                }}
                                                style={{ padding: 5 }}
                                              >
                                                <Check style={{ color: Colors.green }} />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => this.setState({ removeCheckClick: false })}
                                                style={{ padding: 5 }}
                                              >
                                                <Close style={{ color: Colors.red }} />
                                              </IconButton>
                                            </div>
                                          )
                                          : (
                                            <IconButton
                                              onClick={() => this.setState({ removeCheckClick: index })}
                                              style={{ padding: 5 }}
                                            ><Delete />
                                            </IconButton>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  {fileUrlArr?.map((url, index) => (
                                    <TableRow>
                                      <TableCell className="iconCell" style={{ width: 50, textAlign: 'start' }}><Public /></TableCell>
                                      <TableCell colSpan={2} style={{ width: 'auto' }}>{url}</TableCell>
                                      <TableCell style={{ width: 50, textAlign: 'center' }}>
                                        <IconButton
                                          onClick={() => {
                                            fileUrlArr.splice(index, 1);
                                            this.props.setCostingFileUrls(fileUrlArr);
                                            this.setState({ fileUrlArr });
                                          }}
                                          style={{ padding: 5 }}
                                        ><Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </InputWrapper>
                <ActionFooter>
                  <CustomButton onClick={() => history.push('/Costing')}>{tra('Iptal_Et')}</CustomButton>
                  <CustomButton onClick={() => this.createCost(false)}>{tra('Save_Close')}</CustomButton>
                  <CustomButton onClick={() => this.handleSubmitToFromViewDetail()}>{tra('Save_View')}</CustomButton>
                </ActionFooter>
              </GridPageWrapper>
            </div>
          </div>
        </PageContent>
        <AddHWToContract
          addHardware={addHWToContract}
          open={HWCostingPageVariables.addHWModal}
          close={() => setHWCostingPageVariables({
            ...HWCostingPageVariables,
            addHWModal: false,
          })}
          type={HWCostingPageVariables.hardwareValueStatus}
          sendHardwares={HWCostingPageVariables.sendHardwares}
          contractType={HWCostingState.type_id}
          compCurrency={compCurrency}
        />
        <CostingDocModal
          open={HWCostingPageVariables.costingDocModal}
          close={() => setHWCostingPageVariables({
            ...HWCostingPageVariables,
            costingDocModal: false,
          })}
          type="register"
          fileType="hardware"
          setFileArr={(files) => this.setState({ fileArr: files })}
          setFileUrlArr={(fileUrl) => this.setState({ fileUrlArr: fileUrl })}
        />
      </PageWrapper>
    );
  }
}

HWCostingRegister.propTypes = {
  currencies: PropTypes.array.isRequired,
  hwCategories: PropTypes.array.isRequired,
  costingFiles: PropTypes.array.isRequired,
  createHWCosting: PropTypes.func.isRequired,
  setResetState: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  setCostingFiles: PropTypes.func.isRequired,
  tcoAggregations: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  costingFileUrls: PropTypes.array.isRequired,
  setCostingFileUrls: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setSendToDetailPageID: PropTypes.func.isRequired,
  sendToDetailID: PropTypes.number.isRequired,
  sendToView: PropTypes.bool.isRequired,
  setSendToView: PropTypes.func.isRequired,
  getHWCostingCategories: PropTypes.func.isRequired,
  getHWCostingExpenses: PropTypes.func.isRequired,
  hardwares: PropTypes.array.isRequired,
  hwCostingExpenses: PropTypes.array.isRequired,
  // match: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hardwares: selectHardwareDevices(),
  hwCategories: selectHWCostingCategories(),
  currencies: selectInfos('Currencies'),
  tcoAggregations: selectInfos('TcoAggregations'),
  resetState: selectResetState(),
  costingFiles: selectCostingFiles(),
  user: selectActiveUser(),
  costingFileUrls: selectCostingFileUrls(),
  sendToDetailID: selectSendToDetailPageID(),
  sendToView: selectSendToView(),
  hwCostingExpenses: selectHWCostingExpenses(),
  hwCostingList: selectHWCostingList(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getHWCostingCategories,
      createHWCosting,
      setResetState,
      setCostingFiles,
      setToast,
      setCostingFileUrls,
      setSendToDetailPageID,
      setSendToView,
      getHWCostingExpenses,
      getHardwareDevices,
      getHWCostingList,
    }, dispatch)
  )
);
const HWCostingRegisterWithTheme = withTheme(HWCostingRegister);
const HWCostingRegisterWithWidth = withWidth()(HWCostingRegisterWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(HWCostingRegisterWithWidth);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// import { HardwareContext } from '../../commons/context/hardware';
import { v4 as uuidv4 } from 'uuid';
import GridRender from './GridRender';
import { HardwareInputWrapper } from '../../assets/styledNew';
import { HardwareContext } from '../../commons/context/HardwareContext';
import RowGridRender from './RowGridRender';

export default class PagesRender extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType=HardwareContext

  componentWillUnmount() {
    const { pagesIndex } = this.props;
    if (pagesIndex === this.context.pagesNumber) this.context.setContextPagesNumber(this.context.pagesNumber - 1);
  }

  // sonradan eklenebilir alanların uı çizilmesini engelliyor.
  definedAreaFilter=() => this.props.pagesData.filter((item) => item.some((element) =>
    !element.definedName || this.context.setContextDefinedControl(element.definedName)))

  pagesHide=() => this.definedAreaFilter().flat(Infinity).map((i) => i.inputArray.length !== 0).some((e) => e === true)

  render() {
    const { pagesData } = this.props;
    return (
      <HardwareInputWrapper hardwareDetail={this.context.isHardwareDetail}>
        <Grid container spacing={{ md: 2 }}>
          { pagesData && this.definedAreaFilter().map((gridData, gridIndex) =>
            ((Array.isArray(gridData[0]) && gridData.length === 2)
              ? (
                <RowGridRender
                  key={uuidv4()}
                  gridData={gridData}
                  gridIndex={gridIndex}
                  pagesIndex={this.props.pagesIndex}
                />
              )
              : (
                <GridRender
                  key={uuidv4()}
                  gridData={gridData}
                  gridIndex={gridIndex}
                  pagesIndex={this.props.pagesIndex}
                  propsChildren={this.props.children}
                />
              )))}
        </Grid>
      </HardwareInputWrapper>
    );
  }
}

PagesRender.propTypes = {
  pagesData: PropTypes.array.isRequired,
  pagesIndex: PropTypes.number.isRequired,
  children: PropTypes.node,
};
PagesRender.defaultProps = {
  children: false,
};

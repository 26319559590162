import styled, { createGlobalStyle, css } from 'styled-components';
import {
  Button, Tab, Tabs, SwipeableDrawer, Modal, Menu, Dialog, Grid, MenuList, Accordion, AccordionSummary, AccordionDetails, Radio, DialogContent,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Divider from '@material-ui/core/Divider';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { DatePicker } from '@material-ui/pickers';
import { Colors } from './statics';
import loginBG from './img/bg.png';
import mobileLoginBG from './img/mobileBg.png';
import loginCheckBG from './img/bgCheck.png';
import { hexToRgbA, scrSize } from '../commons/utils';
// import { HeaderBottomSheet } from '../components/BottomSheet';

export const GlobalStyle = createGlobalStyle`
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-Regular.ttf') format('truetype');
  //   font-weight: normal;
  // }
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-ExtraLight.ttf') format('truetype');
  //   font-weight: 100;
  // }
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-Thin.ttf') format('truetype');
  //   font-weight: 300;
  // }
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-Medium.ttf') format('truetype');
  //   font-weight: 500;
  // }
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-SemiBonpld.ttf') format('truetype');
  //   font-weight: 600;
  // }
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-Bold.ttf') format('truetype');
  //   font-weight: 700;
  // }
  // @font-face {
  //   font-family: 'Inter';
  //   src: url('./fonts/Inter-ExtraBold.ttf') format('truetype');
  //   font-weight: 900;
  // }
  @media print {
    * {
      margin: 0;
      padding: 0;
    }
    .print {
      background-color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999;
      > div:last-child {
        display: none;
        visibility: hidden;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.blue}; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.blue}; 
  }
  * {
    outline: none!important;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
  }
  body {
    background:${(props) => props.theme.mainBackg};
    overflow-x: hidden;
  }
  a, a:hover {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  .mention__input{
    color: ${(props) => props.theme.text1} !important;
  } 
  .mention {
    color: ${(props) => props.theme.blueText} !important;
  }
  .mention.mention--multiLine {
    height: 100px;
  }
  .mention.mention--multiLine, .mention.mention--multiLine textarea {
    padding: 15px;
    height: 100px;
  }
  .mention__suggestions__list {
    background: ${(props) => props.theme.boxBackg};
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 10px;
    overflow: hidden;
    overflow-y: overlay;
    max-height: 150px;
  }
  /* .mention__suggestions__list li {
    border-bottom: 1px solid ${(props) => props.theme.border};
    padding: 2px 5px;
  } */
  .mention__suggestions__list li.mention__suggestions__item--focused {
    background: ${(props) => props.theme.inputBackg};
  }
  .mention__suggestions {
    // padding-top: 25px!important;
    z-index: 25!important;
    background: transparent !important;
    top: 25px !important;
  }
  .MuiCheckbox-root {
    color: ${(props) => props.theme.blueText} !important;
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.text1};
    white-space: nowrap;
    font-size:13px;
  }
  .MuiDialogContent-root.content, .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollBody {
    overflow: visible;
  }
  .MuiRadio-root {
    color: ${(props) => props.theme.blueText} !important;
    :hover {
      background-color: rgba(33, 68, 212, 0.1);
    }
  }
  .vega-embed .chart-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .MuiPaper-root {
    background: ${(props) => props.theme.boxBackg} !important;
    color: ${(props) => props.theme.text1} !important;
  }
  .checkboxDiv {
    color: ${(props) => props.theme.text1}!important;
    .MuiTypography-root {
      color: ${(props) => props.theme.text1}!important;
    }
  }
  .MuiMenu-paper {
    max-height: calc(50% - 100px);
  }
  .react-calendar-timeline {
    background-color: white;
    margin: 10px;
    .rct-scroll {
      width: 100%;
    }
    .rct-header-root {
      background-color: white;
      border-bottom: 1px solid #C1D1E2;
    }
    .rct-calendar-header {
      border: unset;
    }
    .rct-sidebar {
      border-right: unset;
      .rct-sidebar-row {
        border-bottom: 1px solid #C1D1E2;
        background-color: white!important;
      }
    }
    .rct-vertical-lines {
      .rct-vl {
        border-left: 1px dashed #C1D1E2;
      } 
    }
    .rct-horizontal-lines {
      .rct-hl-even {
        border-bottom: 1px solid #C1D1E2;
        background-color: white;
      }
      .rct-hl-odd {
        border-bottom: 1px solid #C1D1E2;
        background-color: white;
      }
    }
  }
`;

export const Waiter = styled.div`
  position: fixed;
  background: ${(props) => props.theme.mainBackg};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  ${(props) => !props.active && `
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  `};
  img {
    max-width: 200px;
  }
`;

export const SidebarWrapper = styled.div`
  background: ${Colors.purple};
  height: calc(100vh - 50px);
  width: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 25px 15px;
  position: relative;
  .menu {
    background: ${(props) => props.theme.sidebarMenuBackg};
    border-radius: 23px;
    width: 100%;
    margin-top: 25px;
    box-shadow: 0px 8px 14px rgba(6, 12, 34, 0.5);
    position: relative;
    z-index: 0;
    padding: 3px 0;
  }
  .companyLogo {
    width: 45px;
    margin-top: 20px; 
    border-radius: 50px;
  }
  .footer {
    position: absolute;
    bottom: 25px;
  }
  @media screen and (max-height: 720px) {
    width: 56px;
    .menu {
      border-radius: 20px;
    }
    .companyLogo {
      width: 55px;
    }
  }
`;

export const MenuActive = styled.div`
  height: 55px;
  width: calc(100% - 8px);
  position: absolute;
  right: 4px;
  top: ${(props) => (props.index === -1 ? 0 : props.index * 55 + 5)}px;
  left: 4px;
  background: ${(props) => props.theme.sidebarActiveBackg};
  border: 1.5px solid ${(props) => props.theme.sidebarActiveBorder};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px ${hexToRgbA(Colors.black, 0.25)}, 0px 6.28814px 6.28814px rgba(53, 63, 102, 0.08), 0px 6.28814px 14px rgba(53, 63, 102, 0.09);
  border-radius: 23px;
  margin-bottom: 5px;
  z-index: -1;
  transition: all .5s cubic-bezier(0.31, 0.67, 0.44, 1.05);
  @media screen and (max-height: 720px) {
    height: 50px;
    border-radius: 20px;
    top: ${(props) => props.index * 55 + 5}px;
    
  }
`;

export const SidebarMenuItem = styled(Link)`
  height: 55px;
  // width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media screen and (max-height: 720px) {
    height: 55px;
    width: 55px;
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  filter: ${(props) => (props.state ? 'blur(13px)' : 'blur(0)')};
  ${(props) => (props.state ? `
    transform: translate(0%, -100%);
    transition: all 0.5s ease;
    ` : `
    transform: translate(0%, 0%);
    transition: all 0.5s ease;
  `)}
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
  }
  .mainContent {
    width: ${(props) => (props.active ? 'calc(100% - 90px)' : '100%')};
    background: ${(props) => props.theme.mainBackg};
    overflow-y: auto!important;
    background-color: ${(props) => props.theme.mainBackg};
    height: 100vh;
  }
`;

export const CustomButton = styled(Button)`
  ${(props) => props.shadow && 'box-shadow: 0px 4px 20px rgba(53, 63, 102, 0.2);'}
  ${(props) => {
    const colors = (props.color || (props.blue && Colors.purple) || (props.green && Colors.green) || (props.red && Colors.red)) || Colors.blue;
    return (props.opacity
      ? `color: ${Colors.purple};
    background: ${hexToRgbA(colors, 0.15)};
    @media(hover: hover) and (pointer: fine) {
      :hover {
      background: ${colors};
      color: ${Colors.white};
      
    }}`
      : `
    color: ${(props.outlined || props.transparent) ? colors : Colors.white};
    background: ${(props.outlined || props.transparent) ? 'transparent' : colors};
    border: 1px solid ${props.outlined ? colors : 'transparent'};
      :hover {
      background: ${(props.outlined) ? colors : hexToRgbA(colors, 0.8)};
      color: ${Colors.white};
    }
    @media(hover: hover) and (pointer: fine) {
      color: ${(props.outlined || props.transparent) ? colors : Colors.white};
    background: ${(props.outlined || props.transparent) ? 'transparent' : colors};
    border: 1px solid ${props.outlined ? colors : 'transparent'};
    }`
    );
  }}
  white-space: nowrap;
  text-transform: none;
  border-radius: 6px;
  padding: 0 25px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  transition: all .5s cubic-bezier(0.31, 0.67, 0.44, 1.05);
  min-width: fit-content;
  ${(props) => props.disabled && `
    background: rgba(13,12,34,0.05) !important;
    color: #a0a1ab !important;
  `};
  svg:not(.MuiCircularProgress-svg) {
    padding-left: 5px;
    padding-right: 5px;
    path {
      fill: currentColor !important;
    }
    margin: 0px!important;
  }
`;

export const ColorBox = styled.div`
  ${(props) => (props.state ? `
    transform: translate(0%, 0%);
    transition: all 0.5s ease;
  ` : `
    transform: translate(0%, -100%);
    transition: all 0.5s ease;
  `)}
  background: url('${loginBG}') , ${Colors.lightGrey800} ;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  @media ${scrSize('xs', 'sm')}{
    background: url('${mobileLoginBG}') , ${Colors.lightGrey800};
    background-size: cover;
    background-position: center;
  }
`;

export const LoginBox = styled.div`
  background: ${(props) => (props.stepIndex !== 4 ? hexToRgbA(Colors.white, 0.8) : `url('${loginCheckBG}') , ${hexToRgbA(Colors.white, 0.8)}`)};
  background-size: cover;
  background-position: center;
  box-shadow: 0px 40px 40px rgba(76, 111, 255, 0.2);
  backdrop-filter: blur(9px);
  border-radius: 30px;
  width: 40%;
  height: calc(100vh/1.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  padding: 75px;
  ${CustomButton} {
    margin-bottom: 20px;
    padding: 20px 25px;
  }
  .signInButton {
    margin:0 auto;
  }
  .prevButton {
    font-weight: 600;
    font-size: 20px;
    color: ${Colors.blue100};
    svg {
      padding-right: 10px;
    }
  }
  a {
    color: ${Colors.darkBlue300};
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    svg {
      padding-left: 10px;
    }
  }
  h1  {
    color: ${Colors.darkBlackBlue};
    font-weight: 500;
    font-size: 45px;
    text-align: center;
    padding-bottom: 10px;
  }
  h5 {
    color: ${Colors.grey};
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
  }
  .formArea {
    display: flex;
    flex-direction: column;
    place-items: center;
    margin: 0 auto;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .registerEndPage {
    margin-bottom: auto;
  }
  .headerArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px; 
  }
  .inputArea {
    width: 85%;
    margin: auto 0;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    .multipleInputWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      > div + div {
        margin-left: 25px;
      }
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0, -6px) scale(0.75);
    }
  }
  .inputArea div {
    margin: 5px auto;
  }
  .forgotPassword {
    width: fix-content;
  }  
  .formGroup {
    display: flex;
    position: relative;
    align-content: space-between;
    /* height: -webkit-fill-available; */
    height: 75%;
    width: calc(400% + 300px);
    padding-bottom: 20px;
    ${(props) => ((props.stepIndex !== 1 || props.reverse) && 'transition: all 1s ease')};
    right: ${(props) => (props.stepIndex !== 1 ? `calc(${(props.stepIndex - 1) * 100}% + ${props.stepIndex === 2 ? 75 : props.stepIndex * 75}px)` : '0')}; 
    .formArea {
      margin: 0 75px;
    }
    .formArea:first-child {
    margin: 0;
    }
    .formArea:last-child {
    margin: 0;
    }
  }
  .MuiInputBase-root {
    background: transparent !important;
  }
  .MuiInputBase-fullWidth {
    width: 100% !important;
    margin: 0 auto;
    color: ${Colors.lightGrey400};
  }
  .MuiFormHelperText-root {
    width: 100% !important;
    margin: 0 auto !important;
  }
  .MuiInputBase-input { 
    color: ${Colors.purple} !important;
  }
  .MuiInputBase-input::placeholder { 
    color: ${Colors.grey} !important;
  }
  @media ${scrSize('xs', 'sm')} {
    width: 100%;
    overflow-y: auto;
    padding: 100% 45px;
    .formGroup {
      height: 100%;
      padding-bottom: 30px;
    }
    .formArea {
      > div:first-child {
        width: 100%;
      }
      h1 {
        font-size: 22px;
        padding-top: 50px;
        text-align: left;
      }
      h5 {
        display: none;
      }
      .inputArea {
        width: 100%;
        /* margin: 0; */
        /* padding-bottom: 65px; */
      }
      .registerEndPage h5 {
        display: block;
        text-align: center;
      }
      ${CustomButton} {
        width: 100%;
        margin: 25px 0;
      }
    }
  } 
`;

export const StepperBar = styled.div`
  display: inline-flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '30%')};
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    vertical-align: middle;
    background-color: ${Colors.lightGrey200};
    z-index: 0;
  }
  ::before {
    content: "";
    position: absolute;
    height: 3px;
    transition: width 0.5s ease;
    width: ${(props) => (props.stepIndex === 1 ? '0px' : props.stepIndex === props.children.length + 1 ? '100%' : `calc(100% * ${(props.stepIndex - 1) / (props.children.length - 1)})`)};
    vertical-align: middle;
    background-color: ${Colors.blue};
    z-index: 1;
  }
  div:nth-child(${(props) => `-n+ ${props.stepIndex}`}) {
    transition: background-color 1s;
    background-color: ${Colors.blue};
    pointer-events: auto;
    cursor: auto;
    * {
      display: block;
    }
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: ${Colors.lightGrey200};
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
    * {
      display: none;
    }
  } 
`;

export const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.mainBackg};
  width: 100%;
  ${(props) => props.drawer && `
    width: calc(100% - 240px);
  `}
  transition: width 0.1s ease-in-out;
`;

export const PageHeader = styled.div`
  // height: 60px;
  display: flex;
  align-items: center;
  h1 {
    color: ${(props) => props.theme.text1};
    flex: 1;
    position: relative;
    font-size: 30px;
    padding: 15px 0;
    margin: 0;
    font-weight: 600;
    :after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100px;
      height: 3px;
      background-color: ${(props) => props.theme.text1};
    }
  }
  .rightContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > * {
      margin-left: 10px;
    }
  }
  .buttonGroup {
    display: flex;
    button {
      margin: 0px 10px;
    }
    svg {
      margin-left: 10px;
    }
    .hr {
      width: 1px;
      background: ${Colors.lightGrey800};
    }
  }
`;

export const PageContent = styled.div`
  // overflow: hidden;
  .pageInner {
    padding: 20px 60px;
  }
  @media ${scrSize('sm', 'xs')} {
    overflow: visible;
    .pageInner {
      padding: 0px 10px;
    }
  }
`;

export const ActionFooter = styled.div`
  z-index: 5;
  padding: 10px 60px 10px 150px;
  position: fixed;
  bottom: 0;
  right: 5px;
  left: 0;
  background: ${(props) => props.theme.sidebarActiveBackg};
  display:  flex;
  justify-content: ${(props) => (props.multiple ? 'space-between' : 'flex-end')};
  align-items: center;
  box-shadow: 0px 4px 33px rgb(53 63 102 / 10%);
  button + button {
    margin-left: 20px;
  }
`;

// export const PersonelDetailsPage = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   .userImage {
//     position: relative;
//     display: inline-block;
//     padding: 5px;
//     margin: 0 auto;
//     img {
//       width: 23vw;
//       height: 23vw;
//       object-fit: cover;
//       border-radius: 50%;
//       box-shadow: 0px 0px 20px 5px rgb(53 63 100 / 15%);
//     }
//     .userImgChangeButton {
//       position: absolute;
//       background: ${Colors.lightBlue100};
//       border: 3px solid ${(props) => props.theme.mainBackg};
//       bottom: 0;
//       right: 0;
//     }
//   }

//   .content {
//     width: 90%;
//     padding-top: 10px;
//     margin: 10px auto;
//     border-top: 1px solid ${(props) => props.theme.border};
//     .listItem {
//       padding-bottom:15px;
//       p:first-child{
//         font-size: 12px;
//         padding-bottom:5px;
//         color:${(props) => props.theme.text2}
//       }
//       p {
//         font-size: 14px;
//         color:${(props) => props.theme.text1}
//       }
//     }
//   }
// `;

export const PersonelDetailGrid = styled(Grid)`
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
  }
  .userImgChangeButton {
    position: absolute;
    background: ${Colors.lightBlue100};
    border: 10px solid ${(props) => props.theme.boxBackg};
    bottom: 0;
    right: 0;
  }
  .userImage {
    width: 65%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 0 auto;
  }

  p {
    font-size: 18px;
    color: #6C6C8F;
    margin: 5px 0;
    font-weight: 400;
  }
  .name {
    font-size: 22px;
    color: #353F66;
    font-weight: 500;
  }
  .title {
    font-size: 18px;
    font-weight: 400;
    color: ${Colors.lightPurple};
  }
  .role {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: ${Colors.lightGreen}; 
    color: ${Colors.green};
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0 20px 0;
  }
  .lastlogin {
    font-size: 14px;
  }

  .profilContact {
    display: flex;
    flex-direction: column;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    div {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;

    div:last-child {
    margin-bottom: 0;
    }

    svg  {
      margin-right: 10px;
    }
    }
  }

  & > div {
   background: ${(props) => props.theme.boxBackg};
  }

  @media ${scrSize('xs', 'sm')}{
    .userImage {
      width: 20%;
      min-width: 150px;
      
    }
  }
`;

export const Widget = styled.div`
  background: ${(props) => props.theme.boxBackg};
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 420px;
  filter: drop-shadow(0px 4px 3px ${(props) => props.theme.widgetShadow});
 
  .content {
    padding: 15px;
    height: inherit;
    .legendHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      .legendItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
        p {
          margin-left: 5px;
          font-size: 12px;
          color: ${(props) => props.theme.text1};
        }
      }
    }
    > p {
      color: ${(props) => props.theme.text2};
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      height: 18px;
      overflow: hidden;
    }
    h3 {
      color: ${(props) => props.theme.text1};
      font-size: 27px;
      font-weight: 600;
      padding: 10px 0;
    }
    .info {
      font-size: 10px;
      color:${(props) => props.theme.text3};
      span {
        font-weight: 700;
        padding: 5px 15px;
        background: ${(props) => props.theme.widgetInfoBackg};
        color: ${(props) => props.theme.widgetInfoText};
        border-radius: 6px;
      }
    }
  }
  .icon {
    position: absolute;
    right: 5px;
    top: 5px;
    background: ${(props) => props.theme.widgetIconBackg};
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.white};
    border-radius: 100%;
  }
  .calendar {
    display: flex;
    flex-direction: column;
    /* max-height: 180px; */
    overflow: hidden;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      padding: 25px 0 25px 20px;
      :before, :after {
        content: "";
        left: 5px;
        top: 0;
        height: 8px;
        width: 2px;
        position: absolute;
        background-color: ${Colors.lightGrey700};
      }
      :after {
        top: auto;
        bottom: 0;
      }
      :first-child {
        :before {
          display: none;
        }
      }
      :last-child {
        :after {
          display: none;
        }
      }
      * {
        font-size: 15px;
      }
      .dot {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translate(0, -50%);
        content: "";
        width: 6px;
        height: 6px;
        border: 2px solid ${Colors.blue};
        border-radius: 100%;
      }
      p {
        flex: 1;
        padding: 0 15px;
        margin: 0;
        color: ${(props) => props.theme.text2};
        height: 25px;
        overflow: hidden;
        line-height: 25px;
        font-size: 14px;
      }
      strong {
        width: 80px;
        color: ${(props) => props.theme.text1};
        font-size: 10px;
        font-weight: 500;
      }
      span {
        font-weight: bold;
        font-size: 10px;
        border-radius: 6px;
        padding: 5px 15px;
      }
    }
  }
  .cost {
    .content {
      padding: 15px;
      border-radius: 16px;
      border: 1px solid ${(props) => hexToRgbA(props.theme.border, 0.3)};
      position: relative;
      p {
        font-size: 12px;
        color: ${(props) => props.theme.text1};
      }
      .icon {
        background: ${(props) => props.theme.widgetIconBackg};
      }
      & + .content {
        margin-top: 15px;
      }
    }
  }
  .header {
    display: flex;
    align-content: center;
    padding: 0px 0px 15px 0px;
    color: ${(props) => props.theme.text2};
    
    h4 {
      flex: 1;
      font-weight: 600;
      font-size: 16px;
      color: ${(props) => props.theme.text2};
      display: flex;
      align-items: center;
    }
    button {
      font-size: 12px;
    }
    button + button {
      margin-left: 15px;
    }
  }
  & + & {
    margin-top: 15px;
  }

  /* @media ${scrSize('xs')} {
    height: fit-content;
  } */
`;

export const ClassButton = styled.div`
display:flex;
justify-content:center;
align-items:center;
width:100%;
height:500px;
background-color:${Colors.white};
> button{
margin:10px;
}
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  .header {
    display: flex;
    align-content: center;
    margin-bottom: 15px;
    h4 {
      flex: 1;
      font-size: 18px;
      color: ${(props) => props.theme.text1};
      display: flex;
      align-items: center;
    }
    button {
      font-size: 12px;
    }
    button + button {
      margin-left: 15px;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    > button {
      text-transform: none;
      padding: 5px 10px;
    }
  }
`;

export const CustomModal = styled(Dialog)`
  .MuiDialog-paper {
    background: unset;
  }
  .MuiDialogContent-root {
    background: ${(props) => props.theme.boxBackg};
    border-radius: 15px;
  }
  > div:first-child {
    background-color: ${hexToRgbA(Colors.purple, 0.70)} !important;
  }
`;

export const CustomModalWrapper = styled(Modal)`
  ${(props) => props.center && `
  display: flex;
  align-items: center;
  justify-content: center;
  `};
  .MuiDialogContent-root {
    background: ${(props) => props.theme.boxBackg};
    border-radius: 15px;
  }
  > div:first-child {
    background-color: ${hexToRgbA(Colors.purple, 0.70)} !important;
  }
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid ${Colors.lightPurple300};
  height: 60px;
  display: flex;
  align-items: center;
  > h1, .withBackButton {
    flex: 1;
    display: inline-flex;
    align-items: center;
  }
  h1 {
    color: ${(props) => props.theme.text1};
    font-size: 22px;
    font-weight: 500;
  }
`;

export const CustomUpdateWarnModal = styled(DialogContent)`
  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      color: ${(props) => props.theme.text1};
      font-size: 30px;
      margin: 10px 0px;
      text-align: center;
      font-weight: 600;
    }
    p {
      color: ${(props) => props.theme.dateboxLine};
      font-size: 20px;
      margin: 10px 0px;
      text-align: center;
      font-weight: 500;
    }
    .inputList {
      margin: 10px;
      width: 100%;
    }
    .iconWrapper {
      width: 100%;
      margin-bottom: 20px;
      .messageContent {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h3 {
          text-align: start;
          margin: 0px;
          font-size: 20px;
        }
        p {
          text-align: start;
          margin: 10px 0px 0px 0px;
          font-size: 15px;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    margin: 10px 0px 30px 0px;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    button {
      width: 100%;
    }
    button + button {
      margin-left: 20px;
    }
  }
`;

export const AddFileComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px 0px;
  svg {
    opacity: 0.5;
    width: 35px;
    height: 35px;
    path {
      fill: ${(props) => props.theme.purpleText};
    }
  }
  :hover {
    cursor: pointer;
    background-color: ${hexToRgbA(Colors.lightGrey300, 0.4)};
  }
  p {
    color: ${(props) => props.theme.purpleText};
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
  }
`;

export const ModalContent = styled.div`
  overflow: hidden;
  .pageInner {
    padding: 15px 0 25px 0;
    > div {
    padding: 10px 0px;
    }
    .MuiFormControl-root {
      margin: 0px;
    }
    .excelErrorList {
      display: flex;
      flex-direction: column;
      > p {
        span {
          font-weight: 600;
        }
        padding: 10px 0px;
        border-bottom: 1px solid #E1E6F7;
      }
    }
  }
  
`;

export const DocumentItem = styled.a`
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  .del {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: ${Colors.grey};
    transform: scale(0);
    transition: all 0.3s ease;
    border-radius: 4px;
    cursor: pointer;
    svg {
      padding: 0;
      color: white;
    }
  }
  .icon {
    :hover {
      .del {
        transform: scale(1);
      }
    }
  }
  svg {
    padding-right: 10px;
  }
  > p {
    font-size: 14px;
    font-weight: bold;
  }
  .name {
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const SwitchStatus = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  span {
    font-size: 13px;
    font-weight: 600;
    color: ${(props) => (props.active ? Colors.blue : props.theme.text1)};
    padding: 0 5px;
  }
  span:first-child {
    color: ${(props) => (props.active ? props.theme.text1 : Colors.blue)};
  }
`;

export const StarRating = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  margin-right: 10px;
  p {
    font-size:13px;
    color: ${(props) => props.theme.text1};
    font-weight:bold;
  }
  .ratingNumber {
    font-size: 13px;
    padding: 0 5px;
    text-align: center;
    width: 25px;
  }
  .MuiRating-icon {
    color: ${(props) => props.starColor};
  }
`;

export const RatingMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .menu {
    top: 25px;
    left: calc(-50% - 50px);
    display: ${(props) => (props.open ? 'flex' : 'none')};
    padding: 20px;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    z-index: 999;
  }
  button {
    padding: 0;
  }
`;

export const InputWrapper = styled.div`
  .smallInput{
    min-width: 150px;
    max-width: 200px;
  }
  .starRatingArea {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .header {
    display: flex;
    padding-bottom: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > button {
      :not(:last-child){
        margin-right: 10px;
      }
      :first-child {
        margin-right: auto;
      }
    }
    ${CustomButton} {
      padding: 0 10px;
    }
  }
  .MuiFormControl-root {
    input {
      box-sizing: border-box !important;
      height: 40px !important;
    }
    .MuiAutocomplete-root {
      box-sizing: border-box; 
      // height: 50px;
    }
  }
  .buttonWrapperLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    button:not(:first-child) {
      margin-left: 10px;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
      color: ${Colors.darkBlue};
    }
  }
  .buttonWrapperRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    button:not(:last-child) {
      margin-right: 10px;
    }
    .statuInputWrapper {
      margin: 0px 10px;
      min-width: 150px;
    }
  }
  .hr-top {
    padding-top: 45px;
    border-top: 1px solid ${Colors.lightPurple300};
  }
  .hr-bottom {
    padding-top: 45px;
    border-bottom: 1px solid ${Colors.lightPurple300};
  }
  .withButton {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    > button {
      margin-left: 10px;
    }
    .MuiFormControl-root {
      margin: 0;
    }
  }
  .infoBox {
    height: 100px;
    p:first-child {
      color: ${(props) => props.theme.text1};
      font-size: 14px;
      font-weight: 600;
    }
    p {
      color: ${(props) => props.theme.text2};
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  .inputFlexRow {
    display:flex;
    > div {
      :first-child {
        flex:2;
      }
      flex:1;
    }
    
  }
  .inputRow ,.inputFlexRow{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    > svg ,div:not(:last-child) {
      padding-right: 15px;
    }
    div:last-child div{
      padding-right: 0px;
      }
    }
    a {
      display: flex;
      font-weight: 600;
      align-items: center;
      color: ${Colors.blue};
      padding: 15px 0 55px 20px;
    }
  }
  .files {
    display: flex;
    flex-wrap: wrap;
    ${DocumentItem} {
      flex: 0 0 33%;
      overflow: hidden;
      :hover {
        .del {
          transform: scale(1);
        }
      }
      span {
        font-size: 11px;
      }
    }
  }
  @media ${scrSize('xs', 'sm')} {
    padding: 0px 15px 15px 15px;

    .inputFlexRow {
      flex-direction: column;
    }
    .infoBox {
      margin-bottom: 20px;
    }
    ${StarRating}:first-child {
      padding: 10px 5px;
    }
    .withSvg,.inputColumnMobile {
      padding: 0; 
      flex-direction: column;
      svg {
        display: none;
      }
    }
    .starRatingArea {
      background: ${Colors.white};
      border-radius: 10px;
      box-shadow: 0px 0px 10px #0000000f;
      margin-bottom: 20px;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      padding: 10px;
      ${StarRating} {
        padding: 10px 0px;
        margin: 0px;
      }
      .MuiFormControl-root {
        margin: 0px !important;
      }
      .MuiInputLabel-outlined {
        transform: translate(0px,-25px) scale(0.75) !important; 
      }
      ${SwitchStatus}{
        width: 100%;
       }
    }
  }
  @media ${scrSize('xs')} {
    padding: 0px 15px 15px 0px;
    /* .infoBox {
      margin-bottom: 20px;
    }
    ${StarRating}:first-child {
      padding: 0 20px 20px 20px;
    }
    .withSvg,.inputColumnMobile {
      padding: 0; 
      flex-direction: column;
      svg {
        display: none;
      }
    }
    .starRatingArea {
      background: ${Colors.white};
      border-radius: 10px;
      box-shadow: 0px 0px 10px #0000000f;
      margin-bottom: 20px;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      padding: 10px;
      ${StarRating} {
        padding: 10px 0px;
        margin: 0px;
      }
      .MuiFormControl-root {
        margin: 0px !important;
      }
      .MuiInputLabel-outlined {
        transform: translate(0px,-25px) scale(0.75) !important; 
      }
      ${SwitchStatus}{
        width: 100%;
       }
    } */
  }
  .searchAndAdd {
    display: flex;
    align-items: center;
    flex: 5;
    .MuiFormControl-root {
      margin: 0px 10px 0px 0px;
      flex: 1;
    }
    .MuiAutocomplete-tag {
      display: none;
    }
    .MuiFormControl-root .MuiAutocomplete-root {
      height: unset;
    }
    .appBoxList {
      flex: 4;
      overflow-x: auto;
      display: flex;
      align-items: center;
      padding: 5px;
      .appBox {
        transition: all 0.5s ease;
        display: flex;
        flex-direction: row;
        justify-content: space-betwen;
        padding: 10px 20px;
        align-items: center;
        background-color: ${(props) => props.theme.purpleBackg};
        border-radius: 5px;
        :not(:first-child) {
          margin-left: 15px;
        }
        .icon {
          box-shadow: 0px 3px 5px rgba(53, 63, 102, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: ${Colors.blue};
          svg {
            fill: ${(props) => props.theme.lightPurple300};
          }
        }
        .info {
          // margin-left: 20px;
          display: flex;
          flex-direction: column;
          p {
            font-size: 12px;
            font-weight: 600;
            color: ${(props) => props.theme.purpleText};
          }
        }
      }
    }
  }
  @media ${scrSize('sm', 'xs')} {
    .searchAndAdd {
      margin-top: 20px;
      .MuiFormControl-root {
        flex: 2;
      }
    }
  }
`;

export const HederWrapper = styled.div`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  padding: 15px 60px;
  width: 100%;
  transition: width 0.1s ease-in-out;
  ${(props) => props.drawer && `
    width: calc(100% - 350px);
  `};
  .left {
    .backButton {
      display: flex;
      align-items: center;
      p {
        margin-left: 10px;
        color: ${(props) => props.theme.text2};
      }
      button {
        background-color: ${(props) => props.theme.boxBackg};
        padding: 15px;
        svg {
          path {
            fill: ${(props) => props.theme.text2}!important;
          }
        }
      }
    }
    display: flex;
    align-items:center;
    > h1 {
      padding: 0 10px;
      font-size:22px;
      font-weight:600;
      color: ${(props) => props.theme.text1};
      white-space: nowrap;
    }
    .MuiBreadcrumbs-li {
      h1 {
        :not:(first-child) {
          padding: 0 10px;
        }
        font-size: 16px;
        font-weight: 600;
        color: ${(props) => props.theme.text2};
        white-space: nowrap;
        cursor: pointer;
      }
      :last-child {
        h1 { 
          color: ${(props) => props.theme.text1};
        }
      }
    }
    .search {
      position: relative;
      input {
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        padding: 0 25px;
        min-width: 350px;
      }
      svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    
    > ${CustomButton},.headerButton {
      margin-right: 30px;
    }
    svg {
      cursor: pointer;
    }
    .profile {
      position: relative;
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        object-fit: cover;
        display: flex;
      }
    }
    .actions {
      margin-right: 50px;
      display: flex;
      .language {
        margin-right: 50px;
      }
      .notifications {
        cursor: pointer;
        margin-left: 50px;
        position: relative;
        > span {
          position: absolute;
          position: absolute;
          right: -20px;
          top: -20px;
          background: ${Colors.blue};
          color: ${(props) => props.theme.text4};
          font-size: 12px;
          width: 25px;
          height: 25px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid ${(props) => props.theme.mainBackg};
          transition: all .5s ease-in-out;
          transform-origin: 50% 50%;        
        }
      }
    }
  }
`;
export const HeaderWindow = styled.div`
  transform-origin: 90% 0%;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding:20px 10px;
  max-height: 60vh;
  width: 350px;
  background:${(props) => props.theme.boxBackg};
  border-radius: 10px;
  z-index: 999;
  filter: drop-shadow(0px 35px 100px ${hexToRgbA(Colors.black, 0.08)});
  right: -26px;
  top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;
  ::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${(props) => props.theme.boxBackg};
    top: -20px;
    right: 15px;
  }
`;

export const NotificationWindow = styled(HeaderWindow)`
  opacity: ${(props) => (props.notification ? 1 : 0)};
  visibility: ${(props) => (props.notification ? 'visible' : 'hidden')};
  top: ${(props) => (props.notification ? 55 : 20)}px;
  .container {
    height: 100%;
    overflow: overlay;
    padding:0px 10px;
  }
  .footer {
    display: flex;
    justify-content: ${(props) => (props.clearAll ? 'space-between' : 'center')};
    align-items: center;
    background: ${(props) => props.theme.boxBackg};
    padding-top: 20px;
  }
  .dot {
    background: transparent;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    position: absolute;
  }
  .cardContainer {
    display: flex;
    width: 100%;
  }
  .cardHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color:${(props) => props.theme.text1}
  }
  .card {
    display: flex;
    overflow: hidden;
    position: relative;
    padding: 10px 5px;
    margin: 10px 0px;
    justify-content: space-between;
    transition: all .5s cubic-bezier(0.31, 0.67, 0.44, 1.05);
    :hover ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 95%;
      background: ${(props) => props.theme.hoverHeaderWindowBackg};
      border-radius: 10px;
      z-index: -1;
    }
  }
  .readActive::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 95%;
    background: ${(props) => props.theme.hoverHeaderWindowBackg};
    border-radius: 10px;
    z-index: -1;
    opacity: 0.5;
  }
  .date  {
    color: ${(props) => props.theme.notificationTimeText};
    background: ${(props) => props.theme.notificationTimeBackg};
    border-radius: 10px;
    padding: 5px;
    font-size: 10px;
    height: min-content;
    white-space: nowrap;
  }
  .title {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-weight: 700;
    font-size: 13px;
  }
  .desc {
    font-weight: 400;
    font-size: 12px;
    padding: 10px 0 15px 0;
    color: ${(props) => props.theme.text2}
  }
  @media ${scrSize('sm', 'xs')} {
    width: 90vw;
  }
`;

export const ProfilWindow = styled(HeaderWindow)`
  opacity: ${(props) => (props.profilMenu ? 1 : 0)};
  visibility: ${(props) => (props.profilMenu ? 'visible' : 'hidden')};
  top: ${(props) => (props.profilMenu ? 55 : 20)}px;
  border: 1px solid #F0F0F0;
  width: 230px;
  right: -23px;
  text-align: center;
  .header {
    border-bottom: 1px solid ${(props) => props.theme.border};
    padding: 10px 0 20px 0;
    font-size: 14px;
    color: ${(props) => props.theme.text1};
  }
  .menu {
    height: 100%;
    display: flex;
    flex-flow: wrap;
    padding: 5px 0;
    a {
      color: ${(props) => props.theme.text1};
      font-weight: 700;
      width: 70%;
      text-align: left;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 20px 0;
    }
    li {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      :hover {
        background: ${(props) => props.theme.hoverHeaderWindowBackg};
      }
    }
    svg {
      padding-right: 5px;
    }
  }
`;

export const SelectCompanyWrapper = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .companyContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: auto;
    ::-webkit-scrollbar {
    display: none;
}
  }
  > div:first-child {
    background-color: ${hexToRgbA(Colors.purple, 0.70)} !important;
  }
  .MuiFormControl-fullWidth {
    padding-top: 20px;
  }
  @media ${scrSize('xs', 'sm')}{
    justify-content: flex-start;
    overflow: auto;
  }
`;

export const CompanyCard = styled.div`
  cursor: pointer;
  .card {
    opacity: ${(props) => (props.active ? '1' : '0.8')}; 
    margin: 0 30px;
    background: ${(props) => props.theme.boxBackg};
    border-radius: 20px;
    width: 250px;
    padding: 10px;
    text-align: center;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 26px 36px rgba(18, 33, 96, 0.2);
    transition: all .5s cubic-bezier(0, 0, 0.2, 1);
    :hover {
      opacity: 1;
    }
  }
  .image {
    border-radius: 50%;
    background: ${hexToRgbA(Colors.lightBlue100, 0.20)};
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    img {
      border-radius: 50%;
      width: -webkit-fill-available;
    }
  }
  .name {
    font-weight: 600;
    font-size: 30px;
    color: ${(props) => props.theme.text2};
    padding: 20px 0 5px 0;
  }
  .desc {
    font-size: 14px;
    color: ${(props) => props.theme.text3};
  }

  @media ${scrSize('xs', 'sm')}{
    .card {
      margin: 0 10px;
      border-radius: 20px;
      width: 150px;
      padding: 0px;
      height: 250px;
    }
    .image {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      padding: 10px;
    }
    .name {
      font-size: 20px;
      padding: 20px 0 5px 0;
    }
    .desc {
      font-size: 14px;
    }
  }
`;

export const CarouselSlider = styled.div`
  height: calc(100% - 15px);
  overflow: hidden;
  .sliderContent {
    ${(props) => (props.children[0].props.children
    && `width : ${100 * props.children[0].props.children.length}%;
      transform: translateX(calc(${props.sliderStepIndex ? ((-100 / (props.children[0].props.children.length)) * (Math.abs(props.sliderStepIndex) % props.children[0].props.children.length)) : 0}%)); 
    `
  )}
    height: calc(100% - 25x);
    display: flex;
    transition: all .8s ease-in-out;
    color: ${(props) => props.theme.text2};
    font-size: 14px;
  }
  .sliderFooter {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 30px;
  }
  .buttonGroup {
    position: absolute;
    top: calc(100% - 28px);
    left: 0;
    svg {
      border-radius: 50%;
      padding: 2px;
      cursor: pointer;
    }
    svg:last-child {
      background: ${Colors.blue};
      path {
        fill: ${Colors.white};
      }
    }
    svg:first-child {
      background: ${Colors.lightGrey700};
      margin-right: 5px;
      path {
        fill: ${Colors.blue100};
      }
    }
  }
  .item {
    width: 100%;
    padding-top: 15px;
    color: ${(props) => props.theme.text2};
    height: 43px;
    overflow: hidden;
  }
`;
export const CustomDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paperAnchorLeft {
    left: 90px;
    box-shadow: 21px 13px 17px -1px rgba(53, 63, 102, 0.05);
    background-color: white !important;
    z-index: 98;
  }
  display: flex;
  position: relative;
  .footer {
    box-shadow: 0px -17px 21px rgba(53, 63, 102, 0.1);
    position: sticky;
    background: white;
    padding: 25px;
    bottom: 0;
    left: 0;
    .info {
      p {
        font-size: 12px;
        font-weight: 600;
        color: ${Colors.purple};
        span {
          color: ${Colors.blue};
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 15px;
      a {
        font-weight: 600;
        color: ${Colors.purple};
        margin-left: 15px;
      }
    }
  }
  @media ${scrSize('md')} {
    .MuiDrawer-paperAnchorLeft {
      left: 0;
      top: 65px;
    }
  }
  @media ${scrSize('xs', 'sm')} {
    .MuiDrawer-paperAnchorLeft {
      left: 0;
      width: 70%;
    }
  }
  @media screen and (max-height: 720px) and (min-width: 1280px) {
    .MuiDrawer-paperAnchorLeft {
      left: 85px;
    }
  }
  .reportDrawer {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    .reportDrawerHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      h4 {
        margin-left: 10px;
        color: ${Colors.darkBlue};
        opacity: 0.7px;
        font-weight: 600;
      }
    }
    .reportDrawerList {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      button {
        text-transform: none;
        padding: 5px 10px;
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 500;
        color: ${Colors.darkBlue};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
`;

export const Matrix = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MatrisRow = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  :last-child {
    height: 100px;
  }
  color: ${(props) => props.theme.text2};
  :nth-child(odd) {
    .main {
      background: ${hexToRgbA(Colors.lightBlue100, 0.03)};
    }
  }
  span {
    max-width: 150px;
    min-width: 150px;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
  }
  .main {
    width: 100%;
    margin-left: 0px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      :first-of-type {
        border-left: none;
      }
      :nth-child(even) {
        background: ${hexToRgbA(Colors.lightBlue100, 0.03)};
      }
    }
  }
  .mainRotate {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      > p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px;
        transform: rotate(-45deg);
      }
      :first-of-type {
        border-left: none;
      }
      :nth-child(even) {
        background: ${hexToRgbA(Colors.lightBlue100, 0.03)};
      }
    }
  }
`;

export const BoxCalendarWrapper = styled(Widget)`
  box-shadow: 0px 3px 8px -1px rgba(53, 63, 102, 0.05);
  border-radius: 16px;
  .row {
    max-height: 55vh!important;
    :hover {
    overflow-y: overlay;
    }
  } 
  .descArea {
    padding-left: 30px;
    border-left: 2px solid ${Colors.lightGrey700};
    margin-left: 5px;
  }
`;

export const FilteredSidebar = styled.div`
  background: ${(props) => props.theme.boxBackg};
  padding: 25px;
  border-radius: 8px;
  min-width: 220px;
  border-radius: 0;
  p {
    font-size: 13px;
    color: ${(props) => props.theme.text2};
  }
  label span {
    font-size: 11px;
    color: ${(props) => props.theme.text2};
  }
  .MuiFormControl-root {
    margin: 0px;
  }
  .title {
    border-bottom: 1px solid ${Colors.lightPurple300};
    color: ${(props) => props.theme.text2};
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .filterDrawefooter {
    margin-top: auto;
    display: none;
  }
  .MuiPaper-root.MuiAccordion-root.Mui-expanded, .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1 {
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid rgba(184, 200, 219, 5);
    background: ${(props) => props.theme.boxBackg};
  }
  .MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-expandIcon {
    color: ${(props) => props.theme.text1};
  }
  .MuiAccordionSummary-root.Mui-expanded, .MuiAccordionSummary-root {
    min-height: auto;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  @media ${scrSize('xs', 'sm')} {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow: hidden;
    .title {
      padding: 25px 0 20px 25px;
    }
    .filterContent {
      overflow: overlay;
      padding: 0 25px;
    }
    .filterDrawefooter {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: fit-content;
      padding: 5px 0;
      background: ${(props) => props.theme.mainBackg};
      width: -webkit-fill-available;
      p {
        font-size: 12px;
        font-weight: 600;
        padding: 0 0 10px 20px;
        width: 100%;
        color: ${(props) => props.theme.text2};
        span {
          color: ${(props) => props.theme.blueText};
        }
      }
      ${CustomButton} {
        margin-top: 5px;
        width: 70%;
      }
    }
  }
  `;

export const BoxCalendarContainer = styled.div`
  background: transparent;
  height: auto;
  max-height:  100vh;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  transition: all 1s ease;
  position: relative;
  width: 100%;
  > div{
  padding: 0 20px;
  }
  .dateBox {
    margin: 0 5px 10px 5px;
    z-index: 101;
    position: relative;
    height: 150px;
    border-radius: 15px;
    background: ${(props) => props.theme.dateBoxBackg};
    color:  ${(props) => props.theme.text4};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 5px 2px rgb(0 0 0 / 8%);
    > div {
      padding: 0 15px;
    }
    .day {
      font-size: 70px;
    }
    .year {
      font-size: 20px;
      color: ${(props) => hexToRgbA(props.theme.text4, 0.75)};
    }
    .month {
      font-size: 18px;
    }
  }
  .box {
    background: ${(props) => props.theme.boxBackg};
    border-radius: 15px;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 1s ease;
    position: relative;
    > div {
    padding: 0 20px;
    }
    .header {
      color: ${(props) => props.theme.text1};
      font-size: 20px;
      font-weight: 600;
      height: 75px;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 25px;
    }
    .content {
      height: 100%;
      overflow: overlay;
      max-height: 100%;
      height: auto;
      .card {
        display: flex;
        overflow: hidden;
        height: fit-content;
        position:relative;
        padding: 0 10px;
        justify-content: space-between;
        .dot {
          background: ${(props) => props.theme.boxBackg};
          left: 10px;
          border-radius: 50%;
          width: 15px;
          height: 100%;
          box-sizing: border-box;
          position: absolute;
          :after {
            content: "";
            position: absolute;
            width: 3px;
            height: 125px;
            top: 12px;
            background: ${Colors.lightGrey400}; 
            transform: translateX(100%);
          }
        }
        .container {
          padding-bottom: 10px;
          flex: 4;
          padding-left: 50px;
          .title {
            font-weight: 700;
            font-size: 13px;
            color: ${(props) => props.theme.text1};
          }
          .desc {
            font-weight: 400;
            font-size: 12px;
            padding: 10px 0 15px 0;
            color:${(props) => props.theme.text2};
          }
        }
        .date  {
          display: flex;
          flex: 1;
          p {
          color: ${(props) => props.theme.notificationTimeText};
          background: ${(props) => props.theme.notificationTimeBackg};
          border-radius: 10px;
          padding: 5px;
          font-size: 14px;
          height: min-content;
          }
        }
      }
    }
    .newEventBox {
      height: 125px;
      background: ${(props) => props.theme.newEventBoxBackg};
      box-shadow: inset 0px 4px 9px rgb(33 68 212 / 5%);
      border-radius: 15px;
      margin: 10px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > p {
        font-size: 13px;
        font-weight: bold;
        color: ${(props) => props.theme.text1};
        padding: 10px 0;
      }
    }
  }
  @media ${scrSize('xs', 'sm')} {
    height: auto;
  }
`;

export const NewCalendarEventModal = styled.div`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => props.mobile && 'overflow-x: hidden; height: unset;'};
  background-color: ${(props) => !props.mobile && `${hexToRgbA(Colors.purple, 0.70)}`};
  z-index: 100;
  .header {
    padding: 20px 0;
    margin-bottom: 40px;
    border-bottom: 2px solid lightgray;
    color: ${(props) => props.theme.text1};
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div { 
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    width: 500px;
    background: ${(props) => props.theme.boxBackg};
    border-radius: 10px;
    filter: drop-shadow(0px 35px 100px ${hexToRgbA(Colors.black, 0.08)});
    transition: all .2s ease;
    :after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 25px solid transparent;
      border-left: 25px solid ${(props) => props.theme.boxBackg};
      border-bottom: 25px solid transparent;
      right: -25px;
      filter: drop-shadow(0px 35px 100px ${hexToRgbA(Colors.black, 0.08)});
    }
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > p {
      color: ${(props) => props.theme.text1};
      font-size: 20px;
      font-weight: 600;
      padding: 20px 0;
    }
    .icon {
      padding: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    > button {
      margin:0 10px;
      svg{
        margin-right: 5px !important;
      }
    }
  }
  .card {
    width: -webkit-fill-available;
    border: 1px solid ${Colors.lightGrey400};
    border-radius: 15px;
    padding: 15px;
    display: flex;
    align-items: start;
    > div {
      padding-left: 10px;
      width: -webkit-fill-available;
    }
  }
  .cardHeader, .cardHeader > div {
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.theme.text1};
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    height: 45px;
  }
  .cardContent {
    font-size: 12px;
    color: ${(props) => props.theme.text4};
  }
  .cardFooter {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 20px;
    font-size: 12px;
    color: ${(props) => props.theme.text1};
    svg {
      padding-right: 10px;
    }
  }
  .MuiFormControl-root {
    margin: 15px 0;
  }
  @media ${scrSize('xs')} {
  position: relative;
  .container{
  width: -webkit-fill-available;
  }
  }
`;
export const TabWrapper = styled.div`
`;

export const CustomTabHead = styled(Tab)`
  .MuiTab-wrapper {
    flex-direction: row!important;
    align-items: center;
    > svg {
      margin-bottom: 0px!important;
      margin-right: 10px;
      > path {
        fill: ${(props) => props.theme.text2};
      }
      ${(props) => props.active && `
        > path {
          fill: ${props.theme.text4};
        }
      `}
    }
  };
  ${(props) => (props.opacity ? `
    color: ${props.theme.text2}!important;
    ${props.active && `
      color: ${props.theme.tablePagiBorder}!important;
    `};
  ` : `
      color: ${(!props.active ? props.theme.text2 : props.theme.text4)}!important;
  `)};
  background: ${(props) => (props.active ? Colors.blue : props.theme.tabHeadOtherBackg)}!important;
  text-transform: capitalize!important;
  font-weight: 600!important;
  height: 35px;
  opacity: 1;
  min-height: 35px;
  :first-child {
    border-top-left-radius: 5px!important;
  };
  :last-child {
    border-top-right-radius: 5px!important;
  };
  ${(props) => (props.tabValue ? (
    ` 
      :nth-child(${props.tabValue + 2}) {
        border-top-left-radius: 5px!important;
      };
      :nth-child(${props.tabValue}) {
        border-top-right-radius: 5px!important;
      };
    `
  ) : (
    `
      :nth-child(2) {
        border-top-left-radius: 5px!important;
      };
    `
  ))};
  ${(props) => props.active && `
    :not(:first-child) {
      margin: 0px 10px;
    }
    :first-child {
      margin-right: 10px;
    }
    border-top-left-radius: 5px!important;
    border-top-right-radius: 5px!important;
    height: 40px;
  `};
  transition: margin 0.5s ease-in-out;
`;

export const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    height: 100% !important;
  }
  .MuiTabs-indicator {
    display: none!important;
  }
  ${(props) => props.opacity && `
    .MuiTabs-indicator {
      display: flex!important;
      background-color: ${props.theme.tablePagiBorder};
    }
    button {
      background-color: transparent!important;
    }
  `}
  .MuiTabs-flexContainer {
    align-items: flex-end;
  }
`;

export const InputContainer = styled.div`
  padding: 20px 15px 15px 15px;
  width: 100%;
  background-color: ${(props) => props.theme.greyBackg};
  border-radius: 8px;
  box-shadow: 3px 3px 5px 1px ${hexToRgbA(Colors.black, 0.1)};
  .header {
    display: flex;
    align-content: center;
    margin-bottom: 30px;
    h4 {
      flex: 1;
      font-size: 18px;
      color: ${(props) => props.theme.text1};
      display: flex;
      align-items: center;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > button {
      text-transform: none;
      padding: 5px 10px;
    }
  }
  .left {
    /* border-right: 1px solid ${Colors.lightPurple300}; */
    .MuiFormControl-root {
      // margin-bottom: 20px;
    }
  }
  @media ${scrSize('sm', 'xs')} {
   box-shadow: unset;
  }
`;

export const AdminTabsPage = styled.div`
  width: 100%;
  background: ${(props) => props.theme.boxBackg};
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 2px rgb(53 63 102 / 6%);
  @media ${scrSize('sm', 'xs')} {
    background: none; 
    padding: 0;
    box-shadow: none;
  }
`;

export const AdminConfigurationTabs = styled(AdminTabsPage)`
  .container {
    padding: '20px 0px';
    border-bottom: '1px solid ${Colors.lightGrey600}';
  }
  h4 {
    font-size: 16;
    font-weight: 600;
    color: ${(props) => props.theme.text1};
  }
  ${InputContainer} {
    width: auto;
  }
  .inventoryLog {
    padding: 0 !important;
  }
  .inventoryLogDownload {
    font-weight: 600;
    font-size: 13px;
    color: ${Colors.blue};
    padding: 20px 0;
    cursor: pointer;
    transition: all 0.5s ease;
    :hover {
      color: ${hexToRgbA(Colors.blue, 0.8)};
    }
  }
  .inputFlexContainer {
    display: flex;
    align-items: center;
    padding-top: 20px;
    ${CustomButton} {
      margin: 0 10px;
    }
    .MuiFormControl-root {
      margin-right: 20px;
      flex: 0.5;
    }
  }
  .settingContainer > p {
    padding: 20px 0 20px 10px;
    color: ${(props) => props.theme.text1};
    font-weight: bold;
    font-size: 16px;
  }
  .themeWrapper {
    width: auto;
    padding: 20px 5px 15px 5px;
  }
  .tableWidget {
    padding: 20px 15px 15px 15px;
    background-color: ${(props) => props.theme.greyBackg};
    border-radius: 8px;
    box-shadow: 3px 3px 5px 1px ${hexToRgbA(Colors.black, 0.1)};
  }
  @media ${scrSize('xs', 'sm')} {
    .container {
      padding-bottom: 20px;
    }
    .inputFlexContainer {
    flex-direction: column;
    align-items: flex-end;
    ${CustomButton} {
      margin: 10px 0;
    }
    .MuiFormControl-root {
      margin: 5px 0;
      flex: 1;
    }
  }
  }
`;

export const AdminSurveyTabs = styled(AdminTabsPage)`
  .header {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 5px 0;
      color:${(props) => props.theme.text1}
    }
    .buttonGroup ${CustomButton} {
      margin: 0 3px;
      :first-child {
        margin-right: 10px;
      }
    }
    .search {
      position: relative;
      input {
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        padding: 0 25px;
        min-width: 350px;
      }
      svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }
    }
  }
  .cardList {
    margin-top: 20px;
  }
  @media ${scrSize('xs', 'sm')} {
    .search {
      position: absolute;
      z-index: 998;
      top: 70px;
      width: calc(100% - 30px);
      /* position: relative; */
      input {
        height: 40px;
        line-height: 50px;
        overflow: hidden;
        padding: 0 25px;
        /* min-width: 350px; */
      }
      svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }
    }
  }
`;

export const PeopleManagementTabPage = styled(AdminTabsPage)`  

`;

export const CustomMenu = styled(Menu)`
  .MuiMenu-paper {
    padding-top: 0px;
    box-shadow: unset;
    margin-top: 5px;
    border: 1px solid #F0F0F0;
    background-color: unset;
    > ul {
      background: ${(props) => props.theme.boxBackg};
      border-radius: 10px;
      filter: unset;
      ${(props) => !props.notArrow && `
      :before {
        content: '';
        position: absolute;
        top: -20px;
        left: calc(50% - 10px);
        border-bottom: 10px solid ${props.theme.boxBackg};
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
      }
      `}
      li,label{
        color: ${(props) => props.theme.text2};
        svg {
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }
      }
    }
  }
`;

export const LifecycleWrapper = styled(Widget)`
  /* max-height: 60vh; */
  // height: calc(100vh - 110px);
  max-width: 25vw;
  max-height: none;
  padding: 20px;
  .icon {
    right: 15px;
    top: 15px;
  }
  .day,.item,.itemGroup { 
    position: relative;
  }
  .content {
    padding: 0;
    display: flex;
    flex-direction: column;
    > p {
      padding: 15px 0 30px 15px;
      color: ${(props) => props.theme.text1}; 
    }
    > div {
    padding: 0 15px;
    }
  .container {
    height: -webkit-fill-available;
    overflow-y: overlay;
    .column {
      margin: 10px 0px;
      align-items: start;
      display: flex;
      .date {
        display: flex;
        height: 40px;
        align-items: center;
        padding-left: 15px;
        text-align: center;
        > p {
          color: ${(props) => props.theme.text1};
          white-space: nowrap;
          }
        }
      .dateDetail {
        position: relative;
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
        ::before {
          content: "";
          height: 100%;
          width: 2px;
          left: 19px;
          background: ${Colors.lightGrey800};
          position: absolute;
          }
          .dateCardArea {
            display: flex;
            align-items: center;
            > p {
              font-size: 12px;
              font-weight: 200;
              color: ${(props) => props.theme.text2};
              padding-left: 5px;
            }
            .day {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: ${Colors.blue};
              width: 40px;
              height: 40px;
              color: ${Colors.white};
            }
            .dateSince {
              margin-left: auto;
            }
          }
          .item {
            padding: 10px 0 10px 12px;
            font-size: 12px;
            border: none !important;
            display: flex;
            align-items: center;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 3px;
              border-radius: 50%;
              margin-right: 10px;
              border-top: 5px solid ${(props) => props.theme.boxBackg};
              border-bottom: 5px solid ${(props) => props.theme.boxBackg};
            }
            > div {
              color: ${(props) => props.theme.text1} !important;
              height: auto;
            }
          }
      /* .itemGroup {
            padding: 10px 0px;
            margin-left: 35px;
            span {
              padding: 0px 5px;
            }
            .itemBox {
              height: 30px;
              font-size: 12px;
              border: 1px solid ${Colors.lightGrey400};
              color: ${(props) => props.theme.text1};
              border-radius: 10px;
              overflow: hidden;
              display: inline-flex;
              align-items: center;
              .itemIcon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 35px;
              background: ${Colors.lightGrey400};
              }
            }
            > p {
              color: ${(props) => props.theme.text1};
              font-weight: bold;
              padding: 5px 0px;
            }
          } */
      }
    } 
  }
  .footer {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -17px 21px rgba(53, 63, 102, 0.06);
    background: ${(props) => props.theme.boxBackg};
  }
}
  @media ${scrSize('xs', 'sm')} {
    height: -webkit-fill-available;
  }
`;
export const NotificationContainer = styled.div`
  // background: ${(props) => props.theme.boxBackg};
  // border-radius: 8px;
  // box-shadow: 3px 3px 5px 1px ${hexToRgbA(Colors.black, 0.1)};
  padding-bottom: 20px;
  margin-top: 10px;
  overflow-y: overlay;
    .timeSeperatorContainer {
      flex-direction: column;
      .timeSeperator {
        padding-top: 25px;
        display: flex;
        position: relative;
        span {
        background: ${(props) => props.theme.blueBackg};
        color: ${(props) => props.theme.blueText};
        font-size: 14px;
        margin: 5px auto;
        padding: 5px 10px;
        border-radius: 10px;
        }
        ::after {
          left: 0;
        }
        ::before {
          right: 0;
        }
        ::after,::before {
        content: "";
        width: 40%; 
        top: 50%;
        // height: 1px;
        background: ${Colors.lightGrey400};
        position: absolute;
        z-index: 0;
        }
      }
    .item {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      background: transparent;
      border-bottom: 1px solid ${Colors.lightGrey700};
      padding: 20px 20px;
      margin: 20px 0px;
      button {
        margin-top: 15px;
        height: fit-content !important;
        background-color: transparent;
        padding: 0px;
        align-items: flex;
        left: 0px;
        :hover {
          color: ${Colors.darkBlue400};
        }
      }
      > div {
        display: flex;
        align-items: center;
      }
      &:hover {
        background-color: ${(props) => props.theme.lightPurple300};
      }
      .active {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 10px;
        opacity: 0.1;
      }
      .itemWrapper {
        flex: 1;
        .type {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          svg {
            width: 15px;
            height: 15px;
          }
        }
        .profile {
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${Colors.white};
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin: 0px 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          flex: 1;
          font-size: 14px;
          color: ${(props) => props.theme.text3};
          z-index: 99;
          span { 
            :hover {
            color: ${Colors.darkBlue400};}
          }
          p:first-child {
            font-size: 15px;
            color: ${(props) => props.theme.text2};
            padding-right: 14px;
          }
        }
      }
    }
  }
  @media ${scrSize('xs', 'sm')} {
    .timeSeperatorContainer {
      .timeSeperator {
        :after, :before {
        width: 30%;
        }
      }
      .item  a {
        display: none;
      }
    }
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .headerArea {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.white};
    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
  }
  .fullname, .lastTime {
    padding: 0px 10px;
    color: ${(props) => props.theme.text2};
  }
  .fullname {
    color: ${(props) => props.theme.text2};
    font-weight: 500;
    font-size: 16px;
  }
  .lastTime {
    color: ${(props) => props.theme.text3};
    font-size: 14px;
  }
  .title {
    display: inline;
    padding: 5px 10px;
    background: ${(props) => props.theme.blueBackg};
    border-radius: 10px;
    font-size: 14px;
    text-transform: capitalize;
    color: ${(props) => props.theme.blueText};
  }
  .contentArea {
    color: ${(props) => props.theme.text1};
    padding-bottom: 15px;
    border-bottom: 1px solid ${Colors.lightPurple300};
  }
  .commentForm {
    padding: 45px 0 15px 0;
  }
  .buttonWrapper {
    display: flex;
    padding-top: 15px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  @media ${scrSize('xs', 'sm')} {
    .commentForm {
      box-sizing: border-box;
      margin: 0 auto;
      width: calc(100% - 5px);
    }
  }
`;

export const MentionBox = styled.div`
  display: flex;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  min-width: 220px;
  * {
    text-transform: capitalize;
    font-weight: 500;
  }
  img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    margin-right: 10px;
  }
  span {
    font-size: 10px;
    line-height: 12px;
    color: ${(props) => props.theme.text2};
    display: block;
  }
  strong {
    font-size: 13px;
    line-height: 15px;
    color: ${(props) => props.theme.text1};
  }
  p {
    font-size: 10px;
    line-height: 12px;
    color: ${(props) => props.theme.text2};
  }
`;

export const ComponentLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${hexToRgbA(Colors.black, 0.02)};
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: blue;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${hexToRgbA(Colors.black, 0.2)};
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: blue;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  img {
    max-width: 200px;
  }
  p {
    padding: 15px 0px;
    color: ${(props) => props.theme.text1};
  }
  @media ${scrSize('xs', 'sm')} {
    p {
      padding: 40px 0px;
    }
  }
`;

export const DashboardCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 1s ease;
  position: relative;
  width: 100%;
  .header {
    color: ${(props) => props.theme.text1};
    font-size: 20px;
    font-weight: 600;
    height: 75px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 25px;
  }
  .content {
    height: 100%;
    overflow: auto;
    padding: 0px 5px;
    margin-top: 20px;
    .card {
      display: flex;
      overflow: hidden;
      height: 70px;
      position:relative;
      padding-left: 20px;
      // padding: 0 0px;
      justify-content: space-between;
      // width: 100%;
      .dot {
        left: 10px;
        background: ${(props) => props.theme.boxBackg};
        border-radius: 50%;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        position: absolute;
        :after {
          content: "";
          position: absolute;
          width: 3px;
          height: 125px;
          top: 12px;
          background: ${Colors.lightGrey400}; 
          transform: translateX(100%);
        }
      }
      .container {
        width: 50%;
        padding-left: 50px;
        h3, p {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .title {
          font-weight: 700;
          font-size: 13px;
          color:${(props) => props.theme.text1};
          padding: 0px;
        }
        .desc {
          font-weight: 400;
          font-size: 12px;
          margin: 10px 0 15px 0;
          color:${(props) => props.theme.text2};
        }
      }
      .date {
        display: flex;
        padding-right: 30px;
        p {
          color: ${(props) => props.theme.notificationTimeText};
          background: ${(props) => props.theme.notificationTimeBackg};
          border-radius: 10px;
          padding: 5px;
          font-size: 14px;
          height: min-content;
        }
      }
    }
  }
  @media ${scrSize('xs', 'sm')} {
    .content {
      height: 50%;
      padding: 0;
      overflow-x: hidden;
    }
    .card {
      width: -webkit-fill-available;
      box-sizing: border-box;
    }
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  z-index: 999;
  background: white;
  width: 100%;
  position: absolute;
  width: -webkit-fill-available;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 3px 8px -1px rgba(53, 63, 102, 0.05);
  background-color: ${(props) => props.theme.mainBackg};
  ${CustomButton} {
      margin-right: 15px;
      padding: 0 10px !important;
    }
  .left,.right {
    display: flex;
    align-items: center;
    h1 {
      font-size: 16px;
      color: ${(props) => props.theme.text1};
    }
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.text1};
    }
  }
  .notifications {
        cursor: pointer;
        margin: 0 15px 0 auto;
        position: relative;
        > span {
          position: absolute;
          position: absolute;
          right: -20px;
          top: -20px;
          background: ${Colors.blue};
          color: ${(props) => props.theme.text4};
          font-size: 12px;
          width: 25px;
          height: 25px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid ${(props) => props.theme.mainBackg};
          transition: all .5s ease-in-out;
          transform-origin: 50% 50%;        
        }
      }
`;

export const MobileDrawerMenu = styled.div`
  padding: 25px; 
  background-color: ${(props) => props.theme.mainBackg};
  ${CustomButton}{
    padding: 0 0 0 3px;
    color: ${(props) => props.theme.text2};
    svg {
      color: ${(props) => props.theme.text2};
    }
    :hover {
      color: ${Colors.white};
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 25px;
    img {
      width: 80px;
      height: 80px;
      border: 3px solid ${Colors.white};
      border-radius: 20px;
      object-fit: cover;
    }
    > p {
      font-size:14px;
      font-weight: 500;
    }
    .userName {
      color: ${(props) => props.theme.text1};
      padding: 15px 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    border-top: 1.5px solid ${(props) => props.theme.border};
    border-bottom: 1.5px solid ${(props) => props.theme.border};
    width: 200px;
  }
  .footer {
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .MuiSwitch-track {
      border-radius: 25px !important;
      background: ${Colors.lightGrey400};
      border: 1px solid ${Colors.lightGrey200};
    }
    .MuiSwitch-root {
      height: 30px !important;
      width: 55px !important;
      box-sizing: content-box;
      padding: 9px 10px;
    }
    .MuiIconButton-label{
      background: ${Colors.blue};
      border-radius: 50%;
      padding: 5px;
      box-sizing: border-box;
      width: 25px;
      height: 25px;
    }
    .MuiIconButton-root {
      padding: 12px 15px !important;
    }
    .Mui-checked {
      color: none !important;
    }
  }
`;
export const MobileMenuItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 10px;
  border-radius:15px;
  color: ${(props) => (props.disabled ? Colors.lightGrey100 : props.active ? props.theme.text1 : props.theme.text2)};
  :hover {
    background-color: ${hexToRgbA(Colors.blue, 0.05)}
  }
  p {
    padding: 0 20px;
    font-size: 14px;
  }
  svg {
    width: 22px;
  }
`;
export const SwitchWrapper = styled.div`
  .MuiFormControlLabel-labelPlacementStart {
    margin-right: 0px;
    margin-left: 0px;
  }
  .MuiFormControl-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DashboardInfoCardContainer = styled(Grid)`
  .dashboardInfoCard {
    z-index: 99;
  }
  .infoCardAddButton {
    color: ${(props) => props.theme.text1};
    text-transform: capitalize;
  }

  @media ${scrSize('xs', 'sm')} {
    overflow-y: hidden;
    flex-flow: nowrap;
    height: fit-content;
    padding: 0;
    .dashboardInfoCard {
      padding-bottom: 0;
      height: fit-content;
    }
    ${Widget} {
        width: 45vw;
      }
    ::-webkit-scrollbar {
        display: none;
    }
  }
`;

export const Placeholder = styled.div`
  height: ${(props) => `${props.height || 0.1}px `};
  width: ${(props) => `${props.widht || 0.1}px `};
`;

export const BottomNavigationMobile = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -15.0168px 25.0281px rgb(0 0 0 / 5%);
  z-index: 9999;
  background: ${(props) => props.theme.boxBackg};
  ${(props) => `
  .MuiBottomNavigationAction-root:nth-child(${props.tabIndex + 1}) {
    background: ${props.theme.blueBackg}!important;
  }
  `}
`;

export const CustomBottomNavigationAction = styled(BottomNavigationAction)`
.MuiBottomNavigationAction-wrapper{
  path{
    fill: ${(props) => (props.active ? props.theme.blueText : props.theme.text2)} !important;
  }
}
.MuiBottomNavigationAction-label{
  font-size: 10px;
  padding-top: 5px;
  color: ${(props) => (props.active ? props.theme.blueText : props.theme.text1)};
}
`;

export const IntegrationDetail = styled.div`
  transition: all 1s ease;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  padding-top: ${(props) => (props.active ? 0 : '50px')};
  color: ${(props) => props.theme.text1};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  table {
    tr {
      td {
        padding: 8px 15px;
        :first-of-type {
          font-weight: 500;
        }
        ${StarRating} {
          padding: 2px 0;
        }
      }
    }
  }
  .MuiInputBase-root {
    color: ${(props) => props.theme.text2};
  }
  .MuiInput-underline:before , .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.text3};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before , .MuiOutlinedInput-notchedOutline:hover {
    border-color: ${(props) => props.theme.border};
  }
  .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.border};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.border} !important;
  }
`;

export const ScatterPlotWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: 15%;
    left: calc(50% - 27px);
    height: 66%;
    width: 2px;
    background: ${Colors.lightGrey600};
  }
  :after {
    content: "";
    position: absolute;
    left: 10%;
    top: calc(50% + 0.5px);
    width: 75%;
    height: 2px;
    background: ${Colors.lightGrey600};
  }
  svg {
    > g {
      > g:last-of-type {
        display: none;
      }
    }
    > g {
      > g:nth-child(1), > g:nth-child(2) {
        g:last-of-type {
          display: none;
        }
      }
    }
  }
  .bgText {
    position: absolute;
    opacity: 1;
    color: ${(props) => props.theme.text3};
    font-size: 16px;
  }
  .bgText:nth-child(1) {
    left: 22%;
    top: 22%;
  }
  .bgText:nth-child(2) {
    left: 62%;
    top: 22%;
  }
  .bgText:nth-child(3) {
    left: 24%;
    top: 66%;
  }
  .bgText:nth-child(4) {
    left: 62%;
    top: 66%;
  }
  
  @media ${scrSize('xs', 'sm')} {
    :before {
      left: calc(50% - 17px);
    }

  }
`;

export const CustomDivider = styled(Divider)`
  background: ${(props) => props.theme.border};
`;

export const ArchiveAppModal = styled.div`
  .iconTitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    .iconWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.notificationTimeBackg};
      }
    }
    .titleWrap {
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      padding-left: 25px;
      p {
        font-weight: 600;
        font-size: 20px;
        color: ${(props) => props.theme.text1};
      }
      .childTitle {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media ${scrSize('xs')} {
    .iconTitleWrapper {
      margin-bottom: 30px;
      .titleWrap .childTitle{
        margin-top: 0;
      }
    }
    ${InputWrapper} {
      padding: 0px
    }
  }
`;
export const CustomDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    display: none !important;
    margin:0 !important;
  }
`;

export const ChartCustomTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px 30px;
  background-color: ${(props) => (props.bar ? Colors.white : Colors.lightBlue100)};
  ${(props) => (props.bar ? (
    `
      p:first-child {
        color: ${Colors.lightGrey100};
        font-weight: 600;
      }
      p:last-child {
        color: ${Colors.grey};
        margin-top: 5px;
      }
      h4 {
        color: ${Colors.lightPurple};
        margin-top: 10px;
      }
    `
  ) : (
    `
      color: ${Colors.white};
      p:first-child {
        font-weight: 600;
      }
      p:last-child {
        margin-top: 5px;
      }
      h4 {
        margin-top: 10px;
      }
    `
  ))};
`;

export const SurveyListPageWrapper = styled.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .buttonGroup {
      button + button {
        margin-left: 10px;
      }
    }
  }
`;

export const SurveyParagraphRep = styled.div`
  margin: 20px 0px;
  padding: 20px 30px;
  border-radius: 5px;
  background: ${(props) => props.theme.inputBackg};
  :hover {
    background: ${hexToRgbA(Colors.lightPurple300, 0.5)};
  }
  border: 1px solid ${(props) => props.theme.border};
  cursor: pointer;
  > p {
    color: ${(props) => props.theme.text3};
  }
`;

export const SurveyParagraphTooltip = styled.div`
  padding: 10px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      background-color: ${Colors.grey};
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: 12px;
        color: ${Colors.lightGrey300};
        margin: 5px 0px;
      }
      h4 {
        font-size: 15px;
        color: ${Colors.darkBlue};
        margin: 5px 0px;
      }
    }
  }
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 500px;
  height: 600px;
  top: calc(50% - 300px);
  left: calc(50% - 250px);
  h1 {
    font-size: 18px;
    color: ${Colors.darkBlackBlue};
    margin-top: 50px;
    text-align: center;
  }
  p {
    font-size: 15px;
    color: ${Colors.grey};
    margin-top: 20px;
    text-align: center;
  }
  button {
    margin-top: 20px;
  }
  @media (max-width: 450px) {
    width: 250px;
    height: 400px;
    top: calc(50% - 200px);
    left: calc(50% - 125px);
    h1 {
      font-size: 15px;
    }
    p {
      font-size: 12px;
    }
    svg {
      width: 250px;
      height: 200px;
    }
  }
`;

export const CustomMenuList = styled(MenuList)`
  background: ${(props) => props.theme.boxBackg};
  color: ${(props) => props.theme.text1};
`;

export const FieldContainer = styled.div`
  border-radius: 10px;
  & + & {
    margin-top: 20px;
  }
  background-color: ${(props) => (props.light ? Colors.white : 'rgba(193, 209, 226, 0.15)')};
  border: 1px solid ${Colors.lightGrey400};
  padding: 20px;
  > div:last-child {
    margin-bottom: 0px;
  }
  .multipleInputWrapper {
    display: flex;
    align-items: center;
    div + div {
      margin-left: 15px;
    }
  }
  .allocationHeader {
    display: flex;
    align-items: center;
    .chartWrapper {
      width: 150px;
      height: 150px;
    }
    .actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 40px;
    }
  }
  .allocationTableWrapper {
    .MuiTableHead-root {
      position: sticky;
      top: 0;
      background: rgba(193,209,226,1);
      z-index: 3;
    }
    display: flex;
    max-height: 250px;
    overflow: auto;
    input {
      padding: 5px 10px;
    }
    .MuiTableCell-root {
      padding: 5px;
      white-space: nowrap;
    }
    p {
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @media ${scrSize('sm', 'xs')} {
    .multipleInputWrapper {
      display: unset;
      div + div {
        margin-left: 0px;
      }
    }
    .allocationHeader {
      flex-direction: column;
      .actions {
        margin-left: 0px;
        > button {
          margin-bottom: 40px;
        }
      }
    }
  }
`;

export const HardwareTypeCard = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  background: ${(props) => props.theme.hardwareTypeCardBackg};
  color: ${(props) => props.theme.text1};
  cursor: pointer;
  border-radius: 15px;
  padding: 20px 10px 0 10px !important;
  box-shadow: 2px 2px 6px 0.1px ${(props) => props.theme.shadow};
  transition: all .4s;
  height: 180px;
  width: 90%;
  margin: 10px;
  p {
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    width: inherit;
    height: 55px;
  }
  .cardIcon {
    height: 80px;
    width: 80px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  :hover {
    background: ${(props) => props.theme.surveyCardHover};
  }

  @media ${scrSize('xs')}{
    background: ${(props) => props.theme.boxBackg};
    margin: 0 5px;
  }  
`;

export const HardwareDetailBottomSheet = styled.div`
display: ${(prosp) => (prosp.show ? 'flex' : 'none')};
background-color: ${(props) => props.theme.boxBackg};
z-index: 98;
position: fixed;
bottom: 0;
left: 80px;
right: 0;
padding: 20px 120px !important;
box-shadow: 0px 4px 33px rgba(53, 63, 102, 0.1);
.buttonGroup {
  display: flex;
  button {
    margin-left: 10px;
  }
}
@media ${scrSize('md', 'xs')} {
  left: 0;
  padding: 10px 20px!important;
  justify-content: flex-end;
} 
// @media screen and (max-height: 720px) {
//   left:80px;
// }
`;

export const HardwareRegisterContainerSlider = styled.div`
  overflow: hidden;
  width: 100%;
  .sliderContent {
    display: flex;
    flex-direction: row;
    overflow-y: overlay;
    ${(props) => props.notAnimatedSlider && 'transition: all .8s ease-in-out;'} 
    ${(props) => (props.children.props.children
    && `width : ${100 * props.children.props.children.length}%;
        transform: translateX(calc(${props.sliderStepIndex ? ((-100 / (props.children.props.children.length)) * (Math.abs(props.sliderStepIndex) % props.children.props.children.length)) : 0}%)); 
          `)}
    > div {
      width: ${(props) => (props.children.props.children.length > 1 ? 'calc(70% - 30px)' : '100%')};
      transition: all 1s ease-in-out;
      height: auto;
      margin-bottom: 100px;
    }  
    > div:not(:nth-child(${(props) => props.sliderStepIndex + 1})){
      height: 100vh !important;
      overflow: hidden;
    }
  }
`;

export const HardwarePagesUnitArea = styled.div`
// position: relative;
  padding-top: ${(props) => (props.isHeadline ? `${(props.isMobile ? 15 : 70)}px` : 'unset')};
  // &:after {
  //   content: "";
  //   height: 10px;
  //   width: 10px;
  //   background: red;
  //   position:absolute;
  //   top:0;
  // }
  h4 {
    color: ${(props) => (props.isTitle ? props.theme.blueText : props.theme.text1)};
    font-size: ${(props) => (props.isTitle ? 14 : 13)}px;
  }
  p.desc {
    padding-top: 5px;
    font-size: 12px;
    color: ${(props) => props.theme.text2}
  }
  .detailButtonMenu, .inputGroupHeader {
    height: ${(props) => (props.descPadding ? '75px' : '30px')}
  }
  .detailButtonMenu {
    display: flex;
    width: 100%;
    justify-content: end;
    ${CustomButton} {
      padding: 0 15px;
    }
    ${CustomButton} + ${CustomButton}{
      margin-left: 10px;
    }
  }
  .inputGroupHeader {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .addButtonGruop {
    margin-bottom: 15px;
  }
  
  @media ${scrSize('sm', 'xs')} 
  {
    h4 {
      // padding-bottom: ${(props) => (props.smallTitlePadding ? 15 : 40)}px;
    }
    .inputArrayWrapper {
      > .MuiFormControl-root:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export const InventoryBottomSheet = styled.div`
  background-color: ${(props) => props.theme.boxBackg};
  z-index: 98;
  position: fixed;
  bottom: 0;
  display: flex;
  right: 0;
  left: 80px;
  padding: 10px 20px!important;
  justify-content: flex-end;
  box-shadow: 0px 4px 33px rgba(53, 63, 102, 0.1);
  @media ${scrSize('xs', 'md')} {
  left: 0px;
  }
`;

export const BottomSheetWrapper = styled.div`
  width: calc(100% - 30px);
  margin: 0 auto;
  overflow: overlay;
`;

export const HeaderHardwareSelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-center: center;
  color: ${(props) => props.theme.blueText};
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    opacity: .8;
  }
  @media ${scrSize('xs', 'sm')}
  {
    font-size: 16px;
  }
`;

export const HardwareInputWrapper = styled(InputWrapper)`
  
  // background: ${(props) => props.theme.boxBackg};
  position: relative;
  height: fit-content;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 96%;
  //   height: 1px;
  //   background: ${(props) => props.theme.border};
  //   margin-top: 15px;
  // }
  div.checkboxDiv{
    display: flex !important;
    height: 75px;
    box-sizing: border-box;
    align-items: end;
  }
  ${(props) => props.hardwareDetail && `
  &:last-child {
    margin-bottom: 100px;
  }
  padding: 15px 25px !important;
  `}
  @media ${scrSize('xs', 'sm')} {
    padding: 5px 5px 0 5px;;
  }
`;

export const HardwareAddModal = styled.div`
  .seperator {
    display: flex;
    justify-content: right;
    border-top: 1px solid ${(props) => props.theme.text3};
    padding: 10px 0 30px 0px;
    margin: 0 auto;
    svg {
      cursor: pointer;
    }
    :first-child {
      display: none;
    }
  }
  .bottomModal {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }

`;

export const HardwareAddedGroup = styled.div`
  .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    height: 30px;
    h4 {
      color: ${(props) => (props.isTitle ? props.theme.blueText : props.theme.text1)};
      font-size: ${(props) => (props.isTitle ? 14 : 13)}px;
      // padding: 0px 0 26px 0;
    }
  }
  .MuiPaper-root {
    background: none !important;
  }
`;

export const HardwareAddedAccordion = styled(Accordion)`
  box-shadow: none !important;
  .MuiAccordionSummary-root{
    border-radius: 5px;
    background: ${(props) => props.theme.greyBackg} ;
    box-shadow: -2px 4px 4px ${(props) => props.theme.shadow35};
    min-height: 48px !important;
  }
  &::before {
    content: unset !important;
  }
`;

export const HardwareAddedAccordionSummary = styled(AccordionSummary)`
  margin-bottom: 15px;
  ${(props) => props.errorValidate && `
  border: 1.5px solid ${props.theme.notificationTimeText};
  color: ${props.theme.notificationTimeText};
  `}
  background: ${(props) => `${props.focusItem ? props.theme.purpleBackg : hexToRgbA(props.theme.purpleBackg, 0.5)}!important;`}
  & + & {
    margin-top: 15px !important;
  }
  .MuiAccordionSummary-content,MuiAccordionSummary-root {
    margin: 0 !important;
  }
  .flexGrid {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    p.accordionTitle {
      font-size: 14px;
      font-weight: 500;
    }
    .accordionIconButton {
      &:hover {
        opacity: 1 !important;
        svg {
          path {
            fill: ${(props) => props.theme.blueText} !important;
            opacity: 1 !important;
          }
      }
    }
      svg {
        path {
          fill: ${(props) => props.theme.blueText} !important;
          opacity: 0.7 !important;
        }
      }
    }
  }
  &:last-child {
    margin: unset !important;
  }
  svg {
    path {
      fill: ${(props) => props.theme.blueText} !important;
    }
  }
`;

export const HardwareAddedAccordionDetails = styled(AccordionDetails)`
  padding: 0 0 15px 0 !important; 
  display: block;
  ${InputWrapper} {
    padding: unset !important;
  }
  .MuiPaper-root {
    background: unset !important;
  }
`;

export const AllocationPieTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding: 5px 15px;
  background-color: ${Colors.lightBlue100};
  color: ${Colors.white};
  h4 {
    font-size: 10px;
    font-weight: 800;
  }
  p {
    font-size: 10px;
  }
`;

export const CostingSidebar = styled.div`
  position: fixed;
  top: 0px;
  flex: 1 1 0%;
  height: 100%;
  overflow: auto;
  width: 150px;
  left: 90px;
  outline: 0px;
  z-index: 1200;
  padding: 0px 20px;
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: #D0D6DE;
  > div {
    padding-top: 40px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: ${(props) => props.theme.text1};
  }
  .list {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    margin-top: 20px;
    h4 {
     color: ${Colors.blue};
     font-size: 16px;
     font-weight: 600;
    }
    .item {
      .Mui-checked {
        color: black;
      }
      margin-top: 10px;
      display: flex;
      align-items: center;
      > span {
        padding: 5px;

      }
      p {
        font-size: 14px;
        color: ${(props) => props.theme.text1};
      }
    }
  }
`;

export const CustomRadio = styled(Radio)`
  color: ${(props) => (props.checked ? Colors.blue : Colors.grey)}!important;
`;

export const HardwareAddRowGridComp = styled(Grid)`
    // padding: 0 8px;
    .header {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: unset;
    h4 {
      color: ${(props) => (props.isTitle ? props.theme.blueText : props.theme.text1)};
      font-size: ${(props) => (props.isTitle ? 14 : 13)}px;
    }
  }
  .footer {
    margin-top: 5px;
  }
  & + & {
    border-top: 1px solid ${(props) => props.theme.border};
    margin-top: 15px;
  }
`;

export const PhotoBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  .cropWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .emptyBox {
    width: 100%;
    height: 150px;
    border: 2px dotted ${Colors.lightGrey200};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      path {
        fill: rgb(193, 209, 226);
      }
    }
    p {
      font-size: 18px;
      opacity: 0.5;
      color: ${(props) => props.theme.text2};
    }
  }
`;

export const GridPageWrapper = styled.div`
  .gridRowWithoutAfter {
    .rowLeft {
      padding-right: 50px;
    }
    .rowRight {
      padding-left: 50px;
    }
    .rowWithoutInfo {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  .gridRowDashed {
    :after {
      content: "";
      width: calc(100% - 60px);
      height: 1px;
      margin: 30px 0px 50px 12px;
      border-top: 1px dashed ${(props) => props.theme.inputBorder};
    }
    .rowLeft {
      padding-right: 50px;
    }
    .rowRight {
      padding-left: 50px;
    }
  }
  .gridRow {
    .rowLeft {
      padding-right: 50px;
    }
    .rowRight {
      padding-left: 50px;
    }
    .rowWithoutInfo {
      padding-left: 50px;
      padding-right: 50px;
    }
    :after {
      content: "";
      width: calc(100% - 60px);
      height: 1px;
      margin: 30px 0px 50px 12px;
      background-color: ${(props) => props.theme.inputBorder};
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    .headerGrid {
      width: calc(100% - 40px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left {
      }
      .right {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        button + button {
          margin-left: 15px;
        }
      }
      h4 {
        color: ${(props) => props.theme.text1};
        font-size: 14px;
        font-weight: 600;
      }
      p {
        color: ${(props) => props.theme.text2};
        font-size: 13px;
        margin: 15px 0px;
      }
      height: 80px;
    }
    .documentList {
      overflow: auto;
      max-height: 300px;
      a {
        padding: 0;
      }
      thead {
        position: sticky;
        z-index: 4;
        top: 0;
        background: white;
      }
      .iconCell {
        svg {
          path {
            fill: ${(props) => props.theme.lightGrey200};
          }
        }
      }
    }
    .allocationTable {
      .MuiTableHead-root {
        position: sticky;
        top: 0;
        background: rgba(193,209,226,1);
        z-index: 3;
      }
      .totalRow {
        td {
          border-top: 2px solid blue;
          font-weight: 600;
          color: blue;
        }
      }
      .varianceRow {
        td {
          font-weight: 600;
          color: red;
        }
      }
      display: flex;
      width: 100%;
      height: fit-content;
      overflow: auto;
      .MuiFormControl-root {
        margin: 5px 0px;
      }
      input {
        height: 20px!important;
        font-weight: 600;
        color: blue;
        padding: 5px 10px;
        margin: 5px 0px;
      }
      .MuiTableCell-root {
        padding: 5px;
        white-space: nowrap;
        font-size: 15px;
        border-bottom: none;
      }
      p {
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .chartWrapper {
      max-width: 70%;
      width: 70%;
      aspect-ratio: 1;
      margin: auto;
    }
    .costDetailBox {
      background-color: ${(props) => props.theme.tableHoverTr};
      border-radius: 10px;
      padding: 20px;
      .costList {
        display: flex;
        flex-direction: column;
        > div + div {
          margin-top: 10px;
        }
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            color: ${Colors.purple};
            font-weight: 500;
            font-size: 15px;
          }
        }
        .item:last-child {
          margin: 0px;
          p {
            font-weight: 600;
            color: ${Colors.blue};
          }
        }
        .line {
          margin: 20px 0px;
          height: 3px;
          background-color: ${Colors.lightBlue100};
        }
      }
    }
    .inputList {
      .info {
        display: flex;
      }
      .multipleInputWithChecbox {
        display: flex;
        > div:not(:first-child) {
          width: min-content;
        }
      }
      .multipleInputWrapper {
        display: flex;
        aling-items: center;
        div + div {
          // margin-left: 15px;
        }
      }
      @media ${scrSize('sm', 'xs')} {
        .multipleInputWrapper {
          display: unset;
          div + div {
            margin-left: 0px;
          }
        }
        .allocationHeader {
          flex-direction: column;
          .actions {
            margin-left: 0px;
            > button {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
    .costTable {
      td {
        text-align: start;
        padding: 10px;
        :last-child {
          color: ${(props) => props.theme.blueText};
        }
      }
      th {
        text-align: start;
        padding: 10px;
        :last-child {
          color: ${(props) => props.theme.blueText};
        }
      }
      .coloredCells {
        td {
          color: ${(props) => props.theme.blueText};
        }
      }
    }
  }
`;

export const WithIconCell = styled.p`
  display: flex;
  align-items: center;
  color: ${(props) => props.color || Colors.text1};
  svg {
    margin-right: 3px;
  }
`;

export const IntegrationEdgeBundlingScalesItem = styled.p`
  font-size: 11px;
  font-weight: 500;
  margin: 4px 0;
  &::before {
    content: "";
    padding: 0px 30px;
    background: ${(props) => props.color || 'black'};
    margin-right: 3px;
  }
`;

export const IntegrationEdgeBundlingScalesGrid = styled.div`
  position: absolute;
  bottom: 0;
  left: 12px;
  `;
export const LifeCycleWrapper = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  .statusWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    .statusBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 15px;
      p {
        margin-top: 5px;
        font-size: 13px;
      }
    }
  }
`;

export const CustomLifeCycleTooltip = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > p {
    color: #667583;
    margin-top: 10px;
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p + p {
      margin-left: 15px;
    }
    p {
      color: #667583;
      font-weight: 700;
    }
  }
`;

export const DrawerButton = styled(Button)`
  ${(props) => props.active && `
    background-color: rgba(0,0,0,0.05);
  `}
  opacity: ${(props) => (!props.disabled ? '1' : '0.2')};
`;

export const CostingDashCardGrid = styled.div`
  display: flex;
  justify-content: space-around;
  .costingCard {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .costingIcon{
      max-width: 25%;
    }
    .costingTitle {
      padding-top: 15px;
      font-size: 14px;
      color: ${(props) => props.theme.text3};
      font-weight: 500;
    }
    .costingPrice{
      font-size: 16px;
      font-weight: bold;
      color: ${(props) => props.theme.text2};
      padding-top: 5px;
    }
    .costingTotalPrice {
      font-size: 12px;
      font-weight: 500;
      padding-top: 5px;
      color: ${(props) => props.theme.text3};
    }
  }

  @media ${scrSize('xs')} {
   flex-direction: column;
   .costingCard {
    padding: 10px 0;
   }
  }
  
`;

export const WorldMapSvg = styled.svg`
  path {
    fill: ${Colors.grey};
  } 

  ${(props) => props.country.map((item) => css`
  path[country=${item}] {
      fill: #2144D4;
  };
  `)
}

`;

export const DashboardMapCountryList = styled.div`
  background: #FAFAFA;
  background: ${(props) => props.theme.greyBackg};
  border-radius: 15px;
  padding: 15px;
  margin-top: 15px;
  max-height: 300px;
  overflow-y: auto;
  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 0;
    .MuiLinearProgress-root {
      height: 2px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 5px;
      p {
        color: ${(props) => props.theme.text1};
        flex:1;
      }
      .name{
        font-weight: 500;
      }
      .total{
        flex:2;
      }
      .percent{
        text-align: end;
      }
    }
  }
`;

export const ProfilSurveyCard = styled.div`
  display: flex;
  border-radius: 15px;
  background: ${Colors.lightGrey800};
  padding: 15px;
  margin: 10px 0;

  .survey-card_left {
    flex-basis: 40%;
    overflow: hidden;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p:first-child  {
      font-size: 16px;
      color:${Colors.darkBlue};
      margin-bottom: 5px;
    }
    p:last-child {
      font-size: 14px;
      color: ${Colors.lightGrey200};
    }
  }
  .progressArea {
    display: flex;
    flex-basis: 60%;
    font-size: 14px;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;
    .proggresInfo {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

    }
    .progressBar {
      border-radius: 15px;
      height: 5px;
      div {
        background-color:  ${Colors.lightBlue100};
      }
    }
  }
`;

export const ProfilPageGrid = styled(Grid)`
  padding: 0 30px;
  .surveyBG {
    background: url('${loginBG}') ,#ffffffab  !important;
    background-position-x: 85px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    & > div {
      max-height: 20vh;
      overflow: auto;
    }
  }

  .widgetGrid {
    background: ${(props) => props.theme.boxBackg};
    border-radius: 15px;
    padding: 20px;
    align-items: center;

    h4 {
      /* flex: 1; */
      font-size: 18px;
      color: ${(props) => props.theme.text1};
      /* display: flex; */
      /* align-items: center; */
      margin-bottom: 15px;
    }
  }

  ${LifecycleWrapper} {
    border-radius: 0 0 15px 15px;
    max-height: 385px;
  }

  @media ${scrSize('xs', 'sm')} {
    padding: 0 10px;
    margin: 0 auto;
    .surveyBG {
      background-position-x: 50px !important;
    }
    .widgetGrid {
     padding: 10px;
    }
 }
`;

export const LicenseFollowUserList = styled.div`
  background: ${(p) => p.theme.boxBackg};
  padding: 20px 30px;
  border-radius: 15px;
  width: 100%;
  ${CustomButton} {
    background-color: ${(p) => p.theme.lightPurple200};
    color: ${(p) => p.theme.purple};
    margin-left: 40px;
  }

  .userList {
    overflow: auto;
    max-height: 50vh;
  }
`;

export const LicenseFollowUserRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 30px;
  gap: 10px;
  transition: all .8s;
  align-items: self-start;
  padding: 5px 0px;
  & > svg {
    margin: auto;
    cursor: pointer;
    transition: all .8s;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    path {
      fill: ${(p) => (p.sinlgeEditActive ? (p.edit && p.theme.greenText) : p.theme.redText)};
    }
    :hover {
      opacity: ${(p) => (p.edit ? 1 : 0.7)};
      padding:3px;
      background: ${(p) => (p.sinlgeEditActive ? (p.edit ? p.theme.greenBackg : Colors.lightGrey500) : p.theme.redBackg)};
    }
  }
  .MuiTypography-body1 {
    font-size: 12px;
  }
`;

export const IntegrationMessageErrorAccordion = styled(Accordion)`
    box-shadow: ${(p) => (p.expanded ? 'unset' : '0px 4px 5px 0px #0000000f')};
    background: ${Colors.blueBackg} !important;
    transition: all 0.5s;
    .accordionHeaderTitle {
      color: ${Colors.blueText};
    }
`;

// MAIN URL - SOCKET URL
export const MAIN_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

// PROFİL
export const PROFIL = `${MAIN_URL}private/v1/Profile`;

// INIT
export const USER_INIT = `${MAIN_URL}init/v1/User`;

// ACCOUNTS
export const LOGIN = `${MAIN_URL}account/v1/Login`;
export const FORGOT_PASSWORD = `${MAIN_URL}account/v1/Password/forget`;
export const RESET_PASSWORD = `${MAIN_URL}account/v1/Password/reset`;

// PRIVATE
export const INFO = `${MAIN_URL}private/v1/Info`;
export const APPLICATIONS = `${MAIN_URL}private/v1/Application`;
export const COMMENTS = `${MAIN_URL}private/v1/Comment`;
export const UPLOAD = `${MAIN_URL}private/v1/Upload`;
export const USERS = `${MAIN_URL}private/v1/User/allUser`;
export const USER = `${MAIN_URL}private/v1/User`;
export const DASHBOARD = `${MAIN_URL}private/v1/Dashboard`;
export const CALENDAR = `${MAIN_URL}private/v1/Calendar`;
export const EXCEL = `${MAIN_URL}private/v1/Excel`;
export const EVENT = `${MAIN_URL}private/v1/Calendar`;
export const NOTIFICATION = `${MAIN_URL}private/v1/Notification`;
export const PRIVATE_SURVEY = `${MAIN_URL}private/v1/Survey`;
export const CREATE_APP_WITH_FILE = `${MAIN_URL}private/v1/Application/withFile`;
export const PASSWORD = `${MAIN_URL}private/v1/Password`;
export const TIME_MODEL = `${MAIN_URL}private/v1/Dashboard/timeModel`;
export const INTEGRATION = `${MAIN_URL}private/v1/Integration`;
export const INTEGRATION_COMPONENTS = `${MAIN_URL}private/v1/Integration/components`;
export const HARDWARE = `${MAIN_URL}private/v1/Hardware`;
export const COSTING = `${MAIN_URL}private/v1/Costing`;
export const REPORT = `${MAIN_URL}private/v1/Report`;
export const HWCOSTING = `${MAIN_URL}private/v1/HwCosting`;
export const LICENSE_FOLLOW = `${MAIN_URL}private/v1/License`;

// ADMIN
export const CREATE_INFO = `${MAIN_URL}admin/v1/Info`;
export const ADMIN_USER = `${MAIN_URL}admin/v1/User`;
export const ADMIN_APPLICATION = `${MAIN_URL}admin/v1/Application`;
export const SURVEY = `${MAIN_URL}admin/v1/Survey`;
export const COMPANY = `${MAIN_URL}admin/v1/Company`;
export const CHANGE_INFO_STATU = `${MAIN_URL}admin/v1/Info/activePassive`;
export const CHANGE_USER_STATU = `${MAIN_URL}admin/v1/User/changeActive`;
export const DOWNLOAD_INVENTORY = `${MAIN_URL}admin/v1/Excel/application`;
export const SURVEY_REPORT = `${MAIN_URL}admin/v1/SurRep`;
export const ADMIN_EXCEL = `${MAIN_URL}admin/v1/Excel`;
export const LDAP = `${MAIN_URL}admin/v1/User/ldap`;
export const ADMIN_INTEGRATION = `${MAIN_URL}admin/v1/Integration`;

// PUBLIC
export const COMPANY_REGISTER = `${MAIN_URL}public/v1/Companies/register`;
export const COMPANY_INIT = `${MAIN_URL}public/v1/Companies/accountTypes`;
export const COMPANY_ACTIVATION = `${MAIN_URL}public/v1/Companies/activate`;
export const RESEND_ACTIVATION_CODE = `${MAIN_URL}public/v1/Companies/resend`;

// COSTİNG_FİLE_ENCRYPT_KEY
export const PUBLIC_KEY = '-----BEGIN RSA PUBLIC KEY-----MIIBCgKCAQEA0Msd3Ga7cVHyJtdf3/wAQZMcn1EawSvoeJ7bS2To72PjEEDMSRj1DqzPkGcuENpbqgX8JG9FLQYs8T4G94GejGIllDS0po8aDuSntkTveR3aHqQYmBR7pJyzZ60cmQOwniJH47Wur6E6mVtWkrnOrWxWaAsorM0jqZms4cvxPXkIOsPcTCwlR+RO/PxKmEHSZChti3P4IhoBqXgTS+xtzaHjpfnWdsAzKX6KT2ftY2x1ZxzoYFEwNlYLavFCkuKbsGCcgrY3rKIl6S2SF/T9w+L8o9shVPw/nJRU/7OdAf37A8qgsDmCTL6eDE8+UddHPbJwEK4HshStbyZG7SiWUwIDAQAB-----END RSA PUBLIC KEY-----';

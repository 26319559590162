import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from 'styled-components';
import {
  CustomButton,
  CustomModal,
  ModalHeader,
  ModalContent,
  BottomSheetWrapper,
} from '../../assets/styledNew';

import {
  Colors,
} from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';

import { createVendorUser, setResetState, updateVendorUser } from '../../store/actions';
import { selectInfos, selectResetState, selectVendorUsers } from '../../store/selectors';
import BottomSheet from '../BottomSheet';
import Validator from '../../commons/validator';

class AddUpdateVendorUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor_id: '',
      person: '',
      mail: '',
      phone_code: null,
      phone: '',
      title: '',
    };
  }

  componentDidUpdate() {
    if (this.props.resetState) {
      this.resetState();
    }
  }

  handleEntering = () => {
    const { userId, vendorUsers, locations } = this.props;
    if (userId) {
      const selectedUser = vendorUsers.find((item) => item.id === userId);
      const selectedPhoneCode = locations.find((item) => item.dial_code === selectedUser.phone_code);
      this.setState({
        vendor_id: selectedUser.vendor_id,
        person: selectedUser.person,
        mail: selectedUser.mail,
        phone_code: selectedPhoneCode,
        phone: selectedUser.phone,
        title: selectedUser.title,
      });
    }
  }

  resetState = () => {
    this.setState({
      vendor_id: '',
      person: '',
      mail: '',
      phone_code: null,
      phone: '',
      title: '',
    });
    this.props.setResetState(false);
    Validator.hideMessages();
    this.forceUpdate();
  }

  handleSaveUser = () => {
    const { userId } = this.props;
    const {
      vendor_id, person, mail, phone_code, phone, title,
    } = this.state;
    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      return false;
    }
    const data = {
      vendor_id, person, mail, phone_code: phone_code?.dial_code, phone, title,
    };
    if (userId) {
      delete data.vendor_id;
      this.props.updateVendorUser(data, userId);
    } else {
      this.props.createVendorUser(data);
    }
    this.props.close();
    return true;
  }

  handleChange = (e, state) => {
    this.setState({ [state]: e.target.value });
  }

  handlePhoneChange = (values) => {
    this.setState({ phone: values.value });
  }

  handlePhoneCodeChange = (e, newValue) => {
    if (newValue) {
      this.setState({ phone_code: newValue });
    }
  };

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { locations, userId, vendors } = this.props;
    const {
      vendor_id, person, mail, phone_code, phone, title,
    } = this.state;
    Validator.purgeFields();
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{tra(userId ? 'Kullanici_Düzenleme' : 'Yeni_Kullanici_Ekle')}</h1>
          <CloseIcon onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  type="select"
                  title={tra('Vendors')}
                  value={vendor_id}
                  data={vendors}
                  onChange={(e) => this.handleChange(e, 'vendor_id')}
                  error={Validator.message('vendor_id', vendor_id, 'required')}
                  helperText={Validator.message('vendor_id', vendor_id, 'required')}
                  disabled={userId}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Görevi')}
                  value={title}
                  onChange={(e) => this.handleChange(e, 'title')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Adi')}
                  value={person}
                  onChange={(e) => this.handleChange(e, 'person')}
                  error={Validator.message('person', person, 'required|min:3')}
                  helperText={Validator.message('person', person, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Email')}
                  value={mail}
                  onChange={(e) => this.handleChange(e, 'mail')}
                  error={Validator.message('mail', mail, 'required|min:3')}
                  helperText={Validator.message('mail', mail, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="tel"
                  valueKey="dial_code"
                  data={locations}
                  telCode={phone_code}
                  onChangeTelCode={(e, newValue) => this.handlePhoneCodeChange(e, newValue)}
                  title={tra('Telefon')}
                  value={phone}
                  onValueChange={(values) => this.handlePhoneChange(values)}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={this.handleSaveUser} color={Colors.blue}>{tra('Kaydet')}</CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal onEntering={() => this.handleEntering()} open={this.props.open} onClose={this.props.close} onExit={() => this.resetState()} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AddUpdateVendorUser.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  userId: PropTypes.any.isRequired,
  createVendorUser: PropTypes.func.isRequired,
  updateVendorUser: PropTypes.func.isRequired,
  vendorUsers: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locations: selectInfos('Locations'),
  resetState: selectResetState(),
  vendorUsers: selectVendorUsers(),
  vendors: selectInfos('Vendors'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setResetState,
      createVendorUser,
      updateVendorUser,
    }, dispatch)
  )
);

const AddUpdateVendorUserWithTheme = withTheme(AddUpdateVendorUser);
const AddUpdateVendorUserWithWidth = withWidth()(AddUpdateVendorUserWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateVendorUserWithWidth);

/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  CalculateOldNew, CalculatePercentage, CommaStringToNumber,
} from '../utils';

export const CostingContext = React.createContext({});

const CostingProvider = (props) => {
  const categories = useSelector((state) => state.costingCategories);
  const locations = useSelector((state) => state.infos.Locations);
  const currencies = useSelector((state) => state.infos.Currencies);
  const user = useSelector((state) => state.activeUser);
  const activeComp = localStorage.getItem('company');
  const selectedComp = user.companies && user.companies.find((item) => item.id.toString() === activeComp);
  const selectedCompCurrency = currencies.find((item) => item.id === selectedComp?.currency_id);

  const [CostingState, setCostingState] = useState({
    agreement: '',
    service: '',
    category_id: '',
    vendor_id: null,
    vendorContact_person: '',
    vendorContact_mail: '',
    vendorContact_phone: '',
    phone_code: null,
    comments: '',
    total: 0,
    total_inv: 0,
    Applications: [],
    ContractUrls: [],
    fc: 0,
    GenericContItems: [
      {
        monthly_inv: 0,
        total_inv: 0,
        total_cost: 0,
        monthly_cost: 0,
        invCurr_type: '',
        period: 0,
        contract_start: new Date(),
        contract_end: new Date(),
        calculated_end: new Date(),
        purchase_date: null,
        fx_rate: 1,
        tco_agg: '',
      },
    ],
  });

  const [HWCostingState, setHWCostingState] = useState({
    cat_id: '',
    type_id: '',
    expense_type_id: '',
    total: 0,
    total_inv: 0,
    is_draft: 0,
    agreement: '',
    notes: '',
    HwGenericContItems: [
      {
        monthly_inv: 0,
        total_inv: 0,
        total_cost: 0,
        invCurr_type: '',
        period: 0,
        contract_start: new Date(),
        contract_end: new Date(),
        calculated_end: new Date(),
        purchase_date: null,
        fx_rate: 1,
        tco_agg: '',
      },
    ],
    HardwareInventoryContract: [],
    ContractUrls: [],
  });

  const [CostingPageVariables, setCostingPageVariables] = useState({
    costType: '',
    addAppModal: false,
    sendApplications: [],
    allocationTotal: 0,
    costingDocModal: false,
    appValueStatus: 1,
    appValueStatusMenu: null,
  });

  const setReset = () => {
    setCostingState({
      agreement: '',
      service: '',
      category_id: '',
      vendor_id: null,
      vendorContact_person: '',
      vendorContact_mail: '',
      vendorContact_phone: '',
      phone_code: null,
      comments: '',
      total: 0,
      total_inv: 0,
      Applications: [],
      ContractUrls: [],
      fc: 0,
      GenericContItems: [
        {
          monthly_inv: 0,
          total_inv: 0,
          total_cost: 0,
          monthly_cost: 0,
          invCurr_type: selectedCompCurrency ? selectedCompCurrency.id : '',
          period: 0,
          contract_start: new Date(),
          contract_end: new Date(),
          calculated_end: new Date(),
          purchase_date: null,
          fx_rate: 1,
          tco_agg: '',
        },
      ],
    });
    setCostingPageVariables({
      costType: '',
      addAppModal: false,
      sendApplications: [],
      allocationTotal: 0,
      costingDocModal: false,
      appValueStatus: 1,
      appValueStatusMenu: null,
    });
    setHWCostingState({
      cat_id: '',
      type_id: '',
      expense_type_id: '',
      total: 0,
      total_inv: 0,
      is_draft: 0,
      agreement: '',
      notes: '',
      HwGenericContItems: [
        {
          monthly_inv: 0,
          total_inv: 0,
          total_cost: 0,
          invCurr_type: '',
          period: 0,
          contract_start: new Date(),
          contract_end: new Date(),
          calculated_end: new Date(),
          purchase_date: null,
          fx_rate: 1,
          tco_agg: '',
        },
      ],
      HardwareInventoryContract: [],
      ContractUrls: [],
    });
  };

  const changeAppValueStatus = (val) => {
    setCostingState((prvState) => {
      setCostingPageVariables((prevState) => {
        let allocTotal = 0;
        if (val === 2) {
          for (let i = 0; i < prevState.sendApplications.length; ++i) {
            allocTotal += prevState.sendApplications[i].allocation;
          }
        } else {
          for (let i = 0; i < prevState.sendApplications.length; ++i) {
            allocTotal += prevState.sendApplications[i].value;
          }
        }
        return {
          ...prevState,
          allocationTotal: allocTotal,
          appValueStatus: val,
          appValueStatusMenu: null,
        };
      });
      return {
        ...prvState,
      };
    });
  };

  const changeAppValueStatusMenu = (val) => {
    setCostingPageVariables((prevState) => ({
      ...prevState,
      appValueStatusMenu: val,
    }));
  };

  const changeVendorUserFree = (e, newValue) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        setCostingState((prevState) => ({
          ...prevState,
          vendorContact_person: newValue,
        }));
      } else if (newValue && newValue.inputValue) {
        setCostingState((prevState) => ({
          ...prevState,
          vendorContact_person: newValue.inputValue,
        }));
      } else {
        const selectedPhone = locations.find((item) => item?.dial_code === newValue?.phone_code);
        setCostingState((prevState) => ({
          ...prevState,
          vendorContact_person: newValue.person,
          vendorContact_mail: newValue.mail,
          vendorContact_phone: newValue.phone,
          phone_code: selectedPhone,
        }));
      }
    } else {
      setCostingState((prevState) => ({
        ...prevState,
        vendorContact_person: '',
        vendorContact_mail: '',
        vendorContact_phone: '',
        phone_code: null,
      }));
    }
  };

  const changeAgrAutoComplete = (e, newValue) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        setCostingState((prevState) => ({
          ...prevState,
          agreement: newValue,
        }));
      } else if (newValue && newValue.inputValue) {
        setCostingState((prevState) => ({
          ...prevState,
          agreement: newValue.inputValue,
        }));
      } else {
        setCostingState((prevState) => ({
          ...prevState,
          agreement: newValue.agreement,
        }));
      }
    } else {
      setCostingState((prevState) => ({
        ...prevState,
        agreement: '',
      }));
    }
  };

  const handlePhoneChange = (values, name) => {
    const value = values?.value;
    setCostingState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneCodeChange = (e, newValue) => {
    if (newValue) {
      setCostingState((prevState) => ({
        ...prevState,
        phone_code: newValue,
      }));
    }
  };

  const addNewContract = () => {
    setCostingState((prevState) => {
      const prevContract = prevState.GenericContItems[prevState.GenericContItems.length - 1];
      const newdate = new Date(prevContract.contract_end);
      const newObj = {
        monthly_inv: 0,
        total_inv: 0,
        total_cost: 0,
        monthly_cost: 0,
        invCurr_type: selectedCompCurrency ? selectedCompCurrency.id : '',
        period: 0,
        contract_start: newdate,
        contract_end: new Date(newdate.setDate(newdate.getDate() + 1)),
        calculated_end: new Date(),
        purchase_date: null,
        fx_rate: 1,
        tco_agg: '',
      };
      const tempContracts = prevState.GenericContItems;
      tempContracts.push(newObj);
      return {
        ...prevState,
        GenericContItems: tempContracts,
      };
    });
  };

  const deleteContract = (index) => {
    setCostingState((prevState) => {
      const tempContracts = prevState.GenericContItems;
      const newTotalCost = prevState.total - tempContracts[index].total_cost;
      const newTotalInv = prevState.total_inv - tempContracts[index].total_inv;
      setCostingPageVariables((prvState) => {
        const sendApps = prvState.sendApplications;
        if (prvState.appValueStatus === 2) {
          for (let i = 0; i < sendApps.length; ++i) {
            sendApps[i].value = CalculatePercentage(sendApps[i].allocation, newTotalCost);
          }
        } else {
          for (let i = 0; i < sendApps.length; ++i) {
            sendApps[i].allocation = (sendApps[i].value * 100) / newTotalCost;
          }
        }
        return {
          ...prvState,
          sendApplications: sendApps,
        };
      });
      tempContracts.splice(index, 1);
      return {
        ...prevState,
        total: newTotalCost,
        total_inv: newTotalInv,
        GenericContItems: tempContracts,
      };
    });
  };

  const handleChange = (event, name, index) => {
    const value = event.target?.value;
    const checkVal = event.target?.checked;
    switch (name) {
      case 'costType':
        {
          const selectedCostType = categories.find((item) => item.id === value);
          CostingState.category_id = selectedCostType.CostingCategories[0].id;
          setCostingPageVariables({
            ...CostingPageVariables,
            costType: value,
          });
        }
        break;
      case 'fc':
        setCostingState((prevState) => ({
          ...prevState,
          fc: checkVal ? 1 : 0,
        }));
        break;
      case 'tco_agg':
        setCostingState((prevState) => {
          const tempContracts = prevState.GenericContItems;
          tempContracts[index].tco_agg = value;
          return {
            ...prevState,
            GenericContItems: tempContracts,
          };
        });
        break;
      case 'invCurr_type':
        {
          const selectedCur = currencies.find((item) => item.id === value);
          if (selectedCompCurrency.id === selectedCur.id) {
            setCostingState((prevState) => {
              const tempContracts = prevState.GenericContItems;
              tempContracts[index].invCurr_type = value;
              tempContracts[index].fx_rate = 1;
              return {
                ...prevState,
                GenericContItems: tempContracts,
              };
            });
          } else {
            setCostingState((prevState) => {
              const tempContracts = prevState.GenericContItems;
              tempContracts[index].invCurr_type = value;
              return {
                ...prevState,
                GenericContItems: tempContracts,
              };
            });
          }
        }
        break;
      case 'category_id':
        setReset();
        setCostingState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
      default:
        setCostingState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
    }
  };

  const removeApp = (id) => {
    setCostingPageVariables((prvState) => {
      const tempSendApps = prvState.sendApplications;
      const selectedIndex = tempSendApps.findIndex((item) => item.id === id);
      let newTotalAllocation = 0;
      setCostingState((prevState) => {
        const selectedIndex2 = prevState.Applications.findIndex((item) => item.id === id);
        newTotalAllocation = CommaStringToNumber(prvState.allocationTotal) - CommaStringToNumber(tempSendApps[selectedIndex][prvState.appValueStatus === 2 ? 'allocation' : 'value']);
        return {
          ...prevState,
          Applications: prevState.Applications.splice(selectedIndex2, 1),
        };
      });
      tempSendApps.splice(selectedIndex, 1);
      return {
        ...prvState,
        allocationTotal: newTotalAllocation,
        sendApplications: tempSendApps,
      };
    });
  };

  const addAppToContract = (application, allocation, value) => {
    setCostingState((prevState) => {
      const tempApp = prevState.Applications;
      tempApp.push(application);
      return {
        ...prevState,
        Applications: tempApp,
      };
    });
    setCostingPageVariables((prevState) => {
      const tempSendApps = prevState.sendApplications;
      let tempAllocTotal = prevState.allocationTotal;
      if (tempSendApps.findIndex((item) => item.id === application.id) < 0) {
        const obj = {
          id: application.id, value, value_inv: 1, allocation: isNaN(allocation) ? CommaStringToNumber(allocation.replace('%', '')) : allocation,
        };
        if (prevState.appValueStatus === 2) {
          tempAllocTotal = (isNaN(tempAllocTotal) ? CommaStringToNumber(tempAllocTotal) : tempAllocTotal) + CommaStringToNumber(isNaN(allocation) ? CommaStringToNumber(allocation.replace('%', '')) : allocation);
        } else {
          tempAllocTotal = (isNaN(tempAllocTotal) ? CommaStringToNumber(tempAllocTotal) : tempAllocTotal) + value;
        }
        tempSendApps.push(obj);
      }
      return {
        ...prevState,
        sendApplications: tempSendApps,
        addAppModal: false,
        allocationTotal: tempAllocTotal,
      };
    });
  };

  // allocation da absolute value değişirken kullanılan metod
  const handleChangeAppValue = (e, id) => {
    const val = e.target.value;
    let total = 0;
    setCostingState((prevState) => {
      total = prevState.total;
      return {
        ...prevState,
      };
    });
    setCostingPageVariables((prevState) => {
      const tempSendApps = prevState.sendApplications;
      const selectedIndex = tempSendApps.findIndex((item) => item.id === id);
      const newTotal = CalculateOldNew(tempSendApps[selectedIndex].value, val, prevState.allocationTotal);
      tempSendApps[selectedIndex].value = CommaStringToNumber(val.trim());
      tempSendApps[selectedIndex].allocation = CalculatePercentage(tempSendApps[selectedIndex].value, total);
      return {
        ...prevState,
        allocationTotal: newTotal,
        sendApplications: tempSendApps,
      };
    });
  };

  // allocation da yüzdeli kısım değişirken kullanılan metod
  const handleChangeAppValueAllocation = (e, id) => {
    const val = CommaStringToNumber(e.target.value.replace('%', ''));
    let totalCost = 0;
    setCostingState((prevState) => {
      totalCost = prevState.total;
      return {
        ...prevState,
      };
    });
    setCostingPageVariables((prevState) => {
      const tempSendApps = prevState.sendApplications;
      const selectedIndex = tempSendApps.findIndex((item) => item.id === id);
      const calculatedVal = (CommaStringToNumber(totalCost) * val) / 100;
      tempSendApps[selectedIndex].value = calculatedVal;
      const newTotal = CalculateOldNew(CommaStringToNumber(tempSendApps[selectedIndex].allocation), val, CommaStringToNumber(prevState.allocationTotal));
      tempSendApps[selectedIndex].allocation = val;
      return {
        ...prevState,
        allocationTotal: newTotal,
        sendApplications: tempSendApps,
      };
    });
  };

  const calculateApplicationValues = (val) => {
    setCostingPageVariables((prvState) => {
      const tempSendApps = prvState.sendApplications;
      if (tempSendApps.length > 0) {
        if (prvState.appValueStatus === 2) {
          for (let i = 0; i < tempSendApps.length; ++i) {
            const value = (CommaStringToNumber(val) * tempSendApps[i].allocation) / 100;
            tempSendApps[i].value = value;
          }
        } else {
          for (let i = 0; i < tempSendApps.length; ++i) {
            const newPercent = CalculatePercentage(tempSendApps[i].value, CommaStringToNumber(val));
            tempSendApps[i].allocation = newPercent;
          }
        }
        return {
          ...prvState,
          sendApplications: tempSendApps,
        };
      }
      return {
        ...prvState,
      };
    });
  };

  // perpetual de period, fxrate ve totalInv değiştiğinde yapılacak hesaplamalar
  const calcDepreciationPeriod = (selectedDate, totalCost, period, fxrate, index) => {
    setCostingState((prevState) => {
      const tempContracts = prevState.GenericContItems;
      let tempDays = [];
      const val = CommaStringToNumber(period);
      let calculatedAddedDays = 0;
      let date = moment(new Date(tempContracts[index].contract_start)).add(val, 'M').subtract(1, 'd');
      if (selectedDate) {
        date = moment(new Date(selectedDate)).add(val, 'M').subtract(1, 'd');
        if (period.toString().includes('.')) {
          tempDays = period.toString().split('.');
          if (tempDays[1] !== '') {
            const futureDays = moment(new Date(tempContracts[index].contract_start).setMonth(new Date(tempContracts[index].contract_start).getMonth() + parseInt(val, 10))).daysInMonth();
            calculatedAddedDays = (futureDays * parseInt(tempDays[1], 10)) / (10 ** tempDays[1].length);
            date = date.add(calculatedAddedDays, 'd');
          }
        }
      } else if (period !== 0 && period?.toString().includes(',')) {
        tempDays = period.split(',');
        if (tempDays[1] !== '') {
          const futureDays = moment(new Date(tempContracts[index].contract_start).setMonth(new Date(tempContracts[index].contract_start).getMonth() + parseInt(val, 10))).daysInMonth();
          calculatedAddedDays = (futureDays * parseInt(tempDays[1], 10)) / (10 ** tempDays[1].length);
          date = date.add(calculatedAddedDays, 'd');
        }
      }

      const newTotal = CalculateOldNew(tempContracts[index].total_cost, CommaStringToNumber(totalCost), prevState.total);
      const newTotalInv = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(totalCost) * CommaStringToNumber(fxrate), prevState.total_inv);
      tempContracts[index].fx_rate = CommaStringToNumber(fxrate);
      tempContracts[index].period = val;
      tempContracts[index].monthly_inv = CommaStringToNumber(totalCost) / val;
      tempContracts[index].monthly_cost = (CommaStringToNumber(totalCost) / val) * CommaStringToNumber(fxrate);
      tempContracts[index].total_inv = CommaStringToNumber(totalCost) * CommaStringToNumber(fxrate);
      tempContracts[index].calculated_end = new Date(date);
      tempContracts[index].contract_end = new Date(date);

      return {
        ...prevState,
        total: newTotal,
        total_inv: newTotalInv,
        GenericContItems: tempContracts,
      };
    });
  };

  const handleChangeCurrency = (e, index) => {
    const val = e.target.value;
    setCostingState((prevState) => {
      const tempContracts = prevState.GenericContItems;
      const totalTemp = CommaStringToNumber(val) * CommaStringToNumber(tempContracts[index].fx_rate);
      const newTotal = CalculateOldNew(prevState.GenericContItems[index].total_cost, val, prevState.total);
      const newTotalInv = CalculateOldNew(prevState.GenericContItems[index].total_inv, totalTemp, prevState.total_inv);
      if (prevState.category_id === 2) {
        calcDepreciationPeriod(false, val, tempContracts[index].period, tempContracts[index].fx_rate, index);
      } else {
        if (tempContracts[index].period !== 0 && tempContracts[index].fx_rate !== 0) {
          /** Düzenleme lazım buraya newtotal hesabına */
          calculateApplicationValues(newTotal);
          tempContracts[index].total_cost = CommaStringToNumber(val);
          tempContracts[index].total_inv = totalTemp;
          tempContracts[index].monthly_inv = CommaStringToNumber(totalTemp) / CommaStringToNumber(tempContracts[index].period);
          tempContracts[index].monthly_cost = CommaStringToNumber(val) / CommaStringToNumber(tempContracts[index].period);
          return {
            ...prevState,
            total: newTotal,
            total_inv: newTotalInv,
            GenericContItems: tempContracts,
          };
        }
        if (tempContracts[index].period !== 0) {
          tempContracts[index].total_cost = CommaStringToNumber(val);
          tempContracts[index].monthly_cost = CommaStringToNumber(val) / CommaStringToNumber(tempContracts[index].period);
          return {
            ...prevState,
            total: newTotal,
            total_inv: newTotalInv,
            GenericContItems: tempContracts,
          };
        }
        if (tempContracts[index].fx_rate !== 0) {
          calculateApplicationValues(newTotal);
          tempContracts[index].total_inv = totalTemp;
          tempContracts[index].total_cost = CommaStringToNumber(val);
          tempContracts[index].monthly_cost = tempContracts[index].period !== 0 && CommaStringToNumber(val) / CommaStringToNumber(tempContracts[index].period);
          return {
            ...prevState,
            total: newTotal,
            total_inv: newTotalInv,
            GenericContItems: tempContracts,
          };
        }
      }

      tempContracts[index].total_cost = CommaStringToNumber(val);
      return {
        ...prevState,
        total: newTotal,
        total_inv: newTotalInv,
        GenericContItems: tempContracts,
      };
    });
  };

  const handleDateChange = (stateText, index) => (e) => {
    if (stateText === 'contract_start' || stateText === 'contract_end') {
      setCostingState((prevState) => {
        const tempContracts = prevState.GenericContItems;
        const selectedDatePlusOne = moment(new Date(e).setDate(new Date(e).getDate() + 1));
        const contractEndPlusOne = moment(new Date(tempContracts[index].contract_end).setDate(new Date(tempContracts[index].contract_end).getDate() + 1));
        const contractStartPlusOne = moment(new Date(tempContracts[index].contract_start));
        const calculatedPeriod = moment(stateText === 'contract_end'
          ? selectedDatePlusOne.set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          : contractEndPlusOne.set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })).diff(moment(stateText === 'contract_start'
          ? moment(new Date(e)).set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          : contractStartPlusOne.set({
            hour: 13,
            minute: 0,
            second: 0,
            millisecond: 0,
          })), 'months', true);
        if (prevState.category_id !== 2) {
          // perpetual hariç period değiştiğinde alanların tekrar hesaplanması
          tempContracts[index].period = calculatedPeriod;
          if (CommaStringToNumber(tempContracts[index].total_inv) !== 0) {
            tempContracts[index].monthly_inv = CommaStringToNumber(tempContracts[index].total_inv) / calculatedPeriod;
            tempContracts[index].monthly_cost = CommaStringToNumber(tempContracts[index].total_cost) / calculatedPeriod;
            return {
              ...prevState,
              GenericContItems: tempContracts,
            };
          }
          return {
            ...prevState,
            GenericContItems: tempContracts,
          };
        }
        if (stateText === 'contract_start') {
          calcDepreciationPeriod(e, tempContracts[index].total_cost, tempContracts[index].period, tempContracts[index].fx_rate, index);
        }
        return {
          ...prevState,
          GenericContItems: tempContracts,
        };
      });
      if (stateText === 'contract_end') {
        setCostingState((prevState) => {
          const tempContracts = prevState.GenericContItems;
          tempContracts[index].contract_end = e;
          return {
            ...prevState,
            GenericContItems: tempContracts,
          };
        });
      }
    }
    setCostingState((prevState) => {
      const tempContracts = prevState.GenericContItems;
      tempContracts[index][stateText] = e;
      return {
        ...prevState,
        GenericContItems: tempContracts,
      };
    });
  };

  const changeFxRate = (e, type, buttonType, index) => {
    let val = e.target?.value || 0;
    switch (type) {
      case 'change':
        if (CostingState.category_id !== 2) {
          setCostingState((prevState) => {
            const tempContracts = prevState.GenericContItems;
            tempContracts[index].fx_rate = val;
            const newTotal = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val), prevState.total_inv);
            tempContracts[index].total_inv = CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val);
            if (tempContracts[index].period !== 0) {
              tempContracts[index].monthly_inv = (CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val)) / tempContracts[index].period;
              tempContracts[index].monthly_cost = CommaStringToNumber(tempContracts[index].total_cost) / tempContracts[index].period;
            }
            return {
              ...prevState,
              total_inv: newTotal,
              GenericContItems: tempContracts,
            };
          });
        } else {
          setCostingState((prevState) => {
            const tempContracts = prevState.GenericContItems;
            calcDepreciationPeriod(false, tempContracts[index].total_cost, tempContracts[index].period, val, index);
            tempContracts[index].fx_rate = val;
            const newTotal = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val), prevState.total_inv);
            tempContracts[index].total_inv = CommaStringToNumber(tempContracts[index].total_cost) * CommaStringToNumber(val);
            return ({
              ...prevState,
              total_inv: newTotal,
              GenericContItems: tempContracts,
            });
          });
        }
        break;
      case 'buttonClick':
        if (parseFloat(CostingState.GenericContItems[index].fx_rate) !== 0 || buttonType !== 'minus') {
          if (CostingState.category_id !== 2) {
            setCostingState((prevState) => {
              const tempContracts = prevState.GenericContItems;
              val = CommaStringToNumber(prevState.fx_rate) + (buttonType === 'plus' ? +1 : -1);
              tempContracts[index].fx_rate = val;
              const newTotal = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(tempContracts[index].total_cost) * val, prevState.total_inv);
              tempContracts[index].total_inv = CommaStringToNumber(tempContracts[index].total_cost) * val;
              tempContracts[index].monthly_inv = (CommaStringToNumber(tempContracts[index].total_cost) * val) / prevState.period;
              tempContracts[index].monthly_cost = CommaStringToNumber(tempContracts[index].total_cost) / prevState.period;
              return ({
                ...prevState,
                total_inv: newTotal,
                GenericContItems: tempContracts,
              });
            });
          } else {
            setCostingState((prevState) => {
              const tempContracts = prevState.GenericContItems;
              val = CommaStringToNumber(tempContracts[index].fx_rate) + (buttonType === 'plus' ? +1 : -1);
              calcDepreciationPeriod(false, tempContracts[index].total_cost, tempContracts[index].period, val, index);
              const newTotal = CalculateOldNew(tempContracts[index].total_inv, CommaStringToNumber(tempContracts[index].total_cost) * val, prevState.total_inv);
              tempContracts[index].total_inv = CommaStringToNumber(tempContracts[index].total_cost) * val;
              return {
                ...prevState,
                total_inv: newTotal,
                GenericContItems: tempContracts,
              };
            });
          }
        }
        break;
      default:
        break;
    }
    // fx rate değiştikçe allocationdaki ve allocation overview tekrar hesaplanması gerekiyor
    setCostingState((prvState) => {
      setCostingPageVariables((prevState) => {
        let newAllocationTotal = 0;
        const tempSendApps = prevState.sendApplications;
        if (prevState.appValueStatus === 2) {
          for (let i = 0; i < tempSendApps.length; ++i) {
            const value = (prvState.total * tempSendApps[i].allocation) / 100;
            tempSendApps[i].value = value;
            newAllocationTotal += tempSendApps[i].allocation;
          }
          return {
            ...prevState,
            allocationTotal: newAllocationTotal,
            sendApplications: tempSendApps,
          };
        }
        for (let i = 0; i < tempSendApps.length; ++i) {
          tempSendApps[i].allocation = CalculatePercentage(tempSendApps[i].value, prvState.total);
          newAllocationTotal += tempSendApps[i].value;
        }
        return {
          ...prevState,
          allocationTotal: newAllocationTotal,
          sendApplications: tempSendApps,
        };
      });
      return {
        ...prvState,
      };
    });
  };

  const handleChangeDepreciationPeriod = (index) => (e) => {
    const val = e.target.value;
    setCostingState((prevState) => {
      const tempContracts = prevState.GenericContItems;
      calcDepreciationPeriod(false, tempContracts[index].total_cost, val, tempContracts[index].fx_rate, index);
      tempContracts[index].period = val;
      return {
        ...prevState,
        GenericContItems: tempContracts,
      };
    });
  };

  const autoCompChange = (val, key) => {
    if (val !== undefined) {
      setCostingState((prvState) => ({
        ...prvState,
        [key]: val,
      }));
    }
  };

  const value = {
    CostingState,
    HWCostingState,
    CostingPageVariables,
    calcDepreciationPeriod,
    setCostingPageVariables,
    setCostingState,
    setHWCostingState,
    removeApp,
    addAppToContract,
    handleChangeAppValue,
    handleChangeAppValueAllocation,
    handleChangeCurrency,
    handleDateChange,
    changeFxRate,
    setContextCostingState: (object) => setCostingState({ ...CostingState, ...object }),
    setContextHWCostingState: (object) => setHWCostingState({ ...HWCostingState, ...object }),
    setReset,
    handleChange,
    handlePhoneChange,
    changeAgrAutoComplete,
    handleChangeDepreciationPeriod,
    addNewContract,
    deleteContract,
    changeAppValueStatus,
    changeAppValueStatusMenu,
    handlePhoneCodeChange,
    changeVendorUserFree,
    autoCompChange,
  };

  return (
    <CostingContext.Provider value={value}>
      {props.children}
    </CostingContext.Provider>

  );
};

CostingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CostingProvider;

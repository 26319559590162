import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
} from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Calendar from '../components/Calendar';
import {
  PageWrapper,
  PageContent,
  CustomButton,
  BoxCalendarContainer,
} from '../assets/styledNew';
// import { Bell } from '../assets/icons';
import { eventColor } from '../assets/statics';
import { tra } from '../commons/utils';
import CalendarModal from '../components/modals/NewCalendarEvent';
import BottomSheet from '../components/BottomSheet';
import { selectCalendar } from '../store/selectors';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      eventList: [],
      selectedDay: new Date(),
      counter: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.match.params.id) {
      if (prevState.selectedDay.toLocaleDateString() === new Date().toLocaleDateString() && nextProps.calendar && nextProps.calendar.length > 0) {
        const todayEvents = nextProps.calendar.filter((item) => new Date(item.date).toLocaleDateString() === new Date().toLocaleDateString());
        return { counter: 0, eventList: todayEvents };
      }
      return { counter: 0 };
    }
    if (prevState.counter === 0 && nextProps.calendar && nextProps.calendar.length > 0 && nextProps.match.params.id) {
      const events = nextProps.calendar.filter((item) => (item.status === 2));
      const selectedEvent = events.find((item) => item.id.toString() === nextProps.match.params.id.toString());
      if (selectedEvent) {
        const eventList = nextProps.calendar.filter((item) => new Date(item.date).toLocaleDateString() === new Date(selectedEvent.date).toLocaleDateString());
        return { selectedDay: new Date(selectedEvent.date), counter: 1, eventList };
      }
    }
    return null;
  }

  selectDay = (day, events) => {
    this.setState({
      eventList: events,
      selectedDay: day,
    });
  }

  dateBox = () => {
    const { selectedDay } = this.state;
    const today = new Date();
    const date = {
      year: (selectedDay.getDate() === today.getDate()) ? tra('Bugun') : selectedDay.toLocaleDateString('tr', { year: 'numeric' }),
      day: selectedDay.toLocaleDateString('tr', { day: 'numeric' }),
      weekDay: selectedDay.toLocaleDateString('tr', { weekday: 'long' }),
      month: selectedDay.toLocaleDateString('tr', { month: 'long' }),
    };

    return isWidthDown('xs', this.props.width)
      ? (
        <div className="bottomSheetinitialVisible dateBox">
          <p className="year">{selectedDay.toLocaleDateString('tr', { day: 'numeric', weekday: 'long', month: 'long' })}</p>
          {/* <KeyboardArrowUpIcon color={Colors.white} width={32} /> */}
        </div>
      )
      : (
        <div className="dateBox">
          <p className="day">{date.day}</p>
          <div>
            <p className="year">{tra(date.month)} {date.year !== tra('Bugun') && date.year}</p>
            <p className="month">{date.year === tra('Bugun') && date.year} {tra(date.weekDay)}</p>
          </div>
        </div>
      );
  }

  notificationCard = (item) =>
  // const date = new Date(item.date);
    (
      <div className="card">
        <div className="dot" style={{ border: `3px solid ${eventColor[item.status]}` }} />
        <div className="container">
          <div className="title">{item.name}</div>
          <div className="desc">{item.app_name}</div>
          {/* <CustomButton color={eventColor[item.status]}> <Bell color={Colors.white} width={12} />{tra('Hatirlatici_Kur')}</CustomButton> */}
        </div>
        {/* <div className="date"><p>{date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p></div> */}
      </div>
    )
    ;

  displayMobilePaddingReset = () => (isWidthDown('sm', this.props.width) ? { margin: 0, padding: 0 } : {})

  render() {
    const {
      eventList,
      modalShow,
      selectedDay,
    } = this.state;
    return (
      <>
        <PageWrapper>
          <PageContent style={{ overflow: 'unset' }}>
            <div className="pageInner" style={this.displayMobilePaddingReset()}>
              <Grid container spacing={isWidthDown('sm', this.props.width) ? 0 : 3} style={this.displayMobilePaddingReset()}>
                <Grid item xs={12} md={9} style={this.displayMobilePaddingReset()}>
                  <Calendar
                    selectDay={(day, events) => this.selectDay(day, events)}
                  />
                </Grid>
                {!isWidthDown('xs', this.props.width)
                  && (
                    <Grid item xs={12} md={3}>
                      <div style={{ position: 'sticky', top: isWidthDown('md', this.props.width) ? 100 : 20 }}>
                        <BoxCalendarContainer>
                          {this.dateBox()}
                          <div className="box">
                            <div className="header">{tra('Listeler')}</div>
                            <div className="content">
                              {eventList.map((item) => this.notificationCard(item))}
                            </div>
                            <div className="newEventBox">
                              <p>
                                {
                                  moment().diff(selectedDay) < 0 ? (
                                    `${tra('Hatirlatici_Olusturabilir_Mesaji')}.`
                                  ) : (
                                    `${tra('Hatirlatici_Olusturulamaz_Mesaji')}.`
                                  )
                                }
                              </p>
                              {
                                moment().diff(selectedDay) < 0 && (
                                  <CustomButton shadow onClick={() => this.setState({ modalShow: true })}>
                                    <AddCircleOutlineOutlined />{tra('Hatirlatici_Kur')}
                                  </CustomButton>
                                )
                              }
                            </div>
                          </div>
                        </BoxCalendarContainer>
                      </div>
                    </Grid>
                  )}
              </Grid>
            </div>
            <CalendarModal
              open={modalShow}
              close={() => this.setState({ modalShow: false })}
              selectedDay={selectedDay}
            />
          </PageContent>
        </PageWrapper>

        {isWidthDown('xs', this.props.width) && (
          <BottomSheet snapPoint={[600, 150]} open initialSnap={1}>
            <BoxCalendarContainer>
              {this.dateBox()}
              <div className="box">
                <div className="header">{tra('Listeler')}</div>
                <div className="content">
                  {eventList.map((item) => this.notificationCard(item))}
                </div>
                <div className="newEventBox">
                  <p>
                    {
                      moment().diff(selectedDay) < 0 ? (
                        `${tra('Hatirlatici_Olusturabilir_Mesaji')}.`
                      ) : (
                        `${tra('Hatirlatici_Olusturulamaz_Mesaji')}.`
                      )
                    }
                  </p>
                  {
                    moment().diff(selectedDay) < 0 && (
                      <CustomButton shadow onClick={() => this.setState({ modalShow: true })}>
                        <AddCircleOutlineOutlined />{tra('Hatirlatici_Kur')}
                      </CustomButton>
                    )
                  }
                </div>
              </div>
            </BoxCalendarContainer>
          </BottomSheet>
        )}
      </>
    );
  }
}

Schedule.propTypes = {
  width: PropTypes.string.isRequired,
  calendar: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  calendar: selectCalendar(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
    }, dispatch)
  )
);

const ScheduleWithWidth = withWidth()(Schedule);

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleWithWidth);

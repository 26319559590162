import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { AssignmentTurnedIn } from '@material-ui/icons';
import {
  Grid, Tooltip, Slide,
} from '@material-ui/core';
import { withTheme } from 'styled-components';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { DragToScroll } from '../commons/touch';
import {
  PageWrapper, PageContent, CustomButton, ComponentLoader, NoData, Placeholder, GridPageWrapper, SurveyListPageWrapper,
} from '../assets/styledNew';
import {
  Search, RightArrow, Draft, File,
} from '../assets/icons';
import Input from '../components/Input';
import {
  getAssignedSurveys, getSurveyQuestions, setAssignedSurveys,
} from '../store/actions';
import { selectApplications, selectAssignedSurveys } from '../store/selectors';
import { tra } from '../commons/utils';
import { SurveyCard, SurveyListMobile } from '../assets/styledSurveyQuestions';
import NoDataImg from '../assets/img/noData.png';

class Surveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeAll: false,
      selectedApps: [],
      scroll: {
        lastValue: 0,
        status: true,
      },
      surveyAllPageMobile: new Array(3),
      surveyCardFilter: 0,
      searchText: '',
    };
    props.getAssignedSurveys();
    // let timer;
  }

  componentDidMount() {
    document.querySelector('.mainContent').addEventListener('scroll', (e) => this.handlescroll(e));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.assignedSurveys.length > 0 && prevState.selectedApps.length === 0) {
      const temp = [];
      for (let i = 0; i < nextProps.assignedSurveys.length; ++i) {
        for (let j = 0; j < nextProps.assignedSurveys[i].SurveyCounts.length; ++j) {
          const obj = { surveyId: nextProps.assignedSurveys[i].id, appId: nextProps.assignedSurveys[i].SurveyCounts[0].id };
          temp.push(obj);
        }
      }
      return { selectedApps: temp };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({ selectedApps: [] });
    this.props.setAssignedSurveys([]);
    document.querySelector('.mainContent').addEventListener('scroll', (e) => this.handlescroll(e));
  }

  /*
  *   ---------------------  Common Methods  ---------------------
  */

  componentLoader = () => <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>

  noDataImage = () => (
    <NoData>
      <img src={NoDataImg} alt="No Data" />
      <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
    </NoData>
  )

  handleSearch = (surveys) => {
    const { searchText } = this.state;
    const temp = [];
    if (searchText !== '') {
      for (let i = 0; i < surveys.length; ++i) {
        if (surveys[i].name.toUpperCase().indexOf(searchText.toUpperCase()) > -1) {
          temp.push(surveys[i]);
        }
      }
      return temp;
    }
    return surveys;
  }

  handleStartSurvey = (id, appId) => {
    const { history, match } = this.props;
    this.props.getSurveyQuestions(id, appId);
    if (match.params.appId) {
      history.replace(`SurveyAnswer/${id}/${appId}/1`);
    } else {
      history.push(`Surveys/SurveyAnswer/${id}/${appId}/0`);
    }
  }

  onChangeApp = (e, surveyId) => {
    const { selectedApps } = this.state;
    selectedApps.find((item) => item.surveyId === surveyId).appId = e.target.value;
    this.setState({ selectedApps });
  }

  // handleCardMenu = (e) => {
  //   this.setState({ cardMenu: e.currentTarget });
  // }

  handleCardIcon = (active, type) => {
    if (active) {
      switch (type) {
        case 0:
          return <File width={25} />;
        case 1:
          return <AssignmentTurnedIn />;
        case 2:
          return <Draft width={25} />;
        default:
          return <File width={25} />;
      }
    }
    return <AssignmentTurnedIn />;
  }

  surveyCard = (item) => {
    const { selectedApps } = this.state;
    const selectedApp = item.SurveyCounts.find((app) => app.id === (selectedApps.find((sltApp) => sltApp.surveyId === item.id).appId));
    const createdDate = new Date(item.createdAt);
    const date = {
      year: createdDate.toLocaleDateString('tr', { year: 'numeric' }),
      day: createdDate.toLocaleDateString('tr', { day: 'numeric' }),
      month: createdDate.toLocaleDateString('tr', { month: 'long' }),
    };
    return (
      <SurveyCard bar={item.doneTask} volume={item.tasks} color={item.color}>
        <div className="createdCard">
          <div className="cardHeader">
            {/* <div className="smallCircle" /> */}
            {/* <div className="menu">
              <IconButton onClick={(e) => this.handleCardMenu(e)}>
                <MoreVertIcon style={{ color: Colors.grey }} />
              </IconButton>
              <CustomMenu
                anchorEl={this.state.cardMenu}
                open={Boolean(this.state.cardMenu)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                onClose={() => this.setState({ cardMenu: null })}
              >
                <MenuItem><EyeFilled color="#353F66" /> {tra('Gor')} </MenuItem>
                <MenuItem><CheckedCircle color="#353F66" /> {tra('Anketi_Bitir')} </MenuItem>
                <MenuItem><SendNew color="#353F66" /> {tra('Gonder')} </MenuItem>
              </CustomMenu>
            </div> */}
          </div>
          <div className="content">
            <div className="icon">
              {this.handleCardIcon(item.active === 1, selectedApp?.situation)}
            </div>
            <div className="info">
              <Tooltip title={item.name}>
                <h4>{item.name}</h4>
              </Tooltip>
              <p>{item.active === 1 ? tra('Uygulama_Secin') : tra('Anket_Sonlandirilmistir')}</p>
            </div>
          </div>
          {item.active === 1 && (
            <div className="cardBar">
              <Input
                type="select"
                data={item.SurveyCounts}
                value={selectedApps.find((app) => app.surveyId === item.id)?.appId}
                onChange={(e) => this.onChangeApp(e, item.id)}
              />
            </div>
          )}
          <div className="footer">
            <div className="date">
              <p className="date">{tra('Yaratilma_Tarihi')}: {tra(date.day)} {tra(date.month)} {date.year}</p>
            </div>
            <div className="iconButtons">
              {item.active === 1 && selectedApp?.situation === 0 && (
                <CustomButton onClick={() => this.handleStartSurvey(item.id, selectedApp.id)} fullWidth>{tra('Basla')}</CustomButton>
              )}
              {(item.active === 0 || selectedApp?.situation === 1) && (
                <CustomButton onClick={() => this.handleStartSurvey(item.id, selectedApp.id)} fullWidth>{tra('Detay')}</CustomButton>
              )}
              {item.active === 1 && selectedApp?.situation === 2 && (
                <CustomButton onClick={() => this.handleStartSurvey(item.id, selectedApp.id)} fullWidth>{tra('Devam_Et')}</CustomButton>
              )}
            </div>
          </div>
        </div>
      </SurveyCard>
    );
  }

  /*
  *   ------------------///  Common Methods  ---------------------
  */

  /*
  *   ---------------------  Mobile Methods  ---------------------
  */

  displayMobile = () => {
    const { assignedSurveys } = this.props;
    const { scroll } = this.state;
    return (
      <>
        <Slide
          appear={false}
          direction="down"
          in={scroll.status}
        >
          <div className="search">
            <Input endAdornment={<Search />} onChange={(e) => this.setState({ searchText: e.target.value })} type="text" placeholder={tra('Anket_Ara')} />
          </div>
        </Slide>
        <Placeholder height={40} />

        <div className="cardList">
          <Grid container spacing={3}>
            {
              assignedSurveys
                ? assignedSurveys.length > 0 && [...Array(3).keys()].map((item) => this.surveyListSwitchMobile(item))
                : this.componentLoader()
            }
          </Grid>
        </div>

      </>
    );
  }

  /*
  *  Mobilde search slide animasyonu için
  */
  handlescroll = (e) => {
    const { scroll } = this.state;
    const currentScroll = e.target.scrollTop;
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
    this.timer = window.setTimeout(() => {
      if (currentScroll >= scroll.lastValue + 10) {
        scroll.status = false;
      } else {
        scroll.status = true;
      }
      scroll.lastValue = currentScroll;
      this.setState({ scroll });
    }, 100);
  }

  surveyListSwitchMobile = (filter) => {
    const { assignedSurveys } = this.props;
    switch (filter) {
      case 0: {
        const list = this.handleSearch(assignedSurveys);
        return list.length > 0 ? this.surveyListMobile(list, tra('Tumu'), 0)
          : this.noDataImage();
      }
      case 1: {
        const list = this.handleSearch(assignedSurveys.filter((item) => item.active === 1 && item));
        return list.length > 0 && this.surveyListMobile(list, tra('Devam_Edenler'), 1);
      }
      case 2: {
        const list = this.handleSearch(assignedSurveys.filter((item) => item.active === 0 && item));
        return list.length > 0 && this.surveyListMobile(list, tra('Bitenler'), 2);
      }
      default:
        throw new Error('Undefined surveylist id');
    }
  }

  handleAllPageMobile = (id) => {
    const { surveyAllPageMobile } = this.state;
    surveyAllPageMobile[id] = !surveyAllPageMobile[id];
    this.setState({ surveyAllPageMobile });
  }

  surveyListMobile = (item, title, id) => (
    <SurveyListMobile allPage={Boolean(this.state.surveyAllPageMobile[id])}>
      <div className="header">
        <h4>{title}</h4>
        <CustomButton transparent onClick={() => this.handleAllPageMobile(id)}>{tra('Tumu')} <RightArrow width={14} /></CustomButton>
      </div>
      <DragToScroll>
        <div className="content">
          {
            item && item.map((element) => (
              <Grid item sm={this.state.surveyAllPageMobile[id] ? 10 : 7} xs={this.state.surveyAllPageMobile[id] ? 11 : 10} md={3}>
                {this.surveyCard(element)}
              </Grid>
            ))
          }
        </div>
      </DragToScroll>
    </SurveyListMobile>
  )

  /*
  *   ------------------///  Mobile Methods  ---------------------
  */

  /*
  *   ---------------------  Desktop Methods  ---------------------
  */

  displayDesktop = () => {
    const { surveyCardFilter, seeAll } = this.state;
    const { assignedSurveys, match, applications } = this.props;
    const surveyList = assignedSurveys && assignedSurveys.length > 0 ? this.handleSearch(this.handleSurveys()) : [];
    return (
      <SurveyListPageWrapper>
        <PageWrapper>
          <PageContent>
            <div className="pageInner">
              <GridPageWrapper container spacing={2}>
                <Grid item xs={12}>
                  <div className="header">
                    <div>
                      <h4>{tra('Yanitlanmasi_Gereken_Anketler')}</h4>
                      <div>
                        {Object.keys(match.params).length > 1 && match.params.appId && applications.length > 0 && (
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <h3 style={{ margin: '0px 15px' }}>{applications.find((item) => item.id === parseInt(match.params.appId, 10)).name}</h3>
                            <CustomButton onClick={() => this.props.history.replace('/Surveys')}>{tra('Temizle')}</CustomButton>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="buttonGroup">
                      <CustomButton onClick={() => this.setState({ surveyCardFilter: 0 })} outlined={surveyCardFilter !== 0}>{tra('Tumu')}</CustomButton>
                      <CustomButton onClick={() => this.setState({ surveyCardFilter: 1 })} outlined={surveyCardFilter !== 1}>{tra('Devam_Edenler')}</CustomButton>
                      <CustomButton onClick={() => this.setState({ surveyCardFilter: 2 })} outlined={surveyCardFilter !== 2}>{tra('Bitenler')}</CustomButton>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ width: 'fit-content' }}>
                    <Input endAdornment={<Search />} onChange={(e) => this.setState({ searchText: e.target.value })} type="text" placeholder={tra('Anket_Ara')} />
                  </div>
                </Grid>
                <div className="cardList">
                  {/* <Grid container spacing={3}> */}
                  <Grid container spacing={5}>
                    {
                      assignedSurveys
                        ? (surveyList.length > 0 ? (
                          surveyList.filter((item, index) => (this.state.seeAll ? item : index < 12))
                            .map((item) => (
                              <Grid item xs={3}>
                                {this.surveyCard(item)}
                              </Grid>
                            ))
                        )
                          : this.noDataImage())
                        : this.componentLoader()
                    }
                  </Grid>
                </div>
                {surveyList.length > 12 && (
                  <Grid container>
                    <Grid container justify="flex-end" item xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px' }}>
                        {
                          !seeAll
                            ? (
                              <CustomButton onClick={() => this.setState({ seeAll: true })} transparent>{tra('Tum_Anketleri_Gor')} <RightArrow width={14} /></CustomButton>
                            ) : (
                              <CustomButton onClick={() => this.setState({ seeAll: false })} transparent>{tra('Daralt')} <RightArrow width={14} /></CustomButton>
                            )
                        }
                      </div>
                    </Grid>
                  </Grid>
                )}
              </GridPageWrapper>
            </div>
          </PageContent>
        </PageWrapper>
      </SurveyListPageWrapper>
    );
  }

  handleSurveys = () => {
    const { surveyCardFilter } = this.state;
    const { assignedSurveys, match } = this.props;
    let temp = [];
    const returnData = [];
    if (match.params.appId) {
      for (let i = 0; i < assignedSurveys.length; ++i) {
        if (assignedSurveys[i].SurveyCounts.findIndex((item) => item.id === parseInt(match.params.appId, 10)) > -1) {
          temp.push(assignedSurveys[i]);
        }
      }
    } else {
      temp = assignedSurveys;
    }
    for (let i = 0; i < temp.length; ++i) {
      switch (surveyCardFilter) {
        case 0:
          returnData.push(temp[i]);
          break;
        case 1:
          if (temp[i].active === 1) {
            returnData.push(temp[i]);
          }
          break;
        case 2:
          if (temp[i].active === 0) {
            returnData.push(temp[i]);
          }
          break;
        default:
          break;
      }
    }
    return returnData;
  }

  /*
  *   ------------------///  Desktop Methods  ---------------------
  */

  render() {
    return (
      isWidthDown('sm', this.props.width) ? this.displayMobile() : this.displayDesktop()
    );
  }
}

Surveys.propTypes = {
  getAssignedSurveys: PropTypes.func.isRequired,
  assignedSurveys: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  getSurveyQuestions: PropTypes.func.isRequired,
  setAssignedSurveys: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  applications: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  assignedSurveys: selectAssignedSurveys(),
  applications: selectApplications(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getAssignedSurveys,
      getSurveyQuestions,
      setAssignedSurveys,
    }, dispatch)
  )
);

const SurveysWithTheme = withTheme(Surveys);
const SurveysWithWidth = withWidth()(SurveysWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(SurveysWithWidth);

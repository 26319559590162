import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Skeleton } from '@material-ui/lab';
import {
  Colors,
} from '../../assets/statics';
import Input from '../Input';
import {
  CustomButton, BottomSheetWrapper, InputWrapper, CustomModal, ModalHeader, ModalContent,
} from '../../assets/styledNew';
import BottomSheet from '../BottomSheet';
import { tra } from '../../commons/utils';
import { createIntegration, getIntegrationComponents, setToast } from '../../store/actions';
import { selectIntegrationComponents, selectResponseState } from '../../store/selectors';
import responseType from '../../commons/enum/responseType';

class NewIntegrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        yeniEntegrasyon: tra('Yeni_Entegrasyon'),
        gonderenBileseni: tra('Gonderen_Bileseni'),
        gonderenIsOrtagi: tra('Gonderen_Is_Ortagi'),
        gonderenArayuzu: tra('Gonderen_Arayuzu'),
        aliciBileseni: tra('Alici_Bileseni'),
        aliciIsOrtagi: tra('Alici_Is_Ortagi'),
        aliciArayuzu: tra('Alici_Arayuzu'),
      },
    };
  }

  componentDidUpdate(nextProps) {
    if (!this.props.integrationComponents?.components?.length && nextProps.open !== this.props.open) {
      this.props.getIntegrationComponents();
    }
  }

  newIntegration = () => {
    const {
      senderComponent_id,
      sender_interface,
      sender_namespace,
      receiverComponent_id,
      receiver_interface,
      receiver_namespace,
    } = this.state;
    if (
      senderComponent_id
      && sender_interface
      && sender_namespace
      && receiverComponent_id
      && receiver_interface
      && receiver_namespace
    ) {
      const data = {
        senderComponent_id: senderComponent_id.id,
        sender_interface,
        sender_namespace,
        receiverComponent_id: receiverComponent_id.id,
        receiver_interface,
        receiver_namespace,
      };
      this.props.createIntegration(data);
      this.setState({
        senderComponent_id: '',
        sender_interface: '',
        sender_namespace: '',
        receiverComponent_id: '',
        receiver_interface: '',
        receiver_namespace: '',
      });
      this.props.close();
    } else {
      this.props.setToast(true, tra('tum_alanlar_doldurun'), 'warning');
    }
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;

    const { integrationComponents } = this.props;
    const { lang } = this.state;
    return (
      <ContentWrapper className="content">
        <ModalHeader>
          <h1>{lang.yeniEntegrasyon}</h1>
        </ModalHeader>
        <ModalContent style={{ overflow: 'hidden', padding: '20px 0px' }}>
          {this.props.integrationComponentResponse === responseType.LOADING
            ? (
              <div style={{ display: 'grid', gap: '10px' }}>
                {[...Array(6).keys()].map(() => <Skeleton variant="rect" height={30} />)}
                <Skeleton variant="rect" height={30} style={{ width: '40%', marginLeft: 'auto' }} />
              </div>
            )
            : (
              <InputWrapper>
                <Input
                  title={lang.gonderenBileseni}
                  type="autoComplete"
                  onChange={(e, newValue) => this.setState({ senderComponent_id: newValue })}
                  data={integrationComponents.components && integrationComponents.components.filter((c) => c.id !== 0)}
                  value={this.state.senderComponent_id}
                  multiple={false}
                />
                <Input
                  title={lang.gonderenIsOrtagi}
                  type="text"
                  onChange={(e) => this.setState({ sender_namespace: e.target.value })}
                  value={this.state.sender_namespace}
                />
                <Input
                  title={lang.gonderenArayuzu}
                  type="text"
                  onChange={(e) => this.setState({ sender_interface: e.target.value })}
                  value={this.state.sender_interface}
                />
                <Input
                  title={lang.aliciBileseni}
                  type="autoComplete"
                  onChange={(e, newValue) => this.setState({ receiverComponent_id: newValue })}
                  data={integrationComponents.components && integrationComponents.components.filter((c) => c.id !== 0)}
                  value={this.state.receiverComponent_id}
                  multiple={false}
                />
                <Input
                  title={lang.aliciIsOrtagi}
                  type="text"
                  onChange={(e) => this.setState({ receiver_namespace: e.target.value })}
                  value={this.state.receiver_namespace}
                />
                <Input
                  title={lang.aliciArayuzu}
                  type="text"
                  onChange={(e) => this.setState({ receiver_interface: e.target.value })}
                  value={this.state.receiver_interface}
                />
                <div className="buttonWrapperRight">
                  <CustomButton onClick={() => this.newIntegration()} color={Colors.blue}> {tra('Kaydet')}  </CustomButton>
                </div>
              </InputWrapper>
            )}
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

NewIntegrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  createIntegration: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  integrationComponents: PropTypes.array.isRequired,
  setToast: PropTypes.func.isRequired,
  getIntegrationComponents: PropTypes.func.isRequired,
  integrationComponentResponse: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  integrationComponents: selectIntegrationComponents(),
  integrationComponentResponse: selectResponseState(getIntegrationComponents().type),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ...
      setToast,
      createIntegration,
      getIntegrationComponents,
    }, dispatch)
  )
);

const NewIntegrationModalWithWidth = withWidth()(NewIntegrationModal);

export default connect(mapStateToProps, mapDispatchToProps)(NewIntegrationModalWithWidth);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Tooltip, Zoom } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withTheme } from 'styled-components';
import { SidebarWrapper, SidebarMenuItem, MenuActive } from '../assets/styledNew';
import {
  Home, Cloud, Settings, Exit, Logo, File, IntegrationIcon, Notification, HardwareIcon, Coin, BarGraph, LicenseFollowIcon, // Calendar,
} from '../assets/icons';
import { Colors } from '../assets/statics';
import { setLoginState } from '../store/actions';
import { selectActiveUser, selectUserModule } from '../store/selectors';
import { isUserModule, tra } from '../commons/utils';
import moduleType from '../commons/enum/moduleType';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 19px ${Colors.shadow};`,
    fontSize: 12,
    color: Colors.darkBlue,
    fontWeight: 600,
    padding: 15,
    fontFamily: 'Inter',
  },
  arrow: {
    color: Colors.white,
  },
}))(Tooltip);
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePath: '',
      // anchorEl: null,
      lang: {
        genelBakis: tra('Genel_Bakis'),
        envanter: tra('Envanter'),
        raporlar: tra('Raporlar'),
        takvim: tra('Takvim'),
        bildirimler: tra('Bildirimler'),
        anketler: tra('Anketler'),
        ayarlar: tra('Ayarlar'),
        koyuMod: tra('Koyu_Moda_Gec'),
        secilenDil: tra('Secilen_Dil'),
        cikisYap: tra('Cikis_Yap'),
        entegrasyon: tra('Entegrasyon'),
        donanim: tra('Donanim'),
        masraf: tra('Masraf'),
        lisansTakibi: tra('Lisans_Takibi'),
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.location.pathname !== prevState.activePath) {
      const propsPath = nextProps.history.location.pathname;
      const firstPath = propsPath.indexOf('/', 1) !== -1 ? propsPath.substring(0, propsPath.indexOf('/', 1)) : propsPath;
      return {
        activePath: firstPath,
      };
    }
    return null;
  }

  isUserModuleFunc = (modulTypeArr) => isUserModule(modulTypeArr)(this.props.initModule);

  logout = () => {
    localStorage.removeItem('token');
    this.props.setLoginState(true);
  }

  changeLang = () => {
    const lang = localStorage.getItem('language');
    if (lang === 'en') {
      localStorage.setItem('language', 'tr');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

  render() {
    const { activePath, lang } = this.state;
    const { user } = this.props;
    const themeColor = this.props.theme;
    const activeComp = localStorage.getItem('company');
    const selectedComp = Object.keys(user).length > 0 && user.companies && user.companies.find((item) => item.id.toString() === activeComp);
    const isAdmin = selectedComp !== undefined ? (selectedComp.permission === 1) : false;
    const menus = [
      {
        title: lang.genelBakis,
        icon: (pathName) => (<Home color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/',
      },
      {
        title: lang.envanter,
        icon: (pathName) => (<Cloud color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Inventories',
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        title: lang.masraf,
        icon: (pathName) => (<Coin color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Costing',
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE) && !this.isUserModuleFunc(moduleType.HARDWARE),
      },
      {
        title: lang.donanim,
        icon: (pathName) => (<HardwareIcon color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/HardwareInventories',
        disabled: !this.isUserModuleFunc(moduleType.HARDWARE),
      },
      {
        title: lang.raporlar,
        icon: (pathName) => (<BarGraph color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Reports',
        disabled: !this.isUserModuleFunc(moduleType.HARDWARE) && !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        title: lang.entegrasyon,
        icon: (pathName) => (<IntegrationIcon color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Integration',
        disabled: !this.isUserModuleFunc(moduleType.INTEGRATION),
      },
      // {
      //   title: lang.takvim,
      //   icon: (pathName) => (<Calendar color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
      //   link: '/Schedule',
      //   disabled: true,
      // },
      {
        title: lang.bildirimler,
        icon: (pathName) => (<Notification color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Notification',
      },
      {
        title: lang.lisansTakibi,
        icon: (pathName) => (<LicenseFollowIcon color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/LicenseFollow',
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
      {
        title: lang.anketler,
        icon: (pathName) => (<File color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Surveys',
        disabled: !this.isUserModuleFunc(moduleType.SOFTWARE),
      },
    ];
    if (isAdmin) {
      menus.push({
        title: lang.ayarlar,
        icon: (pathName) => (<Settings color={activePath === pathName ? themeColor.sidebarActiveIcon : themeColor.sidebarIcon} />),
        link: '/Admin',
      });
    }
    return (
      <SidebarWrapper>
        <div className="header">
          <Link to="/"><Logo /></Link>
        </div>
        <div className="menu">
          <MenuActive index={menus.findIndex((d) => d.link.substring(0, 5) === activePath.substring(0, 5))} />
          {
            menus.map((m, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <LightTooltip key={`${i}sidebar`} placement="left" arrow title={m.disabled ? tra('Premium_Mesaji') : m.title} TransitionComponent={Zoom}>
                <SidebarMenuItem to={m.disabled ? null : m.link} active={m.disabled}>
                  {m.icon(m.link)}
                </SidebarMenuItem>
              </LightTooltip>
            ))
          }
        </div>
        {
          this.props.user.companies && this.props.user.companies.find((c) => c.id.toString() === localStorage.getItem('company')) && (
            <LightTooltip placement="left" arrow title={this.props.user.companies.find((c) => c.id.toString() === localStorage.getItem('company')).name} TransitionComponent={Zoom}>
              <img
                className="companyLogo"
                src={`${this.props.user.companies.find((c) => c.id.toString() === localStorage.getItem('company')).company_image}v?${new Date().getTime()}`}
                alt={this.props.user.companies.find((c) => c.id.toString() === localStorage.getItem('company')).name}
              />
            </LightTooltip>
          )
        }
        <div className="footer">
          <LightTooltip placement="left" arrow title={lang.cikisYap} TransitionComponent={Zoom}>
            <Link to="/" onClick={() => this.logout()}>
              <Exit color={Colors.white} />
            </Link>
          </LightTooltip>
        </div>
      </SidebarWrapper>
    );
  }
}

Sidebar.propTypes = {
  // toggle: PropTypes.func.isRequired,
  setLoginState: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initModule: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectActiveUser(),
  initModule: selectUserModule(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setLoginState,
    }, dispatch)
  )
);

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(Sidebar);

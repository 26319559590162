import { ResponsiveChoropleth } from '@nivo/geo';
import React from 'react';
import PropsTypes from 'prop-types';
import countries from '../../assets/world_countries.json';

export const WorldMapReportChart = ({ data, maxValue }) => (
  <ResponsiveChoropleth
    data={data}
    features={countries.features}
    margin={{
      top: 0, right: 0, bottom: 0, left: 0,
    }}
    colors="blues"
    domain={[0, maxValue]}
    unknownColor="#8898AA"
    label="properties.name"
    valueFormat=".2s"
    projectionTranslation={[0.5, 0.75]}
    projectionScale={80}
    projectionRotation={[0, 0, 0]}
    graticuleLineColor="#dddddd"
    borderWidth={0.3}
    borderColor="#152538"
    css
  />
);

WorldMapReportChart.propTypes = {
  data: PropsTypes.array.isRequired,
  maxValue: PropsTypes.array.isRequired,
};

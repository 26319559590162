/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Tooltip } from '@material-ui/core';
import { LifecycleWrapper, NoData } from '../assets/styledNew';
import { Colors, monthList } from '../assets/statics';
import { tra, parseByMentions, getPostDateStr } from '../commons/utils';
import {
  // Profil,
  Plus,
  // Star,
  // Key,
  SendTime,
} from '../assets/icons';
import NoDataImg from '../assets/img/noData.png';

function diaries(log) {
  // const itemBoxicon = (log.id === '0') ? <Profil width="16" /> : <Key width="16" />;
  // const itemIcon = (log.id === '0') ? <span style={{ background: Colors.purple }}> <Plus width="10" /></span>
  //   : <span style={{ background: Colors.lightGreen }}> <Star width="10" /></span>;
  return (
    <div className="column">
      <div className="dateDetail">
        <div className="dateCardArea">
          <div className="day">{new Date(log._id).getDate()}</div>
          <div className="date">
            <p><strong>{monthList.find((m) => m.id === new Date(log._id).getMonth()) && monthList.find((m) => m.id === new Date(log._id).getMonth()).sort}</strong> {new Date(log._id).getFullYear()}</p>
          </div>
          {/* <p className="dateSince">{getPostDateStr(new Date(log._id))}</p> */}
        </div>
        {/* <div className="itemGroup">
          <p>header</p>
          <div className="itemBox">
            <div className="itemIcon">{itemBoxicon}</div>
            <span>info box</span>
          </div>
        </div> */}
        {
          log.updates && log.updates.map((msg) => (
            <Tooltip arrow title={new Date(log._id).toLocaleString()} placement="right">
              <div className="item">
                <span style={{ background: Colors.purple }}> <Plus width="10" /></span>
                <div dangerouslySetInnerHTML={{ __html: parseByMentions(msg.text) }} />
              </div>
            </Tooltip>
          ))
        }
        {/* {
          log.updates ? log.updates.map((item) => (
            item.messages.map((msg) => (
              <Tooltip arrow title={new Date(item.created_at).toLocaleString()} placement="right">
                <div className="item">
                  <span style={{ background: Colors.purple }}> <Plus width="10" /></span>
                  <div dangerouslySetInnerHTML={{ __html: parseByMentions(msg) }} />
                </div>
              </Tooltip>
            ))
          )) : log.map((msg) => (
            <Tooltip arrow title={new Date(log._id).toLocaleString()} placement="right">
              <div className="item">
                <span style={{ background: Colors.purple }}> <Plus width="10" /></span>
                <div dangerouslySetInnerHTML={{ __html: parseByMentions(msg) }} />
              </div>
            </Tooltip>
          ))
        } */}
      </div>
    </div>
  );
}

const Logs = ({ data }) => (
  <LifecycleWrapper>
    <div className="content">
      <p>{tra('Yasam_Dongusu')}</p>
      <div className="container">
        {
          data ? (
            data.length > 0 ? (
              data.map((item) => diaries(item))
            ) : (
              <NoData>
                <img src={NoDataImg} alt="No Data" />
                <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
              </NoData>
            )) : (
            [1, 2, 3, 4].map(() => (
              <div className="column">
                <div className="date">
                  <p><Skeleton variant="text" animation="wave" width={70} height={35} /></p>
                </div>
                <div className="dateDetail">
                  <div className="dateCardArea">
                    <div className="day" style={{ background: 'none' }}><Skeleton variant="circle" animation="wave" width={40} height={40} /></div>
                    <p><Skeleton variant="text" animation="wave" width={20} height={20} /></p>
                  </div>
                  <div className="item">
                    <span style={{ background: Colors.purple }}><Plus width="10" /></span>
                    <p><Skeleton variant="text" animation="wave" width={250} height={15} style={{ borderRadius: 0 }} /></p>
                  </div>
                </div>
              </div>
            ))
          )
        }
      </div>
      {/* <div className="footer"><CustomButton>{tra('Daha_Fazla_Goster')}</CustomButton></div> */}
    </div>
    <div className="icon" style={{ background: Colors.blue }}>
      <SendTime color={Colors.white} />
    </div>
  </LifecycleWrapper>
);

Logs.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Logs;

import styled from 'styled-components';
import { FormControl, MenuItem, TextField } from '@material-ui/core';
import { Colors } from './statics';
import { hexToRgbA } from '../commons/utils';

export const CustomFormControl = styled(FormControl)`
  ${(props) => !props.noSpace && 'margin: 17.5px 0px'};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  flex-direction: row;
  align-items: center;
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
  }
  > label {
    font-size: 18px;
    color: ${(props) => props.theme.text1};
    // font-weight: 500;
  }
  .MuiAutocomplete-tag {
    color: ${Colors.blue};
    margin: 0 3px;
    border-radius:10px;
    background: ${Colors.lightPurple300};
    .MuiChip-deleteIcon{
      fill: ${hexToRgbA(Colors.blue, 0.3)};
      :hover {
        fill: ${hexToRgbA(Colors.blue, 0.6)};
        }
    }
  }
  .MuiInputBase-root {
    color: ${(props) => props.theme.text2};
    border-radius: 6px;
    background: ${(props) => props.theme.inputBackg};
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    // transform: translate(15px, -25px) scale(0.75);
    transform: translate(0px, -20px) scale(0.75);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.inputBorder};
    border-width: 1px;
    border-style: solid;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.inputBorder};
  }
  .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.inputBorder};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.inputBorder} !important;
  }
  ${(props) => (props.error && `
    .MuiOutlinedInput-notchedOutline {
      border-color: ${Colors.red}!important;
    }
  `)}

 .MuiOutlinedInput-input {
    padding: ${(props) => (props.row ? '0px' : '10px')};
  }
  .MuiAutocomplete-inputRoot {
    // padding: 4.5px;
    padding: unset !important;
    .MuiAutocomplete-input {
      padding: 9.5px;
    }
  }
  .MuiChip-root.Mui-disabled {
    opacity: 1;
    .MuiChip-deleteIcon {
      display: none;
    }
  }
  .MuiIconButton-root {
    // color: ${(props) => props.theme.text1} !important;
  }
  .MuiAutocomplete-root {
    width: 100%;
  }
  .numberPlusCons {
    .MuiOutlinedInput-input {
      text-align: center;
    }
  }
  .indicate {
    color: ${(props) => props.theme.redText}
  }
  .info {
    display: none;
    width: 40px;
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.menuGrey};
      :hover {
        color: ${(props) => props.theme.widgetIconBackg};
      }
    }
    svg {
      width: 40px;
    }
    ${(props) => !props.info && `
      svg {
        display: none;
      }
    `}
  }
`;

export const CustomFileText = styled(TextField)`
  ${(props) => props.disabled && `
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
      border-width: 1px;
      border-style: solid;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
    .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
  `}
`;

export const CustomMenuItem = styled(MenuItem)`
  ${(props) => props.active && `
    color: ${Colors.blue};
    svg {
      path {
        fill: ${Colors.blue};
      }
      circle {
        fill: ${Colors.blue};
      }
      rect {
        fill: ${Colors.blue};
      }
    }
  `}
`;

export const CustomIconTextField = styled(TextField)`
    .MuiSelect-icon {
      color: ${(props) => props.theme.text1};
    }
`;

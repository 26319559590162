/* eslint-disable no-restricted-syntax */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import Input from '../Input';
import {
  selectAppConfig, selectApplications, selectHardwareDevices, selectInfos, selectUsers,
} from '../../store/selectors';
import Validator from '../../commons/validator';
import { HardwareContext } from '../../commons/context/HardwareContext';
import { ResolvePathObject, HardwareGroupValidateCheck } from '../../commons/utils';
import InputOnChange from './helpers/InputOnChange';

const InputTypeEnum = {
  CHECKBOX: 'checkbox',
  AUTOCOMPLETE: 'autoComplete',
  CURRENCY: 'currency',
  NUMBER: 'number',
  TEL: 'tel',
  TEXT: 'text',
  SELECT: 'select',
  DATE: 'date',
};

class InputRender extends PureComponent {
    // eslint-disable-next-line react/static-property-placement
    static contextType=HardwareContext

    /**
     * pathData       : context verilerin setlenmesi için belirlenen path
     * pathDataDefined: değişen alanların saklanmasında yardımcı path
     * validateName   : validasyon isimleri tutan path
     * InputOnChange  : inputların onChange durumlarını yöneten class
     */
    constructor(props, context) {
      super(props);
      this.state = {
        value: null,
        // Telefon alanı için gerekli
        phone_code: '',
      };
      this.inputOnChange = new InputOnChange({
        context,
        reactComp: this,
        inputProps: this.props,
      });
      const {
        objectGroupName, addGroupName, addGroupIndex, inputObject,
      } = this.props;
      this.pathData = [objectGroupName, addGroupName, addGroupIndex, inputObject.name].filter((i, index, arr) => i || (arr[1] && Number.isInteger(i))).join('.');
      this.pathDataTelCode = [objectGroupName, addGroupName, addGroupIndex, 'phone_code'].filter((i, index, arr) => i || (arr[1] && Number.isInteger(i))).join('.');
      this.pathDataDefined = inputObject.setDefinedName ? [objectGroupName, addGroupName, addGroupIndex, inputObject.setDefinedName].filter((i, index, arr) => i || (arr[1] && Number.isInteger(i))).join('.') : false;
      this.validateName = inputObject.validateRules ? [addGroupName, objectGroupName, inputObject.name, addGroupIndex].filter((i) => i || Number.isInteger(i)).join('.') : false;
    }

    componentDidMount() {
      const { inputObject } = this.props;
      this.setContextValue();
      if (inputObject.validateRules && (inputObject.definedName ? this.context.setContextDefinedControl(inputObject.definedName) : true)) this.context.setContextPageValidate(this.props.pagesNumber, this.validateName);
    }

    componentWillUnmount() {
      const { inputObject } = this.props;
      if (inputObject.validateRules) { this.context.setContextPageValidate(this.props.pagesNumber, this.validateName, true); }
    }

    /**
     *  Parent Comp. render olmasında kaynaklı InputRender Comp. saklanan stateler siliniyor bu nedenle didMount methoduyla silinen stateleri context üzerinden tekrar isteniyor.
     */
    setContextValue=() => {
      const { ServiceData, ScreenData, SubmitData } = this.context;
      const { type } = this.props.inputObject;
      let { value, phone_code } = this.state;
      switch (type) {
        case InputTypeEnum.CHECKBOX:
          value = this.nullCheckControlArr([ScreenData, ServiceData], { control: [null, undefined] });
          break;
        case InputTypeEnum.AUTOCOMPLETE:
        case InputTypeEnum.CURRENCY:
        case InputTypeEnum.NUMBER:
        case InputTypeEnum.TEXT:
        case InputTypeEnum.SELECT:
        case InputTypeEnum.DATE:
          value = this.nullCheckControlArr([ScreenData, SubmitData, ServiceData]);
          break;
        case InputTypeEnum.TEL:
          value = this.nullCheckControlArr([ScreenData, SubmitData, ServiceData]);
          if (this.pathDataTelCode) {
            const phone_code_id = this.nullCheckControlArr([ScreenData, SubmitData, ServiceData], { pathData: this.pathDataTelCode });
            phone_code = this.props.locations.filter((i) => i.dial_code === phone_code_id)?.[0] ?? '';
          }
          break;
        default: throw new Error('Hatalı Atama');
      }
      this.setState({ value, phone_code }, () => this.setChaningFields());
    }

 nullCheckControlArr=(arr, optionsProps) => {
   const options = {
     control: [undefined, null, false],
     pathData: this.pathData,
     ...optionsProps,
   };

   let value = null;
   const { addGroupName } = this.props;
   const { SubmitData } = this.context;

   if (SubmitData[addGroupName] && SubmitData[addGroupName].length === 0) return null;

   for (let index = 0; index < arr.length; index++) {
     const element = arr[index];
     if (!options.control.some((i) => i === ResolvePathObject(element, options.pathData))) {
       value = ResolvePathObject(element, options.pathData);
       break;
     }
   }
   return value;
 }

 /**
    * Koşullara bağlı alanların context setlendiği yer örn: checkbox alanların başka bir inputu açıp kapatması
    */
   setChaningFields = () => {
     const { changingFields } = this.context;
     let { value } = this.state;
     const { type } = this.props.inputObject;
     if (this.pathDataDefined) {
       switch (type) {
         case InputTypeEnum.CHECKBOX:
           if (value !== ResolvePathObject(changingFields, this.pathDataDefined)) {
             value = value || false;
             this.context.setContextChangingFields({ value, path: this.pathDataDefined });
           }
           break;

         case InputTypeEnum.AUTOCOMPLETE:
           if (Array.isArray(value) && JSON.stringify(value.map((i) => i.id)) !== JSON.stringify(ResolvePathObject(changingFields, this.pathDataDefined))) {
             this.context.setContextChangingFields({ value: value.map((i) => i.id), path: this.pathDataDefined });
           }
           break;

         case InputTypeEnum.CURRENCY:
         case InputTypeEnum.NUMBER:
         case InputTypeEnum.TEL:
         case InputTypeEnum.TEXT:
         case InputTypeEnum.SELECT:
         case InputTypeEnum.DATE:
           if (value !== ResolvePathObject(changingFields, this.pathDataDefined)) {
             this.context.setContextChangingFields({ value, path: this.pathDataDefined });
           }
           break;
         default: throw new Error('return value is empty');
       }
     }
   }

    /**
     *
     * @param {Object} payload inputObject input için gerekli bilgiler bunun içinde dönüyor
     * @returns {Object} select ve autoComplete fieldların data propslarını setliyor
     */
    handleSelectDataProps = (payload) => {
      if (payload.noService) return payload.data;
      return (['select', 'autoComplete', 'tel'].some((item) => item === payload.type)
        && this.props[payload.data]
        && this.props[payload.data] !== undefined
          && this.props[payload.data]);
    }

    /**
     *
     * @param {*} e
     * @param {Object} value autocomplete field içindeki değeri dönüyor
     * @param {*} reason autocomplete seçilen item tipini dönüyor 'remove-item' || 'select-item'
     * @param {Object} details autoComplete alanlarda seçilen item dönüyor
     * @returns {*} field üzerinde değişen alanları this.context.SubmitData &&  this.context.ScreenData setliyor
     */
    handleSwitchOnChange = (e, value, reason, details) => {
      const { type } = this.props.inputObject;
      switch (type) {
        case InputTypeEnum.CHECKBOX: return this.inputOnChange.checkBox(e.target.checked);
        case InputTypeEnum.AUTOCOMPLETE: return this.inputOnChange.autoComplete(e, value, reason, details);
        case InputTypeEnum.CURRENCY: return this.inputOnChange.number(e.target.value);
        case InputTypeEnum.NUMBER: return this.inputOnChange.number(e.target.value);
        case InputTypeEnum.TEL: return this.inputOnChange.tel(e.target.value);
        case InputTypeEnum.TEXT: return this.inputOnChange.text(e.target.value);
        case InputTypeEnum.SELECT: return this.inputOnChange.text(e.target.value);
        case InputTypeEnum.DATE: return this.inputOnChange.date(e);
        default: return 'error type';
      }
    }

    handleTelCodeOnChange = (value) => {
      this.inputOnChange.telCode(value);
      // this.setState({ phone_code: value });
    }

    /**
     * Birbirlerine bağlı grup alanların kontrollerini sağlayan yardımcı fonksiyon.
     * @returns {boolean} validate check
     */
    groupControl_ValidatorCheck=() => {
      const { inputObject, validateErrMessageTitle } = this.props;
      return Validator.check(
        this.state.value,
        inputObject.validateRules.concat(':', validateErrMessageTitle, ',', this.hardwareGroupValidateCheckFunc()),
      );
    }

    /**
     * @private {Object} context SumbitData || ServiceData
     * @returns {boolean} group alanlarda servis ya da servise giden dataları koşullarını kontrol ediyor
     */
    hardwareGroupValidateCheckFunc=(SubmitData = this.context.SubmitData) => {
      const {
        addGroupIndex, addGroupName, groupValidateCheckLength,
      } = this.props;
      return HardwareGroupValidateCheck({
        addGroupIndex, addGroupName, groupValidateCheckLength, SubmitData,
      });
    }

    /**
     *
     * @returns {Boolean||Object} validasyonlu fieldlar burada  setleniyor.
     */
    setValidateName=() => {
      const { inputObject, validateErrMessageTitle } = this.props;
      const { SubmitData, ServiceData } = this.context;
      const { value } = this.state;
      if (inputObject.validateRules && (inputObject.definedName ? this.context.setContextDefinedControl(inputObject.definedName) : true)) {
        switch (true) {
          case inputObject.validateRules?.includes('ThanNumber'):
          { const [validateName, validateFieldName, validateMessage] = inputObject.validateRules.split(/:|\|/);
            const validateControlData = ResolvePathObject(SubmitData, validateFieldName) || ResolvePathObject(ServiceData, validateFieldName);
            return Validator.message(this.validateName, value, `${validateName}:${validateControlData},${validateMessage}`);
          }

          case inputObject.validateRules?.includes('groupInputControl'):
            return Validator.message(this.validateName, value, inputObject.validateRules.concat(':', validateErrMessageTitle, ',', this.hardwareGroupValidateCheckFunc()));

          default: return Validator.message(this.validateName, value, inputObject.validateRules);
        }
      }
      return false;
    }

    /**
     *  GroupValidasyonlarda mesajların gizlenmesi için onBlur fonksiyonu çalışıyor
     */
    handleOnBlur= () => {
      const {
        inputObject,
      } = this.props;
      if (inputObject.validateRules) {
        switch (true) {
          case inputObject.validateRules?.includes('groupInputControl') && this.groupControl_ValidatorCheck():
            Validator.hideMessageFor(this.validateName);
            this.props.accordionCheckValidate(this.groupControl_ValidatorCheck());
            break;

          default:
            break;
        }
      }
    }

    render() {
      const { inputObject, appConfig } = this.props;
      const { disabled } = appConfig;
      const { isHardwareDetail } = this.context;
      return inputObject !== undefined && (!inputObject.definedName || this.context.setContextDefinedControl(inputObject.definedName)) && (
      <Input
        type={inputObject.type}
        title={inputObject.title}
        value={this.state.value}
        onChangeTelCode={(e, newValue) => this.handleTelCodeOnChange(newValue)}
        telCode={this.state.phone_code}
        onChange={(e, value, reason, details) => this.handleSwitchOnChange(e, value, reason, details)}
        error={this.setValidateName()}
        helperText={this.setValidateName()}
        data={this.handleSelectDataProps(inputObject)}
        disabled={isHardwareDetail ? disabled : false}
        plusClick={() => inputObject.type === 'number' && this.inputOnChange.quantityButton({ count: 1, type: 'plus' })}
        minusClick={() => inputObject.type === 'number' && this.inputOnChange.quantityButton({ count: 1, type: 'minus' })}
        indicate={inputObject.validateRules && inputObject.validateRules.includes('required')}
        onBlur={() => this.handleOnBlur()}
        {...inputObject.inputProps}
      />
      );
    }
}

InputRender.propTypes = {
  departments: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  producers: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  createHardware: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  apps: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,

  inputObject: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired,
  objectGroupName: PropTypes.string.isRequired,
  addGroupIndex: PropTypes.number.isRequired,
  addGroupName: PropTypes.string.isRequired,
  pagesNumber: PropTypes.number.isRequired,
  setLoading: PropTypes.func.isRequired,
  validateErrMessageTitle: PropTypes.string.isRequired,
  groupValidateCheckLength: PropTypes.array.isRequired,
  accordionCheckValidate: PropTypes.func.isRequired,
  validateGroupNameArray: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  departments: selectInfos('Departments'),
  locations: selectInfos('Locations'),
  currencies: selectInfos('Currencies'),
  producers: selectInfos('HardwareProducers'),
  memories: selectInfos('MemoryTypes'),
  situations: selectInfos('Situations'),
  OSPlatforms: selectInfos('OSPlatforms'),
  Resolutions: selectInfos('Resolutions'),
  MonitorTypes: selectInfos('MonitorTypes'),
  SocketTypes: selectInfos('SocketTypes'),
  DiskTypes: selectInfos('DiskTypes'),
  DiskSizes: selectInfos('DiskSizes'),
  VMTypePlatforms: selectInfos('VMTypePlatforms'),
  vendors: selectInfos('Vendors'),
  DeviceTypes: selectInfos('DeviceTypes'),
  AntennaTypes: selectInfos('AntennaTypes'),
  HostLocations: selectInfos('HostLocations'),

  users: selectUsers(),
  apps: selectApplications(),
  devices: selectHardwareDevices(),
  appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ...
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(InputRender);

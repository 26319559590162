import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid, Accordion, AccordionSummary, AccordionDetails, Tooltip,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Input from '../../components/Input';
import Table from '../../components/Table';
import {
  CustomButton, CustomDrawer, TableWrapper, PageWrapper, FilteredSidebar, PageContent, ComponentLoader,
} from '../../assets/styledNew';
import {
  LeftArrow,
} from '../../assets/icons';
import {
  FormatCurrency, FormatUTCLocaleDateStr, tra,
} from '../../commons/utils';
import {
  getCosting, setFilterDrawer, getCostingDetail, getCostingCategories, getHWCostingCategories,
  getHWCostingDetail,
} from '../../store/actions';
import {
  selectActiveUser,
  selectCostingConfig,
  selectCostingList, selectFilterDrawer, selectHWCostingList, selectInfos,
} from '../../store/selectors';

class CostingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: JSON.parse(localStorage.getItem('costingfilter')) || [],
      drawer: true,
      compCurrency: '',
    };
    props.getCosting();
    this.hiddenInput = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.compCurrency === '' && props.currencies.length > 0 && props.user && Object.keys(props.user).length > 0) {
      const activeComp = localStorage.getItem('company');
      const selectedComp = props.user.companies && props.user.companies.find((item) => item.id.toString() === activeComp);
      const currency = props.currencies.find((item) => item.id === selectedComp.currency_id)?.symbol;
      return {
        compCurrency: currency,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.setFilterDrawer(false);
  }

  getArrFilters = (itemName, arrayPath, count, countKey) => {
    const { costingList } = this.props;
    const data = [];
    for (let i = 0; i < costingList.length; i++) {
      for (let j = 0; j < costingList[i][arrayPath].length; j++) {
        data.push(costingList[i][arrayPath][j][itemName]);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  }

  changeFilter = (state, value, text, type, label, header) => {
    const { filter } = this.state;
    if (type === 'resetAll') {
      this.setState({ filter: [] });
      const localFilter = JSON.stringify([]);
      localStorage.setItem('costingfilter', localFilter);
    } else {
      const stateName = state.replace(/[0-9]/g, '');
      let oldValue = false;
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state);
        filter.splice(index, 1);
      }
      if (type === 'select') {
        oldValue = this.state.filter.find((item) => item.column === stateName);
      } else {
        oldValue = this.state.filter.find((item) => item.column === stateName && item.value === text);
      }
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value) {
        filter.push({
          column: stateName, value: text, label, header,
        });
      }
      this.setState({
        filter,
        [state]: type === 'select' ? text : value,
      });
      const localFilter = JSON.stringify(filter);
      localStorage.setItem('costingfilter', localFilter);
    }
  }

  render() {
    const {
      costingList, filterDrawer, history, costingConfig, hwCostingList,
    } = this.props;
    const { filter, compCurrency } = this.state;
    const columns = [
      {
        name: 'costing_type',
        text: tra('Masraf_Kategorisi'),
        arrayPath: '',
        sortable: true,
        filterable: false,
        align: 'start',
        type: 'text',
        searchable: true,
      },
      {
        name: 'costing_category',
        text: tra('Masraf_Tipi'),
        editable: false,
        align: 'start',
        sortable: true,
        filterable: true,
        searchable: true,
      },
      {
        name: 'agreement',
        text: tra('Tanim'),
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        searchable: true,
        cellWidth: 400,
        customBody: (value) => (
          <Tooltip title={value}>
            <p style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</p>
          </Tooltip>
        ),
      },
      {
        name: 'service',
        text: tra('Servis'),
        sortable: true,
        filterable: true,
        align: 'start',
        searchable: true,
        cellWidth: 400,
        customBody: (value) => (
          <Tooltip title={value}>
            <p style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</p>
          </Tooltip>
        ),
      },
      {
        name: 'vendor',
        text: tra('Tedarikci'),
        sortable: true,
        filterable: true,
        align: 'start',
        searchable: true,
      },
      {
        name: 'contract_start',
        text: tra('Baslangic_Tarihi'),
        sortable: true,
        filterable: true,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatUTCLocaleDateStr(value)}</p>
        ),
      },
      {
        name: 'contract_end',
        text: tra('Bitis_Tarihi'),
        sortable: true,
        filterable: true,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatUTCLocaleDateStr(value)}</p>
        ),
      },
      {
        name: 'full_period',
        text: tra('Full_Period'),
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
      {
        name: 'total_inv',
        text: `${tra('Cost')} ${compCurrency}`,
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
      // {
      //   name: 'comments',
      //   text: 'Comments',
      //   sortable: true,
      //   type: 'text',
      //   filterable: false,
      //   align: 'start',
      //   searchable: true,
      // },
    ];
    const columnsHW = [
      {
        name: 'cat_name',
        text: tra('Masraf_Kategorisi'),
        arrayPath: '',
        sortable: true,
        filterable: false,
        align: 'start',
        type: 'text',
        searchable: true,
      },
      {
        name: 'type_name',
        text: tra('Masraf_Tipi'),
        editable: false,
        align: 'start',
        sortable: true,
        filterable: true,
        searchable: true,
      },
      {
        name: 'agreement',
        text: tra('Tanim'),
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        searchable: true,
        cellWidth: 400,
        customBody: (value) => (
          <Tooltip title={value}>
            <p style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</p>
          </Tooltip>
        ),
      },
      {
        name: 'contract_start',
        text: tra('Baslangic_Tarihi'),
        sortable: true,
        filterable: true,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatUTCLocaleDateStr(value)}</p>
        ),
      },
      {
        name: 'contract_end',
        text: tra('Bitis_Tarihi'),
        sortable: true,
        filterable: true,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatUTCLocaleDateStr(value)}</p>
        ),
      },
      {
        name: 'full_period',
        text: tra('Full_Period'),
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
      {
        name: 'total_inv',
        text: `${tra('Cost')} ${compCurrency}`,
        sortable: true,
        type: 'text',
        filterable: false,
        align: 'start',
        customBody: (value) => (
          <p>{value && FormatCurrency(value)}</p>
        ),
      },
    ];
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomDrawer
          anchor="left"
          open={filterDrawer}
          {...(!isWidthDown('sm', this.props.width)
            ? { variant: 'persistent' }
            : {
              onClose: () => this.props.setFilterDrawer(false),
              onOpen: () => this.props.setFilterDrawer(true),
            })}
        >
          <FilteredSidebar>
            <div className="title">
              {tra('Filtre')}
              {!isWidthDown('sm', this.props.width)
                && <CustomButton onClick={() => this.props.setFilterDrawer(false)} startIcon={<LeftArrow width={12} />}>{tra('Geri')}</CustomButton>}
            </div>
            <div className="filterContent">
              {
                (costingConfig.costingTypeMenu === 'Software' ? columns : columnsHW).map((item, index) => (
                  item.filterable && (
                    item.type === 'select'
                      ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Input
                              type="select"
                              fullWidth
                              data={item.selectData}
                              value={this.state[item.name]}
                              onChange={(e) => this.changeFilter(item.name, true, e.target.value, 'select', e.target.value, item.text)}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )
                      : item.type === 'array'
                        ? (
                          <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <p>{item.text}</p>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                              {
                                this.getArrFilters(item.name, item.arrayPath).map((arrFilter, count) => (
                                  <Input
                                    type="checkbox"
                                    title={`${arrFilter} (${this.getArrFilters(item.name, item.arrayPath, true, arrFilter)})`}
                                    value={filter.find((a) => a.value === arrFilter) !== undefined}
                                    onChange={(e) => this.changeFilter(`${item.arrayPath}.${item.name}${index + 1 * count + 1}`, e.target.checked, arrFilter, 'checkbox', arrFilter, item.text)}
                                  />
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        )
                        : item.filterLocation
                          ? (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  (costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList) && [...new Set((costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList).map((items) => items[item.name]))].map((cost, count) => (
                                    <Input
                                      type="checkbox"
                                      title={`${this.props[item.filterLocation].find((f) => f.id === cost) && this.props[item.filterLocation].find((f) => f.id === cost).name} (${(costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList).filter((fltr) => fltr[item.name] === cost).length})`}
                                      value={filter.find((a) => (a.column === item.name) && (a.value === cost)) !== undefined}
                                      onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, cost, 'checkbox', this.props[item.filterLocation].find((f) => f.id === cost).name, item.text)}
                                    />
                                  ))
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                          : (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  (costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList) && [...new Set((costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList).map((items) => items[item.name]))].map((cost, count) => (
                                    cost !== null && (
                                      <Input
                                        type="checkbox"
                                        title={(item.name === 'contract_start' || item.name === 'contract_end') ? FormatUTCLocaleDateStr(cost) : cost}
                                        value={filter.find((a) => (a.column === item.name) && (a.value === cost)) !== undefined}
                                        onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, cost, 'checkbox', cost, item.text)}
                                      />
                                    )
                                  ))
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                  )
                ))
              }
            </div>
            <div className="filterDrawefooter">
              {(costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList) && <p><span>{`(${(costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList).length})`}</span> {tra('Envanter_Sayisi')}</p>}
              <CustomButton onClick={() => this.props.setFilterDrawer(false)}>{tra('Uygula')}</CustomButton>
              <CustomButton transparent onClick={() => this.changeFilter(null, null, null, 'resetAll')}>{tra('Sifirla')}</CustomButton>
            </div>
          </FilteredSidebar>
        </CustomDrawer>
        <PageWrapper drawer={!isWidthDown('sm', this.props.width) && filterDrawer}>
          <PageContent>
            <div className="pageInner" style={{ paddingTop: 0 }}>
              <Grid container spacing={3} style={{ paddingTop: 0 }}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                  <TableWrapper style={{ minHeight: '60vh' }}>
                    {
                      (costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList) ? (
                        <Table
                          id="costingTable"
                          data={costingConfig.costingTypeMenu === 'Software' ? costingList : hwCostingList}
                          rowClick={(row) => {
                            const obj = {
                              id: null,
                              tabOpen: false,
                            };
                            localStorage.setItem('costfrominventory', JSON.stringify(obj));
                            history.push(`/CostingDetail/${costingConfig.costingTypeMenu}/${row.id}`);
                            if (costingConfig.costingTypeMenu === 'Software') {
                              this.props.getCostingDetail(row.id);
                            } else {
                              this.props.getHWCostingDetail(row.id);
                            }
                          }}
                          rowColorKey="fc" // rowu boyamak için karşılaştırılacak key
                          rowColorCase={1} // rowu boyamak için karşılaştırılacak keyin eşit olması gereken value
                          columns={costingConfig.costingTypeMenu === 'Software' ? columns : columnsHW}
                          options={{
                            header: {
                              visible: true,
                              columnSetting: true,
                            },
                            filter: {
                              show: true,
                              filters: filter,
                              onChange: this.changeFilter,
                              drawerChange: () => this.props.setFilterDrawer(!filterDrawer),
                            },
                            pagination: {
                              active: true,
                            },
                          }}
                        />
                      ) : (
                        <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
                      )
                    }
                  </TableWrapper>
                </Grid>
              </Grid>
            </div>
          </PageContent>
        </PageWrapper>
      </div>
    );
  }
}

CostingList.propTypes = {
  getCosting: PropTypes.func.isRequired,
  getCostingDetail: PropTypes.func.isRequired,
  costingList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  filterDrawer: PropTypes.bool.isRequired,
  setFilterDrawer: PropTypes.func.isRequired,
  history: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  getCostingCategories: PropTypes.func.isRequired,
  getHWCostingCategories: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  hwCostingList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  costingConfig: PropTypes.object.isRequired,
  getHWCostingDetail: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  categories: selectInfos('Categories'),
  costingList: selectCostingList(),
  hwCostingList: selectHWCostingList(),
  filterDrawer: selectFilterDrawer(),
  currencies: selectInfos('Currencies'),
  user: selectActiveUser(),
  costingConfig: selectCostingConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getCosting,
      setFilterDrawer,
      getCostingDetail,
      getCostingCategories,
      getHWCostingCategories,
      getHWCostingDetail,
    }, dispatch)
  )
);

const CostingListWithWidth = withWidth()(CostingList);

export default connect(mapStateToProps, mapDispatchToProps)(CostingListWithWidth);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import React, {
  useContext, useMemo,
} from 'react';
import { InventoryContext } from '../../commons/context/InventoryContext';
import Input from '../Input';

const InventoryFastInput = (props) => {
  const { InventoryState } = useContext(InventoryContext);
  const val = props.name ? InventoryState[props.name] : props.value;
  return useMemo(() => (
    <Input
      {...props}
      data={props.data}
      error={props.error}
      helperText={props.helperText}
      fullWidth
      value={val}
      onChange={props.onChange}
      InputProps={{
        endAdornment: props.endAdornment,
        startAdornment: props.startAdornment,
      }}
    />
  ), [val, props.data, props.error, props.disabled, props.minDate, props.title]);
};

InventoryFastInput.propTypes = {
};

export default InventoryFastInput;

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ExpandTableTd, ExpandTableTr } from '../../../../assets/styledExpandTable';
import ExpandRowSec from './ExpandRowSec';
import { FormatCurrency } from '../../../../commons/utils';

const ExpandingRow = ({ item, currency }) => {
  const [open, setOpen] = useState(false);
  return useMemo(() => (
    <>
      <ExpandTableTr hover onClick={() => setOpen(!open)}>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.name}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'start' }}>{FormatCurrency(item.total)}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'end' }}>{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTd>
      </ExpandTableTr>
      {open && <br />}
      {item.Users.map((appRowItem) => (
        <>
          <ExpandRowSec item={appRowItem} opened={open} currency={currency} />
          {open && <br />}
        </>
      ))}
    </>
  ), [item.name, item.total, item.Users, open, currency]);
};

ExpandingRow.propTypes = {
  item: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

export default ExpandingRow;

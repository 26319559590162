/* eslint-disable prefer-spread */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Zoom } from '@material-ui/core';
import { ScatterPlot } from '@nivo/scatterplot';
import { withTheme, ThemeContext } from 'styled-components';
import { ScatterPlotWrap } from '../../assets/styledNew';
import { tra } from '../../commons/utils';

class GartnerTime extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
    };
  }

  getProps = () => {
    const ref = this.ref.current;

    let commonProps = {};
    if (ref) {
      commonProps = {
        width: ref.offsetWidth - 20,
        height: ref.offsetHeight - 20,
        margin: {
          top: 15, right: 15, bottom: 25, left: 25,
        },
        nodeSize: 10,
        enableGridY: false,
        enableGridX: false,
        blendMode: this.props.isDarkMode ? 'screen' : 'multiply',
      };
    }
    return commonProps;
  }

  selectColor = (x, y) => {
    const themeContext = useContext(ThemeContext);

    let color = themeContext.white;
    if (y < 2.5 && x < 2.5) {
      color = themeContext.red;
    } else if (y >= 2.5 && x < 2.5) {
      color = themeContext.blue;
    } else if (y < 2.5 && x >= 2.5) {
      color = themeContext.pink;
    } else if (y >= 2.5 && x >= 2.5) {
      color = themeContext.green;
    }
    return color;
  }

  customNode = ({
    node,
    x,
    y,
    // size,
    // color,
    blendMode,
    // onMouseEnter,
    onMouseMove,
    onMouseLeave,
    onClick,
  }) => {
    const maxSize = Math.max.apply(Math, this.props.data.map((o) => (o.business_value > 0 || o.it_quality > 0) && o.total));
    const calcSize = (20 / 100) * (node.data.r / maxSize) * 100;
    return (
      <g transform={`translate(${x},${y})`}>
        {
          node.data.r && node.data.x !== 0 && node.data.y !== 0 && (
            <Tooltip
              title={(
                <div>
                  {/* <p>{tra('Is_Degeri')}: {node.data.x}</p> */}
                  {/* <p>{tra('Bt_Kalitesi')}: {node.data.y}</p> */}
                  <p>{tra('Toplam')}: {node.data.r}</p>
                  {
                    node.data.a && node.data.a.length > 0 && (
                      node.data.a.map((a, i) => (
                        <p>{`${i + 1} - ${a.name}`}</p>
                      ))
                    )
                  }
                </div>
              )}
              placement="top"
              TransitionComponent={Zoom}
            >
              <circle
                r={calcSize}
                fill={this.selectColor(node.data.x, node.data.y)}
                style={{ mixBlendMode: blendMode }}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
              />
            </Tooltip>
          )
        }
      </g>
    );
  }

  prepareData = () => {
    const { data } = this.props;
    const rData = [{ x: 5.5, y: 5.5, r: 0 }];
    // const rData = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        rData.push({
          x: data[i].it_quality,
          y: data[i].business_value,
          r: data[i].total,
          a: data[i].applications,
        });
      }
    }
    return rData;
  }

  render() {
    return (
      <ScatterPlotWrap ref={this.ref}>
        <div className="bgText">{tra('Tolere_Et')}</div>
        <div className="bgText">{tra('Yatirim_Yap')}</div>
        <div className="bgText">{tra('Kaldir')}</div>
        <div className="bgText">{tra('Birlestir')}</div>
        {
          this.ref.current && (
            <ScatterPlot
              {...this.getProps()}
              theme={{
                textColor: this.props.theme.text1,
              }}
              colors={{ scheme: 'set2' }}
              nodeSize={24}
              data={[
                {
                  data: this.prepareData(),
                },
              ]}
              renderNode={(
                node,
                x,
                y,
                size,
                color,
                blendMode,
                onMouseEnter,
                onMouseMove,
                onMouseLeave,
                onClick,
              ) => this.customNode(
                node,
                x,
                y,
                // size,
                // color,
                blendMode,
                onMouseEnter,
                onMouseMove,
                onMouseLeave,
                onClick,
              )}
            />
          )
        }
      </ScatterPlotWrap>
    );
  }
}

GartnerTime.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};
const GartnerTimeWithTheme = withTheme(GartnerTime);
export default GartnerTimeWithTheme;

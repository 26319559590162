import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const deviceDetail = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'StorageDevice',
    inputArray: [
      {
        type: 'text',
        title: tra('Isim'),
        name: 'name', // değişecek
        value: '1920 X 1080',
      },
      // {
      //   type: 'select',
      //   title: tra('Disk_Boyutu'),
      //   name: 'disk_size', // değişecek
      //   value: '1920 X 1080',
      //   data: 'DiskTypes',
      //   inputProps: {
      //     emptyItem: true,
      //   },
      // },
      {
        type: 'text',
        title: tra('Disk_Boyutu'),
        name: 'disk_size', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'text',
        title: tra('Urun_Turu'),
        name: 'product_type', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'text',
        title: tra('Baglanti_Tipi'),
        name: 'connection_type', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
];

export default [[...inventoryPages, deviceDetail]];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from 'styled-components';
import {
  CustomButton,
  CustomModal,
  ModalHeader,
  ModalContent,
  BottomSheetWrapper,
} from '../../assets/styledNew';

import {
  Colors,
} from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';

import { createAdminIntegration, updateAdminIntegration, setResetState } from '../../store/actions';
import { selectInfos } from '../../store/selectors';
import BottomSheet from '../BottomSheet';
import Validator from '../../commons/validator';

class AdminAddUrlIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      auth_name: '',
      auth_password: '',
      name: '',
    };
  }

  componentDidUpdate() {
    if (this.props.resetState) {
      this.resetState();
    }
  }

  handleEntering = () => {
    const { selectIntegration } = this.props;
    if (Object.keys(selectIntegration ?? {})?.length > 0) {
      this.setState({
        url: selectIntegration.url,
        auth_name: selectIntegration.auth_name,
        auth_password: selectIntegration.auth_password,
        name: selectIntegration.name,
      });
    }
  }

  resetState = () => {
    this.setState({
      url: '',
      auth_name: '',
      auth_password: '',
      name: '',
    });
    this.props.setResetState(false);
    Validator.hideMessages();
    this.forceUpdate();
  }

  handleSaveUser = () => {
    const { selectIntegration } = this.props;
    const {
      url, auth_name, auth_password, name,
    } = this.state;

    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      return false;
    }
    const data = {
      url: url?.at(-1) === '/' ? url : `${url}/`,
      auth_name,
      auth_password,
      name,
    };
    if (Object.keys(selectIntegration ?? {})?.length > 0) {
      this.props.updateAdminIntegration(data, selectIntegration?.id);
    } else {
      this.props.createAdminIntegration(data);
    }
    this.props.close();
    return true;
  }

  handleChange = (e, state) => {
    this.setState({ [state]: e.target.value });
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const {
      auth_name, auth_password, url, name,
    } = this.state;
    const { selectIntegration } = this.props;
    Validator.purgeFields();
    return (
      <ContentWrapper>
        <ModalHeader>
          {
            selectIntegration
              ? <h1>{tra('Url_Guncelle')}</h1>
              : <h1>{tra('Yeni_Url_Ekle')}</h1>
          }
          <CloseIcon onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title="URL"
                  value={url}
                  onChange={(e) => this.handleChange(e, 'url')}
                  error={Validator.message('url', url, 'required|min:3')}
                  helperText={Validator.message('url', url, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Adi')}
                  value={name}
                  onChange={(e) => this.handleChange(e, 'name')}
                  error={Validator.message('name', name, 'required|min:3')}
                  helperText={Validator.message('name', name, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Kullanici_Adi')}
                  value={auth_name}
                  onChange={(e) => this.handleChange(e, 'auth_name')}
                  error={Validator.message('auth_name', auth_name, 'required|min:3')}
                  helperText={Validator.message('auth_name', auth_name, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Sifre')}
                  value={auth_password}
                  onChange={(e) => this.handleChange(e, 'auth_password')}
                  error={Validator.message('auth_password', auth_password, 'required')}
                  helperText={Validator.message('auth_password', auth_password, 'required')}
                />
              </Grid>

              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={this.handleSaveUser} color={Colors.blue}>
                  {
                    selectIntegration
                      ? tra('Guncelle')
                      : tra('Kaydet')
                  }
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal onEntering={() => this.handleEntering()} open={this.props.open} onClose={this.props.close} onExit={() => this.resetState()} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AdminAddUrlIntegration.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  selectIntegration: PropTypes.any.isRequired,
  updateAdminIntegration: PropTypes.func.isRequired,
  createAdminIntegration: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  integrationUrl: selectInfos('CompanyPiUrls'),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createAdminIntegration,
      updateAdminIntegration,
      setResetState,
    }, dispatch)
  )
);

const AdminAddUrlIntegrationWithTheme = withTheme(AdminAddUrlIntegration);
const AdminAddUrlIntegrationWithWidth = withWidth()(AdminAddUrlIntegrationWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AdminAddUrlIntegrationWithWidth);

import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const terminal = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'HandheldTerminal',
    inputArray: [
      {
        type: 'text',
        title: tra('Imei'),
        name: 'imei', // değişecek
        value: '1920 X 1080',
        validateRules: 'numeric|min:15',
        inputProps: {
          maxLength: 15,
        },
      },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'select',
        title: tra('Platform'),
        name: 'os_platform_id', // değişecek
        value: 1,
        data: 'OSPlatforms',
        inputProps: {
          emptyItem: true,
        },
      //   validateRules: 'required',
      },
    ],
  }],
];

export default [[...inventoryPages, terminal]];

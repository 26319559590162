/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DialogContent } from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withTheme } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { SendNew } from '../../assets/icons';
import {
  ModalHeader,
  ModalContent,
  PageContent,
  CustomButton,
  CommentWrapper,
  CustomModal,
  MentionBox,
  ComponentLoader,
  NoData,
  BottomSheetWrapper,
} from '../../assets/styledNew';
import { tra, parseByMentions } from '../../commons/utils';
import NoDataImg from '../../assets/img/noData.png';
import BottomSheet from '../BottomSheet';

class InventoryComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: {
        yorumYap: tra('Yorum_Yap'),
        yorumlar: tra('Yorumlar'),
      },
    };
  }

  commentChange = (value) => {
    this.setState({ comment: value });
  }

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { lang, comment } = this.state;
    const {
      open,
      close,
      data,
      users,
      departments,
    } = this.props;
    const userMentionData = this.props.users.map((usr) => ({
      id: usr.id,
      display: `${usr.name}`,
      team: departments.find((d) => d.id === usr.department_id) && departments.find((d) => d.id === usr.department_id).name,
      img: usr.profile_img,
      title: usr.title,
    }));
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{lang.yorumlar}</h1>
          <CloseIcon onClick={close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <CommentWrapper>
            {
              data ? (
                data.length > 0 ? data.map((cmnt) => (
                  <>
                    <div className="headerArea">
                      <div>
                        <div className="image">
                          <img src={users.find((item) => item.id === cmnt.user_id) && users.find((item) => item.id === cmnt.user_id).profile_img} alt="" />
                        </div>
                        <p className="fullname">{users.find((item) => item.id === cmnt.user_id) && users.find((item) => item.id === cmnt.user_id).name}</p>
                      </div>
                      <div>
                        <p className="lastTime">{new Date(cmnt.createdAt).toLocaleDateString()}</p>
                        <div className="title">{users.find((item) => item.id === cmnt.user_id) && users.find((item) => item.id === cmnt.user_id).title}</div>
                      </div>
                    </div>
                    <div className="contentArea" dangerouslySetInnerHTML={{ __html: parseByMentions(cmnt.comment) }} />
                  </>
                )) : (
                  <NoData>
                    <img src={NoDataImg} alt="No Data" />
                    <p>{tra('Gösterilecek_Veri_Bulunmamaktadır')}.</p>
                  </NoData>
                )) : (
                  <ComponentLoader><div className="lds-ellipsis"><div /><div /><div /><div /></div></ComponentLoader>
              )
            }
            <div className="commentForm">
              <MentionsInput
                value={comment}
                onChange={(e) => this.commentChange(e.target.value)}
                allowSpaceInQuery
                className="mention"
              >
                <Mention
                  trigger="@"
                  displayTransform={(id, display) => `${display}`}
                  data={userMentionData}
                  markup="@[__id__] ###__display__###"
                  className="mention"
                  appendSpaceOnAdd
                  renderSuggestion={(entry) => (
                    <MentionBox>
                      <div><img src={entry.img} alt={entry.display} /></div>
                      <div>
                        <span>{entry.title}</span>
                        <strong>{entry.display}</strong>
                        <p>{entry.team} Departmanı</p>
                      </div>
                    </MentionBox>
                  )}
                />
              </MentionsInput>
              <div className="buttonWrapper">
                <CustomButton
                  small
                  fontSize="16"
                  // color={Colors.pink}
                  onClick={() => this.setState({ comment: '' }, () => this.props.doComment(comment))}
                  startIcon={<SendNew width={16} />}
                >
                  {lang.yorumYap}
                </CustomButton>
              </div>
            </div>
          </CommentWrapper>
        </ModalContent>
        <PageContent />
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal open={this.props.open} onClose={this.props.close} maxWidth="sm" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} snapPoint={[500, 0]} isOpen={(open) => open === false && this.props.close()}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

InventoryComment.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  doComment: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

const InventoryCommentWithTheme = withTheme(InventoryComment);
const InventoryCommentWithWidth = withWidth()(InventoryCommentWithTheme);

export default InventoryCommentWithWidth;

/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
// import { set } from 'date-fns';
import { ResolvePathObject } from '../utils';
import { hardwareAddedArea } from '../../assets/hardwareStatic';
// import Validator from '../validator';

export const HardwareContext = React.createContext({});
HardwareContext.displayName = 'HWAContext';

const HardwareRegisterProvider = (props) => {
  const [SubmitData, setSubmitData] = useState({});
  const [ServiceData, setServiceData] = useState({});
  const [PagesValidate, setPageValidate] = useState({});
  const [isHardwareDetail, setHardwareDetailActive] = useState(false);
  const [changingFields, setChangingFields] = useState({});
  const [isFirstClick, setFirstClick] = useState({});
  const [parentCompRender, setParentComponent] = useState(null);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [accordionIndexData, setAccordionIndexData] = useState({});
  const [accordionLengthData, setAccordionLengthData] = useState({});
  const [ScreenData, setScreenData] = useState({});

  useEffect(() => {
    // accordion itemlerin bir kere silinmesini sağlayan yapı
    const tempData = Object.fromEntries(hardwareAddedArea.map((key) => [key, true]));
    setFirstClick(tempData);
    // accordion expanded konumu burada belirliyorum
    const accordionIndexDataTemp = Object.fromEntries(hardwareAddedArea.map((key) => [key, 0]));
    setAccordionIndexData(accordionIndexDataTemp);
  }, []);

  useEffect(() => {
    const accordionIndexLength = Object.fromEntries(hardwareAddedArea.map((key) => [key, ServiceData[key]?.length || 0]));
    setAccordionLengthData(accordionIndexLength);
  }, [ServiceData]);

  useEffect(() => {
    /*
    * Detay sayfasından gelen bilgilerde serverAccessories bilgisini burada setliyorum
    * çünkü bu field girdi çıktı cihazlar sayfasının olup olmamasına karar veriyor.
    */

    if (ServiceData.ServerAccessories) {
      setScreenData({ ServerAccessories: ServiceData.ServerAccessories });
    }
  },
  [ServiceData.ServerAccessories]);

  // Hwa Detail & Hwa Register sayfalarının render olmasını sağlıyor bu sayada sayfadaki her şey render etmiş oluyorum
  const parentReRender = () => setParentComponent(Math.random());

  const definedControl = (name) => {
    /**
     * koşula bağlı inputların sayfada çizilmesine karar veriyor.
     */
    const tempData = [];
    if (Object.keys(changingFields).length !== 0) {
      // eslint-disable-next-line no-restricted-syntax
      Object.keys(changingFields).forEach((keys) => {
        switch (true) {
          case Array.isArray(changingFields[keys]):
            tempData.push(...changingFields[keys].map((i) => [keys, i].join('.')).flat(Infinity));
            break;
          case !Array.isArray(changingFields[keys]) && !helpers.NullCheck(changingFields[keys]):
            Object.keys(changingFields[keys]).forEach((itemKeys) => {
              tempData.push([keys, itemKeys, changingFields[keys]?.[itemKeys]].join('.'));
            });
            break;
          default:
            break;
        }
      });
    }
    return tempData.includes(name) || false;
  };

  /**
   *
   * @param {string} fieldName  Servise gidecek datayı silmem için gerekli isim örn: 'computer' ismine karşılık gelen data örneğinin tipine göre silinmesi Array:[] Object: {is_removed:true , id :[objectİd]}
   * @param {string} definedNamePath silinen alanlar duruma göre eklenip çıkarıldığından dolayı değişen alanları kontrolünü sağladığım chaningField alanına müdahele ediyor hemde ekranda ScreenData bağlı fieldlar içinde ScreenDataya müdahale ediyorum
   */
  const deleteSumbitDataDefined = ({ fieldName = false, definedNamePath, arrayFilterName = 'id' }) => {
    if (isHardwareDetail && ServiceData[fieldName]) {
      SubmitData[fieldName] = Array.isArray(ServiceData[fieldName]) ? [] : { is_removed: true, id: ServiceData[fieldName].id };
    } else {
      delete SubmitData[fieldName];
    }

    if (definedNamePath && definedNamePath.split('.').length > 1) {
      const [definedValue, definedObjectNameKeys, definedObjecParentNameKeys] = definedNamePath.split('.').reverse();
      const chaningResolvePath = [definedObjecParentNameKeys, definedObjectNameKeys].filter((i) => i).join('.');
      // eslint-disable-next-line radix
      if (definedValue && Number.isInteger(Number.parseInt(definedValue)) && definedObjectNameKeys.includes('_defined')) { // value integer olanlar arrayin index demektir.
        const parseDefinedName = definedObjectNameKeys.replace('_defined', '');
        const deleleteArrayIndex = ScreenData[parseDefinedName].findIndex((i) => i[arrayFilterName] === Number(definedValue));
        ScreenData[parseDefinedName].splice(deleleteArrayIndex, 1);
        setScreenData(ScreenData);
      }
      if (!helpers.NullCheck(ResolvePathObject(changingFields, chaningResolvePath))) {
        helpers.SetPathData(changingFields, chaningResolvePath, null);
        setChangingFields(changingFields);
        parentReRender();
      }
    }
  };

  /*
   *   ---------------------  validatePage  ---------------------
   *   sayfa bazlı validasyon kontrollerini burada sağlıyorum.
  */
  const validatePage = (pagesIndex, payload, remove) => {
    if ((!remove && (PagesValidate[pagesIndex] && !PagesValidate[pagesIndex].includes(payload))) || Boolean(!PagesValidate[pagesIndex])) {
      PagesValidate[pagesIndex] = [...PagesValidate[pagesIndex] || [], payload];
    }
    if (remove) {
      const arrIndex = PagesValidate[pagesIndex].findIndex((i) => i === payload);
      PagesValidate[pagesIndex].splice(arrIndex, 1);
    }
    setPageValidate(PagesValidate);
  };
  /*   ---------------------///  validatePage  ---------------------*/

  const submitDataConvert = ({
    objectGroupName, addGroupName, addGroupIndex, value, name,
  }) => {
    switch (true) {
      case Boolean(objectGroupName):
        {
          const id = ServiceData[objectGroupName]?.id;
          SubmitData[objectGroupName] = id
            ? { ...SubmitData[objectGroupName], [name]: value, id }
            : { ...SubmitData[objectGroupName], [name]: value };
        }
        break;

      case Boolean(addGroupName):
        if (!Array.isArray(SubmitData[addGroupName])) {
          SubmitData[addGroupName] = JSON.parse(JSON.stringify(ServiceData[addGroupName] || []));
        }
        SubmitData[addGroupName].splice(addGroupIndex, 1, { ...SubmitData[addGroupName]?.[addGroupIndex], [name]: value });
        break;

      case Boolean(name):

        SubmitData[name] = value;
        break;

      default: throw new Error('SubmitDataConvert undefined');
    }
    setSubmitData(SubmitData);
  };

  const screenDataFunc = ({ path, value }) => {
    helpers.SetPathData(ScreenData, path, value);
    setScreenData(ScreenData);
  };

  /**
   *
   * @param {*} value
   * @param {String} path
   * Example:
   * value:true,path:'a.b.c' => {a:{b:{c:true}}}
   * value:true,path:'a.0.c' => {a:[{c:true}]}
   *
   */

  const setChangingFieldsData = ({ value, path }) => {
    helpers.SetPathData(changingFields, path, value);
    setChangingFields(changingFields);
    setPageValidate({});
    parentReRender();
  };

  // Servise gönderilecek data ile servisten gelen data aynı olduğu durumda servisle aynı olan datalar siliniyor.
  const deleteSubmitdata = ({ removePath }) => {
    helpers.RemovePathData(SubmitData, removePath);
    setSubmitData(SubmitData);
  };

  // Eklenebilir alanlarda silme işleminde kullanıyorum.
  const deleteSubmitDataAddArea = ({ addGroupName, addGroupIndex }) => {
    const isEmptySubmitData = SubmitData[addGroupName] && SubmitData[addGroupName].length === 0;
    if (isFirstClick[addGroupName] && !isEmptySubmitData) {
      const dataTemp = JSON.parse(JSON.stringify(ServiceData[addGroupName]));
      dataTemp.splice(addGroupIndex, 1);
      setSubmitData((state) => ({ ...state, [addGroupName]: dataTemp }));
      setFirstClick((state) => ({ ...state, [addGroupName]: false }));
    }
    if (SubmitData[addGroupName]?.[addGroupIndex]) {
      SubmitData[addGroupName].splice(addGroupIndex, 1);
      setSubmitData(SubmitData);
    }
  };

  // accordion features
  const accordionIndexFunc = ({ name, index }) => setAccordionIndexData((state) => ({ ...state, [name]: index }));
  const accordionLengthFunc = ({ name, index }) => setAccordionLengthData((state) => ({ ...state, [name]: index }));

  const helpers = {
    isCheckID: (data) => Object.keys(data).length === 1 && data?.id,
    isEmptyObject: (obj) => obj && Object.keys(obj).length === 0,

    deepDataFindID: ({ addGroupIndex, addGroupName, objectGroupName }) => {
      if (objectGroupName) return ServiceData[objectGroupName]?.id;
      if (addGroupName) return ServiceData[addGroupName]?.[addGroupIndex]?.id;
      return null;
    },

    SetPathData: (object, path, value) => {
      path
        .split('.')
        .reduce((obj, p, i, arr) => obj[p] = path.split('.').length === ++i ? value : obj[p] || (Number.isInteger(Number(arr[i])) ? [] : {}), object);
      if (object) {
        // eslint-disable-next-line no-restricted-syntax
        for (const keys of Object.keys(object)) {
          if (Array.isArray(object[keys])) object[keys].filter((i) => !helpers.NullCheck(i));
        }
      }
    },

    NullCheck: (value) => [null, undefined].some((i) => i === value),

    RemovePathData: (obj, path) => {
      path.split('.')
        .reduce((reduceObj, pathName, index, arr) => {
          if (reduceObj) {
            if (arr.length - 1 === index) {
              delete reduceObj[pathName];
            }

            return reduceObj[pathName];
          }
          return new Error('RemovePathData undefined item');
        }, obj);

      helpers.isEmptyRemoved(obj);
    },

    isEmptyRemoved: (obj) => {
      if (obj && !Array.isArray(obj)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of Object.keys(obj)) {
          if (((typeof obj[item] === 'object' && 'id' in obj[item]) || false) ? Object.keys(obj[item]).length === 1 : helpers.isEmptyObject(obj[item])) delete obj[item];
          else if (Array.isArray(obj[item])) helpers.isEmptyRemoved(obj[item]);
        }
      } else if (obj && Array.isArray(obj)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const index in obj) {
          if (helpers.isEmptyObject(obj[index])) obj.splice(index, 1);
        }
      }
    },

  };

  const value = {
    SubmitData, // Servise giden data
    ServiceData, // Servise gelen data
    PagesValidate, // Sayfa bazlı validasyona ait inputların name tutuyorum
    isHardwareDetail, // Detay sayfasına özel işlemleri bu alanda koşul atıyorum.
    changingFields, // sayfada değişen alanların yönetimini buradan sağlıyorum.
    pagesNumber,
    accordionIndexData,
    accordionLengthData,
    // tempCheckBox,
    ScreenData,

    setContextServiceData: (payload) => setServiceData({ ...payload }),

    // Servise gidilecek dataları burada hazırlıyorum.
    setContextSubmitData: (payload) => submitDataConvert({ ...payload }),

    // checkBox alanların name ve id geçici olarak tutulan yer
    // setContextCheckBox: (payload) => tempCheckBoxData({ ...payload }),

    setContextScreenData: (payload) => screenDataFunc({ ...payload }),

    // AutoComplete alanların name ve id geçici olarak tutulan yer
    // setContextScreenData: (payload) => ScreenDataData({ ...payload }),

    // AutoComplete alanların name ve id geçici olarak tutulan yer
    setContextChangingFields: (payload) => setChangingFieldsData({ ...payload }),

    // Servise gidilecek dataları burada hazırlıyorum. Update olanlara ID ekliyorum
    // setContextSubmitDataUpdate: (payload) => submitDataConvertUpdate(payload),

    // Detay sayfasında olduğunun kontrolünü sağlıyor
    setContextHardwareDetailToggle: (bool = true) => setHardwareDetailActive(bool),

    // Servise giden dataların hepsini siliyor
    setContexDeleteAllSubmitData: () => {
      const accordionIndexLength = Object.fromEntries(hardwareAddedArea.map((key) => [key, ServiceData[key]?.length || 0]));
      const accordionIndexDataTemp = Object.fromEntries(hardwareAddedArea.map((key) => [key, 0]));
      const tempAutoCompData = ServiceData.ServerAccessories ? { ServerAccessories: ServiceData.ServerAccessories } : { ServerAccessories: [] };
      setSubmitData({});
      setScreenData({});
      setScreenData(tempAutoCompData);
      setAccordionIndexData(accordionIndexDataTemp);
      setAccordionLengthData(accordionIndexLength);
      setParentComponent(Math.random());
    },

    // servise göndereciğim datada sadece ilgili yeri siliyorum.
    setContextDeleteSubmitData: (payload) => deleteSubmitdata(payload),

    // servise giden eklenebilir alanları array objectlerini siliyor.
    setContextAddAreaDeleteSubmitData: (payload) => deleteSubmitDataAddArea(payload),

    // Sayfa bazlı validasyon işlemleri için kullanırum
    setContextPageValidate: (pagesIndex, payload, remove) => validatePage(pagesIndex, payload, remove),

    // Koşula bağlı input ve sayfaların görünümlerini ayarlıyor
    setContextDefinedControl: (name) => definedControl(name),

    setContextDeleteSumbitDataDefined: (payload) => deleteSumbitDataDefined(payload),

    // force update için state güncelliyorum.
    // // setContextForceUpdate: () => setForceUpdateCounter(forceUpdateCounter + 1),

    setContextParentReRender: () => parentReRender(),

    // sayfa numarasını burada tutuyorum
    setContextPagesNumber: (pagesIndex) => setPagesNumber(pagesIndex),

    // accordion menuların length durumlarını ve index durumlarını saklıyorum
    setContextAccordionIndex: (payload) => accordionIndexFunc(payload), // name ,index

    setContextAccordionLength: (payload) => accordionLengthFunc(payload),

    // sayfadan çıkış yaptığında hepsini sıfırlıyor
    setReset: () => {
      setPageValidate([]);
      setHardwareDetailActive(false);
      setServiceData({});
      setSubmitData({});
      setFirstClick(true);
      setScreenData({});
      setPagesNumber(0);
    },
  };

  const useMemoChildren = useMemo(() => React.cloneElement(
    props.children,
    {
      reRender: parentCompRender,
      pagesNumber,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [parentCompRender, pagesNumber]);
  return (
    <HardwareContext.Provider value={value}>
      {useMemoChildren}
    </HardwareContext.Provider>
  );
};

HardwareRegisterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HardwareRegisterProvider;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip, Drawer, Grid, isWidthDown, MenuItem, Slide,
} from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Delete, Edit, ExpandMore } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropsTypes from 'prop-types';
import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import {
  HistoryIcon, LeftArrow, MoreVert, Plus,
} from '../../assets/icons';

import {
  CustomButton,
  CustomDrawer,
  CustomMenu,
  FilteredSidebar,
  HardwareDetailBottomSheet, PageContent, PageWrapper, SwitchWrapper, TableWrapper,
} from '../../assets/styledNew';
import Input from '../../components/Input';
import { FormatUTCLocaleDateStr, tra } from '../../commons/utils';
import {
  selectInfos, selectLicenseFollowDetail, selectLicenseFollowDetailLog, selectLicenseFollowSelectData, selectResetState,
} from '../../store/selectors';
import {
  setResetState, updateLicenseFollowKey, getLicensFolloweDetail, getLicenseFollowAgreement, getLicenseFollowUser, deleteLicenseFollow,
} from '../../store/actions';
import Logs from '../../components/Logs';
import Validator from '../../commons/validator';
import Table from '../../components/Table';
import NewLicenseAddUserModal from '../../components/modals/NewLicenseAddUserModal';
import { Colors } from '../../assets/statics';
import DeleteModal from '../../components/modals/DeleteModal';

class LicenseFollowDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      bottomSheet: false,
      logDrawer: false,
      newUserModalShow: false,
      selectUser: {
        index: -1,
        data: {},
      },
      filterShow: false,
      filter: [],
      alwaysOpenMode: false,
      tableData: [],
      licenseId: -1,
    };

    this.params_license_id = false;
  }

  componentDidMount() {
    this.params_license_id = this.props.history.location.pathname?.split('/')?.at(-1) ?? false;
    if (Number(+this.params_license_id)) {
      this.props.getLicenseFollowAgreement();
      this.props.getLicenseFollowUser();
      this.props.getLicensFolloweDetail(this.params_license_id);
    }
  }

  componentDidUpdate() {
    this.params_license_id = this.props.history.location.pathname?.split('/')?.at(-1) ?? false;
    const { LicenseKeys, agreement } = this.props.licenseDetail ?? {};
    if (this.props.resetState) {
      this.handleCancel('resetService');
      this.props.setResetState(false);
    }
    if (Array.isArray(LicenseKeys) && JSON.stringify(LicenseKeys) !== JSON.stringify(this.state.tableData)) {
      // table data length göre kontrol edildiği için bu şekilde yaptım
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ tableData: [] }, () => this.setState({ tableData: LicenseKeys }));
    }
    if (agreement && this.params_license_id !== this.state.licenseId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ licenseId: this.params_license_id }, () => {
        this.props.getLicensFolloweDetail(this.params_license_id);
      });
    }
  }

  getArrFilters = (itemName, arrayPath, count, countKey) => {
    const { license } = this.props;
    const data = [];
    for (let i = 0; i < license.length; i++) {
      for (let j = 0; j < license[i][arrayPath].length; j++) {
        data.push(license[i][arrayPath][j][itemName]);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  }

  changeFilter = (state, value, text, type, label, header) => {
    const { filter } = this.state;
    if (type === 'resetAll') {
      this.setState({ filter: [] });
      const localFilter = JSON.stringify([]);
      localStorage.setItem('licenseFollowFilter', localFilter);
    } else {
      const stateName = state.replace(/[0-9]/g, '');
      let oldValue = false;
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state);
        filter.splice(index, 1);
      }
      if (type === 'select') {
        oldValue = this.state.filter.find((item) => item.column === stateName);
      } else {
        oldValue = this.state.filter.find((item) => item.column === stateName && item.value === text);
      }
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value) {
        filter.push({
          column: stateName, value: text, label, header,
        });
      }
      this.setState({
        filter,
        [state]: type === 'select' ? text : value,
      });
      const localFilter = JSON.stringify(filter);
      localStorage.setItem('licenseFollowFilter', localFilter);
    }
  }

  handleBottomSheet = (value) => {
    this.setState({ bottomSheet: value });
  }

  handleCancel = (resetService) => {
    if (this.state.alwaysOpenMode && resetService) {
      this.setState({ selectUser: {} });
    } else {
      this.setState({ newUserModalShow: false, selectUser: {} });
    }
  }

  // user remove - detail
  handleRemoveUser = (userData) => {
    if (Number(+this.params_license_id)) {
      const data = {
        ...userData,
        isRemoved: !userData?.isRemoved, // silme işlemi için
        LicenseKeyHardwares: userData?.HardwareInventories?.map((hwa) => hwa?.id && ({
          hardware_inventory_id: hwa?.id,
        })),
      };

      delete data.HardwareInventories;
      delete data.id;
      delete data.registry_no;
      delete data.name;

      this.props.updateLicenseFollowKey({ key_id: userData?.id, data, license_id: this.params_license_id });
    }
  }

  render() {
    const {
      newUserModalShow, selectUser, filterShow, filter,
    } = this.state;
    const { agreement, user } = this.props.licenseSelectData;
    const { licenseTypes } = this.props;
    const {
      LicenseKeys,
      agreement_id, quantity, license_type_id, app_id,
    } = this.props.licenseDetail ?? {};

    if (Object.keys(this.props?.licenseDetail ?? {})?.length === 0) return '  Loading...';

    const columns = [
      {
        name: 'isRemoved',
        text: tra('Kullanici_Durumu'),
        align: 'center',
        filterable: true,
        cellWidth: 100,
        customBody: (value, name, index, row) => (
          <SwitchWrapper>
            <Input
              type="switch"
              value={!value}
              onChange={() => {
                // updateUserRemovedState(index, !value);
                this.handleRemoveUser(row);
              }}
            />
          </SwitchWrapper>
        ),
      },
      {
        name: 'name',
        text: tra('Kullanici_Adi'),
        type: 'text',
        tooltip: false,
        sortable: true,
        searchable: true,
        // filterLocation: 'hardwareType',
        // filterable: true,
        align: 'center',
      },
      {
        name: 'registry_no',
        text: tra('Sicil_No'),
        type: 'text',
        tooltip: false,
        // searchable: true,
        // filterLocation: 'hardwareType',
        // filterable: true,
        align: 'center',
        customBody: (val) => (val || ''),
      },
      {
        name: 'key',
        text: tra('Lisans_Key'),
        sortable: true,
        tooltip: true,
        // searchable: true,
        align: 'center',
        cellWidth: 200,
      },
      {
        name: 'id',
        text: tra('Donanim_No'),
        // sortable: true,
        type: 'array',
        // filterable: true,
        // searchable: true,
        align: 'center',
        customBody: (value, name, index, row) => (row?.HardwareInventories?.length ? (
          <div style={{ display: 'inline-flex', gap: '3px' }}>
            {row?.HardwareInventories?.map((i) => <Chip style={{ background: Colors.blueBackg, color: Colors.blueText, borderRadius: '10px' }} label={i?.tag} />)}
          </div>
        ) : ''),
      },
      {
        name: 'contract_start',
        text: tra('Baslangic_Tarihi'),
        sortable: true,
        type: 'text',
        // filterable: true,
        // searchable: true,
        align: 'center',
        customBody: (val) => val && FormatUTCLocaleDateStr(val),

      },
      {
        name: 'contract_end',
        text: tra('Bitis_Tarihi'),
        sortable: true,
        type: 'text',
        // filterable: true,
        // searchable: true,
        align: 'center',
        customBody: (val) => val && FormatUTCLocaleDateStr(val),
      },
      {
        name: 'owner',
        text: tra('Zimmet'),
        sortable: true,
        type: 'text',
        filterable: true,
        // searchable: true,
        align: 'center',
        customBody: (val) => (val ? <CheckBoxIcon htmlColor={Colors.lightBlue100} /> : <CheckBoxOutlineBlankIcon htmlColor={Colors.lightGrey200} />),
      },
      {
        name: 'description',
        text: tra('Description'),
        // sortable: true,
        type: 'text',
        // filterable: true,
        searchable: true,
        align: 'center',
        cellWidth: 200,
      },
      {
        name: 'id',
        text: tra('Duzenle'),
        // editable: false,
        align: 'center',
        customBody: (value, name, index, row) => (
          <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectUser: { index, data: row }, newUserModalShow: true })} aria-hidden>
            <Edit width={15} />
          </div>
        ),
      },

    ];
    Validator.purgeFields();
    return (
      <>
        <PageWrapper>
          <PageContent>
            <div className="pageInner">
              <Grid container spacing={3}>
                <Grid item style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} xs={12} alignItems="center" justifyContent="flex-end">
                  <CustomButton
                    onClick={() => { this.setState({ newUserModalShow: true }); }}
                    endIcon={<Plus width={15} />}
                  >
                    {tra('Kullanici_Ekle')}
                  </CustomButton>
                  <CustomButton
                    opacity
                    onClick={() => this.setState({ logDrawer: true })}
                    startIcon={<HistoryIcon />}
                  >
                    {tra('Tarihce')}
                  </CustomButton>
                  <CustomButton
                    style={{ padding: 0 }}
                    opacity
                    onClick={(e) => this.setState({ otherMenu: e.currentTarget })}
                  >
                    <MoreVert />
                  </CustomButton>
                  <CustomMenu
                    anchorEl={this.state.otherMenu}
                    open={Boolean(this.state.otherMenu)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    keepMounted
                    onClose={() => this.setState({ otherMenu: null })}
                  >
                    <MenuItem
                      onClick={() => {
                        this.setState({ otherMenu: false, deleteModal: true });
                      }}
                    >
                      <Delete />{tra('Delete_License')}
                    </MenuItem>

                  </CustomMenu>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="select"
                    labelKey="agreement"
                    title={tra('Sozlesme_Adi')}
                    data={agreement}
                    value={agreement_id}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="select"
                    disabled
                    value={app_id}
                    title={tra('Uygulama_Ismi')}
                    data={agreement?.find((i) => i.id === agreement_id)?.Applications ?? []}
                    emptyItem
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="select"
                    data={licenseTypes}
                    title={tra('Lisans_Turu')}
                    value={license_type_id}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="number"
                    title={tra('Lisans_Adedi')}
                    value={quantity}
                    disabled
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TableWrapper style={{ minHeight: '60vh' }}>
                    <Table
                      id="licenseFollowTable"
                      data={this.state.tableData || []}
                      columns={columns}
                      options={{
                        header: {
                          visible: true,
                          columnSetting: true,
                        },
                        filter: {
                          show: true,
                          filters: filter,
                          onChange: this.changeFilter,
                          drawerChange: () => this.setState({ filterShow: !filterShow }),
                        },
                        pagination: {
                          active: true,
                        },
                      }}
                    />

                  </TableWrapper>
                </Grid>
              </Grid>
            </div>
          </PageContent>
        </PageWrapper>
        <Drawer open={this.state.logDrawer} onClose={() => this.setState({ logDrawer: false })} anchor="right">
          <Logs data={this.props.logs} />
        </Drawer>
        <Slide
          appear={false}
          direction="up"
          in={this.state.bottomSheet}
        >
          <HardwareDetailBottomSheet show>
            <Grid container>
              <Grid container justify="space-between" item xs={12}>
                <CustomButton outlined onClick={() => this.handleCancel()}>{tra('Iptal_Et')}</CustomButton>
                <div>
                  <CustomButton onClick={() => this.handleSave()}>{tra('Save')}</CustomButton>
                </div>

              </Grid>
            </Grid>
          </HardwareDetailBottomSheet>
        </Slide>
        <NewLicenseAddUserModal
          open={newUserModalShow}
          close={() => this.setState({ newUserModalShow: false })}
          agreement={agreement?.find((i) => i.id === agreement_id) ?? {}}
          user={user}
          alwaysOpenMode={{
            onChange: () => { this.setState((s) => ({ alwaysOpenMode: !s.alwaysOpenMode })); },
            value: this.state.alwaysOpenMode,
          }}
          selectUser={{
            index: selectUser?.index ?? -1,
            data: selectUser.index >= 0 ? {
              ...selectUser.data,
              user_id: user?.find((i) => i?.id === selectUser?.data?.user_id) ?? {},
              LicenseKeyHardwares: selectUser.data.HardwareInventories,
            } : {},
            reset: () => { this.setState({ selectUser: {} }); },
          }}
        />
        <CustomDrawer
          anchor="left"
          open={filterShow}
          {...(!isWidthDown('sm', this.props.width)
            ? { variant: 'persistent' }
            : {
              onClose: () => this.setState({ filterShow: false }),
              onOpen: () => this.setState({ filterShow: true }),
            })}

        >
          <FilteredSidebar>
            <div className="title">
              {tra('Filtre')}
              {!isWidthDown('sm', this.props.width)
                && <CustomButton onClick={() => this.setState({ filterShow: true })} startIcon={<LeftArrow width={12} />}>{tra('Geri')}</CustomButton>}
            </div>
            <div className="filterContent">
              {
                columns.map((item, index) => (
                  item.filterable && (
                    item.type === 'select'
                      ? (
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <p>{item.text}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Input
                              type="select"
                              fullWidth
                              data={item.selectData}
                              value={this.state[item.name]}
                              onChange={(e) => this.changeFilter(item.name, true, e.target.value, 'select', e.target.value, item.text)}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )
                      : item.type === 'array'
                        ? (
                          <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <p>{item.text}</p>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                              {
                                this.getArrFilters(item.name, item.arrayPath).map((arrFilter, count) => (
                                  <Input
                                    type="checkbox"
                                    title={`${arrFilter} (${this.getArrFilters(item.name, item.arrayPath, true, arrFilter)})`}
                                    value={filter.find((a) => a.value === arrFilter) !== undefined}
                                    onChange={(e) => this.changeFilter(`${item.arrayPath}.${item.name}${index + 1 * count + 1}`, e.target.checked, arrFilter, 'checkbox', arrFilter, item.text)}
                                  />
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        )
                        : item.filterLocation
                          ? (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  LicenseKeys && [...new Set(LicenseKeys.map((items) => items[item.name]))].map((licence, count) => (
                                    <Input
                                      type="checkbox"
                                      title={`${this.props[item.filterLocation].find((f) => f.id === licence) && this.props[item.filterLocation].find((f) => f.id === licence).name} (${LicenseKeys.filter((fltr) => fltr[item.name] === licence).length})`}
                                      value={filter.find((a) => (a.column === item.name) && (a.value === licence)) !== undefined}
                                      onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', this.props[item.filterLocation].find((f) => f.id === licence).name, item.text)}
                                    />
                                  ))
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                          : (
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <p>{item.text}</p>
                              </AccordionSummary>
                              <AccordionDetails style={{ flexDirection: 'column', maxHeight: 165, overflow: 'auto' }}>
                                {
                                  LicenseKeys && [...new Set(LicenseKeys.map((items) => items[item.name]))].map((licence, count) => {
                                    let checkboxTitle;
                                    switch (item.name) {
                                      case 'owner':
                                        checkboxTitle = licence ? tra('Aktif') : tra('Pasif');
                                        break;
                                      case 'isRemoved':
                                        // tersi olacak
                                        checkboxTitle = !licence ? tra('Aktif') : tra('Pasif');
                                        break;
                                      default:
                                        checkboxTitle = '';
                                        break;
                                    }
                                    return (
                                      <Input
                                        type="checkbox"
                                        title={`${checkboxTitle} (${LicenseKeys.filter((fltr) => fltr[item.name] === licence).length})`}
                                        value={filter.find((a) => a.value === licence) !== undefined}
                                        onChange={(e) => this.changeFilter(`${item.name}${index + 1 * count + 1}`, e.target.checked, licence, 'checkbox', licence, item.text)}
                                      />
                                    );
                                  })
                                }
                              </AccordionDetails>
                            </Accordion>
                          )
                  )
                ))
              }
            </div>
            <div className="filterDrawefooter">
              {LicenseKeys && <p><span>{`(${LicenseKeys.length})`}</span> {tra('Envanter_Sayisi')}</p>}
              <CustomButton onClick={() => this.setState({ filterShow: false })}>{tra('Uygula')}</CustomButton>
              <CustomButton transparent onClick={() => this.changeFilter(null, null, null, 'resetAll')}>{tra('Sifirla')}</CustomButton>
            </div>
          </FilteredSidebar>
        </CustomDrawer>
        <DeleteModal
          open={this.state.deleteModal}
          close={() => this.setState({ deleteModal: false })}
          message={tra('Uygulama_Silme_Sorusu')}
          deleteFunc={() => {
            this.props.deleteLicenseFollow(this.params_license_id);
            this.setState({ deleteModal: false });
          }}
        />
      </>
    );
  }
}

LicenseFollowDetail.propTypes = {
  licenseSelectData: PropsTypes.object.isRequired,
  agreement: PropsTypes.array.isRequired,
  licenseTypes: PropsTypes.array.isRequired,
  licenseDetail: PropsTypes.object.isRequired,
  resetState: PropsTypes.bool.isRequired,
  width: PropsTypes.any.isRequired,
  logs: PropsTypes.any.isRequired,
  setResetState: PropsTypes.func.isRequired,
  license: PropsTypes.array.isRequired,
  history: PropsTypes.object.isRequired,
  updateLicenseFollowKey: PropsTypes.func.isRequired,
  getLicensFolloweDetail: PropsTypes.func.isRequired,
  getLicenseFollowAgreement: PropsTypes.func.isRequired,
  getLicenseFollowUser: PropsTypes.func.isRequired,
  deleteLicenseFollow: PropsTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  licenseSelectData: selectLicenseFollowSelectData(),
  licenseTypes: selectInfos('LicenseTypes'),
  licenseDetail: selectLicenseFollowDetail(),
  resetState: selectResetState(),
  logs: selectLicenseFollowDetailLog(),
});
const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setResetState,
      updateLicenseFollowKey,
      getLicensFolloweDetail,
      getLicenseFollowAgreement,
      getLicenseFollowUser,
      deleteLicenseFollow,
    }, dispatch)
  )
);
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withRouter,
  withConnect,
)(LicenseFollowDetail);

/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import {
  Checkbox, DialogContent, Grid, MenuItem,
} from '@material-ui/core';
import { CloseOutlined, Edit } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from '@material-ui/icons/Close';
import {
  CloudExport, CloudImport, Plus, Remove,
} from '../../assets/icons';
import {
  CustomButton, CustomMenu, CustomModal, ModalContent, ModalHeader, PeopleManagementTabPage, SwitchWrapper, TableWrapper,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import Table from '../../components/Table';
import {
  adminExcelDownload, adminExcelUpload, setAppConfig, deleteAdminIntegration, getMessageMonitoringSendMailUsers, deleteMessageMonitoringSendMailUsers, createMessageMonitoringSendMailUsers, setResetState,
} from '../../store/actions';
import Input from '../../components/Input';
import AdminAddUpdateModal from '../../components/modals/AdminAddUpdateModal';
import AddUserLdap from '../../components/modals/AddUserLdap';
import AdminAddUrlIntegration from '../../components/modals/AdminAddUrlIntegration';
import { selectInfos } from '../../store/selectors';
import DeleteModal from '../../components/modals/DeleteModal';

const ManageIntegraiton = () => {
  // const [userStatusId, setUserStatusId] = useState(null);
  const [integrationSelectId, setIntegrationSelectId] = useState(null);
  const [editedIntegration, setEditedIntegration] = useState(null);
  // const [ldapModal, setLdapModal] = useState(false);
  const [integraitonModalShow, setIntegraitonModal] = useState(false);
  const integrationData = useSelector(selectInfos('CompanyPiUrls')) ?? [];
  const appConfig = useSelector((state) => state.appConfig);

  // senMail Area
  const [deleteUserId, setDeleteUserId] = useState(false);
  const [userMailAddModal, setUserMailAddModal] = useState(false);
  const [selectUserMail, setSelectUserMail] = useState({});
  const userData = useSelector((s) => s?.users);
  const integrationMessageMonitoringSendMailUser = useSelector((s) => s.integrationMessageMonitoringSendMailUser ?? []);
  const resetState = useSelector((s) => s?.resetState);
  // const allInfospo = useSelector((state) => state.infos);
  // const appConfig = useSelector((state) => state.appConfig);
  // const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessageMonitoringSendMailUsers());
  }, [dispatch]);

  useEffect(() => {
    if (resetState) {
      setSelectUserMail({});
      dispatch(setResetState(false));
    }
  }, [dispatch, resetState]);

  // const hiddenInput = useRef(null);

  const handleSaveSelectUserMail = () => {
    if (selectUserMail?.id) {
      dispatch(createMessageMonitoringSendMailUsers(selectUserMail?.id));
    }
  };

  const handleEditUserModal = (rowValue) => {
    setIntegraitonModal(true);
    setEditedIntegration(rowValue);
  };

  const integrationColumns = [
    {
      name: 'is_removed',
      text: tra('Durum'),
      align: 'center',
      cellWidth: 100,
      customBody: (value, name, index, row) => (
        <SwitchWrapper>
          <Input
            type="switch"
            value={!value}
            onChange={() => {
              setIntegrationSelectId(row.id);
              dispatch(setAppConfig('integrationSureModal', {
                open: true,
                text: tra('URL_Statu_Degistirme_Sorusu'),
              }));
            }}
          />
        </SwitchWrapper>
      ),
    },
    {
      name: 'url',
      text: 'URL',
      align: 'center',
    },
    {
      name: 'name',
      text: tra('Url_Adi'),
      align: 'center',
    },
    {
      name: 'auth_name',
      text: tra('Kullanici_Adi'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'auth_password',
      text: tra('Sifre'),
      editable: false,
      align: 'start',
      searchable: true,
    },
    {
      name: 'id',
      text: tra('Duzenle'),
      editable: false,
      align: 'center',
      customBody: (value, name, index, row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleEditUserModal(row)} aria-hidden>
          <Edit width={15} />
        </div>
      ),
    },
  ];

  const integration_send_mail_user = [
    {
      name: 'name',
      text: tra('Ad_Soyad'),
      editable: false,
      align: 'center',
      searchable: true,
    },
    {
      name: 'email',
      text: tra('Email'),
      editable: false,
      type: 'text',
      align: 'center',
      searchable: true,
    },
    {
      name: 'id',
      text: '',
      editable: false,
      align: 'center',
      customBody: (id) => (
        <div style={{ cursor: 'pointer' }} onClick={() => setDeleteUserId(id)} aria-hidden>
          <Remove width={15} />
        </div>
      ),
    },
  ];

  return (
    <PeopleManagementTabPage>
      <TableWrapper>
        <div className="header">
          <h4>{tra('Entegrasyon_Islemleri')}</h4>
        </div>
        <>
          <Table
            data={integrationData}
            columns={integrationColumns}
            options={{
              header: {
                visible: true,
              },
              pagination: {
                active: true,
                position: 'left',
              },
              editable: false,
            }}
          />
          <div className="buttonWrapper">
            <CustomButton onClick={() => setIntegraitonModal(true)}><Plus width={14} /> {tra('Yeni_Url_Ekle')}</CustomButton>
          </div>
        </>
      </TableWrapper>
      <TableWrapper>
        <div className="header">
          <h4>{tra('Genel_Rapor_Mail_Kullanicilari')}</h4>
        </div>
        <>
          <Table
            data={integrationMessageMonitoringSendMailUser}
            columns={integration_send_mail_user}
            options={{
              header: {
                visible: true,
              },
              pagination: {
                active: true,
                position: 'left',
              },
              editable: false,
            }}
          />
          <div className="buttonWrapper">
            <CustomButton onClick={() => setUserMailAddModal(true)}><Plus width={14} /> {tra('Yeni_Kullanici_Ekle')}</CustomButton>
          </div>
        </>
      </TableWrapper>
      <CustomModal open={appConfig?.integrationSureModal?.open} onClose={() => dispatch(setAppConfig('integrationSureModal', { open: false, text: '' }))} maxWidth="sm" fullWidth="true" scroll="body">
        <DialogContent className="content">
          <ModalHeader>
            <h1>{tra('Url_Statusunu_Degistirme')}</h1>
            <CloseOutlined onClick={() => dispatch(setAppConfig('integrationSureModal', { open: false, text: '' }))} style={{ cursor: 'pointer' }} />
          </ModalHeader>
          <ModalContent>
            <div className="pageInner">
              <Grid container spacing={4}>
                <Grid container justify="flex-end" item xs={12}>
                  <p>{appConfig.integrationSureModal?.text ?? ''}</p>
                </Grid>
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={() => dispatch(deleteAdminIntegration(integrationSelectId))} style={{ margin: '0 15px' }}>{tra('Evet')}</CustomButton>
                <CustomButton onClick={() => dispatch(setAppConfig('integrationSureModal', { open: false, text: '' }))} outlined>{tra('Hayir')}</CustomButton>
              </Grid>
            </div>
          </ModalContent>
        </DialogContent>
      </CustomModal>
      <CustomModal open={userMailAddModal} onClose={() => setUserMailAddModal(false)} maxWidth="sm" fullWidth="true" scroll="body">
        <DialogContent className="content">
          <ModalHeader>
            <h1>{tra('Yeni_Kullanici_Ekle')}</h1>
            <CloseOutlined onClick={() => setUserMailAddModal(false)} style={{ cursor: 'pointer' }} />
          </ModalHeader>
          <ModalContent>
            <div className="pageInner">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    type="autoComplete"
                    multiple={false}
                    data={userData?.filter((i) => !integrationMessageMonitoringSendMailUser?.some((p) => p?.user_id === i?.id))}
                    onChange={(e, data) => setSelectUserMail(data)}
                    value={selectUserMail}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton
                    disabled={!selectUserMail?.id}
                    onClick={() => handleSaveSelectUserMail()}
                  >{tra('Kaydet')}
                  </CustomButton>
                </Grid>
              </Grid>
            </div>
          </ModalContent>
        </DialogContent>
      </CustomModal>
      <AdminAddUrlIntegration
        open={integraitonModalShow}
        selectIntegration={editedIntegration}
        close={() => {
          setIntegraitonModal(false);
          setEditedIntegration(false);
        }}
      />
      <DeleteModal
        open={Number.isInteger(deleteUserId)}
        close={() => setDeleteUserId(false)}
        message={tra('Silme_Mesaji')}
        deleteFunc={() => {
          dispatch(deleteMessageMonitoringSendMailUsers(deleteUserId));
          setDeleteUserId(false);
        }}
      />
    </PeopleManagementTabPage>
  );
};

ManageIntegraiton.propTypes = {
};

export default ManageIntegraiton;

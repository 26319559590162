import React from 'react';
import PropTypes from 'prop-types';
import 'react-calendar-timeline/lib/Timeline.css';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline/lib';
import 'moment/locale/en-au';
import { withStyles } from '@material-ui/styles';
import { ErrorOutline } from '@material-ui/icons';
import { Colors } from '../../assets/statics';
import { CustomLifeCycleTooltip } from '../../assets/styledNew';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 19px ${Colors.shadow};`,
    fontSize: 13,
    color: Colors.darkBlue,
    fontWeight: 600,
    padding: 20,
    fontFamily: 'Inter',
    borderRadius: 15,
  },
  arrow: {
    color: Colors.white,
  },
}))(Tooltip);

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'startDate',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'id',
  itemTimeStartKey: 'startDate',
  itemTimeEndKey: 'endDate',
  groupLabelKey: 'title',
};

const quarterDecider = (text) => {
  switch (text) {
    case '01':
      return 'Q1';
    case '04':
      return 'Q2';
    case '07':
      return 'Q3';
    case '10':
      return 'Q4';
    default:
      return 'Q1';
  }
};

const colorSwitcher = (status) => {
  switch (status) {
    case 2:
      return '#187D7D';
    case 1:
      return '#F0C832';
    case 0:
      return '#FF5242';
    case 4:
      return 'transparent';
    default:
      return 'gray';
  }
};

const defaultTimeStart = moment().startOf('year').toDate();
const defaultTimeEnd = moment().startOf('year').add(3, 'year').toDate();

const LifecycleChart = ({ data }) => {
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });

  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };
  const itemRenderer = ({
    item,
    // timelineContext,
    itemContext,
    getItemProps,
    // getResizeProps,
  }) => (
    <LightTooltip
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => ({
            top: positionRef.current.y,
            left: positionRef.current.x,
            right: positionRef.current.x,
            bottom: positionRef.current.y,
            width: 0,
            height: 0,
          }),
        },
      }}
      title={item.status === 4 ? (
        <CustomLifeCycleTooltip>
          <div className="title">
            <p>{item.title}<span style={{ color: '#FF5242', marginLeft: 10 }}>{item?.dataPlan} / {item?.plan}</span></p>
            <p>{item.year}</p>
          </div>
          <p>{item.desc}</p>
        </CustomLifeCycleTooltip>
      ) : ''}
    >
      <div
        ref={areaRef}
        onMouseMove={(e) => handleMouseMove(e, itemContext)}
        {...getItemProps({
          style: {
            padding: item.status === 4 ? '5px 10px' : 0,
            background: colorSwitcher(item.status),
            color: 'white',
            cursor: 'pointer',
            fontSize: 12,
            height: 50,
            zIndex: 80,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colorSwitcher(item.status),
            borderRadius: 15,
            display: item.status === 4 && 'flex',
            justifyContent: item.status === 4 && 'center',
            alignItems: item.status === 4 && 'center',
          },
        })}
      >
        {item.status === 4 && <ErrorOutline style={{ width: 15 }} />}
      </div>
    </LightTooltip>
  );

  return (
    <Timeline
      groups={data.groups ? data.groups : []}
      items={data.items ? data.items : []}
      keys={keys}
      itemsSorted={false}
      itemTouchSendsClick={false}
      stackItems={false}
      itemHeightRatio={0.75}
      showCursorLine
      canMove={false}
      canResize={false}
      defaultTimeStart={defaultTimeStart}
      defaultTimeEnd={defaultTimeEnd}
      itemRenderer={itemRenderer}
      timeSteps={
        {
          second: 1,
          minute: 1,
          hour: 1,
          day: 1,
          month: 3,
          year: 1,
        }
      }
    >
      <TimelineHeaders className="sticky">
        <SidebarHeader>
          {({ getRootProps }) => (
            <div
              {...getRootProps({
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'white',
                },
              })}
            >
              <p style={{ width: '100%', textAlign: 'center' }}>Application</p>
            </div>
          )}
        </SidebarHeader>
        <DateHeader
          unit="year"
          labelFormat="YYYY"
          style={{ height: 50 }}
          intervalRenderer={({ getIntervalProps, intervalContext }) => (
            <div
              {...getIntervalProps({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  borderBottom: 'unset',
                  cursor: 'pointer',
                  fontSize: '14px',
                  borderLeft: 'unset',
                },
              })}
            >
              {(intervalContext.intervalText)}
            </div>
          )}
        />
        <DateHeader
          unit="month"
          labelFormat="MM/DD"
          style={{ height: 50 }}
          intervalRenderer={({ getIntervalProps, intervalContext }) => (
            <div
              {...getIntervalProps({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  borderBottom: 'unset',
                  cursor: 'pointer',
                  fontSize: '14px',
                  borderLeft: 'unset',
                },
              })}
            >
              {quarterDecider(intervalContext.intervalText.split('/')[0])}
            </div>
          )}
        />
      </TimelineHeaders>
    </Timeline>
  );
};

LifecycleChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default LifecycleChart;

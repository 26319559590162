import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Vega } from 'react-vega';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Skeleton } from '@material-ui/lab';
import { selectAppConfig } from '../../store/selectors';
import { IntegrationEdgeBuildingScales, IntegrationEdgeBundlingScalesGrid, IntegrationEdgeBundlingScalesItem } from '../../assets/styledNew';
import { tra } from '../../commons/utils';

class VegaChart extends PureComponent {
  constructor(props) {
    super(props);

    window.addEventListener('click', (event) => {
      if (event.item && event.item.datum) {
        props.selected(event.item.datum);
        // bütün clickleri kapsıyor bu sayfadaki o yüzden böyle bir if koydum -vegatype ifi
        // sadece grafige basıldıgında data yoksa tabloyu boşaltıyor
      } else if (event.vegaType) {
        props.selected(false);
      }
    });
  }

  getSpec = (data, dataMap, themeColor) => {
    if (data && dataMap) {
      const myData = data;
      // for (let i = 0; i < 50; i++) {
      //   dataMap.push({
      //     sender: Math.floor(Math.random() * 23),
      //     receiver: Math.floor(Math.random() * 23),
      //     parent: 0,
      //     size: 1,
      //   });
      // }
      const rData = {
        // $schema: 'https://vega.github.io/schema/vega/v5.json',
        description: 'A network diagram of software dependencies, with edges grouped via hierarchical edge bundling.',
        padding: 5,
        width: 800,
        height: 800,
        autosize: 'none',
        signals: [
          {
            name: 'tension',
            value: 0.85,
            // bind: {
            //   input: 'range', min: 0, max: 1, step: 0.01,
            // },
          },
          {
            name: 'radius',
            value: 160,
            // bind: { input: 'range', min: 20, max: 400 },
          },
          {
            name: 'extent',
            value: 360,
            // bind: {
            //   input: 'range', min: 0, max: 360, step: 1,
            // },
          },
          {
            name: 'rotate',
            value: 0,
            // bind: {
            //   input: 'range', min: 0, max: 360, step: 1,
            // },
          },
          // chart 50 datanın altındaysa font değişiyor
          {
            name: 'textSize',
            value: data.length < 50 ? 13 : 10,
            // bind: {
            //   input: 'range', min: 2, max: 20, step: 1,
            // },
          },
          {
            name: 'textOffset',
            value: 8,
            // bind: {
            //   input: 'range', min: 0, max: 10, step: 1,
            // },
          },
          {
            name: 'layout',
            value: 'cluster',
            // bind: { input: 'radio', options: ['tidy', 'cluster'] },
          },
          { name: 'colorIn', value: '#2144D4' },
          { name: 'colorOut', value: '#B91033' },
          { name: 'white', value: 'rgba(0,0,0,0.0)' },
          { name: 'originX', update: 'width / 2' },
          { name: 'originY', update: 'height / 2' },
          {
            name: 'active',
            value: null,
            on: [
              // { events: 'text:mouseover', update: 'datum.id' },
              { events: 'text:click', update: 'datum.id' },
              { events: 'click[!event.item]', update: 'null' },
              // { events: 'mouseover[!event.item]', update: 'null' },
            ],
          },
        ],
        data: [
          {
            name: 'tree',
            values: myData,
            transform: [
              {
                type: 'stratify',
                key: 'id',
                parentKey: 'parent',
              },
              {
                type: 'tree',
                method: { signal: 'layout' },
                size: [1, 1],
                as: ['alpha', 'beta', 'depth', 'children'],
              },
              {
                type: 'formula',
                expr: '(rotate + extent * datum.alpha + 270) % 360',
                as: 'angle',
              },
              {
                type: 'formula',
                expr: 'inrange(datum.angle, [90, 270])',
                as: 'leftside',
              },
              {
                type: 'formula',
                expr: 'originX + radius * datum.beta * cos(PI * datum.angle / 180)',
                as: 'x',
              },
              {
                type: 'formula',
                expr: 'originY + radius * datum.beta * sin(PI * datum.angle / 180)',
                as: 'y',
              },
            ],
          },
          {
            name: 'leaves',
            source: 'tree',
            transform: [
              {
                type: 'filter',
                expr: '!datum.children',
              },
            ],
          },
          {
            name: 'dependencies',
            values: dataMap,
            transform: [
              {
                type: 'formula',
                expr: "treePath('tree', datum.sender, datum.receiver,datum.common)",
                as: 'treepath',
                initonly: true,
              },
            ],
          },
          {
            name: 'selected',
            source: 'dependencies',
            transform: [
              {
                type: 'filter',
                expr: 'datum.sender === active || datum.receiver === active',
              },
            ],
          },
          {
            name: 'commonData',
            source: 'dependencies',
            transform: [
              {
                type: 'filter',
                expr: '(datum.common === true && datum.sender === active) || (datum.common === true && datum.receiver === active)',
              },
            ],
          },
        ],
        marks: [
          {
            type: 'text',
            from: { data: 'leaves' },
            encode: {
              enter: {
                text: { field: 'name' },
                baseline: { value: 'middle' },
              },
              update: {
                x: { field: 'x' },
                y: { field: 'y' },
                dx: { signal: 'textOffset * (datum.leftside ? -1 : 1)' },
                angle: { signal: 'datum.leftside ? datum.angle - 180 : datum.angle' },
                align: { signal: "datum.leftside ? 'right' : 'left'" },
                fontSize: { signal: 'textSize' },
                fontWeight: [
                  { test: "indata('selected', 'sender', datum.id)", value: 'bold' },
                  { test: "indata('selected', 'receiver', datum.id)", value: 'bold' },
                  { value: null },
                ],
                fill: [
                  { test: 'datum.id===active', value: themeColor.blue },
                  { test: "indata('commonData', 'receiver', datum.id) || indata('commonData', 'sender', datum.id) && active", value: themeColor.edgeChartSenderMutual },
                  { test: "indata('selected', 'sender', datum.id)", value: themeColor.edgeChartSender },
                  { test: "indata('selected', 'receiver', datum.id)", value: themeColor.edgeChartReceiver },
                  { test: 'active', value: 'grey' },
                  { value: themeColor.text2 },
                ],
              },
            },
          },
          {
            type: 'group',
            from: {
              facet: {
                name: 'path',
                data: 'dependencies',
                field: 'treepath',
              },
            },
            marks: [
              {
                type: 'line',
                interactive: true,
                from: { data: 'path' },
                encode: {
                  enter: {
                    interpolate: { value: 'bundle' },
                    strokeWidth: { value: 1.5 },
                  },
                  update: {
                    stroke: [
                      {
                        test:
                          '(parent.common===true && parent.sender===active) || (parent.common===true && parent.receiver===active)',
                        value: themeColor.edgeChartSenderMutual,
                      },
                      {
                        test: 'parent.sender === active ',
                        value: themeColor.edgeChartReceiver,
                      },
                      {
                        test: 'parent.receiver === active ',
                        value: themeColor.edgeChartSender,
                      },
                      { value: themeColor.edgeChartOutsideSelectLine },
                    ],
                    strokeOpacity: [
                      { test: 'parent.sender === active || parent.receiver === active', value: 1 },
                      { value: 0.2 },
                    ],
                    strokeWidth: [{ test: 'parent.sender === active || parent.receiver === active', value: 3.5 }, { value: 1.5 }],
                    tension: { signal: 'tension' },
                    x: { field: 'x' },
                    y: { field: 'y' },
                  },
                },
              },
            ],
          },
        ],
        scales: [
          {
            name: 'color',
            type: 'ordinal',
            // domain: ['Receiver', 'Sender'],
            range: [{ signal: 'colorIn' }, { signal: 'colorOut' }],
          },
        ],
        legends: [
          {
            stroke: 'color',
            labelColor: themeColor.text1,
            orient: 'bottom-left',
            title: '',
            symbolType: 'stroke',
          },
        ],
      };
      return rData;
    }
    return {};
  }

  render() {
    const { theme } = this.props;
    return (this.props.lazyLoading
      ? <Skeleton variant="rect" style={{ width: '800px', height: '400px', margin: '0 auto' }} />
      : (
        <>
          <Vega style={{ flex: 2, paddingLeft: '20px' }} spec={this.getSpec(this.props.data, this.props.dataMap, this.props.theme)} actions={false} />
          <IntegrationEdgeBundlingScalesGrid>
            <IntegrationEdgeBundlingScalesItem color={theme.edgeChartReceiver}>{tra('Receiver')} </IntegrationEdgeBundlingScalesItem>
            <IntegrationEdgeBundlingScalesItem color={theme.edgeChartSender}>{tra('Sender')} </IntegrationEdgeBundlingScalesItem>
            <IntegrationEdgeBundlingScalesItem color={theme.edgeChartSenderMutual}>{tra('Mutual')} </IntegrationEdgeBundlingScalesItem>
            <IntegrationEdgeBundlingScalesItem color={theme.blue}>{tra('Select')} </IntegrationEdgeBundlingScalesItem>
          </IntegrationEdgeBundlingScalesGrid>
        </>
      ));
  }
}

VegaChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataMap: PropTypes.array.isRequired,
  selected: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired,
  lazyLoading: PropTypes.bool,
};

VegaChart.defaultProps = {
  lazyLoading: false,
};

const mapStateToProps = createStructuredSelector({
  appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // ...
    }, dispatch)
  )
);

const VegaChartWithTheme = withTheme(VegaChart);
export default connect(mapStateToProps, mapDispatchToProps)(VegaChartWithTheme);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import LinearProgress from '@material-ui/core/LinearProgress';
import { selectDashboardMapReport } from '../store/selectors';
import { WorldMapReportChart } from './charts/WorldMapReport';
import { getDashboardMapReport } from '../store/actions';
import { DashboardMapCountryList } from '../assets/styledNew';
import { FormatCurrency } from '../commons/utils';

class WorldMapDash extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // dataConvert: [],
    };
    this.worldMapRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.worldMapRef !== null) {
      const { current: element } = this.worldMapRef;
      if (element !== null) {
        const svg = element.querySelector('svg');
        if (svg !== null) {
          svg.setAttribute('viewBox', `0 0 ${element.clientWidth + 50} ${element.clientHeight}`);
        }
      }
    }
  }

    countryList = ({ percent, name, value }) => (
      <div className="item">
        <div className="header">
          <p className="name">{name}</p>
          <p className="total">{value}</p>
          <p className="percent">{FormatCurrency(percent)}%</p>
        </div>
        <LinearProgress variant="determinate" value={percent} />
      </div>
    )

    render() {
      return (
        <>
          <div ref={this.worldMapRef} style={{ height: '400px', width: '100%' }}>
            <WorldMapReportChart ref={this.worldMapRef} maxValue={this.props?.data[0]?.value ?? 100} data={this.props?.data ?? []} />
          </div>
          <DashboardMapCountryList>
            {
              this.props.data.map((i) => this.countryList(i))
            }
          </DashboardMapCountryList>
        </>
      );
    }
}

const mapStateToProps = createStructuredSelector({
  data: selectDashboardMapReport(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getDashboardMapReport,
    }, dispatch)
  )
);

WorldMapDash.propTypes = {
  getDashboardMapReport: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorldMapDash);

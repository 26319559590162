/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  MenuItem, TableBody, TableHead,
} from '@material-ui/core';
import {
  ExpandLess, ExpandMore, Search, UnfoldMore,
} from '@material-ui/icons';
import ExpandTable, {
  ExpandableRow, ExpandTableTh, ExpandTableTr, TableTh, TableTr, ExpandTableTd, TableTd, CustomCollapse,
  ExpandTableTdWithoutHead,
} from '../../assets/styledExpandTable';
import { tra } from '../../commons/utils';
import Input from '../../components/Input';
import { CustomButton, CustomMenu } from '../../assets/styledNew';
import { ModuleSwitch } from '../../commons/ModuleSwitch';

const ArrayTypes = [
  {
    id: 1,
    name: tra('Tumu'),
  },
  {
    id: 2,
    name: tra('Hardware'),
  },
  {
    id: 3,
    name: tra('Yazilim'),
  },
];

const ExpandRowSec = ({ item, opened, software }) => {
  const [open, setOpen] = useState(false);

  return useMemo(() => (
    <>
      <ExpandableRow>
        <ExpandTableTh style={{ background: '#F6F8FB' }} colSpan={1} />
        <ExpandTableTh style={{ background: '#E6ECF4' }} colSpan={4}>
          <CustomCollapse in={opened}>
            <ExpandTable>
              <TableBody>
                <ExpandTableTr hover onClick={() => { setOpen(!open); }}>
                  <ExpandTableTdWithoutHead textAlign="start">{software ? tra('Yazilim_Envanteri') : tra('Donanim_Envanteri')}</ExpandTableTdWithoutHead>
                  <ExpandTableTdWithoutHead textAlign="end">{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTdWithoutHead>
                </ExpandTableTr>
                <ExpandableRow>
                  <ExpandTableTh colSpan={5}>
                    <CustomCollapse in={open}>
                      <div
                        style={{
                          background: 'white',
                          padding: 20,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                        }}
                      >
                        <ExpandTable>
                          {software ? (
                            <TableHead>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Uygulama_Adi')}</TableTh>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Business_Domain')}</TableTh>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Kategori')}</TableTh>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Uretici')}</TableTh>
                            </TableHead>
                          ) : (
                            <TableHead>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Donanim_Turu')}</TableTh>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Donanim_Kategorisi')}</TableTh>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Marka')}</TableTh>
                              <TableTh style={{ textAlign: 'start' }}>{tra('Model')}</TableTh>
                            </TableHead>
                          )}
                          <br />
                          <TableBody>
                            {software ? item.SystemOwnerApplications.map((sftware) => (
                              <>
                                <TableTr>
                                  <TableTd textAlign="start">{sftware.name}</TableTd>
                                  <TableTd textAlign="start">{sftware.business_domain}</TableTd>
                                  <TableTd textAlign="start">{sftware.category}</TableTd>
                                  <TableTd textAlign="start">{sftware.manufacturer}</TableTd>
                                </TableTr>
                                <br />
                              </>
                            )) : item.HardwareInventories.map((hw) => (
                              <>
                                <TableTr>
                                  <TableTd textAlign="start">{hw.type_name}</TableTd>
                                  <TableTd textAlign="start">{hw.category_name}</TableTd>
                                  <TableTd textAlign="start">{hw.producer_name}</TableTd>
                                  <TableTd textAlign="start">{hw.model}</TableTd>
                                </TableTr>
                                <br />
                              </>
                            ))}
                          </TableBody>
                        </ExpandTable>
                      </div>
                    </CustomCollapse>
                  </ExpandTableTh>
                </ExpandableRow>
              </TableBody>
            </ExpandTable>
          </CustomCollapse>
        </ExpandTableTh>
      </ExpandableRow>
    </>
  ), [opened, open, item]);
};

const ExpandingRow = ({ item, currency }) => {
  const [open, setOpen] = useState(false);
  return useMemo(() => (
    <>
      <ExpandTableTr hover onClick={() => setOpen(!open)}>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.id}</ExpandTableTd>
        <ExpandTableTd style={{ textAlign: 'start' }}>{item.name}</ExpandTableTd>
        <ModuleSwitch software>
          <ExpandTableTd style={{ textAlign: 'start' }}>{item.total_software}</ExpandTableTd>
        </ModuleSwitch>
        <ModuleSwitch hardware>
          <ExpandTableTd style={{ textAlign: 'start' }}>{item.total_hardware}</ExpandTableTd>
        </ModuleSwitch>
        <ExpandTableTd style={{ textAlign: 'end' }}>{open ? <ExpandLess /> : <ExpandMore />}</ExpandTableTd>
      </ExpandTableTr>
      {open && <br />}
      <ModuleSwitch software>
        <>
          <ExpandRowSec item={item} software opened={open} currency={currency} />
          {open && <br />}
        </>
      </ModuleSwitch>
      <ModuleSwitch hardware>
        <>
          <ExpandRowSec item={item} opened={open} currency={currency} />
          {open && <br />}
        </>
      </ModuleSwitch>
    </>
  ), [open, item]);
};

const DebitReport = ({ currency }) => {
  const userReports = useSelector((state) => state.userReports);
  const [searchText, setSearchText] = useState('');
  const [arrayTypeMenu, setArrayTypeMenu] = useState(null);
  const [arrayType, setArrayType] = useState(ArrayTypes[0].name);
  const [searchableList, setSearchableList] = useState([]);

  useEffect(() => {
    if (userReports.length > 0) {
      const keys = Object.keys(userReports[0]);
      setSearchableList(keys);
    }
  }, [userReports]);

  const isTableData = (tableItem, searchVal) => {
    const allReturn = [];
    for (let i = 0; i < searchableList.length; ++i) {
      if (Array.isArray(tableItem[searchableList[i]])) {
        const subArrKeys = Object.keys(tableItem[searchableList[i]][0]);
        for (let k = 0; k < subArrKeys.length; ++k) {
          const arrayTempData = tableItem[searchableList[i]].map((item) => item[subArrKeys[k]]).toString().toUpperCase().includes(searchVal.toUpperCase());
          allReturn.push(arrayTempData);
        }
      } else { allReturn.push([tableItem[searchableList[i]]].toString().toUpperCase().includes(searchVal.toUpperCase())); }
    }

    return allReturn.some((e) => e);
  };

  const searchData = (data) => {
    if (searchText.length > 1 && data && searchableList) {
      const filteredData = data.filter((tableItem) => isTableData(tableItem, searchText));
      return filteredData;
    }
    return data;
  };

  const changeTableArray = (val) => {
    setArrayType(val);
    setArrayTypeMenu(null);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
        <Input startAdornment={<Search />} placeholder={tra('Arama_Yap')} type="text" onChange={(e) => setSearchText(e.target.value)} />
        {/* <CustomButton
          opacity
          style={{ marginLeft: 20, height: 40 }}
          onClick={(e) => setArrayTypeMenu(e.currentTarget)}
          endIcon={<UnfoldMore />}
        >
          {arrayType}
        </CustomButton>
        <CustomMenu
          anchorEl={arrayTypeMenu}
          open={Boolean(arrayTypeMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          onClose={() => setArrayTypeMenu(null)}
        >
          {ArrayTypes.map((item) => (
            <MenuItem onClick={() => changeTableArray(item.name)}>{item.name}</MenuItem>
          ))}
        </CustomMenu> */}
      </div>
      <ExpandTable>
        <TableHead>
          <TableTh style={{ textAlign: 'start' }}>{tra('ID')}</TableTh>
          <TableTh style={{ textAlign: 'start' }}>{tra('Name')}</TableTh>
          <ModuleSwitch software>
            <TableTh style={{ textAlign: 'start' }}>{tra('Total_Software')}</TableTh>
          </ModuleSwitch>
          <ModuleSwitch hardware>
            <TableTh style={{ textAlign: 'start' }}>{tra('Total_Hardware')}</TableTh>
          </ModuleSwitch>
          <TableTh />
        </TableHead>
        <br />
        <TableBody>
          {searchData(userReports).map((itemRow) => (
            <>
              <ExpandingRow item={itemRow} currency={currency} />
              <br />
            </>
          ))}
        </TableBody>
      </ExpandTable>
    </>

  );
};

ExpandingRow.propTypes = {
  item: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

ExpandRowSec.propTypes = {
  item: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
};

DebitReport.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default DebitReport;

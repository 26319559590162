import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  HashRouter, Route, Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Routes from './commons/routes';
import {
  GlobalStyle,
  Layout,
  LoadingWrapper,
  Waiter,
} from './assets/styledNew';
import Toast from './commons/Toast';
import PasswordReset from './pages/PasswordReset';

import {
  setToast,
  getInfo,
  getApplications,
  startRequiresServices,
  setNotifications,
  setLoginState,
  setAppConfig,
} from './store/actions';
import {
  selectToast,
  selectLoginState,
  selectActiveUser,
  selectSocket,
  selectLoading,
  selectAppConfig,
  selectActiveNotFound,
  selectInitLoading,
} from './store/selectors';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import SelectCompanyModal from './components/modals/SelectCompany';
import {
  DarkTheme, Colors,
} from './assets/statics';
import WaitImg from './assets/img/wait.gif';
import CompanyActivation from './pages/CompanyActivation';
import ExcelErrorModal from './components/modals/ExcelErrorModal';
import NotFound from './pages/NotFound';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: localStorage.getItem('company'),
      selectedCompanyModal: true,
      wait: false,
    };
    if (this.state.selectedCompany && localStorage.getItem('token')) {
      props.getInfo();
      props.getApplications();
    }
    if (window.location.href.toString().includes('/changePassword/')) {
      this.props.setLoginState(true);
    }
    if (window.location.href.toString().includes('/activationCompany/')) {
      this.props.setLoginState(true);
    }
    if ((this.props.user.companies && this.props.user.companies.length > 1)) {
      localStorage.setItem('company', this.props.user.companies[0].id);
    }
    this.props.startRequiresServices();
    this.openSocket();
    // setTimeout(() => {
    //   this.setState({ wait: false });
    // }, 3500);

    // localStorage.removeItem('theme');
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.socket) {
      nextProps.socket.on('authResult', (data) => {
        if (data.status) {
          nextProps.setNotifications(data.data);
        }
      });
      nextProps.socket.on('notification', (data) => {
        nextProps.setNotifications(data.data, true);
      });
    }
    return null;
  }

  componentDidMount() {
    if (localStorage.getItem('theme') === null) { localStorage.setItem('theme', 'light'); }
    this.props.setAppConfig('theme', localStorage.getItem('theme'));
  }

  openSocket = () => {
    if (this.props.socket) {
      this.props.socket.on('authResult', (data) => {
        if (data.status) {
          this.props.setNotifications(data.data);
        }
      });
      this.props.socket.on('notification', (data) => {
        this.props.setNotifications(data.data, true);
      });
    }
  }

  setTheme = () => {
    const theme = localStorage.getItem('theme');
    if (theme !== null) {
      if (theme === 'dark') {
        this.props.setAppConfig('theme', 'light');
        localStorage.setItem('theme', 'light');
      } else {
        localStorage.setItem('theme', 'dark');
        this.props.setAppConfig('theme', 'dark');
      }
    } else {
      localStorage.setItem('theme', 'light');
      this.props.setAppConfig('theme', 'light');
    }
  }

  render() {
    const {
      toast, loginState, user, loading, activeNotFound,
    } = this.props;
    const {
      selectedCompany, selectedCompanyModal, wait,
    } = this.state;
    const activeComp = localStorage.getItem('company');
    const selectedComp = Object.keys(user).length > 0 && user.companies && user.companies.find((item) => item.id.toString() === activeComp);
    const isAdmin = selectedComp !== undefined ? (selectedComp.permission === 1) : false;
    return (
      <HashRouter>
        <ThemeProvider theme={(this.props.appConfig.theme === 'light' || loginState) ? Colors : DarkTheme}>
          {
            loading && (
              <LoadingWrapper>
                <div className="lds-ellipsis">
                  <div /><div /><div /><div />
                </div>
              </LoadingWrapper>
            )
          }
          <Toast
            isOpen={toast.open}
            message={toast.message}
            handleClose={() => this.props.setToast(false)}
            variant={toast.variant}
          />
          <Switch>

            <Route path="/changePassword/:token" exact component={PasswordReset} />
            <Route path="/activationCompany/:company" exact component={CompanyActivation} />
            <Route path="/404" component={NotFound} />
            <Route>
              <Login state={loginState} />
              <>
                <Login state={loginState} />
                <Layout state={loginState} active={!isWidthDown('md', this.props.width) && !activeNotFound}>
                  <div className="sidebar">
                    {
                !isWidthDown('md', this.props.width) && !activeNotFound
                && (
                  <Sidebar
                    // active={active}
                    // toggle={() => this.setState((prevState) => ({ active: !prevState.active }))}
                    setTheme={this.setTheme}
                  />
                )
              }
                  </div>
                  <div className="mainContent">
                    <Header setTheme={() => this.setTheme()} />
                    <Routes isAdmin={isAdmin} />
                  </div>
                </Layout>
              </>
            </Route>
          </Switch>

          <ExcelErrorModal />
          {/* <SelectCompanyModal open={(user.companies && user.companies.length > 1) && !selectedCompany && selectedCompanyModal && !loginState} focusClose={() => this.setState({ selectedCompanyModal: false })} close={() => this.setState({ selectedCompanyModal: false })} /> */}
          <GlobalStyle />
          <Waiter active={this.props.initLoading}><img src={WaitImg} alt="wait_img" /></Waiter>
        </ThemeProvider>
      </HashRouter>
    );
  }
}

App.propTypes = {
  toast: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  loginState: PropTypes.bool.isRequired,
  getInfo: PropTypes.func.isRequired,
  getApplications: PropTypes.func.isRequired,
  startRequiresServices: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired,
  socket: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoginState: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
  setAppConfig: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  activeNotFound: PropTypes.bool.isRequired,
  initLoading: PropTypes.bool.isRequired,
};

// App.defaultProps = {
//   toast: '',
// };

const mapStateToProps = createStructuredSelector({
  toast: selectToast(),
  loginState: selectLoginState(),
  user: selectActiveUser(),
  socket: selectSocket(),
  loading: selectLoading(),
  initLoading: selectInitLoading(),
  appConfig: selectAppConfig(),
  activeNotFound: selectActiveNotFound(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getInfo,
      setToast,
      getApplications,
      startRequiresServices,
      setNotifications,
      setLoginState,
      setAppConfig,
    }, dispatch)
  )
);

const AppWithWidth = withWidth()(App);

export default connect(mapStateToProps, mapDispatchToProps)(AppWithWidth);

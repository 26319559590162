/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
// import FormData from 'form-data';
// import { Switch } from 'react-router';
// import { useHistory } from 'react-router';

// import * as fs from 'fs';
import crypto from 'crypto';
import { store } from '../index';
import {
  setLoginState,
  setAppConfig,
  setToast,
  getApplications,
} from '../store/actions';
import { PUBLIC_KEY } from './constants';
import moduleType from './enum/moduleType';

// const pem = require('pem-file');
// import { PUBLIC_KEY } from './constants';
export const tra = (key) => {
  const lang = localStorage.getItem('language');

  if (!lang) {
    localStorage.setItem('language', 'tr');
  }
  const json = require(`../assets/locales/${lang || 'tr'}/translations.js`).default;
  const keys = Object.keys(json);
  if (json.key) {
    return json[key];
  }
  for (let i = 0; i < keys.length; ++i) {
    if (json[keys[i]][key]) {
      return json[keys[i]][key];
    }
  }
  return `${key}`;
};

const saveAs = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const anchorElem = document.createElement('a');
  anchorElem.style = 'display: none';
  anchorElem.href = url;
  anchorElem.download = fileName;
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem);
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 1000);
};

const FETCH_TIMEOUT = 30000;
let didTimeOut = false;

export const request = (url, method, data = false, authorization = false, timeOut = true) => {
  const lang = localStorage.getItem('language');
  const controller = new AbortController();
  const { signal } = controller;

  const isRequireLogin = store.getState().loginState;
  if (isRequireLogin === false || url.includes('account/v1/') || url.includes('public/v1/')) {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (authorization) {
      const token = localStorage.getItem('token');
      if (token) {
        headers.authorization = token;
        headers.companyid = localStorage.getItem('company');
        headers.language = lang || 'tr';
      }
    }
    const options = {
      headers,
      method,
      signal,
    };
    if (data) {
      options.body = data;
    }
    const timeout = timeOut && setTimeout(() => {
      didTimeOut = true;
      controller.abort();
    }, FETCH_TIMEOUT);

    if (!didTimeOut) {
      return fetch(url, options)
        .then(async (response) => {
          clearTimeout(timeout);
          if (!response.ok) {
            if (response.status === 401) {
              const err = await response.text();
              const errText = JSON.parse(err).message;
              store.dispatch(setToast(true, errText, 'error'));
              localStorage.removeItem('token');
              localStorage.removeItem('company');
              store.dispatch(setLoginState(true));
              throw Error(response.statusText);
            }
          } else if (!didTimeOut && response !== undefined) {
            if (!url.includes('public/v1/') && !url.includes('account/v1/Password/forget') && !url.includes('account/v1/Password/reset')) {
              store.dispatch(setLoginState(false));
            }
            return response;
          }
          return 'invalid';
        })
        .then(async (response) => {
          const type = response.headers.get('Content-Type');
          if (type.includes('officedocument')) {
            const res = await response.blob();
            saveAs(res, 'Document');
            return {
              status: true,
              message: tra('Dosya_Başarılı_Bir_Şekilde_İndirildi'),
            };
          }
          return response.json();
        })
        .catch(() => 'invalid');
    }
    store.dispatch(setToast(true, tra('Bağlantı_Zaman_Aşımına_Uğradı'), 'error'));
    didTimeOut = false;
    return 'invalid';
  }
  return 'invalid';
};

export const filesRequest = (url, method, keyName, files, data = false) => {
  const myHeaders = new Headers();
  myHeaders.append('authorization', localStorage.getItem('token'));
  myHeaders.append('companyid', localStorage.getItem('company'));
  myHeaders.append('language', localStorage.getItem('language' || 'tr'));
  const fd = new FormData();
  for (let i = 0; i < files.length; i++) {
    if (files[i]) {
      fd.append('file', files[i]);
    }
  }
  if (data) {
    fd.append('data', JSON.stringify(data));
  }

  const requestOptions = {
    method,
    headers: myHeaders,
    body: fd,
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('token');
          store.dispatch(setLoginState(true));
        }
        throw Error(response.statusText);
      }
      return response;
    })
    .then(async (response) => {
      const type = response.headers.get('Content-Type');
      if (type.includes('officedocument')) {
        const res = await response.blob();
        saveAs(res, 'Document');
        return {
          status: false,
          message: tra('Yüklediğiniz_dosyada_hata_var_indirilen_dosyayı_kontrol_ediniz'),
        };
      }
      return response.json();
    })
    .catch(() => 'invalid');
};

export const fileDownload = (url, method, data = false, fileName) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    authorization: localStorage.getItem('token'),
    responseType: 'arraybuffer',
    companyid: localStorage.getItem('company'),
    language: localStorage.getItem('language') || 'tr',
  };
  const options = {
    headers,
    method,
  };
  if (data) {
    options.body = data;
  }
  return fetch(url, options)
    .then((res) => {
      if (!res.ok) throw new Error('Not 2xx response', { cause: res });
      return res.blob();
    })
    .then((blob) => {
      saveAs(blob, fileName);
    }).catch(() => 'invalid');
};

export const getPostDateStr = (date) => {
  let postTime; let postDateMounth; let postDateWeek;
  let postDateDay; let postDateHour; let postDateMin;
  let postTimeStr;
  if (date) {
    postTime = Math.abs(new Date().getTime() - new Date(date).getTime());
    if (postTime) {
      postDateMounth = Math.floor(postTime / (1000 * 60 * 60 * 24 * 7 * 30));
      postDateWeek = Math.floor(postTime / (1000 * 60 * 60 * 24 * 7));
      postDateDay = Math.floor(postTime / (1000 * 60 * 60 * 24));
      postDateHour = Math.floor(postTime / (1000 * 60 * 60));
      postDateMin = Math.floor(postTime / (1000 * 60));
      postTimeStr = tra('şimdi');
      if (postDateMin > 0) {
        postTimeStr = `${postDateMin} ${tra('dakikaKisa')}`;
      }
      if (postDateHour > 0) {
        postTimeStr = `${postDateHour} ${tra('saatKisa')}`;
      }
      if (postDateDay > 0) {
        postTimeStr = `${postDateDay} ${tra('gunKisa')}`;
      }
      if (postDateWeek > 0) {
        postTimeStr = `${postDateWeek} ${tra('haftaKisa')}`;
      }
      if (postDateMounth > 0) {
        postTimeStr = `${postDateMounth} ${tra('ayKisa')}`;
      }
    }
  }
  return postTimeStr;
};

export const hexToRgbA = (hex, opacity) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  }
  throw new Error('Bad Hex');
};

export const sendByMentions = (str) => {
  const tagExtraClear = str.replaceAll(/###(\w*[a-z ^\wığüşöçĞÜŞÖÇİ]+\w*)###/g, '');
  const cmnt = tagExtraClear.replace(/  +/g, ' ');
  return cmnt;
};

window.setProfile = (id) => {
  store.dispatch(setAppConfig([{ profileModal: true }, { profileId: id }]));
};

export const parseByMentions = (str, id, notif) => {
  const { users, applications, isLogin } = store.getState();
  if (users && applications && !isLogin && localStorage.getItem('token')) {
    let strNew;
    if (notif) {
      const splittedStr = (str.split(' '));
      if (id === 0) {
        const splt = (str.split(" '"));
        strNew = `${tra(splt[0])} '${splt[1]}`;
      } else if (id === 1) {
        // bir yorumda sizden bahsetti için yapıldı
        strNew = `${splittedStr[0]} ${splittedStr[1]}${tra(splittedStr[2])}`;
      } else {
        strNew = tra(splittedStr[0]) + splittedStr[1];
      }
    } else {
      strNew = str;
    }
    const userMentions = strNew.replaceAll(/@\[\d+\]/g, (oldName) => `<div style="color: ${(props) => props.theme.blueText};margin: 0;cursor: pointer;display: inline-block;" onClick="setProfile(${users.find((item) => item.id.toString() === oldName.substring(0, oldName.length - 1).substring(2)) && users.find((item) => item.id.toString() === oldName.substring(0, oldName.length - 1).substring(2)).id})">
      ${users.find((item) => item.id.toString() === oldName.substring(0, oldName.length - 1).substring(2)) && users.find((item) => item.id.toString() === oldName.substring(0, oldName.length - 1).substring(2)).name}
    </div>`);
    const appMentions = userMentions.replaceAll(/@app\[\d+\]/g, (oldName) => `<a href="#/InventoryDetail/${oldName.substring(3, oldName.length - 1).substring(2)}" style="display: inline-block;">
      <span style="color: ${(props) => props.theme.blueText};margin: 0;padding: 0;border: none;">
        ${(applications.length > 0 && applications.find((item) => item.id.toString() === oldName.substring(3, oldName.length - 1).substring(2)) && applications.find((item) => item.id.toString() === oldName.substring(3, oldName.length - 1).substring(2)).name) || store.dispatch(getApplications())}
      </span>
    </a>`);
    const surveyMentions = appMentions.replaceAll(/@survey\[\d+\/\d+]/g, (oldName) => `<a href="#/Surveys/SurveyAnswer/${oldName.substring(6, oldName.length - 1).substring(2)}" style="display: inline-block;">
      <span style="color: ${(props) => props.theme.blueText};margin: 0;padding-left: 0;border: none;">${tra('Görüntülemek_için_tıklayınız')}.</span>
    </a>`);
    const events = surveyMentions.replaceAll(/@event\[\d+\]/g, (oldName) => `<a href="#/Schedule/${oldName.substring(5, oldName.length - 1).substring(2)}" style="display: inline-block;">
    <span style="color: #4C6FFF;margin: 0;padding: 0;border: none;">${tra('Görüntülemek_için_tıklayınız')}.</span>
    </a>`);
    return events;
  }
  return null;
};

export const gartnerModel = [
  {
    name: tra('Kaldir'),
    value: 1,
    id: 1,
    minX: 0,
    maxX: 2.5,
    minY: 0,
    maxY: 2.5,
  },
  {
    name: tra('Birlestir'),
    value: 2,
    id: 2,
    minX: 2.6,
    maxX: 5,
    minY: 0,
    maxY: 2.5,
  },
  {
    name: tra('Tolere_Et'),
    value: 3,
    id: 3,
    minX: 0,
    maxX: 2.5,
    minY: 2.6,
    maxY: 5,
  },
  {
    name: tra('Yatirim'),
    value: 4,
    id: 4,
    minX: 2.6,
    maxX: 5,
    minY: 2.6,
    maxY: 5,
  },
];

export const calculateTimeValue = (businessValue, itQuality) => {
  let foundObject = {};
  const xIndexes = [];
  for (let i = 0; i < gartnerModel.length; i++) {
    if (gartnerModel[i].minX <= businessValue && businessValue <= gartnerModel[i].maxX) {
      xIndexes.push(i);
    }
  }
  for (let i = 0; i < xIndexes.length; i++) {
    const index = xIndexes[i];
    if (gartnerModel[index].minY <= itQuality && itQuality <= gartnerModel[index].maxY) {
      foundObject = gartnerModel[index];
    }
  }
  return foundObject;
};

export const scrSize = (...breakpoint) => {
  try {
    const size = {
      xs: { min: 0, max: 600 },
      sm: { min: 600, max: 960 },
      md: { min: 960, max: 1280 },
      lg: { min: 1280, max: 1920 },
      xl: { min: 1920, max: 1920 },
    };
    if (breakpoint.length <= 2) {
      if (breakpoint.length === 2) {
        const isParametre = breakpoint.map((item) => Object.keys(size).findIndex((element) => element === item)).sort();
        return breakpoint.some((item) => item === 'xl')
          ? ` screen and (max-width: ${Object.values(size)[isParametre[0]].min}px)`
          : ` screen and (min-width: ${Object.values(size)[isParametre[0]].min}px) and (max-width: ${Object.values(size)[isParametre[1]].max}px)`;
      }
      switch (breakpoint[0]) {
        case 'xl':
          return ` screen and (max-width: ${size.xl.max}px)`;
        case 'lg':
          return ` screen and (min-width: ${size.lg.min}px) and (max-width: ${size.lg.max}px)`;
        case 'md':
          return ` screen and (min-width: ${size.md.min}px) and (max-width: ${size.md.max}px)`;
        case 'sm':
          return ` screen and (min-width: ${size.sm.min}px) and (max-width: ${size.sm.max}px)`;
        case 'xs':
          return ` screen and (min-width: ${size.xs.min}px) and (max-width: ${size.xs.max}px)`;
        default:
          throw new Error('Tanımsız değer');
      }
    }
    throw new Error('Fazla değer girdin');
  } catch (e) {
    console.log(e.message);
    throw e;
  }
};

export const pageShow = {
  HOME: '/',
  ADMİN: '/Admin',
  ADMIN_SURVEY: '/Admin/Survey',
  INVENTORY: '/Inventories',
  COSTING: '/Costing',
  COSTING_REGISTER: /^(?=\/?CostingRegister).+$/gm,
  COSTING_DETAIL_REGEX: /^(?=\/?CostingDetail).+$/gm,
  INVENTORY_DETAIL_REGEX: /^(?=\/?InventoryDetail).+$/gm,
  INVENTORY_REGISTER: /^(?=\/?InventoryRegister).+$/gm,
  SCHEDULE_REGEX: /^(?=\/?Schedule).+$/gm,
  SCHEDULE: '/Schedule',
  NOTIFICATION: '/Notification',
  SURVEY: '/Surveys',
  SURVEY_REGEX: /^(?=\/?Surveys).+$/gm, // örn: /Surveys/14
  INTEGRATION: '/Integration',
  INTEGRATION_REGEX: /^(?=\/?Integration).+$/gm,
  SURVEY_ANSWER_REGEX: /^(?=\/?Surveys\/SurveyAnswer).+$/gm,
  PROFIL_SETTİNG: '/ProfilSetting',
  PROFIL: '/Profil',
  SURVEY_DETAIL_APPS_REGEX: /^(?=\/?Admin\/SurveyDetailApps).+$/gm,
  SURVEY_CHART_DETAIL_REGEX: /^(?=\/?Admin\/SurveyChartDetail).+$/gm,
  SURVEY_DETAIL_REGEX: /^(?=\/?Admin\/SurveyDetail).+$/gm,
  HARDWARE_INVENTORY: '/HardwareInventories',
  HARDWARE_REGISTER_REGEX: /^(?=\/?HardwareRegister).+$/gm,
  HARDWARE_DETAIL_REGEX: /^(?=\/?HardwareDetail).+$/gm,
  REPORTS: '/Reports',
  LICENSE_FOLLOW: '/LicenseFollow',
  LICENSE_FOLLOW_REGISTER: '/LicenseFollowRegister',
  LICENSE_FOLLOW_DETAIL_REGEX: /^(?=\/?LicenseFollowDetail).+$/gm,
};

export const isEmptyObject = (obj) => {
  let name;
  // eslint-disable-next-line no-restricted-syntax
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  return true;
};

export const CommaStringToNumber = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    const temp = str.toString().split('.').join('');
    const temp2 = temp.split(',').join('.');
    return parseFloat(parseFloat(temp2));
  }
  return parseFloat(parseFloat(str));
};

export const CalculateOldNew = (oldValue, newValue, oldTotal) => {
  let oldVal = oldValue;
  let newVal = newValue;
  let oldTotalVal = oldTotal;

  if (Number.isNaN(oldVal) || oldVal === undefined || oldVal === 'NaN' || oldVal === '') {
    oldVal = 0;
  }
  if (Number.isNaN(newVal) || newVal === undefined || newVal === 'NaN' || newVal === '') {
    newVal = 0;
  }
  if (Number.isNaN(oldTotalVal) || oldTotalVal === undefined || oldTotalVal === 'NaN' || oldTotalVal === '') {
    oldTotalVal = 0;
  }
  oldVal = CommaStringToNumber(oldVal);
  newVal = CommaStringToNumber(newVal);
  oldTotalVal = CommaStringToNumber(oldTotalVal);
  const newTotal = oldTotalVal - oldVal + newVal;

  return (newTotal);
};

export const ConvertCurrencyValue = (value, rate) => {
  let convertedVal = 0;
  if ((value !== '' && value && rate !== '' && rate)) {
    convertedVal = CommaStringToNumber(value) * CommaStringToNumber(rate);
  }
  return parseFloat(parseFloat(convertedVal).toFixed(2));
};

export const CalculatePercentage = (value, total) => {
  let percent = 0;
  if ((value !== '' && value && total !== '' && total)) {
    percent = (CommaStringToNumber(value) * 100) / CommaStringToNumber(total);
  }
  /*    return parseFloat(Math.round(percent)); burası değiştirildi 0 hatasından dolayı  */
  return percent;
};

export const FormatCurrency = (value) => Intl.NumberFormat('tr-TR', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format(value);

export const FormatCurrencyWithSymbol = (value, cur) => Intl.NumberFormat('tr-TR', {
  currency: cur,
  style: 'currency',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}).format(value);

export const FormatUTCLocaleDateStr = (date) => new Date(date).toLocaleDateString();

export const ResolvePathObject = (object, path = false) => path && path
  .split('.')
  .reduce((o, p) => (![null, undefined].some((i) => i === o) ? o[p] : null), object);

export const HardwareGroupValidateCheck = ({
  addGroupIndex, addGroupName, groupValidateCheckLength, SubmitData,
}) => {
  if (groupValidateCheckLength > 0) {
    const groupInputValue = Object.entries(SubmitData[addGroupName]?.[addGroupIndex] || {}).map((i) => i[1]);
    const groupInputValueLength = groupInputValue.filter((i) => i !== '');
    const allGroupCheck = groupInputValue.map((item) => ![null].some((i) => item === i)).every((i) => i === true);
    const groupValidateControl = groupInputValueLength.length > 0 ? (allGroupCheck && groupInputValueLength.length >= groupValidateCheckLength) : true;
    return groupValidateControl;
  }
  return false;
};

export const LocationDataWrapper = (locations) => {
  const tempData = [];
  for (let i = 0; i < locations.length; ++i) {
    for (let j = 0; j < locations[i].Locations.length; ++j) {
      const obj = {
        countryName: locations[i].name,
        id: locations[i].Locations[j].id,
        title: locations[i].Locations[j].name,
      };
      tempData.push(obj);
    }
  }
  return tempData;
};

export const encryptTokenLink = (value) => {
  const encryptedData = crypto.publicEncrypt(
    PUBLIC_KEY,
    Buffer.from(value),
  );
  return Buffer.from(encryptedData.toString('base64')).toString('base64');
};

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

// export const GetCurrencySymbol = (currency) => (0).toLocaleString(
//   'tr-TR',
//   {
//     style: 'currency',
//     currency,
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   },
// ).replace(/\d/g, '').replace(/,/g, '').trim();

// export const isUserModule = (module = []) => (initModlue = []) => {
//   const filterModuleLength = initModlue.filter((i) => module.some((item) => item === i)).length ?? 0;
//   return filterModuleLength ? filterModuleLength === module.length : false;
// };

export const isUserModule = (module = [], isSome) => (initModule = []) => {
  if (Object.values(moduleType)?.some((i) => i === module)) return initModule.some((i) => i === module);

  if (!module?.length || !initModule?.length) return false;

  const selectModuleArr = Array.isArray(module) && module;

  if (isSome) {
    return selectModuleArr.map((selectModuleItem) =>
      initModule.some((i) => i === selectModuleItem))
      .some((i) => i);
  }
  return selectModuleArr.map((selectModuleItem) =>
    initModule.some((i) => i === selectModuleItem))
    .every((i) => i);
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DialogContent, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from 'styled-components';
import {
  CustomButton,
  CustomModal,
  ModalHeader,
  ModalContent,
  BottomSheetWrapper,
} from '../../assets/styledNew';

import {
  Colors,
} from '../../assets/statics';
import { tra } from '../../commons/utils';
import Input from '../Input';

import { createUser, setResetState, usersOperation } from '../../store/actions';
import { selectInfos, selectResetState, selectUsers } from '../../store/selectors';
import BottomSheet from '../BottomSheet';
import Validator from '../../commons/validator';

class AdminAddUpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userSurname: '',
      userDepartment: '',
      userPermission: '',
      userEmail: '',
      userPhone: '',
      userTitle: '',
      phone_code: null,
    };
  }

  componentDidUpdate() {
    if (this.props.resetState) {
      this.resetState();
    }
  }

  handleEntering = () => {
    const {
      userId, users, locations, departments,
    } = this.props;
    if (userId) {
      const selectedUser = users.find((item) => item.id === userId);
      const selectedPhoneCode = locations.find((item) => item.dial_code === selectedUser.phone_code);
      this.setState({
        userName: selectedUser.only_name,
        userSurname: selectedUser.only_surname,
        userDepartment: departments?.find((i) => i?.id === selectedUser.department_id),
        userPermission: selectedUser.permission_id,
        userEmail: selectedUser.email,
        userPhone: selectedUser.phone,
        userTitle: selectedUser.title,
        phone_code: selectedPhoneCode,
      });
    }
  }

  resetState = () => {
    this.setState({
      userName: '',
      userSurname: '',
      userDepartment: '',
      userPermission: '',
      userEmail: '',
      userPhone: '',
      userTitle: '',
      phone_code: null,
    });
    this.props.setResetState(false);
    this.props.close();
    Validator.hideMessages();
    this.forceUpdate();
  }

  handleSaveUser = () => {
    const { userId } = this.props;
    const {
      userName, userSurname, userDepartment, userPermission, userEmail, userPhone, userTitle, phone_code,
    } = this.state;
    if (!Validator.allValid()) {
      Validator.showMessages();
      this.forceUpdate();
      return;
    }
    const data = {
      name: userName,
      surname: userSurname,
      department_id: userDepartment?.id,
      permission_id: userPermission,
      email: userEmail,
      phone: userPhone,
      title: userTitle,
      phone_code: phone_code?.dial_code,
    };
    if (userId) {
      this.props.usersOperation(data, userId);
    } else {
      this.props.createUser(data);
    }
    // this.props.close();
    // return true;
  }

  handleChange = (e, state) => {
    this.setState({ [state]: e?.target?.value ?? e });
  }

  handlePhoneChange = (values) => {
    this.setState({ userPhone: values.value });
  }

  handlePhoneCodeChange = (e, newValue) => {
    if (newValue) {
      this.setState({ phone_code: newValue });
    }
  };

  contentPage = () => {
    const ContentWrapper = isWidthDown('xs', this.props.width) ? BottomSheetWrapper : DialogContent;
    const { permissions, locations, userId } = this.props;
    const {
      userName, userSurname, userDepartment, userPermission, userEmail, userPhone, userTitle, phone_code,
    } = this.state;
    Validator.purgeFields();
    return (
      <ContentWrapper>
        <ModalHeader>
          <h1>{tra(userId ? 'Kullanici_Düzenleme' : 'Yeni_Kullanici_Ekle')}</h1>
          <CloseIcon onClick={this.props.close} style={{ cursor: 'pointer', color: this.props.theme.text1 }} />
        </ModalHeader>
        <ModalContent>
          <div className="pageInner" style={{ paddingTop: 30 }}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Adi')}
                  value={userName}
                  indicate
                  onChange={(e) => this.handleChange(e, 'userName')}
                  error={Validator.message('userName', userName, 'required|min:3')}
                  helperText={Validator.message('userName', userName, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Soyadi')}
                  value={userSurname}
                  indicate
                  onChange={(e) => this.handleChange(e, 'userSurname')}
                  error={Validator.message('userSurname', userSurname, 'required|min:3')}
                  helperText={Validator.message('userSurname', userSurname, 'required|min:3')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="autoComplete"
                  title={tra('Departman')}
                  value={userDepartment}
                  indicate
                  data={this.props.departments}
                  multiple={false}
                  onChange={(e, val) => this.handleChange(val, 'userDepartment')}
                  error={Validator.message('userDepartment', userDepartment, 'required')}
                  helperText={Validator.message('userDepartment', userDepartment, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="select"
                  title={tra('Yetki')}
                  data={permissions}
                  indicate
                  value={userPermission}
                  onChange={(e) => this.handleChange(e, 'userPermission')}
                  error={Validator.message('userPermission', userPermission, 'required')}
                  helperText={Validator.message('userPermission', userPermission, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Email')}
                  value={userEmail}
                  indicate
                  onChange={(e) => this.handleChange(e, 'userEmail')}
                  error={Validator.message('userEmail', userEmail, 'required|email')}
                  helperText={Validator.message('userEmail', userEmail, 'required|email')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="tel"
                  valueKey="dial_code"
                  data={locations}
                  telCode={phone_code}
                  onChangeTelCode={(e, newValue) => this.handlePhoneCodeChange(e, newValue)}
                  title={tra('Telefon')}
                  value={userPhone}
                  indicate
                  onValueChange={(values) => this.handlePhoneChange(values)}
                  error={Validator.message('phone_code', phone_code, 'required')}
                  helperText={Validator.message('phone_code', phone_code, 'required')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  title={tra('Görevi')}
                  indicate
                  value={userTitle}
                  onChange={(e) => this.setState({ userTitle: e.target.value })}
                  error={Validator.message('userTitle', userTitle, 'required')}
                  helperText={Validator.message('userTitle', userTitle, 'required')}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <CustomButton onClick={this.handleSaveUser} color={Colors.blue}> {tra('Kaydet')}  </CustomButton>
              </Grid>
            </Grid>
          </div>
        </ModalContent>
      </ContentWrapper>
    );
  }

  displayDesktop = () => <CustomModal onEntering={() => this.handleEntering()} open={this.props.open} onClose={this.props.close} onExit={() => this.resetState()} maxWidth="xs" fullWidth="true" scroll="body">{this.contentPage()}</CustomModal>;

  displayMobile = () => <BottomSheet open={this.props.open} scroll height="100%" isOpen={(open) => { if (open === false) this.props.close(); this.resetState(); }}>{this.contentPage()}</BottomSheet>

  render() {
    return isWidthDown('xs', this.props.width) ? this.displayMobile() : this.displayDesktop();
  }
}

AdminAddUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  createUser: PropTypes.func.isRequired,
  resetState: PropTypes.bool.isRequired,
  setResetState: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  usersOperation: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  userId: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  departments: selectInfos('Departments'),
  permissions: selectInfos('Permissions'),
  locations: selectInfos('Locations'),
  users: selectUsers(),
  resetState: selectResetState(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createUser,
      setResetState,
      usersOperation,
    }, dispatch)
  )
);

const AdminAddUpdateModalWithTheme = withTheme(AdminAddUpdateModal);
const AdminAddUpdateModalWithWidth = withWidth()(AdminAddUpdateModalWithTheme);

export default connect(mapStateToProps, mapDispatchToProps)(AdminAddUpdateModalWithWidth);

import * as constants from './constants';

// RESPONSE_STATE
export const setResponseState = (name, statu) => ({
  type: constants.RESPONSE_STATE,
  name,
  statu,
});

// SET_SHOW_TOAST
export const setToast = (value, message, variant) => ({
  type: constants.SET_TOAST,
  value,
  message,
  variant,
});

// SET_LOGIN_STATE
export const setLoginState = (value) => ({ type: constants.SET_LOGIN_STATE, value });

// FORGOT_PASSWORD
export const forgotPassword = (email) => ({ type: constants.FORGOT_PASSWORD, email });

// DO_LOGIN
export const doLogin = (email, password) => ({ type: constants.DO_LOGIN, email, password });

// CHANGES_PASSWORD
export const resetPassword = (password) => ({ type: constants.RESET_PASSWORD, password });

export const setActiveUser = (data) => ({ type: constants.SET_ACTIVE_USER, data });

// GET_INFO
export const getInfo = () => ({ type: constants.GET_INFO });
// GET_INFO_HARDWARE
export const getInfoHardware = () => ({ type: constants.GET_INFO_HARDWARE });
// GET_INFO
export const getInfoMonitoring = () => ({ type: constants.GET_INFO_MONITORING });
// SET_INFO
export const setInfo = (data) => ({ type: constants.SET_INFO, data });

// CREATE_INFO
export const createInfo = (header, data, id) => ({
  type: constants.CREATE_INFO, header, data, id,
});
// CHANGE_INFO_STATU
export const changeInfoStatu = (header, id) => ({ type: constants.CHANGE_INFO_STATU, header, id });

// GET_USERS
export const getUsers = () => ({ type: constants.GET_USERS });
// SET_USERS
export const setUsers = (data) => ({ type: constants.SET_USERS, data });

// GET_APPLICATIONS
export const getApplications = (sendToView) => ({ type: constants.GET_APPLICATIONS, sendToView });
// GET_APPLICATION_DETAIL
export const getApplicationDetail = (id) => ({ type: constants.GET_APPLICATION_DETAIL, id });
// SET_APPLICATION_DETAIL
export const setApplicationDetail = (data) => ({ type: constants.SET_APPLICATION_DETAIL, data });
// SET_APPLICATIONS
export const setApplications = (data) => ({ type: constants.SET_APPLICATIONS, data });
// CREATE_APPLICATION
export const createApplication = (data, createType, id, sendToView) => ({
  type: constants.CREATE_APPLICATION, data, createType, id, sendToView,
});
// CREATE_APP_WITH_FILE
export const createAppWithFile = (data, files, createType, sendToView) => ({
  type: constants.CREATE_APP_WITH_FILE, data, files, createType, sendToView,
});
// UPDATE_APPLICATION
export const updateApplication = (data, appId) => ({ type: constants.UPDATE_APPLICATION, data, appId });
// USERS_OPERATION
export const usersOperation = (data, id, isRemove) => ({
  type: constants.USERS_OPERATION, data, id, isRemove,
});
// GET_APPLICATION_LOG
export const getApplicationLog = (id) => ({ type: constants.GET_APPLICATIONS_LOG, id });
// SET_APPLICATION_LOG
export const setApplicationLog = (data) => ({ type: constants.SET_APPLICATIONS_LOG, data });
// ARCHIVE_APPLICATION
export const archiveApplication = (data, appId) => ({ type: constants.ARCHIVE_APPLICATION, data, appId });
// UNARCHIVE_APPLICATION
export const unarchiveApplication = (appId) => ({ type: constants.UNARCHIVE_APPLICATION, appId });
// FOLLOW_APPLICATION
export const followApplication = (id, followType) => ({ type: constants.FOLLOW_APPLICATION, id, followType });
// CHANGE_APP_STATUS
export const changeAppStatus = (id, data) => ({ type: constants.CHANGE_APP_STATUS, id, data });
// DELETE_APPLICATION
export const deleteApplication = (id) => ({ type: constants.DELETE_APPLICATION, id });

// GET_COMMENTS
export const getComments = (id) => ({ type: constants.GET_COMMENTS, id });
// SET_COMMENTS
export const setComments = (data) => ({ type: constants.SET_COMMENTS, data });
// DO_COMMENT
export const doComment = (id, data) => ({ type: constants.DO_COMMENT, id, data });
// UPLOAD_PROFILE
export const uploadProfile = (id, data) => ({ type: constants.UPLOAD_PROFILE, id, data });
// UPLOAD_APP_FILE
export const uploadAppFile = (data, id) => ({ type: constants.UPLOAD_APP_FILE, data, id });
// USER_INIT
export const userInit = () => ({ type: constants.USER_INIT });
// GET_PROFILE
export const getProfile = (id) => ({ type: constants.PROFIL_INIT, id });
// SET_PROFILE
export const setProfile = (data) => ({ type: constants.SET_PROFILE, data });

// PROFIL_INIT
export const profileInit = () => ({ type: constants.PROFIL_INIT });

// GET_PROFILE
export const getProfileInfo = () => ({ type: constants.GET_PROFILE_INFO });

// SET_PROFILE
export const setProfileInfo = (data) => ({ type: constants.SET_PROFILE_INFO, data });

// GET_PROFILE_HARDWARE
export const getProfileHardware = () => ({ type: constants.GET_PROFILE_HARDWARE });

// SET_PROFILE_HARDWARE
export const setProfileHardware = (data) => ({ type: constants.SET_PROFILE_HARDWARE, data });

// GET_PROFILE_SOFTWARE
export const getProfileApplication = () => ({ type: constants.GET_PROFILE_APPLICATION });

// SET_PROFILE_SOFTWARE
export const setProfileApplication = (data) => ({ type: constants.SET_PROFILE_APPLICATION, data });

// GET_PROFILE_SURVEY
export const getProfileSurvey = () => ({ type: constants.GET_PROFILE_SURVEY });

// SET_PROFILE_SURVEY
export const setProfileSurvey = (data) => ({ type: constants.SET_PROFILE_SURVEY, data });

// GET_PROFILE_SURVEY
export const getProfileLog = (id) => ({ type: constants.GET_PROFILE_LOG, id });

// SET_PROFILE_SURVEY
export const setProfileLog = (data) => ({ type: constants.SET_PROFILE_LOG, data });

// SET_RESET_STATE
export const setResetState = (value) => ({ type: constants.SET_RESET_STATE, value });

// COMPANY_REGISTER
export const companyRegister = (params) => ({ type: constants.COMPANY_REGISTER, params });

// GET_DASHBOARD
export const getDashboard = () => ({ type: constants.GET_DASHBOARD });
// SET_DASHBOARD
export const setDashboard = (data) => ({ type: constants.SET_DASHBOARD, data });

// GET_COSTGRAPH
export const getCostGraph = (data) => ({ type: constants.GET_COSTGRAPH, data });
// SET_COSTGRAPH
export const setCostGraph = (data) => ({ type: constants.SET_COSTGRAPH, data });

// GET_ADDITIONALCOST
export const getAdditionalCost = (data) => ({ type: constants.GET_ADDITIONALCOST, data });
// SET_ADDITIONALCOST
export const setAdditionalCost = (data) => ({ type: constants.SET_ADDITIONALCOST, data });

// GET_VENDORPROCESSMATRİX
export const getVendorProcessMatrix = (data) => ({ type: constants.GET_VENDORPROCESSMATRIX, data });
// SET_VENDORPROCESSMATRİX
export const setVendorProcessMatrix = (data) => ({ type: constants.SET_VENDORPROCESSMATRIX, data });

// SET_APPCONFIG
export const setAppConfig = (key, value) => ({ type: constants.SET_APPCONFIG, key, value });

// GET_CALENDAR
export const getCalendar = (dates) => ({ type: constants.GET_CALENDAR, dates });
// SET_CALENDAR
export const setCalendar = (data) => ({ type: constants.SET_CALENDAR, data });

// SET_FILTER_DRAWER
export const setFilterDrawer = (value) => ({ type: constants.SET_FILTER_DRAWER, value });

// EVENTS
export const createEvent = (params) => ({ type: constants.CREATE_EVENT, params });
export const setEventCreate = (data) => ({ type: constants.SET_EVENT_CREATE, data });
export const getEvents = (dates) => ({ type: constants.GET_EVENTS, dates });
export const setEvents = (data, year) => ({ type: constants.SET_EVENTS, data, year });
// EXCEL_DOWNLOAD
export const excelDownload = (excelType, fileName) => ({ type: constants.EXCEL_DOWNLOAD, excelType, fileName });
// EXCEL_UPLOAD
export const excelUpload = (files, excelType) => ({ type: constants.EXCEL_UPLOAD, files, excelType });
// CREATE_USER
export const createUser = (data) => ({ type: constants.CREATE_USER, data });
// START_REQUIRED_SERVICES
export const startRequiresServices = () => ({ type: constants.START_REQUIRED_SERVICES });

// CREATE_SURVEY
export const createSurvey = (data) => ({ type: constants.CREATE_SURVEY, data });
// GET_SURVEY_LIST
export const getSurveyList = () => ({ type: constants.GET_SURVEY_LIST });
// SET_SURVEY_LIST
export const setSurveyList = (data) => ({ type: constants.SET_SURVEY_LIST, data });
// GET_SINGLE_SURVEY
export const getSingleSurvey = (id) => ({ type: constants.GET_SINGLE_SURVEY, id });
// SET_SINGLE_SURVEY
export const setSingleSurvey = (data) => ({ type: constants.SET_SINGLE_SURVEY, data });
// GET_SURVEY_DETAIL
export const getSurveyDetail = (id, appId) => ({ type: constants.GET_SURVEY_DETAIL, id, appId });
// SET_SURVEY_DETAIL
export const setSurveyDetail = (data) => ({ type: constants.SET_SURVEY_DETAIL, data });
// GET_SINGLE_SURVEY_DETAIL
export const getSingleSurveyDetail = (userId, id, appId) => ({
  type: constants.GET_SINGLE_SURVEY_DETAIL, userId, id, appId,
});
// SET_SINGLE_SURVEY_DETAIL
export const setSingleSurveyDetail = (data) => ({ type: constants.SET_SINGLE_SURVEY_DETAIL, data });
// SET_SURVEY_ASSIGN_DATA
export const setSurveyAssignData = (data) => ({ type: constants.SET_SURVEY_ASSIGN_DATA, data });
// GET_SURVEY_USERS
export const getSurveyUsers = () => ({ type: constants.GET_SURVEY_USERS });
// SET_SURVEY_USERS
export const setSurveyUsers = (data) => ({ type: constants.SET_SURVEY_USERS, data });
// END_SURVEY
export const endSurvey = (id) => ({ type: constants.END_SURVEY, id });
// UPDATE_SURVEY
export const updateSurvey = (id, data) => ({ type: constants.UPDATE_SURVEY, id, data });
// GET_SURVEY_DETAIL_APPS
export const getSurveyDetailApps = (id) => ({ type: constants.GET_SURVEY_DETAIL_APPS, id });
// SET_SURVEY_DETAIL_APPS
export const setSurveyDetailApps = (data) => ({ type: constants.SET_SURVEY_DETAIL_APPS, data });
// GET_SURVEY_REPORT_QUESTIONS
export const getSurveyReportQuestions = (id, appId) => ({ type: constants.GET_SURVEY_REPORT_QUESTIONS, id, appId });
// SET_SURVEY_REPORT_QUESTIONS
export const setSurveyReportQuestions = (data) => ({ type: constants.SET_SURVEY_REPORT_QUESTIONS, data });
// GET_ANSWER_OPTION_USERS
export const getAnswerOptionUsers = (id, appId) => ({ type: constants.GET_ANSWER_OPTION_USERS, id, appId });
// SET_ANSWER_OPTION_USERS
export const setAnswerOptionUsers = (data) => ({ type: constants.SET_ANSWER_OPTION_USERS, data });

// GET_NOTIFICATIONS
export const getNotifications = () => ({ type: constants.GET_NOTIFICATIONS });
export const setNotifications = (data, isNew) => ({ type: constants.SET_NOTIFICATIONS, data, isNew });
export const readNotification = (id) => ({ type: constants.READ_NOTIFICATION, id });
export const readAllNotifications = () => ({ type: constants.READ_ALL_NOTIFICATIONS });

// SOCKET
export const setSocket = (socket) => ({ type: constants.SET_SOCKET, socket });

// GET_ASSIGNED_SURVEYS
export const getAssignedSurveys = () => ({ type: constants.GET_ASSIGNED_SURVEYS });
// SET_ASSIGNED_SURVEYS
export const setAssignedSurveys = (data) => ({ type: constants.SET_ASSIGNED_SURVEYS, data });
// GET_SURVEY_QUESTIONS
export const getSurveyQuestions = (id, appId) => ({ type: constants.GET_SURVEY_QUESTIONS, id, appId });
// SET_SURVEY_QUESTIONS
export const setSurveyQuestions = (data) => ({ type: constants.SET_SURVEY_QUESTIONS, data });
// ANSWER_SURVEY
export const answerSurvey = (data, id, complete, saveType) => ({
  type: constants.ANSWER_SURVEY, data, id, complete, saveType,
});
// ANSWER_BOOKMARK
export const setAnswerBookmark = (data) => ({
  type: constants.SET_SURVEY_ANSWER_BOOKMARK, data,
});
// SET_LOADING
export const setLoading = (value) => ({ type: constants.SET_LOADING, value });

// SET_LOADING
export const setInıtLoading = (value) => ({ type: constants.SET_INIT_LOADING, value });

// DELETE_FILE
export const deleteFile = (fileId, appId, fileType) => ({
  type: constants.DELETE_FILE, fileId, appId, fileType,
});

// CHANGE_PASSWORD
export const changePassword = (data) => ({ type: constants.CHANGE_PASSWORD, data });

// COMPANY_LOGO_UPLOAD
export const companyLogoUpload = (file) => ({ type: constants.COMPANY_LOGO_UPLOAD, file });
// COMPANY_UPDATE
export const companyUpdate = (data) => ({ type: constants.COMPANY_UPDATE, data });
// CREATE_COMPANY
export const createCompany = (data) => ({ type: constants.CREATE_COMPANY, data });

// ADD_COMPANY_MODAL
export const addCompanyModal = (value) => ({ type: constants.ADD_COMPANY_MODAL, value });
// USER_STATUS_CHANGE
export const userStatuChange = (id, isForce) => ({ type: constants.USER_STATUS_CHANGE, id, isForce });

// TIME_MODEL
export const getTimeModel = () => ({ type: constants.GET_TIME_MODEL });
export const setTimeModel = (data) => ({ type: constants.SET_TIME_MODEL, data });

// GET_ADMIN_COMPANIES
export const getAdminCompanies = () => ({ type: constants.GET_ADMIN_COMPANIES });
// SET_ADMIN_COMPANIES
export const setAdminCompanies = (data) => ({ type: constants.SET_ADMIN_COMPANIES, data });
// GET_ADMIN_COMPANY_USERS
export const getAdminCompanyUsers = (id) => ({ type: constants.GET_ADMIN_COMPANY_USERS, id });
// SET_ADMIN_COMPANY_USERS
export const setAdminCompanyUsers = (data) => ({ type: constants.SET_ADMIN_COMPANY_USERS, data });
// ADD_USER_TO_COMPANY
export const addUserToCompany = (data, id) => ({ type: constants.ADD_USER_TO_COMPANY, data, id });
// GET_COMPANY_DEPARTMENTS
export const getCompanyDepartments = (id) => ({ type: constants.GET_COMPANY_DEPARTMENTS, id });
// SET_COMPANY_DEPARTMENTS
export const setCompanyDepartments = (data) => ({ type: constants.SET_COMPANY_DEPARTMENTS, data });
// INTEGRATION_COMPONENTS
export const getIntegrationComponents = (payload) => ({ type: constants.GET_INTEGRATION_COMPONENTS, payload });
export const setIntegrationComponents = (data) => ({ type: constants.SET_INTEGRATION_COMPONENTS, data });
export const getIntegrationComponentsDetail = (id) => ({ type: constants.GET_INTEGRATION_COMPONENTS_DETAIL, id });
export const setIntegrationComponentsDetail = (data) => ({ type: constants.SET_INTEGRATION_COMPONENTS_DETAIL, data });
export const createIntegration = (data) => ({ type: constants.CREATE_INTEGRATION, data });
export const getIntegration = () => ({ type: constants.GET_INTEGRATION });
export const setIntegration = (data) => ({ type: constants.SET_INTEGRATION, data });
export const getIntegrationExcel = () => ({ type: constants.GET_INTEGRATION_EXCEL });
export const getIntegrationExcelSample = () => ({ type: constants.GET_INTEGRATION_EXCEL_SAMPLE });
export const uploadIntegrationExcel = (file) => ({ type: constants.UPLOAD_INTEGRATION_EXCEL, file });
export const getIntegrationComponentsDetailUser = (id) => ({ type: constants.GET_INTEGRATION_COMPONENTS_DETAIL_USER, id });
export const setIntegrationComponentsDetailUser = (data) => ({ type: constants.SET_INTEGRATION_COMPONENTS_DETAIL_USER, data });
export const saveIntegrationComponentsDetailUser = (id, data) => ({ type: constants.SAVE_INTEGRATION_COMPONENTS_DETAIL_USER, id, data });
export const uploadIntegrationFile = (file, id) => ({ type: constants.UPLOAD_INTEGRATION_FILE, file, id });
export const getIntegrationMessages = (payload) => ({ type: constants.GET_INTEGRATION_MESSAGES, payload });
export const setIntegrationMessages = (data) => ({ type: constants.SET_INTEGRATION_MESSAGES, data });
export const getIntegrationChannels = () => ({ type: constants.GET_INTEGRATION_CHANNEL });
export const setIntegrationChannels = (data) => ({ type: constants.SET_INTEGRATION_CHANNEL, data });

// GET_REG_COMP_INIT
export const getRegCompInit = () => ({ type: constants.GET_REG_COMP_INIT });
// SET_REG_ACCOUNT_TYPES
export const setRegAccountTypes = (data) => ({ type: constants.SET_REG_ACCOUNT_TYPES, data });
// SET_REG_CURRENCIES
export const setRegCurrencies = (data) => ({ type: constants.SET_REG_CURRENCIES, data });
// SET_REG_LANGUAGES
export const setRegLanguages = (data) => ({ type: constants.SET_REG_LANGUAGES, data });
// SET_REGISTER_COMPLETE
export const setRegisterComplete = (value) => ({ type: constants.SET_REGISTER_COMPLETE, value });

// COMPANY_ACTIVATION
export const companyActivation = (params) => ({ type: constants.COMPANY_ACTIVATION, params });
// RESEND_ACTIVATION_CODE
export const resendActivationCode = (params) => ({ type: constants.RESEND_ACTIVATION_CODE, params });
// SET_ACTIVATION_COMPLETE
export const setActivationComplete = (value) => ({ type: constants.SET_ACTIVATION_COMPLETE, value });
// SET_PASSWORD_RESET_COMPLETE
export const setPasswordResetComplete = (value) => ({ type: constants.SET_PASSWORD_RESET_COMPLETE, value });

// DOWNLOAD_INVENTORY_DATA
export const downloadInventoryData = (params) => ({ type: constants.DOWNLOAD_INVENTORY_DATA, params });

// SET_INVENTORY_REGISTER_FILES
export const setInventoryRegisterFiles = (data) => ({ type: constants.SET_INVENTORY_REGISTER_FILES, data });
// SET_INVENTORY_REGISTER_FILE_URLS
export const setInventoryRegisterFileUrls = (data) => ({ type: constants.SET_INVENTORY_REGISTER_FILE_URLS, data });

// SET_ACTIVE_NOT_FOUND
export const setActiveNotFound = (value) => ({ type: constants.SET_ACTIVE_NOT_FOUND, value });

// ADMIN_EXCEL_DOWNLOAD
export const adminExcelDownload = (excelType, fileName) => ({ type: constants.ADMIN_EXCEL_DOWNLOAD, excelType, fileName });
// ADMIN_EXCEL_UPLOAD
export const adminExcelUpload = (files, excelType) => ({ type: constants.ADMIN_EXCEL_UPLOAD, files, excelType });

// CREATE HARDWARE
export const createHardware = (params, hardwareType, sendToView) => ({
  type: constants.CREATE_HARDWARE_SERVER, params, hardwareType, sendToView,
});

// HARDWARE MODAL TYPES
export const getHardwareModalTypes = () => ({ type: constants.GET_HARDWARE_MODAL_TYPES });
export const setHardwareModalTypes = (data) => ({ type: constants.SET_HARDWARE_MODAL_TYPES, data });

// HARDWARE MODAL CATEGORY
export const getHardwareModalCategory = (id) => ({ type: constants.GET_HARDWARE_MODAL_CATEGORY, id });
export const setHardwareModalCategory = (data) => ({ type: constants.SET_HARDWARE_MODAL_CATEGORY, data });

// HARDWARE DEVİCES
export const getHardwareDevices = () => ({ type: constants.GET_HARDWARE_DEVICES });
export const setHardwareDevices = (data) => ({ type: constants.SET_HARDWARE_DEVICES, data });

// HARDWARE DEVİCE DETAİL
export const getHardwareDeviceDetail = (id) => ({ type: constants.GET_HARDWARE_DEVICE_DETAIL, id });
export const setHardwareDeviceDetail = (data) => ({ type: constants.SET_HARDWARE_DEVICE_DETAIL, data });

// HARDWARE DEVİCE DETAİL UPDATE
export const updateHardware = (params) => ({ type: constants.UPDATE_HARDWARE_DEVICE, params });

// CREATE_COSTING
export const createCosting = (data, files, sendToView) => ({
  type: constants.CREATE_COSTING, data, files, sendToView,
});
// GET_COSTING_CATEGORIES
export const getCostingCategories = () => ({ type: constants.GET_COSTING_CATEGORIES });
// SET_COSTING_CATEGORIES
export const setCostingCategories = (data) => ({ type: constants.SET_COSTING_CATEGORIES, data });
// GET_COSTING
export const getCosting = (sendToView) => ({ type: constants.GET_COSTING, sendToView });
// SET_COSTING
export const setCosting = (data) => ({ type: constants.SET_COSTING, data });
// GET_COSTING_DETAIL
export const getCostingDetail = (id) => ({ type: constants.GET_COSTING_DETAIL, id });
// SET_COSTING_DETAIL
export const setCostingDetail = (data) => ({ type: constants.SET_COSTING_DETAIL, data });
// SET_COSTING_FILES
export const setCostingFiles = (data) => ({ type: constants.SET_COSTING_FILES, data });
// SET_COSTING_FILE_URLS
export const setCostingFileUrls = (data) => ({ type: constants.SET_COSTING_FILE_URLS, data });
// GET_APPLICATION_CONTRACTS
export const getApplicationContracts = (appId, year) => ({ type: constants.GET_APPLICATION_CONTRACTS, appId, year });
// SET_APPLICATION_CONTRACTS
export const setApplicationContracts = (data) => ({ type: constants.SET_APPLICATION_CONTRACTS, data });
// UPDATE_COSTING
export const updateCosting = (data, costId) => ({ type: constants.UPDATE_COSTING, data, costId });
// DELETE_COSTING
export const deleteCosting = (costId) => ({ type: constants.DELETE_COSTING, costId });
// UPLOAD_COST_FILE
export const uploadCostFile = (data, id) => ({ type: constants.UPLOAD_COST_FILE, data, id });
// DELETE_COST_FILE
export const deleteCostFile = (fileId, costId) => ({ type: constants.DELETE_COST_FILE, fileId, costId });
// GET_COSTING_LOGS
export const getCostingLogs = (costId) => ({ type: constants.GET_COSTING_LOGS, costId });
// SET_COSTING_LOGS
export const setCostingLogs = (data) => ({ type: constants.SET_COSTING_LOGS, data });
// GET_HARDWARE_LOGS
export const getHardwareLogs = (typeID, id) => ({ type: constants.GET_HARDWARE_LOG, typeID, id });
// SET_HARDWARE_LOGS
export const setHardwareLogs = (data) => ({ type: constants.SET_HARDWARE_LOG, data });
// GET_COST_GROUP
export const getCostGroup = (id) => ({ type: constants.GET_COST_GROUP, id });
// SET_COST_GROUP
export const setCostGroup = (data) => ({ type: constants.SET_COST_GROUP, data });
// GET_APPLICATION_DRAFTS
export const getApplicationDrafts = () => ({ type: constants.GET_APPLICATION_DRAFTS });
// SET_APPLICATION_DRAFTS
export const setApplicationDrafts = (data) => ({ type: constants.SET_APPLICATION_DRAFTS, data });
// SET_INVENTORY_CONFIG
export const setInventoryConfig = (key, value) => ({ type: constants.SET_INVENTORY_CONFIG, key, value });
// SET_COSTING_CONFIG
export const setCostingConfig = (key, value) => ({ type: constants.SET_COSTING_CONFIG, key, value });
// GET_APPLICATION_DRAFT
export const getApplicationDraft = (id) => ({ type: constants.GET_APPLICATION_DRAFT, id });
// SET_APPLICATION_DRAFT
export const setApplicationDraft = (data) => ({ type: constants.SET_APPLICATION_DRAFT, data });
// UPDATE_APPLICATION_DRAFT
export const updateApplicationDraft = (data, id) => ({ type: constants.UPDATE_APPLICATION_DRAFT, data, id });
// UPLOAD_APP_DRAFT_FILE
export const uploadAppDraftFile = (data, id) => ({ type: constants.UPLOAD_APP_DRAFT_FILE, data, id });
// GET_APPLICATION_LIFECYCLE
export const getApplicationLifecycle = (id) => ({ type: constants.GET_APPLICATION_LIFECYCLE, id });
// SET_APPLICATION_LIFECYCLE
export const setApplicationLifecycle = (data) => ({ type: constants.SET_APPLICATION_LIFECYCLE, data });
// SET_INVENTORY_REGISTER_FILES
export const setIntegrationRegisterFiles = (data) => ({ type: constants.SET_INTEGRATION_REGISTER_FILES, data });
// SET_INTEGRATION_REGISTER_FILE_URLS
export const setIntegrationRegisterFileUrls = (data) => ({ type: constants.SET_INTEGRATION_REGISTER_FILE_URLS, data });
// SET_EXCEL_ERROR_LIST
export const setExcelErrorList = (data) => ({ type: constants.SET_EXCEL_ERROR_LIST, data });
// SET_EXCEL_ERROR_MODAL
export const setExcelErrorModal = (value) => ({ type: constants.SET_EXCEL_ERROR_MODAL, value });

// GET_HWCOSTING_CATEGORIES
export const getHWCostingCategories = () => ({ type: constants.GET_HWCOSTING_CATEGORIES });
// SET_HWCOSTING_CATEGORIES
export const setHWCostingCategories = (data) => ({ type: constants.SET_HWCOSTING_CATEGORIES, data });
// GET_HWCOSTING_EXPENSES
export const getHWCostingExpenses = () => ({ type: constants.GET_HWCOSTING_EXPENSES });
// SET_HWCOSTING_EXPENSES
export const setHWCostingExpenses = (data) => ({ type: constants.SET_HWCOSTING_EXPENSES, data });
// CREATE_HWCOSTING
export const createHWCosting = (data, files, sendToView) => ({
  type: constants.CREATE_HWCOSTING, data, files, sendToView,
});
// GET_HWCOSTING_LIST
export const getHWCostingList = (sendToView) => ({ type: constants.GET_HWCOSTING_LIST, sendToView });
// SET_HWCOSTING_LIST
export const setHWCostingList = (data) => ({ type: constants.SET_HWCOSTING_LIST, data });
// GET_HWCOSTING_DETAIL
export const getHWCostingDetail = (id) => ({ type: constants.GET_HWCOSTING_DETAIL, id });
// SET_HWCOSTING_DETAIL
export const setHWCostingDetail = (data) => ({ type: constants.SET_HWCOSTING_DETAIL, data });
// UPDATE_HWCOSTING
export const updateHWCosting = (data, costId) => ({ type: constants.UPDATE_HWCOSTING, data, costId });
// DELETE_HWCOSTING
export const deleteHWCosting = (costId) => ({ type: constants.DELETE_HWCOSTING, costId });
// GET_HWCOSTING_LOGS
export const getHWCostingLogs = (costId) => ({ type: constants.GET_HWCOSTING_LOGS, costId });
// SET_HWCOSTING_LOGS
export const setHWCostingLogs = (data) => ({ type: constants.SET_HWCOSTING_LOGS, data });

// GET_LDAP_USERS
export const getLdapUsers = (value) => ({ type: constants.GET_LDAP_USERS, value });
// SET_LDAP_USERS
export const setLdapUsers = (data) => ({ type: constants.SET_LDAP_USERS, data });
// CREATE_LDAP_USER
export const createLdapUser = (data) => ({ type: constants.CREATE_LDAP_USER, data });

// SET_SEND_TO_DETAIL_PAGE_ID
export const setSendToDetailPageID = (id) => ({ type: constants.SET_SEND_TO_DETAIL_PAGE_ID, id });

// GET_CONTRACT_YEARS
export const getContractYears = () => ({ type: constants.GET_CONTRACT_YEARS });
// SET_CONTRACT_YEARS
export const setContractYears = (data) => ({ type: constants.SET_CONTRACT_YEARS, data });
// SET_SEND_TO_VIEW
export const setSendToView = (value) => ({ type: constants.SET_SEND_TO_VIEW, value });

// GET_VENDOR_USERS
export const getVendorUsers = () => ({ type: constants.GET_VENDOR_USERS });
// SET_VENDOR_USERS
export const setVendorUsers = (data) => ({ type: constants.SET_VENDOR_USERS, data });
// CREATE_VENDOR_USER
export const createVendorUser = (data) => ({ type: constants.CREATE_VENDOR_USER, data });
// UPDATE_VENDOR_USER
export const updateVendorUser = (data, id) => ({ type: constants.UPDATE_VENDOR_USER, data, id });
// VENDOR_USER_STATUS_CHANGE
export const vendorUserStatusChange = (id) => ({ type: constants.VENDOR_USER_STATUS_CHANGE, id });
// DELETE_VENDOR_USER
export const deleteVendorUser = (id) => ({ type: constants.DELETE_VENDOR_USER, id });

// GET_APPLICATION_REPORTS
export const getApplicationReports = () => ({ type: constants.GET_APPLICATION_REPORTS });
// SET_APPLICATION_REPORTS
export const setApplicationReports = (data) => ({ type: constants.SET_APPLICATION_REPORTS, data });
// GET_AGGREGATION_REPORTS
export const getAggregationReports = (year) => ({ type: constants.GET_AGGREGATION_REPORTS, year });
// SET_AGGREGATION_REPORTS
export const setAggregationReports = (data) => ({ type: constants.SET_AGGREGATION_REPORTS, data });
// GET_HW_AGGREGATION_REPORTS
export const getHWAggregationReports = (year) => ({ type: constants.GET_HW_AGGREGATION_REPORTS, year });
// SET_HW_AGGREGATION_REPORTS
export const setHWAggregationReports = (data) => ({ type: constants.SET_HW_AGGREGATION_REPORTS, data });
// GET_USER_REPORTS
export const getUserReports = () => ({ type: constants.GET_USER_REPORTS });
// GET_USERS_REPORTS
export const getUsersReports = (year) => ({ type: constants.GET_USERS_REPORTS, year });
// SET_USER_REPORTS
export const setUserReports = (data) => ({ type: constants.SET_USER_REPORTS, data });
// SET_USERS_REPORTS
export const setUsersReports = (data) => ({ type: constants.SET_USERS_REPORTS, data });
// GET_DEPARTMENT_REPORT
export const getDepartmentReport = (year) => ({ type: constants.GET_DEPARTMENT_REPORT, year });
// SET_DEPARTMENT_REPORT
export const setDepartmentReport = (data) => ({ type: constants.SET_DEPARTMENT_REPORT, data });

// SET_GENERAL_REPORT_TYPE
export const setGeneralReportType = (value) => ({ type: constants.SET_GENERAL_REPORT_TYPE, value });

// UPDATE_INFO
export const updateInfo = (id, header, data) => ({
  type: constants.UPDATE_INFO, id, header, data,
});

// GET_APP_COST_REPORT
export const getAppCostReport = (year) => ({ type: constants.GET_APP_COST_REPORT, year });
// SET_APP_COST_REPORT
export const setAppCostReport = (data) => ({ type: constants.SET_APP_COST_REPORT, data });
// GET_PER_BUSINESS_CAPABILITY
export const getPerBusinessCapability = () => ({ type: constants.GET_PER_BUSINESS_CAPABILITY });
// SET_PER_BUSINESS_CAPABILITY
export const setPerBusinessCapability = (data) => ({ type: constants.SET_PER_BUSINESS_CAPABILITY, data });
// GET_PER_IT_DOMAIN
export const getPerITDomain = () => ({ type: constants.GET_PER_IT_DOMAIN });
// SET_PER_IT_DOMAIN
export const setPerITDomain = (data) => ({ type: constants.SET_PER_IT_DOMAIN, data });
// GET_PER_BUSINESS_DOMAIN
export const getPerBusinessDomain = () => ({ type: constants.GET_PER_BUSINESS_DOMAIN });
// SET_PER_BUSINESS_DOMAIN
export const setPerBusinessDomain = (data) => ({ type: constants.SET_PER_BUSINESS_DOMAIN, data });

// GET_DASHBOARD_MAP_REPORT
export const getDashboardMapReport = () => ({ type: constants.GET_DASHBOARD_MAP_REPORT });
// SET_DASHBOARD_MAP_REPORT
export const setDashboardMapReport = (data) => ({ type: constants.SET_DASHBOARD_MAP_REPORT, data });

// GET_DASHBOARD_COSTING_DETAIL
export const getDashboardCostingDetail = () => ({ type: constants.GET_DASHBOARD_COSTING_DETAIL });
// SET_DASHBOARD_COSTING_DETAIL
export const setDashboardCostingDetail = (data) => ({ type: constants.SET_DASHBOARD_COSTING_DETAIL, data });
// UPLOAD_HWCOST_FILE
export const uploadHWCostFile = (data, id) => ({ type: constants.UPLOAD_HWCOST_FILE, data, id });
// DELETE_HWCOST_FILE
export const deleteHWCostFile = (fileId, costId) => ({ type: constants.DELETE_HWCOST_FILE, fileId, costId });

// GET_LICENSE_FOLLOW
export const getLicenseFollow = () => ({ type: constants.GET_LICENSE_FOLLOW });
// DELETE_LICENSE_FOLLOW
export const deleteLicenseFollow = (id) => ({ type: constants.DELETE_LICENSE_FOLLOW, id });
// SET_LICENSE_FOLLOW
export const setLicenseFollow = (data) => ({ type: constants.SET_LICENSE_FOLLOW, data });

// GET_LICENSE_FOLLOW_AGREEMENT
export const getLicenseFollowAgreement = () => ({ type: constants.GET_LICENSE_FOLLOW_AGREEMENT });
// SET_LICENSE_FOLLOW_AGREEMENT
export const setLicenseFollowAgreement = (data) => ({ type: constants.SET_LICENSE_FOLLOW_AGREEMENT, data });

// GET_LICENSE_FOLLOW_USER
export const getLicenseFollowUser = () => ({ type: constants.GET_LICENSE_FOLLOW_USER });
// SET_LICENSE_FOLLOW_USER
export const setLicenseFollowUser = (data) => ({ type: constants.SET_LICENSE_FOLLOW_USER, data });

// GET_LICENSE_FOLLOW_DETAIL
export const getLicensFolloweDetail = (data) => ({ type: constants.GET_LICENSE_FOLLOW_DETAIL, data });
// SET_LICENSE_FOLLOW_DETAIL
export const setLicenseFollowDetail = (data) => ({ type: constants.SET_LICENSE_FOLLOW_DETAIL, data });

// CREATE_LICENSE_FOLLOW
export const createLicenseFollow = (data, sendToView) => ({ type: constants.CREATE_LICENSE_FOLLOW, data, sendToView });
// UPDATE_LICENSE_FOLLOW_USER
export const updateLicenseFollowUser = (payload) => ({ type: constants.UPDATE_LICENSE_FOLLOW_USER, payload });
// UPDATE_LICENSE_FOLLOW_KEY
export const updateLicenseFollowKey = (payload) => ({ type: constants.UPDATE_LICENSE_FOLLOW_KEY, payload });

// SET_LICENSE_FOLLOW_LOG
export const setLicenseFollowLog = (data) => ({ type: constants.SET_LICENSE_FOLLOW_LOG, data });

// CREATE_ADMIN_INTEGRATION
export const createAdminIntegration = (data) => ({ type: constants.CREATE_ADMIN_INTEGRATION, data });

// DELETE_ADMIN_INTEGRATION
export const deleteAdminIntegration = (id) => ({ type: constants.DELETE_ADMIN_INTEGRATION, id });

// UPDATE_ADMIN_INTEGRATION
export const updateAdminIntegration = (data, id) => ({ type: constants.UPDATE_ADMIN_INTEGRATION, data, id });

// GET_INTEGRATION_PI_URL
export const getIntegrationPiUrl = (refresh, id) => ({ type: constants.GET_INTEGRATION_PI_URL, refresh, id });

// SET_INTEGRATION_PI_URL
export const setIntegrationPiUrl = (id) => ({ type: constants.SET_INTEGRATION_PI_URL, id });

// GET_INTEGRATION_PI_URL
export const updateIntegrationPiData = () => ({ type: constants.UPDATE_INTEGRATION_PI_DATA });

// GET_INTEGRATION_MESSAGE_EXCEL
export const getIntegrationMessageExcel = (payload) => ({ type: constants.GET_INTEGRATION_MESSAGE_EXCEL, payload });

// SET_INTEGRATION_COMPONENT_INIT_DATA
export const setIntegrationComponentInitData = (data) => ({ type: constants.SET_INTEGRATION_COMPONENT_INIT_DATA, data });

// GET_INTEGRATION_RESPONSIBLE
export const getIntegrationResponsible = (data) => ({ type: constants.GET_INTEGRATION_RESPONSIBLE, data });

// SET_INTEGRATION_RESPONSIBLE
export const setIntegrationResponsible = (data) => ({ type: constants.SET_INTEGRATION_RESPONSIBLE, data });

// UPDATE_INTEGRATION_MESSAGES
export const updateIntegrationMessages = () => ({ type: constants.UPDATE_INTEGRATION_MESSAGES });

// GET_INTEGRATION_MESSAGE_LOG
export const getIntegrationMessageLog = (id) => ({ type: constants.GET_INTEGRATION_MESSAGE_LOG, id });

// SET_INTEGRATION_MESSAGE_LOG
export const setIntegrationMessageLog = (data) => ({ type: constants.SET_INTEGRATION_MESSAGE_LOG, data });

// CREATE_INTEGRATION_MESSAGE_LOG
export const createIntegrationMessageLog = (payload) => ({ type: constants.CREATE_INTEGRATION_MESSAGE_LOG, payload });

// CREATE_INTEGRATION_MESSAGE_LOG
export const deleteIntegrationMessageLog = (payload) => ({ type: constants.DELETE_INTEGRATION_MESSAGE_LOG, payload });

// GET_MESSAGE_MONITORING_SEND_MAIL_USERS
export const getMessageMonitoringSendMailUsers = (data) => ({ type: constants.GET_MESSAGE_MONITORING_SEND_MAIL_USERS, data });

// SET_MESSAGE_MONITORING_SEND_MAIL_USERS
export const setMessageMonitoringSendMailUsers = (data) => ({ type: constants.SET_MESSAGE_MONITORING_SEND_MAIL_USERS, data });

// CREATE_MESSAGE_MONITORING_SEND_MAIL_USERS
export const createMessageMonitoringSendMailUsers = (data) => ({ type: constants.CREATE_MESSAGE_MONITORING_SEND_MAIL_USERS, data });

// DELETE_MESSAGE_MONITORING_SEND_MAIL_USERS
export const deleteMessageMonitoringSendMailUsers = (data) => ({ type: constants.DELETE_MESSAGE_MONITORING_SEND_MAIL_USERS, data });

// GET_MESSAGE_MONITORING_SEND_MAIL_LOG
export const getMessageMonitoringSendMailLog = (data) => ({ type: constants.GET_MESSAGE_MONITORING_SEND_MAIL_LOG, data });

import { tra } from '../../../commons/utils';
import inventoryPages from '../public/publicInventory';

const deviceDetail = [
  [{
    subtitle: tra('Cihaz_Detaylari'),
    objectGroupName: 'SwitchRouter',
    inputArray: [
      {
        type: 'text',
        title: tra('Isim'),
        name: 'name', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'number',
        title: tra('Uplink_Portu'),
        name: 'uplink_port', // değişecek
        value: '1920 X 1080',
        validateRules: `lessEqualThanNumber:SwitchRouter.port_number|${tra('Port_Sayisi')}`,
      },
      // {
      //   type: 'number',
      //   title: tra('Boyutu'),
      //   name: 'size', // değişecek
      //   value: '1920 X 1080',
      // },
    ],
  }],
  [{
    spacerTitle: true,
    inputArray: [
      {
        type: 'number',
        title: tra('Port_Sayisi'),
        name: 'port_number', // değişecek
        value: '1920 X 1080',
        validateRules: `greaterEqualThanNumber:SwitchRouter.uplink_port|${tra('Uplink_Portu')}`,
      },
      {
        type: 'number',
        title: tra('Katman_Sayisi'),
        name: 'layer_number', // değişecek
        value: '1920 X 1080',
      },
      {
        type: 'checkbox',
        title: tra('Poe_Ozelligi_Var'),
        name: 'is_poe_attribute', // değişecek
        value: '1920 X 1080',
      },
    ],
  }],
];

export default [[...inventoryPages, deviceDetail]];

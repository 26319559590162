import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Edit } from '@material-ui/icons';
import { Grid, Tooltip } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, SaveNew } from '../../assets/icons';
import {
  AdminConfigurationTabs, CustomButton, InputContainer, PhotoBoxWrapper,
} from '../../assets/styledNew';
import { tra } from '../../commons/utils';
import Table from '../../components/Table';
import {
  changeInfoStatu, companyUpdate, createInfo, setToast,
} from '../../store/actions';
import CompanyPhotoChange from '../../components/modals/CompanyPhotoChange';
import Input from '../../components/Input';
// import Validator from '../../commons/validator';
import EditManageDataModal from '../../components/modals/EditManageDataModal';

const Configurations = ({ width }) => {
  // const simpleValidator = useRef(Validator);

  const [compInfo, setCompInfo] = useState({
    name: '',
    currency_id: '',
    language: '',
  });

  const [editManageDataModal, setEditManageDataModal] = useState(false);
  const [selectedManageData, setSelectedManageData] = useState({});

  const [company, setCompany] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);

  const [logoUrl, setLogoUrl] = useState('');

  const [dataType, setDataType] = useState('');
  const [dataVal, setDataVal] = useState('');

  const [editCompany, setEditCompany] = useState('');
  const [companyPhotoModal, setCompanyPhotoModal] = useState('');

  const currencies = useSelector((state) => state.infos.Currencies);
  const languages = useSelector((state) => state.infos.Languages);
  const infos = useSelector((state) => state.infos.Headers);
  const allInfo = useSelector((state) => state.infos);
  const adminCompanies = useSelector((state) => state.adminCompanies);
  const locations = useSelector((state) => state.infos.Locations);

  useEffect(() => {
    if (adminCompanies.length > 0) {
      const comp = adminCompanies.find((d) => d.id.toString() === localStorage.getItem('company'));
      setCompany(comp);
      setCompInfo({ name: comp.name, language: comp.language, currency_id: comp.currency_id });
      setLogoUrl(comp.company_image);
    }
  }, [adminCompanies]);

  useEffect(() => {
    if (locations.length > 0 && company) {
      setPhoneCode(locations.find((item) => item.dial_code === company.phone_code));
    }
  }, [locations, company]);

  const dispatch = useDispatch();

  const handleChangeCompInfo = (e, state) => {
    const val = e.target.value;
    setCompInfo((prevState) => ({
      ...prevState,
      [state]: val,
    }));
  };

  const createNewInfo = () => {
    // const typeValid = simpleValidator.current.fieldValid('dataType');
    // const valValid = simpleValidator.current.fieldValid('dataVal');
    // if (typeValid && valValid) {
    const header = infos.find((item) => item.id === dataType)?.name;

    if (dataVal !== '') {
      const data = {
        name: dataVal,
      };
      if (header) {
        dispatch(createInfo(header, data));
        setDataVal('');
      }
    } else {
      dispatch(setToast(true, tra('Deger_bos_birakilamaz'), 'warning'));
    }
  };

  const editCompanyCancel = () => {
    setEditCompany(false);
    setCompInfo({
      currency_id: company.currency_id,
      language: company.language,
      name: company.name,
    });
  };

  const handleCompUpdate = () => {
    // const compNameValid = simpleValidator.current.fieldValid('name');
    // if (compNameValid) {
    const data = {
      currency_id: compInfo.currency_id,
      language: compInfo.language,
      name: compInfo.name,
    };
    dispatch(companyUpdate(data));
    setEditCompany(false);
    // }
  };

  const handleEditManageData = (id) => {
    const data = allInfo[infos.find((item) => item.id === dataType) && infos.find((item) => item.id === dataType).name];
    setSelectedManageData(data.find((item) => item.id === id));
    setEditManageDataModal(true);
  };

  const columns = [
    {
      name: 'isRemoved',
      text: tra('Durum'),
      editable: false,
      align: 'center',
      sortable: true,
      customBody: (value, colName, index, row) => (
        <Input type="switch" value={!value} onChange={() => dispatch(changeInfoStatu(infos.find((item) => item.id === dataType).name, row.id))} />
      ),
    },
    {
      name: 'id',
      text: tra('ID'),
      editable: false,
      sortable: true,
      align: 'center',
    },
    {
      name: 'name',
      text: tra('Deger'),
      editable: false,
      sortable: true,
      align: 'center',
      searchable: true,
    },
    {
      name: 'desc',
      text: tra('Aciklama'),
      editable: false,
      sortable: false,
      align: 'left',
    },
    {
      name: 'createdAt',
      text: tra('Yaratilma_Tarihi'),
      editable: false,
      sortable: true,
      align: 'center',
      customBody: (value) => value && new Date(value).toLocaleDateString(),
    },
    {
      name: 'id',
      text: tra('Duzenle'),
      editable: false,
      sortable: true,
      align: 'center',
      customBody: (value) => (
        <div style={{ cursor: 'pointer' }}>
          <Edit width={17} onClick={() => handleEditManageData(value)} />
        </div>
      ),
    },
  ];

  return (
    <AdminConfigurationTabs>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div style={{ padding: '20px 0px' }}>
            <Grid
              container
              spacing={isWidthDown('sm', width) ? 0 : 2}
            >
              <Grid item xs={12}>
                <h4>{tra('Sirket_Bilgileri')}</h4>
              </Grid>
              <Grid container justify={isWidthDown('sm', width) ? 'center' : 'flex-start'} item xs={isWidthDown('sm', width) ? 12 : 4}>
                <Tooltip title={tra('Logo_Eklemek_İçin_Tıklayın')}>
                  <PhotoBoxWrapper
                    onClick={() => setCompanyPhotoModal(true)}
                    style={{
                      width: 150,
                      padding: 0,
                      cursor: 'pointer',
                      margin: isWidthDown('sm', width) ? '40px 0px' : 'unset',
                    }}
                  >
                    {logoUrl !== '' ? (
                      <img style={{ width: 150 }} alt="logoComp" src={`${logoUrl}?v=${new Date().getTime()}`} />
                    ) : (
                      <div style={{ borderColor: '#C1D1E2' }} className="emptyBox">
                        <Plus width={20} />
                      </div>
                    )}
                  </PhotoBoxWrapper>
                </Tooltip>
              </Grid>
              <Grid container item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="text"
                      onChange={(e) => handleChangeCompInfo(e, 'name')}
                      value={compInfo.name}
                      disabled={!editCompany}
                      name="compName"
                      title={tra('Sirket_Adi')}
                    // onBlur={() => simpleValidator.current.showMessageFor('name')}
                    // error={Validator.message('name', compInfo.name, 'required|min:3')}
                    // helperText={Validator.message('name', compInfo.name, 'required|min:3')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="select"
                      onChange={(e) => handleChangeCompInfo(e, 'currency_id')}
                      data={currencies}
                      value={compInfo.currency_id}
                      disabled={!editCompany}
                      name="currency_id"
                      title={tra('Para_Birimi')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type="select"
                      onChange={(e) => handleChangeCompInfo(e, 'language')}
                      data={languages}
                      value={compInfo.language}
                      disabled={!editCompany}
                      name="language"
                      title={tra('Dil_Secimi')}
                      labelKey="language"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h4>{tra('Key_Contact_For_Company')}</h4>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="text"
                    disabled
                    value={company?.owner_name}
                    name="userName"
                    title={tra('Adi')}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Input
                    type="text"
                    value={company?.user_email}
                    disabled
                    name="userSurname"
                    title={tra('Soyad')}
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <Input
                    type="text"
                    value={company?.user_email}
                    disabled
                    name="userEmail"
                    title={tra('Email')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    data={locations}
                    disabled
                    telCode={phoneCode}
                    type="tel"
                    value={company?.user_phone}
                    title={tra('Telefon')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    type="text"
                    value={company?.user_title}
                    disabled
                    name="userTitle"
                    title={tra('Unvan')}
                  />
                </Grid>
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                {editCompany ? (
                  <>
                    <CustomButton style={{ marginRight: 20, marginTop: isWidthDown('sm', width) ? 15 : 0 }} onClick={() => editCompanyCancel()}>{tra('Vazgec')}</CustomButton>
                    <CustomButton style={{ marginTop: isWidthDown('sm', width) ? 15 : 0 }} onClick={() => handleCompUpdate()} startIcon={<SaveNew width={14} />}>{tra('Bilgileri_Guncelle')}</CustomButton>
                  </>
                ) : (
                  <CustomButton style={{ marginTop: isWidthDown('sm', width) ? 15 : 0 }} onClick={() => setEditCompany(true)} startIcon={<Edit width={14} />}>{tra('Bilgileri_Düzenle')}</CustomButton>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputContainer>
            <div style={{ paddingBottom: 20 }} className="header">
              <h4>{tra('Veri_Islemleri')}</h4>
            </div>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} className="left">
                <Input
                  title={tra('Veri_Tipi')}
                  value={dataType}
                  type="select"
                  data={infos}
                  onChange={(e) => setDataType(e.target.value)}
                // error={Validator.message('dataType', dataType, 'required')}
                // helperText={Validator.message('dataType', dataType, 'required')}
                />
                <Input
                  title={tra('Deger')}
                  value={dataVal}
                  type="text"
                  onChange={(e) => setDataVal(e.target.value)}
                // error={Validator.message('dataVal', dataVal, 'required')}
                // helperText={Validator.message('dataVal', dataVal, 'required')}
                />
                <div className="buttonWrapper">
                  <CustomButton active onClick={() => createNewInfo()}><SaveNew width={14} /> {tra('Veriyi_Kaydet')}</CustomButton>
                </div>
              </Grid>
              {
                allInfo[infos.find((item) => item.id === dataType) && infos.find((item) => item.id === dataType).name] && (
                  <Grid item xs={12} className="right">
                    <Table
                      id="adminTable"
                      data={allInfo[infos.find((item) => item.id === dataType) && infos.find((item) => item.id === dataType).name] || []}
                      columns={columns}
                      options={{
                        pagination: false,
                        scroll: true,
                      }}
                    />
                  </Grid>
                )
              }
            </Grid>
          </InputContainer>
        </Grid>
      </Grid>
      <CompanyPhotoChange open={companyPhotoModal} close={() => setCompanyPhotoModal(false)} />
      <EditManageDataModal dataType={dataType} item={selectedManageData} open={editManageDataModal} close={() => setEditManageDataModal(false)} />
    </AdminConfigurationTabs>
  );
};

Configurations.propTypes = {
  width: PropTypes.string.isRequired,
};

export default Configurations;
